import { connect } from 'react-redux';
import { RootState } from 'store';
import { PassItemDataProps, PassItemInternalDataProps, PassItemActionProps, PassItem } from '../pass-item';
import { FetchCommandTypes, getCommandStatus } from 'store/global/fetch';
import { getIsPublicWebMode } from 'store/location';
import { UIScheduleViewType } from 'store/global/ui/schedule';
import { scheduleAvbokaPass } from 'store/bokningar';
import { bokaPass } from 'store/pass';
import { disableBokaPass, allowBokaPass } from 'store/global/ui/sitecore/sitecoreActions';
import { closeSideBar } from 'store/global/ui/side-bar';
import { setSidebarToMinimised } from 'components/common/ScheduleLauncher';

const mapState = (state: RootState, props: PassItemInternalDataProps): PassItemDataProps => {
  const passInDay = state.pass.read.days.find(d => d.key === props.passDay);
  const passItem = !!passInDay ? passInDay.items.find(p => p.PassId === props.passId) : undefined;

  return {
    pass: passItem,
    bokaStatus: getCommandStatus(state.global.fetch, props.passId, FetchCommandTypes.BOKA_PASS),
    avbokaStatus: getCommandStatus(state.global.fetch, props.passId, FetchCommandTypes.AVBOKA_PASS),
    bokningar: state.bokningar.bokningar,
    isPublicWebMode: getIsPublicWebMode(state.location),
    isAuthenticated: state.auth.read.isAuthenticated as boolean,
    allowBokaPassFromSitecore: state.global.ui.sitecore.allowBokaPass,
    selectedPass: state.global.ui.schedule.selectedPass,
    isWeekView: state.global.ui.schedule.viewShowing === UIScheduleViewType.WEEKVIEW,
  };
};

const mapDispatchToProps: PassItemActionProps = {
  bokaPass: bokaPass,
  scheduleAvbokaPass: scheduleAvbokaPass,
  disableBokaPass: disableBokaPass,
  allowBokaPass: allowBokaPass,
  closeSchedule: setSidebarToMinimised,
  closeSideBar: closeSideBar,
};

export default connect(mapState, mapDispatchToProps)(PassItem);
