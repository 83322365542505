export enum TrainingPreferencesReadActionType {
  AddSpecialintressen = 'ADD_SPECIALINTRESSEN',
  AddSavedSpecialintressen = 'ADD_SAVED_SPECIALINTRESSEN',
  RemoveSavedSpecialintressen = 'REMOVE_SAVED_SPECIALINTRESSE',
  AddSavedAnlaeggningar = 'ADD_SAVED_ANLAEGGNINGAR',
  RemoveSavedAnlaeggning = 'REMOVE_SAVED_ANLAEGGNING',
}

interface AddSpecialintressenReadAction {
  type: TrainingPreferencesReadActionType.AddSpecialintressen;
  childrenAgeGroups: Array<string>;
  workoutlevels: Array<string>;
}

interface AddSavedSpecialintressen {
  type: TrainingPreferencesReadActionType.AddSavedSpecialintressen;
  specialintressen: Array<fos.api.SavedSpecialintresse>;
}

interface RemoveSavedSpecialintresse {
  type: TrainingPreferencesReadActionType.RemoveSavedSpecialintressen;
  specialintresseId: number;
}

interface AddSavedAnlaeggningar {
  type: TrainingPreferencesReadActionType.AddSavedAnlaeggningar;
  anlaeggningar: Array<fos.api.SavedSpecialintresse>;
}

interface RemoveSavedAnlaeggning {
  type: TrainingPreferencesReadActionType.RemoveSavedAnlaeggning;
  id: number;
}

export const removeSavedSpecialintresse = (specialintresseId: number): RemoveSavedSpecialintresse => ({
  type: TrainingPreferencesReadActionType.RemoveSavedSpecialintressen,
  specialintresseId,
});

export const addSpecialintressen = (
  childrenAgeGroups: Array<string>,
  workoutlevels: Array<string>
): AddSpecialintressenReadAction => ({
  type: TrainingPreferencesReadActionType.AddSpecialintressen,
  childrenAgeGroups,
  workoutlevels,
});

export const addSavedSpecialintressen = (
  specialintressen: Array<fos.api.SavedSpecialintresse>
): AddSavedSpecialintressen => ({
  type: TrainingPreferencesReadActionType.AddSavedSpecialintressen,
  specialintressen,
});

export const addSavedAnlaeggningar = (anlaeggningar: Array<fos.api.SavedSpecialintresse>): AddSavedAnlaeggningar => ({
  type: TrainingPreferencesReadActionType.AddSavedAnlaeggningar,
  anlaeggningar,
});

export const removeSavedAnlaeggning = (id: number): RemoveSavedAnlaeggning => ({
  type: TrainingPreferencesReadActionType.RemoveSavedAnlaeggning,
  id,
});

export type TrainingPreferencesReadAction =
  | AddSpecialintressenReadAction
  | AddSavedSpecialintressen
  | RemoveSavedSpecialintresse
  | AddSavedAnlaeggningar
  | RemoveSavedAnlaeggning;
