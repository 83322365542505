import { configureApi } from './websd/utils/configureApi';
import { addError } from './store/global/errors';
import { store } from './index';
import { checkAuthStatus } from 'store/auth';

const apiURL = process.env.REACT_APP_API_URL as string;

export const api = configureApi({
  apiRootEndpoint: apiURL,
  unauthorizedAction: errorMessage => {
    store.dispatch<any>(checkAuthStatus());
    store.dispatch(addError(errorMessage));
  },
  errorActionCreator: errorMessage => store.dispatch(addError(errorMessage)),
});
