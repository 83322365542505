export enum MainMenuButtonEnum {
  'MY_ACCOUNT',
  'MY_WORKOUT',
}

export enum MainMenuActiveItem {
  NONE = 'NONE',
  MY_ACCOUNT = 'MY_ACCOUNT',
  MY_WORKOUT = 'MY_WORKOUT',
}
