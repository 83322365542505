import * as React from 'react';

export interface IconMyWorkoutProps {
  active: boolean;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconMyWorkout extends React.Component<IconMyWorkoutProps, {}> {
  defaultStyle = 'fill:none;stroke:#8B8F92;stroke-linecap:round;stroke-linejoin:round;';
  activeStyle = 'fill:none;stroke:#e31836;stroke-linecap:round;stroke-linejoin:round;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  render() {
    // TODO: Fix translation support for text
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" className="IconMyWorkout">
        <style type="text/css">
          .IconMyWorkout .inactive{'{' + this.defaultStyle + '}'}
          .IconMyWorkout .active{'{' + this.activeStyle + '}'}
          .activeMenu .inactive{'{' + this.activeStyle + '}'}
        </style>
        <title>{this.props.title}</title>
        {/* tslint:disable */}

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={this.getClassForIcon()}
              d="M37.72,26.28c0,2.83-1.83,6.44-3.23,8.57-2.12,3.22-4.57,4.32-8.46,4.32s-6.23-1-8.36-4.17c-1.46-2.15-3.33-5.83-3.33-8.72,0-7.12,5.23-12.89,11.69-12.89S37.72,19.16,37.72,26.28Z"
            />
            <path className={this.getClassForIcon()} d="M54.7,30.69V41.45a4,4,0,0,1-4,4H44" />
            <path
              className={this.getClassForIcon()}
              d="M38.92,42.24V36.07a1.71,1.71,0,0,1,1.72-1.71h3.24a1.71,1.71,0,0,1,1.72,1.71v9.16"
            />
            <line className={this.getClassForIcon()} x1="42.28" y1="39.72" x2="41.14" y2="39.72" />
            <line className={this.getClassForIcon()} x1="38.32" y1="19.01" x2="41.63" y2="16.95" />
            <path
              className={this.getClassForIcon()}
              d="M54.49,28l1.08.43a1.54,1.54,0,0,0,2-.86h0a1.54,1.54,0,0,0-.86-2L42.23,16.6"
            />
            <path
              className={this.getClassForIcon()}
              d="M21.65,41.43a8.74,8.74,0,0,0-1.42.12,5,5,0,0,0-1.49.63L11.11,47a5.87,5.87,0,0,0-2.78,5v.77a.33.33,0,0,0,.33.33H43.39a.34.34,0,0,0,.34-.33l0-.71A5.88,5.88,0,0,0,41,47.15L32.9,42a4.65,4.65,0,0,0-2.37-.5"
            />
            <path className={this.getClassForIcon()} d="M15.82,44a24.87,24.87,0,0,1,.66,9" />
            <path className={this.getClassForIcon()} d="M36.07,44s-1.67,4.83-1,9" />
            <path className={this.getClassForIcon()} d="M18,42.83s2.33,5.3,7.94,5.3,6.78-3,6.78-3" />
            <rect width="66" height="66" fill="none" />
          </g>
        </g>
      </svg>
    );
  }
}
