import { Reducer } from 'redux';
import { Action } from 'store';
import {
  UIScheduleActionType,
  SetScrollToDayAction,
  UIScheduleSetSelectedPass,
  UIScheduleSetShouldDisableSticky,
  UISetClickedFromDayPickerAction,
  UISetDayClickedFromDayPickerAction,
  UIScheduleSetScrollPosition,
} from './uiScheduleActions';

export interface UIScheduleState {
  scrollToDay: string;
  viewShowing: UIScheduleViewType;
  selectedPass?: fos.api.Pass;
  shouldDisableSticky: boolean;
  clickFromDayPicker: boolean;
  clickFromDayPickerDay: string;
  scrollTop: number;
  scrollBottom: number;
  scrollRightPercent: number;
}

export enum UIScheduleViewType {
  'LISTVIEW' = 'LISTVIEW',
  'WEEKVIEW' = 'WEEKVIEW',
}

export const setScrollToDay = (day: string): SetScrollToDayAction => ({
  type: UIScheduleActionType.SetScrollToDay,
  scrollToDay: day,
});

export const setClickedFromDayPicker = (clickFromDayPicker: boolean): UISetClickedFromDayPickerAction => ({
  type: UIScheduleActionType.SetClickedFromDayPicker,
  clickFromDayPicker,
});

export const setDayClickedFromDayPicker = (clickFromDayPickerDay: string): UISetDayClickedFromDayPickerAction => ({
  type: UIScheduleActionType.SetDayClickedFromDayPicker,
  clickFromDayPickerDay,
});

const initialState: UIScheduleState = {
  scrollToDay: '',
  shouldDisableSticky: false,
  viewShowing: UIScheduleViewType.LISTVIEW,
  clickFromDayPicker: false,
  clickFromDayPickerDay: '',
  scrollTop: 0,
  scrollBottom: -1,
  scrollRightPercent: 0,
};

export const getScheduleHasWeekView = (scheduleUIState: UIScheduleState): boolean => {
  return scheduleUIState.viewShowing === UIScheduleViewType.WEEKVIEW;
};

export const setSelectedPass = (selectedPass: fos.api.Pass): UIScheduleSetSelectedPass => ({
  type: UIScheduleActionType.SetSelectedPass,
  selectedPass: selectedPass,
});

export const setShouldDisableSticky = (shouldBeSticky: boolean): UIScheduleSetShouldDisableSticky => ({
  type: UIScheduleActionType.SetShouldDisableSticky,
  value: shouldBeSticky,
});

export const setScheduleScrollPosition = (
  scrollTop: number,
  scrollBottom: number,
  scrollRightPercent: number
): UIScheduleSetScrollPosition => ({
  type: UIScheduleActionType.SetScrollPosition,
  scrollTop,
  scrollBottom,
  scrollRightPercent,
});

export const scheduleUiReducer: Reducer<UIScheduleState> = (state = initialState, action: Action): UIScheduleState => {
  switch (action.type) {
    case UIScheduleActionType.SetScrollToDay:
      return { ...state, scrollToDay: action.scrollToDay };
    case UIScheduleActionType.SetDayClickedFromDayPicker:
      return {
        ...state,
        clickFromDayPickerDay: action.clickFromDayPickerDay,
      };
    case UIScheduleActionType.SetClickedFromDayPicker:
      return {
        ...state,
        clickFromDayPicker: action.clickFromDayPicker,
      };
    case UIScheduleActionType.SetViewTypeToList:
      return { ...state, viewShowing: UIScheduleViewType.LISTVIEW };
    case UIScheduleActionType.SetViewTypeToWeek:
      return { ...state, viewShowing: UIScheduleViewType.WEEKVIEW };
    case UIScheduleActionType.SetSelectedPass:
      return { ...state, selectedPass: action.selectedPass };
    case UIScheduleActionType.SetShouldDisableSticky:
      return {
        ...state,
        shouldDisableSticky: action.value,
      };
    case UIScheduleActionType.SetScrollPosition:
      return {
        ...state,
        scrollTop: action.scrollTop,
        scrollBottom: action.scrollBottom,
        scrollRightPercent: action.scrollRightPercent,
      };
    default:
      return state;
  }
};
