import * as React from 'react';

import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as Location } from 'store/location/locationActions';
import { FoereningarListContainer } from 'components/foereningarList';
import { EmailInput, MainInput, MainButton, Logotyp } from 'components/common';
import { StatusBar } from 'components/common';

import { SitecoreDictionary } from 'models';
import { Spinner } from '../common/Spinner';
import { sitecoreUtils } from 'utils/sitecoreIntegration';
import { SiteSwitcherState } from 'store/global/ui/siteswitcher';
import { TranslateFunction } from 'react-localize-redux';

export interface LoginDataProps {
  readonly isExecutingFailed: boolean;
  readonly isExecuting: boolean;
  siteSwitcherState: SiteSwitcherState;
  isSiteSwitcherShowing: boolean;
  isVisitingShop: boolean;
  translate: TranslateFunction;
  username: string;
  password: string;
  languageId: number;
  foereningId: number;
  ProductsInBasket?: number | undefined;
}

export interface LoginPassedProps {
  publicScheduleMode: boolean;
  passId: number | string;
  bokaKoePlats: boolean;
  title: string;
}

export interface LoginActionProps {
  updateUsername: (email: string) => void;
  updatePassword: (password: string) => void;
  updateFoereningId: (foereningId: number) => void;
  onLogin: (credentials: fos.api.Credentials) => void;
  goToLogin: () => void;
  loginFromSchedule: (credentials: fos.api.Credentials, passId: string | number, bokaKoePlats: boolean) => void;
  clearLoginCmd: () => void;
  setFoereningIdFromQueryParameter: () => void;
  setSiteSwitcherToVisible: () => void;
  setSiteSwitcherToHidden: () => void;
}

export type LoginComponentProps = LoginActionProps & LoginDataProps & LoginPassedProps;

interface CredentialsState {
  UsernameIsValid?: boolean;
  statusMessageVisible: boolean;

  // Used to animate status message bar
  statusMessageClass: string;
  publicScheduleLoginExpanded: boolean;
}

// TODO: Use localisation for text strings.
export class Login extends React.Component<LoginComponentProps, CredentialsState> {
  errorStatusClass = 'StatusMessage--BlackRoundedCorners';

  private checkAutoCompleteIntervalHandle: number | undefined;
  private emailEl: HTMLInputElement | undefined;
  private passwordEl: HTMLInputElement | undefined;
  private ignoreAutoCheck: boolean | undefined;

  constructor(props: LoginComponentProps) {
    super(props);

    this.state = {
      statusMessageVisible: false,
      statusMessageClass: this.errorStatusClass,
      publicScheduleLoginExpanded: false,
    };
  }

  componentDidMount() {
    if (this.props.publicScheduleMode) {
      this.props.setFoereningIdFromQueryParameter();
    }
    this.checkAutoCompleteIntervalHandle = window.setInterval(this.checkForAutoComplete.bind(this), 1000);
  }

  UNSAFE_componentWillReceiveProps(newProps: LoginDataProps) {
    if (newProps.isExecutingFailed) {
      this.setState({
        statusMessageVisible: true,
      });
    }
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.checkAutoCompleteIntervalHandle);
    this.props.clearLoginCmd();
  }

  getVisibleStatus() {
    if (this.state.statusMessageVisible) {
      return true;
    }
    return false;
  }

  getPublicScheduleClasses() {
    let psClasses = '';
    if (this.props.publicScheduleMode) {
      psClasses += ' Login--scrollLocked';
      if (!this.state.publicScheduleLoginExpanded) {
        psClasses += ' Login--collapsed';
      } else if (this.state.publicScheduleLoginExpanded) {
        psClasses += ' Login--expanded';
      }
    }
    return psClasses;
  }

  expandLoginForm() {
    this.setState({
      publicScheduleLoginExpanded: !this.state.publicScheduleLoginExpanded,
    });
  }

  render() {
    this.ignoreAutoCheck = false;
    return (
      <div className={'Login full-flex ' + this.getPublicScheduleClasses()}>
        {this.props.publicScheduleMode && (
          <div className="Login__PublicScheduleInformation">
            {!this.state.publicScheduleLoginExpanded && (
              <p className="WhoAreYouText">{this.props.translate(SitecoreDictionary.Login.WhoYouAre)}</p>
            )}
            {this.state.publicScheduleLoginExpanded && <Logotyp className="Login__Brand" />}
            {!this.state.publicScheduleLoginExpanded && (
              <div className="PublicScheduleInformation__Identify" onClick={() => this.expandLoginForm()}>
                {this.props.translate(SitecoreDictionary.Login.IdentifyYourSelf)}
              </div>
            )}
          </div>
        )}
        {!this.props.publicScheduleMode && <Logotyp className="Login__Brand" />}
        {(this.state.publicScheduleLoginExpanded || !this.props.publicScheduleMode) && (
          <div className="Login__Form">
            {this.props.isExecuting && <Spinner appearance="white" className="Login__Form__Spinner" />}

            {!this.props.isExecuting && (
              <form onSubmit={event => this.submit(event)} name="Login">
                <div className="Form__Input">
                  <EmailInput
                    id="LoginFormEmail"
                    shouldValidate={true}
                    required={true}
                    alignLabel="left"
                    label={this.props.translate(SitecoreDictionary.Login.Email) as string}
                    isValid={!!this.props.username && this.state.UsernameIsValid}
                    type="email"
                    placeholder={this.props.translate(SitecoreDictionary.Login.EmailPlaceholder) as string}
                    name="epost"
                    value={this.props.username}
                    onValueChange={value => this.handleEmailChange(value as string)}
                    onValidChanged={isValid => this.handleEmailIsValidChange(isValid)}
                    autocomplete="username"
                    autoCorrect="off"
                    tabindex={1}
                  />
                  <MainInput
                    id="LoginFormPassword"
                    name="password"
                    autocomplete="current-password"
                    autoCorrect="off"
                    shouldValidate={true}
                    isValid={this.props.password ? true : false}
                    type="password"
                    label={this.props.translate(SitecoreDictionary.Login.Password) as string}
                    alignLabel="left"
                    placeholder={this.props.translate(SitecoreDictionary.Login.PasswordPlaceholder) as string}
                    value={this.props.password}
                    onValueChange={value => this.handlePasswordChange(value as string)}
                    tabindex={2}
                  />

                  {!this.props.publicScheduleMode && !this.props.isVisitingShop && (
                    <React.Fragment>
                      <FoereningarListContainer
                        labelColor="white"
                        onChange={foreningId => this.handleFoereningChange(foreningId)}
                        tabindex={3}
                        value={this.props.foereningId}
                      />
                      <p className="Login--Notification">
                        {this.props.translate(SitecoreDictionary.Login.FoereningSelectMessage)}
                      </p>
                    </React.Fragment>
                  )}
                  <StatusBar
                    visible={this.getVisibleStatus()}
                    message={this.props.translate(SitecoreDictionary.Login.Messages.InvalidCredentials) as string}
                    cssClass={this.errorStatusClass}
                  />

                  <div className="Input__Submit">
                    <MainButton
                      id="LoginButton"
                      type="submit"
                      buttonStyle="solid"
                      color="white"
                      title={
                        this.props.title
                          ? this.props.title
                          : (this.props.translate(SitecoreDictionary.Login.Login) as string)
                      }
                      className="Button"
                      disabled={this.shouldDisableSubmit()}
                    />
                    {!this.props.publicScheduleMode && ( // We do not want to allow re-setting of password in public mode as this brings complications which are best avoided for now
                      <p>
                        <FancyNavLink to={RouteMap[Location.Reset]}>
                          {this.props.translate(SitecoreDictionary.Login.ForgotPassword) as string}
                        </FancyNavLink>
                      </p>
                    )}
                  </div>
                </div>
              </form>
            )}
            {// byt namn this.props.sitecoremode till publicschedule mode
            sitecoreUtils.isIframedInSitecore() && !this.props.publicScheduleMode && (
              <MainButton
                handleOnClick={() => sitecoreUtils.onHideLoginSignal()}
                buttonStyle="solid"
                color="white"
                title={this.props.translate(SitecoreDictionary.Login.GoBack) as string}
                className="Button"
                tabindex={4}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  private shouldDisableSubmit(): boolean {
    return (
      this.props.password === null ||
      this.props.password === '' ||
      !this.state.UsernameIsValid ||
      !this.props.foereningId ||
      this.props.foereningId < 1
    );
  }

  private submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const credentials: fos.api.Credentials = {
      Username: this.props.username,
      Password: this.props.password,
      FoereningId: this.props.foereningId,
      LanguageId: this.props.languageId,
      CalendarSync: false,
      LoginToToBuy: false,
      ProductsInBasket: this.props.ProductsInBasket,
    };

    if (this.props.publicScheduleMode) {
      this.props.loginFromSchedule(credentials, this.props.passId, this.props.bokaKoePlats);
    } else {
      this.props.onLogin(credentials);
    }
  }

  private handleEmailChange(username: string) {
    this.ignoreAutoCheck = true;
    this.props.updateUsername(username);
  }

  private handleEmailIsValidChange(UsernameIsValid: boolean) {
    this.setState({ UsernameIsValid });
  }

  private handlePasswordChange(password: string) {
    this.ignoreAutoCheck = true;
    this.props.updatePassword(password);
  }

  private handleFoereningChange(foereningId: number) {
    this.props.updateFoereningId(foereningId);
  }

  private updateEmailAndPasswordReferences() {
    this.emailEl = document.getElementById('LoginFormEmail') as HTMLInputElement;
    this.passwordEl = document.getElementById('LoginFormPassword') as HTMLInputElement;
  }

  private checkForAutoComplete() {
    this.updateEmailAndPasswordReferences();
    if (!this.emailEl || !this.passwordEl) {
      return;
    }
    if (this.ignoreAutoCheck) {
      return;
    }
    const emailDomValue = this.emailEl.value;
    const passwordDomValue = this.passwordEl.value;
    if (emailDomValue !== this.props.username) {
      this.props.updateUsername(emailDomValue);
    }

    if (passwordDomValue !== this.props.password) {
      this.props.updatePassword(passwordDomValue);
    }
  }
}
