import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { store } from 'index';
import { ShopDataProps, ShopActionProps, Shop, ShopFilterFunctions } from './Shop';
import {
  // models and types
  ProductHows,
  ProductWhens,
  ProductWhos,
  ShopFilter,
  ShopFilterCategoryNames,
  // selectors
  getFilters,

  // actions
  // ShopActionResetProductsFilter,
  // dispatches
  clearShopFilterOptionsCategory,
  updateShopFilterOptionsCategory,
  updateShopResetProductsFilter,
} from 'store/shop';
import { createBasket, addProduct, removeProduct } from 'store/basket';
import { LocationActionTypes, switchView, productDetailsRouteAction, getIsAdminWebMode } from 'store/location';
import { getTranslate } from 'store/global/dictionary';
import {
  FetchStatusAggregateTypes,
  getIsFetching,
  getIsFetched,
  FetchCommandTypes,
  getIsExecuting,
} from 'store/global/fetch';
import {
  UIShopFilterState,
  setSelectedCategory,
  FilterCategoryType,
  toggleShowProducts,
} from 'store/global/ui/shop-filter/uiShopFilterReducer';

import { getIsPublicWebMode } from 'store/location';
import { removeSubscription } from './Order/Order';
// import { Dispatch } from 'store';

function onFilterCategoryItems(value: string, category: string) {
  // Do nothing as we do not filter products
}

const onSelectCategoryItem = (value: string, category: string) => {
  //
  const categoryName: ShopFilterCategoryNames = category as ShopFilterCategoryNames;
  const selectedItemsInRelevantCategory: string[] = store.getState().product.userSelectedFilterOptions[categoryName];
  let valueAlreadySelected: boolean = false;
  switch (categoryName) {
    // case ScheduleFilterCategoryName.Veckodag: {
    //   // TODO: Investigate removal once we have concluded that we do not need to handle this case specially
    //   valueAlreadySelected = !!find(selectedItemsInRelevantCategory, (selected) => selected === value);
    //
    //   break;
    // }
    default: {
      valueAlreadySelected = !!selectedItemsInRelevantCategory.find(selected => selected === value);
    }
  }
  if (selectedItemsInRelevantCategory) {
    if (valueAlreadySelected) {
      const selecteItemsdWithoutCurrentValue = selectedItemsInRelevantCategory.filter(text => text !== value);
      store.dispatch(updateShopFilterOptionsCategory(categoryName, selecteItemsdWithoutCurrentValue));
    } else {
      let arrayToAppend: string[] | ProductWhos[] | ProductWhens[] | ProductHows[]; // We clone so as not to change the store value directly
      switch (category) {
        // case ScheduleFilterCategoryName.Favoritfilter: {
        //   arrayToAppend = [value];
        //   break;
        // }
        default: {
          arrayToAppend = [...selectedItemsInRelevantCategory];
          arrayToAppend.push(value);
          arrayToAppend.sort();
        }
      }
      store.dispatch(updateShopFilterOptionsCategory(categoryName, arrayToAppend));
    }
  }
};

function onDeselectCategoryItem(value: string, category: string) {
  // Do nothing
}

function onClearCategoryFilters(category: string) {
  const categoryName: ShopFilterCategoryNames = category as ShopFilterCategoryNames;
  store.dispatch(clearShopFilterOptionsCategory(categoryName));
}

const defaultFilterFunctions = {
  onFilter: onFilterCategoryItems,
  onSelect: onSelectCategoryItem,
  onDeselect: onDeselectCategoryItem,
  onClearCategoryFilters: onClearCategoryFilters,
};
const filterFunctions: ShopFilterFunctions = {
  ProductWho: defaultFilterFunctions,
  ProductWhere: defaultFilterFunctions,
  ProductWhen: defaultFilterFunctions,
  ProductHow: defaultFilterFunctions,
};

const mapStateToProps = (state: RootState, props: any): ShopDataProps => {
  const shopFilterUIState: UIShopFilterState = state.global.ui.shopFilter;
  const cachedFullSetOfFilterProperties: ShopFilter = state.product.fullSetOfFilterProperties;
  const shopFoerening = state.global.session.shopFoerening!;
  const foerening = state.foereningar.read.Foereningar.find(f => '' + f.Id === '' + shopFoerening);
  return {
    isPublicWebMode: getIsPublicWebMode(state.location),
    products: state.product.filteredProducts,
    filters: state.product.filteredFilterCategories,
    isFetchingFilters: getIsFetching(state.global.fetch, shopFoerening, FetchStatusAggregateTypes.GET_FILTER),
    isFiltersFetched: getIsFetched(state.global.fetch, shopFoerening, FetchStatusAggregateTypes.GET_FILTER),
    isDesktopMode: props.isDesktopMode,
    shopFoerening: shopFoerening,
    productsInBasket: state.shoppingBasket.products,
    isAuthenticated:
      state.auth.read.isAuthenticated && state.global.session.activeFoerening === state.global.session.shopFoerening,
    translate: getTranslate(state.locale),
    filterUIState: shopFilterUIState,
    cachedUserFilters: state.product.userSelectedFilterOptions,
    cachedFullSetOfFilterProperties: cachedFullSetOfFilterProperties,
    filterFunctions: filterFunctions,
    selectedCategory: state.global.ui.shopFilter.selectedCategory,
    foereningNamn: !!foerening ? foerening.Namn : '',
    foereningDisplayName: !!foerening ? foerening.DisplayName : '',
    isVisiting: state.global.session.activeFoerening !== state.global.session.shopFoerening,
    isShowingProducts: state.global.ui.shopFilter.ShouldShowProducts,
    orderId: state.global.session.orderId!,
    isAdmin: getIsAdminWebMode(state.location),
    adminError: state.global.errors.adminError,
    isPendingOrderChange:
      getIsExecuting(state.global.fetch, '' + state.global.session.orderId, FetchCommandTypes.ADD_TO_ORDER) ||
      getIsExecuting(state.global.fetch, '' + state.global.session.orderId, FetchCommandTypes.REMOVE_FROM_ORDER),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ShopActionProps => ({
  getFilters: (foereningId: number) => dispatch(getFilters(foereningId)),
  createBasket: (foereningId: number) => dispatch(createBasket(foereningId)),
  addProduct: (product: fos.api.Product, foereningId: number) => dispatch(addProduct(product, foereningId)),
  removeProduct: (product: fos.api.Product, foereningId: number, orderId: number) => {
    removeSubscription(foereningId, orderId, product.Id);
    dispatch(removeProduct(product, foereningId));
  },
  goToLogin: () => dispatch(switchView(LocationActionTypes.Home)),
  onClearFilters: () => dispatch(updateShopResetProductsFilter()),
  setSelectedCategory: (selectedCategory: FilterCategoryType) => dispatch(setSelectedCategory(selectedCategory)),
  goToProductDetails: (id: number, name: string, foereningNamn: string, customerType: number) =>
    dispatch(productDetailsRouteAction(id, name, foereningNamn, customerType)),
  toggleShowProducts: (showing: boolean) => dispatch(toggleShowProducts(showing)),
});

export const ShopContainer = connect(mapStateToProps, mapDispatchToProps)(Shop);
