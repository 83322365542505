import * as React from 'react';
import { StatusBar, ConfirmationTextBox, MainButton, IconButton } from 'components/common';

import { SitecoreDictionary, ProductPaymentMethod } from 'models';
import { formatDate } from 'websd/utils';
import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';

export interface InternalTrainingCardDataProps {
  translate: TranslateFunction;
  errorOnBuy?: boolean;
  foereningId?: number;
  shopActive?: boolean;
}

export interface ExternalTrainingCardDataProps {
  data?: fos.api.Traeningskort;
}

export interface TrainingCardActionProps {
  buy?: (card: fos.api.Traeningskort) => void;
  terminate?: (payload: fos.api.AvslutaAbonnemangPayload) => void;
}

export interface TrainingCardState {
  userIsInformed?: boolean;
  showConfirmation?: boolean;
  showStatusMessage?: boolean;
}

export type TrainingCardDataProps = InternalTrainingCardDataProps & ExternalTrainingCardDataProps;

type TrainingCardProps = TrainingCardDataProps & TrainingCardActionProps;

export class TrainingCard extends React.Component<Readonly<TrainingCardProps>, TrainingCardState> {
  confirmationText = this.props
    .translate(SitecoreDictionary.SubscriptionsAndCards.ConfirmTerminateSubscription)
    .toString();
  okStatusClass = 'StatusMessage--Green';
  errorStatusClass = 'StatusMessage--Black';

  constructor(props: TrainingCardProps) {
    super(props);

    this.state = {
      showConfirmation: false,
      showStatusMessage: false,
      userIsInformed: false,
    };

    this.showConfirmation = this.showConfirmation.bind(this);
    this.hideConfirmation = this.hideConfirmation.bind(this);
    this.terminateCard = this.terminateCard.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: TrainingCardProps) {
    if (
      ((newProps.data && newProps.data.StatusMessage) || (newProps.errorOnBuy && !this.props.errorOnBuy)) &&
      !this.state.userIsInformed
    ) {
      this.setState({ showStatusMessage: true, userIsInformed: true });
      setTimeout(
        () =>
          this.setState({
            ...this.state,
            showStatusMessage: false,
            userIsInformed: false,
          }),
        4500
      );
      return true;
    } else if (!newProps.errorOnBuy && this.props.errorOnBuy) {
      this.setState({
        ...this.state,
        showStatusMessage: false,
        userIsInformed: false,
      });
    }
    return false;
  }

  isAktiv() {
    return this.props.data && this.props.data.IsAktiv;
  }

  shouldWarn() {
    return (
      this.props.data &&
      this.props.data.AntalAaterstaaendeKlipp !== undefined &&
      this.props.data.AntalAaterstaaendeKlipp !== null &&
      this.props.data.AntalAaterstaaendeKlipp < 3
    );
  }

  getCardDetailsText() {
    if (
      this.props.data &&
      this.props.data.AntalAaterstaaendeKlipp !== undefined &&
      this.props.data.AntalAaterstaaendeKlipp !== null
    ) {
      return `${this.props
        .translate(SitecoreDictionary.SubscriptionsAndCards.NumberOfUnitsLeft)
        .toString()
        .replace('{unitsLeft}', this.props.data.AntalAaterstaaendeKlipp.toString())}
        ${'\n'}${(this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidUntil) as string).replace(
        '{0}',
        formatDate(this.props.data.Slutdatum as Date)
      )}`;
    } else if (this.props.data && this.props.data.TraeningskortStatus) {
      if (this.props.data.TraeningskortStatus === 'TERMINATED') {
        if (!!this.props.data.Slutdatum && moment(this.props.data.Slutdatum).isAfter(moment.now())) {
          return (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidUntil) as string).replace(
            '{0}',
            formatDate(this.props.data.Slutdatum)
          );
        }
        return this.props.translate(SitecoreDictionary.SubscriptionsAndCards.Terminated) as string;
      }
    }
    if (this.isAktiv() && this.props.data) {
      let text = '';
      const isAutogiroProduct = this.props.data.PaymentMethod === ProductPaymentMethod.AutoGiro;
      if (isAutogiroProduct) {
        if (!!this.props.data.Startdatum && moment(this.props.data.Startdatum).isAfter(moment.now())) {
          text = (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidFrom) as string).replace(
            '{0}',
            formatDate(this.props.data.Startdatum)
          );
        } else {
          text = this.props.translate(SitecoreDictionary.SubscriptionsAndCards.Valid) as string;
        }
        text += !!this.props.data.Bindningstid
          ? ' ' +
            (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.BoundUntil) as string).replace(
              '{0}',
              formatDate(this.props.data.Bindningstid)
            )
          : '';
      } else {
        if (!!this.props.data.Startdatum && !!this.props.data.Slutdatum) {
          text = (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidBetween) as string)
            .replace('{0}', formatDate(this.props.data.Startdatum))
            .replace('{1}', formatDate(this.props.data.Slutdatum));
        } else if (!!this.props.data.Startdatum) {
          text = (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidFrom) as string).replace(
            '{0}',
            formatDate(this.props.data.Startdatum)
          );
        } else if (!!this.props.data.Slutdatum) {
          text = (this.props.translate(SitecoreDictionary.SubscriptionsAndCards.ValidUntil) as string).replace(
            '{0}',
            formatDate(this.props.data.Slutdatum)
          );
        }
      }
      return text;
    }

    return this.props.translate(SitecoreDictionary.SubscriptionsAndCards.CardHasExpired) as string;
  }

  terminateCard() {
    if (this.props.terminate && this.props.data) {
      this.props.terminate({
        foereningsId: 1,
        abonnemangId: this.props.data.Id,
        productId: this.props.data.ProductId,
      });
    }
    this.setState({ ...this.state, userIsInformed: false });
    this.hideConfirmation();
  }

  getVisibleStatus() {
    if (this.state.showStatusMessage && this.props.data) {
      return true;
    }
    return false;
  }

  showConfirmation() {
    this.setState({
      showConfirmation: !this.state.showConfirmation,
    });
  }

  hideConfirmation() {
    this.setState({
      showConfirmation: false,
    });
  }

  getStatusMessage() {
    if (this.props.data && this.props.data.StatusMessage) {
      return this.props.data.StatusMessage;
    } else if (this.props.errorOnBuy) {
      return SitecoreDictionary.SubscriptionsAndCards.ProductNotFound as string;
    }
    return SitecoreDictionary.UnknownError;
  }

  getStatusMessageClass() {
    if (this.props.data && this.props.data.StatusMessage === 'OK') {
      return this.okStatusClass;
    } else {
      return this.errorStatusClass;
    }
  }

  getCardActions() {
    const actions: JSX.Element[] = [];
    if (this.props.data && !!this.props.shopActive) {
      const extendButton = (
        <MainButton
          key="extend"
          buttonStyle="outline"
          color="white"
          title={
            this.props.data.AntalAaterstaaendeKlipp !== undefined && this.props.data.AntalAaterstaaendeKlipp !== null
              ? this.props.translate(SitecoreDictionary.SubscriptionsAndCards.BuyMore).toString()
              : this.props.translate(SitecoreDictionary.SubscriptionsAndCards.Extend).toString()
          }
          handleOnClick={() => (this.props.buy && this.props.data ? this.props.buy(this.props.data) : null)}
        />
      );
      const terminateButton = (
        <IconButton styleType="--TerminateTrainingcard" text="" handleOnClick={() => this.showConfirmation()} />
      );
      if (this.props.data.IsRenewable || this.props.data.PaymentMethod !== ProductPaymentMethod.AutoGiro) {
        actions.push(extendButton);
      }
      if (this.props.data.IsTerminateEnabled) {
        actions.push(terminateButton);
      }
    }
    return actions;
  }

  render() {
    if (!!this.props.data) {
      return (
        <div className="TrainingCards__TrainingCard" key={this.props.data.Id}>
          <div className="SubscriptionsAndTrainingCard__Row">
            <div className="SubscriptionsAndTrainingCard__Row__Info">
              <p className={'SubscriptionsAndTrainingCard__Row__Title'}>{this.props.data.Namn}</p>
              <pre className={'SubscriptionsAndTrainingCard__Row__Details' + (this.shouldWarn() ? ' Warning' : '')}>
                {this.getCardDetailsText()}
              </pre>
            </div>
            <div className="SubscriptionsAndTrainingCard__Row__Actions">{this.getCardActions()}</div>
          </div>
          {this.state.showConfirmation && (
            <ConfirmationTextBox
              handleNo={this.hideConfirmation}
              translate={this.props.translate}
              handleYes={this.terminateCard}
              text={this.confirmationText.toString()}
            />
          )}

          <StatusBar
            visible={this.getVisibleStatus()}
            message={this.props.translate(this.getStatusMessage()).toString()}
            cssClass={this.getStatusMessageClass()}
          />
        </div>
      );
    } else {
      return (
        <div className="SubscriptionsAndTrainingCard__Row Skeleton">
          <div className="SubscriptionsAndTrainingCard__Row__Info">
            <p className="SubscriptionsAndTrainingCard__Row__Title" />
            <p className="SubscriptionsAndTrainingCard__Row__Details" />
          </div>
          <div className="SubscriptionsAndTrainingCard__Row__Actions" />
        </div>
      );
    }
  }
}
