import * as React from 'react';
import close from 'static/assets/converted-to-components/icon-big-close.svg';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as LocationAction } from 'store/location/locationActions';
import { FoereningarListContainer } from 'components/foereningarList';
import { EmailInput, MainButton } from 'components/common';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface RequestPasswordResetActionProps {
  requestResetPasswordLink: (info: fos.api.PasswordResetInfo) => void;
  clearPasswordResetState: () => void;
}

export interface RequestPasswordResetDataProps {
  passwordReseted?: boolean;
  passwordResetResult?: boolean;
  translate: TranslateFunction;
}

type RequestPasswordResetProps = RequestPasswordResetDataProps & RequestPasswordResetActionProps;

interface RequestPasswordResetState {
  email: string;
  emailIsValid?: boolean;
  foereningId: number;
}

export class RequestPasswordReset extends React.Component<RequestPasswordResetProps, RequestPasswordResetState> {
  constructor(props: RequestPasswordResetProps) {
    super(props);
    this.state = {
      email: '',
      foereningId: -1,
    };
  }

  render() {
    return (
      <div className="Reset">
        <div className="Reset__Header">
          <div className="Header__Icon">
            <FancyNavLink to={RouteMap[LocationAction.Login]}>
              <img src={close} alt="Stäng" />
            </FancyNavLink>
          </div>
        </div>
        <div className="Reset__Title">
          <h2>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.Help)}</h2>
        </div>
        <div className="Reset__Info">
          <div className="Info__Text">
            <b>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.WhoCanUseQuestion)}</b>
            <p>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.WhoCanUseAnswer)}</p>
          </div>
          <div className="Info__Text">
            <b>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.HowToLoginQuestion)}</b>
            <p>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.HowToLoginAnswer)}</p>
          </div>
          <div className="Info__Text">
            <b>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.IForgotMyPassTitle)}</b>
            <p>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.IForgotMyPassDesc)}</p>
          </div>
        </div>

        <div className="Reset__Form">
          <form onSubmit={event => this.reset(event)}>
            <div className="Form__Epost">
              <EmailInput
                shouldValidate={true}
                alignLabel="left"
                label={this.props.translate(SitecoreDictionary.RequestResetPasswordPage.Email) as string}
                labelColor="black"
                isValid={this.state.emailIsValid}
                type="email"
                placeholder={
                  this.props.translate(SitecoreDictionary.RequestResetPasswordPage.EmailPlaceholder) as string
                }
                name="epost"
                value={this.state.email}
                onValueChange={value => this.handleEmailChange(value as string)}
                onValidChanged={isValid => this.handleEmailIsValidChange(isValid)}
              />
            </div>
            <FoereningarListContainer
              labelColor="black"
              onChange={foreningId => this.handleFoereningChange(foreningId)}
              value={this.state.foereningId}
            />
            {this.getResultMessage()}
            <div className={'Form__Submit ' + (this.props.passwordReseted ? 'Form__Submit--disabled' : '')}>
              <MainButton
                className="Submit__Button"
                type="submit"
                disabled={this.shouldDisableSubmit()}
                color="red"
                title={this.props.translate(SitecoreDictionary.RequestResetPasswordPage.SubmitButton) as string}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  private handleEmailIsValidChange(emailIsValid: boolean): void {
    this.setState({ emailIsValid });
  }

  private handleEmailChange(email: string): void {
    this.clearPasswordResetState();
    this.setState({ email });
  }

  private handleFoereningChange(foereningId: number): void {
    this.clearPasswordResetState();
    this.setState({ foereningId });
  }

  private clearPasswordResetState(): void {
    if (this.props.passwordReseted !== undefined) {
      this.props.clearPasswordResetState();
    }
  }

  private shouldDisableSubmit(): boolean {
    if (!this.state.emailIsValid || this.state.foereningId < 1) {
      return true;
    } else {
      return false;
    }
  }

  private reset(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    this.props.requestResetPasswordLink({
      Email: this.state.email,
      FoereningId: this.state.foereningId,
    });
  }

  private getResultMessage(): JSX.Element | undefined {
    if (this.props.passwordReseted) {
      if (this.props.passwordResetResult) {
        return (
          <div className="Info__Text Valid">
            <b>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.ResultSucceed)}</b>
          </div>
        );
      } else {
        return (
          <div className="Info__Text Error">
            <b>{this.props.translate(SitecoreDictionary.RequestResetPasswordPage.ResultFailed)}</b>
          </div>
        );
      }
    }
    return;
  }
}
