import * as React from 'react';

export interface PassItemSkeletonProps {
  maxAmountOfPassItemsPerDay: number;
}

export class PassItemSkeleton extends React.Component<PassItemSkeletonProps> {
  getNumberOfDays = () => {
    let numberOfDays;

    if (this.props.maxAmountOfPassItemsPerDay > 0) {
      numberOfDays = this.props.maxAmountOfPassItemsPerDay;
    } else {
      numberOfDays = 3;
    }

    return numberOfDays;
  };

  render() {
    return Array.from({ length: this.getNumberOfDays() }, (_, num) => (
      <div className="PassItem--FullView" key={num}>
        <div className="PassItem">
          <div className="PassItem__Time">
            <div aria-current="false">
              <p className="Time__StartTime" />
              <p className="Time__Duration" />
            </div>
          </div>

          <div className="PassItem__Info">
            <div aria-current="false">
              <div className="Info__PassNameAndExtraInfo">
                <div className="PassNameAndExtraInfo__Container">
                  {/* eslint-disable jsx-a11y/heading-has-content */}
                  <h2 className="PassNameAndExtraInfo__PassName" />
                </div>
              </div>
              <p className="Info__InstructorName" />
              <p className="Info__FacilityNameAndLocale" />
            </div>
          </div>

          <div className="PassItemButton animateSkeleton" />
        </div>
      </div>
    ));
  }
}
