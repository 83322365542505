import * as React from 'react';
import { IconMenuMembership, IconChevron, IconMenuNews, IconBookingStatus } from '../icons';
import { NavLink } from 'redux-first-router-link';

export interface NotificationProps {
  notification: fos.api.Notification;
}

export enum NotificationTypes {
  PASS_CANCELLED = 'PASS_CANCELLED',
  PASS_VAENTELISTA_FAATT_PLATS = 'PASS_VAENTELISTA_FAATT_PLATS',
  NEWS = 'NEWS',
  TRAENINGSKORT_EXPIRE = 'TRAENINGSKORT_EXPIRE',
}

export class Notification extends React.Component<NotificationProps> {

  getIcon() {
    if (this.props.notification.NotificationType === NotificationTypes.NEWS) {
      return <IconMenuNews />;
    } else if (this.props.notification.NotificationType === NotificationTypes.PASS_CANCELLED) {
      return <IconBookingStatus extraClassName="--Cancelled" />;
    } else if (this.props.notification.NotificationType === NotificationTypes.PASS_VAENTELISTA_FAATT_PLATS) {
      return <IconBookingStatus extraClassName="--Booked" />;
    } else if (this.props.notification.NotificationType === NotificationTypes.TRAENINGSKORT_EXPIRE) {
      return <IconMenuMembership />;
    }
    return;
  }

  getClassNames() {
    let classModifiers = '';
    let baseClass = 'Notification';

    if (!this.props.notification.Read) {
      classModifiers += ' Notification--unread';
    }
    if (this.props.notification.Link) {
      classModifiers += ' Notification--clickable';
    }

    return baseClass + classModifiers;
  }

  getShortText() {
    let text = '';
    let maxStr = 100;
    if (this.props.notification.Text.length > maxStr) {
      text = this.props.notification.Text.substring(0, maxStr);
      text += '...';
    } else {
      text = this.props.notification.Text;
    }

    return text;
  }

  handleOnClick(e: React.MouseEvent<HTMLElement>, props: NotificationProps) {
    e.preventDefault();
    this.openUrl();
  }
  render() {
    if (this.props.notification.Link) {
      return (
        <div className={this.getClassNames()}>
          {this.props.notification.Link && this.props.notification.Link.startsWith('http') && (
            <a className="FancyNavLink" href={this.props.notification.Link} target="_blank" rel="noopener noreferrer">
              <div className="Notification__Icon">{this.getIcon()}</div>
              <div className="Notification__Information">
                <div className="h5">{this.props.notification.Rubrik}</div>
                <p>{this.getShortText()}</p>
              </div>
              <div className="Notification__Arrow">
                <IconChevron direction="right" color="red" />
              </div>
            </a>
          )}
          {this.props.notification.Link && !this.props.notification.Link.startsWith('http') && (
            <NavLink
              className="FancyNavLink"
              onClick={e => this.handleOnClick(e, this.props)}
              to={this.props.notification.Link}
            >
              <div className="Notification__Icon">{this.getIcon()}</div>
              <div className="Notification__Information">
                <div className="h5">{this.props.notification.Rubrik}</div>
                <p>{this.getShortText()}</p>
              </div>
              <div className="Notification__Arrow">
                <IconChevron direction="right" color="red" />
              </div>
            </NavLink>
          )}
        </div>
      );
    }
    return (
      <div className={this.getClassNames()}>
        <div className="Notification__Icon">{this.getIcon()}</div>
        <div className="Notification__Information">
          <div className="h5">{this.props.notification.Rubrik}</div>
          <p>{this.getShortText()}</p>
        </div>
      </div>
    );
  }

  private openUrl() {
    window.open(this.props.notification.Link, '_blank');
  }
}
