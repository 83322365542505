import * as React from 'react';

export interface IconMenuSettingsProps {
  title?: string;
  color?: string;
}

export class IconMenuSettings extends React.Component<IconMenuSettingsProps, {}> {
  fillColour = '#000';

  random = 1 + Math.random() * (1000 - 1);
  rand = 'rand-' + Math.round(this.random);
  randD = '.rand-' + Math.round(this.random);

  // TODO: Fix translation support for text
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className={'IconMenuInstallningar ' + this.rand}>
        <title>{this.props.title}</title>
        <style type="text/css">
          {`
          .IconMenuInstallningar${this.randD} {
              width: 55px;
              height: 55px;
          }
          .IconMenuInstallningar${this.randD} .cls-1{
            fill: none;
            stroke-width: 0.8;
            stroke: ${this.props.color ? this.props.color : '#343434'};
            stroke-miterlimit: 10
          }
          `}
        </style>

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect
              className="cls-1"
              x="25.08"
              y="20.73"
              width="2.66"
              height="11.89"
              rx="1.33"
              ry="1.33"
              transform="rotate(45 26.40917964 26.67904038)"
            />
            <path
              className="cls-1"
              d="M24.68 30.28a.94.94 0 0 0 .1 1.31l.48.48a1 1 0 0 0 1.31.1l5.33-5.34a.93.93 0 0 0-.1-1.31l-.47-.47A.93.93 0 0 0 30 25"
            />
            <path fill="none" d="M0 0h40v40H0z" />
            <rect
              className="cls-1"
              x="11.73"
              y="7.38"
              width="2.66"
              height="11.89"
              rx="1.33"
              ry="1.33"
              transform="rotate(-135 13.06327486 13.32246466)"
            />
            {/* tslint:disable-next-line:max-line-length */}
            <path
              className="cls-1"
              d="M14.79 9.72a.94.94 0 0 0-.1-1.31l-.48-.48a.93.93 0 0 0-1.3-.1l-5.34 5.34a.94.94 0 0 0 .1 1.31l.48.47a.92.92 0 0 0 1.3.1m8.44 1.87l-3.27-3.27-1.23 1.23 3.24 3.24m5.03 5.03l3.2 3.2 1.22-1.23-3.17-3.18m3.44-10.06l2.3-2.33a3.34 3.34 0 0 0-4.64 4l-13.1 12.5A2.06 2.06 0 1 0 13.85 29l12.36-13.26a3.33 3.33 0 0 0 3.95-4.69l-2.3 2.33a.49.49 0 0 1-.69 0l-.82-.81a.49.49 0 0 1 0-.69z"
            />
            <path
              className="cls-1"
              d="M12.26 28.25a1.22 1.22 0 0 1 0-1.73"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#343434"
              fill="none"
            />
          </g>
        </g>
      </svg>
    );
  }
}
