import { ProductHows, ProductWhens, ProductWhos, ShopFilter } from './shopModels';
import { ShopFilterCategoryNames } from './shopModels';

export enum ShopActionType {
  Fetch_Products = 'FETCH_PRODUCTS',
  Fetch_FilterCategories = 'FETCH_FILTERCATEGORIES',
  Shop_FilterProductsUsingFilterOptions = 'SHOP:_FILTER_PRODUCTS_USING_FILTER_OPTIONS',
  Shop_UpdateUserSelectedFilterOptions = 'SHOP:_UPDATE_FILTER_OPTIONS',
  Shop_UpdateUserSelectedFilterOptionsProperty = 'SHOP:_UPDATE_FILTER_OPTIONS_PROPERTY',
  Shop_ClearUserSelectedFilterOptionsProperty = 'SHOP:_CLEAR_FILTER_OPTIONS_PROPERTY',
  Shop_SetProductFilterWithPristineUnfilteredProducts = 'SHOP:_SET_PRODUCT_FILTER_WITH_PRISTINE_UNFILTERED_PRODUCTS',
  Shop_ResetProductsFilter = 'SHOP:_RESET_USER_FILTER_OPTIONS',
}

export interface FetchProducts {
  type: ShopActionType.Fetch_Products;
  products: Array<fos.api.Product>;
}

export interface FetchFilters {
  type: ShopActionType.Fetch_FilterCategories;
  filter: Array<fos.api.FilterCategory>;
}

export interface ShopActionFilterProductsUsingFilterOptions {
  type: ShopActionType.Shop_FilterProductsUsingFilterOptions;
  unfilteredProducts: fos.api.Product[];
  shopFilterOptions: ShopFilter;
}

export interface ShopActionUpdateUserSelectedFilterOptions {
  type: ShopActionType.Shop_UpdateUserSelectedFilterOptions;
  shopFilterOptions: ShopFilter;
}

export interface ShopActionUpdateFilterOptionsCategory {
  type: ShopActionType.Shop_UpdateUserSelectedFilterOptionsProperty;
  category: ShopFilterCategoryNames;
  propertyValue: string[] | ProductWhos[] | ProductWhens[] | ProductHows[];
}

export interface ShopActionClearFilterOptionsCategory {
  type: ShopActionType.Shop_ClearUserSelectedFilterOptionsProperty;
  category: ShopFilterCategoryNames;
}

export interface ShopActionSetProductFilterWithPristineUnfilteredProducts {
  type: ShopActionType.Shop_SetProductFilterWithPristineUnfilteredProducts;
  unfilteredProducts: fos.api.Product[];
}

export interface ShopActionResetProductsFilter {
  type: ShopActionType.Shop_ResetProductsFilter;
}

export const fetchProducts = (products: Array<fos.api.Product>): FetchProducts => ({
  type: ShopActionType.Fetch_Products,
  products,
});

export const fetchFilters = (filter: Array<fos.api.FilterCategory>): FetchFilters => ({
  type: ShopActionType.Fetch_FilterCategories,
  filter,
});

export type ShopAction =
  | FetchProducts
  | FetchFilters
  | ShopActionFilterProductsUsingFilterOptions
  | ShopActionUpdateUserSelectedFilterOptions
  | ShopActionUpdateFilterOptionsCategory
  | ShopActionClearFilterOptionsCategory
  | ShopActionSetProductFilterWithPristineUnfilteredProducts
  | ShopActionResetProductsFilter;
