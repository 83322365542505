import * as React from 'react';
import { IconChevron } from 'components/icons';

export interface ProductMobileHeaderProps {
  title: string;
  isExpanded: boolean;
  price: number;

  handleToggleProduct: () => void;
}

export const ProductMobileHeader: React.SFC<ProductMobileHeaderProps> = props => (
  <div
    className={!props.isExpanded ? 'ProductMobileHeader' : 'ProductMobileHeader ProductMobileHeader--Red'}
    onClick={() => props.handleToggleProduct()}
  >
    <div className="ProductMobileHeader__Title">{props.title}</div>
    <div className="ProductMobileHeader__Price">{props.price}:–</div>
    <div className="ProductMobileHeader__ExpandIcon">
      <IconChevron direction={props.isExpanded ? 'top' : 'bottom'} />
    </div>
  </div>
);
