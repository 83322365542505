import {
  removeFavoriteAnlaeggningar,
  addFavoriteAnlaeggning,
  removeFavoriteAnlaeggning,
} from 'store/trainingpreferences/write/trainingPreferencesWriteActions';
import {
  deleteSavedSpecialintresse,
  saveFavoriteAnlaeggningar,
  getSavedAnlaeggningar,
  deleteSavedAnlaeggning,
} from 'store/trainingpreferences/thunks';
import {
  removeChildAgeGroup,
  addChildAgeGroup,
  removeWorkoutLevel,
  addWorkoutLevel,
  removeSpecialintressen,
} from 'store/trainingpreferences/write/trainingPreferencesWriteActions';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import {
  TrainingPreferences,
  TrainingPreferencesDataProps,
  TrainingPreferencesActionProps,
} from './TrainingPreferences';
import { getSitecoreAnlaeggningar } from 'store/anlaeggningar';
import { getActiveFoerening } from 'utils';
import { getSpecialIntressen, saveSpecialIntressen, getSavedSpecialIntressen } from 'store/trainingpreferences';
import { getTranslate } from 'store/global/dictionary';

const mapStateToProps = (state: RootState): TrainingPreferencesDataProps => {
  return {
    user: state.user.read.user,
    savedSpecialintressen: state.trainingPreferences.read.savedSpecialintressen,
    savedAnlaeggningar: state.trainingPreferences.read.anlaeggningar,
    anlaeggningar: state.anlaeggningar.read.sitecoreAnlaeggningar,
    activeFoerning: getActiveFoerening(state.global.session.inloggadeFoereningar, state.global.session.activeFoerening),
    workoutLevels: state.trainingPreferences.read.workoutlevels,
    childrenAgeGroups: state.trainingPreferences.read.childrenAgeGroups,
    translate: getTranslate(state.locale),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): TrainingPreferencesActionProps => ({
  getSitecoreAnlaeggningar: (foereningNamn: string, foereningId: number, countryId: number) =>
    dispatch(getSitecoreAnlaeggningar(foereningNamn, foereningId, countryId)),
  getSpecialIntressen: () => dispatch(getSpecialIntressen()),
  removeWorkoutLevel: (name: string) => dispatch(removeWorkoutLevel(name)),
  addWorkoutLevel: (name: string) => dispatch(addWorkoutLevel(name)),
  removeChildAgeGroup: (name: string) => dispatch(removeChildAgeGroup(name)),
  addChildAgeGroup: (name: string) => dispatch(addChildAgeGroup(name)),
  saveSpecialIntressen: () => dispatch(saveSpecialIntressen()),
  removeSpecialintressen: () => dispatch(removeSpecialintressen()),
  getSavedSpecialIntressen: () => dispatch(getSavedSpecialIntressen()),
  removeSavedSpecialintresse: (specialIntresse: fos.api.SavedSpecialintresse) =>
    dispatch(deleteSavedSpecialintresse(specialIntresse)),
  removeFavoriteAnlaeggningar: () => dispatch(removeFavoriteAnlaeggningar()),
  removeFavoriteAnlaeggning: (anlaeggning: string) => dispatch(removeFavoriteAnlaeggning(anlaeggning)),
  removeSavedFavoriteAnlaeggning: (specialIntresse: fos.api.SavedSpecialintresse) =>
    dispatch(deleteSavedAnlaeggning(specialIntresse)),
  addFavoriteAnlaeggning: (anlaeggning: string) => dispatch(addFavoriteAnlaeggning(anlaeggning)),
  saveFavoriteAnlaeggningar: () => dispatch(saveFavoriteAnlaeggningar()),
  getSavedAnlaeggningar: () => dispatch(getSavedAnlaeggningar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingPreferences);
