import { combineReducers } from 'redux';
import { readReducer, FoereningarReadState, FoereningarReadAction } from './read';

export type FoereningarAction = FoereningarReadAction;

export interface FoereningarRootState {
  read: FoereningarReadState;
}

export const FoereningarReducer = combineReducers<FoereningarRootState>({
  read: readReducer,
});
