import * as React from 'react';
import { MainInput } from '.';
import { SitecoreDictionary } from 'models';

import { LocationState } from 'store/location';
import { TranslateFunction } from 'react-localize-redux';

interface NewPasswordDataProps {
  className?: string;
  translate: TranslateFunction;
  passwordValue: string;
  repeatPasswordValue: string;
  isPasswordValid: boolean;
  isRepeatPasswordValid: boolean;
  alignText?: 'left' | 'center';
  disabled?: boolean;
  location: LocationState;
}

interface NewPasswordActionProps {
  updatePassword: (value: string) => void;
  updateRepeatPassword: (value: string) => void;
  updatePasswordValidity: (isValid: boolean) => void;
  updateRepeatPasswordValidity: (isValid: boolean) => void;
}

interface NewPasswordState {
  isAnlaeggningarOverlayOpen: boolean;
}

export type NewPasswordProps = NewPasswordDataProps & NewPasswordActionProps;

export class NewPassword extends React.Component<NewPasswordProps, NewPasswordState> {
  render() {
    return (
      <div className={this.getContainerClassName()}>
        <MainInput
          required={true}
          shouldValidate={true}
          label={this.props.translate(SitecoreDictionary.Common.NewPasswordField.Password) as string}
          placeholder={this.props.translate(SitecoreDictionary.Common.NewPasswordField.PasswordPlaceholder) as string}
          alignLabel="left"
          align={this.props.alignText || 'left'}
          labelColor="black"
          type="password"
          border="grey"
          value={this.props.passwordValue}
          onValueChange={val => this.onPasswordChange(val as string)}
          isValid={this.props.isPasswordValid}
          disabled={this.props.disabled}
          infoText={
            !this.props.isPasswordValid && !!this.props.passwordValue && this.props.passwordValue !== ''
              ? (this.props.translate(SitecoreDictionary.Common.NewPasswordField.NewPasswordRules) as string)
              : undefined
          }
          infoType="error"
          autocomplete="off"
        />

        <MainInput
          required={true}
          shouldValidate={true}
          label={this.props.translate(SitecoreDictionary.Common.NewPasswordField.RepeatPassword) as string}
          placeholder={this.props.translate(SitecoreDictionary.Common.NewPasswordField.PasswordPlaceholder) as string}
          alignLabel="left"
          align={this.props.alignText || 'left'}
          labelColor="black"
          type="password"
          border="grey"
          value={this.props.repeatPasswordValue}
          onValueChange={val => this.onRepeatPasswordChange(val as string)}
          isValid={this.props.isRepeatPasswordValid}
          disabled={this.props.disabled}
          autocomplete="off"
        />
      </div>
    );
  }

  private onPasswordChange(value: string) {
    this.props.updatePassword(value as string);
    this.validateInput(value, this.props.repeatPasswordValue);
  }

  private onRepeatPasswordChange(value: string) {
    this.props.updateRepeatPassword(value as string);
    this.validateInput(this.props.passwordValue, value);
  }

  private validateInput(password?: string, repeatPassword?: string) {
    const isPasswordValid = this.isPasswordValid(password);
    this.props.updatePasswordValidity(isPasswordValid);
    const isRepeatPasswordValid = this.isRepeatedPasswordValid(repeatPassword, password);
    this.props.updateRepeatPasswordValidity(isRepeatPasswordValid);
  }

  private isPasswordValid(value?: string) {
    return !!value && value.length > 5 && /\d/.test(value);
  }

  private isRepeatedPasswordValid(value?: string, password?: string) {
    return !!value && !!password && this.isPasswordValid(password) && value === password;
  }

  private getContainerClassName() {
    return 'NewPasswordContainer ' + (this.props.className ? this.props.className : '');
  }
}
