import * as React from 'react';

export enum IconHeartStyling {
  filled = 'filled',
  empty = 'empty',
  disabled = 'disabled',
}
export type IconHeartStylingTypes = 'filled' | 'empty' | 'disabled';

export interface IconHeartProps {
  styling?: IconHeartStylingTypes;
  title?: string;
  desc?: string;
}

export class IconHeart extends React.Component<IconHeartProps, {}> {
  // TODO: Fix translation support for text

  getClass(styling?: IconHeartStylingTypes) {
    if (!styling) {
      return IconHeartStyling.filled;
    }

    switch (styling) {
      case IconHeartStyling.filled:
        return IconHeartStyling.filled;
      case IconHeartStyling.empty:
        return IconHeartStyling.empty;
      case IconHeartStyling.disabled:
        return IconHeartStyling.disabled;
      default:
        return IconHeartStyling.filled;
    }
  }

  render() {
    const styling = this.getClass(this.props.styling);

      /* tslint:disable */
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" className="IconHeart">
        <title>{this.props.title}</title>
        <style type="text/css">
          {`.IconHeart{
                width: 33px;
                height: 33px;
            }
            .IconHeart .filled{
                fill:#E31836;
             }          
            .IconHeart .disabled {
              fill:#a6a6a6	
            }
            .IconHeart .empty{
                fill:none;
                stroke:#b1b3b3;
            }
            `}
        </style>
        <desc>{this.props.desc}</desc>
        <path
          className={styling}
          d="M33 54.8l-0.7-0.6c-1.3-1.1-3.2-2.3-5.3-3.7C18.8 45.1 7.6 37.7 7.6 25c0-7.8 6.4-14.2 14.4-14.2 4.3 0 8.3 1.9 11.1 5.1 2.7-3.2 6.8-5.1 11.1-5.1 7.9 0 14.4 6.4 14.4 14.2 0 12.7-11.2 20.1-19.5 25.5 -2.1 1.4-3.9 2.6-5.3 3.7L33 54.8z"
        />
      </svg>
    );
  }
}
