export enum ProductPaymentMethod {
  Card = 'Card',
  AutoGiro = 'AutoGiro',
}

export enum PaymentMethodType {
  BANK = 'BANK',
  CARD = 'CARD',
  SWISH = 'SWISH',
  UNKNOWN = 'UNKNOWN',
}
