import * as React from 'react';
import { Slots } from './Slots';
import { Info } from './Info';
import BookingRules from 'components/bookingRules';
import { SitecoreDictionary } from 'models';

import { IconMessage, IconDrop, IconInformation } from 'components/icons';
import { TranslateFunction } from 'react-localize-redux';

interface InfoBlockProps {
  translate: TranslateFunction;
  isDesktopMode: boolean;
  pass: fos.api.PassDetails;
  bokningar: fos.api.Bokning[];
}

export const PassDetailsInfoBlock: React.FunctionComponent<InfoBlockProps> = ({
  translate,
  isDesktopMode,
  pass,
  bokningar,
}) => (
  <div className="InfoBlock">
    <Slots pass={pass} translate={translate} bokningar={bokningar} />
    <Info
      icon={<IconDrop />}
      header={pass.AntalDropinPlatser + ' ' + (translate(SitecoreDictionary.PassDetails.XDropsAvailable) as string)}
      infoText={translate(SitecoreDictionary.PassDetails.DropInRule) as string}
    />
    {pass.ExtraInfo && <Info icon={<IconInformation />} header={pass.ExtraInfo} />}
    <Info icon={<IconMessage />} header={<BookingRules isDesktopMode={isDesktopMode} />} textColor="#e31836" />
  </div>
);
