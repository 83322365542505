import { Reducer } from 'redux';
import { Action } from 'store';
import { MainMenuActionTypes, MainMenuActiveItem } from '.';

export interface MainMenuState {
  menuShowing: MainMenuActiveItem;
}

const initialState: MainMenuState = {
  menuShowing: MainMenuActiveItem.NONE,
};

const getMenuSettingBasedOnCurrenlyShowingMenu = (stateValue: MainMenuActiveItem, actionValue: MainMenuActiveItem) => {
  if (stateValue === actionValue) {
    return MainMenuActiveItem.NONE;
  } else {
    return actionValue;
  }
};

export const mainMenuReducer: Reducer<MainMenuState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case MainMenuActionTypes.SHOW_MENU: {
      const menuValue = getMenuSettingBasedOnCurrenlyShowingMenu(state.menuShowing, action.menuShowing);

      return { ...state, menuShowing: menuValue };
    }
    case MainMenuActionTypes.CLOSE_MENU: {
      return {
        ...state,
        menuShowing: MainMenuActiveItem.NONE,
      };
    }
    default:
      const mainMenuIsOpen = state.menuShowing.length > 0 ? true : false;

      if (mainMenuIsOpen) {
        return { ...state };
      } else {
        return {
          ...state,
          menuShowing: MainMenuActiveItem.NONE,
        };
      }
  }
};
