import { FetchState } from '.';

import {
  ContextType,
  FetchStatusAggregateTypes,
  FetchCommandTypes,
  ContextTypes,
  EntityStatus,
  CommandStatus,
} from './fetchModels';

export const getEntityStatus = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): EntityStatus => {
  const fetchStatus = state.find(
    s => s.id === id && s.contextType === contextType && s.aggregateType === aggregateType
  );

  return (fetchStatus && fetchStatus.status) as EntityStatus;
};

export const getCommandStatus = (
  state: FetchState,
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): CommandStatus => {
  const fetchStatus = state.find(s => s.id === id && s.contextType === contextType && s.commandType === commandType);
  return (fetchStatus && fetchStatus.status) as CommandStatus;
};

export const getIsFetching = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.FETCHING;

export const getIsFetchingInBackgound = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.FETCHING_IN_BACKGROUND;

export const getIsFetched = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.SUCCESS;

export const getHasLoadFailed = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.FAILED;

export const getIsSaving = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.SAVING;

export const getHasSaveFailed = (
  state: FetchState,
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => getEntityStatus(state, id, aggregateType, contextType) === EntityStatus.SAVING_FAILED;

export const getIsExecuting = (
  state: FetchState,
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => {
  return getCommandStatus(state, id, commandType, contextType) === CommandStatus.EXECUTING;
};

export const getIsExecuted = (
  state: FetchState,
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => {
  return getCommandStatus(state, id, commandType, contextType) === CommandStatus.EXECUTED;
};

export const getIsExecutingFailed = (
  state: FetchState,
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
) => {
  return getCommandStatus(state, id, commandType, contextType) === CommandStatus.EXECUTING_FAILED;
};
