import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import {
  PassFilterOverlayMessageDataProps,
  PassFilterOverlayMessageActionProps,
  PassFilterOverlayMessage,
  PassFilterOverlayMessageExternalDataProps,
} from './PassFilterOverlayMessage';
import {
  isFiltervalGrupp,
  passFilterVisaFilter,
  passFilterSetKategoriVald,
  PassFilterKategorier,
} from 'store/passFilter';

const mapState = (
  state: RootState,
  props: PassFilterOverlayMessageExternalDataProps
): PassFilterOverlayMessageDataProps => {
  const harPass = state.passFilter.filteredPassIds.map(ids => ids.passIds.length).some(n => n !== 0);
  const traeningsstaelleFilter = state.passFilter.filter.Traeningsstaelle;
  const skaVisaTraeningsstaelleMeddelande =
    !!traeningsstaelleFilter &&
    traeningsstaelleFilter.vaerden.length > 3 &&
    !traeningsstaelleFilter.vaerden.some(v => {
      if (isFiltervalGrupp(v)) {
        return v.filterval.some(fv => fv.vald);
      } else {
        return v.vald;
      }
    });
  return {
    visaIngaTraeffarMeddelande: !harPass,
    visaTraeningsstaelleMeddelande: skaVisaTraeningsstaelleMeddelande,
    isFullDesktopView: props.isFullDesktopView,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: any): PassFilterOverlayMessageActionProps => ({
  visaFilter: () => {
    dispatch(passFilterVisaFilter(true));
  },
  vaeljKategori: (kategori: keyof typeof PassFilterKategorier) => {
    dispatch(passFilterSetKategoriVald(kategori, true));
  },
});

export default connect(mapState, mapDispatchToProps)(PassFilterOverlayMessage);
