import * as React from 'react';
import { TranslateFunction } from 'react-localize-redux';

import { ApiError } from 'store/global/errors';

const ErrorBubble: React.SFC<{
  error: ApiError;
  onClose: (error: ApiError) => void;
  translate: TranslateFunction;
}> = ({ error, onClose, translate }) => {
  // Please check history of this file to restore the ErrorBubble to it's former glory
  return null;
};

export default ErrorBubble;
