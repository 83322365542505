import * as React from 'react';

export interface IconFilterCalendarViewProps {
  active: boolean;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconFilterCalendarView extends React.Component<IconFilterCalendarViewProps, {}> {
  inactiveStyle = 'fill: #B6B6B6;';
  activeStyle = 'fill: #e31836;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  /* tslint:disable */
  render() {
    // TODO: Fix translation support for text
    return (
      <svg width="21" height="22" viewBox="0 0 21 22" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <style type="text/css">
          .inactive{'{' + this.inactiveStyle + '}'}
          .active{'{' + this.activeStyle + '}'}
        </style>
        <desc> </desc>
        <g fill="none">
          <g className={this.getClassForIcon()}>
            <path d="M4.4 0C4 0 3.6 0.4 3.6 0.9L3.6 1.8 0.9 1.8C0.7 1.8 0.4 1.9 0.3 2 0.1 2.2 0 2.4 0 2.7L0 20.4C0 20.7 0.1 20.9 0.3 21.1 0.4 21.2 0.7 21.3 0.9 21.3L19.5 21.3C19.8 21.3 20 21.2 20.2 21.1 20.3 20.9 20.4 20.7 20.4 20.4L20.4 2.7C20.4 2.4 20.3 2.2 20.2 2 20 1.9 19.8 1.8 19.5 1.8L16.9 1.8 16.9 0.9C16.9 0.4 16.5 0 16 0L15.1 0C14.6 0 14.2 0.4 14.2 0.9L14.2 1.8 6.2 1.8 6.2 0.9C6.2 0.4 5.8 0 5.3 0L4.4 0ZM4.4 0.9L5.3 0.9 5.3 3.6 4.4 3.6 4.4 0.9ZM15.1 0.9L16 0.9 16 3.6 15.1 3.6 15.1 0.9ZM0.9 2.7L3.6 2.7 3.6 3.6C3.6 4 4 4.4 4.4 4.4L5.3 4.4C5.8 4.4 6.2 4 6.2 3.6L6.2 2.7 14.2 2.7 14.2 3.6C14.2 4 14.6 4.4 15.1 4.4L16 4.4C16.5 4.4 16.9 4 16.9 3.6L16.9 2.7 19.5 2.7 19.5 5.8 0.9 5.8 0.9 2.7ZM0.9 6.7L19.5 6.7 19.5 20.4 0.9 20.4 0.9 6.7ZM3.6 8.4L3.6 18.7 16.9 18.7 16.9 8.4 3.6 8.4ZM4.4 9.3L6.7 9.3 6.7 11.5 4.4 11.5 4.4 9.3ZM7.5 9.3L9.8 9.3 9.8 11.5 7.5 11.5 7.5 9.3ZM10.7 9.3L12.9 9.3 12.9 11.5 10.7 11.5 10.7 9.3ZM13.8 9.3L16 9.3 16 11.5 13.8 11.5 13.8 9.3ZM4.4 12.4L6.7 12.4 6.7 14.7 4.4 14.7 4.4 12.4ZM7.5 12.4L9.8 12.4 9.8 14.7 7.5 14.7 7.5 12.4ZM10.7 12.4L12.9 12.4 12.9 14.7 10.7 14.7 10.7 12.4ZM13.8 12.4L16 12.4 16 14.7 13.8 14.7 13.8 12.4ZM4.4 15.5L6.7 15.5 6.7 17.8 4.4 17.8 4.4 15.5ZM7.5 15.5L9.8 15.5 9.8 17.8 7.5 17.8 7.5 15.5ZM10.7 15.5L12.9 15.5 12.9 17.8 10.7 17.8 10.7 15.5ZM13.8 15.5L16 15.5 16 17.8 13.8 17.8 13.8 15.5Z" />
          </g>
        </g>
      </svg>
    );
  }
}
