import * as React from 'react';

interface HeaderProps {
  header?: string;
}

export const Header: React.SFC<HeaderProps> = ({ header }) => {
  return (
    <div className="Header hide-for-medium-down">
      <h1>{header}</h1>
    </div>
  );
};
