import * as React from 'react';
import { IconMenuFriskis, IconMenuFacility, IconMenuNews, IconMenuCalendar } from 'components/icons';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as LocationAction } from 'store/location/locationActions';

import { SitecoreDictionary } from 'models';
import { MainMenuListItem } from './MainMenuListItem';
import { FoereningSwitcherItem } from './FoereningSwitcherItem';
import { TranslateFunction } from 'react-localize-redux';
import { Mobile, isApp } from 'models/app/MobileOSType';
import { IconFriskisGo } from 'components/icons/IconFriskisGo';

export interface MainMenuListWorkoutActionProps {
  openSidebarSchedule: () => void;
  showSidebar: () => void;
  showFoereningSwitcher: () => void;
  goToSchedule: () => void;
  onClick?: (val: boolean) => void;
}

export interface MainMenuListWorkoutState {
  workoutMenu?: boolean;
}

export interface MainMenuListWorkoutDataProps {
  translate: TranslateFunction;
  currentFoerening: fos.api.InloggadeFoerening;
  availableFoereningar: fos.api.InloggadeFoerening[];
  isDesktopMode: boolean;
  isTargetBlank?: string;
  getFoereningUrl: string;
  onlineTrainingUrl?: string;
  setWorkoutMenu: (val: boolean) => void;
}

export type MainMenuListWorkoutProps = MainMenuListWorkoutActionProps &
  MainMenuListWorkoutState &
  MainMenuListWorkoutDataProps;

export class MainMenuListWorkout extends React.Component<MainMenuListWorkoutProps, MainMenuListWorkoutState> {
  onScheduleClick() {
    if (this.props.isDesktopMode) {
      this.props.openSidebarSchedule();
      this.props.showSidebar();
    } else {
      this.props.goToSchedule();
    }
  }

  render() {
    return (
      <div className="MainMenuListWorkout mainMenuContent">
        <FoereningSwitcherItem
          currentFoerening={this.props.currentFoerening}
          availableFoereningar={this.props.availableFoereningar}
          showFoereningSwitcher={this.props.showFoereningSwitcher}
        />
        <FancyNavLink to={this.props.getFoereningUrl} isTargetBlank={true}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuFriskis />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Friskis)}
            onClick={() => this.props.setWorkoutMenu(true)}
          />
        </FancyNavLink>
        {isApp() && (
          <MainMenuListItem
            showIcon={true}
            icon={<IconFriskisGo />}
            text={'Friskis Go'}
            onClick={() => {
              Mobile.openFriskisGo();
            }}
          />
        )}
        <FancyNavLink to={RouteMap[LocationAction.Facilities]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuFacility />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Facilities)}
            onClick={() => this.props.setWorkoutMenu(true)}
          />
        </FancyNavLink>
        <FancyNavLink to={RouteMap[LocationAction.News]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuNews />}
            text={this.props.translate(SitecoreDictionary.MainMenu.News)}
            onClick={() => this.props.setWorkoutMenu(true)}
          />
        </FancyNavLink>
        <MainMenuListItem
          onClick={() => this.onScheduleClick()}
          showIcon={true}
          icon={<IconMenuCalendar />}
          text={this.props.translate(SitecoreDictionary.MainMenu.Schedule)}
        />
      </div>
    );
  }
}
