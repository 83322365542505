import { AnlaeggningarReadActionTypes } from '.';
import { Action } from 'store';
export interface AnlaeggningarReadState {
  anlaeggningar: Array<fos.api.Anlaeggningar>;
  sitecoreAnlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
}
let bar = [
  { Id: 1, AnlaeggningsId: 123, AnlaeggningsNamn: 'F&S Hornstull' },
  { Id: 2, AnlaeggningsId: 124, AnlaeggningsNamn: 'F&S Vasastan' },
  { Id: 3, AnlaeggningsId: 125, AnlaeggningsNamn: 'F&S Hagastan' },
  { Id: 4, AnlaeggningsId: 126, AnlaeggningsNamn: 'F&S City' },
  { Id: 5, AnlaeggningsId: 127, AnlaeggningsNamn: 'F&S Nacka' },
];

const initialState: AnlaeggningarReadState = {
  anlaeggningar: bar,
  sitecoreAnlaeggningar: [],
};

export const readReducer = (state: AnlaeggningarReadState = initialState, action: Action): AnlaeggningarReadState => {
  switch (action.type) {
    case AnlaeggningarReadActionTypes.ADD_SITECORE_ANLAEGGNINGAR:
      return { ...state, sitecoreAnlaeggningar: action.anlaeggningar };
    default:
      return { ...state };
  }
};
