import { RootState, Dispatch } from '../../../store';
import { connect } from 'react-redux';
import { setCalendarSync, setSelectedCalendar } from 'store/global/calendar';

import { CalendarSync, CalendarSyncDataProps, CalendarSyncActionProps } from './CalendarSync';
import { FetchStatusAggregateTypes, getEntityStatus, EntityStatus } from 'store/global/fetch';
import { getTranslate } from 'store/global/dictionary';

const mapState = (state: RootState): CalendarSyncDataProps => ({
  syncCalendar: state.global.calendar.syncIsActive,
  selectedCalendar: state.global.calendar.selectedCalendar,
  userId: state.global.session.userId,
  fetchCalendarStatus: !!state.global.session.userId
    ? getEntityStatus(state.global.fetch, state.global.session.userId, FetchStatusAggregateTypes.GET_CALENDAR)
    : EntityStatus.INITIAL,
  translate: getTranslate(state.locale),
});

const mapActionsToProps = (dispatch: Dispatch): CalendarSyncActionProps => ({
  setCalendarSync: (syncCalendar: boolean, userId: number) => {
    dispatch(setCalendarSync(userId, syncCalendar));
  },
  setSelectedCalendar: (id: string, userId: number) => {
    dispatch(setSelectedCalendar(userId, id));
  },
});

export default connect(mapState, mapActionsToProps)(CalendarSync);
