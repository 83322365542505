import { RootState } from 'store';
import { connect } from 'react-redux';
import { Notifications, NotificationsActionProps, NotificationsDataProps } from './Notifications';
import { getEntityStatus, FetchStatusAggregateTypes } from 'store/global/fetch';
import { fetchNotifications } from 'store/notifications';

const mapState = (state: RootState): NotificationsDataProps => ({
  notifications: state.notifications.notifications,
  fetchState: getEntityStatus(
    state.global.fetch,
    state.global.session.userId as number,
    FetchStatusAggregateTypes.NOTIFICATIONS
  ),
  newNotificationsCount: state.global.ui.newNotification.newNotificationsCount,
});

const mapDispatch: NotificationsActionProps = {
  fetchNotifications,
};

export default connect(mapState, mapDispatch)(Notifications);
