import { Reducer } from 'redux';
import { Action } from 'store';
import { UserReadActionType } from './userReadActions';

export interface UserReadState {
  user: fos.api.User;
  betalningsmedel: fos.api.Betalningsmedel;
  languageCode: string;
}

const initialState: UserReadState = {
  user: {
    Id: '',
    FirstName: '',
    LastName: '',
    Address: '',
    ZipCode: '',
    City: '',
    PhoneNumber: '',
    Email: '',
    SSN: '',
    businessUnit: {
      id: 0,
      namn: '',
    },
  },
  betalningsmedel: {
    BankkortLista: [],
    AutogiroLista: [],
    AvtalegiroLista: [],
    IsEditEnabled: false,
  },
  languageCode: 'sv',
};

let cachedAutogiroArray: fos.api.Autogiro[] = [];

export const userReadReducer: Reducer<UserReadState> = (state = initialState, action: Action) => {
  const currentUserInfo = state.user;
  switch (action.type) {
    case UserReadActionType.GET_USER:
      return { ...state, user: action.updatedUser };
    case UserReadActionType.RegisterUserId:
      return {
        ...state,
        user: {
          ...currentUserInfo,
          Id: action.userId,
        },
      };
    case UserReadActionType.GET_BETALNINGSMEDEL:
      return {
        ...state,
        betalningsmedel: action.betalningsmedel,
      };
    case UserReadActionType.GET_AUTOGIRO:
      let newState: Array<fos.api.Autogiro> = state.betalningsmedel.AutogiroLista.map(t => {
        if (t.Id !== action.autogiro.Id) {
          return t;
        } else {
          return action.autogiro;
        }
      });
      cachedAutogiroArray = newState;
      return {
        ...state,
        betalningsmedel: {
          ...state.betalningsmedel,
          AutogiroLista: cachedAutogiroArray,
        },
      };
    case UserReadActionType.GET_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: action.languageCode,
      };
    default:
      return state;
  }
};
