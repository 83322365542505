import { combineReducers } from 'redux';
import { StatisticsReadReducer, StatisticsReadActions, StatisticsReadState } from './read';

export type StatisticsActions = StatisticsReadActions;

export interface StatisticsRootState {
  read: StatisticsReadState;
}

export const StatisticsReducers = combineReducers<StatisticsRootState>({
  read: StatisticsReadReducer,
});
