import * as React from 'react';
import { formatDate } from 'websd/utils';
import { FancyNavLink } from 'components/app';
import { routesMap, LocationActionTypes } from 'store/location';

export interface ReceiptsProps {
  receipt?: fos.api.ReceiptInfo;
}

export const SubscriptionReceipt: React.FunctionComponent<ReceiptsProps> = ({ receipt }) => {
  if (receipt) {
    return (
      <FancyNavLink
        to={routesMap[LocationActionTypes.Receipt] + '?receiptId=' + receipt.Id}
        className="SubscriptionsAndTrainingCard__Row"
        key={receipt.Id}
      >
        <div className="SubscriptionsAndTrainingCard__Row__Info">
          <p className={'SubscriptionsAndTrainingCard__Row__Title'}>{receipt.Pris + ' ' + receipt.Valuta}</p>
          <pre className={'SubscriptionsAndTrainingCard__Row__Details'}>{formatDate(receipt.Datum)}</pre>
        </div>
      </FancyNavLink>
    );
  } else {
    return (
      <div className="SubscriptionsAndTrainingCard__Row Skeleton">
        <div className="SubscriptionsAndTrainingCard__Row__Info">
          <p className="SubscriptionsAndTrainingCard__Row__Title" />
          <p className="SubscriptionsAndTrainingCard__Row__Details" />
        </div>
        <div className="SubscriptionsAndTrainingCard__Row__Actions" />
      </div>
    );
  }
};
