import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import { connectRoutes, Options } from 'redux-first-router';
import thunkMiddleware from 'redux-thunk';
import { rootReducer, RootState } from './store';
import { routesMap, LocationState } from './store/location';
import { localeReducer } from './store/global/dictionary';
import { LocaleState } from 'react-localize-redux';
import { Reducer } from 'redux';
import * as queryString from 'query-string';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <E, R>(e?: E, a?: R) => R;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = (initialState?: RootState) => {
  const options: Options<{}, RootState> = {
    displayConfirmLeave: (confirmLeave: string, callback: (_: boolean) => void) => {
      const canLeave = window.confirm(confirmLeave);

      callback(canLeave);
    },
    querySerializer: queryString,
    // Bara för mobilapp version
    // https://github.com/ReactTraining/react-router/issues/2161
    basename: undefined,
  };

  const {
    reducer: locationReducer,
    middleware: locationMiddleware,
    enhancer: locationEnhancer,
  } = connectRoutes<any>(routesMap, options);
  const l = locationReducer as unknown as Reducer<LocationState<any>, AnyAction>;
  const store = createStore(
    rootReducer(l, localeReducer as Reducer<LocaleState>),
    initialState,
    composeEnhancers(locationEnhancer, applyMiddleware(locationMiddleware, thunkMiddleware))
  );

  // const rooten = rootReducer(locationReducer, localeReducer as Reducer<LocaleState>);
  // const middlewares = applyMiddleware<Dispatch>(locationMiddleware, thunkMiddleware);
  // const comp = compose(locationEnhancer, middlewares);
  // const store = createStore(rooten, comp) as StoreEnhancer<RootState>;

  // const store = createStore(
  //   rootReducer(locationReducer, localeReducer as Reducer<LocaleState>),
  //   composeEnhancers(
  //     locationEnhancer,
  //     applyMiddleware(
  //       locationMiddleware,

  //       // Enable thunk action side effects
  //       thunkMiddleware
  //     )
  //   ) as StoreEnhancer<RootState>
  // );

  return store;
};
