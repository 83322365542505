import { DictionaryActionTypes } from './dictionaryActions';
import { Action } from 'store';
import { Reducer } from 'redux';
import { DictionaryRootState, DictionaryStatus } from '.';

const initialStatus: DictionaryRootState = {
  status: DictionaryStatus.INITIAL,
};

export const DictionaryReducer: Reducer<DictionaryRootState> = (
  state: DictionaryRootState = initialStatus,
  action: Action
) => {
  switch (action.type) {
    case DictionaryActionTypes.UPDATE_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};
