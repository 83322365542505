interface OverwriteUserReadAction {
  type: UserReadActionType.GET_USER;
  updatedUser: fos.api.User;
}

export type UserReadAction =
  | OverwriteUserReadAction
  | RegisterUserIdAction
  | GetBetalningsmedelReadAction
  | GetAutogiroReadAction
  | GetLanguageCodeReadAction;

export interface RegisterUserIdAction {
  type: UserReadActionType.RegisterUserId;
  userId: string;
}

export interface GetBetalningsmedelReadAction {
  type: UserReadActionType.GET_BETALNINGSMEDEL;
  betalningsmedel: fos.api.Betalningsmedel;
}

export interface GetAutogiroReadAction {
  type: UserReadActionType.GET_AUTOGIRO;
  autogiro: fos.api.Autogiro;
}

export interface GetLanguageCodeReadAction {
  type: UserReadActionType.GET_LANGUAGE_CODE;
  languageCode: string;
}

export enum UserReadActionType {
  GET_USER = 'GET_USER',
  RegisterUserId = 'REGISTER_USER_ID',
  GET_BETALNINGSMEDEL = 'GET_BETALNINGSMEDEL',
  GET_AUTOGIRO = 'GET_AUTOGIRO',
  GET_LANGUAGE_CODE = 'GET_LANGUAGE_CODE',
}

export const getUser = (newValue: fos.api.User): OverwriteUserReadAction => ({
  type: UserReadActionType.GET_USER,
  updatedUser: newValue,
});

export const registerUserId = (userId: string): RegisterUserIdAction => ({
  type: UserReadActionType.RegisterUserId,
  userId,
});

export const getBetalningsmedel = (betalningsmedel: fos.api.Betalningsmedel): GetBetalningsmedelReadAction => ({
  type: UserReadActionType.GET_BETALNINGSMEDEL,
  betalningsmedel: betalningsmedel,
});

export const getAutogiro = (autogiro: fos.api.Autogiro): GetAutogiroReadAction => ({
  type: UserReadActionType.GET_AUTOGIRO,
  autogiro: autogiro,
});

export const getLanguageCode = (languageCode: string): GetLanguageCodeReadAction => ({
  type: UserReadActionType.GET_LANGUAGE_CODE,
  languageCode,
});
