import { Reducer } from 'redux';
import { Action } from 'store';
import { BookingRulesActionTypes } from '.';

export interface BookingRulesState {
  focusedFoereningId: number;
}

const initialState: BookingRulesState = {
  focusedFoereningId: -1,
};

export const bookingRulesReducer: Reducer<BookingRulesState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case BookingRulesActionTypes.SET_FOCUS_ON_FOEREING: {
      return {
        ...state,
        focusedFoereningId: action.id,
      };
    }
    default:
      return state;
  }
};
