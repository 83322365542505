import * as React from 'react';
import { TranslateIfSpecialIntresse } from 'utils';
import { formatDate } from 'websd/utils';

export interface NewsItemDataProps {
  title: string;
  text: string;
  imageUrl: string;
  date: string;
  tags: Array<string>;
  url: string;
}

const NewsItem: React.SFC<NewsItemDataProps> = (props: NewsItemDataProps) => {
  const fosURL = process.env.REACT_APP_FOS_URL as string;
  const imageUrl = fosURL + props.imageUrl;
  const itemUrl = fosURL + props.url;
  const specialTag = 'Show All Users';

  return (
    <a href={itemUrl} target="_blank" rel="noopener noreferrer" className="News__Item">
      <div
        className="Item__Image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <div className="Item__Information">
        <div className="Information__Section">
          <div className="Information__Header">
            <h5>{props.title}</h5>
          </div>
          <div className="Information__Text">
            <p>{props.text}</p>
          </div>
        </div>

        <div className="Information__Section">
          <div className="Information__Extras">
            <div className="Extras__Tags">
              {props.tags.map(
                (t, i) =>
                  t !== specialTag && (
                    <div className="Tags__Item" key={i}>
                      {TranslateIfSpecialIntresse(t)}
                    </div>
                  )
              )}
            </div>
            <div className="Extras__Date">{formatDate(props.date)}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default NewsItem;
