import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { UserAccount, UserAccountDataProps, UserAccountActionProps } from './userAccount';
import { getTranslate } from 'store/global/dictionary';
import { changePasswordActions, changePassword } from 'store/user/changePasswordCmd';
import {
  getIsExecutingFailed,
  FetchCommandTypes,
  getIsExecuting,
  getIsExecuted,
  initialCommand,
} from 'store/global/fetch';

const mapStateToProps = (state: RootState): UserAccountDataProps => ({
  location: state.location,
  activeFoereningId: state.global.session.activeFoerening as number,
  anlaeggningar: state.anlaeggningar.read.sitecoreAnlaeggningar,
  savedAnlaeggningar: state.trainingPreferences.read.anlaeggningar,
  currentPasswordValue: state.user.changePasswordCmd.currentPassword,
  isNewPasswordValid: state.user.changePasswordCmd.isNewPasswordValid,
  isRepeatNewPasswordValid: state.user.changePasswordCmd.isRepeatNewPasswordValid,
  newPasswordValue: state.user.changePasswordCmd.newPassword,
  repeatNewPasswordValue: state.user.changePasswordCmd.repeatNewPassword,
  isCurrentPasswordValid: state.user.changePasswordCmd.isCurrentPasswordValid,
  isFormValid: state.user.changePasswordCmd.isFormValid,
  translate: getTranslate(state.locale),
  changePasswordCmdExecutionFailed:
    !!state.global.session.activeFoerening &&
    getIsExecutingFailed(
      state.global.fetch,
      state.global.session.activeFoerening.toString(),
      FetchCommandTypes.AENDRA_LOESENORD
    ),
  changePasswordCmdIsExecuted:
    !!state.global.session.activeFoerening &&
    getIsExecuted(
      state.global.fetch,
      state.global.session.activeFoerening.toString(),
      FetchCommandTypes.AENDRA_LOESENORD
    ),
  changePasswordCmdIsExecuting:
    !!state.global.session.activeFoerening &&
    getIsExecuting(
      state.global.fetch,
      state.global.session.activeFoerening.toString(),
      FetchCommandTypes.AENDRA_LOESENORD
    ),
});

const mapDispatchToProps = (dispatch: Dispatch): UserAccountActionProps => ({
  updateCurrentPasswordValidity: (isValid: boolean) =>
    dispatch(changePasswordActions.setCurrentPasswordValidity(isValid)),
  updateCurrentPasswordValue: (newValue: string) => dispatch(changePasswordActions.setCurrentPassword(newValue)),
  updateFormValidity: (isValid: boolean) => dispatch(changePasswordActions.setFormValidity(isValid)),
  updateNewPasswordValidity: (isValid: boolean) => dispatch(changePasswordActions.setNewPasswordValidity(isValid)),
  updateNewPasswordValue: (newValue: string) => dispatch(changePasswordActions.setNewPassword(newValue)),
  updateRepeatNewPasswordValidity: (isValid: boolean) =>
    dispatch(changePasswordActions.setRepeatNewPasswordValidity(isValid)),
  updateRepeatNewPasswordValue: (newValue: string) => dispatch(changePasswordActions.setRepeatNewPassword(newValue)),
  changePassword: () => dispatch(changePassword()),
  clearChangeFetchPasswordCmd: aktiveFoereningId =>
    dispatch(initialCommand(aktiveFoereningId.toString(), FetchCommandTypes.AENDRA_LOESENORD)),
  clearChangePasswordCmd: () => dispatch(changePasswordActions.clearCmd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
