import { ThunkAction } from 'store';
import { api } from 'api';
import { executedCommand, executingCommandFailed, executingCommand, FetchCommandTypes } from 'store/global/fetch';
import {
  passwordResetLinkRequested,
  doClearPasswordResetState,
  setPasswordResetResult,
  PasswordResetErrorCodes,
} from '.';

export const requestResetPasswordLink = (passwordResetInfo: fos.api.PasswordResetInfo): ThunkAction => dispatch => {
  const promise = api.post<never, fos.api.PasswordResetInfo>('Anvaendare/RequestResetPassword/', passwordResetInfo, {
    muteErrorNotificationOnStatus: [406],
    anonymousCall: true,
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(passwordResetLinkRequested(true));
    } else {
      dispatch(passwordResetLinkRequested(false));
    }
  });
  return promise;
};

export const resetPassword = (resetPasswordRequest: fos.api.ResetPasswordRequest): ThunkAction => dispatch => {
  dispatch(executingCommand(resetPasswordRequest.Token, FetchCommandTypes.RESET_LOESENORD));
  const promise = api.post<never, fos.api.ResetPasswordRequest>('Anvaendare/ResetPassword/', resetPasswordRequest, {
    muteErrorNotificationOnStatus: [404, 406, 410],
    anonymousCall: true,
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(setPasswordResetResult(true));
      dispatch(executedCommand(resetPasswordRequest.Token, FetchCommandTypes.RESET_LOESENORD));
    } else {
      if (result.result && result.result.http) {
        switch (result.result.http.status) {
          case 404:
            dispatch(setPasswordResetResult(false, PasswordResetErrorCodes.INVALID_TOKEN));
            break;
          case 410:
            dispatch(setPasswordResetResult(false, PasswordResetErrorCodes.INVALID_TOKEN));
            break;
          default:
            dispatch(setPasswordResetResult(false, PasswordResetErrorCodes.UNKNOWN));
            break;
        }
      } else {
        dispatch(setPasswordResetResult(false, PasswordResetErrorCodes.UNKNOWN));
      }
      dispatch(executingCommandFailed(resetPasswordRequest.Token, FetchCommandTypes.RESET_LOESENORD));
    }
  });
  return promise;
};

export const clearPasswordResetState = (): ThunkAction => dispatch => dispatch(doClearPasswordResetState());
