import * as React from 'react';
import { ShoppingBasketProductsListContainer } from './shoppingBasketProducts/shoppingBasketProductsListContainer';
import { KnownUserBuyingFormContainer } from './forms/knownUserBuying';
import { SitecoreDictionary } from 'models';
import { ErrorResult, SuccessResult } from 'websd/utils/configureApi';
import { Spinner } from '../common/Spinner';
import { routesMap, LocationActionTypes as RouteActionType } from 'store/location';
import { FancyNavLink } from 'components/app';
import { TranslateFunction } from 'react-localize-redux';
export interface ShoppingBasketDataProps {
  shopFoerening: number;
  allProducts: Array<fos.api.Product>;
  isPublicWebMode: boolean;
  isAdmin: boolean;
  translate: TranslateFunction;
  inloggadeFoerening: number | undefined;
  isAuthenticated?: boolean;
}
export interface ShoppingBasketInternalProps {
  isDesktopMode: boolean;
}
export interface ShoppingBasketActionsProps {
  resetBuyCommand: () => void;
  getRegistreradeBetalningsmedel: (
    inloggadeFoerening: number
  ) => Promise<ErrorResult | SuccessResult<fos.api.Betalningsmedel>>;
}

type ShoppingBasketProps = ShoppingBasketDataProps & ShoppingBasketActionsProps & ShoppingBasketInternalProps;
export class ShoppingBasket extends React.Component<ShoppingBasketProps> {

  componentDidMount() {
    if (this.props.inloggadeFoerening && this.props.isAuthenticated) {
      this.props.getRegistreradeBetalningsmedel(this.props.inloggadeFoerening);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: ShoppingBasketProps) {
    if (
      newProps.inloggadeFoerening &&
      ((newProps.isAuthenticated && !this.props.isAuthenticated) ||
        (newProps.inloggadeFoerening !== this.props.inloggadeFoerening && newProps.isAuthenticated))
    ) {
      this.props.getRegistreradeBetalningsmedel(newProps.inloggadeFoerening);
    }
  }
  componentWillUnmount() {
    this.props.resetBuyCommand();
  }

  render() {
    if (!this.props.allProducts || this.props.allProducts.length === 0) {
      return (
        <div className="ShoppingBasket__Spinner__Container">
          <Spinner className="ShoppingBasket__Spinner" />
        </div>
      );
    }
    if (!this.props.allProducts || this.props.allProducts.length === 0) {
      return (
        <div className="ShoppingBasket__Spinner__Container">
          <Spinner className="ShoppingBasket__Spinner" />
        </div>
      );
    }
    return (
      <div className="ShoppingBasket__Container">
        {
          <ShoppingBasketProductsListContainer
            productList={this.props.allProducts}
            translate={this.props.translate}
            activeFoerening={this.props.shopFoerening}
            isAdmin={this.props.isAdmin}
          />
        }
        {
          <KnownUserBuyingFormContainer
            isDesktopMode={this.props.isDesktopMode}
            translate={this.props.translate}
            foereningId={this.props.shopFoerening}
            isAuthenticated={!!this.props.isAuthenticated}
            isAdmin={this.props.isAdmin}
          />
        }
        {
          <div className="FancyNavLinkCenter">
            <FancyNavLink
              to={
                this.props.isPublicWebMode
                  ? routesMap[RouteActionType.PublicShop]
                  : routesMap[this.props.isAdmin ? RouteActionType.AdminShop : RouteActionType.Shop]
              }
            >
              {this.props.translate(SitecoreDictionary.Common.BackToProducts) as string}
            </FancyNavLink>
          </div>
        }
      </div>
    );
  }
}
