import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { Overlay } from 'components/common';
import { TranslateFunction } from 'react-localize-redux';

export interface PopupGDPRPassedProps {
  buttons?: { text: string; buttonType: 'primary' | 'secondary'; onClick: () => void }[];
  onClose?: () => void;
  readonly translate: TranslateFunction;
}

export type PopupGDPRProps = PopupGDPRPassedProps;

export class PopupGDPR extends React.Component<PopupGDPRProps> {
  render() {
    return (
      <Overlay close={this.props.onClose} title={this.props.translate(SitecoreDictionary.GDPR.GDRPTitle) as string}>
        <div className="MedlemsVillkor">
          <div
            className={
              'MedlemsVillkor__PopupSelect__TextContainer' +
              (!this.props.buttons || this.props.buttons.length === 0 ? '__WithoutButtons' : '')
            }
          >
            <div className="MedlemsVillkor__PopupSelect__Text">
              {this.props.translate(SitecoreDictionary.GDPR.GDPR)}
            </div>
            <div className="MedlemsVillkor__PopupSelect__Buttons">
              {!!this.props.buttons &&
                this.props.buttons.map((button, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index > 0 && <div className="MedlemsVillkor__PopupSelect__Buttons--space" />}
                      <div className="MedlemsVillkor__PopupSelect__Buttons--button">
                        <button
                          className={'Button Button--' + button.buttonType + ' Button--fullWidth'}
                          onClick={button.onClick}
                        >
                          {this.props.translate(button.text)}
                        </button>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}
