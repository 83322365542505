export enum MobileOSType {
  Android,
  iOS,
}
interface WebAppInterface {
  // tslint:disable-next-line: no-any
  showToast(message: string): any;
  // tslint:disable-next-line: no-any
  askCalendarPermission(data: string): any;
  // tslint:disable-next-line: no-any
  addToCalendar(data: string): any;
  // tslint:disable-next-line: no-any
  fetchCalendars(): any;
  // tslint:disable-next-line: no-any
  setCalendarId(id: string): any;
  // tslint:disable-next-line: no-any
  openFriskisGo(): any;
}
declare var Android: WebAppInterface;

class Ios {
  static showToast = (message: string) => {
    var id = 'showToast';
    var data = message;
    var dictionaryItem = JSON.stringify({ id: id, data: data });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };

  static askCalendarPermission = () => {
    var id = 'calendarPermission';
    var data = '';
    var dictionaryItem = JSON.stringify({ id: id, data: data });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };

  static addToCalendar = (data: string) => {
    var id = 'addPass';
    var dictionaryItem = JSON.stringify({ id: id, data: data });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };

  static fetchCalendars = () => {
    var id = 'fetchCalendars';
    var data = '';
    var dictionaryItem = JSON.stringify({ id: id, data: data });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };

  static setCalendarId = (calendarId: string) => {
    var id = 'setCalendarId';
    var dictionaryItem = JSON.stringify({ id: id, data: calendarId });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };

  static openFriskisGo = () => {
    var id = 'openFriskisGo';
    var data = '';
    var dictionaryItem = JSON.stringify({ id: id, data: data });
    // tslint:disable-next-line: no-any
    (window as any).webkit.messageHandlers.iosListener.postMessage(dictionaryItem);
  };
}

class Debug {
  static showToast = (message: string) => {
    // not implemented
  };

  static askCalendarPermission = () => {
    // not implemented
  };

  static addToCalendar = (data: string) => {
    // not implemented
  };

  static fetchCalendars = () => {
    // simulera att det kalendrar inte uppdateras samma frame.
    window.setTimeout(() => {
      localStorage.setItem(
        'calendars',
        JSON.stringify([
          { id: '1111', name: 'test' },
          { id: '2222', name: 'test 2' },
        ])
      );
    }, 500);
  };

  static setCalendarId = (calendarId: string) => {
    // not implemented
  };

  static openFriskisGo = () => {
    // not implemented
  };
}

export class Mobile {
  static showToast = (message: string) => {
    if (isIOS()) {
      Ios.showToast(message);
    } else if (isAndroid()) {
      Android.showToast(message);
    } else if (isDebug()) {
      Debug.showToast(message);
    }
  };

  static askCalendarPermission = () => {
    if (isIOS()) {
      Ios.askCalendarPermission();
    } else if (isAndroid()) {
      Android.askCalendarPermission('');
    } else if (isDebug()) {
      Debug.askCalendarPermission();
    }
  };

  static addToCalendar = (data: string) => {
    if (isIOS()) {
      Ios.addToCalendar(data);
    } else if (isAndroid()) {
      Android.addToCalendar(data);
    } else if (isDebug()) {
      Debug.addToCalendar(data);
    }
  };

  static fetchCalendars = () => {
    if (isIOS()) {
      Ios.fetchCalendars();
    } else if (isAndroid()) {
      Android.fetchCalendars();
    } else if (isDebug()) {
      Debug.fetchCalendars();
    }
  };

  static setCalendarId = (calendarId: string) => {
    if (isIOS()) {
      Ios.setCalendarId(calendarId);
    } else if (isAndroid()) {
      Android.setCalendarId(calendarId);
    } else if (isDebug()) {
      Debug.setCalendarId(calendarId);
    }
  };

  static openFriskisGo = () => {
    if (isIOS()) {
      Ios.openFriskisGo();
    } else if (isAndroid()) {
      Android.openFriskisGo();
    } else if (isDebug()) {
      Debug.openFriskisGo();
    }
  };
}

export const isApp = () => {
  let app = localStorage.getItem('nativeApp');
  return app !== null && parseInt(app, 10) > 0;
};

export const isIOS = () => {
  let app = localStorage.getItem('nativeApp');
  if (app !== null && parseInt(app, 10) === 1) {
    return true;
  }

  return false;
};

export const isAndroid = () => {
  let app = localStorage.getItem('nativeApp');
  if (app !== null && parseInt(app, 10) === 2) {
    return true;
  }

  return false;
};

export const isDebug = () => {
  let app = localStorage.getItem('nativeApp');
  if (app !== null && parseInt(app, 10) === 3) {
    return true;
  }

  return false;
};

export const isDebugIos = () => {
  let app = localStorage.getItem('nativeApp');
  if (app !== null && parseInt(app, 10) === 4) {
    return true;
  }

  return false;
};

export const isDebugAndroid = () => {
  let app = localStorage.getItem('nativeApp');
  if (app !== null && parseInt(app, 10) === 5) {
    return true;
  }

  return false;
};
