import { ProductTypes } from 'store/shop';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export type CouldAddProduct = CanAddProduct | CannotAddProduct;

export interface CanAddProduct {
  canAdd: true;
}

export interface CannotAddProduct {
  canAdd: false;
  message: string;
}

export const canAddProduct = (
  product: fos.api.Product,
  translate: TranslateFunction,
  productsInBasket: fos.api.Product[] | undefined,
  isPendingOrderChange: boolean
): CouldAddProduct => {
  if (isPendingOrderChange) {
    return { canAdd: false, message: '' };
  }
  // Om vi försöker lägga till ett träningskort
  if (product.Types.find(t => t === ProductTypes.Traeningskort || t === ProductTypes.Tvaafoereningskort)) {
    if (!!productsInBasket) {
      const typesInBasket = productsInBasket.map(p => p.Types).reduce((a, b) => a.concat(b), []);
      // kolla om det redan finns träningskort
      if (typesInBasket.find(t => t === ProductTypes.Traeningskort || t === ProductTypes.Tvaafoereningskort)) {
        return {
          canAdd: false,
          message: translate(SitecoreDictionary.Shop.CanOnlyAddOneOfTypeTraeningsKort) as string,
        };
      }
    }
  }
  return { canAdd: true };
};
