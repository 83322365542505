import { ProductHows, ProductWhens, ProductWhos, ShopFilter, ShopFilterCategoryNames } from './shopModels';

import {
  ShopActionType,
  ShopActionFilterProductsUsingFilterOptions,
  ShopActionUpdateUserSelectedFilterOptions,
  ShopActionUpdateFilterOptionsCategory,
  ShopActionClearFilterOptionsCategory,
  ShopActionSetProductFilterWithPristineUnfilteredProducts,
  ShopActionResetProductsFilter,
} from './shopActions';

export const filterProductsUsingFilterOptions = (
  unfilteredProducts: fos.api.Product[],
  shopFilterOptions: ShopFilter
): ShopActionFilterProductsUsingFilterOptions => ({
  type: ShopActionType.Shop_FilterProductsUsingFilterOptions,
  unfilteredProducts: unfilteredProducts,
  shopFilterOptions: shopFilterOptions,
});

export const updateUserSelectedFilterOptions = (
  shopFilterOptions: ShopFilter
): ShopActionUpdateUserSelectedFilterOptions => ({
  type: ShopActionType.Shop_UpdateUserSelectedFilterOptions,
  shopFilterOptions: shopFilterOptions,
});

export const updateShopFilterOptionsCategory = (
  category: ShopFilterCategoryNames,
  propertyValue: string[] | ProductWhos[] | ProductWhens[] | ProductHows[]
): ShopActionUpdateFilterOptionsCategory => ({
  type: ShopActionType.Shop_UpdateUserSelectedFilterOptionsProperty,
  category: category,
  propertyValue: propertyValue,
});

export const clearShopFilterOptionsCategory = (
  category: ShopFilterCategoryNames
): ShopActionClearFilterOptionsCategory => ({
  type: ShopActionType.Shop_ClearUserSelectedFilterOptionsProperty,
  category: category,
});

export const setProductFilterWithPristineUnfilteredProducts = (
  unfilteredProducts: fos.api.Product[]
): ShopActionSetProductFilterWithPristineUnfilteredProducts => ({
  type: ShopActionType.Shop_SetProductFilterWithPristineUnfilteredProducts,
  unfilteredProducts: unfilteredProducts,
});

export const updateShopResetProductsFilter = (): ShopActionResetProductsFilter => ({
  type: ShopActionType.Shop_ResetProductsFilter,
});
