import * as React from 'react';
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';

import { StatusBar, ConfirmationTextBox, IconButton } from 'components/common';
import { SitecoreDictionary, SitecorePlaceholder, BokningsStatusEnum } from 'models';
import { NavLink } from 'redux-first-router-link';
import { passDetailsRouteAction } from 'store/location';
import { PublicSchedulePopupContainer } from 'components/publicSchedulePopup';
import { getDateTextForView } from 'websd/utils';
import { IconInformation } from 'components/icons';
import { Info } from '../../../pass-details/Info';
import { TranslateFunction } from 'react-localize-redux';
import { CommandStatus } from 'store/global/fetch';

export interface PassItemInternalDataProps {
  passId: string;
  translate: TranslateFunction;
  aktivFoerening: number;
  baseClass: string;
  isDesktopMode: boolean;
  passDay: string;
}

export interface PassItemDataProps {
  pass?: fos.api.Pass;
  bokaStatus: CommandStatus;
  avbokaStatus: CommandStatus;
  bokningar: Array<fos.api.Bokning>;
  isPublicWebMode: boolean;
  readonly isAuthenticated: boolean;
  readonly allowBokaPassFromSitecore: boolean;
  readonly selectedPass?: fos.api.Pass;
  readonly isWeekView: boolean;
}

export interface PassItemActionProps {
  bokaPass: (pass: fos.api.Pass, bokaKoePlats: boolean) => void;
  scheduleAvbokaPass: (payload: fos.api.CancelPayload, passId: string, key: string) => void;
  disableBokaPass: () => void;
  allowBokaPass: () => void;
  closeSchedule: (close: boolean) => void;
  closeSideBar: () => void;
}

const renderWithMaxLength = (input: string, maxLength: number) => {
  if (!input) {
    return '';
  } else {
    return input.length > maxLength ? input.substr(0, maxLength - 3) + '...' : input;
  }
};

type PassItemProps = PassItemDataProps & PassItemActionProps & PassItemInternalDataProps;

export const PassItem: React.FunctionComponent<PassItemProps> = ({
  aktivFoerening,
  allowBokaPass,
  allowBokaPassFromSitecore,
  baseClass,
  bokaPass,
  bokningar,
  bokaStatus,
  avbokaStatus,
  closeSchedule,
  closeSideBar,
  disableBokaPass,
  isAuthenticated,
  isDesktopMode,
  isPublicWebMode,
  isWeekView,
  passDay,
  scheduleAvbokaPass,
  translate,
  pass,
  selectedPass,
}) => {
  const okStatusClass = 'StatusMessage--Green';
  const errorStatusClass = 'StatusMessage--Black';
  const NotBookable = [
    BokningsStatusEnum.EJ_BOKNINGSBART_DATE_DROPIN as string,
    BokningsStatusEnum.FOERSENT_FOER_ATT_BOKA as string,
    BokningsStatusEnum.FOERTIDIGT_FOER_ATT_BOKA as string,
    BokningsStatusEnum.FULLT as string,
    BokningsStatusEnum.FULLT_DROPIN as string,
    BokningsStatusEnum.DROPIN as string,
    BokningsStatusEnum.INSTAELLD as string,
  ];

  const [statusMessageVisible, setStatusMessageVisible] = React.useState<boolean>(false);
  const [statusMessageText, setStatusMessageText] = React.useState<string>('');
  const [confirmationVisible, setConfirmationVisible] = React.useState<boolean>(false);
  const [showLoginPopOver, setShowLoginPopOver] = React.useState<boolean>(false);
  const [showAtStart, setShowAtStart] = React.useState<boolean>(false);
  const [userInformed, setUserInformed] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (
      !userInformed &&
      bokaStatus !== CommandStatus.EXECUTING &&
      avbokaStatus !== CommandStatus.EXECUTING &&
      !!pass &&
      !!pass.statusMessage
    ) {
      setStatusMessageVisible(true);
      setStatusMessageText(pass.statusMessage);
      setUserInformed(true);
      setTimeout(() => {
        setStatusMessageVisible(false);
        setStatusMessageText('');
      }, 4500);
    }
  }, [userInformed, bokaStatus, avbokaStatus, pass]);

  const closePublicPopup = () => {
    setShowLoginPopOver(false);
    setShowAtStart(true);
    allowBokaPass();
  };

  const getStatusMessageClass = () => {
    if (
      statusMessageText === SitecoreDictionary.Schedule.Messages.OK_BOOK ||
      statusMessageText === SitecoreDictionary.Schedule.Messages.QUEUED ||
      statusMessageText === SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return okStatusClass;
    } else {
      return errorStatusClass;
    }
  };
  const hideConfirmation = () => {
    setConfirmationVisible(false);
  };

  const avbokaPass = () => {
    if (!pass) {
      return;
    }
    let bokning = bokningar.find(b => b.PassId.toString() === pass.PassId.toString());
    if (bokning) {
      let payload = {
        bokningId: bokning.BokningId.toString(),
        foereningsId: aktivFoerening,
        queued: bokning.Koad,
        passId: bokning.PassId,
        anlaeggningsId: bokning.AnlaeggningsId,
      };
      const date = getDateTextForView(pass.StartTime);
      scheduleAvbokaPass(payload, pass.PassId, date);

      // GTM stuff for avboka
      // tslint:disable-next-line
      let test: any = window;
      /* tslint:disable */
      test.dataLayer.push({
        event: 'cancelBookedTraining',
        passName: pass.PassNamn,
        passId: pass.PassId,
        startTime: pass.StartTime,
        AnlaeggningsNamn: pass.AnlaeggningsNamn,
      });
    }
    setUserInformed(false);
    hideConfirmation();
  };

  const handleOnClickIconButton = (event: React.MouseEvent<HTMLButtonElement>, statusBarIsActive?: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    if (!pass || pass.disabled || statusBarIsActive) {
      return;
    }

    const passIsBooked = bokningar.find(b => b.PassId === pass.PassId);
    const bookable =
      pass.BokningStatus !== BokningsStatusEnum.DROPIN && pass.BokningStatus !== BokningsStatusEnum.INSTAELLD;
    if (!isPublicWebMode && passIsBooked) {
      setConfirmationVisible(true);
    } else if (bookable) {
      const bokaKoePlats =
        pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
        pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN;
      if (!isPublicWebMode) {
        bokaPass(pass, bokaKoePlats);
        setUserInformed(false);
        //GTM stuff for boka
        // tslint:disable-next-line
        let test: any = window;
        /* tslint:disable */

        test.dataLayer.push({
          event: 'bookTraining',
          passName: pass.PassNamn,
          passId: pass.PassId,
          startTime: pass.StartTime,
          AnlaeggningsNamn: pass.AnlaeggningsNamn,
        });
      } else {
        if (!isAuthenticated && !showLoginPopOver && allowBokaPassFromSitecore) {
          disableBokaPass();
          setShowLoginPopOver(true);
          bokaPass(pass, bokaKoePlats);
        }
      }
    } else {
      setStatusMessageVisible(true);
      setStatusMessageText(
        NotBookable.indexOf(pass.BokningStatus) >= 0
          ? SitecoreDictionary.Schedule.BookingStatus[pass.BokningStatus]
          : SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR
      );
      setTimeout(() => {
        setStatusMessageVisible(false);
        setStatusMessageText('');
      }, 4500);
    }
  };
  const getConfirmationText = () => {
    let confirmationText = translate(SitecoreDictionary.Schedule.ConfirmBook);
    if (!pass) {
      return confirmationText as string;
    }
    if (bokningar.find(b => b.PassId === pass.PassId)) {
      confirmationText = translate(SitecoreDictionary.Schedule.ConfirmCancel);
    }

    confirmationText = confirmationText.toString().replace(SitecorePlaceholder.PASS, pass.PassNamn);
    confirmationText = confirmationText.toString().replace(SitecorePlaceholder.FOERENING, pass.AnlaeggningsNamn);

    return confirmationText;
  };

  const getIconButtonText = () => {
    if (!pass) {
      return `0 ${translate(SitecoreDictionary.PassItem.Left)}`;
    }
    const bokning = bokningar.find(b => b.PassId === pass.PassId);

    if (pass.AerInstaelld) {
      return `${translate(SitecoreDictionary.Schedule.Messages.CANCELLED)}`;
    }
    if (!!bokning && bokning.Koad) {
      return `${translate(SitecoreDictionary.PassItem.QueuePosition)} ${bokning.KoPlats}`;
    } else if (!!bokning) {
      if (pass.AntalLedigaPlatser === 0) {
        return `${pass.AntalIVaenteLista} ${translate(SitecoreDictionary.PassItem.InQueue)}`;
      } else {
        return `${pass.AntalLedigaPlatser} ${translate(SitecoreDictionary.PassItem.Left)}`;
      }
    } else if (pass.BokningStatus === BokningsStatusEnum.DROPIN) {
      return `${pass.AntalDropinPlatser.toString()} drop-in`;
    } else if (
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART ||
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_DROPIN
    ) {
      return `${pass.AntalLedigaPlatser} ${translate(SitecoreDictionary.PassItem.Left)}`;
    } else if (
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN
    ) {
      return `${pass.AntalIVaenteLista} ${translate(SitecoreDictionary.PassItem.InQueue)}`;
    }

    return `${pass.AntalLedigaPlatser} ${translate(SitecoreDictionary.PassItem.Left)}`;
  };

  const getIconButtonStyle = () => {
    if (!pass) {
      return '--Available';
    }
    let bokning = bokningar.find(b => b.PassId === pass.PassId);

    const isCancelled = pass.AerInstaelld;

    if (!isCancelled && bokning) {
      if (bokning.Koad) {
        return '--BookedWithQueue';
      } else {
        return '--Booked';
      }
    }

    switch (pass.BokningStatus) {
      case BokningsStatusEnum.EJ_BOKNINGSBART_DATE_DROPIN:
        return '--NotAvailableYet';
      case BokningsStatusEnum.FOERSENT_FOER_ATT_BOKA:
        return '--LateToBook';
      case BokningsStatusEnum.FOERTIDIGT_FOER_ATT_BOKA:
        return '--EarlyToBook';
      case BokningsStatusEnum.BOKNINGSBART:
        return '--Available';
      case BokningsStatusEnum.BOKNINGSBART_DROPIN:
        return '--Available';
      case BokningsStatusEnum.BOKNINGSBART_KOE:
        return '--AvailableWithQueue';
      case BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN:
        return '--AvailableWithQueue';
      case BokningsStatusEnum.FULLT || BokningsStatusEnum.FULLT_DROPIN:
        return '--MaxCapacity';
      case BokningsStatusEnum.DROPIN:
        return '--Dropin';
      case BokningsStatusEnum.INSTAELLD:
        return '--Cancelled';
      default:
        return '--Available';
    }
  };

  const renderPass = () => {
    let isSelected = false;
    if (!pass) {
      return <div />;
    }
    if (selectedPass) {
      isSelected = selectedPass.PassId === pass.PassId;
    }
    return (
      <React.Fragment>
        <div className={baseClass + ' ' + (isSelected ? baseClass + '--selected' : '')}>
          {confirmationVisible && (
            <ConfirmationTextBox
              handleNo={hideConfirmation}
              translate={translate}
              handleYes={avbokaPass}
              text={getConfirmationText()}
            />
          )}

          <div className={baseClass + '__Time'}>
            <div className="Time__StartTime">{formatTime(pass.StartTime, 'HH:mm')}</div>
            <div className="Time__Duration">{pass.Length} min</div>
          </div>

          <div className={baseClass + '__Info'}>
            <div className="PassNameAndExtraInfo__PassName">{pass.PassNamn}</div>
            <div className="Info__InstructorName">
              {renderWithMaxLength(pass.Instruktoerer ? pass.Instruktoerer.toString() : '', 80)}
            </div>
            <div className="FacilityNameAndLocale__FacilityName">
              {pass.AnlaeggningsNamn.replace('Friskis&Svettis', 'F&S').replace(/.+? • /, '')}
              {
                pass.Lokal && pass.Lokal.length > 0
                  ? ', ' + pass.Lokal.join(', ').replace(' ', '\u00A0')
                  : '' /* \u00A0 = &nbsp; */
              }
            </div>
            {pass.ExtraInfo && (
              <div className="PassNameAndExtraInfo__ExtraInfo">
                <Info icon={<IconInformation />} header={pass.ExtraInfo} />
              </div>
            )}
          </div>

          {!isPublicWebMode && (
            <IconButton
              handleOnClick={handleOnClickIconButton}
              text={getIconButtonText()}
              styleType={getIconButtonStyle()}
              visible={statusMessageVisible}
              cssClass={'IconButton__' + getStatusMessageClass()}
              isExecuting={bokaStatus === CommandStatus.EXECUTING || avbokaStatus === CommandStatus.EXECUTING}
            />
          )}
        </div>
        {!isPublicWebMode && (
          <StatusBar
            visible={statusMessageVisible}
            message={translate(statusMessageText).toString()}
            cssClass={getStatusMessageClass() + ' StatusMessage' + getIconButtonStyle()}
          />
        )}
        <CSSTransition
          mountOnEnter={true}
          unmountOnExit={true}
          timeout={400}
          classNames="PublicSchedulePopup__Login__Animation"
          in={showLoginPopOver}
        >
          <PublicSchedulePopupContainer
            showAtStart={showAtStart}
            pass={pass}
            passDay={passDay}
            closePopup={closePublicPopup}
          />
        </CSSTransition>
      </React.Fragment>
    );
  };

  const formatTime = (dateTime: string, format: string) => {
    const mDateTime = moment(dateTime);
    if (mDateTime.isValid()) {
      return mDateTime.format(format);
    } else {
      return null;
    }
  };
  return (
    <>
      {!!pass && !pass.disabled && (!isPublicWebMode || pass.BokningStatus !== BokningsStatusEnum.INSTAELLD) && (
        <React.Fragment>
          {isPublicWebMode && (
            <div className={baseClass + '--FullView ' + (statusMessageVisible ? baseClass + '--statusBarVisible' : '')}>
              {renderPass()}
            </div>
          )}
          {!isPublicWebMode && (
            <NavLink
              className={baseClass + '--FullView ' + (statusMessageVisible ? baseClass + '--statusBarVisible' : '')}
              to={passDetailsRouteAction(pass!)}
            >
              {renderPass()}
            </NavLink>
          )}
        </React.Fragment>
      )}
    </>
  );
};
