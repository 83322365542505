import { combineReducers } from 'redux';
import { MedlemskapOchKortReadActions, MedlemskapOchKortReadReducer, MedlemskapOchKortReadState } from './read';
import { MedlemskapOchKortWriteAction } from './write';

export type MedlemskapOchKortActions = MedlemskapOchKortReadActions | MedlemskapOchKortWriteAction;

export interface MedlemskapOchKortRootState {
  read: MedlemskapOchKortReadState;
}

export const MedlemskapOchKortReducer = combineReducers<MedlemskapOchKortRootState>({
  read: MedlemskapOchKortReadReducer,
});
