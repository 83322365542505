import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import {
  AcceptBookingRulesPopup,
  AcceptBookingRulesPopupDataProps,
  AcceptBookingRulesPopupActionProps,
} from './AcceptBookingRulesPopup';
import { getTranslate } from 'store/global/dictionary';
import { updateBookingRuleForActiveFoereningAction } from 'store/user/updateBookingRuleForActiveFoereningCmd/updateBookingRuleForActiveFoereningThunks';
import { getIsExecuting, FetchCommandTypes, getIsExecutingFailed } from 'store/global/fetch';
import { setFocusOnFoerening } from 'store/global/ui/bookingRules';
import { fetchReglerIfNotInStore } from 'store/foereningar';

const mapStateToProps = (state: RootState, props: any): AcceptBookingRulesPopupDataProps => ({
  translate: getTranslate(state.locale),
  isLoading: getIsExecuting(
    state.global.fetch,
    state.global.session.activeFoerening ? state.global.session.activeFoerening.toString() : '-1',
    FetchCommandTypes.ACCEPT_BOOKING_RULES
  ),
  updateFailed: getIsExecutingFailed(
    state.global.fetch,
    state.global.session.activeFoerening ? state.global.session.activeFoerening.toString() : '-1',
    FetchCommandTypes.ACCEPT_BOOKING_RULES
  ),
  foereningar: state.foereningar.read.Foereningar.filter(i =>
    state.global.session.inloggadeFoereningar.find(j => j.Id.toString() === i.Id.toString())
  ),
  activeFoerning: state.global.session.activeFoerening as number,
  isDesktopMode: props.isDesktopMode,
  focusedFoerening: state.foereningar.read.Foereningar.find(
    i => i.Id.toString() === state.global.ui.bookingRules.focusedFoereningId.toString()
  ),
});

const mapActionsToProps = (dispatch: Dispatch): AcceptBookingRulesPopupActionProps => ({
  updateBookingRuleForActiveFoerening: (accept: boolean) => dispatch(updateBookingRuleForActiveFoereningAction(accept)),
  fetchReglerIfNotInStore: (foerening: number) => dispatch(fetchReglerIfNotInStore(foerening)),
  setFocusOnFoerening: (id: number) => dispatch(setFocusOnFoerening(id)),
});

export default connect(mapStateToProps, mapActionsToProps)(AcceptBookingRulesPopup);
