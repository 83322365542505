export enum ImageActionType {
  SetImages = 'SET_IMAGES',
}

export interface SetImages {
  type: ImageActionType.SetImages;
  images: { [key: string]: string };
}

export const setImages = (images: { [key: string]: string }): SetImages => ({
  type: ImageActionType.SetImages,
  images,
});

export type ImageAction = SetImages;
