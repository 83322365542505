import { Reducer } from 'redux';
import { Action } from 'store';
import { updateAutogiroActionType as actionTypes } from './updateAutogiroActions';

export interface UpdateAutogiroState {
  id: string;
  clearingNumber: string;
  accountNumber: string;
  isClearingNumberValid: boolean;
  isAccountNumberValid: boolean;
  errorMessage: string;
}

const initialState: UpdateAutogiroState = {
  id: '',
  clearingNumber: '',
  accountNumber: '',
  isClearingNumberValid: false,
  isAccountNumberValid: false,
  errorMessage: '',
};

export const updateAutogiroReducer: Reducer<UpdateAutogiroState> = (
  state: UpdateAutogiroState = initialState,
  action: Action
): UpdateAutogiroState => {
  switch (action.type) {
    case actionTypes.SET_CLEARING_NUMBER:
      return {
        ...state,
        clearingNumber: action.newValue,
      };
    case actionTypes.SET_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: action.newValue,
      };
    case actionTypes.SET_AUTOGIRO_TO_UPDATE:
      return {
        ...state,
        id: action.id,
        clearingNumber: action.currentClearingNumber,
        accountNumber: action.currentAccountNumber,
      };
    case actionTypes.SET_CLEARING_NUMBER_VALIDITY:
      return {
        ...state,
        isClearingNumberValid: action.isValid,
      };
    case actionTypes.SET_ACCOUNT_NUMBER_VALIDITY:
      return {
        ...state,
        isAccountNumberValid: action.isValid,
      };
    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};
