import * as React from 'react';
import { SideBySideStatistics } from './SideBySideStatistics';
import { ListOfStatistics } from './ListOfStatistics';
import { EntityStatus } from 'store/global/fetch';
import { Spinner } from '../common/Spinner';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface StatisticsDataProps {
  statistics: fos.api.TrainingStatistics;
  fetchState?: EntityStatus;
  translate: TranslateFunction;
}

export interface StatisticsActionProps {
  fetchTraeningsstatistik: () => void;
}

type StatisticsProps = StatisticsDataProps & StatisticsActionProps;

export class Statistics extends React.Component<StatisticsProps> {
  componentDidMount() {
    this.props.fetchTraeningsstatistik();
  }

  render() {
    if (this.props.fetchState === EntityStatus.FETCHING) {
      return (
        <div className="Statistics__Spinner__Container">
          <Spinner className="Statistics__Spinner" />
        </div>
      );
    } else {
      return (
        <div className="Statistics">
          <SideBySideStatistics
            dataValue1={this.props.statistics.WorkoutsThisWeek}
            dataValue2={this.props.statistics.WorkoutsLastWeek}
            mainTitle={this.props.translate(SitecoreDictionary.Statistics.WorkoutsPerWeek).toString()}
            title1={this.props.translate(SitecoreDictionary.Statistics.WorkoutsThisWeek).toString()}
            title2={this.props.translate(SitecoreDictionary.Statistics.WorkoutsLastWeek).toString()}
          />
          <SideBySideStatistics
            dataValue1={this.props.statistics.WorkoutsThisMonth}
            dataValue2={this.props.statistics.WorkoutsLastMonth}
            mainTitle={this.props.translate(SitecoreDictionary.Statistics.WorkoutsPerMonth).toString()}
            title1={this.props.translate(SitecoreDictionary.Statistics.WorkoutsThisMonth).toString()}
            title2={this.props.translate(SitecoreDictionary.Statistics.WorkoutsLastMonth).toString()}
          />
          <SideBySideStatistics
            dataValue1={this.props.statistics.WorkoutsThisYear}
            dataValue2={this.props.statistics.WorkoutsLastYear}
            mainTitle={this.props.translate(SitecoreDictionary.Statistics.WorkoutsPerYear).toString()}
            title1={this.props.translate(SitecoreDictionary.Statistics.WorkoutsThisYear).toString()}
            title2={this.props.translate(SitecoreDictionary.Statistics.WorkoutsLastYear).toString()}
          />

          <ListOfStatistics
            data={this.props.statistics.WorkoutTypesPercentageLast30Days}
            mainTitle={this.props.translate(SitecoreDictionary.Statistics.ActivityTypesLast30Days).toString()}
            translate={this.props.translate}
          />
        </div>
      );
    }
  }
}
