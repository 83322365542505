import * as React from 'react';
import { NextBokning } from './nextBokning';
import { MobileLinks } from './mobileLinks';

import { StartPageDesktopBanner } from './startPageDesktopBanner';
import { LocationActionTypes } from 'store/location';
import { DesktopLinks } from './desktopLinks';
import { PassListSkeleton } from '../common/pass-list-skeleton/PassListSkeleton';
import { IconChevron } from '../icons';
import News from 'components/news';
import { CSSTransition } from 'react-transition-group';
import { EntityStatus } from 'store/global/fetch';
import { TranslateFunction } from 'react-localize-redux';

export interface StartDataProps {
  nextBokning?: fos.api.Bokning;
  translate: TranslateFunction;
  hasFetchedBokningarOnce: boolean;
  fetchBokningarStatus?: EntityStatus;
  fetchPassListStatus?: EntityStatus;
  isDesktopMode?: boolean;
  userName?: string;
  aktivFoerening?: number;
  isAuthenticated: boolean;
  news: Array<fos.api.News>;
  inloggadFoerening?: fos.api.InloggadeFoerening;
}

export interface StartActionProps {
  getNextBokning: () => void;
  getPassList: (aktivFoerening: number) => void;
  fetchBokningar: () => void;
  goToSchema: () => void;
  goToBookings: () => void;
  switchView: (location: LocationActionTypes) => void;
  getProfileInfo: (aktivFoerening: number) => void;
  redirectTo: (location: LocationActionTypes) => void;
  setSidebarToContainSchedule: () => void;
  setSidebarToVisible: () => void;
  getNewNotificationsCount: () => void;
  goToScheduleMobile: () => void;
}

export type StartProps = StartDataProps & StartActionProps;

export class Start extends React.Component<StartProps> {
  setNewsRef: (element: Element | null) => void;
  news: Element | null = null;
  constructor(props: StartProps) {
    super(props);

    this.setNewsRef = element => {
      this.news = element;
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.redirectTo(LocationActionTypes.Login);
      return;
    }
    this.props.getNewNotificationsCount();
    this.props.getNextBokning();
    if (this.props.aktivFoerening) {
      if (!this.props.isDesktopMode) {
        this.props.getPassList(this.props.aktivFoerening);
      }
      this.props.getProfileInfo(this.props.aktivFoerening);
    }
  }

  componentDidUpdate() {
    if (
      this.props.fetchBokningarStatus === undefined ||
      (this.props.fetchBokningarStatus !== EntityStatus.FETCHING &&
        this.props.fetchBokningarStatus !== EntityStatus.FETCHING_IN_BACKGROUND &&
        this.props.fetchBokningarStatus !== EntityStatus.SUCCESS)
    ) {
      this.props.fetchBokningar();
    }
  }

  scrollToNews() {
    if (this.news) {
      const scrollToValue = this.news.clientTop;
      window.scrollTo({ behavior: 'smooth', top: scrollToValue });
    }
  }

  render() {
    return (
      <div className={this.getMainContainerClasses()}>
        <div className="StartPage__Container">
          <div className={this.getMainContentClasses()}>
            {this.props.isDesktopMode && (
              <StartPageDesktopBanner
                translate={this.props.translate}
                nextBokning={this.props.nextBokning}
                BookingsFetched={this.props.hasFetchedBokningarOnce}
                userName={this.props.userName}
                openSidebarSchedule={this.props.setSidebarToContainSchedule}
                showSidebar={this.props.setSidebarToVisible}
                goToScheduleMobile={this.props.goToScheduleMobile}
              />
            )}
            {!this.props.isDesktopMode &&
              this.props.fetchBokningarStatus === EntityStatus.SUCCESS &&
              this.props.fetchPassListStatus === EntityStatus.SUCCESS && (
                <NextBokning
                  className="StartPage__Content__NextBokning"
                  pass={this.props.nextBokning}
                  translate={this.props.translate}
                  openSidebarSchedule={this.props.setSidebarToContainSchedule}
                  showSidebar={this.props.setSidebarToVisible}
                  goToScheduleMobile={this.props.goToScheduleMobile}
                  isDesktopMode={false}
                />
              )}
            {!this.props.isDesktopMode && this.props.fetchBokningarStatus !== EntityStatus.SUCCESS && (
              <PassListSkeleton numberOfDays={1} maxAmountOfPassItemsPerDay={1} />
            )}
            {this.props.isDesktopMode && (
              <DesktopLinks
                inloggadFoerening={this.props.inloggadFoerening}
                switchView={this.props.switchView}
                translate={this.props.translate}
              />
            )}
          </div>
          <CSSTransition
            mountOnEnter={true}
            timeout={500}
            classNames="StartPage__MobileLinks--Animation"
            in={
              !this.props.isDesktopMode &&
              this.props.fetchBokningarStatus === EntityStatus.SUCCESS &&
              !!this.props.nextBokning
            }
          >
            <MobileLinks
              goToBookings={this.props.goToBookings}
              goToSchema={this.props.goToScheduleMobile}
              translate={this.props.translate}
            />
          </CSSTransition>
          {this.props.news !== null && this.props.news.length > 0 && (
            <div onClick={() => this.scrollToNews()}>
              <IconChevron direction="bottom" />
            </div>
          )}
        </div>
        <div ref={this.setNewsRef}>
          <News startPageMode={true} />
        </div>
      </div>
    );
  }

  private getMainContainerClasses(): string {
    let className: string = 'StartPage';
    if (this.props.isDesktopMode) {
      className += ' StartPage--noBackground StartPage--Desktop';
    }
    return className;
  }
  private getMainContentClasses(): string {
    let className: string = 'StartPage__Content';
    if (this.props.isDesktopMode) {
      className += ' StartPage__Content--Desktop';
    }
    return className;
  }
}
