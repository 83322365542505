export interface SessionState {
  activeFoerening?: number;
  shopFoerening?: number;
  inloggadeFoereningar: fos.api.InloggadeFoerening[];
  userId?: number;
  shouldAcceptBookingRules?: boolean;
  passToBook?: fos.api.Pass;
  orderId?: number;
}

export enum SessionActionTypes {
  SetActiveFoerening = 'session/SetActiveFoerening',
  SetShopFoerening = 'session/SetShopFoerening',
  ClearActiveFoerening = 'session/ClearActiveFoerening',
  SetInloggadeFoereningar = 'session/SetInloggadeFoereningar',
  SetFoereningExtraInfo = 'session/setFoereningExtraInfo',
  SetUserId = 'session/SetUserId',
  ShouldAcceptBookingRules = 'session/SHOULD_ACCEPT_BOOKING_RULES',
  SetPassIdToBook = 'session/SET_PASS_ID_TO_BOOK',
  SetOrderId = 'session/SET_ORDER_ID',
}
export interface ClearActiveFoereningAction {
  type: SessionActionTypes.ClearActiveFoerening;
}

export interface SetActiveFoereningAction {
  type: SessionActionTypes.SetActiveFoerening;
  foereningId: number;
}

export interface SetShopFoereningAction {
  type: SessionActionTypes.SetShopFoerening;
  foereningId?: number;
}

export interface SetInloggadeFoereningarAction {
  type: SessionActionTypes.SetInloggadeFoereningar;
  inloggadeFoereningar: fos.api.InloggadeFoerening[];
}

export interface SetFoereningExtraInfoAction {
  type: SessionActionTypes.SetFoereningExtraInfo;
  extraInfo: fos.api.ExtraFoereningInfo;
  foereningName: string;
}

export interface SetUserIdAction {
  type: SessionActionTypes.SetUserId;
  userId: number;
}

export interface ShouldAcceptBookingRulesAction {
  type: SessionActionTypes.ShouldAcceptBookingRules;
  value: boolean;
}

export interface SetPassIdToBookAction {
  type: SessionActionTypes.SetPassIdToBook;
  value?: fos.api.Pass;
}

export interface SetOrderIdAction {
  type: SessionActionTypes.SetOrderId;
  orderId?: number;
}
