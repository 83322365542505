export enum changePasswordActionType {
  UPDATE_CURRENT_PASSWORD_VALUE = 'USER/CHANGE_PASSWORD_CMD/UPDATE_CURRENT_PASSWORD_VALUE',
  UPDATE_NEW_PASSWORD_VALUE = 'USER/CHANGE_PASSWORD_CMD/UPDATE_NEW_PASSWORD_VALUE',
  UPDATE_REPEAT_NEW_PASSWORD_VALUE = 'USER/CHANGE_PASSWORD_CMD/UPDATE_REPEAT_NEW_PASSWORD_VALUE',
  UPDATE_NEW_PASSWORD_VALIDITY = 'USER/CHANGE_PASSWORD_CMD/UPDATE_NEW_PASSWORD_VALIDITY',
  UPDATE_REPEAT_NEW_PASSWORD_VALIDITY = 'USER/CHANGE_PASSWORD_CMD/UPDATE_REPEAT_NEW_PASSWORD_VALIDITY',
  UPDATE_CURRENT_PASSWORD_VALIDITY = 'USER/CHANGE_PASSWORD_CMD/UPDATE_CURRENT_PASSWORD_VALIDITY',
  UPDATE_FORM_VALIDITY = 'USER/CHANGE_PASSWORD_CMD/UPDATE_FORM_VALIDITY',
  CLEAR = 'USER/CHANGE_PASSWORD_CMD/CLEAR',
}

interface SetCurrentPasswordAction {
  type: changePasswordActionType.UPDATE_CURRENT_PASSWORD_VALUE;
  newValue: string;
}

interface SetNewPasswordAction {
  type: changePasswordActionType.UPDATE_NEW_PASSWORD_VALUE;
  newValue: string;
}

interface SetRepeatNewPasswordAction {
  type: changePasswordActionType.UPDATE_REPEAT_NEW_PASSWORD_VALUE;
  newValue: string;
}

interface SetNewPasswordValidityAction {
  type: changePasswordActionType.UPDATE_NEW_PASSWORD_VALIDITY;
  isValid: boolean;
}

interface SetRepeatNewPasswordValidityAction {
  type: changePasswordActionType.UPDATE_REPEAT_NEW_PASSWORD_VALIDITY;
  isValid: boolean;
}

interface SetCurrentPasswordValidityAction {
  type: changePasswordActionType.UPDATE_CURRENT_PASSWORD_VALIDITY;
  isValid: boolean;
}

interface SetFormValidityAction {
  type: changePasswordActionType.UPDATE_FORM_VALIDITY;
  isValid: boolean;
}

interface ClearCMDAction {
  type: changePasswordActionType.CLEAR;
}

const setCurrentPassword = (newValue: string = ''): SetCurrentPasswordAction => ({
  type: changePasswordActionType.UPDATE_CURRENT_PASSWORD_VALUE,
  newValue,
});

const setNewPassword = (newValue: string = ''): SetNewPasswordAction => ({
  type: changePasswordActionType.UPDATE_NEW_PASSWORD_VALUE,
  newValue,
});

const setRepeatNewPassword = (newValue: string = ''): SetRepeatNewPasswordAction => ({
  type: changePasswordActionType.UPDATE_REPEAT_NEW_PASSWORD_VALUE,
  newValue,
});

const setNewPasswordValidity = (isValid: boolean = false): SetNewPasswordValidityAction => ({
  type: changePasswordActionType.UPDATE_NEW_PASSWORD_VALIDITY,
  isValid,
});

const setRepeatNewPasswordValidity = (isValid: boolean = false): SetRepeatNewPasswordValidityAction => ({
  type: changePasswordActionType.UPDATE_REPEAT_NEW_PASSWORD_VALIDITY,
  isValid,
});

const setCurrentPasswordValidity = (isValid: boolean = false): SetCurrentPasswordValidityAction => ({
  type: changePasswordActionType.UPDATE_CURRENT_PASSWORD_VALIDITY,
  isValid,
});

const setFormValidity = (isValid: boolean = false): SetFormValidityAction => ({
  type: changePasswordActionType.UPDATE_FORM_VALIDITY,
  isValid,
});

const clearCmd = (): ClearCMDAction => ({
  type: changePasswordActionType.CLEAR,
});

export const changePasswordActions = {
  setCurrentPassword,
  setNewPassword,
  setRepeatNewPassword,
  setNewPasswordValidity,
  setRepeatNewPasswordValidity,
  setCurrentPasswordValidity,
  setFormValidity,
  clearCmd,
};

export type ChangePasswordAction =
  | SetCurrentPasswordAction
  | SetNewPasswordAction
  | SetRepeatNewPasswordAction
  | SetNewPasswordValidityAction
  | SetRepeatNewPasswordValidityAction
  | SetCurrentPasswordValidityAction
  | SetFormValidityAction
  | ClearCMDAction;
