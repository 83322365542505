import { fetched, fetching, FetchStatusAggregateTypes, fetchedFailed } from 'store/global/fetch';
import { api } from 'api';
import { Dispatch } from 'store';
import { Action } from 'redux';
import { getTraeningsstatistik } from './read';

export const fetchTraeningsstatistik = () => (dispatch: Dispatch<Action>) => {
  dispatch(fetching(-1, FetchStatusAggregateTypes.TRAENINGSSTATISTIK));
  let url = `Traeningsstatistik/GetTraeningsstatistik`;
  let promise = api.get<fos.api.TrainingStatistics>(url, {
    muteErrorNotificationOnStatus: [403],
  });
  promise.then(result => {
    if (!result.error) {
      let statistics: fos.api.TrainingStatistics = result.result;
      dispatch(getTraeningsstatistik(statistics));
      dispatch(fetched(-1, FetchStatusAggregateTypes.TRAENINGSSTATISTIK, statistics));
    } else {
      dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.TRAENINGSSTATISTIK));
    }
  });
  return promise;
};
