export enum EntityStatus {
  INITIAL = 'ENTITY_STATUS/INITIAL',
  FETCHING = 'ENTITY_STATUS/FETCHING',
  FETCHING_IN_BACKGROUND = 'ENTITY_STATUS/FETCHING_IN_BACKGROUND',
  SUCCESS = 'ENTITY_STATUS/SUCCESS', // TODO - FETCHED?
  FAILED = 'ENTITY_STATUS/FAILED',
  SAVING = 'ENTITY_STATUS/SAVING',
  SAVING_FAILED = 'ENTITY_STATUS/SAVING_FAILED',
  DELETED = 'ENTITY_STATUS/DELETED',
}

export enum CommandStatus {
  INITIAL = 'COMMAND_STATUS/INITIAL',
  EXECUTING = 'COMMAND_STATUS/EXECUTING',
  EXECUTED = 'COMMAND_STATUS/EXECUTED',
  EXECUTING_FAILED = 'COMMAND_STATUS/EXECUTING_FAILED',
}

// Används för aggregat som har id:n
export type FetchStatus = FetchAggregateStatus | FetchCommandStatus;
export interface FetchAggregateStatus {
  readonly id: number | string;
  readonly contextType: ContextType;
  readonly aggregateType: FetchStatusAggregateTypes;
  readonly commandType: undefined;
  readonly status: EntityStatus | CommandStatus;
  readonly reason: string | null;
  readonly date: Date;
}

export interface FetchCommandStatus {
  readonly id: string;
  readonly contextType: ContextType;
  readonly aggregateType: undefined;
  readonly commandType: FetchCommandTypes;
  readonly status: EntityStatus | CommandStatus;
  readonly reason: string | null;
  readonly date: Date;
}

export enum ContextTypes {
  MITT_FRISKIS = 'CONTEXT_TYPES/MITT_FRISKIS',
}

export enum FetchStatusAggregateTypes {
  PASS = 'FETCH_STATUS_AGGREGATE_TYPES/PASS',
  PASS_LIST = 'FETCH_STATUS_AGGREGATE_TYPES/PASS_LIST',
  BOKNING = 'FETCH_STATUS_AGGREGATE_TYPES/BOKNING',
  ANVAENDARE = 'FETCH_STATUS_AGGREGATE_TYPES/ANVAENDARE',
  MEDLEMSKAP_OCH_KORT = 'FETCH_STATUS_AGGREGATE_TYPES/MEDLEMSKAP_OCH_KORT',
  MEDLEM_VILLKOR = 'FETCH_STATUS_AGGREGATE_TYPES/MEDLEM_VILLKOR',
  SMS_EMAIL_VILLKOR = 'FETCH_STATUS_AGGREGATE_TYPES/SMS_EMAIL_VILLKOR',
  TRAENINGSSTATISTIK = 'FETCH_STATUS_AGGREGATE_TYPES/TRAENINGSSTATISTIK',
  REGISTRERADE_BETALNINGS_MEDEL = 'FETCH_STATUS_AGGREGATE_TYPES/REGISTRERADE_BETALNINGS_MEDEL',
  ANLAEGGNINGAR = 'FETCH_STATUS_AGGREGATE_TYPES/ANLAEGGNIGNAR',
  GET_PRODUCTS = 'FETCH_STATUS_AGGREGATE_TYPES/GET_PRODUCTS',
  GET_FILTER = 'FETCH_STATUS_AGGREGATE_TYPES/GET_FILTER',
  GET_FOERENINGAR = 'FETCH_STATUS_AGGREGATE_TYPES/GET_FOERENINGAR',
  GET_PAYMENT_METHODS = 'FETCH_STATUS_AGGREGATE_TYPES/GET_PAYMENT_METHODS',
  NOTIFICATIONS = 'FETCH_STATUS_AGGREGATE_TYPES/NOTIFICATIONS',
  NOTIFICATIONS_SETTINGS = 'FETCH_STATUS_AGGREGATE_TYPES/NOTIFICATIONS_SETTINGS',
  NEWS = 'FETCH_STATUS_AGGREGATE_TYPES/NEWS',
  SIGNATURECASE = 'FETCH_STATUS_AGGREGATE_TYPES/SIGNATURECASE',
  PRODUCT_INFO = 'FETCH_STATUS_AGGREGATE_TYPES/PRODUCT_INFO',
  GET_BILDER = 'FETCH_STATUS_AGGREGATE_TYPES/GET_BILDER',
  GET_RECEIPT = 'FETCH_STATUS_AGGREGATE_TYPES/GET_RECEIPT',
  GET_PERSON_LOOKUP_AVAILABLE = 'FETCH_STATUS_AGGREGATE_TYPES/GET_PERSON_LOOKUP_AVAILABLE',
  GET_PERSON_LOOKUP = 'FETCH_STATUS_AGGREGATE_TYPES/GET_PERSON_LOOKUP',
  GET_ORDER = 'FETCH_STATUS_AGGREGATE_TYPES/GET_ORDER',
  GET_CALENDAR = 'FETCH_STATUS_AGGREGATE_TYPES/GET_CALENDAR',
  RECEIPT_INFOS = 'FETCH_STATUS_AGGREGATE_TYPES/RECEIPT_INFOS',
}

export enum FetchCommandTypes {
  SKAPA_MEDLEMSSKAP = 'FETCH_COMMAND_TYPES/SKAPA_MEDLEMSSKAP',
  FOERNYA_MEDLEMSSKAP = 'FETCH_COMMAND_TYPES/FOERNYA_MEDLEMSSKAP',
  AVSLUTA_MEDLEMSSKAP = 'FETCH_COMMAND_TYPES/AVSLUTA_MEDLEMSSKAP',
  BOKA_PASS = 'FETCH_COMMAND_TYPES/BOKA_PASS',
  AVBOKA_PASS = 'FETCH_COMMAND_TYPES/AVBOKA_PASS',
  LOGIN = 'FETCH_COMMAND_TYPES/LOGIN',
  SKAPA_ANVAENDARE = 'FETCH_COMMAND_TYPES/SKAPA_ANVAENDARE',
  SPARA_ANVAENDARE = 'FETCH_COMMAND_TYPES/SPARA_ANVAENDARE',
  AENDRA_LOESENORD = 'FETCH_COMMAND_TYPES/AENDRA_LOESENORD',
  RESET_LOESENORD = 'FETCH_COMMAND_TYPES/RESET_LOESENORD',
  UPDATE_AUTOGIRO = 'FETCH_COMMAND_TYPES/UPDATE_AUTOGIRO',
  BUY_BASKET = 'FETCH_COMMAND_TYPES/BUY_BASKET',
  MARK_NOTIFICATIONS_AS_READ = 'FETCH_COMMAND_TYPES/MARK_NOTIFICATIONS_AS_READ',
  REGISTER_DEVICE_ID = 'FETCH_COMMAND_TYPES/REGISTER_DEVICE_ID',
  ACCEPT_BOOKING_RULES = 'FETCH_COMMAND_TYPES/ACCEPT_BOOKING_RULES',
  CREATE_ORDER = 'FETCH_COMMAND_TYPES/CREATE_ORDER',
  SET_CALENDAR_SYNC = 'SET_CALENDAR_SYNC',
  SET_SELECTED_CALENDAR = 'SET_SELECTED_CALENDAR',
  ADD_TO_ORDER = 'ADD_TO_ORDER',
  REMOVE_FROM_ORDER = 'REMOVE_FROM_ORDER',
  SET_USER_ON_ORDER = 'FETCH_COMMAND_TYPES/SET_USER_ON_ORDER',
}

export type ContextType =
  | ContextTypes.MITT_FRISKIS
  | FetchStatusAggregateTypes.MEDLEM_VILLKOR
  | FetchStatusAggregateTypes.ANLAEGGNINGAR;
