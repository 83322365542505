import * as React from 'react';
import { getDateTextForView } from 'websd/utils';

export interface DayPickerDataProps {
  days: string[];
  day: string;
  isExpanded: boolean;
  centerDay: string;
  date: Date;
  visible: boolean;
  clickFromDayPickerDay: string;
}

export interface DayPickerActionProps {
  setScrollToDay: (day: string) => void; // Scroll to clicked day
  updateDayExpanded?: (dayName: string, expanded: boolean) => void;
  toggleDayPickerExpanded: (isExpanded: boolean) => void;
  setDayClicked: (day: string) => void;
  setClicked: (clickFromDayPicker: boolean) => void;
}

type DayPickerProps = Readonly<DayPickerDataProps> & Readonly<DayPickerActionProps>;

export class DayPicker extends React.Component<DayPickerProps> {
  expand(expandedDay: string) {
    if (this.props.updateDayExpanded) {
      this.props.toggleDayPickerExpanded(true);
      this.props.updateDayExpanded(this.props.day, true);
    }
  }

  collapse(event: React.MouseEvent<HTMLElement>, day?: string) {
    event.stopPropagation();

    this.props.toggleDayPickerExpanded(false);

    if (this.props.updateDayExpanded) {
      this.props.updateDayExpanded(this.props.day, false);
    }
    if (day) {
      this.props.setDayClicked(day);
      this.props.setClicked(true);

      this.props.setScrollToDay(day);
    }
  }

  getClassName(day: string) {
    if (this.props.isExpanded) {
      return '';
    }
    if (this.props.centerDay !== '') {
      if (this.props.centerDay === day) {
        return '';
      }
      return 'DayPicker__Item--inactive';
    }

    return day === this.props.day ? '' : 'DayPicker__Item--inactive';
  }

  onDayPickerListItemClick(event: React.MouseEvent<HTMLElement>, dayName: string) {
    if (this.props.isExpanded && this.props.centerDay !== dayName) {
      this.collapse(event, dayName);
    }
  }

  onDayPickerClick(event: React.MouseEvent<HTMLElement>) {
    if (this.props.isExpanded) {
      this.collapse(event);
    } else {
      this.expand(this.props.day);
    }
  }

  getDate(day: string | Date) {
    return getDateTextForView(day);
  }

  render() {
    return (
      <div
        className={
          'DayPicker' +
          (!this.props.visible ? ' DayPicker--hidden' : '') +
          (this.props.isExpanded ? ' DayPicker--expanded' : '')
        }
        onClick={event => this.onDayPickerClick(event)}
      >
        <div className="Gradients">
          <div className="Gradients__Top" />
          <div className="Gradients__Bottom" />
        </div>
        {this.props.isExpanded && (
          <ul>
            {this.props.days.map(day => (
              <li key={day} className={this.getClassName(day)} onClick={e => this.onDayPickerListItemClick(e, day)}>
                {day}
              </li>
            ))}
          </ul>
        )}
        {!this.props.isExpanded && <div className="DayPicker__Header">{this.getDate(this.props.date)}</div>}
      </div>
    );
  }
}
