import { Reducer } from 'redux';
import { Action } from 'store';
import { UIBookingActionType, SetScrollToDayAction, UIBookingSetSelectedDayAction } from '.';

export interface UIBookingState {
  scrollToDay: string;
  centerDay: string;
}

const initialState: UIBookingState = {
  scrollToDay: '',
  centerDay: '',
};

export const setScrollToDay = (day: string): SetScrollToDayAction => ({
  type: UIBookingActionType.SetScrollToDay,
  scrollToDay: day,
});

export const setSelectedDay = (day: string): UIBookingSetSelectedDayAction => ({
  type: UIBookingActionType.SetSelectedDayPickerDay,
  centerDay: day,
});

export const bookingUiReducer: Reducer<UIBookingState> = (state = initialState, action: Action): UIBookingState => {
  switch (action.type) {
    case UIBookingActionType.SetScrollToDay:
      return { ...state, scrollToDay: action.scrollToDay };
    case UIBookingActionType.SetSelectedDayPickerDay:
      return { ...state, centerDay: action.centerDay };
    default:
      return state;
  }
};
