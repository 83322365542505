import * as React from 'react';
import { ConfirmationTextBox, IconButton } from 'components/common';
import { SitecoreDictionary, SitecorePlaceholder, BokningsStatusEnum } from 'models';
import { OK_STATUS_CLASS, ERROR_STATUS_CLASS } from 'components/pass-details/model';
import { getDateTextForView } from 'websd/utils';
import { TranslateFunction } from 'react-localize-redux';
import { ErrorResult, SuccessResult } from 'websd/utils/configureApi';
import { BokningResponse } from 'store/pass';

export interface PassBookerExternalDataProps {
  pass: fos.api.Pass;
  shouldShowInfoOnHover: boolean;
  translate: TranslateFunction;
  onBokatEllerAvbokat: () => void;
}

export interface PassBookerInternalDataProps {
  bokningar?: Array<fos.api.Bokning>;
  isExecuting: boolean;
}

export interface PassBookerActionProps {
  bokaPass: (pass: fos.api.Pass, bokaKoePlats: boolean) => Promise<ErrorResult | SuccessResult<BokningResponse>>;
  scheduleAvbokaPass: (
    payload: fos.api.CancelPayload,
    passId: string,
    key: string
  ) => Promise<ErrorResult | SuccessResult<fos.api.AvbokningResponse>>;
}

export type PassBookerDataProps = PassBookerInternalDataProps & PassBookerExternalDataProps;
export type PassBookerProps = PassBookerDataProps & PassBookerActionProps;

export const PassBooker: React.FunctionComponent<PassBookerProps> = ({
  bokningar,
  translate,
  isExecuting,
  pass,
  shouldShowInfoOnHover,
  onBokatEllerAvbokat,
  bokaPass,
  scheduleAvbokaPass,
}) => {
  const [confirmationVisible, setConfirmationVisible] = React.useState(false);
  const [isHoveringOnPassBooker, setIsHoveringOnPassBooker] = React.useState(false);

  const avbokaPass = () => {
    if (!bokningar) {
      return;
    }

    let bokning = bokningar.find(b => b.PassId.toString() === pass.PassId.toString());

    if (bokning) {
      let payload = {
        bokningId: bokning.BokningId.toString(),
        foereningsId: bokning.FoereningId,
        queued: bokning.Koad,
        passId: bokning.PassId,
        anlaeggningsId: bokning.AnlaeggningsId,
      };
      const date = getDateTextForView(pass.StartTime);
      scheduleAvbokaPass(payload, pass.PassId, date).then(() => {
        onBokatEllerAvbokat();
      });
    }
    setConfirmationVisible(false);
  };

  const getConfirmationText = () => {
    let confirmationText = translate(SitecoreDictionary.Schedule.ConfirmBook);
    if (bokningar) {
      if (bokningar.find(b => b.PassId === pass.PassId)) {
        confirmationText = translate(SitecoreDictionary.Schedule.ConfirmCancel);
      }
    }

    confirmationText = confirmationText.toString().replace(SitecorePlaceholder.PASS, pass.PassNamn);
    confirmationText = confirmationText.toString().replace(SitecorePlaceholder.FOERENING, pass.AnlaeggningsNamn);

    return confirmationText;
  };

  const getIconButtonText = () => {
    let bokning = bokningar ? bokningar.find(b => b.PassId.toString() === pass.PassId) : undefined;
    let str = '';

    if (!shouldShowInfoOnHover) {
      return str;
    }

    if (pass.disabled) {
      return str;
    }

    if (pass.AerInstaelld) {
      return `${translate(SitecoreDictionary.Schedule.Messages.CANCELLED)}`;
    }

    if (bokning && bokning.Koad) {
      str = `${translate(SitecoreDictionary.PassItem.QueuePosition)} ${bokning.KoPlats}`;
    } else if (bokning) {
      if (pass.AntalLedigaPlatser === 0) {
        str = `${pass.AntalIVaenteLista} ${translate(SitecoreDictionary.PassItem.InQueue)}`;
      } else {
        str = `${pass.AntalLedigaPlatser} ${translate(SitecoreDictionary.PassItem.Left)}`;
      }
    } else if (pass.BokningStatus === BokningsStatusEnum.DROPIN) {
      str = `${pass.AntalDropinPlatser.toString()} drop-in`;
    } else if (
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART ||
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_DROPIN
    ) {
      str = `${pass.AntalLedigaPlatser} ${translate(SitecoreDictionary.PassItem.Left)}`;
    } else if (
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
      pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN
    ) {
      str = `${pass.AntalIVaenteLista} ${translate(SitecoreDictionary.PassItem.InQueue)}`;
    }

    if (shouldShowInfoOnHover && isHoveringOnPassBooker && getPassStatus() !== 'NotBooked') {
      str = 'Klicka för att avboka';
    }

    return str;
  };

  const getStatusMessageClass = () => {
    if (
      pass.statusMessage === SitecoreDictionary.Schedule.Messages.OK_BOOK ||
      pass.statusMessage === SitecoreDictionary.Schedule.Messages.QUEUED
    ) {
      return OK_STATUS_CLASS;
    } else {
      return ERROR_STATUS_CLASS;
    }
  };

  const getBookedPass = () => {
    return bokningar ? bokningar.find(b => b.PassId === pass.PassId) : undefined;
  };

  const getPassStatus = (): 'Booked' | 'NotBooked' | 'Queued' | 'Cancelled' => {
    const bookedPass = getBookedPass();
    return !bookedPass ? 'NotBooked' : bookedPass.Koad ? 'Queued' : pass.AerInstaelld ? 'Cancelled' : 'Booked';
  };

  const getIconButtonStyle = () => {
    switch (getPassStatus()) {
      case 'Booked':
        return '--Booked';
      case 'Queued':
        return '--BookedWithQueue';
      case 'Cancelled':
        return '--Cancelled';
      default:
        switch (pass.BokningStatus) {
          case BokningsStatusEnum.EJ_BOKNINGSBART_DATE_DROPIN:
            return '--NotAvailableYet';
          case BokningsStatusEnum.FOERTIDIGT_FOER_ATT_BOKA:
            return '--EarlyToBook';
          case BokningsStatusEnum.FOERSENT_FOER_ATT_BOKA:
            return '--LateToBook';
          case BokningsStatusEnum.BOKNINGSBART:
            return '--Available';
          case BokningsStatusEnum.BOKNINGSBART_DROPIN:
            return '--Available';
          case BokningsStatusEnum.BOKNINGSBART_KOE:
            return '--AvailableWithQueue';
          case BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN:
            return '--AvailableWithQueue';
          case BokningsStatusEnum.FULLT || BokningsStatusEnum.FULLT_DROPIN:
            return '--MaxCapacity';
          case BokningsStatusEnum.DROPIN:
            return '--Dropin';
          case BokningsStatusEnum.INSTAELLD:
            return '--Cancelled';
          default:
            return '--Available';
        }
    }
  };

  const handleOnClickIconButton = () => {
    if (!shouldShowInfoOnHover) {
      return;
    }
    const bookable =
      pass.BokningStatus !== BokningsStatusEnum.DROPIN && pass.BokningStatus !== BokningsStatusEnum.INSTAELLD;
    if (bokningar && !!bokningar.find(b => b.PassId === pass.PassId)) {
      setConfirmationVisible(true);
      return;
    } else if (bookable) {
      const bokaKoePlats =
        pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
        pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN;
      bokaPass(pass, bokaKoePlats).then(() => {
        onBokatEllerAvbokat();
      });
    }
  };

  return (
    <div className="PassBooker">
      <div
        className="IconButtonHoverTrigger"
        onMouseOver={() => setIsHoveringOnPassBooker(true)}
        onMouseOut={() => setIsHoveringOnPassBooker(false)}
      >
        <IconButton
          isExecuting={isExecuting}
          handleOnClick={() => handleOnClickIconButton()}
          text={getIconButtonText()}
          styleType={getIconButtonStyle()}
          cssClass={getStatusMessageClass()}
        />
      </div>

      {confirmationVisible && (
        <ConfirmationTextBox
          handleNo={() => setConfirmationVisible(false)}
          translate={translate}
          handleYes={() => avbokaPass()}
          text={getConfirmationText()}
        />
      )}
    </div>
  );
};
