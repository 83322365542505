import { combineReducers } from 'redux';
import { InitializeActionTypes, SetInitializedAction } from './initializeActions';
import { Action } from 'store';

interface InitializeState {
  initialized: boolean;
}

export interface InitializeRootState {
  read: InitializeState;
}

const initialState: InitializeState = {
  initialized: false,
};

export const setInitialized = (initialized: boolean): SetInitializedAction => ({
  type: InitializeActionTypes.SET_INITIALIZED,
  initialized,
});

export const reducer = (state: InitializeState = initialState, action: Action): InitializeState => {
  switch (action.type) {
    case InitializeActionTypes.SET_INITIALIZED:
      return { ...state, initialized: action.initialized };
    default:
      return { ...state };
  }
};

export const initializeReducer = combineReducers<InitializeRootState>({
  read: reducer,
});
