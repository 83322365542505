import { connect } from 'react-redux';
import { ShopFilter, ShopFilterActionProps, ShopFilterDataProps, ShopFilterType } from './ShopFilter';
import { RootState, Dispatch } from 'store';
import { UIScheduleFilterAction, UIScheduleFilterActionType } from 'store/global/ui/schedule-filter';
import { UIShopFilterAction, UIShopFilterActionType } from 'store/global/ui/shop-filter';
import { fetchPassListFromActiveFoereningInBackground } from 'store/pass';
import { getBookingsInBackground } from 'store/bokningar';

const expandFilter = (expanded: boolean, filterType: ShopFilterType) => (
  dispatch: Dispatch<UIScheduleFilterAction | UIShopFilterAction>
) => {
  if (filterType === ShopFilterType.Schedule) {
    if (expanded) {
      dispatch({
        type: UIScheduleFilterActionType.SetFilterToMinimised,
        expanded: false,
      });
    } else {
      dispatch({
        type: UIScheduleFilterActionType.SetFilterToExpanded,
        expanded: true,
      });
    }
  }

  if (filterType === ShopFilterType.Shop) {
    if (expanded) {
      dispatch({
        type: UIShopFilterActionType.SetFilterToMinimised,
        expanded: false,
      });
    } else {
      dispatch({
        type: UIShopFilterActionType.SetFilterToExpanded,
        expanded: true,
      });
    }
  }
};

const mapState = (state: RootState, props: any): ShopFilterDataProps => {
  const expanded = props.filterType === ShopFilterType.Schedule ? state.global.ui.scheduleFilter.expanded : true;
  return {
    productsIsShowing: props.productsIsShowing,
    onHideProductClick: props.onHideProductClick,

    isAuthenticated: !!state.auth.read.isAuthenticated,
    isPublicWebMode: props.isPublicWebMode,
    expanded: expanded,
    filterUIState: props.filterUIState,
    filterHidden: props.filterHidden,
    userFilters: props.userFilters,
    filterableProperties: props.filterableProperties,
    filterFunctions: props.filterFunctions,
    totalNumberOfItems: props.totalNumberOfItems,
    maxNumberOfItemsExcceeded: props.maxNumberOfItemsExcceeded,
    translate: props.translate,
    onFilterSave: props.onFilterSave,
    onFilterDelete: props.onFilterDelete,
    onClearFilters: props.onClearFilters,
    listItemGroupingMappings: props.listItemGroupingMappings,
    isFullDesktopView: props.isFullDesktopView,
    isDesktopMode: props.isDesktopMode,
    scheduleIsFetching: props.scheduleIsFetching,
    filterType: props.filterType,
    hideFilterSaving: props.hideFilterSaving,
    hideTrashCan:
      state.product.fullSetOfFilterProperties.ProductWho.length < 2 &&
      state.product.fullSetOfFilterProperties.ProductWhere.length < 2 &&
      state.product.fullSetOfFilterProperties.ProductWhen.length < 2 &&
      state.product.fullSetOfFilterProperties.ProductHow.length < 2 &&
      props.filterType === ShopFilterType.Shop,
    shopFilter: props.shopFilter,
    selectedTab: props.selectedTab,
    giveToggleButtonSomeFocus: props.giveToggleButtonSomeFocus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: any): ShopFilterActionProps => ({
  expandFilter: (expanded: boolean, filterType: ShopFilterType) => {
    dispatch(expandFilter(expanded, filterType));
    if (expanded) {
      dispatch(fetchPassListFromActiveFoereningInBackground());
      dispatch(getBookingsInBackground());
    }
  },
  selectAllCategoryListItems: props.selectAllCategoryListItems,
  unSelectCategoryHeader: props.unSelectCategoryHeader,
});

export default connect(mapState, mapDispatchToProps)(ShopFilter);
