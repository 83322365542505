import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';

import { Location } from 'store/location/locationModels';
import { LocationActionTypes as RouteActionType } from 'store/location/locationActions';
import { getLocation, goBack } from 'store/location';
import Profile from 'components/settings/profile';
import Start from '../start';
import Login from '../login/LoginContainer';
import AdminLogin from '../adminLogin/AdminLoginContainer';
import NotFound from './NotFoundContainer';
import RequestResetPasswordContainer from 'components/request-password-reset';
import Settings, { SettingsPages } from 'components/settings';
// import Profile from '../settings/profile';
import PaymentSettings from '../settings/paymentSettings';
import TrainingPreferences from '../settings/trainingpreferences';
import SubscriptionsAndCardsContainer from 'components/subscriptionsAndCards';
import PassDetailsContainer from '../pass-details';
import UserAccount from 'components/settings/userAccount';
import { ShoppingBasketContainer } from 'components/shoppingBasket';
import { FacilitiesContainer } from 'components/facilities/';
import News from '../news/';
import { PaymentContainer } from 'components/payment';
import { ReceiptContainer } from 'components/receipt';
import BookingsContainer from '../bookings/';
import { ShopContainer } from 'components/shop';
import Notifications from '../notifications/';
import NotificationSettings from 'components/settings/notification-settings';
import CalendarSync from 'components/settings/calendarSync';
import Statistics from '../statistics/';
import ResetPassword from 'components/reset-password';
import SchedulePage from 'components/schedulePage';
import { ProductDetailsContainer } from 'components/shop/Products/product-details/ProductDetailsContainer';
import { NewUserFormContainer } from 'components/shoppingBasket/forms/newUserForm';
import ScheduleContainer from 'components/schedule/schedule';
import { ShopWrapperContainer } from 'components/shop';

const NotFoundElementSync = <NotFound />;
const LoginPage = <Login />;
const AdminLoginPage = <AdminLogin />;
const RequestResetPasswordPage = <RequestResetPasswordContainer />;
const SubscriptionsPage = <SubscriptionsAndCardsContainer />;
const FacilitiesPage = <FacilitiesContainer />;
const NewsPage = <News />;
const PaymentPage = <PaymentContainer />;
const ResetPasswordPage = <ResetPassword />;

interface SwitchPageDataProps {
  location: Location;
  isDesktopMode: boolean;
  isPublicWebMode: boolean;
  isDesktopFullViewMode: boolean;
}

interface SwitchPageActionProps {
  goBack: () => void;
}

type SwitchPageProps = Readonly<SwitchPageDataProps & SwitchPageActionProps>;

export const SwitchPage: React.SFC<SwitchPageProps> = (props: SwitchPageProps) => {
  const {
    location,
    // authenticated,
    isDesktopMode,
    isPublicWebMode,
    isDesktopFullViewMode,
  } = props;
  switch (location.type) {
    case RouteActionType.Home:
      return <Start isDesktopMode={isDesktopMode} />; // :
    // authenticated ?
    // LoginPage;
    case RouteActionType.Login:
      return LoginPage;
    case RouteActionType.AdminLogin:
      return AdminLoginPage;
    case RouteActionType.Schedule:
      return isDesktopMode ? (
        <SchedulePage />
      ) : (
        <>
          <SchedulePage />
          <PassDetailsContainer isDesktopMode={false} />
        </>
      );
    case RouteActionType.PublicSchedule:
      return (
        <ScheduleContainer
          isDesktopMode={isDesktopMode}
          isFullDesktopView={isDesktopFullViewMode}
          isPublicWebMode={isPublicWebMode}
        />
      );
    case RouteActionType.Reset:
      return RequestResetPasswordPage;
    case RouteActionType.Settings:
      return <Settings isDesktopMode={isDesktopMode} />;
    case RouteActionType.Settings_Profile:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.Profile} isDesktopMode={isDesktopMode} />
      ) : (
        <Profile />
      );
    case RouteActionType.Settings_UserAccount:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.UserAccount} isDesktopMode={isDesktopMode} />
      ) : (
        <UserAccount />
      );
    case RouteActionType.Settings_PaymentSettings:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.PaymentSettings} isDesktopMode={isDesktopMode} />
      ) : (
        <PaymentSettings />
      );
    case RouteActionType.Settings_NotificationSettings:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.NotificationSettings} isDesktopMode={isDesktopMode} />
      ) : (
        <NotificationSettings />
      );

    case RouteActionType.Settings_CalendarSyncSettings:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.CalendarSync} isDesktopMode={isDesktopMode} />
      ) : (
        <CalendarSync />
      );
    case RouteActionType.Settings_TrainingPreferences:
      return isDesktopMode ? (
        <Settings activeSettingsPage={SettingsPages.TrainingPreferences} isDesktopMode={isDesktopMode} />
      ) : (
        <TrainingPreferences />
      );
    case RouteActionType.Bookings:
      return <BookingsContainer isDesktopMode={isDesktopMode} />;
    case RouteActionType.Subscriptions:
      return SubscriptionsPage;
    case RouteActionType.PassDetails:
      return isDesktopMode ? (
        <PassDetailsContainer isDesktopMode={true} />
      ) : (
        <>
          <SchedulePage />
          <PassDetailsContainer isDesktopMode={false} />
        </>
      );
    case RouteActionType.ShoppingBasket:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShoppingBasketContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.PublicShoppingBasket:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShoppingBasketContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.PublicProductDetails:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ProductDetailsContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.AdminShoppingBasket:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShoppingBasketContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.AdminProductDetails:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ProductDetailsContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.Facilities:
      return FacilitiesPage;
    case RouteActionType.News:
      return NewsPage;
    case RouteActionType.Payment:
      return PaymentPage;
    case RouteActionType.Shop:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShopContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.AdminShop:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShopContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.PublicRegister:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <div className="ShoppingBasket__Container">
            <NewUserFormContainer isDesktopMode={isDesktopMode} />
          </div>
        </ShopWrapperContainer>
      );
    case RouteActionType.AdminRegister:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <div className="ShoppingBasket__Container">
            <NewUserFormContainer isDesktopMode={isDesktopMode} />
          </div>
        </ShopWrapperContainer>
      );
    case RouteActionType.ProductDetails:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ProductDetailsContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.PublicShop:
      return (
        <ShopWrapperContainer isDesktopMode={isDesktopMode}>
          <ShopContainer isDesktopMode={isDesktopMode} />
        </ShopWrapperContainer>
      );
    case RouteActionType.Notifications:
      return <Notifications />;
    case RouteActionType.Statistics:
      return <Statistics />;
    case RouteActionType.ResetPassword:
      return ResetPasswordPage;
    case RouteActionType.Receipt:
      return <ReceiptContainer />;
    default:
      return NotFoundElementSync;
  }
};

const mapStateToProps = (state: RootState, props: any): SwitchPageDataProps => ({
  location: getLocation(state.location),
  // authenticated: state.auth.read.isAuthenticated,
  isDesktopMode: props.isDesktopMode,
  isPublicWebMode: props.isPublicWebMode,
  isDesktopFullViewMode: props.isDesktopFullViewMode,
});

const mapActionsToProps: SwitchPageActionProps = {
  goBack: goBack,
};

export default connect(mapStateToProps, mapActionsToProps)(SwitchPage);
