import { constructSafeName } from 'utils/helpers';
import { addSitecoreAnlaeggningar } from './read/anlaeggningarReadActions';
import { api } from 'api';
import { ThunkAction } from 'store';
import { fetching, FetchStatusAggregateTypes } from 'store/global/fetch';
import { fetched, fetchedFailed } from './../global/fetch/fetchActions';

export const getSitecoreAnlaeggningar = (
  foereningNamn: string,
  foereningId: number,
  countryId: number
): ThunkAction => async dispatch => {
  dispatch(fetching(foereningId, FetchStatusAggregateTypes.ANLAEGGNINGAR));
  try {
    const res = await api.get<Array<fos.api.SitecoreAnlaeggningar>>(
      `Foerening/GetAnlaeggningarFromSitecore?foerening=${constructSafeName(foereningNamn)}&countryId=${countryId}`
    );
    if (!res.error) {
      dispatch(addSitecoreAnlaeggningar(res.result));

      dispatch(fetched(foereningId, FetchStatusAggregateTypes.ANLAEGGNINGAR, res.result));
    } else {
      dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.ANLAEGGNINGAR));
    }
  } catch (error) {
    dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.ANLAEGGNINGAR));
  }
};
