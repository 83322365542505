import { Reducer } from 'redux';
import { CalendarActionTypes, CalendarState, CalendarActions } from '.';

const initialState: CalendarState = {
  syncIsActive: false,
  shouldShowCalendarSyncPopup: true,
};

export const calendarReducer: Reducer<CalendarState> = (state = initialState, action: CalendarActions) => {
  switch (action.type) {
    case CalendarActionTypes.SetCalendarSync:
      return {
        ...state,
        syncIsActive: action.syncCalendar,
      };
    case CalendarActionTypes.SetSelectedCalendar:
      return {
        ...state,
        selectedCalendar: action.id,
      };
    case CalendarActionTypes.SetShouldShowCalendarSyncPopup:
      return {
        ...state,
        shouldShowCalendarSyncPopup: action.show,
      };
    default:
      return state;
  }
};
