interface GetAuthAction {
  type: AuthReadActionType.GetAuth;
  token: string;
  giltighetstid: string;
}

interface ClearAuthAction {
  type: AuthReadActionType.ClearAuth;
}

export type AuthReadActions = GetAuthAction | ClearAuthAction;

export enum AuthReadActionType {
  GetAuth = 'GET_AUTH',
  UpdateAuth = 'UPDATE_AUTH',
  ClearAuth = 'CLEAR_AUTH',
}

export const setAuthToken = (token: string, giltighetstid: string): AuthReadActions => ({
  type: AuthReadActionType.GetAuth,
  token,
  giltighetstid,
});

export const clearAuthToken = (): AuthReadActions => ({
  type: AuthReadActionType.ClearAuth,
});
