import * as React from 'react';

export interface SmallHeaderProps {
  headerText: string;
}

export class SmallHeader extends React.Component<SmallHeaderProps> {
  render() {
    return <h4 className="SmallHeader">{this.props.headerText}</h4>;
  }
}
