import { Reducer } from 'redux';
import { Action } from 'store';
import { UIShopFilterActionType, SetFilterSelectedCategory, ToggleShowProducts } from './uiShopFilterActions';
import { ShopFilterCategoryName } from 'store/shop/shopModels';

export type FilterCategoryType = 'Träningskort' | 'Medlemskap';

export interface UIShopFilterState {
  readonly expanded: boolean;
  categoryState: ShopFilterCategoryState;
  selectedCategory: FilterCategoryType;
  ShouldShowProducts: boolean;
}

export interface FilterCategoryLocalState {
  clicked: boolean;
  topPosition: number;
  atTop: boolean;
}

export interface ShopFilterCategoryState {
  ProductWhat: FilterCategoryLocalState;
  ProductWho: FilterCategoryLocalState;
  ProductWhen: FilterCategoryLocalState;
  ProductHow: FilterCategoryLocalState;
}

const defaultFilterCategoryLocalState: FilterCategoryLocalState = {
  clicked: false,
  atTop: false,
  topPosition: 0,
};
export const initialShopFilterState: UIShopFilterState = {
  expanded: false,
  selectedCategory: 'Träningskort',
  ShouldShowProducts: false,
  categoryState: {
    ProductWhat: defaultFilterCategoryLocalState,
    ProductWho: defaultFilterCategoryLocalState,
    ProductWhen: defaultFilterCategoryLocalState,
    ProductHow: defaultFilterCategoryLocalState,
  },
};

export const setSelectedCategory = (selectedCategory: FilterCategoryType): SetFilterSelectedCategory => ({
  type: UIShopFilterActionType.SetCategory,
  selectedCategory: selectedCategory,
});

export const getShopFilterIsExpanded = (shopFilterUIState: UIShopFilterState): boolean => {
  return shopFilterUIState.expanded;
};

export const getCategoryLocalState = (
  shopFilterUIState: UIShopFilterState,
  internalCategoryName: ShopFilterCategoryName
): FilterCategoryLocalState => {
  return shopFilterUIState.categoryState[internalCategoryName];
};

export const toggleShowProducts = (showing: boolean): ToggleShowProducts => ({
  type: UIShopFilterActionType.ToggleShowProducts,
  showing: showing,
});

export const shopFilterUIReducer: Reducer<UIShopFilterState> = (state = initialShopFilterState, action: Action) => {
  switch (action.type) {
    case UIShopFilterActionType.SetCategory:
      return { ...state, selectedCategory: action.selectedCategory };

    case UIShopFilterActionType.SetFilterToExpanded:
      return { ...state, expanded: true };

    case UIShopFilterActionType.SetFilterToMinimised:
      return { ...state, expanded: false };

    case UIShopFilterActionType.SetCategoryClicked: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: true };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }

    case UIShopFilterActionType.SetCategoryNotClicked: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: false };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }

    case UIShopFilterActionType.ToggleCategoryClicked: {
      state.categoryState.ProductWho.clicked = false;
      state.categoryState.ProductWhat.clicked = false;
      state.categoryState.ProductWhen.clicked = false;
      state.categoryState.ProductHow.clicked = false;
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: !action.clicked };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIShopFilterActionType.SetCategoryAtTop: {
      state.categoryState.ProductWho.atTop = false;
      state.categoryState.ProductWhat.atTop = false;
      state.categoryState.ProductWhen.atTop = false;
      state.categoryState.ProductHow.atTop = false;
      let localState = state.categoryState[action.category];
      localState = { ...localState, atTop: true };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIShopFilterActionType.SetCategoryAtNotTop: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, atTop: false };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIShopFilterActionType.SetCategoryTopPosition: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, topPosition: action.topPosition };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIShopFilterActionType.SetCategoryLocalState: {
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: action.localState,
        },
      };
    }
    case UIShopFilterActionType.ToggleShowProducts: {
      return {
        ...state,
        ShouldShowProducts: action.showing,
      };
    }

    default:
      return state;
  }
};
