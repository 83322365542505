import * as React from 'react';
import moment from 'moment';

import { SitecoreDictionary } from 'models';
import { IconArrow } from 'components/icons';
import { FacilityExceptionalOpenTimesIndicator } from './FacilityExceptionalOpenTimesIndicator';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { TranslateFunction } from 'react-localize-redux';

export interface FacilitiesItemDataProps {
  name: string;
  street: string;
  disabled: boolean;
  open: string;
  additionalOpen: string;
  close: string;
  additionalClose: string;
  receptionOpen: string;
  additionalReceptionOpen: string;
  receptionClosing: string;
  additionalReceptionClosing: string;
  openTomorrow: string;
  closeTomorrow: string;
  receptionOpenTomorrow: string;
  url: string;
  translate: TranslateFunction;
  exceptionalHoursEnd?: string;
  exceptionalHoursStart?: string;
  exceptionalHoursText?: string;
  isTargetBlank?: string;
}

export default class FacilitiesItem extends React.Component<Readonly<FacilitiesItemDataProps>> {
  render() {
    return (
      <div className="Facilities__Item">
        <FancyNavLink to={this.props.url} isTargetBlank={true}>
          <div className="Item__Header">
            <h5 className="Item__Name">
              {!this.props.disabled && (
                <div
                  className={
                    this.isOpen(
                      this.props.open,
                      this.props.close,
                      this.props.additionalOpen,
                      this.props.additionalClose
                    )
                      ? 'Facilities__StatusIndicator Facilities__StatusIndicator--open'
                      : 'Facilities__StatusIndicator Facilities__StatusIndicator--closed'
                  }
                />
              )}
              {this.props.name}
            </h5>
            <p>{this.props.street}</p>
          </div>
          <div className="Item__Information">
            <div>
              {!this.props.disabled && (
                <p
                  className={
                    this.isOpen(
                      this.props.open,
                      this.props.close,
                      this.props.additionalOpen,
                      this.props.additionalClose
                    )
                      ? 'Information'
                      : 'Information--Red'
                  }
                >
                  {this.getPrimaryText()}
                  {
                    <div className="Item__openHours">
                      {!!this.props.open
                        ? this.props.open + ' - ' + this.props.close
                        : this.props.translate(SitecoreDictionary.Facilities.Closed)}
                    </div>
                  }
                  {!!this.props.additionalOpen && (
                    <div className="Item__additionalHours">
                      {this.props.additionalOpen} - {this.props.additionalClose}
                    </div>
                  )}
                </p>
              )}
              {((!!this.props.receptionOpen &&
                !!this.props.receptionClosing &&
                this.props.receptionOpen.trim() !== '' &&
                this.props.receptionClosing.trim() !== '') ||
                (!!this.props.receptionOpenTomorrow && this.props.receptionOpenTomorrow.trim() !== '')) && (
                <p
                  className={
                    this.isOpen(
                      this.props.receptionOpen,
                      this.props.receptionClosing,
                      this.props.additionalReceptionOpen,
                      this.props.additionalReceptionClosing
                    )
                      ? 'Information'
                      : 'Information--Red'
                  }
                >
                  {this.getSecondaryText()}
                  {!!this.props.receptionOpen && (
                    <div className="Item__receptionHours">
                      {this.props.receptionOpen} - {this.props.receptionClosing}
                    </div>
                  )}
                  {!!this.props.additionalReceptionOpen && (
                    <div className="Item__additionalReceptionHours">
                      {this.props.additionalReceptionOpen} - {this.props.additionalReceptionClosing}
                    </div>
                  )}
                </p>
              )}
            </div>
            {this.shouldShowExceptionalOpenTimes() && (
              <FacilityExceptionalOpenTimesIndicator
                message={this.props.exceptionalHoursText as string}
                translate={this.props.translate}
              />
            )}
          </div>

          <div className="Facilities__Item__Arrow">
            <IconArrow color="#e31836" direction="down" />
          </div>
        </FancyNavLink>
      </div>
    );
  }

  private shouldShowExceptionalOpenTimes() {
    const haveExceptionalHours =
      this.props.exceptionalHoursEnd &&
      this.props.exceptionalHoursStart &&
      this.props.exceptionalHoursEnd.trim() !== '' &&
      this.props.exceptionalHoursEnd.trim() !== '';

    if (haveExceptionalHours) {
      const mExHoursStart = moment(this.props.exceptionalHoursStart).startOf('day');
      const mExHoursEnd = moment(this.props.exceptionalHoursEnd).endOf('day');
      if (!mExHoursEnd.isValid() || !mExHoursStart.isValid()) {
        return false;
      } else {
        const dateNow = moment();
        return dateNow.isAfter(mExHoursStart) && dateNow.isBefore(mExHoursEnd);
      }
    }
    return false;
  }

  private getPrimaryText() {
    return `${this.props.translate(SitecoreDictionary.Facilities.Open)}`;

    // TODO: remove this if friskis doesnt want to have the old functionality
    // if (this.isOpenLater(this.props.open)) {
    //   return `${this.props.translate(SitecoreDictionary.Facilities.OpenLater)} ${this.props.open}-${this.props.close}`;
    // } else if (!this.isOpen(this.props.open, this.props.close)) {
    //   if (
    //     (
    //       !this.props.closeTomorrow &&
    //       !this.props.openTomorrow
    //     ) ||
    //     (
    //       this.props.closeTomorrow &&
    //       this.props.openTomorrow &&
    //       this.props.closeTomorrow.trim() === '' &&
    //       this.props.openTomorrow.trim() === ''
    //     )
    //   ) {
    //     return this.props.translate(SitecoreDictionary.Facilities.CloseTomorrow);
    //   }
    //   return `${
    //     this.props.translate(SitecoreDictionary.Facilities.OpenTomorrow)
    //   } ${
    //     this.props.openTomorrow
    //   }${
    //     this.props.openTomorrow &&
    //     this.props.closeTomorrow &&
    //     this.props.closeTomorrow.trim() !== '' &&
    //     this.props.openTomorrow.trim() !== '' ?
    //       '-' :
    //       ''
    //     }${
    //       this.props.closeTomorrow
    //     }`;
    // }
    // return `${this.props.translate(SitecoreDictionary.Facilities.Open)} ${this.props.close}`;
  }

  private getSecondaryText() {
    return this.props.translate(SitecoreDictionary.Facilities.OpenReception);
  }
  // TODO: remove this if friskis doesnt want to have the old functionality
  // if (this.isOpenLater(this.props.receptionOpen)) {
  //   return `${this.props.translate(SitecoreDictionary.Facilities.OpenReceptionLater)} ${this.props.receptionOpen}-${this.props.receptionClosing}`;
  // } else if (this.isOpen(this.props.receptionOpen, this.props.receptionClosing)) {
  //   return `${this.props.translate(SitecoreDictionary.Facilities.OpenReception)} ${this.props.receptionClosing}`;
  // }
  // if (!this.props.receptionOpenTomorrow || this.props.receptionOpenTomorrow.trim() === '') {
  //   return '';
  // }
  // return `${this.props.translate(SitecoreDictionary.Facilities.OpenReceptionTomorrow)} ${this.props.receptionOpenTomorrow}`;

  private isOpen(start: string, end: string, additionalStart: string, additionalClose: string) {
    const currentDate = new Date();
    let startDate = this.convertStringToDate(start);
    let endDate = this.convertStringToDate(end);
    let additionalStartDate;
    let additionalCloseDate;
    if (!!additionalStart && !!additionalClose) {
      additionalStartDate = this.convertStringToDate(additionalStart);
      additionalCloseDate = this.convertStringToDate(additionalClose);
    } else {
      additionalStartDate = '';
      additionalCloseDate = '';
    }

    return (
      (currentDate >= startDate && currentDate < endDate) ||
      (currentDate >= additionalStartDate && currentDate < additionalCloseDate)
    );
  }

  private isOpenLater(start: string) {
    const currentDate = new Date();
    let startDate = this.convertStringToDate(start);
    return currentDate < startDate;
  }

  private convertStringToDate(str: string) {
    const currentDate = new Date();
    let list = str.split(':');
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      parseInt(list[0], 10),
      parseInt(list[1], 10)
    );
    return date;
  }
}
