import * as React from 'react';
import { YouTubeVideo, HeroImage } from '../media';
import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

const fosURL = process.env.REACT_APP_FOS_URL as string;

interface PassDetailsHeroBannerProps {
  translate: TranslateFunction;
  isDesktopMode: boolean;
  pass: fos.api.PassDetails;
}

export const PassDetailsHeroBanner: React.FunctionComponent<PassDetailsHeroBannerProps> = ({
  translate,
  isDesktopMode,
  pass,
}) => {
  const shouldShowVideo = !!pass.YoutubeVideoId;

  const shouldShowHeroBild = !shouldShowVideo && ((pass.HeroBild && !isDesktopMode) || isDesktopMode);

  return (
    <div>
      {shouldShowVideo && pass.YoutubeVideoId && (
        <YouTubeVideo
          videoId={pass.YoutubeVideoId}
          placeholderUrl={
            pass.YoutubeVideoPlaceholderUrl
              ? pass.YoutubeVideoPlaceholderUrl
              : pass.HeroBild
              ? fosURL + pass.HeroBild
              : ''
          }
          imageUrl={pass.HeroBild ? fosURL + pass.HeroBild : undefined}
          header={pass.Rubrik}
          tickers={pass.Tickers}
          buttonText={
            pass.Rubrik
              ? (translate(SitecoreDictionary.PassDetails.PlayFilmAbout) as string) + ' ' + pass.Rubrik
              : undefined
          }
        />
      )}
      {shouldShowHeroBild && (
        <HeroImage
          header={pass.Rubrik ? pass.Rubrik : pass.PassNamn}
          tickers={pass.Tickers}
          imageUrl={pass.HeroBild ? fosURL + pass.HeroBild : undefined}
        />
      )}
    </div>
  );
};
