import * as React from 'react';

export interface IconMenuSwitchProps {
  title?: string;
}

export class IconMenuSwitch extends React.Component<IconMenuSwitchProps, {}> {
  fillColour = '#000';

  // TODO: Fix translation support for text
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g fill="none">
          {/* tslint:disable */}
          <path
            fill={this.fillColour}
            d="M14.7 0C14.4 0.1 14.1 0.3 14 0.5 13.9 0.8 14 1.2 14.2 1.4L16.7 3.8 1.2 3.8C1.1 3.8 1.1 3.8 1.1 3.8 0.7 3.9 0.3 4.2 0.3 4.7 0.4 5.1 0.7 5.4 1.2 5.4L16.7 5.4 14.2 7.9C14 8.1 13.9 8.4 14 8.6 14 8.9 14.3 9.1 14.5 9.2 14.8 9.2 15.1 9.2 15.3 9L19.6 4.6 15.3 0.3C15.1 0.1 14.9 0 14.7 0ZM5.2 10C5 10.1 4.9 10.1 4.7 10.3L0.4 14.6 4.7 19C4.9 19.2 5.2 19.2 5.5 19.2 5.8 19.1 6 18.9 6 18.6 6.1 18.4 6 18.1 5.8 17.9L3.3 15.4 18.8 15.4C19.1 15.4 19.4 15.2 19.5 15 19.7 14.8 19.7 14.5 19.5 14.2 19.4 14 19.1 13.8 18.8 13.8L3.3 13.8 5.8 11.4C6.1 11.1 6.1 10.8 6 10.5 5.8 10.2 5.5 10 5.2 10Z"
          />
        </g>
      </svg>
    );
  }
}
