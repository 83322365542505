import * as React from 'react';
import { HeaderIconEnum } from 'store/global/ui/header';

import { IconFilterCalendarView, IconFilterListView } from 'components/icons';
import { UIScheduleState, UIScheduleViewType } from '../../../store/global/ui/schedule/uiScheduleReducer';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface ScheduleModeSwitcherDataProps {
  icon: HeaderIconEnum;
  scheduleUIState: UIScheduleState;
  translate: TranslateFunction;
}

export interface ScheduleModeSwitcherActionProps {
  setScheduleToListView: () => void;
  setScheduleToWeekView: () => void;
}

export type ScheduleModeSwitcherProps = ScheduleModeSwitcherDataProps & ScheduleModeSwitcherActionProps;

export class ScheduleModeSwitcher extends React.Component<Readonly<ScheduleModeSwitcherProps>, {}> {
  onListViewClick() {
    this.props.setScheduleToListView();
  }

  onWeekViewClick() {
    this.props.setScheduleToWeekView();
    const ele = document.getElementById('passlist') as HTMLElement;
    if (ele !== null) {
      ele.scrollIntoView();
    }
  }

  scheduleStateIs(scheduleViewType: UIScheduleViewType) {
    return scheduleViewType === this.props.scheduleUIState.viewShowing;
  }

  render() {
    return (
      <div className="ScheduleModeSwitcher">
        <div className="ScheduleModeSwitcher_ListView" onClick={() => this.onListViewClick()}>
          <IconFilterListView
            active={this.scheduleStateIs(UIScheduleViewType.LISTVIEW)}
            title={this.props.translate(SitecoreDictionary.Schedule.ListView) as string}
          />
        </div>
        <div className="ScheduleModeSwitcher_WeekView" onClick={() => this.onWeekViewClick()}>
          <IconFilterCalendarView
            active={this.scheduleStateIs(UIScheduleViewType.WEEKVIEW)}
            title={this.props.translate(SitecoreDictionary.Schedule.WeekView) as string}
          />
        </div>
      </div>
    );
  }
}
