import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

interface MainInputDataProps {
  id?: string;
  align?: 'left' | 'center' | 'right';
  alignLabel?: 'left' | 'center' | 'right';
  alt?: string;
  autocomplete?: string;
  autoCorrect?: 'off' | 'on';
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  formId?: string;
  isValid?: boolean;
  label?: string;
  labelColor?: string;
  max?: number;
  maxlength?: number;
  min?: number;
  name?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  step?: string | number;
  shouldValidate?: boolean;
  type?: string;
  value?: string | number;
  smallRadius?: boolean;
  border?: 'grey';
  infoText?: string;
  infoType?: 'info' | 'warning' | 'error';
  tabindex?: number;
  hideSpinner?: boolean;
  autoSelect?: boolean;
}

interface MainInputActionProps {
  onValueChange?: (newValue: string | boolean) => void;
}

export type MainInputProps = MainInputDataProps & MainInputActionProps;

export const MainInput: React.FunctionComponent<MainInputProps> = ({
  id,
  align,
  alignLabel,
  alt,
  autocomplete,
  autoCorrect,
  className,
  checked,
  disabled,
  formId,
  isValid,
  label,
  labelColor,
  max,
  maxlength,
  min,
  name,
  placeholder,
  readonly,
  required,
  step,
  shouldValidate,
  type,
  value,
  smallRadius,
  border,
  infoText,
  infoType,
  tabindex,
  hideSpinner,
  autoSelect,

  onValueChange,
}) => {
  const [skaVisaInfoText, setSkaVisaInfoText] = React.useState(false);

  const hasInfoText = (): boolean => {
    return !!infoText && infoText !== '';
  };

  const getInfoTextClasses = () => {
    let classes = 'MainInput__Label ';
    switch (infoType) {
      case 'error':
        classes += ' MainInput__Label--error';
        break;
      case 'info':
        classes += ' MainInput__Label--info';
        break;
      case 'warning':
        classes += ' MainInput__Label--warning';
        break;
      default:
        classes += ' MainInput__Label--info';
    }
    return classes;
  };

  const getInputClasses = (): string => {
    let cName: string = 'Input';
    switch (align) {
      case 'center':
        cName += ' Input__Center';
        break;
      case 'left':
        cName += ' Input__Left';
        break;
      case 'right':
        cName += ' Input__Right';
        break;
      default:
        cName += ' Input__Center';
        break;
    }
    if (smallRadius) {
      cName = cName + ' Input__SmallRadius';
    }
    if (hideSpinner) {
      cName += ' Input__NoSpinner';
    }
    if (border) {
      switch (border) {
        case 'grey':
          return (cName = cName + ' Input__Border--Grey');
        default:
          return (cName = cName + ' Input__Border--Grey');
      }
    }
    return cName;
  };

  const getContainerClasses = (): string => {
    let cName: string = 'MainInput ';
    cName += className ? className : '';
    if (shouldValidate) {
      cName += ' ' + (isValid ? 'MainInput__Valid' : 'MainInput__InValid');
    }

    return cName;
  };

  const getLabel = (): React.ReactNode => {
    if (label) {
      return (
        <label htmlFor={name} className={getLabelClasses()}>
          {label}
        </label>
      );
    } else {
      return null;
    }
  };

  const getLabelClasses = (): string => {
    let cName: string = 'InputLabel';
    switch (alignLabel) {
      case 'center':
        cName += ' InputLabel__Center';
        break;
      case 'left':
        cName += ' InputLabel__Left';
        break;
      case 'right':
        cName += ' InputLabel__Right';
        break;
      default:
        cName += ' InputLabel__Center';
        break;
    }

    switch (labelColor) {
      case 'black':
        cName += ' InputLabel--black';
        break;
      default:
        break;
    }
    return cName;
  };

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (onValueChange) {
      switch (target.type) {
        case 'checkbox':
          onValueChange(target.checked);
          break;
        default:
          onValueChange(target.value);
          break;
      }
    }
  };
  return (
    <div className={getContainerClasses()}>
      {getLabel()}
      <div className="InputContainer">
        <input
          id={id}
          ref={ref => {
            if (ref && autoSelect) {
              ref.focus();
            }
          }}
          className={getInputClasses()}
          alt={alt}
          autoComplete={autocomplete}
          autoCorrect={autoCorrect}
          disabled={disabled}
          form={formId}
          max={max}
          maxLength={maxlength}
          min={min}
          name={name}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          step={step}
          type={type}
          value={type !== 'checkbox' ? value : checked ? 'on' : 'off'}
          onChange={event => {
            updateValue(event);
            setSkaVisaInfoText(false);
          }}
          checked={checked === true}
          tabIndex={tabindex}
          onFocus={e => {
            if (autoSelect) {
              e.currentTarget.select();
            }
          }}
          onBlur={e => {
            setSkaVisaInfoText(true);
          }}
        />
      </div>
      <CSSTransition
        in={hasInfoText() && skaVisaInfoText}
        timeout={300}
        classNames={'MainInput__Label-animation'}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <div className={getInfoTextClasses()}>{infoText}</div>
      </CSSTransition>
    </div>
  );
};
