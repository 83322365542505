import * as React from 'react';
import CalendarSyncContainer from 'components/settings/calendarSync';
import { Overlay } from 'components/common';

import { Mobile } from 'models/app/MobileOSType';
import { TranslateFunction } from 'react-localize-redux';

export interface CalendarSyncPopupDataProps {
  syncCalendar: boolean;
  passToBook?: fos.api.Pass;
  translate: TranslateFunction;
}

export interface CalendarSyncPopupActionProps {
  popupShown: () => void;
}

export interface CalendarSyncState {
  checked: boolean;
  selectedCalendar?: string;
}

export type CalendarSyncProps = CalendarSyncPopupDataProps & CalendarSyncPopupActionProps;

const Container = (props: any) => (
  <div className="CalendarSyncPopup">
    <div className="Wrapper">{props.children}</div>
  </div>
);

export class CalendarSyncPopup extends React.Component<CalendarSyncProps, CalendarSyncState> {
  constructor(props: CalendarSyncProps) {
    super(props);
    this.state = {
      checked: this.props.syncCalendar,
    };
  }

  render() {
    return (
      <Overlay
        close={() => {
          this.close();
        }}
        title="Kalendersynk"
      >
        <Container>
          <h4 className="CalendarSync__Title">Vill du synka dina passbokningar med din kalender?</h4>
          <CalendarSyncContainer />
        </Container>
      </Overlay>
    );
  }

  close() {
    const pass = this.props.passToBook;
    if (this.props.syncCalendar && !!pass) {
      var data = JSON.stringify(pass);
      Mobile.addToCalendar(data);
    }
    this.props.popupShown();
  }
}
