import { connect } from 'react-redux';
import { RootState } from '../../store';
import {
  RequestPasswordReset,
  RequestPasswordResetDataProps,
  RequestPasswordResetActionProps,
} from './RequestPasswordReset';
import { requestResetPasswordLink, clearPasswordResetState } from 'store/global/passwordReset';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state: RootState, props: any): RequestPasswordResetDataProps => ({
  passwordReseted: state.global.passowrdReset.passwordResetRequestCompleted,
  passwordResetResult: state.global.passowrdReset.requestPasswordResetLinkResult,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps: RequestPasswordResetActionProps = {
  requestResetPasswordLink,
  clearPasswordResetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestPasswordReset);
