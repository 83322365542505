import * as React from 'react';

import brand from 'static/assets/friskis-logo--red.svg';
import calendar from 'static/assets/converted-to-components/icon-calender.svg';
import close from 'static/assets/converted-to-components/icon-big-close.svg';

import { HeaderIconEnum } from 'store/global/ui/header';
import { SideBarState } from 'store/global/ui/side-bar/sideBarReducer';
import ScheduleLauncher from 'components/common/ScheduleLauncher';
import { BackButton } from './BackButton';
import { FancyNavLink } from 'components/app';
import { routesMap, LocationActionTypes as RouteActionType, LocationState, siteHasHistory } from 'store/location';
import { MobileHeaderContent } from './MobileHeaderContent';
import { DesktopHeaderContent } from './DesktopHeaderContent';
import { ShoppingBasketButton } from 'components/common';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { Overlay } from 'components/common';
import { LoginPrompt } from '../../login/LoginPrompt';

import { FilterCategoryType } from 'store/global/ui/shop-filter';
import { SitecoreDictionary } from 'models';
import filtericon from '../../../static/assets/Filter.svg';
import { TranslateFunction } from 'react-localize-redux';

export interface HeaderContentExternalProps {
  isDesktopMode: boolean;
  isStartPage: boolean;
  isFirstVisit: boolean;
}

export interface HeaderContentInternalProps {
  title: string | null;
  rightIcon: HeaderIconEnum;
  leftIcon: HeaderIconEnum;
  visible: boolean;
  sideBarState: SideBarState;
  isSiteSwitcherShowingFullscreen: boolean;
  showBackButton: boolean;
  backButtonPosition?: 'right' | 'left';
  isAuthenticated: boolean;
  isAuthenticatedInShopFoerening: boolean;
  showHeaderTitle: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  numberOfProductsInBasket: number;
  productsInBasket?: Array<fos.api.Product>;
  isIframe: boolean;
  location: LocationState;
  isPublicWebMode: boolean;
  activeFoerening: number;
  shopFoerening?: number;
  products: Array<fos.api.Product>;
  readonly expanded: boolean;
  translate: TranslateFunction;
  numbersOfFilters: number;
}

export interface HeaderContentActionProps {
  showStatusMessage: (showMessage: boolean) => void;
  setSelectedCategory: (categoryName: FilterCategoryType) => void;
  goBack: () => void;
  goToLogin: () => void;
  setSiteSwitcherToVisible: () => void;
  setSiteSwitcherToHidden: () => void;
  setSiteSwitcherToVisibleFullscreen: () => void;
  clickHandler?: () => void;
  setSidebarToContainSchedule: () => void;
  setSidebarToVisible: () => void;
  goToSchedule: () => void;
  goToHome: () => void;
  setFilterVisible: (visible: boolean) => void;
}

export type HeaderContentDataProps = HeaderContentInternalProps & HeaderContentExternalProps;
export type HeaderContentProps = HeaderContentActionProps & HeaderContentDataProps;

export class HeaderContent extends React.Component<HeaderContentProps> {
  componentDidUpdate(prevProps: HeaderContentProps) {
    this.checkIdInBasket();
  }

  getIcon(icon: HeaderIconEnum): string {
    if (icon === HeaderIconEnum.CALENDAR) {
      return calendar;
    } else {
      return close;
    }
  }

  onScheduleIconClick() {
    if (this.props.isDesktopMode) {
      this.props.setSidebarToContainSchedule();
      this.props.setSidebarToVisible();
    } else {
      this.props.goToSchedule();
    }
  }

  setIconClass(icon: HeaderIconEnum): string {
    if (icon === HeaderIconEnum.CALENDAR) {
      return 'scheduleLauncher__logoImage--calendar';
    } else {
      return 'scheduleLauncher__logoImage--close';
    }
  }

  getContentForHeaderMiddle() {
    return this.props.isDesktopMode ? (
      <DesktopHeaderContent
        isAuthenticated={this.props.isAuthenticated}
        showHeaderTitle={this.props.showHeaderTitle}
        headerTitle={this.props.headerTitle}
        headerSubtitle={this.props.headerSubtitle}
        activeFoerening={this.props.activeFoerening}
        location={this.props.location.type}
        isFirstVisit={this.props.isFirstVisit}
      />
    ) : (
      <MobileHeaderContent title={this.props.title} />
    );
  }

  openFilterIcon = () => {
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var iOS2 = !!navigator.platform.match(/iPhone|iPod|iPad/);
    const filterIco = document.getElementById('FilterIconHeader');
    if (iOS || iOS2 || isSafari) {
      if (filterIco !== null) {
        filterIco.setAttribute('style', 'display: block');
      }
    }
  };
  closeFilterIcon = () => {
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var iOS2 = !!navigator.platform.match(/iPhone|iPod|iPad/);
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    const filterIco = document.getElementById('FilterIconHeader');
    if (iOS || iOS2 || isSafari) {
      if (filterIco !== null) {
        filterIco.setAttribute('style', 'display: none');
      }
    }
  };
  // check product type in basket. if type 0 ('Medlemskap') exists, return true
  checkIdInBasket = () => {
    if (this.props.productsInBasket) {
      for (let i = 0; i < this.props.productsInBasket.length; i++) {
        if (this.props.productsInBasket[i].Types[0] === 0) {
          return true;
        }
      }
    }
    return false;
  };

  getHeaderTitleContent = (
    locationIsProductPage: boolean,
    locationIsShoppingBasket: boolean,
    isAdmin: boolean,
    locationIsRegister: boolean
  ) => {
    const headerTitleCond: boolean =
      this.props.isDesktopMode &&
      !this.props.isStartPage &&
      !locationIsProductPage &&
      !!this.props.title &&
      this.props.title.trim() !== '';
    if (headerTitleCond) {
      return (
        <div className="HeaderContent__Secondary">
          <h1 className="Secondary__TitleText">{this.props.title}</h1>
        </div>
      );
    } else if (locationIsProductPage) {
      const product = this.props.products.find(
        p =>
          '' + p.Id === '' + this.props.location.payload.productId &&
          '' + p.CustomerType.Id === '' + this.props.location.query.customerType
      );
      return (
        <div className="HeaderContent__Secondary">
          <h1 className="Secondary__TitleText">
            <FancyNavLink
              to={
                this.props.isPublicWebMode
                  ? routesMap[RouteActionType.PublicShop]
                  : routesMap[isAdmin ? RouteActionType.AdminShop : RouteActionType.Shop]
              }
            >
              <React.Fragment>
                {this.props.translate(SitecoreDictionary.Shop.ShopName)}
                {' >'}
              </React.Fragment>
            </FancyNavLink>
            {!!product ? product.NamnSitecore : ''}
          </h1>
        </div>
      );
    } else if (locationIsShoppingBasket && this.props.isDesktopMode) {
      return (
        <div className="HeaderContent__Secondary">
          <h1 className="Secondary__TitleText">
            <FancyNavLink
              to={
                this.props.isPublicWebMode
                  ? routesMap[RouteActionType.PublicShop]
                  : routesMap[isAdmin ? RouteActionType.AdminShop : RouteActionType.Shop]
              }
            >
              <React.Fragment>
                {this.props.translate(SitecoreDictionary.Cart.CartName)}
                {' >'}
              </React.Fragment>
            </FancyNavLink>
          </h1>
        </div>
      );
    } else if (locationIsRegister && this.props.isDesktopMode) {
      return (
        <div className="HeaderContent__Secondary">
          <h1 className="Secondary__TitleText">
            <FancyNavLink
              to={
                this.props.isPublicWebMode
                  ? routesMap[RouteActionType.PublicShop]
                  : routesMap[isAdmin ? RouteActionType.AdminShop : RouteActionType.Shop]
              }
            >
              <React.Fragment>{this.props.translate(SitecoreDictionary.Shop.RegisterName)}</React.Fragment>
            </FancyNavLink>
          </h1>
        </div>
      );
    }
    return <React.Fragment />;
  };

  getShoppingBasketLinkUrl = (isAdmin: boolean) => {
    if (this.props.location.type === RouteActionType.PublicShoppingBasket) {
      return RouteMap[RouteActionType.PublicShoppingBasket];
    } else if (this.props.numberOfProductsInBasket !== 0 && !this.props.isPublicWebMode) {
      return RouteMap[isAdmin ? RouteActionType.AdminShoppingBasket : RouteActionType.ShoppingBasket];
    } else if (this.props.numberOfProductsInBasket === 0 && this.props.isPublicWebMode) {
      // eslint-disable-next-line no-script-url
      return 'javascript:void(0)';
    } else if (this.props.numberOfProductsInBasket === 0 && !this.props.isPublicWebMode) {
      return RouteMap[isAdmin ? RouteActionType.AdminShop : RouteActionType.Shop];
    } else if (this.props.numberOfProductsInBasket !== 0 && this.props.isPublicWebMode) {
      return RouteMap[RouteActionType.PublicShoppingBasket];
    }
    // eslint-disable-next-line no-script-url
    return 'javascript:void(0)';
  };

  render() {
    const isAdmin =
      this.props.location.type === RouteActionType.AdminShop ||
      this.props.location.type === RouteActionType.AdminProductDetails ||
      this.props.location.type === RouteActionType.AdminShoppingBasket ||
      this.props.location.type === RouteActionType.AdminRegister;
    const locationIsProductPage =
      this.props.location.type === RouteActionType.ProductDetails ||
      this.props.location.type === RouteActionType.PublicProductDetails ||
      this.props.location.type === RouteActionType.AdminProductDetails;
    const locationIsShoppingBasket =
      this.props.location.type === RouteActionType.ShoppingBasket ||
      this.props.location.type === RouteActionType.PublicShoppingBasket ||
      this.props.location.type === RouteActionType.AdminShoppingBasket;
    const locationIsRegister =
      this.props.location.type === RouteActionType.PublicRegister ||
      this.props.location.type === RouteActionType.AdminRegister;
    if (this.props.visible) {
      const logoUrl = this.props.isPublicWebMode
        ? routesMap[RouteActionType.PublicShop]
        : locationIsShoppingBasket || locationIsProductPage || locationIsRegister
        ? isAdmin
          ? routesMap[RouteActionType.AdminShop]
          : routesMap[RouteActionType.Shop]
        : routesMap[RouteActionType.Home];
      return (
        <div className="HeaderContent">
          <div className="HeaderContent__Primary">
            <div className="brandArea">
              {this.props.leftIcon === HeaderIconEnum.FRISKIS_LOGO && (
                <div className="brandArea__logoImage">
                  <FancyNavLink to={logoUrl}>
                    <img src={brand} alt="Friskis" />
                  </FancyNavLink>
                </div>
              )}
              {this.props.leftIcon === HeaderIconEnum.FILTER && (
                <div className="brandArea__logoImage">
                  {/* <FancyNavLink to={logoUrl}> */}
                  <img
                    onClick={() => this.props.setFilterVisible(true)}
                    src={filtericon}
                    id="FilterIconHeader"
                    alt="filtericon"
                  />
                  {this.props.numbersOfFilters > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '16px',
                        height: '16px',
                        backgroundColor: '#e31836',
                        lineHeight: '16px',
                        color: '#ffffff',
                        fontSize: '12px',
                        textAlign: 'center',
                        top: '15px',
                        left: '40px',
                        borderRadius: '50px',
                      }}
                    >
                      {this.props.numbersOfFilters}
                    </div>
                  )}
                  {/* </FancyNavLink> */}
                </div>
              )}
              {this.props.leftIcon === HeaderIconEnum.BACK && (
                <BackButton
                  className="brandArea__BackIconLeft"
                  goBack={() => {
                    if (
                      this.props.location.type === RouteActionType.PassDetails &&
                      !siteHasHistory(this.props.location)
                    ) {
                      this.props.goToSchedule();
                    } else {
                      this.props.goBack();
                    }
                  }}
                  icon="chevron"
                />
              )}
            </div>
            {this.getContentForHeaderMiddle()}
            {/* Vi använder för tillfället bara rightIcon BACK på passlistan i mobil, där ska krysset alltid gå tillbaka till hem. Se över vid omskrivning */}
            {this.props.rightIcon === HeaderIconEnum.BACK && <BackButton goBack={this.props.goToHome} icon="close" />}
            {(this.props.rightIcon === HeaderIconEnum.CALENDAR || this.props.rightIcon === HeaderIconEnum.CLOSE) && (
              <div onClick={() => this.openFilterIcon()} className="ScheduleLauncherContainer">
                <ScheduleLauncher isDesktopMode={this.props.isDesktopMode} />
              </div>
            )}
            {this.props.rightIcon === HeaderIconEnum.SHOPPINGBASKET && (
              <FancyNavLink
                className={' ShoppingBasketButton' + (this.props.numberOfProductsInBasket > 0 ? '' : ' noprod')}
                to={this.getShoppingBasketLinkUrl(isAdmin)}
              >
                <ShoppingBasketButton
                  className={this.props.isPublicWebMode ? ' ejinlogg' : ''}
                  amount={this.props.numberOfProductsInBasket}
                />
              </FancyNavLink>
            )}
          </div>
          {this.getHeaderTitleContent(locationIsProductPage, locationIsShoppingBasket, isAdmin, locationIsRegister)}
          {this.props.isSiteSwitcherShowingFullscreen && (
            <Overlay className="Overlay__LoginPrompt">
              <LoginPrompt
                setSiteSwitcherToVisible={this.props.setSiteSwitcherToVisible}
                toggleLoginPromptOpen={this.props.setSiteSwitcherToHidden}
                translate={this.props.translate}
              />
            </Overlay>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
