import * as React from 'react';

interface IconKvittoProps {
  className?: string;
  title?: string;
}

export const IconKvitto: React.SFC<IconKvittoProps> = (props: IconKvittoProps) => (
  <svg
    width="335px"
    height="260px"
    viewBox="0 0 335 260"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.className ? props.className : ''}
  >
    <title>{props.title}</title>
    <defs>
      <filter x="-12.9%" y="-16.4%" width="125.8%" height="134.7%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <polygon id="path-2" points="0 0 287 0 287 213 0 213" />
      <linearGradient x1="50%" y1="9.4506764%" x2="50%" y2="0%" id="linearGradient-4">
        <stop stopColor="#FEFEFE" offset="0%" />
        <stop stopColor="#EFEFEF" offset="58.8329061%" />
        <stop stopColor="#9F9F9F" offset="100%" />
      </linearGradient>
    </defs>
    <g id="1.-Köp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-11" transform="translate(13.000000, 15.000000)">
        <rect id="Rectangle-6" fill="#262626" x="0" y="0" width="309" height="13.875" rx="6.9375" />
        <g id="Page-1" filter="url(#filter-1)" transform="translate(11.000000, 7.000000)">
          <mask id="mask-3" fill="white">
            <use xlinkHref="#path-2" />
          </mask>
          <g id="Clip-2" />
          <path
            d={
              'M5.28077764,0 C3.70331063,0 0,6.35444979 0,43.3700537 L0,213 L3.99240137,213 C4.4315163,210.924832 5.9585729,' +
              '209.384724 7.79182847,209.384724 C10.1173654,209.384724 11.644422,210.924832 12.0835369,213 L16.3073105' +
              ',213 C16.7474099,210.924832 18.2744666,209.384724 20.1067376,209.384724 C22.4322744,209.384724 23.9583465,210.924832 24.398446,213 L28.6222196' +
              ',213 C29.062319,210.924832 30.5883911,209.384724 32.4216467,209.384724 C34.7471835,209.384724 36.2732556,210.924832 36.7133551,213 L40.9371286,213 C41.3762436' +
              ',210.924832 42.9033002,209.384724 44.7365557,209.384724 C47.0611081,209.384724 48.5881647,210.924832 49.0272796,213 L53.2520377,213 C53.6911527,210.924832 55.2182093' +
              ',209.384724 57.0504803,209.384724 C59.3760172,209.384724 60.9030738,210.924832 61.3421887,213 L65.5669468,213 C66.0060617,210.924832 67.5331184,209.384724 69.3653894' +
              ',209.384724 C71.6909262,209.384724 73.2179828,210.924832 73.6570978,213 L77.8818559,213 C78.3209708,210.924832 79.8480274,209.384724 81.6802985,209.384724 C84.0058353' +
              ',209.384724 85.5328919,210.924832 85.9720069,213 L90.1957804,213 C90.6358799,210.924832 92.161952,209.384724 93.9952075,209.384724 C96.3207444,' +
              '209.384724 97.847801,210.924832 98.286916,213 L102.51069,213 C102.949804,210.924832 104.476861,209.384724 106.310117,209.384724 C108.635654' +
              ',209.384724 110.16271,210.924832 110.60084,213 L114.825599,213 C115.264714,210.924832 116.79177,209.384724 118.624041,209.384724 C120.950563,209.384724 122.476635,' +
              '210.924832 122.91575,213 L127.140508,213 C127.579623,210.924832 129.106679,209.384724 130.93895,209.384724 C133.264487,209.384724 134.791544,210.924832 135.230659,' +
              '213 L139.455417,213 C139.894532,210.924832 141.421588,209.384724 143.253859,209.384724 C145.579396,209.384724 147.106453,210.924832 147.545568,213 L151.769341,' +
              '213 C152.209441,210.924832 153.735513,209.384724 155.568768,209.384724 C157.894305,209.384724 159.421362,210.924832 159.860477,213 L164.08425,213 C164.52435,' +
              '210.924832 166.050422,209.384724 167.883678,209.384724 C170.209214,209.384724 171.736271,210.924832 172.175386,213 L176.400144,213 C176.839259,210.924832 178.366316' +
              ',209.384724 180.198587,209.384724 C182.524123,209.384724 184.05118,210.924832 184.48931,213 L188.714069,213 C189.154168' +
              ',210.924832 190.68024,209.384724 192.513496,209.384724 C194.839033' +
              ',209.384724 196.365105,210.924832 196.80422,213 L201.028978,213 C201.468093,210.924832 202.995149,209.384724 204.828405,' +
              '209.384724 C207.152957,209.384724 208.680014,210.924832 209.119129,213 L213.343887,213 C213.783002,210.924832 215.310058,' +
              '209.384724 217.142329,209.384724 C219.467866,209.384724 220.994923,210.924832 221.434038,213 L225.658796,213 C226.097911,' +
              '210.924832 227.624967,209.384724 229.457238,209.384724 C231.782775,209.384724 233.309832,210.924832 233.749931,213 L237.973705,' +
              '213 C238.41282,210.924832 239.939877,209.384724 241.772148,209.384724 C244.097684,209.384724 245.624741,210.924832 246.063856,' +
              '213 L250.28763,213 C250.727729,210.924832 252.253801,209.384724 254.087057,209.384724 C256.412593,209.384724 257.93965,' +
              '210.924832 258.378765,213 L262.602539,213 C263.042638,210.924832 264.56871,209.384724 266.401966,209.384724 C268.727503,' +
              '209.384724 270.254559,210.924832 270.693674,213 L274.917448,213 C275.356563,210.924832 276.883619,209.384724 278.71589,' +
              '209.384724 C281.042412,209.384724 282.569468,210.924832 283.008583,213 L287,213 L287,43.3700537 C287,7.32816407 282.226853,0 282.226853,0 L5.28077764,0 Z'
            }
            id="Fill-1"
            fill="url(#linearGradient-4)"
            mask="url(#mask-3)"
          />
        </g>
      </g>
    </g>
  </svg>
);
