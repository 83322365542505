import { connect } from 'react-redux';
import { RootState } from 'store';
import {
  MedlemsVillkor,
  MedlemsVillkorActionProps,
  MedlemsVillkorDataProps,
  MedlemsVillkorPassedProps,
} from './MedlemsVillkor';
import { getMedlemsVillkor } from 'store/foereningar';
import { getTranslate } from 'store/global/dictionary';
import { FoereningInternal } from 'store/foereningar/read';
import { getHasLoadFailed, FetchStatusAggregateTypes, getIsFetching, getIsFetched } from 'store/global/fetch';

const mapStateToProps = (state: RootState, props: MedlemsVillkorPassedProps): MedlemsVillkorDataProps => {
  const foereningId = state.global.session.shopFoerening
    ? state.global.session.shopFoerening
    : state.global.session.activeFoerening;
  const activeFoerening = getActiveFoerening(state.foereningar.read.Foereningar, foereningId);
  const medlemsVillkor = getVillkor(state.foereningar.read.Foereningar, foereningId);
  return {
    activeFoerening,
    medlemsVillkor,
    translate: getTranslate(state.locale),
    medlemsVillkorNotFound:
      !!activeFoerening &&
      getHasLoadFailed(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.MEDLEM_VILLKOR) &&
      !medlemsVillkor,
    isFetching:
      !!activeFoerening &&
      getIsFetching(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.MEDLEM_VILLKOR),
    isFetched:
      !!activeFoerening &&
      (getIsFetched(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.MEDLEM_VILLKOR) ||
        getHasLoadFailed(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.MEDLEM_VILLKOR)),
  };
};

const getActiveFoerening = (foereningar: FoereningInternal[], foereningId?: number): FoereningInternal | undefined => {
  if (!foereningId) {
    return;
  }
  return foereningar.find(f => f.Id.toString() === foereningId.toString());
};

const getVillkor = (foereningar: FoereningInternal[], foereningId?: number): string | undefined => {
  const foerening = getActiveFoerening(foereningar, foereningId);

  return foerening ? foerening.MedlemsVillkor : undefined;
};

const mapDispatchToProps: MedlemsVillkorActionProps = {
  getMedlemsVillkor,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedlemsVillkor);
