import * as React from 'react';

interface IconChrevonProps {
  direction?: 'top' | 'right' | 'bottom' | 'left';
  title?: string;
  color?: 'red';
  onClick?: () => void;
}

export class IconChevron extends React.Component<IconChrevonProps, {}> {

  getClassName() {
    let value = 'IconChevron';
    if (this.props.direction) {
      if (this.props.direction === 'top') {
        value += ' IconChevron--top';
      } else if (this.props.direction === 'right') {
        value += ' IconChevron--right';
      } else if (this.props.direction === 'bottom') {
        value += ' IconChevron--bottom';
      } else if (this.props.direction === 'left') {
        value += ' IconChevron--left';
      }
    }
    if (this.props.color) {
      if (this.props.color === 'red') {
        value += ' IconChevron--red';
      }
    }
    return value;
  }

  render() {
    return (
      /* tslint:disable */
      <svg className={this.getClassName()} id="Layer_1" viewBox="0 0 42 42" onClick={this.props.onClick}>
        <title>{this.props.title}</title>
        <path
          d={'M31,27c-0.3,0-0.5-0.1-0.7-0.3L21,17.5l-9.2,'
            .concat('9.2c-0.4,0.4-1.1,0.4-1.5,0c0,0,0,0,0,0c-0.4-0.4-0.4-1.1,')
            .concat('0-1.5l10-10c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0l10,10c0.4,')
            .concat('0.4,0.4,1.1,0,1.5C31.5,26.9,31.2,27,31,27z')}
        />
      </svg>
    );
  }
}
