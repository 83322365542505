import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { Bookings, BookingsDataProps, BookingsActionProps } from './Bookings';
import { getTranslate } from 'store/global/dictionary';
import { updateBookingDayExpanded, getBookingsInBackground } from 'store/bokningar';
import { FetchStatusAggregateTypes, getIsFetching } from 'store/global/fetch';

const mapStateToProps = (state: RootState, props: any): BookingsDataProps => {
  return {
    days: state.bokningar.days,
    translate: getTranslate(state.locale),
    isFullDesktopView: props.isFullDesktopView,
    isDesktopMode: props.isDesktopMode,
    isFetching: getIsFetching(
      state.global.fetch,
      state.global.session.userId as number,
      FetchStatusAggregateTypes.BOKNING
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): BookingsActionProps => ({
  onGetBookings: () => dispatch(getBookingsInBackground()),
  updateBookingDayExpanded: (day, expanded) => dispatch(updateBookingDayExpanded(day, expanded)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
