import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';

// import { ActionType as RouteActionType } from 'store/location/actions';
// import { getLocation } from 'store/location';
// import { SideBarAction,  SidebarSize, SideBarState } from 'store/global/ui/side-bar';
import { UIScheduleAction, UIScheduleActionType } from 'store/global/ui/schedule';
import {
  ScheduleModeSwitcherDataProps,
  ScheduleModeSwitcherActionProps,
  ScheduleModeSwitcher,
} from './ScheduleModeSwitcher';
import { UIScheduleViewType } from 'store/global/ui/schedule/uiScheduleReducer';
import { SideBarAction, SidebarSize } from 'store/global/ui/side-bar';
import { getTranslate } from 'store/global/dictionary';
import { goBack, goToSchedule } from 'store/location';
import { UIScheduleFilterActionType } from 'store/global/ui/schedule-filter';

const setScheduleToListView = () => (dispatch: Dispatch<UIScheduleAction | SideBarAction>) => {
  // Vi vill att detta endast ska ske för desktop, men denna komponent finns endast för desktop, så....
  dispatch(goBack());

  dispatch({
    type: 'UI_SIDEBAR_SET_SIZE_MINIMISED',
    size: SidebarSize.MINIMISED,
  });
  dispatch({
    type: UIScheduleActionType.SetViewTypeToList,
    viewShowing: UIScheduleViewType.LISTVIEW,
  });
};

const setScheduleToWeekView = () => (dispatch: Dispatch<UIScheduleAction | SideBarAction>) => {
  // Vi vill att detta endast ska ske för desktop, men denna komponent finns endast för desktop, så....
  dispatch(goToSchedule());

  dispatch({
    type: UIScheduleFilterActionType.SetFilterToMinimised,
    expanded: false,
  });
  dispatch({
    type: UIScheduleActionType.SetViewTypeToWeek,
    viewShowing: UIScheduleViewType.WEEKVIEW,
  });
  dispatch({
    type: 'UI_SIDEBAR_SET_SIZE_MAXIMISED',
    size: SidebarSize.MAXIMISED,
  });
};

const mapStateToProps = (state: RootState): ScheduleModeSwitcherDataProps => ({
  icon: state.global.ui.header.rightDesktopIcon,
  scheduleUIState: state.global.ui.schedule,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps: ScheduleModeSwitcherActionProps = {
  setScheduleToListView: setScheduleToListView,
  setScheduleToWeekView: setScheduleToWeekView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleModeSwitcher);
