import { RootState, Dispatch } from 'store';
import { connect } from 'react-redux';
import { getTranslate } from 'store/global/dictionary';
import {
  fetchPushNotificationSettings,
  updatePushNotificationSettings,
} from 'store/pushNotificationSettings/pushNotificationSettingsThunks';
import {
  NotificationSettingsDataProps,
  NotificationSettingsActionProps,
  NotificationSettings,
} from './NotificationSettings';

const mapState = (state: RootState): NotificationSettingsDataProps => ({
  PassCancelled: state.pushNotificationSettings.PassCancelled,
  Vaentelista: state.pushNotificationSettings.Vaentelista,
  News: state.pushNotificationSettings.News,
  TraeningskortAboutToExpire: state.pushNotificationSettings.TraeningskortAboutToExpire,
  translate: getTranslate(state.locale),
});

const mapDispatch = (dispatch: Dispatch): NotificationSettingsActionProps => ({
  fetchPushNotificationSettings: () => dispatch(fetchPushNotificationSettings()),
  updatePushNotificationSettings: (setting: string) => dispatch(updatePushNotificationSettings(setting)),
});

export default connect(mapState, mapDispatch)(NotificationSettings);
