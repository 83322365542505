import { combineReducers } from 'redux';
import { errorsReducer, ErrorsState, ErrorsAction } from './errors';
import { debugReducer, DebugState, DebugAction } from './debug';
import { fetchReducer, FetchState, FetchAction } from './fetch';
import { uiReducer, UIState, UIAction } from './ui';
import { SessionState, sessionReducer, SessionActions } from './session';
import { PasswordResetState, passwordResetReducer, PasswordResetActions } from './passwordReset';
import { DictionaryRootState, DictionaryActions, DictionaryReducer } from './dictionary';
import { PushNotificationsState, pushNotificationsReducer, PushNotificationsActions } from './pushNotifications';
import { CalendarState, CalendarActions, calendarReducer } from './calendar';

export type GlobalAction =
  | ErrorsAction
  | DebugAction
  | FetchAction
  | UIAction
  | SessionActions
  | PasswordResetActions
  | DictionaryActions
  | PushNotificationsActions
  | CalendarActions;

export interface GlobalState {
  readonly errors: ErrorsState;
  readonly debug: DebugState;
  readonly fetch: FetchState;
  readonly ui: UIState;
  readonly session: SessionState;
  readonly passowrdReset: PasswordResetState;
  readonly dictionary: DictionaryRootState;
  readonly pushNotifications: PushNotificationsState;
  readonly calendar: CalendarState;
}

export const globalReducer = combineReducers<GlobalState>({
  errors: errorsReducer,
  debug: debugReducer,
  fetch: fetchReducer,
  ui: uiReducer,
  session: sessionReducer,
  passowrdReset: passwordResetReducer,
  dictionary: DictionaryReducer,
  pushNotifications: pushNotificationsReducer,
  calendar: calendarReducer,
});
