import { setLanguage } from './languageActions';
import { ThunkAction } from 'store';
import { api } from 'api';
import { getLanguageCode } from 'store/user/read';
import moment from 'moment';

export const setupTranslation = (): ThunkAction => (dispatch, getState) => {
  const promise = api.get<fos.api.Language>(`Settings/LanguageSettings`, {
    anonymousCall: true,
    muteErrorNotificationOnStatus: [401],
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(setLanguage(result.result));

      const code = result.result.LanguageCode.substring(0, 2);
      dispatch(getLanguageCode(code));
      moment.locale(code);
    } else {
      // Fallback till svenska
      dispatch(getLanguageCode('sv'));
      moment.locale('sv');
    }
  });
  return promise;
};
