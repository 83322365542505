import * as React from 'react';
import { ShoppingBasketProduct } from '../shoppingBasketProduct';
import { ShoppingBasketCoupon } from '../shoppingBasketCoupon';
import { ShoppingBasketTotalPrice } from '../shoppingBasketTotalPrice';

import { SitecoreDictionary } from 'models';
import { StatusBar } from 'components/common';
import { TranslateFunction } from 'react-localize-redux';

export interface ShoppingBasketProductsListInternalProps {
  order?: fos.api.Order;
  showMessage?: boolean;
}

export interface ShoppingBasketProductsListExternalProps {
  productList: fos.api.Product[];
  translate: TranslateFunction;
  activeFoerening?: number;
  isAdmin: boolean;
}

export type ShoppingBasketProductsListDataProps = ShoppingBasketProductsListInternalProps &
  ShoppingBasketProductsListExternalProps;

export interface ShoppingBasketProductsListActionProps {
  deleteCoupon: (coupon: fos.api.Coupon, orderId: number, foereningId: number) => void;
  deleteSubscription: (productId: number, orderId: number, foereningId: number) => void;
  showMembershipMessage: (showMessage: boolean) => void;
}
export interface ShoppingBasketProductsListState {}

type ShoppingBasketProductsListProps = ShoppingBasketProductsListDataProps & ShoppingBasketProductsListActionProps;

export class ShoppingBasketProductsList extends React.Component<
  ShoppingBasketProductsListProps,
  ShoppingBasketProductsListState
> {
  render() {
    return (
      <div className="ShoppingBasketProducts__Container">
        <StatusBar
          visible={!!this.props.showMessage ? this.props.showMessage : false}
          message={this.props.translate(SitecoreDictionary.Shop.AddedMembershipToBasket)}
          cssClass="ShoppingBagStatusMessage"
          closeBtn={true}
          handleClick={() => this.props.showMembershipMessage(false)}
        />
        <ul className="ShoppingBasketProducts__List">
          {!!this.props.order &&
            this.props.productList.length > 0 &&
            !!this.props.order.subscriptionItems &&
            this.props.order.subscriptionItems.filter(i => !!i).map((item, index) => this.renderProduct(item, index))}
          {(!this.props.order ||
            !this.props.order.subscriptionItems ||
            this.props.order.subscriptionItems.length === 0) && (
            <li className="ShoppingBasketProducts__List__Item">
              {this.props.translate(SitecoreDictionary.Cart.EmptyCart)}
            </li>
          )}
        </ul>

        {!!this.props.order &&
          !!this.props.order.subscriptionItems &&
          this.props.order.subscriptionItems.length > 0 && (
            <ShoppingBasketTotalPrice
              title={this.props.translate(SitecoreDictionary.Cart.TotalToPay) as string}
              amount={this.props.order.subscriptionItems.map(s => s.price.amount).reduce((p, c) => p + c) / 100}
              currency={this.props.order.subscriptionItems[0].price.currency}
            />
          )}
      </div>
    );
  }

  renderProduct = (item: fos.api.SubscriptionItem, index: number) => {
    const product = this.props.productList.find(p => '' + p.Id === '' + item.product.id);
    if (!product && !this.props.isAdmin) {
      throw new Error('Product ' + item.product.id + ' does not exist');
    }
    return (
      <React.Fragment key={item.product.id + ' ' + index}>
        <li className="ShoppingBasketProducts__List__Item" key={item.product.name + index}>
          {!!product && (
            <ShoppingBasketProduct
              item={item}
              product={product}
              delete={() => {
                if (!!this.props.activeFoerening && !!this.props.order) {
                  // this.props.deleteProduct(this.props.anlaeggningId, this.props.productList.find(p => '' + p.Id === '' + item.product.id)!, this.props.activeFoerening);
                  this.props.deleteSubscription(item.product.id, this.props.order.id, this.props.activeFoerening);
                }
              }}
            />
          )}
          {!product && (
            <div>
              {(this.props.translate(SitecoreDictionary.Shop.Admin.ProductNotFound) as string)
                .replace('{name}', item.product.name)
                .replace('{id}', item.product.id.toString())}
            </div>
          )}
        </li>
        {!!item.coupon && (
          <li className="ShoppingBasketProducts__List__Item" key={item.coupon.name + index}>
            <ShoppingBasketCoupon
              coupon={item.coupon}
              delete={() => {
                if (!!this.props.activeFoerening && !!this.props.order) {
                  this.props.deleteCoupon(item.coupon, this.props.order.id, this.props.activeFoerening);
                }
              }}
            />
          </li>
        )}
      </React.Fragment>
    );
  };
}
