import { ApiError } from './errorsModels';

export enum ActionType {
  AddError = 'errors/AddError',
  RemoveError = 'errors/RemoveError',
  AppCrashed = 'errors/AppCrashed',
  AppResumeAfterCrash = 'errors/AppResumeAfterCrash',
  SetAdminError = 'errors/SetAdminError',
}

interface AddAction {
  type: ActionType.AddError;
  error: ApiError;
}

interface RemoveAction {
  type: ActionType.RemoveError;
  error: ApiError;
}

interface AppCrashed {
  type: ActionType.AppCrashed;
  error: Error;
}

interface AppResumeAfterCrash {
  type: ActionType.AppResumeAfterCrash;
}
interface SetAdminErrorAction {
  type: ActionType.SetAdminError;
  error: string;
}

export type ErrorsAction = AddAction | RemoveAction | AppCrashed | AppResumeAfterCrash | SetAdminErrorAction;

export const addError = (error: ApiError): AddAction => ({
  type: ActionType.AddError,
  error,
});

export const removeError = (error: ApiError): RemoveAction => ({
  type: ActionType.RemoveError,
  error,
});

export const appCrashed = (error: Error): AppCrashed => ({
  type: ActionType.AppCrashed,
  error: error,
});

export const appResumeAfterCrash = (): AppResumeAfterCrash => ({
  type: ActionType.AppResumeAfterCrash,
});

export const setAdminError = (error: string): SetAdminErrorAction => ({
  type: ActionType.SetAdminError,
  error,
});
