import { Reducer } from 'redux';
import { Action } from 'store';
import { MedlemskapOchKortReadActionType } from './medlemskapOchKortReadActions';
import { MedlemskapOchKortWriteActionType } from '../write';

export interface MedlemskapOchKortReadState {
  Abonnemang?: fos.api.Abonnemang;
}

const initialState: MedlemskapOchKortReadState = {};

let cachedAbonnemangArray: fos.api.Traeningskort[] = [];

export const MedlemskapOchKortReadReducer: Reducer<MedlemskapOchKortReadState> = (
  state = initialState,
  action: Action
): MedlemskapOchKortReadState => {
  switch (action.type) {
    case MedlemskapOchKortReadActionType.GetMedlemskapOchKort:
      return {
        Abonnemang: action.abonnemang,
      };
    case MedlemskapOchKortReadActionType.ClearMedlemskapOchKort:
      return initialState;
    case MedlemskapOchKortWriteActionType.UpdateAvslutaAbonnemangStatusAction:
      if (state.Abonnemang) {
        let newState: Array<fos.api.Traeningskort> = state.Abonnemang.TraeningskortLista.map(t => {
          if (t.Id !== action.abonnemangId) {
            return t;
          } else if (action.endDate) {
            return { ...t, StatusMessage: action.message, Slutdatum: action.endDate, IsTerminateEnabled: false };
          } else {
            return { ...t, StatusMessage: action.message };
          }
        });
        cachedAbonnemangArray = newState;
        return {
          ...state,
          Abonnemang: {
            ...state.Abonnemang,
            TraeningskortLista: cachedAbonnemangArray,
          },
        };
      }
      return state;
    default:
      return state;
  }
};
