import { Reducer } from 'redux';
import { Action } from 'store';
import { uiSettingsProfileActionType } from './uiSettingsProfileActions';
import { UISettingsProfileUnderEdit } from './uiSettingsProfileModels';

export interface UISettingsProfileState {
  uiProfileSettings: UISettingsProfileUnderEdit;
}

const initialState: UISettingsProfileState = {
  uiProfileSettings: {
    FirstName: false,
    LastName: false,
    Email: false,
    Address: false,
    ZipCode: false,
    City: false,
    PhoneNumber: false,
  },
};

export const uiSettingsProfileReducer: Reducer<UISettingsProfileState> = (
  state: UISettingsProfileState = initialState,
  action: Action
) => {
  switch (action.type) {
    case uiSettingsProfileActionType.GetUISettingsProfileUnderEdit:
      return { ...state };
    case uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit:
      return {
        ...state,
        uiProfileSettings: action.uiSettingsProfileUnderEdit,
      };
    case uiSettingsProfileActionType.UpdateUISettingsProfileUnderClick:
      return {
        ...state,
        uiProfileSettings: {
          ...state.uiProfileSettings,
          PropertyFieldUnderClick: action.propertyFieldUnderClick,
        },
      };
    default:
      return state;
  }
};
