import * as React from 'react';
import { BookingList } from './booking-list';

import { DayViewObject } from '../../models/Day';
import BookingRulesContainer from 'components/bookingRules';
import { SitecoreDictionary } from 'models';
import { PassListSkeleton } from '../common/pass-list-skeleton/PassListSkeleton';
import { TranslateFunction } from 'react-localize-redux';

export interface BookingsDataProps {
  readonly days: DayViewObject<fos.api.Bokning>;
  readonly isFetching: boolean;
  translate: TranslateFunction;
  isFullDesktopView?: boolean;
  isDesktopMode: boolean;
}

export interface BookingsActionProps {
  readonly updateBookingDayExpanded: (dayName: string, expanded: boolean) => void;
  readonly onGetBookings: () => void;
}

type BookingsProps = BookingsDataProps & BookingsActionProps;

export class Bookings extends React.Component<BookingsProps> {
  private readonly containerClassName: string = 'Bookings__PassListContainer';

  componentDidMount() {
    this.props.onGetBookings();
  }

  getClasses(): string {
    const baseClass = 'Schedule Schedule--MyBooking';
    let scheduleClasses = '' + baseClass;
    if (this.props.isFullDesktopView) {
      scheduleClasses = scheduleClasses + ' Schedule--Is-Desktop-Full-View-Mode';
    } else {
      scheduleClasses = scheduleClasses + ' Schedule--Is-Mobile-Mode';
    }
    return scheduleClasses;
  }

  render() {
    return (
      <div className={!!this.props.days ? 'Schedule Schedule--MyBooking Schedule--EmptyList' : this.getClasses()}>
        <div className="Bookings__Title">
          <BookingRulesContainer isDesktopMode={this.props.isDesktopMode} />
        </div>

        {!this.props.isFetching && Object.keys(this.props.days).length < 1 && (
          <div className="Bookings__Text">{this.props.translate(SitecoreDictionary.Bookings.NothingBooked)}</div>
        )}

        <div className={this.containerClassName}>
          {this.props.isFetching && <PassListSkeleton numberOfDays={3} maxAmountOfPassItemsPerDay={3} />}

          {!this.props.isFetching && (
            <BookingList
              translate={this.props.translate}
              days={this.props.days}
              updateBookingDayExpanded={this.props.updateBookingDayExpanded}
            />
          )}
        </div>
      </div>
    );
  }
}
