import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { AutogiroSettings, AutogiroSettingsDataProps, AutogiroSettingsActionProps } from './AutogiroSettings';
import { getTranslate } from 'store/global/dictionary';
import { updateAutogiroActions, updateAutogiro } from 'store/user/updateAutogiroCmd';
import { getIsExecutingFailed, FetchCommandTypes, getIsExecuting, getIsExecuted } from 'store/global/fetch';

const mapStateToProps = (state: RootState): AutogiroSettingsDataProps => {
  return {
    id: state.user.updateAutogiroCmd.id,
    clearingNumber: state.user.updateAutogiroCmd.clearingNumber,
    accountNumber: state.user.updateAutogiroCmd.accountNumber,
    isClearingNumberValid: state.user.updateAutogiroCmd.isClearingNumberValid,
    isAccountNumberValid: state.user.updateAutogiroCmd.isAccountNumberValid,
    updateAutogiroCmdIsExecuting: getIsExecuting(
      state.global.fetch,
      state.user.updateAutogiroCmd.id,
      FetchCommandTypes.UPDATE_AUTOGIRO
    ),
    updateAutogiroCmdIsExecuted: getIsExecuted(
      state.global.fetch,
      state.user.updateAutogiroCmd.id,
      FetchCommandTypes.UPDATE_AUTOGIRO
    ),
    updateAutogiroCmdExecutionFailed: getIsExecutingFailed(
      state.global.fetch,
      state.user.updateAutogiroCmd.id,
      FetchCommandTypes.UPDATE_AUTOGIRO
    ),
    translate: getTranslate(state.locale),
    errorMessage: state.user.updateAutogiroCmd.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AutogiroSettingsActionProps => ({
  updateClearingNumberValue: (newValue: string) => dispatch(updateAutogiroActions.setClearingNumber(newValue)),
  updateAccountNumberValue: (newValue: string) => dispatch(updateAutogiroActions.setAccountNumber(newValue)),
  updateClearingNumberValidity: (isValid: boolean) =>
    dispatch(updateAutogiroActions.setClearingNumberValidity(isValid)),
  updateAccountNumberValidity: (isValid: boolean) => dispatch(updateAutogiroActions.setAccountNumberValidity(isValid)),
  updateAutogiro: () => dispatch(updateAutogiro()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutogiroSettings);
