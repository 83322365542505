import * as React from 'react';
import ScheduleContainer from 'components/schedule/schedule';

export interface SchedulePageDataProps {
  isDesktopMode: boolean;
}

export interface SchedulePageActionProps {
  setScheduleToWeekView: () => void;
  setScheduleToListView: () => void;
}

type SchedulePageProps = SchedulePageDataProps & SchedulePageActionProps;

export const SchedulePage: React.FunctionComponent<SchedulePageProps> = ({
  isDesktopMode,
  setScheduleToWeekView,
  setScheduleToListView,
}) => {
  React.useEffect(() => {
    if (isDesktopMode) {
      return () => setScheduleToListView();
    }
    return () => {};
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isDesktopMode) {
      setScheduleToWeekView();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopMode]);

  return isDesktopMode ? null : (
    <ScheduleContainer isDesktopMode={false} isFullDesktopView={false} isPublicWebMode={false} />
  );
};
