import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { ShoppingBasket, ShoppingBasketDataProps, ShoppingBasketActionsProps } from './shoppingBasket';
import { resetBuyBasketCommand } from 'store/basket';
import { getIsPublicWebMode, LocationActionTypes } from 'store/location';
import { getTranslate } from 'store/global/dictionary';
import { getRegistreradeBetalningsmedel } from 'store/user';
import { ErrorResult, SuccessResult } from 'websd/utils/configureApi';

const mapStateToProps = (state: RootState): ShoppingBasketDataProps => {
  const isAuthenticated =
    !!state.auth.read.isAuthenticated && state.global.session.activeFoerening === state.global.session.shopFoerening;
  const shopFoerening = state.global.session.shopFoerening!;
  return {
    isPublicWebMode: getIsPublicWebMode(state.location),
    isAdmin: state.location.type === LocationActionTypes.AdminShoppingBasket,
    allProducts: state.product.unfilteredProducts,
    shopFoerening: shopFoerening,
    translate: getTranslate(state.locale),
    inloggadeFoerening: state.global.session.activeFoerening,
    isAuthenticated: isAuthenticated,
  };
};

const mapActionsToProps = (dispatch: Dispatch): ShoppingBasketActionsProps => ({
  resetBuyCommand: () => dispatch(resetBuyBasketCommand()),
  getRegistreradeBetalningsmedel: foereningId =>
    dispatch<Promise<ErrorResult | SuccessResult<fos.api.Betalningsmedel>>>(
      getRegistreradeBetalningsmedel(foereningId)
    ),
});

export const ShoppingBasketContainer = connect(mapStateToProps, mapActionsToProps)(ShoppingBasket);
