import {
  setSignatureCasesAction,
  setPaymentMethodsAction,
  setReceiptAction,
  setOrderIdAction,
  setReceiptInfosAction,
} from './read/paymentReadActions';
import { fetching, FetchStatusAggregateTypes, fetchedFailed, fetched } from 'store/global/fetch';
import { onBasketPurchaseError } from './../basket/basketActions';
import { ThunkAction } from 'store';

import { LocationActionTypes, redirectTo } from 'store/location';
import { PaymentResult, P_RESULTCODE, AffaerssytemType } from '.';
import { getPaymentSuccededAction } from './read';
import { deleteBasket, createBasket } from 'store/basket';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { api } from 'api';
import { setOrderId } from 'store/global/session';

export const processPaymentResult =
  (result: PaymentResult): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const fid = parseInt(result.fid, 10);
    // If we have a shopping basket in the state for another förening so we must to change to the current one
    if (result && state.shoppingBasket.foereningId && state.shoppingBasket.foereningId.toString() !== result.fid) {
      dispatch(createBasket(fid));
    }
    if (!result || (!result.error && (!result.RESULTCODE || result.RESULTCODE === P_RESULTCODE.OK))) {
      // Delete the basket
      const inloggadeFoerening =
        state.shoppingBasket && state.shoppingBasket.foereningId ? state.shoppingBasket.foereningId : null;
      if (inloggadeFoerening) {
        dispatch(deleteBasket(inloggadeFoerening));
        localStorage.removeItem('ORDER::' + inloggadeFoerening);
        dispatch(setOrderId(undefined));
      }

      if (result.orderid) {
        dispatch(setOrderIdAction(parseInt(result.orderid, 10)));
      }
      if (result.orderid && result.type === AffaerssytemType.B) {
        dispatch<Promise<ErrorResult | SuccessResult<fos.api.SignatureCase[]>>>(
          getSignatureCases(fid, result.orderid)
        ).then(res => dispatch(getPaymentSuccededAction()));
      } else {
        dispatch(getPaymentSuccededAction());
      }
    } else {
      const error: string =
        result && result.error ? result.error.toString() : result.RESULTCODE ? result.RESULTCODE : '';
      if (error && error !== '') {
        dispatch(onBasketPurchaseError(error));
      }
      dispatch(redirectTo(LocationActionTypes.ShoppingBasket));
    }
  };

export const removeSignatureCase =
  (signatureCase: fos.api.SignatureCase): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    if (!state.payment.read.signatureCases) {
      return;
    }
    const signatureCases = state.payment.read.signatureCases.filter(sc => sc.Id !== signatureCase.Id);
    dispatch(setSignatureCasesAction(signatureCases));
  };

export const getSignatureCases =
  (foereningId: number, orderId: string): ThunkAction =>
  dispatch => {
    dispatch(fetching(orderId, FetchStatusAggregateTypes.SIGNATURECASE));
    const promise = api.get<fos.api.SignatureCase[]>(
      `SignatureCases/GetSignatureCasesByOrder?foereningId=${foereningId}&orderId=${orderId}`
    );
    promise
      .then(res => {
        if (res.error) {
          dispatch(fetchedFailed(orderId, FetchStatusAggregateTypes.SIGNATURECASE));
        } else {
          dispatch(setSignatureCasesAction(res.result));
          dispatch(fetched(orderId, FetchStatusAggregateTypes.SIGNATURECASE, res.result));
        }
      })
      .catch(err => dispatch(fetchedFailed(orderId, FetchStatusAggregateTypes.SIGNATURECASE)));
    return promise;
  };

export const getPaymentMethods =
  (foereningId: number, orderId: number): ThunkAction =>
  dispatch => {
    dispatch(fetching('' + foereningId, FetchStatusAggregateTypes.GET_PAYMENT_METHODS));

    const url = `Foerening/GetPaymentMethods?foereningId=${foereningId}&orderId=${orderId}`;
    const res = api.get<fos.api.PaymentMethod[]>(url);
    res.then(result => {
      if (!result.error) {
        dispatch(setPaymentMethodsAction(result.result));
        dispatch(fetched('' + foereningId, FetchStatusAggregateTypes.GET_PAYMENT_METHODS, result.result));
      } else {
        dispatch(fetchedFailed('' + foereningId, FetchStatusAggregateTypes.GET_PAYMENT_METHODS));
      }
    });
  };

export const getReceipt =
  (receiptId: number, foereningId: number): ThunkAction =>
  dispatch => {
    dispatch(fetching('' + receiptId, FetchStatusAggregateTypes.GET_RECEIPT));

    const url = `Anvaendare/GetReceipt/${receiptId}?foereningId=${foereningId}`;
    const res = api.get<fos.api.Receipt>(url);
    res.then(result => {
      if (!result.error) {
        dispatch(setReceiptAction(result.result));
        dispatch(fetched('' + receiptId, FetchStatusAggregateTypes.GET_RECEIPT, result.result));
      } else {
        dispatch(fetchedFailed('' + receiptId, FetchStatusAggregateTypes.GET_RECEIPT));
      }
    });
  };

export const getReceiptForOrder =
  (orderId: number, foereningId: number): ThunkAction =>
  dispatch => {
    dispatch(fetching('order' + orderId, FetchStatusAggregateTypes.GET_RECEIPT));

    const url = `Anvaendare/GetReceiptForOrder/${orderId}?foereningId=${foereningId}`;
    const res = api.get<fos.api.Receipt>(url);
    res.then(result => {
      if (!result.error) {
        dispatch(setReceiptAction(result.result));
        dispatch(fetched('order' + orderId, FetchStatusAggregateTypes.GET_RECEIPT, result.result));
      } else {
        dispatch(fetchedFailed('order' + orderId, FetchStatusAggregateTypes.GET_RECEIPT));
      }
    });
  };

export const getReceiptInfos =
  (foereningId: number): ThunkAction =>
  dispatch => {
    dispatch(fetching(-1, FetchStatusAggregateTypes.RECEIPT_INFOS));
    const url = `Anvaendare/GetReceiptInfos?foereningId=${foereningId}`;
    const res = api.get<fos.api.ReceiptInfo[]>(url);
    res.then(result => {
      if (!result.error) {
        dispatch(setReceiptInfosAction(result.result));
        dispatch(fetched(-1, FetchStatusAggregateTypes.RECEIPT_INFOS, result.result));
      } else {
        dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.RECEIPT_INFOS));
      }
    });
  };
