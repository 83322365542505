import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { showMembershipMessage } from 'store/basket';
import { Location, LocationState } from 'store/location/locationModels';
import { LocationActionTypes as RouteActionType } from 'store/location/locationActions';
import { SideBarAction } from 'store/global/ui/side-bar/sideBarActions';
import { getTranslate } from 'store/global/dictionary';
import { getIsPublicWebMode, goToHome } from 'store/location';
import { SideBarState, SidebarStatus, SidebarContentType } from 'store/global/ui/side-bar/sideBarReducer';
import {
  HeaderContent,
  HeaderContentDataProps,
  HeaderContentActionProps,
  HeaderContentExternalProps,
} from './HeaderContent';
import {
  getLocation,
  getPageTitle,
  LocationActionTypes as LocationActionType,
  goBack,
  goToSchedule,
  goToLogin,
  siteHasHistory,
  hasHeaderTitle,
  hasHeaderSubtitle,
  getHeaderSubtitleForCurrentLocation,
  getIsIframeMode,
} from 'store/location';
import { getApplicationViewModeIsDesktop } from 'store/global/ui/application-mode';
import { SiteSwitcherAction, SiteSwitcherStatus } from 'store/global/ui/siteswitcher';
import { setSelectedCategory } from 'store/global/ui/shop-filter/uiShopFilterReducer';
import { constructSafeName } from 'utils';
import { TranslateFunction } from 'react-localize-redux';
import { passFilterVisaFilter } from 'store/passFilter';
import { getIsFetching, FetchStatusAggregateTypes, getIsFetchingInBackgound } from 'store/global/fetch';

const setSidebarToContainSchedule = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_SET_CONTENT_TYPE',
    contentType: SidebarContentType.SCHEDULE,
  });
};

const setSidebarToVisible = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_LAUNCH',
    visibility: SidebarStatus.SHOWING,
  });
};

const setSiteSwitcherToVisible = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_SHOW',
  });
};
const setSiteSwitcherToVisibleFullscreen = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_SHOW_FULLSCREEN',
  });
};
const setSiteSwitcherToHidden = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_HIDE',
  });
};

const mapStateToProps = (state: RootState, props: HeaderContentExternalProps): HeaderContentDataProps => {
  const location: Location = getLocation(state.location);
  const translate: TranslateFunction = getTranslate(state.locale);
  const sidebarState: SideBarState = state.global.ui.sideBar;
  const showBackButton: boolean = shouldShowBackButton(location);
  const isPublicWebMode = getIsPublicWebMode(state.location);
  const expanded = state.global.ui.scheduleFilter.expanded;

  let backButtonPosition: 'left' | 'right' | undefined = undefined;
  if (showBackButton) {
    backButtonPosition = getBackButtonPosition(location);
  }
  const queryFromURL = new URLSearchParams(location.search);
  const foereningFromURL = queryFromURL.get('foerening');
  const foereningFromQuery = !!foereningFromURL
    ? state.foereningar.read.Foereningar.find(
        o =>
          constructSafeName(o.Namn.toLowerCase()) ===
          constructSafeName(decodeURIComponent(foereningFromURL.toLowerCase()))
      )
    : undefined;
  const foereningId = !!foereningFromQuery
    ? parseInt('' + foereningFromQuery.Id, 10)
    : !!state.global.session.activeFoerening
    ? state.global.session.activeFoerening
    : -1;
  const products = state.product.filteredProducts;

  let title: string | null = null;
  if (location.type === RouteActionType.PublicProductDetails || location.type === RouteActionType.ProductDetails) {
    const product =
      location.payload && location.payload.productId
        ? products.find(p => '' + p.Id === '' + location.payload.productId)
        : undefined;
    if (product) {
      title = product.NamnSitecore;
    }
  } else if (location.type === RouteActionType.PassDetails && state.passDetails.read.pass) {
    const passId = state.location.payload.passId;
    const isFetching =
      getIsFetching(state.global.fetch, passId, FetchStatusAggregateTypes.PASS) ||
      getIsFetchingInBackgound(state.global.fetch, passId, FetchStatusAggregateTypes.PASS);
    if (!isFetching) {
      title = state.passDetails.read.pass.Rubrik
        ? state.passDetails.read.pass.Rubrik
        : state.passDetails.read.pass.PassNamn;
    }
  }
  if (!title) {
    title = getPageTitle(location, props.isDesktopMode, translate);
  }

  return {
    expanded: expanded,
    sideBarState: sidebarState,
    isSiteSwitcherShowingFullscreen: state.global.ui.siteSwitcher.visibility === SiteSwitcherStatus.SHOW_FULLSCREEN,
    isDesktopMode: props.isDesktopMode,
    activeFoerening: foereningId as number,
    title: title,
    leftIcon: props.isDesktopMode ? state.global.ui.header.leftDesktopIcon : state.global.ui.header.leftMobileIcon,
    rightIcon: props.isDesktopMode ? state.global.ui.header.rightDesktopIcon : state.global.ui.header.rightMobileIcon,
    visible: location.type !== RouteActionType.Login && location.type !== RouteActionType.Reset,
    showBackButton,
    backButtonPosition,
    isStartPage: props.isStartPage,
    isAuthenticated: !!state.auth.read.isAuthenticated,
    showHeaderTitle:
      (hasHeaderTitle(state.location) || hasHeaderSubtitle(state.location)) && !state.auth.read.isAuthenticated,
    headerSubtitle: getHeaderSubtitleForCurrentLocation(),
    numberOfProductsInBasket: state.shoppingBasket.products.length,
    productsInBasket: state.shoppingBasket.products,
    isIframe: getIsIframeMode(state.location, getApplicationViewModeIsDesktop(state)),
    location: state.location,
    isPublicWebMode: isPublicWebMode,
    products: products,
    translate,
    shopFoerening: state.global.session.shopFoerening,
    isAuthenticatedInShopFoerening:
      !!state.auth.read.isAuthenticated && state.global.session.activeFoerening === state.global.session.shopFoerening,
    isFirstVisit: props.isFirstVisit,
    numbersOfFilters:
      state.passFilter.flatFilter.LedareOrTraenare.length +
      state.passFilter.flatFilter.Tidpunkt.length +
      state.passFilter.flatFilter.Traeningspass.length +
      state.passFilter.flatFilter.Traeningsstaelle.length +
      state.passFilter.flatFilter.Veckodag.length,
  };
};

const mapDispatchToProps: HeaderContentActionProps = {
  // onScheduleIconClick: onScheduleIconClick,
  setSidebarToContainSchedule: setSidebarToContainSchedule,
  setSidebarToVisible: setSidebarToVisible,
  setSiteSwitcherToVisible: setSiteSwitcherToVisible,
  setSiteSwitcherToHidden: setSiteSwitcherToHidden,
  setSiteSwitcherToVisibleFullscreen: setSiteSwitcherToVisibleFullscreen,
  goToLogin: goToLogin,
  goToSchedule: goToSchedule,
  goToHome: goToHome,
  goBack,
  setSelectedCategory: setSelectedCategory,
  showStatusMessage: showMembershipMessage,
  setFilterVisible: passFilterVisaFilter,
};

function shouldShowBackButton(location: Location): boolean {
  return (
    location.type === LocationActionType.ShoppingBasket ||
    location.type === LocationActionType.ProductDetails ||
    location.type === LocationActionType.Settings_Profile ||
    location.type === LocationActionType.Settings_PaymentSettings ||
    location.type === LocationActionType.Settings_TrainingPreferences ||
    location.type === LocationActionType.Settings_NotificationSettings ||
    location.type === LocationActionType.Settings_CalendarSyncSettings ||
    location.type === LocationActionType.Settings_UserAccount ||
    location.type === LocationActionType.Schedule ||
    location.type === LocationActionType.PublicProductDetails ||
    (location.type === LocationActionType.PassDetails && siteHasHistory(location as LocationState))
  );
}

function getBackButtonPosition(location: Location): 'right' | 'left' {
  switch (location.type) {
    case LocationActionType.Settings_Profile:
    case LocationActionType.Settings_PaymentSettings:
    case LocationActionType.Settings_UserAccount:
    case LocationActionType.Settings_TrainingPreferences:
    case LocationActionType.PassDetails:
      return 'left';
    case LocationActionType.Settings_NotificationSettings:
      return 'left';
    case LocationActionType.Settings_CalendarSyncSettings:
      return 'left';
    default:
      return 'right';
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContent);
