import * as React from 'react';
import { Banner } from '../common';
import background from 'static/assets/bgbild.jpg';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface ShopAssociationSelectorViewStateProps {
  isDesktopMode: boolean;
  foereningar: Array<fos.api.FoereningBase>;
  shopFoerening?: number;
  translate: TranslateFunction;
  isInitialied: boolean;
}

export interface ShopAssociationSelectorViewActionProps {
  clearBasket: () => void;
  updateFoerening: (foereningsId: number) => void;
}

type ShopAssociationSelectorViewProps = ShopAssociationSelectorViewStateProps & ShopAssociationSelectorViewActionProps;

export class ShopAssociationSelectorView extends React.Component<ShopAssociationSelectorViewProps> {
  render() {
    return (
      <div className="Shop--public--BannerWrap Shop--public--Core">
        <Banner backgroundUrl={background} className="Shop--public--Banner">
          <h4>{this.props.translate(SitecoreDictionary.Shop.PublicShopCoreBannerTitle)}</h4>
          <p>{this.props.translate(SitecoreDictionary.Shop.PublicShopCoreBannerText)}</p>

          <div className="MainInput Input__DropDownMenu ShopAssociationSelector_input">
            <div className="Input__DropDownMenu__Select">
              <select
                className="Input__Select Input__Select__Left"
                value={0}
                onChange={e => {
                  this.handleChange(e);
                }}
              >
                <option value="0">{this.props.translate(SitecoreDictionary.Shop.PublicShopSelectionDefault)}</option>
                {this.props.foereningar.map(foerening => {
                  return (
                    <option key={foerening.Id} value={foerening.Id}>
                      {foerening.DisplayName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </Banner>
      </div>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    this.props.updateFoerening(+event.target.value);
    this.props.clearBasket();
  }
}
