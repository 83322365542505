import {
  SetActiveFoereningAction,
  SessionActionTypes,
  SetInloggadeFoereningarAction,
  ClearActiveFoereningAction,
  SetUserIdAction,
  SetPassIdToBookAction,
} from '.';
import {
  ShouldAcceptBookingRulesAction,
  SetShopFoereningAction,
  SetOrderIdAction,
  SetFoereningExtraInfoAction,
} from './sessionModels';

export const clearActiveFoerening = (): ClearActiveFoereningAction => ({
  type: SessionActionTypes.ClearActiveFoerening,
});

export const setActiveFoerening = (foereningId: number): SetActiveFoereningAction => ({
  type: SessionActionTypes.SetActiveFoerening,
  foereningId,
});
export const setShopFoerening = (foereningId?: number): SetShopFoereningAction => ({
  type: SessionActionTypes.SetShopFoerening,
  foereningId,
});

export const setInloggadeFoereningar = (
  inloggadeFoereningar: fos.api.InloggadeFoerening[]
): SetInloggadeFoereningarAction => ({
  type: SessionActionTypes.SetInloggadeFoereningar,
  inloggadeFoereningar,
});

export const setFoereningExtraInfo = (
  foereningExtraInfo: fos.api.ExtraFoereningInfo,
  foerening: string
): SetFoereningExtraInfoAction => ({
  type: SessionActionTypes.SetFoereningExtraInfo,
  extraInfo: foereningExtraInfo,
  foereningName: foerening,
});

export const setUserId = (userId: number): SetUserIdAction => ({
  type: SessionActionTypes.SetUserId,
  userId,
});
export const setShouldAcceptBookingRulesAction = (shouldAccept: boolean): ShouldAcceptBookingRulesAction => ({
  type: SessionActionTypes.ShouldAcceptBookingRules,
  value: shouldAccept,
});

export const setPassToBook = (pass?: fos.api.Pass): SetPassIdToBookAction => ({
  type: SessionActionTypes.SetPassIdToBook,
  value: pass,
});

export const setOrderId = (orderId?: number): SetOrderIdAction => ({
  type: SessionActionTypes.SetOrderId,
  orderId,
});

export type SessionActions =
  | ClearActiveFoereningAction
  | SetActiveFoereningAction
  | SetShopFoereningAction
  | SetInloggadeFoereningarAction
  | SetFoereningExtraInfoAction
  | SetUserIdAction
  | ShouldAcceptBookingRulesAction
  | SetPassIdToBookAction
  | SetOrderIdAction;
