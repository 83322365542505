import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { PassBookerActionProps, PassBooker, PassBookerExternalDataProps, PassBookerDataProps } from './PassBooker';
import { bokaPass, BokningResponse } from 'store/pass';
import { scheduleAvbokaPass } from 'store/bokningar';
import { FetchCommandTypes, getIsExecuting } from 'store/global/fetch';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';

const mapStateToProps = (state: RootState, props: PassBookerExternalDataProps): PassBookerDataProps => {
  const isExecuting =
    getIsExecuting(state.global.fetch, props.pass.PassId, FetchCommandTypes.BOKA_PASS) ||
    getIsExecuting(state.global.fetch, props.pass.PassId, FetchCommandTypes.AVBOKA_PASS);
  return {
    bokningar: state.bokningar.bokningar,
    isExecuting: isExecuting,

    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: any): PassBookerActionProps => ({
  bokaPass: (pass: fos.api.Pass, bokaKoePlats: boolean) => {
    return dispatch<Promise<ErrorResult | SuccessResult<BokningResponse>>>(bokaPass(pass, bokaKoePlats));
  },
  scheduleAvbokaPass: (payload: fos.api.CancelPayload, passId: string, key: string) => {
    return dispatch<Promise<ErrorResult | SuccessResult<fos.api.AvbokningResponse>>>(
      scheduleAvbokaPass(payload, passId, key)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PassBooker);
