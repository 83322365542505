import { connect } from 'react-redux';
import { RootState } from 'store';
import { SideBarDataProps, SideBarActionProps, SideBar } from './SideBar';
import { closeSideBar } from 'store/global/ui/side-bar';
import { passFilterVisaFilter } from 'store/passFilter';

const mapStateToProps = (state: RootState, props: any): SideBarDataProps => {
  return {
    isShowing: props.isShowing,
    contentType: props.contentType,
    isDesktopFullViewMode: props.isDesktopFullViewMode,
    isDesktopMode: props.isDesktopMode,
    isAuthenticated: props.isAuthenticated,
    aktivFoerening: props.aktivFoerening || state.global.session.activeFoerening,
    userId: props.userId,
  };
};

const mapDispatchToProps: SideBarActionProps = {
  hideSideBar: closeSideBar,
  setFilterVisible: passFilterVisaFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
