import * as React from 'react';
import { Overlay } from 'components/common';
import { PreferenceItem } from './PreferenceItem';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface IntressenProps {
  preferences: Array<string>;
  savedFavoriteAnlaeggningar: Array<string>;
  close: () => void;
  removeFavoriteAnlaeggning: (preference: string) => void;
  addFavoriteAnlaeggning: (preference: string) => void;
  saveFavoriteAnlaeggningar: () => void;
  translate: TranslateFunction;
}

export class AnlaeggningarList extends React.Component<IntressenProps> {
  constructor(props: IntressenProps) {
    super(props);
    this.state = {};
    this.onSave = this.onSave.bind(this);
  }

  render() {
    return (
      <Overlay close={this.props.close} className="Overlay__PreferenceList">
        <div className="PreferenceList">
          <div className="PreferenceList__Title">
            <h5>
              {this.props.translate(SitecoreDictionary.TrainingPreferences.Add)}{' '}
              {this.props.translate(SitecoreDictionary.TrainingPreferences.Facilities)}
            </h5>
          </div>

          {this.props.preferences.map((p, index) => (
            <PreferenceItem
              removeItem={this.props.removeFavoriteAnlaeggning}
              addItem={this.props.addFavoriteAnlaeggning}
              title={p}
              key={index}
              active={this.isAlreadySaved(p)}
              disabled={this.isAlreadySaved(p)}
              selectedCallback={this.props.saveFavoriteAnlaeggningar}
            />
          ))}
        </div>
      </Overlay>
    );
  }

  private isAlreadySaved(name: string) {
    const el = this.props.savedFavoriteAnlaeggningar.find(i => i === name);
    return el ? true : false;
  }

  private onSave() {
    this.props.saveFavoriteAnlaeggningar();
    this.props.close();
  }
}
