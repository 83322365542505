import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';
import { Affaerssystem } from 'store/shop';

export const getPurchaseErrorMessage = (
  translate: TranslateFunction,
  error: string | undefined,
  provider: number | undefined
): string | undefined => {
  const defaultError = (translate(SitecoreDictionary.PaymentErrorMessages.GeneralError) as string) || 'Okänt fel!';
  if (!error) {
    return;
  }
  if (!provider) {
    return defaultError;
  }

  switch (provider.toString()) {
    case Affaerssystem.BRP.toString(): {
      switch (error) {
        case '10':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f10) as string;
        case '101':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f101) as string;
        case '102':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f102) as string;
        case '103':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f103) as string;
        case '104':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f104) as string;
        case '105':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f105) as string;
        case '106':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f106) as string;
        case '107':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f107) as string;
        case '110':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f110) as string;
        case '13':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f13) as string;
        case '14':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f14) as string;
        case '15':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f15) as string;
        case '200':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f200) as string;
        case '201':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f201) as string;
        case '202':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f202) as string;
        case '203':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f203) as string;
        case '204':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f204) as string;
        case '205':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f205) as string;
        case '206':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f206) as string;
        case '207':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f207) as string;
        case '9':
          return translate(SitecoreDictionary.PaymentErrorMessages.BRP.f9) as string;
        default:
          return defaultError;
      }
    }
    case Affaerssystem.PastellData.toString(): {
      switch (error) {
        case 'ERROR':
          return translate(SitecoreDictionary.PaymentErrorMessages.PastellData.ERROR) as string;
        case 'CANCEL':
          return translate(SitecoreDictionary.PaymentErrorMessages.PastellData.CANCEL) as string;
        default:
          return defaultError;
      }
    }
    default:
      return defaultError;
  }
};
