import { PersonLookupStatus } from 'models';

export enum CreateUserActionType {
  Update_SSN = 'UPDATE_SSN',
  Update_FirstName = 'UPDATE_FIRSTNAME',
  Update_LastName = 'UPDATE_LASTNAME',
  Update_Email = 'UPDATE_EMAIL',
  Update_Telephone = 'UPDATE_TELEPHONE',
  Update_Street = 'UPDATE_STREET',
  Update_ZipCode = 'UPDATE_ZIPCODE',
  Update_City = 'UPDATE_CITY',
  Update_Password = 'UPDATE_PASSWORD',
  Update_RepeatPassword = 'UPDATE_REPEATPASSWORD',
  Update_HasNoSwedishSsn = 'UPDATE_HASNOSWEDISHSSN',
  Update_AnlaeggningsId = 'UPDATE_ANLAEGGNINGSID',
  Update_LookupStatus = 'UPDATE_LOOKUPSTATUS',
}

export interface UpdateSSN {
  type: CreateUserActionType.Update_SSN;
  value: string;
}
export interface UpdateAnlaeggningsId {
  type: CreateUserActionType.Update_AnlaeggningsId;
  value: string;
}
export interface UpdateFirstName {
  type: CreateUserActionType.Update_FirstName;
  value: string;
}

export interface UpdateLastName {
  type: CreateUserActionType.Update_LastName;
  value: string;
}

export interface UpdateEmail {
  type: CreateUserActionType.Update_Email;
  value: string;
}

export interface UpdateTelephone {
  type: CreateUserActionType.Update_Telephone;
  value: string;
}

export interface UpdateStreet {
  type: CreateUserActionType.Update_Street;
  value: string;
}

export interface UpdateZipCode {
  type: CreateUserActionType.Update_ZipCode;
  value: string;
}

export interface UpdateCity {
  type: CreateUserActionType.Update_City;
  value: string;
}

export interface UpdatePassword {
  type: CreateUserActionType.Update_Password;
  value: string;
}

export interface UpdateRepeatPassword {
  type: CreateUserActionType.Update_RepeatPassword;
  value: string;
}

export interface UpdateHasNoSwedishSsn {
  type: CreateUserActionType.Update_HasNoSwedishSsn;
  value: boolean;
}

export interface UpdateLookupStatus {
  type: CreateUserActionType.Update_LookupStatus;
  value: PersonLookupStatus;
}

export const updateSSN = (value: string): UpdateSSN => ({
  type: CreateUserActionType.Update_SSN,
  value,
});

export const updateAnlaeggningsId = (value: string): UpdateAnlaeggningsId => ({
  type: CreateUserActionType.Update_AnlaeggningsId,
  value,
});

export const updateFirstName = (value: string): UpdateFirstName => ({
  type: CreateUserActionType.Update_FirstName,
  value,
});

export const updateLastName = (value: string): UpdateLastName => ({
  type: CreateUserActionType.Update_LastName,
  value,
});

export const updateEmail = (value: string): UpdateEmail => ({
  type: CreateUserActionType.Update_Email,
  value,
});

export const updateTelephone = (value: string): UpdateTelephone => ({
  type: CreateUserActionType.Update_Telephone,
  value,
});

export const updateStreet = (value: string): UpdateStreet => ({
  type: CreateUserActionType.Update_Street,
  value,
});

export const updateZipCode = (value: string): UpdateZipCode => ({
  type: CreateUserActionType.Update_ZipCode,
  value,
});

export const updateCity = (value: string): UpdateCity => ({
  type: CreateUserActionType.Update_City,
  value,
});

export const updatePassword = (value: string): UpdatePassword => ({
  type: CreateUserActionType.Update_Password,
  value,
});

export const updateRepeatPassword = (value: string): UpdateRepeatPassword => ({
  type: CreateUserActionType.Update_RepeatPassword,
  value,
});

export const updateHasNoSwedishSsn = (value: boolean): UpdateHasNoSwedishSsn => ({
  type: CreateUserActionType.Update_HasNoSwedishSsn,
  value,
});

export const UpdateLookupStatus = (value: PersonLookupStatus): UpdateLookupStatus => ({
  type: CreateUserActionType.Update_LookupStatus,
  value,
});

export type CreateUserAction =
  | UpdateSSN
  | UpdateAnlaeggningsId
  | UpdateFirstName
  | UpdateLastName
  | UpdateEmail
  | UpdateTelephone
  | UpdateStreet
  | UpdateZipCode
  | UpdateCity
  | UpdatePassword
  | UpdateRepeatPassword
  | UpdateHasNoSwedishSsn
  | UpdateLookupStatus;
