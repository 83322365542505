import * as React from 'react';

import { SitecoreDictionary } from 'models/SitecoreDictionary';
import { NextBokningTitle } from '../nextBokningTitle';
import { IconCalendar } from '../../../icons';
import { Bow } from 'components/common';
import { getAngleBetween } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface NoBookedPassDataProps {
  translate: TranslateFunction;
  isDesktopMode: boolean;
}

export interface NoBookedPassActionProps {
  openSidebarSchedule: () => void;
  showSidebar: () => void;
  goToScheduleMobile: () => void;
}

export interface NoBookedPassState {
  bowStyles: React.CSSProperties;
  bowStylesCalculated: boolean;
}

type NoBookedPassProps = Readonly<NoBookedPassDataProps & NoBookedPassActionProps>;

const initialState: NoBookedPassState = {
  bowStyles: {},
  bowStylesCalculated: false,
};

export class NoBookedPass extends React.Component<NoBookedPassProps, NoBookedPassState> {
  private iconRef?: HTMLDivElement | null;
  private textRef?: HTMLDivElement | null;

  constructor(props: NoBookedPassProps) {
    super(props);
    this.state = initialState;
    this.refresh = this.refresh.bind(this);
    this.hardRefreshListener = this.hardRefreshListener.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.hardRefreshListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.hardRefreshListener);
  }

  onScheduleClick() {
    if (this.props.isDesktopMode) {
      this.props.openSidebarSchedule();
      this.props.showSidebar();
    } else {
      this.props.goToScheduleMobile();
      this.openFilterIcon();
    }
  }
  openFilterIcon = () => {
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var iOS2 = !!navigator.platform.match(/iPhone|iPod|iPad/);
    const filterIco = document.getElementById('FilterIconHeader');
    if (iOS || iOS2 || isSafari) {
      if (filterIco !== null) {
        filterIco.setAttribute('style', 'display: block');
      }
    }
  };

  render() {
    return (
      <div className="NoBookedPass" onClick={() => this.onScheduleClick()}>
        <div className="NoBookedPass__Title">
          <NextBokningTitle title={this.props.translate(SitecoreDictionary.StartPage.NoBookedPassTitle) as string} />
        </div>
        <div className="NoBookedPass__Content">
          <div className="NoBookedPass__Content__Text" ref={ref => this.setTextRef(ref)}>
            {this.props.translate(SitecoreDictionary.StartPage.NoBookedPassText)}
          </div>
          <Bow style={this.state.bowStyles} />
          <div className="NoBookedPass__Content__Icon" ref={ref => this.setIconRef(ref)}>
            <IconCalendar />
          </div>
        </div>
      </div>
    );
  }

  private setTextRef(elementRef: HTMLDivElement | null) {
    this.textRef = elementRef;
    this.refresh();
  }

  private setIconRef(elementRef: HTMLDivElement | null) {
    this.iconRef = elementRef;
    this.refresh();
  }

  private hardRefreshListener = () => this.refresh(true);

  private refresh(hardRefresh: boolean = false) {
    if (this.textRef && this.iconRef && (!this.state.bowStylesCalculated || hardRefresh)) {
      this.updateBowStyles();
    }
  }

  private updateBowStyles() {
    if (!this.iconRef || !this.textRef) {
      return;
    } else {
      const cssProps: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: 'auto',
      };
      const left = this.textRef.offsetLeft + this.textRef.offsetWidth * 0.66 + 'px';
      const width =
        this.iconRef.offsetLeft +
        this.textRef.offsetWidth * 0.33 -
        (this.textRef.offsetLeft + this.textRef.offsetWidth) +
        'px';
      const Start = {
        x: this.textRef.offsetHeight + this.textRef.offsetTop,
        y: this.textRef.offsetLeft + this.textRef.offsetWidth / 2,
      };

      const End = {
        x: this.iconRef.offsetHeight + this.iconRef.offsetTop,
        y: this.iconRef.offsetWidth,
      };
      const angleDeg = getAngleBetween(Start, End, {
        x: End.x,
        y: Start.y,
      });

      let top = this.iconRef.offsetHeight + this.iconRef.offsetTop;
      top += top * 0.1; // lil margin
      this.setState({
        ...this.state,
        bowStyles: {
          ...cssProps,
          left,
          top,
          width,
          transform: `rotateZ(${angleDeg}deg)`,
        },
        bowStylesCalculated: true,
      });
    }
  }
}
