import * as React from 'react';

export interface IconMenuFacilityProps {
  title?: string;
}

export class IconMenuFacility extends React.Component<IconMenuFacilityProps, {}> {
  fillColour = '#000';

  // TODO: Fix translation support for text
  render() {
    return (
      <svg viewBox="0 0 40 39" xmlns="http://www.w3.org/2000/svg" className="IconMenuFacility">
        <title>{this.props.title}</title>
        <style>
          {`
            .IconMenuFacility {
              width: 55px;
              height: 55px;
            }

            .IconMenuFacility .cls-2{fill:none;stroke:#2a3143;stroke-linecap:round;stroke-linejoin:round}
          `}
        </style>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect width="40" height="40" fill="none" />
            <circle className="cls-2" cx="20" cy="17.23" r="3.74" />
            <path
              className="cls-2"
              d="M30.23,17.23C30.23,27,20,33,20,33S9.77,27.31,9.77,17.23a10.23,10.23,0,0,1,20.46,0Z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
