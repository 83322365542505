export interface CalendarState {
  syncIsActive: boolean;
  shouldShowCalendarSyncPopup: boolean;
  selectedCalendar?: string;
}

export enum CalendarActionTypes {
  SetCalendarSync = 'session/TOGGLE_CALENDARSYNC_VALUE',
  SetSelectedCalendar = 'session/SET_SELECTED_CALENDAR',
  SetShouldShowCalendarSyncPopup = 'session/SET_SHOULD_SHOW_CALENDAR_SYNC_POPUP',
}
export interface SetCalendarSyncAction {
  type: CalendarActionTypes.SetCalendarSync;
  syncCalendar: boolean;
}
export interface SetSelectedCalendarAction {
  type: CalendarActionTypes.SetSelectedCalendar;
  id?: string;
}
export interface SetShouldShowCalendarSyncPopupAction {
  type: CalendarActionTypes.SetShouldShowCalendarSyncPopup;
  show: boolean;
}
