import {
  PushNotificationSettingsActions,
  PushNotificationSettingsReducer,
  PushNotificationSettingsRootState,
} from './pushNotificationSettings';
import { NewsReducer } from './news/newsReducer';
import { AnlaeggningAction as AnlaeggningUIAction } from './global/ui/anlaeggningar/anlaeggningarAction';
import { combineReducers, Reducer, Action as ReduxAction, AnyAction } from 'redux';
import { globalReducer, GlobalState, GlobalAction } from './global';
import { LocationState } from './location';
import { LocaleAction } from './global/dictionary';
import { InitializeRootState, initializeReducer } from './initialize/initializeReducer';
import { AnlaeggningarRootState, anlaeggningarReducer, AnlaeggningarAction } from './anlaeggningar';
import { InitializeAction } from './initialize/initializeActions';
import { passDetailsReducer, PassDetailsRootState, PassDetailsAction } from './pass-details';
import { passReducer, PassRootState, PassAction } from './pass';
import { UIAction } from './global/ui/uiActions';
import { userReducer, UserRootState, UserAction } from './user';
import { FoereningarRootState, FoereningarReducer } from './foereningar';
import { BasketRootState, BasketReducer, BasketActions } from './basket';

import { BokningarReducer, BokningarRootState, BokningarAction } from './bokningar';
import {
  TrainingPreferencesReducer,
  TrainingPreferencesRootState,
  TrainingPreferencesAction,
} from './trainingpreferences';
import { MedlemskapOchKortReducer, MedlemskapOchKortRootState, MedlemskapOchKortActions } from './medlemskapOchKort';
import { AuthReducer, AuthRootState, AuthActions, AuthAction } from './auth';
import { PaymentActions, PaymentRootState, PaymentReducer } from './payment';
import { ShopAction, ShopRootState, ProductReducer } from './shop';
import { UIShopFilterAction } from 'store/global/ui/shop-filter/uiShopFilterActions';
import { StatisticsActions, StatisticsRootState, StatisticsReducers } from './statistics';
import { NotificationsActions, NotificationsRootState, NotificationsReducer } from './notifications';
import { NewsAction, NewsRootState } from './news';
import { calendarReducer, CalendarState } from './global/calendar';
import { HeaderAction } from './global/ui/header';
import { PassFilterAction, PassFilterRootState, passFilterReducer } from 'store/passFilter';
import { LocaleState } from 'react-localize-redux';
// import { LocationState } from 'redux-first-router';

export type Action =
  | GlobalAction
  | LocaleAction
  | PassDetailsAction
  | PassAction
  | InitializeAction
  | UIAction
  | UserAction
  | BokningarAction
  | TrainingPreferencesAction
  | MedlemskapOchKortActions
  | AuthActions
  | BasketActions
  | PaymentActions
  | ShopAction
  | AnlaeggningUIAction
  | AnlaeggningarAction
  | UIShopFilterAction
  | StatisticsActions
  | NotificationsActions
  | NewsAction
  | PushNotificationSettingsActions
  | HeaderAction
  | PassFilterAction
  | AuthAction;

export interface Dispatch<A = AnyAction | ReduxAction | Action | ReadonlyArray<Action> | ReadonlyArray<AnyAction>> {
  <T extends A>(action: T): T;
  <R>(asyncAction: ThunkAction): R;
}

export type ThunkAction = (
  dispatch: Dispatch,
  getState: () => RootState
) => // tslint:disable-next-line:no-any
AnyAction | Action | ReduxAction | ReadonlyArray<Action> | ReadonlyArray<AnyAction> | Promise<any> | null | void;

export interface RootState {
  readonly global: GlobalState;
  readonly location: LocationState;
  readonly locale: LocaleState;
  readonly pass: PassRootState;
  readonly passDetails: PassDetailsRootState;
  readonly initialize: InitializeRootState;
  readonly user: UserRootState;
  readonly anlaeggningar: AnlaeggningarRootState;
  readonly foereningar: FoereningarRootState;
  readonly bokningar: BokningarRootState;
  readonly trainingPreferences: TrainingPreferencesRootState;
  readonly abonnemang: MedlemskapOchKortRootState;
  readonly auth: AuthRootState;
  readonly shoppingBasket: BasketRootState;
  readonly payment: PaymentRootState;
  readonly product: ShopRootState;
  readonly statistics: StatisticsRootState;
  readonly notifications: NotificationsRootState;
  readonly news: NewsRootState;
  readonly pushNotificationSettings: PushNotificationSettingsRootState;
  readonly calendar: CalendarState;
  readonly passFilter: PassFilterRootState;
}

export const rootReducer = (locationReducer: Reducer<LocationState>, localeReducer: Reducer<LocaleState>) =>
  combineReducers<RootState>({
    global: globalReducer,
    location: locationReducer,
    locale: localeReducer,
    pass: passReducer,
    passDetails: passDetailsReducer,
    initialize: initializeReducer,
    anlaeggningar: anlaeggningarReducer,
    foereningar: FoereningarReducer,
    user: userReducer,
    bokningar: BokningarReducer,
    trainingPreferences: TrainingPreferencesReducer,
    abonnemang: MedlemskapOchKortReducer,
    auth: AuthReducer,
    shoppingBasket: BasketReducer,
    payment: PaymentReducer,
    product: ProductReducer,
    statistics: StatisticsReducers,
    notifications: NotificationsReducer,
    news: NewsReducer,
    pushNotificationSettings: PushNotificationSettingsReducer,
    calendar: calendarReducer,
    passFilter: passFilterReducer,
  });
