import * as React from 'react';

interface HeaderTitleProps {
  title?: string;
  subtitle?: string;
}

export const HeaderTitle: React.SFC<HeaderTitleProps> = props => (
  <div className="HeaderTitle">
    {props.title && props.title.trim() !== '' && <h1>{props.title}</h1>}
    {props.subtitle && props.subtitle.trim() !== '' && <h4>{props.subtitle}</h4>}
  </div>
);
