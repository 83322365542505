import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { PassFilterExternalProps, PassFilterDataProps, PassFilterActionProps, PassFilter } from './PassFilter';
import {
  PassFilterKategorier,
  passFilterSetKategoriVald,
  passFilterSetFilterValt,
  passFilterSetAllaIFiltergruppVald,
  passFilterSetFilterIGruppVald,
  passFilterSetFiltergruppOeppnad,
  PassFilterFavoritfilter,
  passFilterSetFavoritfilterValt,
  passFilterRensaFilter,
  passFilterVisaFilter,
  saveFavouriteFilter,
  getFavouriteFilters,
  deleteFavouriteFilter,
  passFilterSetFilterFraanCache,
  PassFilterFilterCache,
} from 'store/passFilter';
import { getTranslate } from 'store/global/dictionary';
import { HeaderIconEnum, setHeaderIcons } from 'store/global/ui/header';
import { getQueryStringValue } from 'utils';
import { LocationActionTypes } from 'store/location';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';

const mapState = (state: RootState, props: PassFilterExternalProps): PassFilterDataProps => {
  const inloggadFoerening = state.global.session.inloggadeFoereningar.find(
    i => '' + i.Id === '' + state.global.session.activeFoerening
  );
  let foereningName = decodeURIComponent(getQueryStringValue('foerening'));
  if (!foereningName && inloggadFoerening) {
    foereningName = inloggadFoerening.DisplayName;
  }
  return {
    filter: state.passFilter.filter,
    oeppenKategori: state.passFilter.oeppenKategori,
    favoritFilter: state.passFilter.favoritFilter,
    valtFavoritFilterId: state.passFilter.valtFavoritFilterId,
    filterSynligt: state.passFilter.filterSynligt,
    translate: getTranslate(state.locale),
    userId: state.global.session.userId,
    flatFilter: state.passFilter.flatFilter,
    harTraeffar: state.passFilter.filteredPassIds.some(p => p.passIds.length > 0),
    foereningName: foereningName,
    foereningId: !!inloggadFoerening ? inloggadFoerening.Id : undefined,
    isPassDetailsShowing: state.location.type === LocationActionTypes.PassDetails,
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: any): PassFilterActionProps => ({
  vaeljKategori: (kategori: keyof typeof PassFilterKategorier, vald: boolean) => {
    dispatch(passFilterSetKategoriVald(kategori, vald));
  },
  vaeljFilter: (kategori: keyof typeof PassFilterKategorier, namn: string, vald: boolean) => {
    dispatch(passFilterSetFilterValt(kategori, namn, vald));
  },
  vaeljAllaIFiltergrupp: (kategori: keyof typeof PassFilterKategorier, filtergruppNamn: string, vald: boolean) => {
    dispatch(passFilterSetAllaIFiltergruppVald(kategori, filtergruppNamn, vald));
  },
  vaeljFilterIGrupp: (
    kategori: keyof typeof PassFilterKategorier,
    filtergruppNamn: string,
    filter: string,
    vald: boolean
  ) => {
    dispatch(passFilterSetFilterIGruppVald(kategori, filtergruppNamn, filter, vald));
  },
  oeppnaFiltergrupp: (kategori: keyof typeof PassFilterKategorier, filtergruppNamn: string, oeppnad: boolean) => {
    dispatch(passFilterSetFiltergruppOeppnad(kategori, filtergruppNamn, oeppnad));
  },
  vaeljFavoritfilter: (favoritfilterId: number) => {
    dispatch(passFilterSetFavoritfilterValt(favoritfilterId));
  },
  skapaFavoritfilter: (favoritfilter: PassFilterFavoritfilter) => {
    return dispatch<Promise<ErrorResult | SuccessResult<PassFilterFavoritfilter>>>(saveFavouriteFilter(favoritfilter));
  },
  rensaFilter: () => {
    dispatch(passFilterRensaFilter());
  },
  setFilterSynligt: (synligt: boolean) => {
    dispatch(passFilterVisaFilter(synligt));
  },
  deleteFavoritFilter: (favoritfilter: PassFilterFavoritfilter) => {
    return dispatch<Promise<ErrorResult | SuccessResult<boolean>>>(deleteFavouriteFilter(favoritfilter));
  },
  getFavoritFilters: (userId: number) => {
    return dispatch<Promise<ErrorResult | SuccessResult<PassFilterFavoritfilter[]>>>(getFavouriteFilters(userId));
  },
  setFilterFraanCache: (cache: PassFilterFilterCache) => {
    dispatch(passFilterSetFilterFraanCache(cache));
  },
  setHeaderIcons: (
    leftDesktopIcon?: HeaderIconEnum,
    rightDesktopIcon?: HeaderIconEnum,
    leftMobileIcon?: HeaderIconEnum,
    rightMobileIcon?: HeaderIconEnum
  ) => dispatch(setHeaderIcons(leftDesktopIcon, rightDesktopIcon, leftMobileIcon, rightMobileIcon)),
});

export default connect(mapState, mapDispatchToProps)(PassFilter);
