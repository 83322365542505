import { ThunkAction } from 'store';
import { Anlaeggning } from './anlaeggningarReducer';
import { api } from 'api';
import { getAnlaeggningar } from './anlaeggningarAction';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from 'store/global/fetch';

export const fetchAnlaeggningar = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  let foereningId: number;
  if (!!state.shoppingBasket.foereningId) {
    foereningId = state.shoppingBasket.foereningId;
  } else if (!!state.global.session.shopFoerening) {
    foereningId = state.global.session.shopFoerening;
  } else if (!!state.global.session.activeFoerening) {
    foereningId = state.global.session.activeFoerening;
  } else {
    return;
  }
  dispatch(fetching(-1, FetchStatusAggregateTypes.ANLAEGGNINGAR));
  return api
    .get<Array<Anlaeggning>>(`Foerening/GetAnlaeggningar?foereningId=${foereningId}`, {
      anonymousCall: true,
    })
    .then(res => {
      if (!res.error) {
        dispatch(getAnlaeggningar(res.result));
        dispatch(fetched(-1, FetchStatusAggregateTypes.ANLAEGGNINGAR, res.result));
      } else {
        dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.ANLAEGGNINGAR));
      }
    });
};
