import * as React from 'react';
import { NoBookedPass } from './noBookedPass';

import { BookedPass } from './bookedPass';
import { TranslateFunction } from 'react-localize-redux';

export interface NextBokningDataProps {
  pass?: fos.api.Bokning;
  className?: string;
  translate: TranslateFunction;
  isDesktopMode: boolean;
}
export interface NextBokningActionProps {
  goToScheduleMobile: () => void;
  openSidebarSchedule: () => void;
  showSidebar: () => void;
}

export type NextBokningProps = NextBokningDataProps & NextBokningActionProps;

export class NextBokning extends React.Component<NextBokningProps, {}> {
  // onScheduleClick() {
  //   this.props.openSidebarSchedule();
  //   this.props.showSidebar();
  // }
  render() {
    return (
      <div className={'NextBokning ' + (this.props.className ? this.props.className : '')}>
        {!this.props.pass ? (
          <NoBookedPass
            openSidebarSchedule={this.props.openSidebarSchedule}
            showSidebar={this.props.showSidebar}
            translate={this.props.translate}
            goToScheduleMobile={this.props.goToScheduleMobile}
            isDesktopMode={this.props.isDesktopMode}
          />
        ) : (
          <BookedPass booking={this.props.pass} translate={this.props.translate} />
        )}
      </div>
    );
  }
}
