export enum BookingRulesActionTypes {
  SET_FOCUS_ON_FOEREING = 'SET_FOCUS_ON_FOEREING',
}
export interface SetFocusOnFoerening {
  type: BookingRulesActionTypes.SET_FOCUS_ON_FOEREING;
  id: number;
}

export const setFocusOnFoerening = (id: number) => ({
  type: BookingRulesActionTypes.SET_FOCUS_ON_FOEREING,
  id,
});

export type BookingRulesActions = SetFocusOnFoerening;
