import { HeaderActionTypes } from './headerActions';
import { Reducer } from 'redux';
import { Action } from 'store';

export enum HeaderIconEnum {
  FRISKIS_LOGO = 'FRISKIS_LOGO',
  FILTER = 'FILTER',
  NONE = 'NONE',
  CLOSE = 'CLOSE',
  CALENDAR = 'CALENDAR',
  BACK = 'BACK',
  SHOPPINGBASKET = 'SHOPPINGBASKET',

  INITIAL = 'INITIAL',
}

export interface HeaderState {
  leftDesktopIcon: HeaderIconEnum;
  rightDesktopIcon: HeaderIconEnum;
  leftMobileIcon: HeaderIconEnum;
  rightMobileIcon: HeaderIconEnum;
  title: string;
  visible: boolean;
}

const initialState: HeaderState = {
  leftDesktopIcon: HeaderIconEnum.FRISKIS_LOGO,
  rightDesktopIcon: HeaderIconEnum.NONE,
  leftMobileIcon: HeaderIconEnum.FRISKIS_LOGO,
  rightMobileIcon: HeaderIconEnum.CALENDAR,
  title: '',
  visible: true,
};

export const headerReducer: Reducer<HeaderState> = (state = initialState, action: Action): HeaderState => {
  switch (action.type) {
    case HeaderActionTypes.SET_ICONS:
      return {
        ...state,
        leftDesktopIcon: !!action.leftDesktopIcon
          ? action.leftDesktopIcon === HeaderIconEnum.INITIAL
            ? initialState.leftDesktopIcon
            : action.leftDesktopIcon
          : state.leftDesktopIcon,
        rightDesktopIcon: !!action.rightDesktopIcon
          ? action.rightDesktopIcon === HeaderIconEnum.INITIAL
            ? initialState.rightDesktopIcon
            : action.rightDesktopIcon
          : state.rightDesktopIcon,
        leftMobileIcon: !!action.leftMobileIcon
          ? action.leftMobileIcon === HeaderIconEnum.INITIAL
            ? initialState.leftMobileIcon
            : action.leftMobileIcon
          : state.leftMobileIcon,
        rightMobileIcon: !!action.rightMobileIcon
          ? action.rightMobileIcon === HeaderIconEnum.INITIAL
            ? initialState.rightMobileIcon
            : action.rightMobileIcon
          : state.rightMobileIcon,
      };
    case HeaderActionTypes.RESET_ICONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
