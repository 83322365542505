import { Reducer } from 'redux';
import { Action } from 'store';
import { CreateUserState, CreateUserActionType } from '.';

const initialState: CreateUserState = {
  personnr: '',
  foernamn: '',
  efternamn: '',
  epost: '',
  mobiltelefonnr: '',
  postkod: '',
  stad: '',
  gata: '',
  loesenord: '',
  anlaeggningsId: '0',
  loesenordRepeat: '',
  gdprTermsAccepted: false,
  hasNoSwedishSsn: false,
};

export const createUserReducer: Reducer<CreateUserState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case CreateUserActionType.Update_SSN:
      return { ...state, personnr: action.value };

    case CreateUserActionType.Update_AnlaeggningsId:
      return { ...state, anlaeggningsId: action.value };

    case CreateUserActionType.Update_FirstName:
      return { ...state, foernamn: action.value };

    case CreateUserActionType.Update_LastName:
      return { ...state, efternamn: action.value };

    case CreateUserActionType.Update_Email:
      return { ...state, epost: action.value };

    case CreateUserActionType.Update_Telephone:
      return { ...state, mobiltelefonnr: action.value };

    case CreateUserActionType.Update_Street:
      return { ...state, gata: action.value };

    case CreateUserActionType.Update_ZipCode:
      return { ...state, postkod: action.value };

    case CreateUserActionType.Update_City:
      return { ...state, stad: action.value };

    case CreateUserActionType.Update_Password:
      return { ...state, loesenord: action.value };

    case CreateUserActionType.Update_RepeatPassword:
      return { ...state, loesenordRepeat: action.value };

    case CreateUserActionType.Update_HasNoSwedishSsn:
      return { ...state, hasNoSwedishSsn: action.value };

    case CreateUserActionType.Update_LookupStatus:
      return { ...state, lookupStatus: action.value };
    default:
      return state;
  }
};
