export enum PassDetailsReadActionType {
  PassDetails_UpdatePassDetails = 'PASS_DETAILS_UPDATE_PASS_DETAILS',
}

export interface PassDetailsUpdatePassReadAction {
  type: PassDetailsReadActionType.PassDetails_UpdatePassDetails;
  passDetails: fos.api.PassDetails;
}

export const updatePassDetailsData = (passData: fos.api.PassDetails): PassDetailsUpdatePassReadAction => ({
  type: PassDetailsReadActionType.PassDetails_UpdatePassDetails,
  passDetails: passData,
});

export type PassDetailsReadAction = PassDetailsUpdatePassReadAction;
