import * as React from 'react';
import { MainInput } from 'components/common';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface AutogiroInputDataProps {
  className?: string;
  isClearingNumberValid?: boolean;
  isAccountNumberValid?: boolean;
  required?: boolean;
  shouldValidate?: boolean;
  clearingNumberValue: string;
  accountNumberValue: string;
  disabled?: boolean;
  translate: TranslateFunction;
}

export interface AutogiroInputActionProps {
  onClearingNumberChange: (newValue: string) => void;
  onAccountNumberChange: (newValue: string) => void;
}

export type AutogiroInputProps = AutogiroInputDataProps & AutogiroInputActionProps;

export const AutogiroInput: React.SFC<AutogiroInputProps> = props => (
  <div>
    <h4 className="ShoppingBasketForm__Title">
      {props.translate(SitecoreDictionary.Profile.PaymentSettings.DebitDetails)}
    </h4>
    <div className={`AutogiroInput ${props.className ? props.className : ''}`}>
      <MainInput
        name="clearingNumber"
        shouldValidate={props.shouldValidate}
        isValid={props.isClearingNumberValid}
        required={props.required}
        maxlength={5}
        border="grey"
        label={props.translate(SitecoreDictionary.Profile.PaymentSettings.ClearingNumber) as string}
        alignLabel="left"
        labelColor="black"
        align="center"
        value={props.clearingNumberValue}
        onValueChange={value => props.onClearingNumberChange(value as string)}
        disabled={props.disabled}
        type="number"
        autocomplete="off"
        hideSpinner={true}
      />
      <MainInput
        name="bankAccountNumber"
        shouldValidate={props.shouldValidate}
        required={props.required}
        isValid={props.isAccountNumberValid}
        border="grey"
        label={props.translate(SitecoreDictionary.Profile.PaymentSettings.AccountNumber) as string}
        alignLabel="left"
        labelColor="black"
        align="center"
        value={props.accountNumberValue}
        onValueChange={value => props.onAccountNumberChange(value as string)}
        disabled={props.disabled}
        autocomplete="off"
        type="number"
        hideSpinner={true}
      />
    </div>
  </div>
);
