import { connect } from 'react-redux';
import {
  ShopFilterCategory,
  ShopFilterCategoryDataProps,
  ShopFilterCategoryActionProps,
  ShopFilterCategoryProps,
} from './ShopFilterCategory';
import { RootState, Dispatch } from 'store';
import {
  UIScheduleFilterAction,
  // , UIScheduleFilterActionType
} from 'store/global/ui/schedule-filter/';
import {
  getCategoryLocalState as getScheduleCategoryLocalState,
  UIScheduleFilterState,
} from 'store/global/ui/schedule-filter/uiScheduleFilterReducer';
import { UIScheduleFilterActionType } from 'store/global/ui/schedule-filter/uiScheduleFilterActions';
import { ScheduleFilterCategoryName } from 'store/schedule/scheduleModels';
import { ShopFilterType } from '../ShopFilter';

import { UIShopFilterActionType, UIShopFilterAction } from 'store/global/ui/shop-filter/';
import { ShopFilterCategoryName } from 'store/shop/shopModels';
import {
  getCategoryLocalState as getShopCategoryLocalState,
  UIShopFilterState,
} from 'store/global/ui/shop-filter/uiShopFilterReducer';

const setTopPosition = (
  categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName | string,
  scheduleFilterUIState: UIScheduleFilterState | UIShopFilterState,
  topPosition: number,
  props: ShopFilterCategoryDataProps
) => (dispatch: Dispatch<UIScheduleFilterAction | UIShopFilterAction>) => {
  if (props.filterType === ShopFilterType.Schedule) {
    dispatch({
      type: UIScheduleFilterActionType.SetCategoryTopPosition,
      category: categoryName as ScheduleFilterCategoryName,
      topPosition: topPosition,
    });
  }
  if (props.filterType === ShopFilterType.Shop) {
    dispatch({
      type: UIShopFilterActionType.SetCategoryTopPosition,
      category: categoryName as ShopFilterCategoryName,
      topPosition: topPosition,
    });
  }
};

const expandCategory = (
  categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName | string,
  filterUIState: UIScheduleFilterState | UIShopFilterState,
  props: ShopFilterCategoryProps
) => (dispatch: Dispatch<UIScheduleFilterAction | UIShopFilterAction>) => {
  if (props.filterType === ShopFilterType.Schedule) {
    const categoryState = getScheduleCategoryLocalState(
      filterUIState as UIScheduleFilterState,
      categoryName as ScheduleFilterCategoryName
    );
    dispatch({
      type: UIScheduleFilterActionType.ToggleCategoryClicked,
      category: categoryName as ScheduleFilterCategoryName,
      clicked: categoryState.clicked,
    });

    if (categoryState.atTop) {
        setTimeout(function() {
          dispatch({
            type: UIScheduleFilterActionType.SetCategoryAtNotTop,
            category: categoryName as ScheduleFilterCategoryName,
          });
        }, 600);
    } else {
        setTimeout(function() {
          dispatch({
            type: UIScheduleFilterActionType.SetCategoryAtTop,
            category: categoryName as ScheduleFilterCategoryName,
          });
        }, 300);
    }
  }
  if (props.filterType === ShopFilterType.Shop) {
    const categoryState = getShopCategoryLocalState(
      filterUIState as UIShopFilterState,
      categoryName as ShopFilterCategoryName
    );
    dispatch({
      type: UIShopFilterActionType.ToggleCategoryClicked,
      category: categoryName as ShopFilterCategoryName,
      clicked: categoryState.clicked,
    });

    if (categoryState.atTop) {
        dispatch({
          type: UIShopFilterActionType.SetCategoryAtNotTop,
          category: categoryName as ShopFilterCategoryName,
        });
    } else {
        dispatch({
          type: UIShopFilterActionType.SetCategoryAtTop,
          category: categoryName as ShopFilterCategoryName,
        });
    }
  }
};
const mapState = (state: RootState, props: any): ShopFilterCategoryDataProps => {
  return {
    categoryName: props.categoryName,
    data: props.data,
    internalCategoryName: props.internalCategoryName,
    localState: props.localState, // TODO: Map to proper field.
    filterUIState: props.filterUIState,
    onFavoriteFilterDelete: props.onFavoriteFilterDelete,
    filterCategoryActionFunctions: props.filterCategoryActionFunctions,
    selectedValues: props.selectedValues,
    translate: props.translate,
    listItemGroupingMapping: props.listItemGroupingMapping,
    isFullDesktopView: props.isFullDesktopView,
    isDesktopMode: props.isDesktopMode,
    filterType: props.filterType,
    showSearchbar: props.showSearchbar,
    children: props.children,
    id: props.id,
    selectedCategory: props.selectedCategory,
    setClickedCategory: props.setClickedCategory,
    giveToggleButtonSomeFocus: props.giveToggleButtonSomeFocus,
    categoryDescription: props.categoryDescription,
  };
};
const mapDispatchToProps = (dispatch: Dispatch, props: any): ShopFilterCategoryActionProps => ({
  expandCategory: (
    categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName | string,
    filterUIState: UIScheduleFilterState | UIShopFilterState,
    filterCategoryProps: ShopFilterCategoryProps
  ) => dispatch(expandCategory(categoryName, filterUIState, filterCategoryProps)),
  setTopPosition: (
    categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName | string,
    scheduleFilterUIState: UIScheduleFilterState | UIShopFilterState,
    topPosition: number,
    filterCategoryDataProps: ShopFilterCategoryDataProps
  ) => dispatch(setTopPosition(categoryName, scheduleFilterUIState, topPosition, filterCategoryDataProps)),
  selectAllCategoryListItems: props.selectAllCategoryListItems,
  unSelectCategoryHeader: props.unSelectCategoryHeader,
});

export default connect(mapState, mapDispatchToProps)(ShopFilterCategory);
