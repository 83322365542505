import {
  PasswordResetActionTypes,
  SetPasswordResetAction,
  ClearPasswordResetAction,
  SetPasswordResetResultAction,
  PasswordResetErrorCodes,
  SetNewPasswordAction,
  SetRepeatNewPasswordAction,
  SetIsNewPasswordValidAction,
  SetIsRepeatNewPasswordValidAction,
} from './passwordResetModels';

export const passwordResetLinkRequested = (result: boolean): SetPasswordResetAction => ({
  type: PasswordResetActionTypes.SET_PASSWORD_RESET,
  passwordResetRequestCompleted: true,
  isSuccess: result,
});

export const setPasswordResetResult = (
  result: boolean,
  errorCode?: PasswordResetErrorCodes
): SetPasswordResetResultAction => ({
  type: PasswordResetActionTypes.RESET_PASSWORD_RESULT,
  passwordResetSucceeded: result,
  errorCode,
});

export const setNewPassword = (value: string): SetNewPasswordAction => ({
  type: PasswordResetActionTypes.SET_NEW_PASSWORD,
  value,
});

export const setRepeatNewPassword = (value: string): SetRepeatNewPasswordAction => ({
  type: PasswordResetActionTypes.SET_REPEAT_NEW_PASSWORD,
  value,
});

export const setIsNewPasswordValid = (isValid: boolean): SetIsNewPasswordValidAction => ({
  type: PasswordResetActionTypes.SET_IS_NEW_PASSWORD_VALID,
  isValid,
});

export const setIsRepeatNewPasswordValid = (isValid: boolean): SetIsRepeatNewPasswordValidAction => ({
  type: PasswordResetActionTypes.SET_IS_REPEAT_NEW_PASSWORD_VALID,
  isValid,
});

export const doClearPasswordResetState = (): ClearPasswordResetAction => ({
  type: PasswordResetActionTypes.CLEAR_PASSWORD_RESET,
});
