import * as React from 'react';

// https://upmostly.com/tutorials/build-a-react-switch-toggle-component
interface SwitchProps {
  isOn: boolean;
  handleToggle: () => void;
  onColor: string;
  switchId: string;
}

const Switch = ({ isOn, handleToggle, switchId, onColor }: SwitchProps) => {
  return (
    <React.Fragment>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new${switchId}`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? onColor : undefined }}
        className="react-switch-label"
        htmlFor={`react-switch-new${switchId}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </React.Fragment>
  );
};

export default Switch;
