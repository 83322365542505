import { Dispatch, Action } from 'redux';
import { RootState, ThunkAction } from 'store';
import { BokningRegler, addMedlemsVillkor, addFoereningar, addSmsAndEmailVillkor } from './read';
import { api } from 'api';
import { addBokningRegler } from './read';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from 'store/global/fetch';
import { constructSafeName } from 'utils';
import { updateFoereningId } from 'store/auth/loginCmd';

const LAST_KNOWN_FOERENING_STORAGE_KEY: string = 'LAST_KNOWN_FOERENING';

export const fetchReglerIfNotInStore = (foereningId: number) => async (
  dispatch: Dispatch<Action>,
  getState: () => RootState
) => {
  let state = getState();
  let foerening = state.foereningar.read.Foereningar.find(f => foereningId.toString() === f.Id.toString());
  if (foerening) {
    const res = await api.get<BokningRegler>('Bokning/Rules?foerening=' + constructSafeName(foerening.Namn), {
      muteErrorNotificationOnStatus: [404],
    });
    if (!res.error) {
      if (res.result.RuleText) {
        dispatch(addBokningRegler(res.result.RuleText, foereningId));
      } else {
        dispatch(addBokningRegler('Inga regler hittade', foereningId));
      }
    }
  }
};

export const getMedlemsVillkor = (foereningNamn: string, foereningId: number): ThunkAction => async dispatch => {
  dispatch(fetching(foereningId, FetchStatusAggregateTypes.MEDLEM_VILLKOR));
  try {
    const res = await api.get<fos.api.MedlemsVillkor>(
      `Abonnemang/GetSubscriptionRules?foereningNamn=${constructSafeName(foereningNamn)}`,
      {
        muteErrorNotificationOnStatus: [404],
      }
    );
    if (!res.error) {
      dispatch(addMedlemsVillkor(res.result.RuleText as string, foereningId));
      dispatch(fetched(foereningId, FetchStatusAggregateTypes.MEDLEM_VILLKOR, res.result));
    } else {
      dispatch(addMedlemsVillkor('', foereningId));
      dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.MEDLEM_VILLKOR));
    }
  } catch (error) {
    dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.MEDLEM_VILLKOR));
  }
};

export const getSmsEmailVillkor = (foereningNamn: string, foereningId: number): ThunkAction => async dispatch => {
  dispatch(fetching(foereningId, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR));
  try {
    const res = await api.get<fos.api.MedlemsVillkor>(
      `Abonnemang/GetSmsAndEmailRules?foereningNamn=${constructSafeName(foereningNamn)}`,
      {
        muteErrorNotificationOnStatus: [404],
      }
    );
    if (!res.error) {
      dispatch(addSmsAndEmailVillkor(res.result.RuleText as string, foereningId));
      dispatch(fetched(foereningId, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR, res.result));
    } else {
      dispatch(addSmsAndEmailVillkor('', foereningId));
      dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR));
    }
  } catch (error) {
    dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR));
  }
};

export const getFoereningar = (all: boolean): ThunkAction => async dispatch => {
  dispatch(fetching(-1, FetchStatusAggregateTypes.GET_FOERENINGAR));
  try {
    const foereningarUrl = all ? `Foerening/GetAllFoereningar` : `Foerening/GetFoereningar`;
    const res = await api.get<fos.api.FoereningBase[]>(foereningarUrl, {
      anonymousCall: true,
    });
    if (!res.error) {
      dispatch(addFoereningar(res.result));
      const lastKnownFoerening = localStorage.getItem(LAST_KNOWN_FOERENING_STORAGE_KEY);
      if (!!lastKnownFoerening && lastKnownFoerening !== '') {
        const foerening = res.result.find(f => f.Id.toString() === lastKnownFoerening.toString());
        if (foerening) {
          dispatch(updateFoereningId(foerening.Id));
        }
      }

      dispatch(fetched(-1, FetchStatusAggregateTypes.GET_FOERENINGAR, res.result));
    } else {
      dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.GET_FOERENINGAR));
    }
  } catch (error) {
    dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.GET_FOERENINGAR));
  }
};
