import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { Start, StartDataProps, StartActionProps } from './Start';
import { getNextBokning, getBookingsInBackground } from 'store/bokningar';
import { switchView, LocationActionTypes, redirectTo, goToSchedule } from 'store/location';
import { getTranslate } from 'store/global/dictionary';
import { FetchStatusAggregateTypes, getEntityStatus } from 'store/global/fetch';
import { getProfileInfo } from 'store/user';

import { SideBarAction } from 'store/global/ui/side-bar/sideBarActions';
import { SidebarStatus, SidebarContentType } from '../../store/global/ui/side-bar/sideBarReducer';
import { fetchNewNotificationsCount } from 'store/global/ui/newNotification';
import { fetchPassListInForeground } from 'store/pass';

const setSidebarToContainSchedule = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_SET_CONTENT_TYPE',
    contentType: SidebarContentType.SCHEDULE,
  });
};

const setSidebarToVisible = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_LAUNCH',
    visibility: SidebarStatus.SHOWING,
  });
};

const mapStateToProps = (state: RootState, props: any): StartDataProps => {
  const inloggadFoerening = state.global.session.inloggadeFoereningar.find(
    f => '' + f.Id === '' + state.global.session.activeFoerening
  );
  return {
    news: state.news.news,
    nextBokning: state.bokningar.nextBokning,
    hasFetchedBokningarOnce: state.bokningar.hasFetchedBokningarOnce,
    translate: getTranslate(state.locale),
    fetchBokningarStatus: state.global.session.userId
      ? getEntityStatus(state.global.fetch, state.global.session.userId, FetchStatusAggregateTypes.BOKNING)
      : undefined,
    fetchPassListStatus: state.global.session.activeFoerening
      ? getEntityStatus(state.global.fetch, state.global.session.activeFoerening, FetchStatusAggregateTypes.PASS_LIST)
      : undefined,
    aktivFoerening: state.global.session.activeFoerening,
    userName: state.user.read.user.FirstName,
    isAuthenticated: !!state.auth.read.isAuthenticated,
    inloggadFoerening,
  };
};

const mapActionsToProps = (dispatch: Dispatch): StartActionProps => ({
  getNextBokning: () => dispatch(getNextBokning()),
  getPassList: (aktivFoerening: number) => dispatch(fetchPassListInForeground(aktivFoerening)),
  fetchBokningar: () => dispatch(getBookingsInBackground()),
  goToSchema: () => {
    dispatch(setSidebarToContainSchedule());
    dispatch(setSidebarToVisible());
  },
  goToBookings: () => dispatch(switchView(LocationActionTypes.Bookings)),
  switchView: (location: LocationActionTypes) => dispatch(switchView(location)),
  getProfileInfo: (aktivFoerening: number) => dispatch(getProfileInfo(aktivFoerening)),
  redirectTo: (location: LocationActionTypes) => dispatch(redirectTo(location)),
  setSidebarToContainSchedule: () => dispatch(setSidebarToContainSchedule()),
  setSidebarToVisible: () => dispatch(setSidebarToVisible()),
  getNewNotificationsCount: () => dispatch(fetchNewNotificationsCount()),
  goToScheduleMobile: () => dispatch(goToSchedule()),
});

export default connect(mapStateToProps, mapActionsToProps)(Start);
