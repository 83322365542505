import * as React from 'react';
import moment from 'moment';
import { NextBokningTitle } from '../nextBokningTitle';

import { SitecoreDictionary } from 'models/SitecoreDictionary';
import { BookingItemContainer } from 'components/common';
import { TranslateFunction } from 'react-localize-redux';

interface BookedPassProps {
  booking: fos.api.Bokning;
  translate: TranslateFunction;
}

export const BookedPass: React.SFC<BookedPassProps> = props => {
  const getTitle = () => {
    const startTime = moment(props.booking.StartTime);
    const diffInDays = startTime.startOf('day').diff(moment().startOf('day'), 'days');
    const relativeTime = startTime.calendar().split(' ')[0];
    const formatedDay = startTime.format('dddd');
    const day: string = diffInDays < 2 ? relativeTime.toLocaleLowerCase() : 'på ' + formatedDay;
    return `${props.translate(SitecoreDictionary.StartPage.NextPass)} - ${day}`;
  };

  return (
    <div className="BookedPass">
      <div className="BookedPass__Title">
        <NextBokningTitle title={getTitle()} />
      </div>
      <div className="BookedPass__Content">
        <BookingItemContainer bokning={props.booking} translate={props.translate} />
      </div>
    </div>
  );
};
