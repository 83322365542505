import * as React from 'react';
import { Overlay } from 'components/common';
import { IconArrow } from 'components/icons';

interface FoereningSwitcherDataProps {
  currentFoerening: fos.api.InloggadeFoerening;
  foereningar: fos.api.InloggadeFoerening[];
}

interface FoereningSwitcherActionProps {
  close: () => void;
  setActiveFoerening: (foerening: fos.api.InloggadeFoerening) => void;
}

export type FoereningSwitcherProps = FoereningSwitcherDataProps & FoereningSwitcherActionProps;

export const FoereningSwitcher: React.SFC<FoereningSwitcherProps> = props => {
  return (
    <Overlay className="FoereningSwitcher__Overlay" close={props.close}>
      <div className="FoereningSwitcher">
        <ul className="FoereningSwitcher__List">
          {props.foereningar.map(f => (
            <li
              className={
                'FoereningSwitcher__ListItem' +
                (props.currentFoerening.Id === f.Id ? ' FoereningSwitcher__ListItem--current' : '')
              }
              key={f.Id}
              onClick={() => (props.currentFoerening.Id !== f.Id ? props.setActiveFoerening(f) : null)}
            >
              {!!f.DisplayName ? f.DisplayName : f.Namn}
              {f.Id !== props.currentFoerening.Id && (
                <div className="FoereningSwitcher__ListItem__Arrow">
                  <IconArrow color="#000" direction="down" />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Overlay>
  );
};
