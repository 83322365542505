import * as React from 'react';
import { TranslateFunction } from 'react-localize-redux';

import FacilitiesItem from './FacilitiesItem';
import { Spinner } from 'components/common';

export interface FacilitiesDataProps {
  activeFoerning?: fos.api.InloggadeFoerening;
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
  translate: TranslateFunction;
  isFetching: boolean;
}

export interface FacilitiesActionProps {
  getSitecoreAnlaeggningar: (foereningName: string, foereningId: number, countryId: number) => void;
}

export type FacilitiesProps = FacilitiesDataProps & FacilitiesActionProps;

export class Facilities extends React.Component<FacilitiesProps> {
  componentDidMount() {
    if (this.props.activeFoerning) {
      this.props.getSitecoreAnlaeggningar(
        this.props.activeFoerning.Namn,
        this.props.activeFoerning.Id,
        this.props.activeFoerning.CountryId
      );
    }
  }

  render() {
    if (this.props.isFetching) {
      return (
        <div style={{ justifyContent: 'center', flexDirection: 'row', marginTop: '22px', width: '100%' }}>
          <Spinner />
        </div>
      );
    }
    const date = new Date();
    const day = (date.getDay() + 6) % 7;
    const items = this.props.anlaeggningar.map((i, key) => (
      <FacilitiesItem
        name={i.Name}
        street={i.Street}
        disabled={i.Disabled}
        open={i.OpeningHours[day]}
        additionalOpen={i.AdditionalOpeningHours[day]}
        close={i.ClosingHours[day]}
        additionalClose={i.AdditionalClosingHours[day]}
        receptionOpen={i.ReceptionOpeningHours[day]}
        additionalReceptionOpen={i.AdditionalReceptionOpeningHours[day]}
        receptionClosing={i.ReceptionClosingHours[day]}
        additionalReceptionClosing={i.AdditionalReceptionClosingHours[day]}
        openTomorrow={day === 6 ? i.OpeningHours[0] : i.OpeningHours[day + 1]}
        closeTomorrow={day === 6 ? i.ClosingHours[0] : i.ClosingHours[day + 1]}
        receptionOpenTomorrow={day === 6 ? i.ReceptionOpeningHours[0] : i.ReceptionOpeningHours[day + 1]}
        exceptionalHoursEnd={i.ExceptionalHoursEnd}
        exceptionalHoursStart={i.ExceptionalHoursStart}
        exceptionalHoursText={i.ExceptionalHoursText}
        url={i.Url}
        key={key}
        translate={this.props.translate}
      />
    ));
    return <div className="Facilities">{items}</div>;
  }
}
