import * as React from 'react';
import { Notification } from './Notification';
import { EntityStatus } from 'store/global/fetch';
import { Spinner } from '../common/Spinner';

export interface NotificationsDataProps {
  notifications: Array<fos.api.Notification>;
  fetchState?: EntityStatus;
  newNotificationsCount?: number;
}

export interface NotificationsActionProps {
  fetchNotifications: () => void;
}

export interface NotificationsState {}

type NotificationsProps = NotificationsDataProps & NotificationsActionProps;

export class Notifications extends React.Component<NotificationsProps, NotificationsState> {

  componentDidMount() {
    this.props.fetchNotifications();
  }

  UNSAFE_componentWillReceiveProps(newProps: NotificationsProps) {
    if (this.props.newNotificationsCount !== newProps.newNotificationsCount) {
      newProps.fetchNotifications();
    }
  }

  render() {
    if (this.props.fetchState === EntityStatus.FETCHING) {
      return (
        <div className="Statistics__Spinner__Container">
          <Spinner className="Statistics__Spinner" />
        </div>
      );
    } else {
      return (
        <div className="Notifications">
          {this.props.notifications.map(x => (
            <Notification key={x.Id} notification={x} />
          ))}
        </div>
      );
    }
  }
}
