import * as React from 'react';

export interface IconMenuInstallningarProps {
  title?: string;
}

export class IconMenuInstallningar extends React.Component<IconMenuInstallningarProps, {}> {
  // TODO: Fix translation support for text

  render() {
    /* tslint:disable */
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.5 29.5" className="IconMenuInstallningar2 ">
        <title>{this.props.title}</title>
        <style type="text/css">
          {`
          .IconMenuInstallningar2 {
              width: 22px;
              height: 22px;

          }
          .IconMenuInstallningar2 .st0{stroke-width: 0.8;fill:none;stroke:#343434;stroke-miterlimit:10;}
          .IconMenuInstallningar2 .st1{stroke-width: 0.8;fill:none;stroke:#343434;stroke-linecap:round;stroke-linejoin:round;}
          `}
        </style>
        <path
          className="st0"
          d="M2.2 11.9L2.2 11.9c-0.6-0.6-0.6-1.6 0-2.2l7.5-7.5c0.6-0.6 1.6-0.6 2.2 0l0 0c0.6 0.6 0.6 1.6 0 2.2l-7.5 7.5C3.8 12.5 2.8 12.5 2.2 11.9z"
        />
        <path
          className="st0"
          d="M9.1 2.9c0.4-0.4 0.3-1.1-0.1-1.5L8.4 0.9C8 0.4 7.3 0.4 6.9 0.8L0.8 6.9C0.4 7.3 0.4 8 0.9 8.4l0.6 0.6C1.8 9.4 2.5 9.4 2.9 9"
        />
        <path
          className="st0"
          d="M27.2 17.6L27.2 17.6c0.6 0.6 0.6 1.6 0 2.2l-7.5 7.5c-0.6 0.6-1.6 0.6-2.2 0v0c-0.6-0.6-0.6-1.6 0-2.2l7.5-7.5C25.7 17 26.6 17 27.2 17.6z"
        />
        <path
          className="st0"
          d="M20.4 26.5C20 27 20.1 27.7 20.5 28c0 0 0 0 0 0l0.5 0.5c0.4 0.4 1.1 0.5 1.5 0.1l6.1-6.1c0.4-0.4 0.3-1.1-0.1-1.5L28 20.5c-0.4-0.4-1.1-0.5-1.5-0.1 0 0 0 0 0 0"
        />
        <line className="st0" x1="7.4" y1="8.9" x2="11.2" y2="12.6" />
        <line className="st0" x1="12.6" y1="11.2" x2="8.8" y2="7.4" />
        <line className="st0" x1="22" y1="20.6" x2="18.4" y2="17" />
        <line className="st0" x1="16.9" y1="18.4" x2="20.6" y2="22" />
        <path
          className="st0"
          d="M22.3 5.4L25 2.7c-1.9-0.9-4.2-0.1-5.1 1.8 -0.4 0.9-0.5 1.9-0.2 2.8L4.6 21.7c-0.9 0.9-0.9 2.4 0 3.4C5.6 26 7.1 25.9 8 25l0 0L22.2 9.8c2 0.6 4.2-0.5 4.8-2.5 0.3-1 0.2-2-0.2-2.9l-2.6 2.7c-0.2 0.2-0.6 0.2-0.8 0l-1-0.9C22.1 6 22.1 5.6 22.3 5.4z"
        />
        <path className="st1" d="M6.1 24.2c-0.6-0.5-0.6-1.4 0-2 0 0 0 0 0 0" />
      </svg>
    );
  }
}
