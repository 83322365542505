import * as React from 'react';

export interface IconMyAccountProps {
  active: boolean;
  color?: string;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconMyAccount extends React.Component<IconMyAccountProps, {}> {
  // defaultStyle = `stroke: #8B8F92;fill:none;stroke-linecap:round;stroke-linejoin:round;`;

  defaultStyle = `stroke: ${
    this.props.color ? this.props.color : '#8B8F92'
  };fill:none;stroke-linecap:round;stroke-linejoin:round;`;
  activeStyle = 'stroke: #e31836;fill:none;stroke-linecap:round;stroke-linejoin:round;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  render() {
    // TODO: Fix translation support for text
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" className="IconMyAccount">
        <title>{this.props.title}</title>
        <style type="text/css">
          .IconMyAccount .inactive{'{' + this.defaultStyle + '}'}
          .IconMyAccount .active{'{' + this.activeStyle + '}'}
          .activeMenu .inactive{'{' + this.activeStyle + '}'}
        </style>
        {/* tslint:disable */}

        <g id="Layer_2" data-name="Layer 2">
          <path
            className={this.getClassForIcon()}
            d="M44.69,27.28c0,2.83-1.83,6.44-3.23,8.57-2.12,3.22-4.57,4.32-8.46,4.32s-6.23-1-8.36-4.17c-1.46-2.15-3.33-5.83-3.33-8.72,0-7.12,5.23-12.89,11.69-12.89S44.69,20.16,44.69,27.28Z"
          />
          <path
            className={this.getClassForIcon()}
            d="M28.62,41.44a8.58,8.58,0,0,0-1.41.12,5,5,0,0,0-1.5.63L18.08,47a5.87,5.87,0,0,0-2.78,5v.77a.34.34,0,0,0,.34.33H50.37a.33.33,0,0,0,.33-.33l0-.71A5.88,5.88,0,0,0,48,47.16L39.87,42a4.68,4.68,0,0,0-2.37-.51"
          />
          <path className={this.getClassForIcon()} d="M22.79,44a25,25,0,0,1,.67,9" />
          <path className={this.getClassForIcon()} d="M43,44s-1.67,4.83-1,9" />
          <path className={this.getClassForIcon()} d="M24.66,42.83s2.33,5.3,7.94,5.3,6.79-3,6.79-3" />
          <rect width="66" height="66" fill="none" />
        </g>
      </svg>
    );
  }
}
