import * as React from 'react';
import { LocationState } from 'store/location';
import { Spinner } from '../../../common/Spinner';
import { StatusBar, MainButton } from '../../../common';
import { FoereningInternal } from 'store/foereningar/read';
import { addProductToOrder } from 'components/shop/Order/Order';

import { canAddProduct } from 'utils';
import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface ProductDetailsDataProps {
  isDesktopMode: boolean;
  products: Array<fos.api.Product>;
  productsInBasket?: Array<fos.api.Product>;
  location: LocationState;
  shopFoerening: number;
  foereningar: FoereningInternal[];
  translate: TranslateFunction;
  orderId: number;
  isPendingOrderChange: boolean;
}

export interface ProductDetailsActionProps {
  addProduct: (product: fos.api.Product, foereningId: number) => void;
}

export interface ProductDetailsState {
  statusMessageVisible: boolean;
  statusMessage: string;
  statusMessageClass: string;
  mainButtonDisabled: boolean;
}

type ProductDetailsProps = ProductDetailsDataProps & ProductDetailsActionProps;

export class ProductDetails extends React.Component<ProductDetailsProps, ProductDetailsState> {
  constructor(props: ProductDetailsProps) {
    super(props);

    this.state = {
      statusMessageVisible: false,
      statusMessage: this.props.translate(SitecoreDictionary.Shop.ProductAddedToBasket) as string,
      statusMessageClass: 'StatusMessage--Green',
      mainButtonDisabled: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!!this.props.productsInBasket) {
      this.setState({
        ...this.state,
        mainButtonDisabled: this.checkIfInBasket(this.props.productsInBasket, this.findProductId()),
      });
    }
  }

  checkIfInBasket = (basket: fos.api.Product[], id: string) => {
    for (let i = 0; i < basket.length; i++) {
      if (String(basket[i].Id) === id) {
        return true;
      }
    }
    return false;
  };

  findProductId = () => {
    const path = this.props.location.pathname;
    return path.substring(path.lastIndexOf('/') + 1);
  };

  findCustomerType = () => {
    return this.props.location.query.customerType as string | undefined;
  };

  findProduct = () => {
    const productId = this.findProductId();
    const customerType = this.findCustomerType();
    const selectedProduct = this.props.products.find(
      item => '' + item.Id === '' + productId && '' + item.CustomerType.Id === '' + customerType
    );
    if (!selectedProduct && this.props.products.length > 0) {
      throw Error(
        'Did not find product with Id "' +
          productId +
          '" and customerType "' +
          customerType +
          '". Valid products are: ' +
          JSON.stringify(this.props.products)
      );
    }
    return selectedProduct;
  };

  handleClick = (product: fos.api.Product) => {
    if (!this.props.shopFoerening) {
      return;
    }
    const canAdd = canAddProduct(
      product,
      this.props.translate,
      this.props.productsInBasket,
      this.props.isPendingOrderChange
    );
    if (canAdd.canAdd) {
      this.props.addProduct(product, this.props.shopFoerening);
      addProductToOrder(this.props.shopFoerening, this.props.orderId, product);
      this.setState({
        ...this.state,
        statusMessageVisible: true,
        statusMessage: this.props.translate(SitecoreDictionary.Shop.ProductAddedToBasket) as string,
        statusMessageClass: 'StatusMessage--Green',
        mainButtonDisabled: true,
      });
    } else if (!!canAdd.message) {
      this.setState({
        ...this.state,
        statusMessageVisible: true,
        statusMessage: canAdd.message,
        statusMessageClass: 'StatusMessage--Black',
      });
    }
    this.setStatusMessageTimeout();
  };

  setStatusMessageTimeout = () => {
    setTimeout(
      () =>
        this.setState({
          ...this.state,
          statusMessageVisible: false,
        }),
      4500
    );
  };

  getButtonText = () => {
    if (this.state.mainButtonDisabled) {
      return this.props.translate(SitecoreDictionary.Shop.ProductExistsInBasket) as string;
    } else {
      return this.props.translate(SitecoreDictionary.Shop.AddToBasket) as string;
    }
  };

  render() {
    const displaySpinner = this.props.foereningar.length === 0 || this.props.products.length === 0;
    const product = this.findProduct();
    return (
      <React.Fragment>
        <div className="mainProductContent">
          {displaySpinner && <Spinner alignment="center" />}
          {!displaySpinner && product && (
            <React.Fragment>
              <div className="product">
                <div className="productImage">
                  <img alt="Produktkort" src={process.env.REACT_APP_FOS_URL + '/assets/images/icon_card.svg'} />
                </div>
                <div className="productInfo">
                  <div className="productInfo__Cont">
                    <div
                      className={
                        'Item__Price priceText bold ' + (product.PaymentMethod === 'AutoGiro' ? 'flex-row' : '')
                      }
                    >
                      <div>{product.Pris}:-</div>
                      {product.PaymentMethod === 'AutoGiro' && <span className="Price__PerMonth">/mån</span>}
                      {product.NrOfMonths > 1 && product.PaymentMethod !== 'AutoGiro' && (
                        <span className="Price__Compare-PerMonth">
                          ({Number((product.Pris / product.NrOfMonths).toFixed(0))}:- /mån)
                        </span>
                      )}
                    </div>
                    <div className="productInfo__Button">
                      <MainButton
                        type="submit"
                        buttonStyle="solid"
                        color="red"
                        title={this.getButtonText()}
                        className="Button"
                        disabled={this.state.mainButtonDisabled}
                        handleOnClick={() => this.handleClick(product)}
                      />
                      <StatusBar
                        visible={this.state.statusMessageVisible}
                        message={this.state.statusMessage}
                        cssClass={this.state.statusMessageClass}
                      />
                    </div>
                    {product.BeskrivningSitecore && (
                      <div
                        className="productInfo__Text"
                        dangerouslySetInnerHTML={{
                          __html: product.BeskrivningSitecore,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
