import * as React from 'react';
import { ShopAssociationSelectorViewContainer } from 'components/shop/ShopAssociationSelectorViewContainer';
import ShopNotAvailableForFoerening from 'components/shop/ShopNotAvailableForFoerening';
import { Spinner } from '../common';
import { BasketRootState } from 'store/basket';

export interface ShopWrapperInternalDataProps {
  shopFoerening?: number;
  foereningAktiv?: boolean;
  isInitialized: boolean;
  isFetchingProducts: boolean;
  isProductsFetched: boolean;
  isAdmin: boolean;

  isFetchingOrder: boolean;
  isOrderFetched: boolean;
}

export interface ShopWrapperExternalDataProps {
  children: JSX.Element;
  isDesktopMode: boolean;
}

export type ShopWrapperDataProps = ShopWrapperExternalDataProps & ShopWrapperInternalDataProps;

export interface ShopWrapperActionProps {
  fetchProducts: (foereningId: number, isAdmin: boolean) => void;
  setOrderId: (orderId: number) => void;
  createOrder: (foereningId: number, products: fos.api.Product[]) => void;
  getOrder: (foereningId: number, orderId: number) => void;
}

export const ShopWrapper: React.FunctionComponent<ShopWrapperDataProps & ShopWrapperActionProps> = ({
  shopFoerening,
  foereningAktiv,
  isInitialized,
  isFetchingProducts,
  isProductsFetched,
  isAdmin,
  isFetchingOrder,
  isOrderFetched,
  children,
  isDesktopMode,
  fetchProducts,
  setOrderId,
  createOrder,
  getOrder,
}) => {
  React.useEffect(() => {
    if (isInitialized && !!foereningAktiv && !!shopFoerening) {
      if (!isFetchingProducts && !isProductsFetched) {
        fetchProducts(shopFoerening, isAdmin);
      }
    }
  }, [isInitialized, foereningAktiv, shopFoerening, isFetchingProducts, isProductsFetched, fetchProducts, isAdmin]);

  React.useEffect(() => {
    if (isInitialized && !!foereningAktiv && !!shopFoerening) {
      if (!isFetchingProducts && isProductsFetched && !isFetchingOrder && !isOrderFetched) {
        const orderFromLocalStorage = localStorage.getItem('ORDER::' + shopFoerening);
        if (!!orderFromLocalStorage) {
          const orderId = parseInt(orderFromLocalStorage, 10);
          setOrderId(orderId);
          getOrder(shopFoerening, orderId);
        } else {
          const basketFromSessionStorage = sessionStorage.getItem('BASKET::' + shopFoerening);
          if (!!basketFromSessionStorage) {
            const basket: BasketRootState = JSON.parse(basketFromSessionStorage);
            createOrder(shopFoerening, basket.products);
          } else {
            createOrder(shopFoerening, []);
          }
        }
      }
    }
  }, [
    isInitialized,
    foereningAktiv,
    shopFoerening,
    isFetchingProducts,
    isProductsFetched,
    isFetchingOrder,
    isOrderFetched,
    setOrderId,
    getOrder,
    createOrder,
  ]);
  return (
    <React.Fragment>
      {!isInitialized && (
        <div className="Shop">
          <Spinner alignment="center" />
        </div>
      )}
      {isInitialized && shopFoerening === undefined && (
        <ShopAssociationSelectorViewContainer isDesktopMode={isDesktopMode} />
      )}
      {isInitialized && shopFoerening !== undefined && foereningAktiv === undefined && (
        <div className="Shop">
          <Spinner alignment="center" />
        </div>
      )}
      {isInitialized && shopFoerening !== undefined && foereningAktiv === false && <ShopNotAvailableForFoerening />}
      {isInitialized &&
        shopFoerening !== undefined &&
        !!foereningAktiv &&
        (!isProductsFetched || isFetchingProducts || !isOrderFetched || isFetchingOrder) && (
          <div className="Shop">
            <Spinner alignment="center" />
          </div>
        )}
      {isInitialized &&
        shopFoerening !== undefined &&
        !!foereningAktiv &&
        isProductsFetched &&
        !isFetchingProducts &&
        isOrderFetched &&
        !isFetchingOrder &&
        children}
    </React.Fragment>
  );
};
