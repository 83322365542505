import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { EntityStatus } from 'store/global/fetch';
import { Autogiros } from './Autogiros';
import { Avtalegiros } from './Avtalegiros';
import { Cards } from './Cards';
import { TranslateFunction } from 'react-localize-redux';

export interface PaymentSettingsDataProps {
  betalningsmedel?: fos.api.Betalningsmedel;
  fetchState?: EntityStatus;
  inloggadFoereningId: number | undefined;
  translate: TranslateFunction;
}

export interface PaymentSettingsActionProps {
  getRegistreradeBetalningsmedel: (foreningId: number) => void;
  setAutogiroToUpdate: (id: string, currentClearingNumber: string, currentAccountNumber: string) => void;
}

type PaymentSettingsProps = PaymentSettingsDataProps & PaymentSettingsActionProps;

export class PaymentSettings extends React.Component<PaymentSettingsProps, {}> {
  componentDidMount() {
    if (!!this.props.inloggadFoereningId) {
      this.props.getRegistreradeBetalningsmedel(this.props.inloggadFoereningId);
    }
  }

  render() {
    const hasRegisteredPayments = this.hasPayments();
    return (
      <div className="PaymentSettings">
        <div className="PaymentSettings__Information">
          {
            this.props.translate(
              hasRegisteredPayments
                ? SitecoreDictionary.Profile.PaymentSettings.Information
                : SitecoreDictionary.Profile.PaymentSettings.NoRegisteredPayments
            ) as string
          }
        </div>
        {this.autogiro}
        {this.avtalegiro}
        {this.cards}
        {hasRegisteredPayments && (
          <div className="PaymentSettings__TerminateInformation">{this.terminateInformation}</div>
        )}
      </div>
    );
  }

  private get cards() {
    return (
      <Cards
        data={this.props && this.props.betalningsmedel ? this.props.betalningsmedel.BankkortLista : null}
        fetchState={this.props.fetchState}
        translate={this.props.translate}
      />
    );
  }

  private get autogiro() {
    return (
      <Autogiros
        data={this.props && this.props.betalningsmedel ? this.props.betalningsmedel.AutogiroLista : null}
        fetchState={this.props.fetchState}
        translate={this.props.translate}
        enableEdit={this.props && this.props.betalningsmedel ? this.props.betalningsmedel.IsEditEnabled : false}
        setAutogiroToUpdate={this.props.setAutogiroToUpdate}
      />
    );
  }

  private get avtalegiro() {
    return (
      <Avtalegiros
        data={this.props && this.props.betalningsmedel ? this.props.betalningsmedel.AvtalegiroLista : null}
        fetchState={this.props.fetchState}
        translate={this.props.translate}
      />
    );
  }

  private get terminateInformation() {
    if (this.props && this.props.betalningsmedel && this.props.betalningsmedel.IsEditEnabled) {
      return this.props.translate(SitecoreDictionary.Profile.PaymentSettings.TerminateInformationWhenEnabled) as string;
    }
    return this.props.translate(SitecoreDictionary.Profile.PaymentSettings.TerminateInformation) as string;
  }

  private hasPayments(): boolean {
    return (
      !!this.props.betalningsmedel &&
      ((!!this.props.betalningsmedel.AutogiroLista && this.props.betalningsmedel.AutogiroLista.length > 0) ||
        (!!this.props.betalningsmedel.AvtalegiroLista && this.props.betalningsmedel.AvtalegiroLista.length > 0) ||
        (!!this.props.betalningsmedel.BankkortLista && this.props.betalningsmedel.BankkortLista.length > 0))
    );
  }
}
