import { RootState } from 'store';
import { connect } from 'react-redux';
import { SubscriptionsAndCards, SubscriptionsActionProps, SubscriptionsDataProps } from './SubscriptionsAndCards';
import { getAbonnemang, resetMedlemskapOchKort } from 'store/medlemskapOchKort';
import { buySubscriptionOnTheFly } from 'store/basket';
import { getEntityStatus, FetchStatusAggregateTypes, EntityStatus } from 'store/global/fetch';
import { getReceiptInfos } from 'store/payment';

import { getTranslate } from 'store/global/dictionary';

const mapState = (state: RootState): SubscriptionsDataProps => {
  const aktivFoerening = state.foereningar.read.Foereningar.find(
    f => '' + f.Id === '' + state.global.session.activeFoerening
  );
  const medlemskapOchKortStatus = getEntityStatus(
    state.global.fetch,
    -1,
    FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT
  );
  const receiptInfosStatus = getEntityStatus(state.global.fetch, -1, FetchStatusAggregateTypes.RECEIPT_INFOS);
  return {
    abonnemang: state.abonnemang.read.Abonnemang,
    receipts: state.payment.read.receiptInfos,
    isFetching:
      medlemskapOchKortStatus === EntityStatus.FETCHING ||
      medlemskapOchKortStatus === EntityStatus.FETCHING_IN_BACKGROUND ||
      receiptInfosStatus === EntityStatus.FETCHING ||
      receiptInfosStatus === EntityStatus.FETCHING_IN_BACKGROUND,
    activeFoerening: state.global.session.activeFoerening,
    shopActive: !!aktivFoerening ? aktivFoerening.AerButikAktiv : undefined,
    translate: getTranslate(state.locale),
  };
};

const mapDispatch: SubscriptionsActionProps = {
  getAbonnemang,
  getReceiptInfos,
  resetMedlemskapOchKort,
  buySubscription: buySubscriptionOnTheFly,
};

export default connect(mapState, mapDispatch)(SubscriptionsAndCards);
