import {
  toogleFoereningSwitcher,
  isSwitchingFoereningSwitcher,
} from 'store/global/ui/foereningSwitcher/foereningSwitcherActions';
import { sitecoreUtils } from 'utils/sitecoreIntegration';
import { updatePassword } from './loginCmd/loginCmdActions';
import { bokaPassFromSitecore, fetchPassListInForeground } from 'store/pass/passThunks';
import { ThunkAction } from 'store';
import moment from 'moment';
import { AuthReadActions, clearAuthToken, setAuthToken } from 'store/auth/read';
import { ChangeLocationAction, switchView, redirectTo, LocationActionTypes, getIsPublicWebMode } from 'store/location';
import { createBasket, BasketActions } from 'store/basket';
import { setActiveFoerening, setInloggadeFoereningar, SessionActions, setUserId } from 'store/global/session';
import {
  clearSavedAuth,
  hasAValidAuthToken,
  readToken,
  localStorage,
  hasAValidRefreshToken,
  getRefreshToken,
} from 'utils';
import { api } from 'api';
import { getProfileInfo, getExtraFoereningInformation } from '../user';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { clearLoginCmd, updateEmail } from './loginCmd';
import { executingCommand, FetchCommandTypes, executingCommandFailed, executedCommand } from 'store/global/fetch';
import { getBookingsInForeground } from 'store/bokningar';
import { deleteCookie } from 'websd/utils/cookie';
import { fetchNews } from 'store/news';
import { setupTranslation } from 'store/global/ui/language';
import { setUserOnOrder } from 'components/shop/Order/Order';
import { getCalendarSync } from 'store/global/calendar';
import { RootState } from 'store/rootReducer';

export type AuthAction = ChangeLocationAction | SessionActions | BasketActions | AuthReadActions;

const STORED_AUTH_NAME: string = process.env.REACT_APP_AUTH_COOKIE as string;
const FOS_URL: string = process.env.REACT_APP_FOS_URL as string;
const INLOGGADE_FOERENINGAR_STORAGE_KEY: string = 'INLOGGADE_FOERENING';
const LANGUAGE_STORAGE_KEY: string = 'LANGUAGE';
const LAST_KNOWN_FOERENING_STORAGE_KEY: string = 'LAST_KNOWN_FOERENING';

export const logout =
  (goToLogin: boolean = true, shouldRedirectToPublicWeb: boolean = false): ThunkAction =>
  (dispatch, getState) => {
    clearSavedAuth();
    dispatch(clearAuthToken());
    clearLoginId();
    if (shouldRedirectToPublicWeb) {
      dispatch(redirectToPublicWeb());
    } else {
      if (goToLogin) {
        dispatch(switchView(LocationActionTypes.Login, getState().location.query));
      }
    }
    return null;
  };

export const getActiveFoereningUrl = (state: RootState): string => {
  const foereningId = state.global.session.activeFoerening;
  let foerening: fos.api.InloggadeFoerening | undefined;
  if (foereningId) {
    foerening = state.global.session.inloggadeFoereningar.find(x => x.Id.toString() === foereningId.toString());
  }
  let url = FOS_URL;
  if (!!foerening && !!foerening.ExtraFoereningInfo) {
    url = foerening.ExtraFoereningInfo.ExternalUrl;
  }
  return url;
};

export const redirectToPublicWeb = (): ThunkAction => (dispatch, getState) => {
  const url = getActiveFoereningUrl(getState());
  window.open(url, '_self');
};

export const goToPublicWeb = (): ThunkAction => (dispatch, getState) => {
  const url = getActiveFoereningUrl(getState());
  window.open(url, '_blank');
};

export const clearLoginId = () => {
  deleteCookie('LID', '/', process.env.REACT_APP_FOS_DOMAIN);
};

export const login =
  (credentials: fos.api.Credentials): ThunkAction =>
  (dispatch, getState) => {
    localStorage.removeItem(STORED_AUTH_NAME);
    dispatch(executingCommand(credentials.Username, FetchCommandTypes.LOGIN));
    const promise = api.post<fos.api.TokenModel, fos.api.Credentials>('Anvaendare/Login/', credentials, {
      anonymousCall: true,
      muteErrorNotificationOnStatus: [401],
    });
    promise.then(res => {
      if (!res.error) {
        dispatch(executedCommand(credentials.Username, FetchCommandTypes.LOGIN));
        dispatch(authenticateAndSetupUser(res.result, credentials.LanguageId));
        // TODO ouyo Get the currency
        dispatch(createBasket(res.result.AktivFoereningId, 'SEK'));
      } else {
        // TODO handle error messages for different errors
        dispatch(executingCommandFailed(credentials.Username, FetchCommandTypes.LOGIN));
      }
    });

    return promise;
  };

export const refreshToken =
  (token: string, foereningId: number): ThunkAction =>
  (dispatch, getState) => {
    // tslint:disable-next-line: no-any
    const promise = api.post<fos.api.TokenModel, any>('Anvaendare/RefreshLogin/', {
      RefreshToken: token,
      FoereningId: foereningId,
    });

    promise.then(res => {
      if (!res.error) {
        setAuthTokenInStorage(res.result);
      }
    });

    return promise;
  };

export const loginToNewFoerening =
  (foereningId: number): ThunkAction =>
  (dispatch, getState) => {
    const promise = api.get<fos.api.TokenModel>(`Anvaendare/LoginToNewFoerening?foereningId=${foereningId}`, {
      muteErrorNotificationOnStatus: [401],
    });
    promise.then(res => {
      if (!res.error) {
        localStorage.saveItem(LAST_KNOWN_FOERENING_STORAGE_KEY, res.result.AktivFoereningId);
        dispatch(updateAuthenticationAndUserSetup(res.result));
        // TODO ouyo Get the currency
        dispatch(createBasket(res.result.AktivFoereningId, 'SEK'));
      }
      return res;
    });
    return promise;
  };

const setAuthTokenInStorage = (token: fos.api.TokenModel) => {
  localStorage.saveItem(STORED_AUTH_NAME, token, moment(token.Giltighetstid).toDate());
};

export const setInloggadeFoereningarInStorage = (
  inloggadeFoereningar: fos.api.InloggadeFoerening[],
  expirationDate?: string
) => {
  localStorage.saveItem(
    INLOGGADE_FOERENINGAR_STORAGE_KEY,
    inloggadeFoereningar,
    expirationDate ? moment(expirationDate).toDate() : undefined
  );
};

const setLanguageInStorage = (languageId: number) => {
  localStorage.saveItem(LANGUAGE_STORAGE_KEY, languageId);
};

export const authenticateAndSetupUser =
  (token: fos.api.TokenModel, languageId: number): ThunkAction =>
  (dispatch, getState) => {
    // const state = getState();
    setAuthTokenInStorage(token);
    // I app läget vill vi att spara foereningar för längre tid då kan man hämta dem vid auto-inloggning
    setInloggadeFoereningarInStorage(
      token.FoereningarLista,
      token.Giltighetstid
      // !state.global.session.appMode ?
      //   token.Giltighetstid :
      //   undefined
    );
    setLanguageInStorage(languageId);
    dispatch(setAuthToken(token.Token, token.Giltighetstid));
    dispatch(setActiveFoerening(token.AktivFoereningId));
    dispatch(setInloggadeFoereningar(token.FoereningarLista));
    dispatch(setUserId(token.AnvaendarId));
    dispatch(setupTranslation());
    dispatch(getCalendarSync(token.AnvaendarId));
    setUserOnOrder(token.AktivFoereningId);
    localStorage.saveItem(LAST_KNOWN_FOERENING_STORAGE_KEY, token.AktivFoereningId);
    dispatch(clearLoginCmd());
    const foerening = token.FoereningarLista.find(f => '' + f.Id === '' + token.AktivFoereningId);
    if (!!foerening) {
      dispatch(getExtraFoereningInformation(foerening.Namn));
    }
    return null;
  };

export const updateAuthenticationAndUserSetup =
  (token: fos.api.TokenModel): ThunkAction =>
  (dispatch, getState) => {
    // const state = getState();
    setAuthTokenInStorage(token);
    // I app läget vill vi att spara foereningar för längre tid då kan man hämta dem vid auto-inloggning
    setInloggadeFoereningarInStorage(
      token.FoereningarLista,
      token.Giltighetstid
      // !state.global.session.appMode ?
      //   token.Giltighetstid :
      //   undefined
    );
    dispatch(setAuthToken(token.Token, token.Giltighetstid));
    dispatch(setActiveFoerening(token.AktivFoereningId));
    dispatch(setInloggadeFoereningar(token.FoereningarLista));
    dispatch(setUserId(token.AnvaendarId));
    const foerening = token.FoereningarLista.find(f => '' + f.Id === '' + token.AktivFoereningId);
    if (!!foerening) {
      dispatch(getExtraFoereningInformation(foerening.Namn));
    }
    return null;
  };

export const loginWithCreds =
  (credentials: fos.api.Credentials): ThunkAction =>
  (dispatch, getState) => {
    const hasFirstVisitSet = !!localStorage.getItem('firstvisit') || !!localStorage.getItem('firstVisit');
    if (!hasFirstVisitSet) {
      localStorage.saveItem('firstVisit', true);
    }
    const promise = dispatch<Promise<ErrorResult | SuccessResult<fos.api.TokenModel>>>(login(credentials));
    promise.then(res => {
      if (!res.error) {
        if (sitecoreUtils.isIframedInSitecore()) {
          sitecoreUtils.onLoginSignal();
        } else {
          const state = getState();
          if (state.location.type === LocationActionTypes.Login) {
            dispatch(redirectTo(LocationActionTypes.Home));
          } else if (state.location.type === LocationActionTypes.AdminLogin) {
            dispatch(redirectTo(LocationActionTypes.Home));
          } else if (
            state.location.type === LocationActionTypes.PublicShop ||
            state.location.type === LocationActionTypes.Shop
          ) {
            dispatch(redirectTo(LocationActionTypes.ShoppingBasket));
          } else if (
            state.location.type === LocationActionTypes.PublicShoppingBasket ||
            state.location.type === LocationActionTypes.ShoppingBasket
          ) {
            dispatch(redirectTo(LocationActionTypes.ShoppingBasket));
          } else if (
            state.location.type === LocationActionTypes.ProductDetails ||
            state.location.type === LocationActionTypes.PublicProductDetails
          ) {
            dispatch(redirectTo(LocationActionTypes.ShoppingBasket));
          } else if (
            state.location.type === LocationActionTypes.AdminProductDetails ||
            state.location.type === LocationActionTypes.AdminShop ||
            state.location.type === LocationActionTypes.AdminShoppingBasket ||
            state.location.type === LocationActionTypes.AdminRegister
          ) {
            dispatch(redirectTo(LocationActionTypes.AdminShoppingBasket));
          } else {
            dispatch(redirectTo(LocationActionTypes.Home));
          }
        }
      }
    });
    return promise;
  };

export const loginFromShop =
  (credentials: fos.api.Credentials): ThunkAction =>
  dispatch => {
    const promise = dispatch<Promise<ErrorResult | SuccessResult<fos.api.TokenModel>>>(login(credentials));
    promise.then(res => {
      if (!res.error) {
        dispatch(getProfileInfo(credentials.FoereningId));
      }
    });
    return promise;
  };

export const loginFromSchedule =
  (credentials: fos.api.Credentials, passId: number | string, bokaKoePlats: boolean): ThunkAction =>
  dispatch => {
    const promise = dispatch<Promise<ErrorResult | SuccessResult<fos.api.TokenModel>>>(login(credentials));
    promise.then(res => {
      if (!res.error) {
        dispatch(bokaPassFromSitecore({ passId: passId, foereningsId: credentials.FoereningId }, bokaKoePlats));
        dispatch(getBookingsInForeground());
        window.parent.postMessage('authenticated', process.env.REACT_APP_FOS_URL as string);
      } else {
        dispatch(updateEmail(''));
        dispatch(updatePassword(''));
      }
    });
    return promise;
  };

export const checkAuthStatus = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (hasAValidAuthToken()) {
    const authCookie: fos.api.TokenModel | null = readToken();
    if (authCookie == null) {
      dispatch(logout());
    } else {
      dispatch(setAuthToken(authCookie.Token, authCookie.Giltighetstid));
      const activeFoerening = localStorage.getItem<number>(LAST_KNOWN_FOERENING_STORAGE_KEY);
      if (activeFoerening && activeFoerening.value) {
        dispatch(setActiveFoerening(activeFoerening.value));
        dispatch(createBasket(activeFoerening.value, 'SEK'));
      }

      const inloggadeFoereningar = localStorage.getItem<fos.api.InloggadeFoerening[]>(
        INLOGGADE_FOERENINGAR_STORAGE_KEY
      );
      if (inloggadeFoereningar && inloggadeFoereningar.value) {
        dispatch(setInloggadeFoereningar(inloggadeFoereningar.value));
      }
      dispatch(setUserId(authCookie.AnvaendarId));
      dispatch(setupTranslation());
      const foerening = authCookie.FoereningarLista.find(f => '' + f.Id === '' + authCookie.AktivFoereningId);
      if (!!foerening) {
        dispatch(getExtraFoereningInformation(foerening.Namn));
      }
      if (state.location.type === LocationActionTypes.Login || state.location.type === LocationActionTypes.AdminLogin) {
        dispatch(redirectTo(LocationActionTypes.Home));
      }
      dispatch(getCalendarSync(authCookie.AnvaendarId));
    }
  } else if (hasAValidRefreshToken()) {
    const activeFoerening = localStorage.getItem<number>(LAST_KNOWN_FOERENING_STORAGE_KEY);
    if (activeFoerening !== null && activeFoerening.value !== null) {
      dispatch<Promise<ErrorResult | SuccessResult<fos.api.TokenModel>>>(
        refreshToken(getRefreshToken(), activeFoerening.value)
      ).then(() => {
        if (hasAValidAuthToken()) {
          dispatch(checkAuthStatus());
        } else {
          dispatch(logout());
        }
      });
    }
  } else if (
    state.location.type === LocationActionTypes.Reset ||
    state.location.type === LocationActionTypes.ResetPassword ||
    getIsPublicWebMode(state.location)
  ) {
    dispatch(clearAuthToken());
    dispatch(setupTranslation());
  } else {
    dispatch(logout());
  }
};

const switchToFoerening =
  (foerening: fos.api.InloggadeFoerening): ThunkAction =>
  (dispatch, getState) => {
    dispatch(setActiveFoerening(foerening.Id));
    dispatch(createBasket(foerening.Id, 'SEK'));
    dispatch(toogleFoereningSwitcher(false));
    dispatch(getProfileInfo(foerening.Id));
    dispatch(getBookingsInForeground());
    dispatch(fetchPassListInForeground(foerening.Id));
    dispatch(fetchNews());
    dispatch(switchView());
    dispatch(getExtraFoereningInformation(foerening.Namn));
  };

export const updateActiveFoerening =
  (foerening: fos.api.InloggadeFoerening): ThunkAction =>
  (dispatch, getState) => {
    dispatch(isSwitchingFoereningSwitcher(true));
    const state = getState();
    if (state.global.session.inloggadeFoereningar.find(f => f.Id.toString() === foerening.Id.toString())) {
      dispatch(switchToFoerening(foerening));
      dispatch(isSwitchingFoereningSwitcher(false));
      dispatch(getExtraFoereningInformation(foerening.Namn));
    } else {
      dispatch(getExtraFoereningInformation(foerening.Namn));
      dispatch<Promise<ErrorResult | SuccessResult<fos.api.TokenModel>>>(loginToNewFoerening(foerening.Id))
        .then(res => {
          if (res && !res.error) {
            dispatch(switchToFoerening(foerening));
          }
          dispatch(isSwitchingFoereningSwitcher(false));
        })
        .catch(() => dispatch(isSwitchingFoereningSwitcher(false)));
    }
  };
