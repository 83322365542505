import { MobileOSType } from 'models/app/MobileOSType';

export interface RegisterDeviceRequest {
  deviceId: string;
  mobileOs: MobileOSType;
}

export enum RegisterDeviceCmdActionTypes {
  SET_DEVICE_ID = 'PUSH_NOTIFICATIONS/REGISTER_DEVICE_CMD/SET_DEVICE_ID',
  SET_OS = 'PUSH_NOTIFICATIONS/REGISTER_DEVICE_CMD/SET_OS',
}

export interface SetOsTypeAction {
  type: RegisterDeviceCmdActionTypes.SET_OS;
  value: MobileOSType;
}

export interface SetDeviceIdAction {
  type: RegisterDeviceCmdActionTypes.SET_DEVICE_ID;
  value: string;
}
