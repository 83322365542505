import * as React from 'react';

import { Summary } from '../text';
import { PassDetailsInfoBlock } from './PassDetailsInfoBlock';
import { DetailsBlockMobile } from './DetailsBlockMobile';
import { TranslateFunction } from 'react-localize-redux';

interface PassDetailsContentProps {
  translate: TranslateFunction;
  isDesktopMode: boolean;
  pass: fos.api.PassDetails;
  bokningar: fos.api.Bokning[];
}

export const PassDetailsContent: React.FunctionComponent<PassDetailsContentProps> = ({
  translate,
  isDesktopMode,
  pass,
  bokningar,
}) => (
  <div className="PassDetails__Content">
    <div className="Text">
      {pass.Beskrivning ? (
        <Summary header={pass.Ingress ? pass.Ingress : ''} fullText={pass.Beskrivning} />
      ) : (
        <div className="default-text">Tyvärr finns det ingen information om den här träningsformen</div>
      )}
    </div>
    {!isDesktopMode && <DetailsBlockMobile pass={pass} translate={translate} />}

    <PassDetailsInfoBlock isDesktopMode={isDesktopMode} translate={translate} pass={pass} bokningar={bokningar} />
  </div>
);
