import { combineReducers } from 'redux';

import { userReadReducer, UserReadState, UserReadAction } from './read';
import {
  updateUserProfileCmdReducer,
  UpdateUserProfileCmdState,
  UpdateUserProfileCmdActions,
} from './updateUserProfileCmd';
import { createUserReducer, CreateUserAction, CreateUserState } from './createUserCmd';
import { changePasswordReducer, ChangePasswordAction, ChangePasswordState } from './changePasswordCmd';
import { updateAutogiroReducer, UpdateAutogiroAction, UpdateAutogiroState } from './updateAutogiroCmd';

export type UserAction =
  | UserReadAction
  | UpdateUserProfileCmdActions
  | CreateUserAction
  | ChangePasswordAction
  | UpdateAutogiroAction;

export interface UserRootState {
  read: UserReadState;
  write: UpdateUserProfileCmdState;
  createUserCmd: CreateUserState;
  changePasswordCmd: ChangePasswordState;
  updateAutogiroCmd: UpdateAutogiroState;
}

export const userReducer = combineReducers<UserRootState>({
  read: userReadReducer,
  write: updateUserProfileCmdReducer,
  createUserCmd: createUserReducer,
  changePasswordCmd: changePasswordReducer,
  updateAutogiroCmd: updateAutogiroReducer,
});
