import * as React from 'react';
import { Header } from './Header';
import { Tickers } from './Tickers';
import { Banner } from '../common';

interface HeroImageProps {
  imageUrl?: string;
  header?: string;
  tickers: Array<string>;
}

export class HeroImage extends React.Component<HeroImageProps> {
  render() {
    const { header, tickers } = this.props;
    return (
      <Banner className="HeroImage" backgroundUrl={this.props.imageUrl}>
        <Header header={header} />
        <Tickers tickers={tickers} />
      </Banner>
    );
  }
}
