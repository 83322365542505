import * as React from 'react';
import { IconPen, IconCross, IconCrossColor, IconCheckmark } from 'components/icons';
import { Spinner } from './Spinner';
import { CSSTransition } from 'react-transition-group';
import { ProfilePropertyNameValues } from 'store/global/ui/settings/profile';

export interface TextBoxDataProps {
  title: string;
  value: string;
  propertyName: ProfilePropertyNameValues;
  enabled: boolean;
  alerts?: TextBoxMessagingStatus;
  isValid?: boolean;
  className?: string;
  alwaysHideActions?: boolean;
  isWorking?: boolean;
}

export interface TextBoxMessagingStatus {
  warning?: TextBoxStatusMessage;
  error?: TextBoxStatusMessage;
}

export interface TextBoxStatusMessage {
  show: boolean;
  message?: string;
}

export interface TextBoxActionProps {
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activateEditMode: (propertyName: ProfilePropertyNameValues) => void;
  onTextBoxClick?: (propertyName: ProfilePropertyNameValues) => void;
  onRevertClicked: (propertyName: ProfilePropertyNameValues) => void;
  onAcceptClicked: (propertyName: ProfilePropertyNameValues, value: string) => void;
}

type TextBoxProps = TextBoxDataProps & TextBoxActionProps;

export const TextBox: React.FunctionComponent<TextBoxProps> = ({
  title,
  value,
  propertyName,
  enabled,
  alerts,
  isValid,
  className,
  alwaysHideActions,
  isWorking,
  onValueChange,
  activateEditMode,
  onTextBoxClick,
  onRevertClicked,
  onAcceptClicked,
}) => {
  const onEditClicked = (event: React.SyntheticEvent<HTMLDivElement>) => {
    activateEditMode(propertyName);
    if (onTextBoxClick) {
      onTextBoxClick(propertyName);
    }
  };

  const getTextBoxClass = () => {
    let myClass: string;
    if (enabled) {
      myClass = 'TextBox__InputArea--Unlocked';
    } else {
      myClass = 'TextBox__InputArea--Locked';
    }
    if (alerts) {
      myClass += ' TextBox__InputArea--error';
    }
    return myClass;
  };

  return (
    <div className={'TextBox ' + (className ? className : '')}>
      <label>{title}</label>
      <div className={getTextBoxClass()} onClick={event => onEditClicked(event)}>
        <input
          type="text"
          name={propertyName}
          value={value}
          autoFocus={enabled}
          onChange={event => onValueChange(event)}
          onFocus={event => activateEditMode(propertyName)}
          disabled={!enabled || isWorking}
        />
        <div className="InputArea__ActionArea">
          <div className="ActionArea__Edit">
            <CSSTransition
              in={!alwaysHideActions && !isWorking && !enabled}
              timeout={300}
              mountOnEnter={true}
              unmountOnExit={true}
              classNames="EditContainer-animation"
            >
              <div className="EditContainer">
                <button className="Edit__Pen" disabled={enabled || !isWorking}>
                  <IconPen />
                </button>
              </div>
            </CSSTransition>
            <CSSTransition
              in={!alwaysHideActions && !isWorking && enabled}
              timeout={300}
              mountOnEnter={true}
              unmountOnExit={true}
              classNames="SaveContainer-animation"
            >
              <div className="SaveContainer">
                <button
                  className="Edit__Revert"
                  disabled={!enabled || isWorking}
                  onClick={event => onRevertClicked(propertyName)}
                >
                  <IconCross color={IconCrossColor.gray} />
                </button>
                <button
                  className="Edit__Accept"
                  disabled={!enabled || !isValid || isWorking}
                  onClick={event => onAcceptClicked(propertyName, value)}
                >
                  <IconCheckmark selected={true} />
                </button>
              </div>
            </CSSTransition>
            <CSSTransition
              in={isWorking}
              timeout={300}
              mountOnEnter={true}
              unmountOnExit={true}
              classNames="SpinnerContainer-animation"
            >
              <div className="SpinnerContainer">
                <Spinner className="Edit__Spinner" />
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
      <div className={alerts ? 'TextBox__Alerts TextBox__Alerts--active' : 'TextBox__Alerts'}>
        {alerts && alerts.warning && alerts.warning.show && (
          <div className="TextBox__Alerts__Message TextBox__Alerts__Message--warning">{alerts.warning.message}</div>
        )}
        {alerts && alerts.error && alerts.error.show && (
          <div className="TextBox__Alerts__Message TextBox__Alerts__Message--error">{alerts.error.message}</div>
        )}
      </div>
    </div>
  );
};
