import * as React from 'react';

export interface IconPlusProps {
  title?: string;
}

export class IconPlus extends React.Component<IconPlusProps, {}> {
  render() {
    return (
      <div>
        <svg
          version="1.1"
          id="Lager_1"
          className="IconPlus"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 22 22"
        >
          <title>{this.props.title}</title>
          <g>
            <line className="st0" x1="11" y1="3.2" x2="11" y2="18.8" />
          </g>
          <g>
            <line className="st0" x1="18.8" y1="11" x2="3.2" y2="11" />
          </g>
        </svg>
        <style type="text/css">
          .IconPlus {'transform: scale(0.2);'}
          .st0{'fill:none;stroke:#1D1D1B;stroke-miterlimit:10;'}
        </style>
      </div>
    );
  }
}
