export enum AnlaeggningarReadActionTypes {
  ADD_SITECORE_ANLAEGGNINGAR = 'ADD_SITECORE_ANLAEGGNINGAR',
}

export type AnlaeggningarReadAction = AddSitecoreAnlaeggningar;

export interface AddSitecoreAnlaeggningar {
  type: AnlaeggningarReadActionTypes.ADD_SITECORE_ANLAEGGNINGAR;
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
}

export const addSitecoreAnlaeggningar = (
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>
): AddSitecoreAnlaeggningar => ({
  type: AnlaeggningarReadActionTypes.ADD_SITECORE_ANLAEGGNINGAR,
  anlaeggningar,
});
// interface GetAnlaeggningarAction {
//     type: 'GET_ANLAEGGNINGAR';
// }

// export const getAnlaeggningar = (): GetAnlaeggningarAction =>
//     ({ type: 'GET_ANLAEGGNINGAR' });
