import { FoereningActionTypes, FoereningarReadAction } from '.';

export interface FoereningarReadState {
  Foereningar: Array<FoereningInternal>;
}

export interface BokningRegler {
  RuleText?: string;
}
export interface MedlemsVillkor {
  MedlemsVillkor?: string;
  SmsEmailVillkor?: string;
}

export type FoereningInternal = fos.api.FoereningBase & BokningRegler & MedlemsVillkor;

const initialState: FoereningarReadState = {
  Foereningar: [],
};

export const readReducer = (
  state: FoereningarReadState = initialState,
  action: FoereningarReadAction
): FoereningarReadState => {
  switch (action.type) {
    case FoereningActionTypes.ADD_FOERENINGAR:
      return {
        ...state,
        Foereningar: action.foereningar,
      };
    case FoereningActionTypes.ADD_BOKNINGREGLER:
      return {
        ...state,
        Foereningar: state.Foereningar.map(i =>
          i.Id.toString() === action.id.toString()
            ? {
                ...i,
                RuleText: action.text,
              }
            : { ...i }
        ),
      };
    case FoereningActionTypes.ADD_MEDLEMSVILLKOR:
      return {
        ...state,
        Foereningar: state.Foereningar.map(i =>
          i.Id.toString() === action.id.toString()
            ? {
                ...i,
                MedlemsVillkor: action.text,
              }
            : { ...i }
        ),
      };
    case FoereningActionTypes.ADD_SMS_EMAIL_VILLKOR:
      return {
        ...state,
        Foereningar: state.Foereningar.map(i =>
          i.Id.toString() === action.id.toString()
            ? {
                ...i,
                SmsEmailVillkor: action.text,
              }
            : { ...i }
        ),
      };
    default:
      return { ...state };
  }
};
