export enum FoereningActionTypes {
  FETCH_BOKNINGREGLER = 'FETCH_BOKNINGREGLER',
  ADD_BOKNINGREGLER = 'ADD_BOKNINGREGLER',
  ADD_MEDLEMSVILLKOR = 'ADD_MEDLEMSVILLKOR',
  ADD_SMS_EMAIL_VILLKOR = 'ADD_SMS_EMAIL_VILLKOR',
  ADD_FOERENINGAR = 'ADD_FOERENINGAR',
}

interface FetchBokningReglerAction {
  type: FoereningActionTypes.FETCH_BOKNINGREGLER;
}

interface AddBokningReglerAction {
  type: FoereningActionTypes.ADD_BOKNINGREGLER;
  text: string;
  id: number;
}

interface AddMedlemsVillkorAction {
  type: FoereningActionTypes.ADD_MEDLEMSVILLKOR;
  text: string;
  id: number;
}

interface AddSmsEmailVillkorAction {
  type: FoereningActionTypes.ADD_SMS_EMAIL_VILLKOR;
  text: string;
  id: number;
}

interface AddFoereningarAction {
  type: FoereningActionTypes.ADD_FOERENINGAR;
  foereningar: fos.api.FoereningBase[];
}

export const fetchBokningRegler = (): FetchBokningReglerAction => ({
  type: FoereningActionTypes.FETCH_BOKNINGREGLER,
});

export const addBokningRegler = (text: string, id: number): AddBokningReglerAction => ({
  type: FoereningActionTypes.ADD_BOKNINGREGLER,
  text,
  id,
});

export const addMedlemsVillkor = (text: string, id: number): AddMedlemsVillkorAction => ({
  type: FoereningActionTypes.ADD_MEDLEMSVILLKOR,
  text,
  id,
});

export const addSmsAndEmailVillkor = (text: string, id: number): AddSmsEmailVillkorAction => ({
  type: FoereningActionTypes.ADD_SMS_EMAIL_VILLKOR,
  text,
  id,
});

export const addFoereningar = (foereningar: fos.api.FoereningBase[]): AddFoereningarAction => ({
  type: FoereningActionTypes.ADD_FOERENINGAR,
  foereningar,
});

export type FoereningarReadAction =
  | FetchBokningReglerAction
  | AddBokningReglerAction
  | AddMedlemsVillkorAction
  | AddSmsEmailVillkorAction
  | AddFoereningarAction;
