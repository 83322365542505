import { ImageActionType } from './imageActions';
import { Reducer } from 'redux';
import { Action } from 'store/rootReducer';

export interface ImageState {
  images: { [key: string]: string };
}

const initalState = {
  images: {},
};

export const imageReducer: Reducer<ImageState> = (state = initalState, action: Action): ImageState => {
  switch (action.type) {
    case ImageActionType.SetImages:
      return { ...state, images: action.images };
    default:
      return state;
  }
};
