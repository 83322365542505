import * as React from 'react';

import { IconCheckmark } from 'components/icons';

interface ShopFilterCategoryProps {
  setSelectedCategoryItem: (event: React.MouseEvent<HTMLElement>, item: fos.api.FilterChoice) => void;
  item: fos.api.FilterChoice;
  giveToggleButtonSomeFocus(): void;
}

export class ShopFilterCategoryItem extends React.Component<ShopFilterCategoryProps> {
  handleClick = (event: React.MouseEvent<HTMLElement>, item: fos.api.FilterChoice) => {
    this.props.giveToggleButtonSomeFocus();
    this.props.setSelectedCategoryItem(event, item);
  };

  render() {
    return (
      <div className="Category__Item">
        <div
          className="Item__Container"
          onClick={(event: React.MouseEvent<HTMLElement>) => this.handleClick(event, this.props.item)}
        >
          {this.props.item.Name}
          <IconCheckmark selected={this.props.item.isSelected} />
        </div>
      </div>
    );
  }
}
