import * as React from 'react';

interface SettingsPageHeaderProps {
  title: string;
  description?: string;
  className?: string;
}

export const SettingsPageHeader: React.SFC<SettingsPageHeaderProps> = props => {
  return (
    <div className={'SettingsPageHeader ' + (props.className ? props.className : '')}>
      <div className="SettingsPageHeader__Title">
        <h5>{props.title}</h5>
      </div>
      {props.description && <div className="SettingsPageHeader__Description">{props.description}</div>}
    </div>
  );
};
