import * as React from 'react';

export interface IconProfileProps {
  active: boolean;
  color?: string;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconProfile extends React.Component<IconProfileProps, {}> {
  // defaultStyle = `stroke: #8B8F92;fill:none;stroke-linecap:round;stroke-linejoin:round;`;

  defaultStyle = `stroke: ${
    this.props.color ? this.props.color : '#8B8F92'
  };fill:none;stroke-linecap:round;stroke-linejoin:round;`;
  activeStyle = 'stroke: #e31836;fill:none;stroke-linecap:round;stroke-linejoin:round;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  render() {
    // TODO: Fix translation support for text
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 27" className="IconProfile">
        <defs>
          <style>
            .IconProfile .inactive{'{' + this.defaultStyle + '}'}
            .IconProfile .active{'{' + this.activeStyle + '}'}
            .activeMenu .inactive{'{' + this.activeStyle + '}'}
          </style>
        </defs>
        <title>{this.props.title}</title>
        {/* tslint:disable */}
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <g>
                <path
                  className={this.getClassForIcon() + ' st0'}
                  d="M3.682,10.453V5.965c0-3.018,2.447-5.465,5.465-5.465h1.786c3.018,0,5.465,2.447,5.465,5.465v4.427"
                />
              </g>
              <circle className={this.getClassForIcon() + ' st0'} cx="10.106" cy="18.251" r="2.944" />
              <path
                className={this.getClassForIcon() + ' st1'}
                d="M13.512,26.839H6.568c-3.354,0-6.073-2.719-6.073-6.073v-5.029c0-3.354,2.719-6.073,6.073-6.073h6.944
                            c3.354,0,6.073,2.719,6.073,6.073v5.029C19.585,24.12,16.866,26.839,13.512,26.839z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
