import { combineReducers } from 'redux';
import { AuthReadActions, AuthReadReducer, AuthReadState } from './read';
import { LoginCmdActions, LoginCmdState, loginCmdReducer } from './loginCmd';

export type AuthActions = AuthReadActions | LoginCmdActions;

export interface AuthRootState {
  read: AuthReadState;
  loginCmd: LoginCmdState;
}

export const AuthReducer = combineReducers<AuthRootState>({
  read: AuthReadReducer,
  loginCmd: loginCmdReducer,
});
