import { Reducer } from 'redux';
import { Action } from 'store';

import { FetchStatus, FetchAction, CommandStatus, EntityStatus } from '.';

export type FetchState = ReadonlyArray<FetchStatus>;
const initialFetchState: FetchState = [];

const setFetchStatus = (fetchState: FetchState, status: FetchStatus, action: FetchAction) => {
  const currentState: FetchStatus | undefined = fetchState.find(
    s =>
      s.id === action.id &&
      s.contextType === action.contextType &&
      ((s.aggregateType !== undefined &&
        action.aggregateType !== undefined &&
        s.aggregateType === action.aggregateType) ||
        (s.commandType !== undefined && action.commandType !== undefined && s.commandType === action.commandType))
  );

  // wtf?

  // if (currentState !== undefined) {
  //   const index: number = fetchState.indexOf(currentState);

  //   return _.orderBy(
  //     [...fetchState.slice(0, index), ...fetchState.slice(index + 1, fetchState.length), status],
  //     ['id']
  //   );
  // }

  // return _.orderBy([...fetchState, status], ['id']);

  if (currentState !== undefined) {
    const index: number = fetchState.indexOf(currentState);

    return [...fetchState.slice(0, index), ...fetchState.slice(index + 1, fetchState.length), status].sort((a, b) => {
      return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
    });
  }

  return [...fetchState, status].sort((a, b) => {
    return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
  });
};

export const fetchReducer: Reducer<FetchState> = (state = initialFetchState, action: Action) => {
  switch (action.type) {
    case EntityStatus.INITIAL:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.INITIAL,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.FETCHING:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.FETCHING,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.FETCHING_IN_BACKGROUND:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.FETCHING_IN_BACKGROUND,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.SUCCESS:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.SUCCESS,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.FAILED:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.FAILED,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.SAVING:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.SAVING,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.SAVING_FAILED:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.SAVING_FAILED,
          reason: null,
          date: new Date(),
        },
        action
      );

    case EntityStatus.DELETED:
      return setFetchStatus(
        state,
        {
          id: action.id,
          contextType: action.contextType,
          aggregateType: action.aggregateType,
          commandType: undefined,
          status: EntityStatus.DELETED,
          reason: null,
          date: new Date(),
        },
        action
      );

    case CommandStatus.INITIAL:
      return setFetchStatus(
        state,
        {
          id: action.id as string,
          contextType: action.contextType,
          aggregateType: undefined,
          commandType: action.commandType,
          status: CommandStatus.INITIAL,
          reason: null,
          date: new Date(),
        },
        action
      );

    case CommandStatus.EXECUTING:
      return setFetchStatus(
        state,
        {
          id: action.id as string,
          contextType: action.contextType,
          aggregateType: undefined,
          commandType: action.commandType,
          status: CommandStatus.EXECUTING,
          reason: null,
          date: new Date(),
        },
        action
      );

    case CommandStatus.EXECUTED:
      return setFetchStatus(
        state,
        {
          id: action.id as string,
          contextType: action.contextType,
          aggregateType: undefined,
          commandType: action.commandType,
          status: CommandStatus.EXECUTED,
          reason: null,
          date: new Date(),
        },
        action
      );

    case CommandStatus.EXECUTING_FAILED:
      return setFetchStatus(
        state,
        {
          id: action.id as string,
          contextType: action.contextType,
          aggregateType: undefined,
          commandType: action.commandType,
          status: CommandStatus.EXECUTING_FAILED,
          reason: null,
          date: new Date(),
        },
        action
      );

    default:
      return state;
  }
};
