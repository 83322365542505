import { Anlaeggning } from './anlaeggningarReducer';

export enum AnlaeggningarActionType {
  GetAnlaeggningar = 'GET_ANLAGGNINGAR',
}

interface GetAnlaeggningarAction {
  type: AnlaeggningarActionType.GetAnlaeggningar;
  payload: Array<Anlaeggning>;
}

export const getAnlaeggningar = (annlaeggningar: Array<Anlaeggning>) => ({
  type: AnlaeggningarActionType.GetAnlaeggningar,
  payload: annlaeggningar,
});

export type AnlaeggningAction = GetAnlaeggningarAction;
