import { Reducer } from 'redux';
import { Action } from 'store';
import { TrainingPreferencesReadActionType } from './trainingPreferencesReadActions';

export interface TrainingPreferencesReadState {
  childrenAgeGroups: Array<string>;
  workoutlevels: Array<string>;
  savedSpecialintressen: Array<fos.api.SavedSpecialintresse>;
  anlaeggningar: Array<fos.api.SavedSpecialintresse>;
}

const initialState: TrainingPreferencesReadState = {
  savedSpecialintressen: [],
  childrenAgeGroups: [],
  workoutlevels: [],
  anlaeggningar: [],
};

export const trainingPreferencesReadReducer: Reducer<TrainingPreferencesReadState> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case TrainingPreferencesReadActionType.AddSpecialintressen:
      return { ...state, childrenAgeGroups: action.childrenAgeGroups, workoutlevels: action.workoutlevels };

    case TrainingPreferencesReadActionType.AddSavedSpecialintressen:
      return { ...state, savedSpecialintressen: action.specialintressen };

    case TrainingPreferencesReadActionType.RemoveSavedSpecialintressen:
      return {
        ...state,
        savedSpecialintressen: state.savedSpecialintressen.filter(
          i => i.SpecialintresseId !== action.specialintresseId
        ),
      };

    case TrainingPreferencesReadActionType.AddSavedAnlaeggningar:
      return { ...state, anlaeggningar: action.anlaeggningar };

    case TrainingPreferencesReadActionType.RemoveSavedAnlaeggning:
      return { ...state, anlaeggningar: state.anlaeggningar.filter(i => i.SpecialintresseId !== action.id) };

    default:
      return state;
  }
};
