import * as React from 'react';
import { IconChevron } from '../icons';
import Login from '../login/LoginContainer';
import { TranslateFunction } from 'react-localize-redux';

export interface SiteSwitcherLoginProps {
  readonly isDesktopMode: boolean;
  readonly isDesktopFullViewMode: boolean;
  isExecutingFailed: boolean;
  readonly isExecuting: boolean;
  foereningId: number;
  translate: TranslateFunction;
  languageId: number;
  username: string;
  password: string;
  isSiteSwitcherShowing: boolean;
  numberOfProductsInBasket?: number;
}

export interface SiteSwitcherLoginPassedProps {
  publicScheduleMode?: boolean;
  passId?: number | string;
  bokaKoePlats?: boolean;
  title?: string;
}

interface SiteSwitcherLoginState {
  readonly halfExpanded: boolean;
  outsideClick: boolean;
}

export interface SiteSwitcherLoginActionProps {
  updateUsername: (email: string) => void;
  updatePassword: (password: string) => void;
  updateFoereningId: (foereningId: number) => void;
  onLogin: (credentials: fos.api.Credentials) => void;
  goToLogin: () => void;
  loginFromSchedule?: (credentials: fos.api.Credentials, passId: string | number, bokaKoePlats: boolean) => void;
  clearLoginCmd: () => void;
  setSiteSwitcherToHidden: () => void;
}

type SiteSwitcherLoginAll = SiteSwitcherLoginProps & SiteSwitcherLoginActionProps & SiteSwitcherLoginPassedProps;

export class SiteSwitcherLogin extends React.Component<SiteSwitcherLoginAll, SiteSwitcherLoginState> {
  private node?: HTMLElement | null;
  constructor(props: SiteSwitcherLoginAll) {
    super(props);
    this.getExpandedClass = this.getExpandedClass.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  UNSAFE_componentWillMount() {
    this.setExpandedState(false);
    this.isClickOutside(false);
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }
  componentDidUpdate() {
    if (this.props.isSiteSwitcherShowing) {
      this.halfExpand();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }
  isClickOutside(outsideState: boolean) {
    this.setState({
      outsideClick: outsideState,
    });
  }
  handleClickOutside = (e: MouseEvent) => {
    if (this.node !== undefined && this.node !== null) {
      if (this.node.contains(e.target as Element)) {
        this.isClickOutside(false);
      } else {
        if ((e.target as Element).id === 'wrapper') {
          this.isClickOutside(true);
          this.setExpandedState(false);
        }
      }
    }
  };
  setExpandedState(halfState: boolean) {
    this.setState({
      halfExpanded: halfState,
    });
  }
  halfExpand() {
    if (!this.state.halfExpanded) {
      this.props.setSiteSwitcherToHidden();
      this.setExpandedState(true);
    } else {
      this.setExpandedState(false);
    }
  }
  getExpandedClass() {
    if (this.state.halfExpanded && this.state.outsideClick) {
      this.isClickOutside(false);
      return 'SiteSwitcher SiteSwitcher--Login';
    }
    if (this.state.halfExpanded || this.props.isSiteSwitcherShowing) {
      return 'SiteSwitcher SiteSwitcher--Login SiteSwitcher--halfExpanded';
    }
    return 'SiteSwitcher SiteSwitcher--Login';
  }
  getExpandedHeaderClass() {
    if (this.state.halfExpanded && this.state.outsideClick) {
      return 'SiteSwitcher__Header SiteSwitcher__Header--hidden';
    }
    if (this.state.halfExpanded || this.props.isSiteSwitcherShowing) {
      return 'SiteSwitcher__Header SiteSwitcher__Header--visible';
    }
    return 'SiteSwitcher__Header SiteSwitcher__Header--hidden';
  }
  getArrowDirection() {
    if (this.state.halfExpanded) {
      return 'right';
    }
    return 'left';
  }

  render() {
    return (
      <div
        className={this.getExpandedClass()}
        ref={node => {
          this.node = node;
        }}
      >
        <div className={this.getExpandedHeaderClass()}>
          <Login ProductsInBasket={this.props.numberOfProductsInBasket} />
        </div>

        <div className="SiteSwitcher__Arrow" onClick={() => this.halfExpand()}>
          <IconChevron direction={this.getArrowDirection()} />
        </div>
      </div>
    );
  }
}
