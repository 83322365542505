import * as React from 'react';
import { AccountForm } from './AccountForm';
import { MemberProfileForm } from './MemberProfileForm';

import { FormContainer } from '../formContainer';
import { SitecoreDictionary, PersonLookupStatus } from 'models';
import { MainButton, Spinner } from 'components/common';
import { TermsCheckbox } from 'components/shoppingBasket/forms/TermsCheckbox';
import { CreateUserState } from 'store/user/createUserCmd';
import { StatusBar } from 'components/common';
import { getShoppingBasketError } from 'models/SitecoreDictionary';
import { LocationState } from 'store/location';
import { Anlaeggning } from 'store/global/ui/anlaeggningar/anlaeggningarReducer';
import { TermsCheckboxSmsEmail } from '..';
import { FancyNavLink } from 'components/app';
import { EntityStatus } from 'store/global/fetch';
import { TranslateFunction } from 'react-localize-redux';
export interface NewUserFormInternalProps {
  isDesktopMode: boolean;
}

export interface NewUserFormDataProps {
  translate: TranslateFunction;
  personnr: string;
  anlaeggningsId: string;
  foernamn: string;
  efternamn: string;
  epost: string;
  mobiltelefonnr: string;
  gata: string;
  postkod: string;
  stad: string;
  loesenord: string;
  loesenordRepeat: string;
  hasNoSwedishSsn: boolean;
  isExecutingFailed: boolean;
  errorMessages?: string[];
  basket?: fos.api.Product[];
  foereningId?: number;
  anlaeggningar: Array<Anlaeggning>;
  location: LocationState;
  lookupStatus?: PersonLookupStatus;
  hasDebitProductInBasket: boolean;
  fetchStateAnlaeggningar?: EntityStatus;
  isCreatingUser: boolean;
}
export interface NewUserFormActionProps {
  createUser: (user: CreateUserState) => void;
  updateSSN: (value: string) => void;
  updateAnlaeggningsId: (value: string) => void;
  updateFirstName: (value: string) => void;
  updateLastName: (value: string) => void;
  updateEmail: (value: string) => void;
  updateTelephone: (value: string) => void;
  updateStreet: (value: string) => void;
  updateZipCode: (value: string) => void;
  updateCity: (value: string) => void;
  updatePassword: (value: string) => void;
  updateRepeatPassword: (value: string) => void;
  updateHasNoSwedishSsn: (value: boolean) => void;
  getPersonLookupAvailable: (foereningId: number) => void;
  getPersonLookup: (foereningId: number, ssn: string) => void;
  setSiteSwitcherToVisible: () => void;
  fetchAnlaeggningar: () => void;
}

interface NewUserFormState {
  termsAccepted: boolean;
  gdprTermsAccepted: boolean;
  isEmailValid: boolean;
  isStatusBarVisible: boolean;
  isPasswordValid: boolean;
  isRepeatPasswordValid: boolean;
}

const initialState: NewUserFormState = {
  termsAccepted: false,
  gdprTermsAccepted: false,
  isEmailValid: false,
  isStatusBarVisible: false,
  isPasswordValid: false,
  isRepeatPasswordValid: false,
};

export type NewUserFormProps = NewUserFormActionProps & NewUserFormDataProps & NewUserFormInternalProps;

export class NewUserForm extends React.Component<NewUserFormProps, NewUserFormState> {
  errorStatusClass = 'StatusMessage--BlackRoundedCorners';
  constructor(props: NewUserFormProps) {
    super(props);
    this.state = initialState;

    this.validateEmail = this.validateEmail.bind(this);
    this.updatePasswordValidity = this.updatePasswordValidity.bind(this);
    this.updateRepeatPasswordValidity = this.updateRepeatPasswordValidity.bind(this);
  }

  componentDidMount() {
    if (!this.props.fetchStateAnlaeggningar || this.props.fetchStateAnlaeggningar === EntityStatus.INITIAL) {
      this.props.fetchAnlaeggningar();
    }
    if (!!this.props.foereningId) {
      this.props.getPersonLookupAvailable(this.props.foereningId);
    }
    if (!this.props.fetchStateAnlaeggningar || this.props.fetchStateAnlaeggningar === EntityStatus.INITIAL) {
      this.props.fetchAnlaeggningar();
    }
    if (this.props.anlaeggningar.length === 1) {
      this.props.updateAnlaeggningsId(this.props.anlaeggningar[0].AnlaeggningId);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: NewUserFormProps) {
    if (newProps.isExecutingFailed) {
      this.setState({
        isStatusBarVisible: true,
      });
    }
    return true;
  }

  componentDidUpdate(prevProps: NewUserFormProps) {
    if (!this.props.fetchStateAnlaeggningar || this.props.fetchStateAnlaeggningar === EntityStatus.INITIAL) {
      this.props.fetchAnlaeggningar();
    }
    if (!prevProps.foereningId && !!this.props.foereningId && this.props.lookupStatus === undefined) {
      this.props.getPersonLookupAvailable(this.props.foereningId);
    }
    if (!this.props.fetchStateAnlaeggningar || this.props.fetchStateAnlaeggningar === EntityStatus.INITIAL) {
      this.props.fetchAnlaeggningar();
    }

    if (
      this.props.anlaeggningar.length > prevProps.anlaeggningar.length ||
      (!!this.props.anlaeggningar && !prevProps.anlaeggningar)
    ) {
      if (this.props.anlaeggningar.length === 1) {
        this.props.updateAnlaeggningsId(this.props.anlaeggningar[0].AnlaeggningId);
      }
    }
  }

  showStatusBar() {
    if (this.state.isStatusBarVisible) {
      return true;
    }
    return false;
  }

  validateEmail(isValid: boolean) {
    this.setState({ isEmailValid: isValid });
  }

  render() {
    if (this.props.lookupStatus === undefined) {
      return <Spinner alignment="center" />;
    }

    return (
      <FormContainer title={this.props.translate(SitecoreDictionary.Cart.YourProfile) as string}>
        <form
          className="NewUserForm" // KnownUserBuyingForm
          name="NewUserForm"
          onSubmit={event => this.submit(event)}
        >
          <MemberProfileForm
            translate={this.props.translate}
            validateEmail={this.validateEmail}
            updateSSN={this.props.updateSSN}
            updateFirstName={this.props.updateFirstName}
            updateLastName={this.props.updateLastName}
            updateEmail={this.props.updateEmail}
            updateTelephone={this.props.updateTelephone}
            updateStreet={this.props.updateStreet}
            updateZipCode={this.props.updateZipCode}
            updateCity={this.props.updateCity}
            updateHasNoSwedishSsn={this.props.updateHasNoSwedishSsn}
            emailIsValid={this.state.isEmailValid}
            ssn={this.props.personnr}
            firstName={this.props.foernamn}
            lastName={this.props.efternamn}
            email={this.props.epost}
            telephone={this.props.mobiltelefonnr}
            city={this.props.stad}
            zipCode={this.props.postkod}
            street={this.props.gata}
            hasNoSwedishSsn={this.props.hasNoSwedishSsn}
            anlaeggningsId={this.props.anlaeggningsId}
            anlaeggningar={this.props.anlaeggningar}
            lookupStatus={this.props.lookupStatus}
            foereningId={this.props.foereningId}
            getPersonLookup={this.props.getPersonLookup}
            isSsnValid={this.isSsnValid}
            isPhonenumberValid={this.isPhonenumberValid}
            hasDebitProductInBasket={this.props.hasDebitProductInBasket}
            openLoginPrompt={this.props.setSiteSwitcherToVisible}
          />
          {(this.props.lookupStatus === PersonLookupStatus.EjTillgaenglig ||
            this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad ||
            this.props.hasNoSwedishSsn) && (
            <React.Fragment>
              <AccountForm
                translate={this.props.translate}
                isDesktopMode={this.props.isDesktopMode}
                password={this.props.loesenord}
                repeatPassword={this.props.loesenordRepeat}
                updateRepeatPassword={this.props.updateRepeatPassword}
                updatePassword={this.props.updatePassword}
                isPasswordValid={this.state.isPasswordValid}
                isRepeatPasswordValid={this.state.isRepeatPasswordValid}
                updatePasswordValidity={this.updatePasswordValidity}
                updateRepeatPasswordValidity={this.updateRepeatPasswordValidity}
                location={this.props.location}
              />
              {this.props.anlaeggningar.length > 1 && (
                <React.Fragment>
                  <div className="MainInput forceNoBottomMargin">
                    <label className="InputLabel InputLabel__Left InputLabel--black forceNoBottomMargin">
                      {this.props.translate(SitecoreDictionary.Cart.FavouriteBusinessUnitTitle)}
                    </label>
                  </div>
                  <div
                    className={
                      'MainInput Dropdown_Select Input__DropDownMenu__Select ' +
                      (this.props.anlaeggningsId !== '0' ? 'MainInput__Valid' : 'MainInput__InValid')
                    }
                  >
                    <div className="InputContainer">
                      <select value={this.props.anlaeggningsId} onChange={event => this.anlaeggningChanged(event)}>
                        <option value="0" disabled={true}>
                          {this.props.translate(SitecoreDictionary.Cart.FavouriteBusinessUnitDefault)}
                        </option>
                        {this.props.anlaeggningar.map(anlaeggning => {
                          return (
                            <option value={anlaeggning.AnlaeggningId} key={anlaeggning.AnlaeggningId}>
                              {anlaeggning.AnlaeggningNamn}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="MainInput">
                    <label className="InputLabel InputLabel--black forceNoBottomMargin alignCenter">
                      {this.props.translate(SitecoreDictionary.Cart.FavouriteBusinessUnitDescription)}
                    </label>
                  </div>
                </React.Fragment>
              )}
              <TermsCheckbox
                isChecked={this.state.termsAccepted}
                onValueChange={newValue => this.updateTermsAccepted(newValue as boolean)}
                isDesktopMode={this.props.isDesktopMode}
                translate={this.props.translate}
              />
              <TermsCheckboxSmsEmail
                isChecked={this.state.gdprTermsAccepted}
                onValueChange={newValue => this.updateGdprTermsAccepted(newValue as boolean)}
                isDesktopMode={this.props.isDesktopMode}
                translate={this.props.translate}
              />
              {!this.props.isCreatingUser && (
                <MainButton
                  title={this.props.translate(SitecoreDictionary.Cart.ContinueToPayment) as string}
                  className="NewUserForm__SubmitButton"
                  color="red"
                  buttonStyle="solid"
                  type="submit"
                  disabled={this.props.isCreatingUser || this.shouldDisable()}
                  handleOnClick={() => this.onContinueToPayment()}
                />
              )}
              {this.props.isCreatingUser && <Spinner alignment="center" />}
              {this.showStatusBar() && this.props.errorMessages && this.props.errorMessages.length > 0 && (
                <div className="NewUserForm__Status">
                  <StatusBar
                    visible={this.showStatusBar()}
                    message={
                      <div>
                        {this.props.errorMessages.map((error, index) => (
                          <span key={index}>
                            &nbsp;-&nbsp;
                            {this.props.translate(getShoppingBasketError(error)) as string}
                            {(index !== (!!this.props.errorMessages ? this.props.errorMessages.length - 1 : 0) ||
                              (!!this.props.errorMessages &&
                                !!this.props.errorMessages.find(
                                  e =>
                                    e === 'EPOST_ALREADY_EXISTS' ||
                                    e === 'PERSONNR_ALREADY_EXISTS' ||
                                    e === 'UserAlreadyExistsInMittFriskis'
                                ))) && (
                              <React.Fragment>
                                <br />
                                <br />
                              </React.Fragment>
                            )}
                          </span>
                        ))}
                        {!!this.props.errorMessages.find(
                          e =>
                            e === 'EPOST_ALREADY_EXISTS' ||
                            e === 'PERSONNR_ALREADY_EXISTS' ||
                            e === 'UserAlreadyExistsInMittFriskis'
                        ) && (
                          <React.Fragment>
                            <FancyNavLink to="#" clickHandler={this.props.setSiteSwitcherToVisible}>
                              <div>{this.props.translate(SitecoreDictionary.Login.Login)}</div>
                            </FancyNavLink>
                          </React.Fragment>
                        )}
                      </div>
                    }
                    cssClass={this.errorStatusClass}
                  />
                </div>
              )}
              {this.showStatusBar() && (!this.props.errorMessages || this.props.errorMessages.length === 0) && (
                <div className="NewUserForm__Status">
                  <StatusBar
                    visible={this.showStatusBar()}
                    message={this.props.translate(getShoppingBasketError(undefined)) as string}
                    cssClass={this.errorStatusClass}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </form>
      </FormContainer>
    );
  }

  onContinueToPayment() {
    this.props.createUser({
      personnr: this.props.personnr,
      foernamn: this.props.foernamn,
      efternamn: this.props.efternamn,
      epost: this.props.epost,
      mobiltelefonnr: this.props.mobiltelefonnr,
      postkod: this.props.postkod,
      stad: this.props.stad,
      loesenord: this.props.loesenord,
      loesenordRepeat: this.props.loesenordRepeat,
      gata: this.props.gata,
      gdprTermsAccepted: this.state.gdprTermsAccepted,
      anlaeggningsId: this.props.anlaeggningsId,
      hasNoSwedishSsn: this.props.hasNoSwedishSsn,
    });
  }

  private updateTermsAccepted(newValue: boolean) {
    this.setState({
      ...this.state,
      termsAccepted: newValue,
    });
  }

  private updateGdprTermsAccepted(newValue: boolean) {
    this.setState({
      ...this.state,
      gdprTermsAccepted: newValue,
    });
  }

  private submit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
  }

  private shouldDisable(): boolean {
    const isPersonnrValid = this.isSsnValid(this.props.personnr, !this.props.hasNoSwedishSsn);
    const isFoernamnValid = this.props.foernamn.length > 0;
    const isEfternamnValid = this.props.efternamn.length > 0;
    const isMobiltelefonnrValid = this.isPhonenumberValid(this.props.mobiltelefonnr);

    const isGataValid = this.props.gata.length > 0;
    const isPostkodValid = this.props.postkod.length === 5;
    const isStadValid = this.props.stad.length > 0;
    const hasSelectedTraeningsstaelle = this.props.anlaeggningsId !== '0';
    return !(
      isPersonnrValid &&
      isFoernamnValid &&
      isEfternamnValid &&
      isMobiltelefonnrValid &&
      isGataValid &&
      isPostkodValid &&
      isStadValid &&
      this.state.isPasswordValid &&
      this.state.isRepeatPasswordValid &&
      this.state.isEmailValid &&
      this.state.termsAccepted &&
      hasSelectedTraeningsstaelle
    );
  }

  private isSsnValid(value: string, swedishSsn: boolean): boolean {
    return swedishSsn
      ? /^(19|20)?\d\d(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])-?\d{4}$/.test(value)
      : /^(19|20)?\d\d-?(0[1-9]|1[012])-?(0[1-9]|[12][0-9]|3[01])$/.test(value);
  }

  private isPhonenumberValid(value: string): boolean {
    return /^\+?\d{9,12}$/.test(value);
  }

  private updatePasswordValidity(isPasswordValid: boolean) {
    this.setState({ isPasswordValid });
  }

  private updateRepeatPasswordValidity(isRepeatPasswordValid: boolean) {
    this.setState({ isRepeatPasswordValid });
  }

  private anlaeggningChanged(event: React.FormEvent<HTMLSelectElement>) {
    this.props.updateAnlaeggningsId(event.currentTarget.value);
  }
}
