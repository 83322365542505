import * as React from 'react';

interface MainMenuListItemProps {
  className?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  text: string | React.ReactNode;
  showSeperator?: boolean;
  reverse?: boolean;
  newEventsCount?: number;
  onClick?: (val: boolean) => void;
  setAccountMenu?: (val: boolean) => void;
}

export const MainMenuListItem: React.SFC<MainMenuListItemProps> = props => {
  return (
    <div
      onClick={() => (props.onClick ? props.onClick(true) : null)}
      className={(props.className ? props.className : '') + (props.reverse ? ' row-reversed' : '') + ' row'}
    >
      {props.showIcon && <div className="icon">{props.icon}</div>}
      <div className="text">{props.text}</div>
      {!!props.newEventsCount && props.newEventsCount > 0 && <div className="newEvents">{props.newEventsCount}</div>}
      {props.showSeperator && <hr />}
    </div>
  );
};
