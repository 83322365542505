import * as React from 'react';

export interface IconCalendarProps {
  title?: string;
}

export class IconCalendar extends React.Component<IconCalendarProps, {}> {
  getDate() {
    let renderThis = new Date().getDate();
    return <div className="calenderText">{renderThis}</div>;
  }

  // TODO: Fix translation support for text
  // https://petercollingridge.appspot.com/svg-optimiser

  render() {
    return (
      <div className="IconCalenderContainer">
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className="IconCalender">
          <title>{this.props.title}</title>
          <style type="text/css">
            {`
                .IconCalender {
                  width: 55px;
                  height: 55px;
                  text-align: center;
                  transform: scale(1.2);
                }
                @media (max-width: 680px) {
                  .IconCalender {
                    transform: scale(1.2) translate(0px, 1px);
                  }
                }
                @media (min-width: 681px) and (max-width: 1200px) {
                  .IconCalender {
                    transform: scale(1.2);
                  }
                }
                .IconCalender .st0{fill:none;text-align: center;}
                .IconCalender .st1{fill:none;stroke-width: 1.1;stroke:#FFFFFF;stroke-miterlimit:10;}
                // .IconCalender .st2{fill:#FFFFFF;}
                .IconCalender .st3{font-family:'Apercu-Bold';text-align: center;}
                .IconCalender .st4{font-size:12px;}
              `}
          </style>

          <g id="Layer_2_1_">
            <g id="Layer_1-2">
              <rect y="0" className="st0" width="40" height="40" />
              <path
                className="st1"
                d="M14.3,7.7h11.6c3.4,0,6.2,2.8,6.2,6.2v11.6c0,3.4-2.8,6.2-6.2,6.2H14.3c-3.4,0-6.2-2.8-6.2-6.2V13.9C8.1,10.5,10.9,7.7,14.3,7.7z"
              />
              <line className="st1" x1="32.2" y1="14.8" x2="8.4" y2="14.8" />
            </g>
          </g>
          <g>
            <rect x="8.7" y="17.9" className="st0" width="22.8" height="10.1" />
          </g>
        </svg>
        {this.getDate()}
      </div>
    );
  }
}
