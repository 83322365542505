import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { Overlay } from 'components/common';
import { FoereningInternal } from 'store/foereningar/read';
import { TranslateFunction } from 'react-localize-redux';

export interface SmsEmailVillkorDataProps {
  activeFoerening?: FoereningInternal;
  smsEmailVillkor?: string;
  readonly translate: TranslateFunction;
  smsEmailVillkorNotFound: boolean;
  isFetching: boolean;
  isFetched: boolean;
}

export interface SmsEmailVillkorActionProps {
  getSmsEmailVillkor: (foereningNamn: string, foereningId: number) => void;
}

export interface SmsEmailVillkorPassedProps {
  isDesktopMode: boolean;
  close: () => void;
}

export type SmsEmailVillkorProps = SmsEmailVillkorActionProps & SmsEmailVillkorDataProps & SmsEmailVillkorPassedProps;

export class SmsEmailVillkor extends React.Component<SmsEmailVillkorProps> {

  componentDidMount() {
    if (!this.props.isFetching && !this.props.isFetched && this.props.activeFoerening) {
      this.props.getSmsEmailVillkor(this.props.activeFoerening.Namn, this.props.activeFoerening.Id);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: SmsEmailVillkorProps) {
    if (!newProps.isFetching && !newProps.isFetched && newProps.activeFoerening) {
      newProps.getSmsEmailVillkor(newProps.activeFoerening.Namn, newProps.activeFoerening.Id);
    }
  }

  render() {
    return (
      <Overlay
        close={this.props.close}
        title={this.props.translate(SitecoreDictionary.Cart.SubscriptionTermsTitle) as string}
      >
        <div className="MedlemsVillkor">
          {this.props.smsEmailVillkor && !this.props.smsEmailVillkorNotFound ? (
            <div
              className="MedlemsVillkor__PopupSelect__Text rich-text"
              dangerouslySetInnerHTML={{
                __html: this.props.smsEmailVillkor,
              }}
            />
          ) : this.props.smsEmailVillkorNotFound ? (
            <div className="MedlemsVillkor__PopupSelect__Text--empty">
              {this.props.translate(SitecoreDictionary.MedlemsVillkor.HasNoSmsEmailVillkor)}
            </div>
          ) : (
            <div className="Skeleton__textFields" />
          )}
          {!this.props.isDesktopMode && (
            <button className="Button Button--secondary Button--fullWidth" onClick={this.props.close}>
              {this.props.translate(SitecoreDictionary.Common.Back)}
            </button>
          )}
        </div>
      </Overlay>
    );
  }
}
