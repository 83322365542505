import { MainMenuActiveItem } from '.';

export enum MainMenuActionTypes {
  SHOW_MENU = 'MAIN_MENU/SHOW_MENU',
  CLOSE_MENU = 'MAIN_MENU/CLOSE_MENU',
}

interface MainMenuShowingAction {
  type: MainMenuActionTypes.SHOW_MENU;
  menuShowing: MainMenuActiveItem;
}

interface MainMenuCloseAction {
  type: MainMenuActionTypes.CLOSE_MENU;
}

export const openMainMenu = (menuShowing: MainMenuActiveItem): MainMenuShowingAction => ({
  type: MainMenuActionTypes.SHOW_MENU,
  menuShowing,
});

export const closeMainMenu = (): MainMenuShowingAction => ({
  type: MainMenuActionTypes.SHOW_MENU,
  menuShowing: MainMenuActiveItem.NONE,
});

export type MainMenuActions = MainMenuShowingAction | MainMenuCloseAction;
