import { api } from '../../../api';
import { updateBasket, showCouponMessage, updateOrderAction, showMembershipMessage } from 'store/basket';
import { store } from 'index';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { fetchProducts, ProductTypes } from 'store/shop';
import {
  fetching,
  FetchStatusAggregateTypes,
  fetched,
  fetchedFailed,
  executingCommand,
  FetchCommandTypes,
  executedCommand,
  executingCommandFailed,
} from 'store/global/fetch';
import { setOrderId } from 'store/global/session/sessionActions';

const ORDER_STORAGE_PREFIX = 'ORDER::';

export const getOrder = (foereningId: number, orderId: number) => {
  store.dispatch(fetching(orderId, FetchStatusAggregateTypes.GET_ORDER));
  const promise = api.get<fos.api.Order>('Order/GetOrder?orderId=' + orderId + '&foereningId=' + foereningId);
  promise.then(result => {
    if (!result.error) {
      store.dispatch(fetched(orderId, FetchStatusAggregateTypes.GET_ORDER, result.result));
      store.dispatch(updateOrderAction(result.result));
      if (!!result.result) {
        mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id, false);
      }
    } else {
      store.dispatch(fetchedFailed(orderId, FetchStatusAggregateTypes.GET_ORDER));
    }
  });
};

export const setUserOnOrder = (foereningId: number) => {
  const orderId = localStorage.getItem(ORDER_STORAGE_PREFIX + foereningId);
  if (!orderId) {
    return;
  }
  store.dispatch(executingCommand(orderId, FetchCommandTypes.SET_USER_ON_ORDER));
  const promise = api.put<fos.api.Order, { FoereningId: number; OrderId: string }>('Order/SetUserOnOrder', {
    FoereningId: foereningId,
    OrderId: orderId,
  });
  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand(orderId, FetchCommandTypes.SET_USER_ON_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id, false);
    } else {
      store.dispatch(executingCommandFailed(orderId, FetchCommandTypes.SET_USER_ON_ORDER));
    }
  });
};

export const createOrder = (foereningId: number, products: fos.api.Product[]) => {
  store.dispatch(executingCommand('' + foereningId, FetchCommandTypes.CREATE_ORDER));
  const filteredProducts = products.filter(p => p.Types.indexOf(ProductTypes.Medlemskap) === -1); // Remove medlemskap as a product
  const promise = api.post<fos.api.Order, { FoereningId: number; Products: fos.api.Product[] }>('Order/CreateOrder', {
    FoereningId: foereningId,
    Products: filteredProducts.length > 0 ? filteredProducts : products, // Skicka inte den filtrerade listan om orginallistan endast innehåller medlemskap
  });

  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand('' + foereningId, FetchCommandTypes.CREATE_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id, false);
      localStorage.setItem(ORDER_STORAGE_PREFIX + foereningId, result.result.id.toString());
      store.dispatch(setOrderId(result.result.id));
    } else {
      store.dispatch(executingCommandFailed('' + foereningId, FetchCommandTypes.CREATE_ORDER));
    }
  });
};

export const deleteOrder = (foereningId: number, orderId: number) => {
  api.post<boolean, { OrderId: number; FoereningId: number; Products: fos.api.Product[] }>('Order/DeleteOrder', {
    OrderId: orderId,
    FoereningId: foereningId,
    Products: [],
  });
  localStorage.removeItem(ORDER_STORAGE_PREFIX + foereningId);
  store.dispatch(setOrderId(undefined));
};

export const addProductToOrder = (foereningId: number, orderId: number, product: fos.api.Product) => {
  store.dispatch(executingCommand('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
  const promise = api.post<fos.api.Order, fos.api.AddProduct>('Order/AddProduct', {
    FoereningId: foereningId,
    OrderId: orderId,
    Product: product,
  });

  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id);
    } else {
      store.dispatch(executingCommandFailed('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
    }
  });
};

export const addCoupon = (foereningId: number, orderId: number, code: string) => {
  store.dispatch(executingCommand('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
  const promise = api.post<fos.api.Order, fos.api.Discount>(
    'Order/AddCoupon',
    {
      Code: code,
      OrderId: orderId,
      FoereningId: foereningId,
    },
    { muteErrorNotificationOnStatus: [403] }
  );

  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id);
    } else if (!!result.result.http && !!result.result.http.response) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
      store.dispatch(updateOrderAction(result.result.http.response));
      mapOrderToBasket(
        foereningId,
        result.result.http.response.subscriptionItems,
        result.result.http.response.customerType.id
      );
      store.dispatch(showCouponMessage(true));
    } else {
      store.dispatch(executingCommandFailed('' + orderId, FetchCommandTypes.ADD_TO_ORDER));
    }
  });
  return promise;
};

export const removeSubscription = (foereningId: number, orderId: number, productId: number) => {
  store.dispatch(executingCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
  const order = store.getState().shoppingBasket.order;
  const subscription = order
    ? order.subscriptionItems.find(s => s.product.id.toString() === productId.toString())
    : undefined;
  const promise = api.post<fos.api.Order, fos.api.RemoveProduct>('Order/RemoveSubscription', {
    FoereningId: foereningId,
    OrderId: orderId,
    ProductId: productId,
    SubscriptionId: subscription ? subscription.subscriptionId : undefined,
  });

  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id);
    } else if (!!result.result.http && !!result.result.http.response) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
      store.dispatch(updateOrderAction(result.result.http.response));
      mapOrderToBasket(
        foereningId,
        result.result.http.response.subscriptionItems,
        result.result.http.response.customerType.id
      );
    } else {
      store.dispatch(executingCommandFailed('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
    }
  });
};

export const removeCoupon = (foereningId: number, orderId: number, couponCode: string) => {
  store.dispatch(executingCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
  const promise = api.post<fos.api.Order, fos.api.Discount>('Order/RemoveCoupon', {
    FoereningId: foereningId,
    OrderId: orderId,
    Code: couponCode,
  });

  promise.then(result => {
    if (!result.error) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
      store.dispatch(updateOrderAction(result.result));
      mapOrderToBasket(foereningId, result.result.subscriptionItems, result.result.customerType.id);
    } else if (!!result.result.http && !!result.result.http.response) {
      store.dispatch(executedCommand('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
      store.dispatch(updateOrderAction(result.result.http.response));
      mapOrderToBasket(
        foereningId,
        result.result.http.response.subscriptionItems,
        result.result.http.response.customerType.id
      );
    } else {
      store.dispatch(executingCommandFailed('' + orderId, FetchCommandTypes.REMOVE_FROM_ORDER));
    }
  });
};

const doUpdateBasket = (
  subscriptionItems: fos.api.SubscriptionItem[],
  foereningId: number,
  products: fos.api.Product[],
  customerType: number,
  checkMembershipMessage: boolean
) => {
  const parsedProducts = subscriptionItemsToProducts(subscriptionItems, products, customerType);
  if (checkMembershipMessage) {
    const currentMedlemskap = store
      .getState()
      .shoppingBasket.products.filter(p => p.Types.some(t => t === ProductTypes.Medlemskap)).length;
    const newMedlemskap = parsedProducts.filter(p => p.Types.some(t => t === ProductTypes.Medlemskap)).length;
    if (currentMedlemskap < newMedlemskap) {
      store.dispatch(showMembershipMessage(true));
    }
  }
  store.dispatch(updateBasket(foereningId, parsedProducts));
};

const subscriptionItemsToProducts = (
  subscriptionItems: fos.api.SubscriptionItem[],
  products: fos.api.Product[],
  customerType: number
) => {
  if (!subscriptionItems) {
    return products;
  }
  return subscriptionItems.map(s => {
    const filteredProducts = products.filter(p => {
      return '' + p.Id === '' + s.product.id; // p.Id is string for some reason????
    });
    if (filteredProducts.length === 1) {
      const product = filteredProducts[0];
      product.Pris = s.price.amount / 100;
      return product;
    } else if (filteredProducts.length > 1) {
      const product = filteredProducts.find(p => '' + p.CustomerType.Id === '' + customerType);
      if (!!product) {
        product.Pris = s.price.amount / 100;
        return product;
      }
    }
    return products[0];
  });
};

const getProducts = (foereningId: number): Promise<ErrorResult | SuccessResult<fos.api.Product[]>> => {
  const url = 'produkter/getproduktlist?foereningId=';
  return api.get<Array<fos.api.Product>>(url + foereningId, {
    muteErrorNotificationOnStatus: [400],
  });
};

const mapOrderToBasket = (
  foereningId: number,
  subscriptionItems: fos.api.SubscriptionItem[],
  customerType: number,
  checkMembershipMessage: boolean = true
) => {
  const cachedProducts = store.getState().product.unfilteredProducts;
  if (cachedProducts.length === 0) {
    getProducts(foereningId).then(result2 => {
      if (!result2.error) {
        doUpdateBasket(subscriptionItems, foereningId, result2.result, customerType, checkMembershipMessage);
        store.dispatch(fetchProducts(result2.result));
      }
    });
  } else {
    doUpdateBasket(subscriptionItems, foereningId, cachedProducts, customerType, checkMembershipMessage);
  }
};
