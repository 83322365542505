// Wrapper for the module react-localize-redux
// github: https://github.com/ryandrewjohnson/react-localize-redux/
// Documentation: https://ryandrewjohnson.github.io/react-localize-redux/

import {
  localeReducer,
  LocaleState,
  getTranslate as translate,
  getActiveLanguage,
} from 'react-localize-redux';

import { store } from 'index';

export { getTranslate as Translate, localeReducer };

// wrap getTranslate to avoid error when stepping back in Redux DevTools
export const getTranslate = (localeState: LocaleState) => {
  if (typeof getActiveLanguage(localeState) !== 'undefined') {
    return translate(localeState);
  }

  let defaultLanguage = store.getState().global.ui.language.code;

  return translate({
    languages: [{ name: defaultLanguage, code: defaultLanguage, active: true }],
    translations: {},
    options: {},
  });
};

// const exampleTranslations = {
//   'MittFriskis': {
//     'Schedule': {
//       'Messages': {
//         'CancellationConfirmation': ['ccc-sv', 'ccc-no', 'ccc-de'],
//         'MaxPassesBooked': ['mpb', 'mpb2', 'mpb3']
//       },
//       'ShowFilter': ['show-sv', 'show-no', 'show-de'], // ex: { translate('Schedule.ShowFilter') }
//       'HideFilter': ['hide-sv', 'hide-no', 'hide-de'],
//       'Places': ['places-sv', 'places-no', 'places-de'],
//       'Schedule': ['Schema', 'schedule-no', 'schedule-de']
//     },
//     'WeekDays': {
//       'Monday': ['monday-sv', 'monday-no', 'monday-de'],
//       'Tuesday': ['tuesday-sv', 'tuesday-no', 'tuesday-de']
//     }
//   },
//   'HejNamn': ['Hej ${ namn }', '', ''], // ex: { translate('HejNamn', { namn: 'Testy McTest' }) }
//   'Test': [ // ex: { translate('Test') }
//     'Testtext på svenska (använder translate)',
//     'Testtext på norska',
//     'Testtext på tyska'
//   ]
// };
