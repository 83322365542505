import * as React from 'react';
import LoginContainer from 'components/login/LoginContainer';
import { BokningStatusPopupContainer } from 'components/publicSchedulePopup/BokningStatusPopupContainer';

import { SitecoreDictionary, BokningsStatusEnum } from 'models';
import { IconBigClose } from 'components/icons';
import { TranslateFunction } from 'react-localize-redux';

export interface PublicSchedulePopupDataProps {
  isAuthenticated?: boolean;
  translate: TranslateFunction;
}

export interface PublicSchedulePopupPassedProps {
  pass: fos.api.Pass;
  passDay: string;
  closePopup: () => void;
  // visible: boolean;
  showAtStart: boolean;
}

export interface PublicSchedulePopupActionProps {}
export interface PublicSchedulePopupStateProps {
  hiddenFromStart: boolean;
}

export type PublicSchedulePopupProps = PublicSchedulePopupDataProps &
  PublicSchedulePopupActionProps &
  PublicSchedulePopupPassedProps;
export class PublicSchedulePopup extends React.Component<PublicSchedulePopupProps, PublicSchedulePopupStateProps> {
  constructor(props: PublicSchedulePopupProps) {
    super(props);

    this.state = {
      hiddenFromStart: true,
    };
  }

  getClasses() {
    let myClasses = 'PublicSchedulePopup';
    return myClasses;
  }

  render() {
    return (
      <div
        className={this.getClasses()}
        // style={getStyle(this.props)}
      >
        {this.props.isAuthenticated ? (
          <BokningStatusPopupContainer passId={this.props.pass.PassId} passDay={this.props.passDay} />
        ) : (
          <LoginContainer
            publicScheduleMode={true}
            passId={this.props.pass.PassId}
            bokaKoePlats={
              this.props.pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
              this.props.pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN
            }
            title={this.props.translate(SitecoreDictionary.Schedule.PublicWeb.Identify as string)}
          />
        )}
        <div className="PublicSchedulePopup__CloseIcon">
          <button onClick={() => this.props.closePopup()}>
            <IconBigClose className="IconBigClosePublicSchedule" />
          </button>
        </div>
      </div>
    );
  }
}
