import * as React from 'react';

interface PassDetailsHeroBannerSkeletonProps {
  isDesktop: boolean;
}

export const PassDetailsHeroBannerSkeleton: React.SFC<PassDetailsHeroBannerSkeletonProps> = props => (
  <div className="HeroImage Banner">
    {props.isDesktop && (
      <div>
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h1 />
        <p />
      </div>
    )}
  </div>
);
