interface GetTraeningsstatistikAction {
  type: StatisticsReadActionType.GetTraeningsstatistik;
  statistics: fos.api.TrainingStatistics;
}

export type StatisticsReadActions = GetTraeningsstatistikAction;

export enum StatisticsReadActionType {
  GetTraeningsstatistik = 'GET_TRAENINGSSTATISTIK',
}

export const getTraeningsstatistik = (statistics: fos.api.TrainingStatistics): StatisticsReadActions => ({
  type: StatisticsReadActionType.GetTraeningsstatistik,
  statistics,
});
