import * as React from 'react';
import ShopFilterCategoryContainer from './filter-category/ShopFilterCategoryContainer';
import { ShopFilterActionFunctions } from './filter-category';
import { ScheduleFilterCategoryName } from 'store/schedule/scheduleModels';
import { UIScheduleFilterState } from 'store/global/ui/schedule-filter/uiScheduleFilterReducer';
import { ScheduleFilter } from 'store/schedule/scheduleModels';
import { ShopFilterCategoryListItemDataPropertyKeys } from './filter-category/filter-category-list/filter-category-list-item/ShopFilterCategoryListItem';
import { TranslateFunction } from 'react-localize-redux';
import { SitecoreDictionary } from 'models';
import { ShopFilterCategoryName, FilterCategory, FilterChoice } from 'store/shop/shopModels';
import { UIShopFilterState } from 'store/global/ui/shop-filter/uiShopFilterReducer';
import { filterUtils } from 'utils/filter';
import { Spinner } from '../Spinner';
import { Tab } from 'components/shop/Tabs/Tabs';
import { BackButton } from '../../header/HeaderContent/BackButton';

export interface ShopFilterFunctions {
  [name: string]: ShopFilterActionFunctions;
}

export enum ShopFilterType {
  Schedule = 'Schedule',
  Shop = 'Shop',
}

export interface ShopFilterDataProps {
  readonly isPublicWebMode: boolean;
  readonly expanded: boolean;
  readonly filterUIState: UIScheduleFilterState | UIShopFilterState;
  readonly filterHidden: boolean;
  readonly userFilters: ScheduleFilter;
  readonly filterableProperties: ScheduleFilter;
  readonly filterFunctions: ShopFilterFunctions;
  readonly totalNumberOfItems: number;
  readonly maxNumberOfItemsExcceeded: boolean;
  readonly translate: TranslateFunction;
  readonly listItemGroupingMappings: {
    Traeningspass: {
      [index: string]: string | string[];
    };
  };
  readonly isFullDesktopView: boolean;
  readonly isDesktopMode: boolean;
  readonly scheduleIsFetching: boolean;
  readonly filterType: ShopFilterType;
  readonly hideFilterSaving?: boolean;
  readonly hideTrashCan: boolean;
  readonly isAuthenticated: boolean;
  readonly shopFilter: [FilterCategory];
  readonly selectedTab: Tab;
  readonly giveToggleButtonSomeFocus: () => void;

  readonly productsIsShowing: boolean;

  onHideProductClick(): void;
  onFilterDelete(filterName: string): void;
  onFilterSave(filterName: string): void;
  onClearFilters(): void;
}

export interface ShopFilterDataState {
  readonly selectedCategory: string;
}

export interface ShopFilterActionProps {
  selectAllCategoryListItems: (value: string, category: string) => void;
  unSelectCategoryHeader: (value: string, category: string) => void;
  expandFilter: (expanded: boolean, filterType: ShopFilterType) => void;
}

export type ShopFilterProps = ShopFilterDataProps & ShopFilterActionProps;
export type ShopFilterState = ShopFilterDataState;

const defaultPropertyKeys: ShopFilterCategoryListItemDataPropertyKeys = {
  // INFO: Only needed before we have proper mapping of properties
  listItemIdProperty: 'listItemId',
  listItemKeyProperty: 'listItemText',
  listItemTextProperty: 'listItemValueForDisplay',
};

function mapDataForListItem(filterProperties: ScheduleFilter, internalCategoryName: string): FilterChoice[] {
  if (!filterProperties || !filterProperties[internalCategoryName] || !filterProperties[internalCategoryName].length) {
    return [];
  }
  const values: string[] = filterProperties[internalCategoryName];
  let listItemValueForDisplay = '';
  switch (internalCategoryName) {
    default: {
      return values.map((value: string, index: number) => {
        listItemValueForDisplay = value;
        return getListItemData(value, index, internalCategoryName, listItemValueForDisplay);
      });
    }
  }
}

function getListItemData(value: string, index: number, internalCategoryName: string, listItemValueForDisplay: string) {
  return {
    SitecoreId: value,
    Name: value,
    Description: listItemValueForDisplay,
    Type: internalCategoryName,
    isSelected: false,
  };
}

function getActionFunctionsForCategory(
  filterFunctions: ShopFilterFunctions,
  categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName | string
): ShopFilterActionFunctions | undefined {
  const filterFunction = filterFunctions[categoryName];
  return filterFunction;
}

function renderCategoryContainer(
  showSearchbar: boolean,
  presentationCategoryName: string,
  internalCategoryName: ScheduleFilterCategoryName | ShopFilterCategoryName,
  filterCategoryListItemDataPropertyKeys: ShopFilterCategoryListItemDataPropertyKeys,
  props: ShopFilterProps
) {
  // TODO : NOTE: A lot of this code should be moved to Schedule.tsx because this file should be as agnostic to the data it receives as possible.
  let categoryData: FilterChoice[];
  // const categoryName: string = getCategoryName(props, internalCategoryName);
  const categoryName = internalCategoryName;
  switch (internalCategoryName) {
    // INFO: If we need special handling of a category's data, this is where we want to do it.
    default: {
      categoryData = mapDataForListItem(props.filterableProperties, internalCategoryName);
    }
  }
  const listItemGroupingMapping = props.listItemGroupingMappings
    ? props.listItemGroupingMappings[internalCategoryName]
    : undefined;

  if (props.filterType === ShopFilterType.Shop) {
    // If we are in the shop filter and we have only 1 category value then we want to hide the category filter for that category
    const weHaveFewerThan2Categories = categoryData.length < 2;
    if (weHaveFewerThan2Categories) {
      return null;
    }
  }

  return (
    <ShopFilterCategoryContainer
      categoryName={categoryName}
      data={categoryData}
      internalCategoryName={internalCategoryName}
      localState={props.filterUIState.categoryState[internalCategoryName]}
      filterUIState={props.filterUIState}
      listItemKeyProperty={filterCategoryListItemDataPropertyKeys.listItemKeyProperty}
      listItemIdProperty={filterCategoryListItemDataPropertyKeys.listItemIdProperty}
      listItemTextProperty={filterCategoryListItemDataPropertyKeys.listItemTextProperty}
      filterCategoryActionFunctions={getActionFunctionsForCategory(props.filterFunctions, internalCategoryName)}
      selectedValues={props.userFilters[internalCategoryName]}
      onFavoriteFilterDelete={props.onFilterDelete}
      listItemGroupingMapping={listItemGroupingMapping}
      translate={props.translate}
      isFullDesktopView={props.isFullDesktopView}
      isDesktopMode={props.isDesktopMode}
      filterType={props.filterType}
      selectAllCategoryListItems={props.selectAllCategoryListItems}
      unSelectCategoryHeader={props.unSelectCategoryHeader}
      showSearchbar={showSearchbar}
    />
  );
}

function renderScheduleCategories(
  filterCategoryListItemDataPropertyKeys: ShopFilterCategoryListItemDataPropertyKeys,
  props: ShopFilterProps,
  showFilter: boolean
) {
  // *********** OBS! FavoritFilter temporarily hidden until further fixes
  // const stringFavoritFilter = (props.translate(SitecoreDictionary.Schedule.Filter.Categories.Favoritfilter) as string);
  const stringTraeningsstaelle = props.translate(
    SitecoreDictionary.Schedule.Filter.Categories.Traeningsstaelle
  ) as string;
  const stringTraeningspass = props.translate(SitecoreDictionary.Schedule.Filter.Categories.Traeningspass) as string;
  const stringLedareOrTraenare = props.translate(
    SitecoreDictionary.Schedule.Filter.Categories.LedareOrTraenare
  ) as string;
  const stringTidpunkt = props.translate(SitecoreDictionary.Schedule.Filter.Categories.Tidpunkt) as string;
  const stringVeckodag = props.translate(SitecoreDictionary.Schedule.Filter.Categories.Veckodag) as string;
  return (
    <React.Fragment>
      {/*<div className={'newFilter__Categories ' + (showFilter ? 'newFilter__Categories--show' : '')}>*/}
      {/*
          props.isAuthenticated &&
          renderCategoryContainer(false, stringFavoritFilter, ScheduleFilterCategoryName.Favoritfilter, filterCategoryListItemDataPropertyKeys, props)
          */}
      {renderCategoryContainer(
        true,
        stringTraeningsstaelle,
        ScheduleFilterCategoryName.Traeningsstaelle,
        filterCategoryListItemDataPropertyKeys,
        props
      )}
      {renderCategoryContainer(
        true,
        stringTraeningspass,
        ScheduleFilterCategoryName.Traeningspass,
        filterCategoryListItemDataPropertyKeys,
        props
      )}
      {renderCategoryContainer(
        true,
        stringLedareOrTraenare,
        ScheduleFilterCategoryName.LedareOrTraenare,
        filterCategoryListItemDataPropertyKeys,
        props
      )}
      {renderCategoryContainer(
        false,
        stringTidpunkt,
        ScheduleFilterCategoryName.Tidpunkt,
        filterCategoryListItemDataPropertyKeys,
        props
      )}
      {renderCategoryContainer(
        false,
        stringVeckodag,
        ScheduleFilterCategoryName.Veckodag,
        filterCategoryListItemDataPropertyKeys,
        props
      )}
      {/* </div> */}
    </React.Fragment>
  );
}

function getClasses(props: ShopFilterProps) {
  const baseClass = 'newFilter';
  let filterClasses = baseClass;
  if (props.expanded) {
    filterClasses = filterClasses + `  ${baseClass}--expanded`;
  }
  if (props.filterHidden) {
    filterClasses = filterClasses + `  ${baseClass}--hidden`;
  }
  if (filterUtils.hasCategoryExpanded(props.filterUIState.categoryState)) {
    filterClasses = filterClasses + `  ${baseClass}--hasExpandedCategory`;
  }
  return filterClasses;
}

export class ShopFilter extends React.Component<ShopFilterProps, ShopFilterState> {
  constructor(props: ShopFilterProps) {
    super(props);

    this.state = {
      selectedCategory: '1',
    };
  }

  getCurrentUserFilterAmount = () => {
    let currentUserFilter = 0;

    if (this.props.userFilters.Traeningsstaelle) {
      currentUserFilter += this.props.userFilters.Traeningsstaelle.length;
    }

    if (this.props.userFilters.Traeningspass) {
      currentUserFilter += this.props.userFilters.Traeningspass.length;
    }

    if (this.props.userFilters.LedareOrTraenare) {
      currentUserFilter += this.props.userFilters.LedareOrTraenare.length;
    }

    if (this.props.userFilters.Tidpunkt) {
      currentUserFilter += this.props.userFilters.Tidpunkt.length;
    }

    if (this.props.userFilters.Veckodag) {
      currentUserFilter += this.props.userFilters.Veckodag.length;
    }

    if (currentUserFilter === 0) {
      return null;
    } else {
      return ' (' + currentUserFilter + ')';
    }
  };

  renderFilterButton = () => {
    return (
      <div className="newFilter__Selection">
        <div className="Inner__Container">
          <BackButton icon="chevron" goBack={() => this.props.onHideProductClick()} />
        </div>
      </div>
    );
  };

  renderShopCategories(
    filterCategoryListItemDataPropertyKeys: ShopFilterCategoryListItemDataPropertyKeys,
    props: ShopFilterProps
  ) {
    const translate = this.props.translate;
    switch (props.selectedTab) {
      case Tab.Local:
        return this.props.shopFilter.map((item, index) => {
          if (item.Children.length > 1) {
            return this.renderShopCategoryContainer(
              false,
              item.SitecoreId,
              item.Name,
              item.Description,
              props,
              item.Children
            );
          }
          return <React.Fragment key={index} />;
        });
      case Tab.TwoBusinessUnits:
        return (
          <div className="Cards">
            <div className="Cards__inner">
              <h5>{translate(SitecoreDictionary.Shop.Filter.TwoBusinessUnitsCardTitle)}</h5>
              <p>{translate(SitecoreDictionary.Shop.Filter.TwoBusinessUnitsCardDescription)}</p>
            </div>
          </div>
        );
      case Tab.National:
        return (
          <div className="Cards">
            <div className="Cards__inner">
              <h5>{translate(SitecoreDictionary.Shop.Filter.NationalCardTitle)}</h5>
              <p>{translate(SitecoreDictionary.Shop.Filter.NationalCardDescription)}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderShopCategoryContainer(
    showSearchbar: boolean,
    SitecoreId: string,
    internalCategoryName: string,
    categoryDescription: string,
    props: ShopFilterProps,
    subCategories: FilterChoice[]
  ) {
    let categoryData = subCategories;
    const categoryName = internalCategoryName;

    switch (internalCategoryName) {
      default: {
        categoryData = mapDataForListItem(props.filterableProperties, internalCategoryName);
      }
    }

    const listItemGroupingMapping = props.listItemGroupingMappings
      ? props.listItemGroupingMappings[internalCategoryName]
      : undefined;

    return (
      <ShopFilterCategoryContainer
        id={SitecoreId}
        key={SitecoreId}
        categoryName={categoryName}
        data={categoryData}
        children={subCategories}
        internalCategoryName={internalCategoryName}
        localState={props.filterUIState.categoryState[internalCategoryName]}
        filterUIState={props.filterUIState}
        filterCategoryActionFunctions={getActionFunctionsForCategory(props.filterFunctions, internalCategoryName)}
        selectedValues={props.userFilters[internalCategoryName]}
        onFavoriteFilterDelete={props.onFilterDelete}
        listItemGroupingMapping={listItemGroupingMapping}
        translate={props.translate}
        isFullDesktopView={props.isFullDesktopView}
        isDesktopMode={props.isDesktopMode}
        filterType={props.filterType}
        selectAllCategoryListItems={props.selectAllCategoryListItems}
        unSelectCategoryHeader={props.unSelectCategoryHeader}
        showSearchbar={showSearchbar}
        setClickedCategory={this.setClickedCategory}
        selectedCategory={this.state.selectedCategory}
        giveToggleButtonSomeFocus={this.props.giveToggleButtonSomeFocus}
        categoryDescription={categoryDescription}
      />
    );
  }

  setClickedCategory = (id: string) => {
    this.setState({
      ...this.state,
      selectedCategory: this.state.selectedCategory === id ? '' : id,
    });
  };

  render() {
    const filterIsForSchedule = this.props.filterType === ShopFilterType.Schedule;
    const filterIsForShop = this.props.filterType === ShopFilterType.Shop;

    const showFilter = filterIsForSchedule && this.props.isFullDesktopView;
    return (
      <div className={getClasses(this.props)}>
        {this.props.productsIsShowing && this.renderFilterButton()}

        {!filterIsForShop && renderScheduleCategories(defaultPropertyKeys, this.props, showFilter)}

        {filterIsForShop && this.props.shopFilter && this.renderShopCategories(defaultPropertyKeys, this.props)}

        {this.props.shopFilter.length <= 0 && <Spinner alignment="center" />}
      </div>
    );
  }
}
