import * as React from 'react';
import { EntityStatus } from 'store/global/fetch';
import { Spinner, MainButton } from 'components/common';
import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';
import { SitecoreDictionary } from 'models';
import { isApp } from 'models/app/MobileOSType';
import { lockBackgroundScroll } from 'websd/utils';

export interface ReceiptDataProps {
  receiptId?: number;
  orderId?: number;
  activeFoerening?: number;
  receipt?: fos.api.Receipt;
  fetchReceiptStatus: EntityStatus;
  fetchProductsStatus: EntityStatus;
  translate: TranslateFunction;
}

export interface ReceiptActionProps {
  getReceipt: (receiptId: number, foereningId: number) => void;
  getReceiptForOrder: (orderId: number, foereningId: number) => void;
}

type ReceiptProps = ReceiptDataProps & ReceiptActionProps;

export const Receipt: React.FunctionComponent<ReceiptProps> = ({
  receiptId,
  orderId,
  activeFoerening,
  receipt,
  fetchReceiptStatus,
  fetchProductsStatus,
  translate,
  getReceipt,
  getReceiptForOrder,
}) => {
  React.useEffect(() => {
    if (
      !!activeFoerening &&
      fetchReceiptStatus !== EntityStatus.FETCHING &&
      fetchReceiptStatus !== EntityStatus.SUCCESS
    ) {
      if (!!receiptId) {
        getReceipt(receiptId, activeFoerening);
      } else if (!!orderId) {
        getReceiptForOrder(orderId, activeFoerening);
      }
    }
  }, [activeFoerening, fetchReceiptStatus, receiptId, orderId, getReceipt, getReceiptForOrder]);

  React.useEffect(() => {
    const unlock = lockBackgroundScroll();
    return () => {
      if (unlock) {
        unlock();
      }
    };
  }, []);

  const getPayedToday = () => {
    if (!receipt || receipt.Betalningar.length === 0) {
      return [];
    }
    const payed: { sum: number; type: string }[] = [];
    receipt.Betalningar.forEach(b => {
      let current = payed.find(p => p.type === b.Valuta);
      if (!current) {
        current = { sum: b.Pris, type: b.Valuta };
        payed.push(current);
      } else {
        current.sum += b.Pris;
      }
    });
    return payed;
  };

  const getTranslatedConsentName = (consentName: string) => {
    switch (consentName) {
      case 'DIRECT_DEBIT_SE':
        return translate(SitecoreDictionary.Receipt.SpecificReceipt.DirectDebitSe);
      case 'DIRECT_DEBIT_NO':
        return translate(SitecoreDictionary.Receipt.SpecificReceipt.DirectDebitNo);
      case 'RECURRING_CARD_PAYMENT':
        return translate(SitecoreDictionary.Receipt.SpecificReceipt.RecurringCardPayment);
      default:
        return '';
    }
  };
  if (fetchReceiptStatus === EntityStatus.FETCHING || fetchProductsStatus === EntityStatus.FETCHING) {
    return (
      <div className="Receipt">
        <Spinner alignment="center" />
      </div>
    );
  }
  if (
    ((fetchReceiptStatus === EntityStatus.FAILED || fetchReceiptStatus === EntityStatus.SUCCESS) && !receipt) ||
    (!receiptId && !orderId)
  ) {
    return (
      <div className="Receipt">
        <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.ReceiptDetailsNotFoundTitle)}</h4>
      </div>
    );
  }
  return (
    <div className="Receipt">
      {!!receipt && (
        <React.Fragment>
          <div className="Receipt__Section">
            <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.Title)}</h4>
            <div className="Section__InfoContainer">
              <div className="InfoContainer__Info">
                <span className="Info__Value">{moment(receipt.Datum).format('LLL')}</span>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Date)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Betalsaett}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.PaymentMethod)}
                </span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Kvittonummer}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.ReceiptNumber)}
                </span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Bestaellningsnummer}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.OrderNumber)}
                </span>
              </div>
            </div>
          </div>
          <div className="Receipt__Section">
            <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.SellerTitle)}</h4>
            <div className="Section__InfoContainer">
              <div className="InfoContainer__Info">
                <div className="Info__Value">
                  <p>{receipt.Saeljare.FoereningNamn}</p>
                  <p>{receipt.Saeljare.Adress.Gata}</p>
                  <p>
                    {receipt.Saeljare.Adress.Postnummer} {receipt.Saeljare.Adress.Stad}
                  </p>
                  <p>{receipt.Saeljare.Adress.Land}</p>
                  {!!receipt.Saeljare.Adress.CareOf && <p>{'c/o ' + receipt.Saeljare.Adress.CareOf}</p>}
                </div>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.SellerInfo)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Saeljare.Email}</span>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Email)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Saeljare.Url}</span>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Homepage)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Saeljare.Telefonnummer}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.PhoneNumber)}
                </span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Saeljare.Organisationsnummer}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.OrganisationId)}
                </span>
              </div>
            </div>
          </div>
          <div className="Receipt__Section">
            <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.BuyerTitle)}</h4>
            <div className="Section__InfoContainer">
              <div className="InfoContainer__Info">
                <span className="Info__Value">
                  {receipt.Koepare.Foernamn} {receipt.Koepare.Efternamn}
                </span>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.BuyerTitle)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Koepare.Email}</span>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Email)}</span>
              </div>
              <div className="InfoContainer__Info">
                <div className="Info__Value">
                  <p>{receipt.Koepare.Adress.Gata}</p>
                  <p>
                    {receipt.Koepare.Adress.Postnummer} {receipt.Koepare.Adress.Stad}
                  </p>
                  <p>{receipt.Koepare.Adress.Land}</p>
                  {!!receipt.Koepare.Adress.CareOf && <p>{'c/o ' + receipt.Koepare.Adress.CareOf}</p>}
                </div>
                <span className="Info__Footer">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Address)}</span>
              </div>
              <div className="InfoContainer__Info">
                <span className="Info__Value">{receipt.Koepare.Telefonnummer}</span>
                <span className="Info__Footer">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.PhoneNumber)}
                </span>
              </div>
            </div>
          </div>
          {receipt.Autogiromedgivanden.length > 0 && (
            <div className="Receipt__Section">
              <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.DirectDebitConsentTitle)}</h4>
              <div className="Section__InfoContainer">
                {receipt.Autogiromedgivanden.map(m => {
                  return (
                    <div key={m.Id} className="InfoContainer__Info">
                      <span className="Info__Value">{m.Id}</span>
                      <span className="Info__Footer">{getTranslatedConsentName(m.Namn)}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="Receipt__Section">
            <h4>{translate(SitecoreDictionary.Receipt.SpecificReceipt.ProductsTitle)}</h4>
            <table className="Receipt__ProductTable">
              <tbody>
                <tr>
                  <th className="ProductTable__Wide">
                    {translate(SitecoreDictionary.Receipt.SpecificReceipt.Product)}
                  </th>
                  <th>{translate(SitecoreDictionary.Receipt.SpecificReceipt.Amount)}</th>
                  <th>{translate(SitecoreDictionary.Receipt.SpecificReceipt.Total)}</th>
                </tr>
                {receipt.Produkter.map(p => {
                  return (
                    <React.Fragment key={p.ProduktId}>
                      <tr key={p.ProduktId}>
                        <td>{p.ProduktNamn}</td>
                        <td>{p.Antal}</td>
                        <td>
                          {p.Pris + (!!p.Kupong ? p.Kupong.Pris : 0)} {p.Valuta}
                        </td>
                      </tr>
                      {!!p.Kupong && (
                        <tr key={p.ProduktId + p.Kupong.Namn}>
                          <td>
                            {(translate(SitecoreDictionary.Receipt.SpecificReceipt.CouponCode) as string).replace(
                              '$',
                              p.Kupong.Namn
                            )}
                          </td>
                          <td>1</td>
                          <td>
                            -{p.Kupong.Pris} {p.Valuta}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            <div className="Receipt__SumContainer">
              <div className="SumContainer__Total">
                <div className="Total__ItemHeader">{translate(SitecoreDictionary.Receipt.SpecificReceipt.Sum)}</div>
                <div className="Total__ItemValue">
                  {receipt.Pris} {receipt.Valuta}
                </div>
              </div>
              {receipt.Momssatser.map(m => {
                return (
                  <div key={m.Procent + m.Pris} className="SumContainer__Detailed">
                    <div className="Detailed__ItemHeader">
                      {(translate(SitecoreDictionary.Receipt.SpecificReceipt.IncludingVAT) as string).replace(
                        '$',
                        m.Procent
                      )}
                    </div>
                    <div className="Detailed__ItemValue">
                      {m.Pris} {m.Valuta}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="Receipt__SumContainer">
              <div className="SumContainer__Total">
                <div className="Total__ItemHeader">
                  {translate(SitecoreDictionary.Receipt.SpecificReceipt.PayedToday)}
                </div>
                <div className="Total__ItemValue">
                  {getPayedToday().map(b => {
                    return (
                      <span key={b.type + b.sum}>
                        {b.sum} {b.type}
                      </span>
                    );
                  })}
                </div>
              </div>
              {receipt.Betalningar.map((b, index) => {
                return (
                  <div key={b.Betalmetod + ' ' + index} className="SumContainer__Detailed">
                    <div className="Detailed__ItemHeader">{b.Betalmetod}</div>
                    <div className="Detailed__ItemValue">
                      {b.Pris} {b.Valuta}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
      {!isApp() && (
        <div>
          <MainButton
            className="Button Receipt__Print"
            buttonStyle="solid"
            color="red"
            handleOnClick={() => window.print()}
            title={translate(SitecoreDictionary.Receipt.SpecificReceipt.PrintButton) as string}
          />
        </div>
      )}
    </div>
  );
};
