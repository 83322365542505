import { getTranslate } from 'store/global/dictionary/dictionary';
import { store } from 'index';
import { getWeekDays } from 'websd/utils';

const bookings = 'Bookings';
const Bookings = {
  Title: `${bookings}.BookingsTitle`,
  BookingRulesTitle: `${bookings}.BookingRulesTitle`,
  NothingBooked: `${bookings}.NothingBooked`,
};

const cart = 'Cart';
const ssnLookup = `${cart}.SSNLookup`;
const SSNLookup = {
  PersonAlreadyExists: `${ssnLookup}.PersonAlreadyExists`,
  PersonNotFound: `${ssnLookup}.PersonNotFound`,
  PerfomLookupButtonText: `${ssnLookup}.PerfomLookupButtonText`,
};
const Cart = {
  SSNLookup: SSNLookup,
  SubscriptionTermsTitle: `${cart}.SubscriptionTermsTitle`,
  EmptyCart: `${cart}.EmptyCart`,
  TermsCheckboxTitle: `${cart}.TermsCheckboxTitle`,
  IAccept: `${cart}.IAccept`,
  TheTerms: `${cart}.TheTerms`,
  PaymentInputLabel: `${cart}.PaymentInputLabel`,
  ContinueToPayment: `${cart}.ContinueToPayment`,
  SSN: `${cart}.SSN`,
  BirthDate: `${cart}.BirthDate`,
  DebitInfo: `${cart}.DebitInfo`,
  HasNoSwedishSSN: `${cart}.HasNoSwedishSSN`,
  CouponCodeLabel: `${cart}.CouponCodeLabel`,
  CouponCodeTitle: `${cart}.CouponCodeTitle`,
  CouponCodeButtonText: `${cart}.CouponCodeButtonText`,
  CouponCodePlaceholder: `${cart}.CouponCodePlaceholder`,
  CouponCodeInvalid: `${cart}.CouponCodeInvalid`,
  FirstName: `${cart}.FirstName`,
  LastName: `${cart}.LastName`,
  YourProfile: `${cart}.YourProfile`,
  FavouriteBusinessUnitTitle: `${cart}.FavouriteBusinessUnitTitle`,
  FavouriteBusinessUnitDefault: `${cart}.FavouriteBusinessUnitDefault`,
  FavouriteBusinessUnitDescription: `${cart}.FavouriteBusinessUnitDescription`,
  CartName: `${cart}.CartName`,
  Email: `${cart}.Email`,
  TelephoneNumber: `${cart}.TelephoneNumber`,
  Street: `${cart}.Street`,
  ZipCode: `${cart}.ZipCode`,
  City: `${cart}.City`,

  Facility: `${cart}.Facility`,
  FacilityExplanation: `${cart}.FacilityExplanation`,

  PaymentMethodsTitle: `${cart}.PaymentMethodsTitle`,
  PaymentMethodsAutogiroExtraInfo: `${cart}.PaymentMethodsAutogiroExtraInfo`,
  PickPaymentMethod: `${cart}.PickPaymentMethod`,
  PhoneNumberLabel: `${cart}.PhoneNumberLabel`,
  PaymentMethodBank: `${cart}.PaymentMethodBank`,
  PaymentMethodCard: `${cart}.PaymentMethodCard`,
  PaymentMethodSwish: `${cart}.PaymentMethodSwish`,

  // Placeholders
  SSNPlaceholder: `${cart}.SSNPlaceholder`,
  SSNPlaceholderNoSwedishSSN: `${cart}.SSNPlaceholderNoSwedishSSN`,
  FirstNamePlaceholder: `${cart}.FirstNamePlaceholder`,
  LastNamePlaceholder: `${cart}.LastNamePlaceholder`,
  EmailPlaceholder: `${cart}.EmailPlaceholder`,
  StreetPlaceholder: `${cart}.StreetPlaceholder`,
  TelephonePlaceholder: `${cart}.TelephonePlaceholder`,
  FacilityInput: `${cart}.FacilityPlaceholder`,
  PhoneNumberPlaceholder: `${cart}.PhoneNumberPlaceholder`,
  PaymentMethodPlaceholder: `${cart}.PaymentMethodPlaceholder`,

  // Titles
  AccountTitle: `${cart}.AccountTitle`,
  TotalToPay: `${cart}.TotalToPay`,

  // Messages
  INVALID_ADDRESS: `${cart}.INVALID_ADDRESS`,
  PERSONNR_WRONG_FORMAT: `${cart}.SNNWrongFormat`,
  PERSONNR_ALREADY_EXISTS: `${cart}.SNNAlreadyExists`,
  EPOST_ALREADY_EXISTS: `${cart}.EpostAlreadyExists`,
  EPOST_NOT_VALID: `${cart}.EpostNotValid`,
  INVALID_INPUT: `${cart}.InvalidInput`,
  UNKNOWN_ERROR: `${cart}.UnknownError`,
  UserAlreadyExistsInMittFriskis: `${cart}.UserAlreadyExistsInMittFriskis`,
  SSN_INVALID_SE: `${cart}.SNNWrongFormat`,
  ADDRESS_INVALID_POSTALCODE: `${cart}.INVALID_ADDRESS`,

  // SSN lookup
};

const common = 'Common';

const newPasswordField = `${common}.NewPasswordField`;
const NewPasswordField = {
  NewPasswordRules: `${newPasswordField}.NewPasswordRules`,
  Password: `${newPasswordField}.Password`,
  PasswordPlaceholder: `${newPasswordField}.PasswordPlaceholder`,
  RepeatPassword: `${newPasswordField}.RepeatPassword`,
  RepeatPasswordPlaceholder: `${newPasswordField}.RepeatPasswordPlaceholder`,
};

const Common = {
  Back: `${common}.Back`,
  Continue: `${common}.Continue`,
  Hide: `${common}.Hide`,
  No: `${common}.No`,
  Cancel: `${common}.Cancel`,
  Save: `${common}.Save`,
  Show: `${common}.Show`,
  Yes: `${common}.Yes`,
  SavingSucceed: `${common}.SavingSucceed`,
  SavingFailed: `${common}.SavingFailed`,
  BackToProducts: `${common}.BackToProducts`,
  NewPasswordField,
};

const facilities = 'Facilities';
const Facilities = {
  Open: `${facilities}.Open`,
  OpenTomorrow: `${facilities}.OpenTomorrow`,
  CloseTomorrow: `${facilities}.CloseTomorrow`,
  OpenReception: `${facilities}.OpenReception`,
  OpenReceptionTomorrow: `${facilities}.OpenReceptionTomorrow`,
  OpenLater: `${facilities}.OpenLater`,
  OpenReceptionLater: `${facilities}.OpenReceptionLater`,
  ExceptionalOpenTimes: `${facilities}.ExceptionalOpenTimes`,
  Closed: `${facilities}.Closed`,
};

const gdpr = 'GDPR';
const GDPR = {
  GDPR: `${gdpr}.GDPR`,
  TermsCheckBoxTitleGDPR: `${gdpr}.TermsCheckBoxTitleGDPR`,
  GDRPTitle: `${gdpr}.GDPRTitle`,
};

const settings = 'Settings';
const Settings = {
  Banner: `${settings}.Banner`,
};

const login = 'Login';
const loginMessages = `${login}.Messages`;
const LoginMessages = {
  InvalidCredentials: `${loginMessages}.InvalidCredentials`,
};
const Login = {
  Email: `${login}.Email`,
  EmailPlaceholder: `${login}.EmailPlaceholder`,
  Foerening: `${login}.Foerening`,
  ForgotPassword: `${login}.ForgotPassword`,
  Login: `${login}.Login`,
  Password: `${login}.Password`,
  PasswordPlaceholder: `${login}.PasswordPlaceholder`,
  SelectFoerening: `${login}.SelectFoerening`,
  Messages: LoginMessages,
  GoBack: `${login}.GoBack`,
  Hi: `${login}.Hi`,
  WhoYouAre: `${login}.WhoYouAre`,
  IdentifyYourSelf: `${login}.IdentifyYourSelf`,
  PromptTitle: `${login}.PromptTitle`,
  PromptDescription: `${login}.PromptDescription`,
  PromptLoginDescription: `${login}.PromptLoginDescription`,
  PromptSignUpDescription: `${login}.PromptSignUpDescription`,
  PromptSignUpButton: `${login}.PromptSignUpButton`,
  FoereningSelectMessage: `${login}.FoereningSelectMessage`,
};

const paymentErrorMessages = 'PaymentErrorMessages';
const brpPaymentErrorMessages = `${paymentErrorMessages}.BRP`;
const pastellDataPaymentErrorMessages = `${paymentErrorMessages}.PastellData`;
const createPaymentErrorMessages = `${paymentErrorMessages}.Create`;
const PaymentErrorMessages = {
  BRP: {
    f10: `${brpPaymentErrorMessages}.f10`,
    f101: `${brpPaymentErrorMessages}.f101`,
    f102: `${brpPaymentErrorMessages}.f102`,
    f103: `${brpPaymentErrorMessages}.f103`,
    f104: `${brpPaymentErrorMessages}.f104`,
    f105: `${brpPaymentErrorMessages}.f105`,
    f106: `${brpPaymentErrorMessages}.f106`,
    f107: `${brpPaymentErrorMessages}.f107`,
    f110: `${brpPaymentErrorMessages}.f110`,
    f13: `${brpPaymentErrorMessages}.f13`,
    f14: `${brpPaymentErrorMessages}.f14`,
    f15: `${brpPaymentErrorMessages}.f15`,
    f200: `${brpPaymentErrorMessages}.f200`,
    f201: `${brpPaymentErrorMessages}.f201`,
    f202: `${brpPaymentErrorMessages}.f202`,
    f203: `${brpPaymentErrorMessages}.f203`,
    f204: `${brpPaymentErrorMessages}.f204`,
    f205: `${brpPaymentErrorMessages}.f205`,
    f206: `${brpPaymentErrorMessages}.f206`,
    f207: `${brpPaymentErrorMessages}.f207`,
    f9: `${brpPaymentErrorMessages}.f9`,
  },
  PastellData: {
    CANCEL: `${pastellDataPaymentErrorMessages}.CANCEL`,
    ERROR: `${pastellDataPaymentErrorMessages}.ERROR`,
  },
  GeneralError: `${paymentErrorMessages}.GeneralError`,
  CreatePaymentErrorMessages: {
    Autogiro: `${createPaymentErrorMessages}.Autogiro`,
  },
};

const mainMenu = 'MainMenu';
const MainMenu = {
  Bookings: `${mainMenu}.Bookings`,
  Facilities: `${mainMenu}.Facilities`,
  Friskis: `${mainMenu}.Friskis`,
  GoToPublicWeb: `${mainMenu}.GoToPublicWeb`,
  Me: `${mainMenu}.Me`,
  News: `${mainMenu}.News`,
  Notifications: `${mainMenu}.Notifications`,
  Schedule: `${mainMenu}.Schedule`,
  Settings: `${mainMenu}.Settings`,
  Subscriptions: `${mainMenu}.Subscriptions`,
  TrainingStatistics: `${mainMenu}.TrainingStatistics`,
  OnlineTraining: `${mainMenu}.OnlineTraining`,
};

const profile = 'Profile';

const profileMenu = `${profile}.Menu`;
const ProfileMenu = {
  CalendarSyncText: `${profileMenu}.CalendarSyncText`,
  CalendarSyncTitle: `${profileMenu}.CalendarSyncTitle`,
  NotificationSettingsText: `${profileMenu}.NotificationSettingsText`,
  NotificationSettingsTitle: `${profileMenu}.NotificationSettingsTitle`,
  PaymentsText: `${profileMenu}.PaymentsText`,
  PaymentsTitle: `${profileMenu}.PaymentsTitle`,
  ProfileText: `${profileMenu}.ProfileText`,
  ProfileLongText: `${profileMenu}.ProfileLongText`,
  ProfileTitle: `${profileMenu}.ProfileTitle`,
  TrainingPreferencesText: `${profileMenu}.TrainingPreferencesText`,
  TrainingPreferencesTitle: `${profileMenu}.TrainingPreferencesTitle`,
  UserAccountText: `${profileMenu}.UserAccountText`,
  UserAccountTitle: `${profileMenu}.UserAccountTitle`,
};

const userAccount = `UserAccount`;
const UserAccount = {
  ChangePassword: `${userAccount}.ChangePassword`,
  ChangePasswordTitle: `${userAccount}.ChangePasswordTitle`,
  EnterCurrentPassword: `${userAccount}.EnterCurrentPassword`,
  EnterCurrentPasswordPlaceHolder: `${userAccount}.EnterCurrentPasswordPlaceHolder`,
  RepeatPassword: `${userAccount}.RepeatPassword`,
  Title: `${userAccount}.Title`,
  PasswordChangedSuccessfully: `${userAccount}.PasswordChangedSuccessfully`,
  PasswordChangeFailed: `${userAccount}.PasswordChangeFailed`,
};

const paymentSettings = `${profile}.PaymentSettings`;
const PaymentSettings = {
  Account: `${paymentSettings}.Account`,
  Autogiro: `${paymentSettings}.Autogiro`,
  Avtalegiro: `${paymentSettings}.Avtalegiro`,
  Card: `${paymentSettings}.Card`,
  Information: `${paymentSettings}.Information`,
  NoRegisteredPayments: `${paymentSettings}.NoRegisteredPayments`,
  PaymentSettings: `${paymentSettings}.PaymentSettings`,
  TerminateInformation: `${paymentSettings}.TerminateInformation`,
  TerminateInformationWhenEnabled: `${paymentSettings}.TerminateInformationWhenEnabled`,
  AccountNumber: `${paymentSettings}.AccountNumber`,
  ClearingNumber: `${paymentSettings}.ClearingNumber`,
  DebitDetails: `${paymentSettings}.DebitDetails`,
  UpdateBankAccount: `${paymentSettings}.UpdateBankAccount`,
  INVALID_INPUT: `${paymentSettings}.InvalidInput`,
  UNKNOWN_ERROR: `${paymentSettings}.UnknownError`,
};

const sectionDescription = `${profile}.SectionDescription`;
const SectionDescription = {
  Profile: `${sectionDescription}.Profile`,
  Account: `${sectionDescription}.Account`,
  PaymentMethods: `${sectionDescription}.PaymentMethods`,
  NotificationsSettings: `${sectionDescription}.NotificationsSettings`,
  TrainingPreferences: `${sectionDescription}.TrainingPreferences`,
};

const calendarSync = `${profile}.CalendarSync`;
const CalendarSync = {
  Title: `${calendarSync}.Title`,
  Text: `${calendarSync}.Text`,
  PickCalendar: `${calendarSync}.PickCalendar`,
};

const Profile = {
  Logout: `${profile}.Logout`,
  Login: `${profile}.Login`,
  Address: `${profile}.Address`,
  City: `${profile}.City`,
  Email: `${profile}.Email`,
  Firstname: `${profile}.Firstname`,
  Lastname: `${profile}.Lastname`,
  Phonenumber: `${profile}.Phonenumber`,
  ZipCode: `${profile}.ZipCode`,
  RequiredFieldError: `${profile}.RequiredFieldError`,
  FieldAcceptOnlyChars: `${profile}.FieldAcceptOnlyChars`,
  InvalidEmail: `${profile}.InvalidEmail`,
  InvalidZipCode: `${profile}.InvalidZipCode`,
  InvalidTelNumber: `${profile}.InvalidTelNumber`,
  MustSaveBeforeContinue: `${profile}.MustSaveBeforeContinue`,
  EmailAlreadyExists: `${profile}.EmailAlreadyExists`,
  Menu: ProfileMenu,
  PaymentSettings: PaymentSettings,
  SectionDescription,
  CalendarSync,
};

const scheduleBookingStatus = 'Schedule.BookingStatus';
const ScheduleBookingStatus = {
  EJ_BOKNINGSBART_DATE: `${scheduleBookingStatus}.EJ_BOKNINGSBART_DATE`,
  EJ_BOKNINGSBART_DATE_DROPIN: `${scheduleBookingStatus}.EJ_BOKNINGSBART_DATE_DROPIN`,
  FOERSENT_FOER_ATT_BOKA: `${scheduleBookingStatus}.FOERSENT_FOER_ATT_BOKA`,
  FOERTIDIGT_FOER_ATT_BOKA: `${scheduleBookingStatus}.FOERTIDIGT_FOER_ATT_BOKA`,
  BOKNINGSBART: `${scheduleBookingStatus}.BOKNINGSBART`,
  BOKNINGSBART_DROPIN: `${scheduleBookingStatus}.BOKNINGSBART_DROPIN`,
  BOKNINGSBART_KOE: `${scheduleBookingStatus}.BOKNINGSBART_KOE`,
  BOKNINGSBART_KOE_DROPIN: `${scheduleBookingStatus}.BOKNINGSBART_KOE_DROPIN`,
  KOEPLATS: `${scheduleBookingStatus}.KOEPLATS`,
  KOEPLATS_DROPIN: `${scheduleBookingStatus}.KOEPLATS_DROPIN`,
  FULLT: `${scheduleBookingStatus}.FULLT`,
  FULLT_DROPIN: `${scheduleBookingStatus}.FULLT_DROPIN`,
  DROPIN: `${scheduleBookingStatus}.DROPIN`,
  INSTAELLD: `${scheduleBookingStatus}.INSTAELLD`,
};

const scheduleDayPeriods = 'Schedule.DayPeriods';
const ScheduleDayPeriods = {
  Morning: `${scheduleDayPeriods}.Morning`,
  Midmorning: `${scheduleDayPeriods}.Midmorning`,
  Lunch: `${scheduleDayPeriods}.Lunch`,
  Afternoon: `${scheduleDayPeriods}.Afternoon`,
  Evening: `${scheduleDayPeriods}.Evening`,
};

const scheduleMessages = 'Schedule.Messages';
const ScheduleMessages = {
  ALREADY_BOOKED: `${scheduleMessages}.AlreadyBooked`,
  ALREADY_ON_WAITINGLIST: `${scheduleMessages}.AlreadyOnWaitingList`,
  BOOKING_CLASHES_WITH_OTHER_BOOKING: `${scheduleMessages}.BookingClashesWithOtherBooking`,
  CANCELLATION_CONFIRMATION: `${scheduleMessages}.CancellationConfirmation`,
  CANCELLATION_OK: `${scheduleMessages}.CancellationOk`,
  CANCELLED: `${scheduleMessages}.Cancelled`,
  NO_FREE_SLOTS_AVAILABLE: `${scheduleMessages}.NoFreeSlotsAvailable`,
  OK_BOOK: `${scheduleMessages}.OkBook`,
  OK_CANCEL: `${scheduleMessages}.OkCancel`,
  PassAddedToCalendar: `${scheduleMessages}.PassAddedToCalendar`,
  PassCanceled: `${scheduleMessages}.PassCanceled`,
  QUEUED: `${scheduleMessages}.Queued`,
  QueuePlaceNotification: `${scheduleMessages}.QueuePlaceNotification`,
  TOO_LATE_TO_BOOK: `${scheduleMessages}.TooLateToBook`,
  TOO_LATE_TO_CANCEL: `${scheduleMessages}.TooLateToCancel`,
  TOO_MANY_CONCURRENT_BOOKINGS: `${scheduleMessages}.TooManyConcurrentBookings`,
  TOO_MANY_CONCURRENT_WAITINGLISTS: `${scheduleMessages}.TooManyConcurrentWaitingLists`,
  TOO_MANY_NO_SHOWS: `${scheduleMessages}.TooManyNoShows`,
  UNKNOWN_ERROR: `${scheduleMessages}.UnknownError`,
  UNKNOWN_ERROR_CANCEL: `${scheduleMessages}.UnknownErrorCancel`,
  MISSING_PRIVILEGE: `${scheduleMessages}.MissingPrivilege`,
  NOT_OPEN_FOR_BOOKING: `${scheduleMessages}.NotOpenForBooking`,
  BOOKING_TERMS_NOT_ACCEPTED: `${scheduleMessages}.BookingTermsNotAccepted`,
  BOOKING_REQUIRES_MEMBERSHIP: `${scheduleMessages}.BookingRequiresMembership`,
  BOOKING_RESOURCE_REQUIREMENT_HAS_CHANGED: `${scheduleMessages}.BookingResourceRequirementHasChanged`,
  BOOKING_RULE_PREVENTS_ADDING_TO_WAITING_LIST: `${scheduleMessages}.BookingRulePreventsAddingToWaitingList`,
  BOOKING_RULE_PREVENTS_BOOKING: `${scheduleMessages}.BookingRulePreventsBooking`,
  BOOKING_WAITING_LIST_NOT_ALLOWED_IF_FREE_SLOTS: `${scheduleMessages}.BookingWaitingListNotAllowedIfFreeSlot`,
  BOOK_EVENT_INSUFFICIENT_SLOTS: `${scheduleMessages}.BookEventInsufficientSlots`,
  BOOK_EVENT_NOT_IN_BOOKING_PERIOD: `${scheduleMessages}.BookEventNotInBookingPeriod`,
  BOOK_SERVICE_INSUFFICIENT_RIGHTS: `${scheduleMessages}.BookServiceInsufficientRights`,
  CANNOT_BOOK_CANCELLED_ACTIVITY: `${scheduleMessages}.CannotBookCancelledActivity`,
  TOO_EARLY_TO_BOOK: `${scheduleMessages}.TooEarlyToBook`,
  NO_FREE_RESOURCES_AVAILABLE: `${scheduleMessages}.NoFreeResourcesAvailable`,
  UNPAID_INVOICES_EXIST: `${scheduleMessages}.UnpaidInvoicesExist`,
};

const scheduleFilterCategoriesBase = 'Schedule.Filter.Categories';
const ScheduleFilterCategories = {
  Bokningsstatus: `${scheduleFilterCategoriesBase}.Bokningsstatus`,
  Favoritfilter: `${scheduleFilterCategoriesBase}.Favoritfilter`,
  LedareOrTraenare: `${scheduleFilterCategoriesBase}.LedareOrTraenare`,
  Tidpunkt: `${scheduleFilterCategoriesBase}.Tidpunkt`,
  Traeningspass: `${scheduleFilterCategoriesBase}.Traeningspass`,
  Traeningsstaelle: `${scheduleFilterCategoriesBase}.Traeningsstaelle`,
  Veckodag: `${scheduleFilterCategoriesBase}.Veckodag`,
};

const scheduleFilter = 'Schedule.Filter';
const ScheduleFilter = {
  Bokningsstatusar: {},
  Categories: ScheduleFilterCategories,
  ClearFilter: `${scheduleFilter}.ClearFilter`,
  DeleteFilterConfirm: `${scheduleFilter}.DeleteFilterConfirm`,
  HideFilter: `${scheduleFilter}.HideFilter`,
  NameFilter: `${scheduleFilter}.NameFilter`,
  QuickSearchPlaceholder: `${scheduleFilter}.QuickSearchPlaceholder`,
  DeleteFilter: `${scheduleFilter}.DeleteFilter`,
  NameFilterTitle: `${scheduleFilter}.NameFilterTitle`,
  SaveFilter: `${scheduleFilter}.SaveFilter`,
  SavedFilters: `${scheduleFilter}.SavedFilters`,
  SearchWithFreeText: `${scheduleFilter}.SearchWithFreeText`,
  ShowFilter: `${scheduleFilter}.ShowFilter`,
  EditFilter: `${scheduleFilter}.EditFilter`,
  FilterHasNoHits: `${scheduleFilter}.FilterHasNoHits`,
  MaxNumberOfItemsExceeded: `${scheduleFilter}.MaxNumberOfItemsExceeded`,
  SelectAllInCategory: `${scheduleFilter}.SelectAllInCategory`,
  MustPickOneGymTitle: `${scheduleFilter}.MustPickOneGymTitle`,
  MustPickOneGymButton: `${scheduleFilter}.MustPickOneGymButton`,
  MustPickOneGymTitlePublicSchedule: `${scheduleFilter}.MustPickOneGymTitlePublicSchedule`,
  MustPickOneGymButtonPublicSchedule: `${scheduleFilter}.MustPickOneGymButtonPublicSchedule`,
  MustPickOneGymTitleSchedule: `${scheduleFilter}.MustPickOneGymTitleSchedule`,
  MustPickOneGymButtonSchedule: `${scheduleFilter}.MustPickOneGymButtonSchedule`,
};

const scheduleView = 'Schedule.View';
const ScheduleView = {
  ListView: `${scheduleView}.ListView`,
  WeekView: `${scheduleView}.WeekView`,
};

const schedulePublicWeb = 'Schedule.PublicWeb';
const SchedulePublicWeb = {
  Bookings: `${schedulePublicWeb}.Bookings`,
  CancelBookings: `${schedulePublicWeb}.CancelBookings`,
  Hello: `${schedulePublicWeb}.Hello`,
  Identify: `${schedulePublicWeb}.Identify`,
  Info: `${schedulePublicWeb}.Info`,
  MittFriskisHeader: `${schedulePublicWeb}.MittFriskisHeader`,
  MittFriskisLink: `${schedulePublicWeb}.MittFriskisLink`,
  SaveFilter: `${schedulePublicWeb}.SaveFilter`,
  TrainingStatistics: `${schedulePublicWeb}.TrainingStatistics`,
};

const schedule = 'Schedule';
const Schedule = {
  BookingRules: `${schedule}.BookingRules`,
  BookingRulesFor: `${schedule}.BookingRulesFor`,
  BookingStatus: ScheduleBookingStatus,
  DayPeriods: ScheduleDayPeriods,
  Filter: ScheduleFilter,
  Messages: ScheduleMessages,
  View: ScheduleView,
  ConfirmBook: `${schedule}.ConfirmBook`,
  ConfirmCancel: `${schedule}.ConfirmCancel`,
  HideFilter: `${schedule}.HideFilter`,
  Places: `${schedule}.Places`,
  QueuePosition: `${schedule}.QueuePosition`,
  Schedule: `${schedule}.Schedule`,
  ShowFilter: `${schedule}.ShowFilter`,
  ShowRules: `${schedule}.ShowRules`,
  ListView: `${schedule}.ListView`,
  WeekView: `${schedule}.WeekView`,
  PublicWeb: SchedulePublicWeb,
  PullToRefresh: `${schedule}.PullToRefresh`,
  ReleaseToRefresh: `${schedule}.ReleaseToRefresh`,
  Refreshing: `${schedule}.Refreshing`,
};

const signing = 'Signing';
const Signing = {
  SignatureCaseNotSigned: `${signing}.SignatureCaseNotSigned`,
  SignatureCaseSigned: `${signing}.SignatureCaseSigned`,
  SigningName: `${signing}.SigningName`,
  SigningTitle: `${signing}.SigningTitle`,
  SigningTitleAllSigned: `${signing}.SigningTitleAllSigned`,
  SigningTitleDirectDebit: `${signing}.SigningTitleDirectDebit`,
  SigningTitleMembership: `${signing}.SigningTitleMembership`,
};

const shop = 'Shop';
const shopFilter = `${shop}.Filter`;
const adminShop = `${shop}.Admin`;
const shopFilterCategories = `${shopFilter}.Categories`;
const shopFilterCategoryValues = `${shop}.Filter.CategoryValues`;
const ShopFilterCategoryValues = {
  ProductHow: {
    DIRECT_DEBIT_NO: `${shopFilterCategoryValues}.ProductHow.DIRECT_DEBIT_NO`,
    DIRECT_DEBIT_SE: `${shopFilterCategoryValues}.ProductHow.DIRECT_DEBIT_SE`,
    MANUAL_DEBIT: `${shopFilterCategoryValues}.ProductHow.MANUAL_DEBIT`,
    RECURRING_CARD_PAYMENT: `${shopFilterCategoryValues}.ProductHow.RECURRING_CARD_PAYMENT`,
  },
  ProductWhen: {
    Dag: `${shopFilterCategoryValues}.ProductWhen.Dag`,
    DygnetRunt: `${shopFilterCategoryValues}.ProductWhen.DygnetRunt`,
  },
  ProductWho: {
    Pensionar: `${shopFilterCategoryValues}.ProductWho.Pensionar`,
    Student: `${shopFilterCategoryValues}.ProductWho.Student`,
    Tonaring: `${shopFilterCategoryValues}.ProductWho.Tonaring`,
    Vuxen: `${shopFilterCategoryValues}.ProductWho.Vuxen`,
  },
};

const AdminShop = {
  ShopNotAvailableForAdminTitle: `${adminShop}.ShopNotAvailableForAdminTitle`,
  ShopNotAvailableForAdminText: `${adminShop}.ShopNotAvailableForAdminText`,
  ShopNotAvailableForAdminReturnButtonText: `${adminShop}.ShopNotAvailableForAdminReturnButtonText`,
  CannotPurchaseInAdminShop: `${adminShop}.CannotPurchaseInAdminShop`,
  ProductNotFound: `${adminShop}.ProductNotFound`,
};

const ShopFilter = {
  Intro: `${shopFilter}.Intro`,
  Categories: {
    ProductHow: `${shopFilterCategories}.ProductHow`,
    ProductWhen: `${shopFilterCategories}.ProductWhen`,
    ProductWhere: `${shopFilterCategories}.ProductWhere`,
    ProductWho: `${shopFilterCategories}.ProductWho`,
  },
  CategoryValues: ShopFilterCategoryValues,
  TwoBusinessUnitsCardTitle: `${shopFilter}.TwoBusinessUnitsCardTitle`,
  TwoBusinessUnitsCardDescription: `${shopFilter}.TwoBusinessUnitsCardDescription`,
  NationalCardTitle: `${shopFilter}.NationalCardTitle`,
  NationalCardDescription: `${shopFilter}.NationalCardDescription`,
};

const Shop = {
  RecommendedProducts: `${shop}.RecommendedProducts`,
  ShopName: `${shop}.ShopName`,
  RegisterName: `${shop}.RegisterName`,
  // Filter
  Filter: ShopFilter,
  AddToBasket: `${shop}.AddToBasket`,
  CannotBuyProduct: `${shop}.CannotBuyProduct`,
  GoBackToPublicSite: `${shop}.GoBackToPublicSite`,
  TwoBusinessUnitCard: `${shop}.TwoBusinessUnitCard`,
  NationalCard: `${shop}.NationalCard`,
  TrainingCardTabsTitle: `${shop}.TrainingCardTabsTitle`,
  AllProducts: `${shop}.AllProducts`,
  ExactHits: `${shop}.ExactHits`,
  OtherHits: `${shop}.OtherHits`,
  NoExactHits: `${shop}.NoExactHits`,

  // Public Shop
  PublicShopBannerTitle: `${shop}.PublicShopBannerTitle`,
  PublicShopBannerText: `${shop}.PublicShopBannerText`,
  PublicShopCoreBannerTitle: `${shop}.PublicShopCoreBannerTitle`,
  PublicShopCoreBannerText: `${shop}.PublicShopCoreBannerText`,
  PublicShopSelectionDefault: `${shop}.PublicShopSelectionDefault`,

  MembershipTitle: `${shop}.MembershipTitle`,
  MembershipDescription: `${shop}.MembershipDescription`,
  AddedMembershipToBasket: `${shop}.AddedMembershipToBasket`,
  NoProductsFoundFromFilter: `${shop}.NoProductsFoundFromFilter`,

  ProductAddedToBasket: `${shop}.ProductAddedToBasket`,
  ProductExistsInBasket: `${shop}.ProductExistsInBasket`,
  CanOnlyAddOneOfTypeTraeningsKort: `${shop}.CanOnlyAddOneOfTypeTraeningsKort`,

  ShopNotAvailableTitle: `${shop}.ShopNotAvailableTitle`,
  ShopNotAvailableText: `${shop}.ShopNotAvailableText`,
  ShopNotAvailableReturnButtonText: `${shop}.ShopNotAvailableReturnButtonText`,

  Admin: AdminShop,

  DisplayProducts: `${shop}.DisplayProducts`,
  HideProducts: `${shop}.HideProducts`,
};

const startPage = 'StartPage';

const startPageDesktopMenu = `${startPage}.DesktopMenu`;
const startPageDesktopMenuTitles = {
  Bookings: `${startPageDesktopMenu}.Bookings`,
  Facilities: `${startPageDesktopMenu}.Facilities`,
  News: `${startPageDesktopMenu}.News`,
  Notifications: `${startPageDesktopMenu}.Notifications`,
  Subsctiptions: `${startPageDesktopMenu}.Subscriptions`,
  TrainingStatistics: `${startPageDesktopMenu}.TrainingStatistics`,
};

const StartPage = {
  NextPass: `${startPage}.NextPass`,
  NoBookedPassTitle: `${startPage}.NoBookedPassTitle`,
  NoBookedPassText: `${startPage}.NoBookedPassText`,
  OpenSchema: `${startPage}.OpenSchema`,
  ShowBokningar: `${startPage}.ShowBokningar`,
  Greetings: `${startPage}.Greetings`,
  Menu: startPageDesktopMenuTitles,
};

const statistics = 'TrainingStatistics';
const Statistics = {
  ActivityTypesLast30Days: `${statistics}.ActivityTypesLast30Days`,
  WorkoutsLastMonth: `${statistics}.WorkoutsLastMonth`,
  WorkoutsLastWeek: `${statistics}.WorkoutsLastWeek`,
  WorkoutsLastYear: `${statistics}.WorkoutsLastYear`,
  WorkoutsPerMonth: `${statistics}.WorkoutsPerMonth`,
  WorkoutsPerWeek: `${statistics}.WorkoutsPerWeek`,
  WorkoutsPerYear: `${statistics}.WorkoutsPerYear`,
  WorkoutsThisMonth: `${statistics}.WorkoutsThisMonth`,
  WorkoutsThisWeek: `${statistics}.WorkoutsThisWeek`,
  WorkoutsThisYear: `${statistics}.WorkoutsThisYear`,
  DefaultValueGym: `${statistics}.DefaultValueGym`,
};

const subscriptionsAndCards = 'SubscriptionsAndCards';

const subscriptionsAndCardsMessages = `${subscriptionsAndCards}.Messages`;
const SubscriptionsAndCardsMessages = {
  ALREADY_TERMINATED: `${subscriptionsAndCardsMessages}.AlreadyTerminated`,
  CANNOT_TERMINATE_SUBSCRIPTION: `${subscriptionsAndCardsMessages}.CannotTerminateSubscription`,
  FAILED_TO_TERMINATE: `${subscriptionsAndCardsMessages}.FailedToTerminate`,
  UNKNOWN_ERROR: `${subscriptionsAndCardsMessages}.UnknownError`,
  OK: `${subscriptionsAndCardsMessages}.OkTerminate`,
};

const SubscriptionsAndCards = {
  BannerText: `${subscriptionsAndCards}.BannerText`,
  BuyMore: `${subscriptionsAndCards}.BuyMore`,
  CardHasExpired: `${subscriptionsAndCards}.CardHasExpired`,
  ConfirmTerminateSubscription: `${subscriptionsAndCards}.ConfirmTerminateSubscription`,
  Extend: `${subscriptionsAndCards}.Extend`,
  Membership: `${subscriptionsAndCards}.Membership`,
  NumberOfUnitsLeft: `${subscriptionsAndCards}.NumberOfUnitsLeft`,
  Renew: `${subscriptionsAndCards}.Renew`,
  ShopButton: `${subscriptionsAndCards}.ShopButton`,
  TrainingCards: `${subscriptionsAndCards}.TrainingCards`,
  SubscriptionReceipts: `${subscriptionsAndCards}.SubscriptionReceipts`,
  Valid: `${subscriptionsAndCards}.Valid`,
  ValidFrom: `${subscriptionsAndCards}.ValidFrom`,
  ValidUntil: `${subscriptionsAndCards}.ValidUntil`,
  ValidBetween: `${subscriptionsAndCards}.ValidBetween`,
  BoundUntil: `${subscriptionsAndCards}.BoundUntil`,
  MembershipHasExpired: `${subscriptionsAndCards}.MembershipHasExpired`,
  ProductNotFound: `${subscriptionsAndCards}.ProductNotFound`,
  Messages: SubscriptionsAndCardsMessages,
  Terminated: `${subscriptionsAndCards}.Terminated`,
};

const medlemsVillkor = 'MedlemsVillkor';
const MedlemsVillkor = {
  HasNoMedlemsVillkor: `${medlemsVillkor}.HasNoMedlemsVillkor`,
  HasNoSmsEmailVillkor: `${medlemsVillkor}.HasNoSmsEmailVillkor`,
};

const passDetails = 'PassDetails';
const PassDetails = {
  XDropsAvailable: `${passDetails}.XDropsAvailable`,
  DropInRule: `${passDetails}.DropInRule`,
  PlayFilmAbout: `${passDetails}.PlayFilmAbout`,
  LoadingFailed: `${passDetails}.LoadingFailed`,
};

const pushNotificationSettings = 'PushNotificationSettings';
const PushNotificationSettings = {
  NewsText: `${pushNotificationSettings}.NewsText`,
  NewsTitle: `${pushNotificationSettings}.NewsTitle`,
  QueueText: `${pushNotificationSettings}.QueueText`,
  QueueTitle: `${pushNotificationSettings}.QueueTitle`,
  TrainingCardText: `${pushNotificationSettings}.TrainingCardText`,
  TrainingCardTitle: `${pushNotificationSettings}.TrainingCardTitle`,
  WorkoutTitle: `${pushNotificationSettings}.WorkoutTitle`,
  WorkoutText: `${pushNotificationSettings}.WorkoutText`,
};

const resetPassword = 'ResetPasswordPage';
const changePasswordError = `${resetPassword}.ChangePasswordError`;

const ChangePasswordError = {
  UNKNOWN: `${changePasswordError}.UNKNOWN`,
  INVALID_TOKEN: `${changePasswordError}.INVALID_TOKEN`,
};

const ResetPassword = {
  Login: `${resetPassword}.Login`,
  ChangePassword: `${resetPassword}.ChangePassword`,
  ResetSucceeded: `${resetPassword}.ResetSucceeded`,
  ChangePasswordError,
};

const trainingPreferences = 'TrainingPreferences';
const TrainingPreferences = {
  Add: `${trainingPreferences}.Add`,
  Facilities: `${trainingPreferences}.Facilities`,
  FacilitiesText: `${trainingPreferences}.FacilitiesText`,
  SpecialInterests: `${trainingPreferences}.SpecialInterests`,
  SpecialInterestsText: `${trainingPreferences}.SpecialInterestsText`,
  Text: `${trainingPreferences}.Text`,
  Title: `${trainingPreferences}.Title`,
  Save: `${trainingPreferences}.Save`,
  WorkoutLevel: `${trainingPreferences}.WorkoutLevel`,
  Children: `${trainingPreferences}.Children`,
  Preferences: `${trainingPreferences}.Preferences.`,
};

const receipt = 'Receipt';
const specificReceipt = `${receipt}.Specific`;
const Receipt = {
  ClickToSignAutogiro: `${receipt}.ClickToSignAutogiro`,
  ContinueToTheWebSite: `${receipt}.ContinueToTheWebSite`,
  DisplayReceipt: `${receipt}.DisplayReceipt`,
  ContinueToApp: `${receipt}.ContinueToApp`,
  ReceiptText: `${receipt}.ReceiptText`,
  ReceiptTitle: `${receipt}.ReceiptTitle`,
  ReceiptAdditionalText: `${receipt}.ReceiptAdditionalText`,
  ReceiptAdditionalTitle: `${receipt}.ReceiptAdditionalTitle`,
  SpecificReceipt: {
    Title: `${specificReceipt}.Title`, // Kvittouppgifter
    Date: `${specificReceipt}.Date`, // Datum
    PaymentMethod: `${specificReceipt}.PaymentMethod`, // Betalsätt
    ReceiptNumber: `${specificReceipt}.ReceiptNumber`, // Kvittonummer
    OrderNumber: `${specificReceipt}.OrderNumber`, // Beställningsnummer
    SellerTitle: `${specificReceipt}.SellerTitle`, // Säljare
    SellerInfo: `${specificReceipt}.SellerInfo`, // Säljare
    Email: `${specificReceipt}.Email`, // E-postadress
    Homepage: `${specificReceipt}.Homepage`, // Hemsida
    PhoneNumber: `${specificReceipt}.PhoneNumber`, // Telefonnummer
    OrganisationId: `${specificReceipt}.OrganisationId`, // Organisationsnummer
    BuyerTitle: `${specificReceipt}.BuyerTitle`, // Kund
    Name: `${specificReceipt}.Name`, // Namn
    Address: `${specificReceipt}.Address`, // Adress
    ProductsTitle: `${specificReceipt}.ProductsTitle`, // Produkter
    Product: `${specificReceipt}.Product`, // Produkt
    Amount: `${specificReceipt}.Amount`, // Antal
    Total: `${specificReceipt}.Total`, // Totalt
    Sum: `${specificReceipt}.Sum`, // Totalt
    IncludingVAT: `${specificReceipt}.IncludingVAT`, // Varav moms ($0)
    PayedToday: `${specificReceipt}.PayedToday`, // Betalt idag
    DirectDebitSe: `${specificReceipt}.DirectDebitSe`, // Svenskt autogiro
    DirectDebitNo: `${specificReceipt}.DirectDebitNo`, // Norkst autogiro
    RecurringCardPayment: `${specificReceipt}.RecurringCardPayment`, // Återkommande kortbetalning
    DirectDebitConsentTitle: `${specificReceipt}.DirectDebitConsentTitle`, // Autogiromedgivanden
    ReceiptDetailsNotFoundTitle: `${specificReceipt}.ReceiptDetailsNotFoundTitle`, // Kunde inte hämta kvittodetaljerna
    CouponCode: `${specificReceipt}.CouponCode`, // Rabattkod "$"
    PrintButton: `${specificReceipt}.PrintButton`, // Skriv ut eller spara som PDF
  },
};

const blueScreen = 'ErrorPage';
const ErrorPage = {
  AnErrorOccured: `${blueScreen}.AnErrorOccured`,
  ClickHereToRefresh: `${blueScreen}.ClickHereToRefresh`,
};

const filter = 'Filter';
const Filter = {
  TooManyItems: `${filter}.TooManyItems`,
};

const requestResetPasswordPage = 'RequestResetPasswordPage';
const RequestResetPasswordPage = {
  Email: `${requestResetPasswordPage}.Email`,
  EmailPlaceholder: `${requestResetPasswordPage}.EmailPlaceholder`,
  Help: `${requestResetPasswordPage}.Help`,
  HowToLoginAnswer: `${requestResetPasswordPage}.HowToLoginAnswer`,
  HowToLoginQuestion: `${requestResetPasswordPage}.HowToLoginQuestion`,
  IForgotMyPassDesc: `${requestResetPasswordPage}.IForgotMyPassDesc`,
  IForgotMyPassTitle: `${requestResetPasswordPage}.IForgotMyPassTitle`,
  ResultFailed: `${requestResetPasswordPage}.ResultFailed`,
  ResultSucceed: `${requestResetPasswordPage}.ResultSucceed`,
  SubmitButton: `${requestResetPasswordPage}.SubmitButton`,
  WhoCanUseAnswer: `${requestResetPasswordPage}.WhoCanUseAnswer`,
  WhoCanUseQuestion: `${requestResetPasswordPage}.WhoCanUseQuestion`,
};

const passItem = 'PassItem';
const PassItem = {
  InQueue: `${passItem}.InQueue`,
  Left: `${passItem}.Left`,
  Places: `${passItem}.Places`,
  QueuePosition: `${passItem}.QueuePosition`,
  EmptyPlaces: `${passItem}.EmptyPlaces`,
  Total: `${passItem}.Total`,
};

const pageTitles = 'PageTitles';
const PageTitles = {
  Account: `${pageTitles}.Account`,
  Bookings: `${pageTitles}.Bookings`,
  Facilities: `${pageTitles}.Facilities`,
  MembershipAndCards: `${pageTitles}.MembershipAndCards`,
  News: `${pageTitles}.News`,
  Notifications: `${pageTitles}.Notifications`,
  Payment: `${pageTitles}.Payment`,
  PaymentMethods: `${pageTitles}.PaymentMethods`,
  Profile: `${pageTitles}.Profile`,
  ProfileAndSettings: `${pageTitles}.ProfileAndSettings`,
  PushNotifications: `${pageTitles}.PushNotifications`,
  Schedule: `${pageTitles}.Schedule`,
  Shop: `${pageTitles}.Shop`,
  ShoppingBasket: `${pageTitles}.ShoppingBasket`,
  Statistics: `${pageTitles}.Statistics`,
  TrainingsPreferences: `${pageTitles}.TrainingsPreferences`,
  Receipt: `${pageTitles}.Receipt`,
};

const acceptBookingRulesPopup = 'AcceptBookingRulesPopup';
const AcceptBookingRulesPopup = {
  Text: `${acceptBookingRulesPopup}.Text`,
  Cancel: `${acceptBookingRulesPopup}.Cancel`,
  TryAgain: `${acceptBookingRulesPopup}.TryAgain`,
  FailedToUpdate: `${acceptBookingRulesPopup}.FailedToUpdate`,
  Accept: `${acceptBookingRulesPopup}.Accept`,
};

const WeekDays = {
  Monday: 'WeekDays.Monday',
  Tuesday: 'WeekDays.Tuesday',
  Wednesday: 'WeekDays.Wednesday',
  Thursday: 'WeekDays.Thursday',
  Friday: 'WeekDays.Friday',
  Saturday: 'WeekDays.Saturday',
  Sunday: 'WeekDays.Sunday',
  Today: 'WeekDays.Today',
  Tomorrow: 'WeekDays.Tomorrow',
};

const Months = {
  January: 'Months.January',
  February: 'Months.February',
  March: 'Months.March',
  April: 'Months.April',
  May: 'Months.May',
  June: 'Months.June',
  July: 'Months.July',
  August: 'Months.August',
  September: 'Months.September',
  October: 'Months.October',
  November: 'Months.November',
  December: 'Months.December',
};

export const SitecoreDictionary = {
  CopyFailCode: 'CopyFailCode',
  UnknownError: 'UnknownError',
  PageNotFound: 'PageNotFound',
  Bookings,
  Cart,
  Common,
  Login,
  MainMenu,
  PaymentErrorMessages,
  Profile,
  ProfileMenu,
  PushNotificationSettings,
  Schedule,
  Signing,
  Shop,
  StartPage,
  Statistics,
  SubscriptionsAndCards,
  Settings,
  Facilities,
  MedlemsVillkor,
  UserAccount,
  PassDetails,
  ResetPassword,
  GDPR,
  TrainingPreferences,
  Receipt,
  ErrorPage,
  Filter,
  RequestResetPasswordPage,
  PassItem,
  PageTitles,
  AcceptBookingRulesPopup,
  WeekDays,
  Months,
};

export const SitecorePlaceholder = {
  FOERENING: '{association}',
  PASS: '{activity}',
};

export const getStatusMessage = (status: string) => {
  if (status in SitecoreDictionary.Schedule.Messages) {
    return (SitecoreDictionary.Schedule.Messages as any)[status];
  } else {
    return SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR;
  }
};

export const getSubscriptionStatusMessage = (status: string) => {
  if (status in SitecoreDictionary.SubscriptionsAndCards.Messages) {
    return (SitecoreDictionary.SubscriptionsAndCards.Messages as any)[status];
  } else {
    return SitecoreDictionary.SubscriptionsAndCards.Messages.UNKNOWN_ERROR;
  }
};

export const getShoppingBasketError = (status: string | undefined) => {
  if (status && status in SitecoreDictionary.Cart) {
    return (SitecoreDictionary.Cart as any)[status];
  }
  return SitecoreDictionary.Cart.UNKNOWN_ERROR;
};

export const getPaymentSettingsError = (status: string) => {
  if (status in SitecoreDictionary.Profile.PaymentSettings) {
    return (SitecoreDictionary.Profile.PaymentSettings as any)[status];
  } else {
    return SitecoreDictionary.Profile.PaymentSettings.UNKNOWN_ERROR;
  }
};

export interface TranslationMapping {
  [index: string]: string;
}

export const getWeekdayName = (weekday: number): string => {
  switch (weekday) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export const getMonthName = (month: number): string => {
  switch (month) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    case 11:
      return 'December';
    default:
      return '';
  }
};

export const getWeekdaysObjectMap = (): TranslationMapping => {
  const dayKeys = getWeekDays();
  const returnObject = {};
  dayKeys.map(dayKey => {
    return ((returnObject as any)[dayKey] = dayKey);
  });
  return returnObject;
};

export const getDayPeriodsObjectMap = (): TranslationMapping => {
  const translate = getTranslate(store.getState().locale);
  const dayPeriods = Object.keys(ScheduleDayPeriods);
  const returnObject = {};
  dayPeriods.map(dayPeriod => {
    return ((returnObject as any)[dayPeriod] = translate((ScheduleDayPeriods as any)[dayPeriod]) as string);
  });
  return returnObject;
};

export const getShopFilterCategoryValuesObjectMap = (): TranslationMapping => {
  const translate = getTranslate(store.getState().locale);
  const categoryKeys = Object.keys(ShopFilterCategoryValues); // ProductHow, ProductWhen, ProductWho
  const returnObject = {};
  categoryKeys.forEach(categoryKey => {
    const categoryObject = (ShopFilterCategoryValues as any)[categoryKey];
    const categoryObjectKeys = Object.keys(categoryObject); // Keys in ProductHow, ProductWhen, ProductWho, i.e., DIRECT_DEBIT_NO, Pensionar etc.
    categoryObjectKeys.forEach(categoryObjectKey => {
      if (
        ShopFilterCategoryValues &&
        (ShopFilterCategoryValues as any)[categoryKey] &&
        (ShopFilterCategoryValues as any)[categoryKey][categoryObjectKey]
      ) {
        const translationKey = (ShopFilterCategoryValues as any)[categoryKey][categoryObjectKey];
        (returnObject as any)[categoryObjectKey] = translate(translationKey) as string;
      }
    });
  });
  return returnObject;
};

export const getBokningsstatusarObjectMap = (): TranslationMapping => {
  const translate = getTranslate(store.getState().locale);
  const bokningsstatusar = Object.keys(ScheduleBookingStatus);
  const returnObject = {};
  bokningsstatusar.map(status => {
    return ((returnObject as any)[status] = translate((ScheduleBookingStatus as any)[status]) as string);
  });
  return returnObject;
};
