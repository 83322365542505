import { ComponentLifecycle } from 'react';

interface LastKnownState {
  body: {
    position: string | null;
    height: string | null;
    width: string | null;
    overflow: string | null;
    scrollPosition: number;
  };
  html: {
    position: string | null;
    height: string | null;
    width: string | null;
    overflow: string | null;
  };
}

export type unlockBackgroundCallback = () => void;

const body = document.getElementsByTagName('body')[0];
const html = document.getElementsByTagName('html')[0];

function lock(): LastKnownState {
  const doc = document.documentElement;
  const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  const lastKnownState: LastKnownState = {
    body: {
      position: body.style.position,
      height: body.style.height,
      width: body.style.width,
      overflow: body.style.overflow,
      scrollPosition: top || 0,
    },
    html: {
      position: html.style.position,
      height: html.style.height,
      width: html.style.width,
      overflow: html.style.overflow,
    },
  };
  body.style.position = 'relative';
  body.style.height = '100%';
  body.style.width = '100%';
  body.style.overflow = 'hidden';
  html.style.position = 'relative';
  html.style.height = '100%';
  html.style.width = '100%';
  html.style.overflow = 'hidden';
  return lastKnownState;
}

function unlock(lastKnownState: LastKnownState): void {
  if (!lastKnownState) {
    body.style.position = 'relative';
    body.style.height = 'auto';
    body.style.width = 'auto';
    body.style.overflow = 'auto';
    html.style.position = 'relative';
    html.style.height = 'auto';
    html.style.width = 'auto';
    html.style.overflow = 'hidden';
  } else {
    body.style.position = lastKnownState.body.position || '';
    body.style.height = lastKnownState.body.height || '';
    body.style.width = lastKnownState.body.width || '';
    body.style.overflow = lastKnownState.body.overflow || '';
    if (lastKnownState.body.scrollPosition > 0) {
      setTimeout(() => {
        if (!!window.scrollTo) {
          window.scrollTo(0, lastKnownState.body.scrollPosition);
        } else {
          window.scroll(0, lastKnownState.body.scrollPosition);
        }
        // tslint:disable-next-line: align
      }, 0);
    }
    html.style.position = lastKnownState.html.overflow || '';
    html.style.height = lastKnownState.html.overflow || '';
    html.style.width = lastKnownState.html.overflow || '';
    html.style.overflow = lastKnownState.html.overflow || '';
  }
}

function willUnmount(lastKnownState: LastKnownState, componentWillUnmount?: () => void): () => void {
  return () => {
    unlock(lastKnownState);
    if (componentWillUnmount) {
      componentWillUnmount();
    }
  };
}

function generateUnlockFunction(lastKnownState: LastKnownState) {
  return () => {
    unlock(lastKnownState);
  };
}

/**
 * En funktion som inaktiverar scroll på HTML och body taggar,
 * Användbar när man behöver att visa någon modal, sida eller en popup som täcker hela viewporten och vill att scrollning sker bara i den här delen,
 * Denna funktion förändrar componentWillMount funktion om component är inte null för att kunna återställa HTML och body till deras föregående state när den angiven komponent plockas bort ur DOM
 * @param component en komponent - React komponent - som implmenenterar ComponentLifecycle interface
 * @returns en funktion som kan köras för att låsa upp scroll på HTML och body taggar om component inparameter är null
 */
export function lockBackgroundScroll(component?: ComponentLifecycle<{}, {}>): unlockBackgroundCallback | undefined {
  const lastKnownState: LastKnownState = lock();
  if (component) {
    component.componentWillUnmount = willUnmount(lastKnownState, component.componentWillUnmount);
  } else {
    return generateUnlockFunction(lastKnownState);
  }
  return;
}
