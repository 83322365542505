import * as React from 'react';
import { IconChevron } from '../icons';
import { FancyNavLink } from 'components/app';

export interface SiteSwitcherProps {
  readonly isDesktopMode: boolean;
  foereningUrl: string;
  foereningNamn: string;
  isPublicWebMode?: boolean;
}

interface SiteSwitcherState {
  readonly halfExpanded: boolean;
  readonly fullExpanded: boolean;
}

export class SiteSwitcher extends React.Component<SiteSwitcherProps, SiteSwitcherState> {
  constructor(props: SiteSwitcherProps) {
    super(props);
    this.getExpandedClass = this.getExpandedClass.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setExpandedState(false, false);
  }

  setExpandedState(halfState: boolean, fullState: boolean) {
    this.setState({
      halfExpanded: halfState,
      fullExpanded: fullState,
    });
  }

  halfExpand() {
    if (!this.state.halfExpanded && !this.state.fullExpanded) {
      this.setExpandedState(true, false);
    } else if (this.state.halfExpanded && !this.state.fullExpanded) {
      this.setExpandedState(false, false);
    } else if (!this.state.halfExpanded && this.state.fullExpanded) {
      this.setExpandedState(false, false);
    }
  }

  fullExpand() {
    if (this.state.halfExpanded && !this.state.fullExpanded) {
      this.setExpandedState(false, true);
    }

    setTimeout(() => window.open(this.props.foereningUrl, '_self'), 400);
  }

  getExpandedClass() {
    if (this.state.halfExpanded && !this.state.fullExpanded) {
      return 'SiteSwitcher SiteSwitcher--halfExpanded';
    }
    if (this.state.fullExpanded && !this.state.halfExpanded) {
      return 'SiteSwitcher SiteSwitcher--fullExpanded';
    }
    if (!this.state.fullExpanded && !this.state.halfExpanded) {
      return 'SiteSwitcher SiteSwitcher--Ejinloggad';
    }
    return 'SiteSwitcher';
  }

  getArrowDirection() {
    if (this.state.halfExpanded || this.state.fullExpanded) {
      return 'left';
    }
    return 'right';
  }
  render() {
    if (this.props.isDesktopMode) {
      return (
        <div className={this.getExpandedClass()}>
          <div
            onClick={() => this.fullExpand()}
            className={
              'SiteSwitcher__Header' +
              (this.state.halfExpanded ? ' SiteSwitcher__Header--visible' : ' SiteSwitcher__Header--hidden')
            }
          >
            <span className="Header__Subtitle">Gå till</span>
            {'F&S ' + this.props.foereningNamn}
          </div>
          <div className="SiteSwitcher__Arrow" onClick={() => this.halfExpand()}>
            <IconChevron direction={this.getArrowDirection()} />
          </div>
        </div>
      );
    } else if (this.props.isPublicWebMode) {
      return (
        <div className={this.getExpandedClass()}>
          <FancyNavLink to={this.props.foereningUrl} isTargetBlank={true}>
            {'F&S ' + this.props.foereningNamn}
          </FancyNavLink>
        </div>
      );
    } else {
      return null;
    }
  }
}
