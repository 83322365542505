import * as React from 'react';
import { IconChevron } from 'components/icons';
import { StatusBar, IconButton } from 'components/common';

import { SitecoreDictionary } from 'models';
import { CouldAddProduct } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface ProductProps {
  isInBasket: boolean;
  product: fos.api.Product;
  translate: TranslateFunction;
  foereningNamn: string;
  isAdmin: boolean;
  canAddProduct: (product: fos.api.Product) => CouldAddProduct;
  addProduct: (product: fos.api.Product) => void;
  removeProduct: (product: fos.api.Product) => void;
  goToProductDetails: (id: number, name: string, foereningNamn: string, customerType: number) => void;
}
export interface ProductItemState {
  statusMessageVisible: boolean;
  statusMessage: string;
  statusMessageClass: string;
}
export class Product extends React.Component<ProductProps, ProductItemState> {
  statusMessageTimeout: number | undefined;
  constructor(props: ProductProps) {
    super(props);
    this.state = {
      statusMessageVisible: false,
      statusMessage: this.props.translate(SitecoreDictionary.Shop.ProductAddedToBasket) as string,
      statusMessageClass: 'StatusMessage--Green',
    };
  }

  handleClick = () => {
    const canAddProduct = this.props.canAddProduct(this.props.product);
    if (this.props.isInBasket) {
      this.props.removeProduct(this.props.product);
      this.setState({
        ...this.state,
        statusMessageVisible: false,
        statusMessage: this.props.translate(SitecoreDictionary.Shop.ProductAddedToBasket) as string,
        statusMessageClass: 'StatusMessage--Green',
      });
    } else if (canAddProduct.canAdd) {
      this.props.addProduct(this.props.product);
      this.setState({
        ...this.state,
        statusMessageVisible: true,
        statusMessage: this.props.translate(SitecoreDictionary.Shop.ProductAddedToBasket) as string,
        statusMessageClass: 'StatusMessage--Green',
      });
    } else if (!!canAddProduct.message) {
      this.setState({
        ...this.state,
        statusMessageVisible: true,
        statusMessage: canAddProduct.message,
        statusMessageClass: 'StatusMessage--Black',
      });
    }
    this.setStatusMessageTimeout();
  };

  getIconButtonStyle = () => {
    if (this.props.isInBasket) {
      return '--Booked';
    } else {
      return '--Available';
    }
  };

  setStatusMessageTimeout = () => {
    this.statusMessageTimeout = window.setTimeout(
      () =>
        this.setState({
          ...this.state,
          statusMessageVisible: false,
        }),
      4500
    );
  };

  componentWillUnmount() {
    if (!!this.statusMessageTimeout) {
      clearTimeout(this.statusMessageTimeout);
    }
  }

  render() {
    const { product } = this.props;
    return (
      <div>
        {product.hasOwnProperty('NamnSitecore') && (
          <React.Fragment>
            <div className="List__Item">
              <div
                className="Item__ProductName"
                onClick={() =>
                  this.props.goToProductDetails(
                    product.Id,
                    product.NamnSitecore,
                    this.props.foereningNamn,
                    product.CustomerType.Id
                  )
                }
              >
                <p>{product.NamnSitecore}</p>
                <IconChevron direction="right" color="red" />
              </div>
              <div className="Item__Price">
                <span className="Price__Total">
                  {product.Pris}:-
                  {product.PaymentMethod === 'AutoGiro' && <span className="Price__PerMonth">/mån</span>}
                  {product.NrOfMonths > 1 && product.PaymentMethod !== 'AutoGiro' && (
                    <span className="Price__Compare-PerMonth">
                      ({Number((product.Pris / product.NrOfMonths).toFixed(0))}:- /mån)
                    </span>
                  )}
                </span>
              </div>

              <IconButton
                handleOnClick={this.handleClick}
                text={''}
                styleType={this.getIconButtonStyle()}
                visible={this.state.statusMessageVisible}
                cssClass="IconButton"
                isExecuting={false}
              />
            </div>
            <StatusBar
              visible={this.state.statusMessageVisible}
              message={this.state.statusMessage}
              cssClass={this.state.statusMessageClass}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
