import { RootState } from 'store';
import { connect } from 'react-redux';
import { ProductDetails, ProductDetailsDataProps, ProductDetailsActionProps } from './ProductDetails';
import {
  getIsExecuting,
  FetchCommandTypes,
} from 'store/global/fetch';
import { addProduct } from 'store/basket';
import { getTranslate } from 'store/global/dictionary';

const mapStateToProps = (state: RootState, props: any): ProductDetailsDataProps => {
  const foereningId = state.global.session.shopFoerening!;
  return {
    isDesktopMode: props.isDesktopMode,
    products: state.product.filteredProducts,
    productsInBasket: state.shoppingBasket.products,
    location: state.location,
    shopFoerening: foereningId,
    foereningar: state.foereningar.read.Foereningar,
    translate: getTranslate(state.locale),
    orderId: state.global.session.orderId!,
    isPendingOrderChange:
      getIsExecuting(state.global.fetch, '' + state.global.session.orderId, FetchCommandTypes.ADD_TO_ORDER) ||
      getIsExecuting(state.global.fetch, '' + state.global.session.orderId, FetchCommandTypes.REMOVE_FROM_ORDER),
  };
};

const mapDispatchToProps: ProductDetailsActionProps = {
  addProduct,
};

export const ProductDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
