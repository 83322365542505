import { ThunkAction } from './../rootReducer';
import { api } from 'api';
import { Dispatch } from 'store';
import { getMedlemskapOchKort, MedlemskapOchKortReadActions, MedlemskapOchKortReadActionType } from './read';
import { updateAvslutaAbonnemangStatus } from './write';
import {
  executingCommand,
  executedCommand,
  executingCommandFailed,
  FetchCommandTypes,
  fetching,
  fetched,
  fetchedFailed,
  FetchStatusAggregateTypes,
  FetchAction,
  initialFetch,
} from 'store/global/fetch';
import { getSubscriptionStatusMessage } from 'models';

export type Action = MedlemskapOchKortReadActions | FetchAction;

export const getAbonnemang = (foereningId: number): ThunkAction => dispatch => {
  dispatch(
    fetching(
      // It should be a real id if we use some Id to get the data from the api
      // But abonneman/Get has no parameters so we set it to -1
      -1,
      FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT
    )
  );

  return api.get<fos.api.Abonnemang>(`Abonnemang/Get/${foereningId}`).then(
    res => {
      if (!res.error) {
        dispatch(getMedlemskapOchKort(res.result));
        dispatch(fetched(-1, FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT, res.result));
      } else {
        dispatch(getMedlemskapOchKort());
        dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT));
      }
    },
    err => {
      dispatch(getMedlemskapOchKort());
      dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT));
    }
  );
};

export const resetMedlemskapOchKort = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: MedlemskapOchKortReadActionType.ClearMedlemskapOchKort,
  });
  dispatch(initialFetch(-1, FetchStatusAggregateTypes.MEDLEMSKAP_OCH_KORT));
};

export const avslutaAbonnemang = (payload: fos.api.AvslutaAbonnemangPayload): ThunkAction => (dispatch, getState) => {
  dispatch(executingCommand(payload.abonnemangId.toString(), FetchCommandTypes.AVSLUTA_MEDLEMSSKAP));
  const promise = api.post<fos.api.AvslutaAbonnemangStatus, fos.api.AvslutaAbonnemangPayload>(
    'Abonnemang/AvslutaAbonnemang/',
    payload,
    {
      muteErrorNotificationOnStatus: [400],
    }
  );
  promise.then(res => {
    // TODO Map status messages
    if (!res.error) {
      dispatch(
        updateAvslutaAbonnemangStatus(
          payload.abonnemangId,
          getSubscriptionStatusMessage(res.result.status),
          payload.abonnemangId.toString(),
          res.result.endDate
        )
      );
      dispatch(executedCommand(payload.abonnemangId.toString(), FetchCommandTypes.AVSLUTA_MEDLEMSSKAP));
    } else {
      if (res.result.http && res.result.http.response) {
        dispatch(
          updateAvslutaAbonnemangStatus(
            payload.abonnemangId,
            getSubscriptionStatusMessage(res.result.http.response.status),
            payload.abonnemangId.toString()
          )
        );
      } else {
        dispatch(
          updateAvslutaAbonnemangStatus(
            payload.abonnemangId,
            getSubscriptionStatusMessage('UNKNOWN_ERROR'),
            payload.abonnemangId.toString()
          )
        );
      }
      dispatch(executingCommandFailed(payload.abonnemangId.toString(), FetchCommandTypes.AVSLUTA_MEDLEMSSKAP));
    }
  });
  return promise;
};
