import * as React from 'react';
import { FacilityExceptionalOpenTimes } from './FacilityExceptionalOpenTimes';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface FacilityExceptionalOpenTimesIndicatorProps {
  message: string;
  translate: TranslateFunction;
}

interface FacilityExceptionalOpenTimesIndicatorState {
  showInfoOverlay?: boolean;
}

export class FacilityExceptionalOpenTimesIndicator extends React.Component<
  FacilityExceptionalOpenTimesIndicatorProps,
  FacilityExceptionalOpenTimesIndicatorState
> {
  constructor(props: FacilityExceptionalOpenTimesIndicatorProps) {
    super(props);
    this.state = {};
  }

  setIsDetailsVisible(isVisible: boolean, clickEvent?: React.MouseEvent<HTMLElement>) {
    if (clickEvent) {
      clickEvent.stopPropagation();
    }
    this.setState({
      showInfoOverlay: isVisible,
    });
  }

  render() {
    return (
      <div className="FacilityExceptionalOpenTimesIndicator">
        <div
          className="FacilityExceptionalOpenTimesIndicator__Content"
          onClick={e => this.setIsDetailsVisible(true, e)}
        >
          <div className="FacilityExceptionalOpenTimesIndicator__Icon">!</div>
          <div className="FacilityExceptionalOpenTimesIndicator__Text">
            <span>{this.props.translate(SitecoreDictionary.Facilities.ExceptionalOpenTimes)}</span>
          </div>
        </div>
        {this.state.showInfoOverlay && (
          <FacilityExceptionalOpenTimes
            message={this.props.message}
            close={() => this.setIsDetailsVisible(false)}
            translate={this.props.translate}
          />
        )}
      </div>
    );
  }
}
