import * as React from 'react';

export interface IconMenuFriskisProps {
  title?: string;
}

export class IconMenuFriskis extends React.Component<IconMenuFriskisProps, {}> {
  fillColour = '#262626';

  // TODO: Fix translation support for text
  render() {
    return (
      <svg width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g fill="none">
          <g fill={this.fillColour}>
            {/* tslint:disable */}
            <path d="M20 39.8C9.4 39.8 0.9 31.1 0.9 20.4 0.9 9.6 9.4 0.9 20 0.9 30.5 0.9 39 9.6 39 20.4 39 31.1 30.5 39.8 20 39.8M20 0C8.9 0 0 9.1 0 20.4 0 31.6 8.9 40.7 20 40.7 31 40.7 39.9 31.6 39.9 20.4 39.9 9.1 31 0 20 0" />
            <path d="M19.8 38.5C9.9 38.5 1.9 30.3 1.9 20.2 1.9 10.1 9.9 2 19.8 2 29.7 2 37.7 10.1 37.7 20.2 37.7 30.3 29.7 38.5 19.8 38.5M19.8 1.6C9.8 1.6 1.6 10 1.6 20.2 1.6 30.5 9.8 38.8 19.8 38.8 29.9 38.8 38 30.5 38 20.2 38 10 29.9 1.6 19.8 1.6" />
            <path d="M29.2 25.3C27.5 28.9 23.4 31.5 19.7 31.5 19.3 31.5 19 31.5 18.7 31.5 18.5 31.6 17.9 31.9 17.2 32.1 22.6 33.1 28 30.2 30.3 24.9 30.2 24.9 30 25 29.9 25.1 29.6 25.2 29.3 25.3 29.2 25.3" />
            <path d="M10.1 14.7C11.2 12.6 14.4 9 19.6 9 21.5 9 22.6 9.4 22.8 9.5L24.1 9C22.8 8.5 21.2 8.2 19.6 8.2 17.4 8.2 15.1 8.8 13.2 10.1 11.3 11.3 9.7 13.1 8.8 15.2 9 15.2 9.3 15 9.5 14.9 9.8 14.8 10 14.8 10.1 14.7" />
            <path d="M28.5 12.2L27.6 12.6C28.2 13.2 29.1 14.4 29.5 15.3L30.3 14.8C29.8 13.8 29 12.7 28.5 12.2" />
            <path d="M24.8 18.2C25 18.1 25.4 17.9 25.8 17.8 26.5 17.5 27 16.7 27 15.8 27 15.5 26.8 15 26.7 14.8 26.7 14.7 26.6 14.5 26.6 14.4 26.5 14.2 26.4 14 26.3 13.8 26.2 13.5 26.1 13.2 26.1 12.9 26.1 12.5 26.3 12.2 26.6 12.1 26.6 12.1 29.7 10.8 30.3 10.5L30.3 9.4C30.1 9.5 29.8 9.6 29.5 9.7 28.5 10.1 26.9 10.8 26.3 11.1 25.2 11.5 25 12.3 25 12.8 25 13 25.1 13.4 25.1 13.5 25.3 13.7 25.6 14.6 25.8 14.9 25.9 15.1 25.9 15.4 25.9 15.6 25.9 16.4 25.5 16.8 24.8 17.1 24.8 17.3 24.8 17.9 24.8 18.2" />
            <path d="M20.3 23.8C19.5 22.4 19.1 21.6 18.8 21 18.5 20.5 18.4 20.3 18.3 20.1 17.9 19.2 17.7 18.7 17.7 18.2 17.7 17.5 18.1 16.9 18.7 16.7L18.7 15.5C18.6 15.6 18.4 15.6 18.1 15.7 16.9 16.3 16.7 17.4 16.7 18.3 16.7 19.2 17 20.1 17.2 20.3 17.6 21.3 18.3 22.6 18.9 23.6 19.1 24.1 19.3 24.4 19.4 24.7 19.7 25.2 19.9 25.8 19.9 26.4 19.9 28.5 18.3 30.1 16.3 30.1 14.6 30.1 12.8 28.7 12.8 26.4 12.8 25.9 12.9 25.4 13.1 24.8 12.5 25.1 11.4 25.6 10.2 26 8.9 26.6 7.6 27.1 7.2 27.3 7.2 27.5 7.2 28.1 7.2 28.4 8.1 28.1 11.3 26.8 11.5 26.7L11.7 26.6 11.7 26.8C11.6 27.8 12.1 29 13 29.8 13.6 30.5 14.7 31.3 16.3 31.3 19 31.3 21 29.2 21 26.4 21 25.6 20.8 24.7 20.3 23.8" />
            <path d="M14.1 25.1L14.1 25.2C13.7 25.7 13.6 26.2 13.6 26.7 13.6 28.8 15.3 29.7 16.4 29.7 18.1 29.7 19.2 28.2 19.2 26.8 19.2 26 18.9 25.4 18.4 24.8L16.6 25.5C16.6 25.7 16.6 26.2 16.6 26.6 16.7 26.5 16.9 26.4 17.1 26.4 17.4 26.2 17.7 26.1 17.8 26.1L17.9 26 18 26.1C18.2 26.6 18.2 27.1 17.9 27.6 17.6 28.2 17 28.5 16.4 28.5 15.4 28.5 14.9 27.8 14.7 27.2 14.6 26.6 14.8 25.7 15.6 25.3 15.2 25 15 24.7 15 24.3 14.9 24 15 23.6 15.3 23.3 15.5 23 16 22.8 16.4 22.8 16.6 22.8 16.9 22.9 17.1 23.1 17.1 23 17.1 23 17.1 22.9 17.1 22.6 17.1 22.1 17.1 21.8 16.8 21.7 16.5 21.6 16.2 21.6 14.9 21.6 13.8 23 13.8 24.1 13.8 24.5 14 24.9 14.1 25L14.1 25.1Z" />
            <path d="M22.1 23.1L22 23.5 20.9 20.8C20.7 20.9 20.2 21.1 20 21.2 20.3 21.9 21.4 24.8 21.7 25.5 21.9 25.4 22.2 25.3 22.5 25.2L22.8 25.1C22.9 24.5 23.7 20.6 23.9 19.6 23.6 19.7 23 20 22.8 20 22.7 20.5 22.2 22.9 22.1 23.1" />
            <path d="M14.3 18.7C14.3 18.4 14.4 17.6 14.4 17.1 14.2 17.2 14 17.3 13.9 17.4 12.9 17.8 12.5 18.7 12.5 19.9 12.5 20 12.4 20.7 12.4 21.4 12.4 22 12.4 22.7 12.4 23.1 12.7 23 13.3 22.7 13.5 22.6 13.5 22.6 13.5 22.6 13.5 22.6 13.5 22.2 13.5 21.2 13.5 20.2 13.6 19.3 13.7 18.9 14.3 18.7" />
            <path d="M29.3 17.4L29.3 17.2C29.3 17.1 29.4 16.5 29.4 16.1L28.4 16.8C28.4 17.1 28.4 17.7 28.4 17.8L28.4 17.9 28 18 28 19.2C28 19.1 28.1 19.1 28.1 19.1L28.3 19 28.3 19.3C28.3 19.3 28.3 19.7 28.3 20.1 28.3 20.6 28.3 21.1 28.3 21.2 28.3 21.7 28.6 22.5 28.8 22.7L29.1 22.6C29.3 22.5 29.6 22.4 29.8 22.3 29.5 22.1 29.3 21.6 29.3 21.1L29.3 18.7 30.5 18.2 30.5 18.4C30.5 18.5 30.4 19.8 30.4 20.1 30.4 20.9 30.6 21.6 31 21.9 31.2 21.8 31.7 21.6 32 21.5 31.7 21.2 31.5 20.8 31.5 20.3 31.5 20.1 31.5 17.9 31.5 17.9L31.5 17.8 32.2 17.5 32.2 16.3 31.5 16.6 31.6 15.1C31.3 15.3 30.7 15.7 30.6 15.9 30.6 16.1 30.6 16.7 30.6 16.9L30.6 17 30.5 17C30.4 17 29.9 17.2 29.7 17.3L29.3 17.4Z" />
            <path d="M25 23.3C25.8 23.7 26.9 23.6 27.6 23.2L27.6 22.2C27.2 22.5 26.7 22.7 26.2 22.7 25.8 22.7 25.5 22.5 25.3 22.2L25.2 22 25.4 22C25.4 22 25.9 21.8 26.4 21.6 26.7 21.5 27.1 21.3 27.2 21.3L27.2 20.2C27.1 20.3 26.8 20.4 26.6 20.5 26 20.7 25.4 21 25.3 21L25 21.1 25.1 20.9C25.1 20.5 25.4 20.1 25.8 19.8 26.2 19.5 26.8 19.4 27.2 19.4L27.2 18.4C27.2 18.4 27.1 18.4 27 18.4 26.5 18.4 25.6 18.6 25 19.1 24.3 19.7 24 20.4 24 21.2 24 22.2 24.4 22.9 25 23.3" />
            <path d="M10.8 16.3L30 8.5 30 8C30 7.8 30 7.5 30 7.3L6.6 16.9C4.5 17.8 3.2 19.6 3.2 21.8 3.2 23 3.7 24.2 4.5 24.8 5.2 25.2 5.9 25.4 6.7 25.1L6.7 24.8C6.7 24.6 6.7 24.2 6.7 24 6.3 24.1 5.8 24.1 5.4 23.9 4.7 23.5 4.3 22.8 4.3 21.8 4.3 19.7 5.9 18.3 7.2 17.8 8.3 17.3 9.4 16.9 9.4 16.9L9.6 16.8 9.6 17C9.6 17.2 9.6 17.5 9.6 17.9 9.5 18.4 9.5 19.1 9.5 19.2L9.5 19.3 8.3 19.8C8.3 20 8.3 20.3 8.3 20.6 8.3 20.8 8.3 21 8.3 21.2L9.5 20.7 9.5 20.9C9.5 21 9.5 21.4 9.5 21.9 9.5 22.5 9.4 23.1 9.4 23.3 9.4 24 9.2 24.4 8.8 24.6 8.5 24.7 7.3 25.2 7 25.3L7 26.4 9.1 25.6C10.1 25.2 10.5 24.6 10.5 23.7 10.6 22.8 10.6 20.8 10.6 20.3L10.6 20.2 11.8 19.7 11.8 18.4 10.7 18.9 10.8 16.3Z" />
            <path d="M33.7 21C33.7 20.7 33.7 19.7 33.8 18.4 33.8 17.4 33.8 16.4 33.8 15.9 33.5 16 33.1 16.2 32.9 16.3 32.9 16.9 32.8 20.5 32.8 21.4 32.9 21.3 33 21.3 33.1 21.3 33.3 21.2 33.6 21.1 33.7 21" />
            <path d="M34 14.9C34 14.6 33.7 14.3 33.4 14.3 33.1 14.3 32.8 14.6 32.8 14.9 32.8 15.2 33.1 15.5 33.4 15.5 33.7 15.5 34 15.2 34 14.9" />
            <path d="M14.8 21C15.1 20.9 15.5 20.8 15.9 20.7 15.9 20.6 15.9 20.3 15.9 19.9 15.9 18.9 15.9 17.3 15.9 16.7L14.9 17.1 14.8 21Z" />
            <path d="M15.4 16.3C15.7 16.3 16 16 16 15.7 16 15.4 15.7 15.1 15.4 15.1 15.1 15.1 14.8 15.4 14.8 15.7 14.8 16 15.1 16.3 15.4 16.3" />
            <path d="M24.2 18.5L24.2 13.5 23.3 13.9 23.2 18.9C23.4 18.8 23.7 18.7 23.9 18.6L24.2 18.5Z" />
            <path d="M24.4 12.4C24.4 12.1 24.1 11.8 23.8 11.8 23.5 11.8 23.2 12.1 23.2 12.4 23.2 12.8 23.5 13.1 23.8 13.1 24.1 13.1 24.4 12.8 24.4 12.4" />
            <path d="M20.3 16.6L20.6 16.5C21.2 16.3 21.5 16.1 21.5 15.4 21.5 15.4 21.5 15.1 21.5 14.8L22.6 14.4C22.6 14.7 22.6 15.1 22.6 15.1 22.6 15.7 22.6 16.3 21.9 16.7 21.9 16.7 22.6 16.8 22.6 17.7 22.6 18.1 22.6 19.4 22.6 19.4 22.3 19.5 21.9 19.6 21.6 19.8 21.6 19.3 21.6 18.3 21.6 18.1 21.6 17.9 21.6 17.7 21.5 17.6 21.4 17.5 21.3 17.4 21.1 17.4 20.9 17.4 20.6 17.5 20.4 17.7L20.3 17.7 20.3 17.8C20.2 18.3 20.2 19.9 20.2 20.3 20 20.4 19.5 20.6 19.2 20.7 19.2 19.5 19.3 14.7 19.3 13.9L20.3 13.5 20.3 16.6Z" />
            <path d="M35.8 18.1C35.6 17.6 35.4 17.2 35.4 16.8 35.4 16.3 35.6 16 35.8 15.9L35.8 14.7C35.7 14.7 35.6 14.8 35.5 14.8 35 15.1 34.5 15.6 34.5 16.7 34.5 17.5 34.7 18.1 35 18.7 35.2 19.1 35.3 19.5 35.3 19.9 35.3 20.4 35 21.1 34.5 21.3 33.8 21.6 22.8 26 21.7 26.5 21.7 26.7 21.7 27.2 21.6 27.6 23.3 26.9 33.4 22.9 34.8 22.3 35.9 21.9 36.4 21.1 36.4 20.1 36.4 20.1 36.4 20.1 36.4 20.1 36.4 19.3 36.1 18.7 35.8 18.1" />
          </g>
        </g>
      </svg>
    );
  }
}
