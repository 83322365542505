interface UpdateAvslutaAbonnemangStatusAction {
  type: MedlemskapOchKortWriteActionType.UpdateAvslutaAbonnemangStatusAction;
  abonnemangId: number;
  message: string;
  key: string;
  endDate?: Date;
}

export type MedlemskapOchKortWriteAction = UpdateAvslutaAbonnemangStatusAction;

export enum MedlemskapOchKortWriteActionType {
  UpdateAvslutaAbonnemangStatusAction = 'UPDATE_AVSLUTA_ABONNEMANG_UI',
}

export const updateAvslutaAbonnemangStatus = (
  abonnemangId: number,
  message: string,
  key: string,
  endDate?: Date
): UpdateAvslutaAbonnemangStatusAction => ({
  type: MedlemskapOchKortWriteActionType.UpdateAvslutaAbonnemangStatusAction,
  abonnemangId: abonnemangId,
  message: message,
  key: key,
  endDate: endDate,
});
