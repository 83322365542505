import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { ShopWrapperExternalDataProps, ShopWrapperDataProps, ShopWrapper, ShopWrapperActionProps } from './ShopWrapper';
import { getIsAdminWebMode } from 'store/location';
import { getProducts } from 'store/shop';
import {
  getIsFetching,
  getIsFetched,
  FetchStatusAggregateTypes,
  getIsExecuting,
  FetchCommandTypes,
  getIsExecuted,
} from 'store/global/fetch';
import { setAdminError } from 'store/global/errors';
import { setOrderId } from 'store/global/session';
import { createOrder, getOrder } from './Order/Order';

const mapStateToProps = (state: RootState, props: ShopWrapperExternalDataProps): ShopWrapperDataProps => {
  const shopFoerening = state.global.session.shopFoerening;
  const foerening = !shopFoerening
    ? undefined
    : state.foereningar.read.Foereningar.find(f => f.Id.toString() === shopFoerening.toString());
  return {
    children: props.children,
    isDesktopMode: props.isDesktopMode,
    foereningAktiv: !!foerening
      ? getIsAdminWebMode(state.location)
        ? !foerening.AerButikAktiv
        : foerening.AerButikAktiv
      : undefined,
    shopFoerening: shopFoerening,
    isInitialized: state.initialize.read.initialized,
    isFetchingProducts: !!shopFoerening
      ? getIsFetching(state.global.fetch, shopFoerening, FetchStatusAggregateTypes.GET_PRODUCTS)
      : false,
    isProductsFetched: !!shopFoerening
      ? getIsFetched(state.global.fetch, shopFoerening, FetchStatusAggregateTypes.GET_PRODUCTS)
      : false,
    isAdmin: getIsAdminWebMode(state.location),

    isFetchingOrder:
      !!shopFoerening && !!state.global.session.orderId
        ? getIsFetching(state.global.fetch, state.global.session.orderId, FetchStatusAggregateTypes.GET_ORDER) ||
          getIsExecuting(state.global.fetch, '' + shopFoerening, FetchCommandTypes.CREATE_ORDER)
        : false,
    isOrderFetched:
      !!shopFoerening && !!state.global.session.orderId
        ? getIsFetched(state.global.fetch, state.global.session.orderId, FetchStatusAggregateTypes.GET_ORDER) ||
          getIsExecuted(state.global.fetch, '' + shopFoerening, FetchCommandTypes.CREATE_ORDER)
        : false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ShopWrapperActionProps => ({
  fetchProducts: (foereningId: number, isAdmin: boolean) => {
    const nonAdmin = dispatch<Promise<Array<fos.api.Product>>>(getProducts(foereningId));
    if (isAdmin) {
      nonAdmin.catch((e: Error & { __handled__: boolean }) => {
        e.__handled__ = true;
        dispatch(setAdminError(e.message));
      });
    }
  },
  setOrderId: (orderId: number) => dispatch(setOrderId(orderId)),
  createOrder: (foereningId: number, products: fos.api.Product[]) => createOrder(foereningId, products),
  getOrder: (foereningId: number, orderId: number) => getOrder(foereningId, orderId),
});

export const ShopWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(ShopWrapper);
