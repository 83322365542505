import { Day } from 'models';

export enum PassActionType {
  Day_UpdateDay = 'DAY_UPDATE_DAY',
  UpdateBokaPassStatus = 'UPDATE_BOKA_PASS_UI',
}

export interface DayUpdateDayReadAction {
  type: PassActionType.Day_UpdateDay;
  days: Array<Day<fos.api.Pass>>;
}

export interface UpdateBokaPassStatusAction {
  type: PassActionType.UpdateBokaPassStatus;
  passId: string | number;
  message: string;
  key: string;
}
export const updateDayData = (passData: Array<Day<fos.api.Pass>>): DayUpdateDayReadAction => ({
  type: PassActionType.Day_UpdateDay,
  days: passData,
});

export const updateBokaPassStatus = (
  passId: string | number,
  message: string,
  key: string
): UpdateBokaPassStatusAction => ({
  type: PassActionType.UpdateBokaPassStatus,
  passId: passId,
  message: message,
  key: key,
});

export type PassAction = DayUpdateDayReadAction | UpdateBokaPassStatusAction;
