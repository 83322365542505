import * as React from 'react';
import { BookingDayItem } from './day';

import { DayViewObject } from 'models/Day';
import { TranslateFunction } from 'react-localize-redux';

interface BookingListDataProps {
  days: DayViewObject<fos.api.Bokning>;
  translate: TranslateFunction;
}

interface BookingListActionProps {
  updateBookingDayExpanded: (dayName: string, expanded: boolean) => void;
}

type BookingListProps = Readonly<BookingListDataProps & BookingListActionProps>;

export const BookingList: React.SFC<BookingListProps> = props => {
  const { days, translate, updateBookingDayExpanded } = props;
  const dayKeys = Object.keys(days);
  return (
    <div className="PassList">
      {dayKeys.map(d => (
        <BookingDayItem
          days={days}
          key={days[d].key}
          day={days[d]}
          translate={translate}
          updateBookingDayExpanded={updateBookingDayExpanded}
        />
      ))}
    </div>
  );
};
