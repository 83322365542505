import * as React from 'react';
import { MainInput, MainButton, AutogiroInput, StatusBar, SelectInput, Spinner } from 'components/common';
import { FormContainer } from '../formContainer';
import { TermsCheckbox } from '../TermsCheckbox';
import { PaymentPurchaseFailMessage } from '..';

import { SitecoreDictionary, PaymentMethodType } from 'models';
import { addCoupon } from 'components/shop/Order/Order';
import { EntityStatus } from 'store/global/fetch';
import { TranslateFunction } from 'react-localize-redux';
import { SelectInputOption } from 'components/common/SelectInput';

export interface KnownUserBuyingFormInternalDataProps {
  isClearingNumberValid?: boolean;
  isAccountNumberValid?: boolean;
  clearingNumberValue: string;
  accountNumberValue: string;
  phoneNumber?: string;
  shouldShowCouponMessage?: boolean;
  getPaymentMethodsStatus?: EntityStatus;
  paymentMethods?: fos.api.PaymentMethod[];
  errorOnPurchase?: string;
  errorsOnCreatePayment?: string[];
  disableSubmit: boolean;
  shouldShowAutogiroInfo: boolean;
  orderId: number;
}
export interface KnownUserBuyingFormExternalDataProps {
  isDesktopMode: boolean;
  translate: TranslateFunction;
  foereningId?: number;
  isAuthenticated: boolean;
  isAdmin: boolean;
}

export type KnownUserBuyingFormDataProps = KnownUserBuyingFormExternalDataProps & KnownUserBuyingFormInternalDataProps;

export interface KnownUserBuyingFormActionProps {
  buy: () => void;
  onClearingNumberChange: (newValue: string) => void;
  onAccountNumberChange: (newValue: string) => void;
  showCouponMessage: (showMessage: boolean) => void;
  getPaymentMethods: (foereningId: number, orderId: number) => void;
  updatePaymentMethod: (paymentMethodId: number) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  clearCreatePaymentErrors: () => void;
  clearPurchaseErrors: () => void;
  setSiteSwitcherToVisible: () => void;
  setSiteSwitcherToHidden: () => void;
}

interface KnownUserBuyingFormState {
  termsAccepted: boolean;
  code: string;
  paymentMethodId?: number;
}

const initialState: KnownUserBuyingFormState = {
  termsAccepted: false,
  code: '',
};

type KnownUserBuyingFormProps = KnownUserBuyingFormActionProps & KnownUserBuyingFormDataProps;

export class KnownUserBuyingForm extends React.Component<KnownUserBuyingFormProps, KnownUserBuyingFormState> {
  constructor(props: KnownUserBuyingFormProps) {
    super(props);
    this.state = initialState;
    this.props.showCouponMessage(false);
  }

  componentDidUpdate(prevProps: KnownUserBuyingFormProps) {
    if (!!this.props.foereningId && !!this.props.orderId) {
      if (!this.props.getPaymentMethodsStatus || this.props.getPaymentMethodsStatus === EntityStatus.INITIAL) {
        this.props.getPaymentMethods(this.props.foereningId, this.props.orderId);
      }
    }
  }

  componentDidMount() {
    if (!!this.props.foereningId && !!this.props.orderId) {
      if (!this.props.getPaymentMethodsStatus || this.props.getPaymentMethodsStatus === EntityStatus.INITIAL) {
        this.props.getPaymentMethods(this.props.foereningId, this.props.orderId);
      }
    }
  }

  render() {
    if (this.props.getPaymentMethodsStatus !== EntityStatus.SUCCESS) {
      return <Spinner alignment="center" />;
    }
    return (
      <FormContainer title={this.props.translate(SitecoreDictionary.Cart.YourProfile) as string}>
        <form className="KnownUserBuyingForm" name="KnownUserBuyingForm" onSubmit={event => this.submit(event)}>
          {
            /* KAMPANJ */

            <React.Fragment>
              <h4 className="ShoppingBasketForm__Title">
                {this.props.translate(SitecoreDictionary.Cart.CouponCodeTitle)}
              </h4>
              <div className="MemberProfileForm__KampanjInfo">
                <MainInput
                  required={false}
                  shouldValidate={false}
                  placeholder={this.props.translate(SitecoreDictionary.Cart.CouponCodePlaceholder) as string}
                  label={this.props.translate(SitecoreDictionary.Cart.CouponCodeLabel) as string}
                  name="kampanj"
                  alignLabel="left"
                  align="left"
                  labelColor="black"
                  type="text"
                  border="grey"
                  onValueChange={value => this.setCampaignCode(value as string)}
                  autocomplete="off"
                />
                <MainButton
                  color="red"
                  className="Button Button Button--primary Button--solid"
                  handleOnClick={() => this.handleCampaignChange(this.state.code)}
                  title={this.props.translate(SitecoreDictionary.Cart.CouponCodeButtonText) as string}
                />
              </div>
              <StatusBar
                visible={!!this.props.shouldShowCouponMessage ? this.props.shouldShowCouponMessage : false}
                message={this.props.translate(SitecoreDictionary.Cart.CouponCodeInvalid)}
                cssClass="StatusMessage--up"
                closeBtn={true}
                handleClick={() => this.props.showCouponMessage(false)}
              />
            </React.Fragment>
          }
          {
            /* AUTOGIRO */
            this.props.shouldShowAutogiroInfo && this.props.isAuthenticated && (
              <div>
                <AutogiroInput
                  required={true}
                  shouldValidate={true}
                  className="KnownUserBuyingForm__AutoGiro__Input"
                  isClearingNumberValid={this.props.isClearingNumberValid}
                  isAccountNumberValid={this.props.isAccountNumberValid}
                  clearingNumberValue={this.props.clearingNumberValue}
                  accountNumberValue={this.props.accountNumberValue}
                  onClearingNumberChange={this.props.onClearingNumberChange}
                  onAccountNumberChange={this.props.onAccountNumberChange}
                  translate={this.props.translate}
                />
              </div>
            )
          }
          {
            /* BETALSÄTT */
            this.props.getPaymentMethodsStatus === EntityStatus.SUCCESS && this.props.isAuthenticated && (
              <div>
                <h4 className="ShoppingBasketForm__Title">
                  {this.props.translate(SitecoreDictionary.Cart.PaymentMethodsTitle)}
                </h4>
                {this.props.shouldShowAutogiroInfo && (
                  <p>{this.props.translate(SitecoreDictionary.Cart.PaymentMethodsAutogiroExtraInfo)}</p>
                )}
                <SelectInput
                  align="center"
                  alignLabel="left"
                  label={this.props.translate(SitecoreDictionary.Cart.PickPaymentMethod) as string}
                  labelColor="black"
                  name="experience"
                  value={!!this.state.paymentMethodId ? this.state.paymentMethodId : -1}
                  shouldValidate={true}
                  isValid={!!this.state.paymentMethodId && this.state.paymentMethodId > 0}
                  options={this.mapPaymentMethods()}
                  onValueChange={value => this.onChange(parseInt(value, 10))}
                  theme=""
                  disabled={false}
                  tabindex={0}
                />
                {this.getExtraInfo()}
              </div>
            )
          }
          {this.props.errorOnPurchase && this.props.isAuthenticated && (
            <PaymentPurchaseFailMessage msg={this.props.errorOnPurchase} />
          )}
          {this.props.isAuthenticated && (
            <TermsCheckbox
              isChecked={this.state.termsAccepted}
              onValueChange={newValue => this.updateTermsAccepted(newValue as boolean)}
              isDesktopMode={this.props.isDesktopMode}
              translate={this.props.translate}
            />
          )}
          <MainButton
            title={this.props.translate(SitecoreDictionary.Cart.ContinueToPayment) as string}
            className="KnownUserBuyingForm__SubmitButton"
            color="red"
            buttonStyle="solid"
            type="submit"
            disabled={
              this.props.isAuthenticated &&
              (this.props.disableSubmit ||
                !this.state.termsAccepted ||
                // Submit will be disabled if the user should enter autogiro info
                (this.props.shouldShowAutogiroInfo &&
                  (!this.props.isAccountNumberValid || !this.props.isClearingNumberValid)) ||
                !this.state.paymentMethodId ||
                !this.isPaymentMethodValid(this.state.paymentMethodId, this.props.paymentMethods))
            }
            handleOnClick={() => {
              if (this.props.isAuthenticated) {
                this.props.clearCreatePaymentErrors();
                this.props.buy();
              } else {
                this.props.setSiteSwitcherToVisible();
              }
            }}
          />
          {
            <StatusBar
              cssClass="StatusMessage--BlackRoundedCorners--up"
              visible={!!this.props.errorsOnCreatePayment && this.props.errorsOnCreatePayment.length > 0}
              closeBtn={true}
              handleClick={this.props.clearCreatePaymentErrors}
              message={
                !!this.props.errorsOnCreatePayment
                  ? this.props.errorsOnCreatePayment.map((e, i) => {
                      const message = this.props.translate(
                        e in SitecoreDictionary.PaymentErrorMessages.CreatePaymentErrorMessages
                          ? (SitecoreDictionary.PaymentErrorMessages.CreatePaymentErrorMessages as any)[e]
                          : SitecoreDictionary.PaymentErrorMessages.GeneralError
                      );

                      return i !== this.props.errorsOnCreatePayment!.length - 1 ? (
                        <p key={e + i}>{message}</p>
                      ) : (
                        message
                      );
                    })
                  : ''
              }
            />
          }
        </form>
      </FormContainer>
    );
  }
  onChange = (value: number): void => {
    this.setState({
      ...this.state,
      paymentMethodId: value,
    });
    this.props.clearPurchaseErrors();
    this.props.updatePaymentMethod(value);
  };
  mapPaymentMethods = (): SelectInputOption[] => {
    const arr: SelectInputOption[] = [
      {
        value: -1,
        label: this.props.translate(SitecoreDictionary.Cart.PaymentMethodPlaceholder) as string,
        isDisabled: true,
      },
    ];
    if (!this.props.paymentMethods || this.props.paymentMethods.length === 0) {
      return arr;
    }
    return arr.concat(
      this.props.paymentMethods.map<SelectInputOption>(p => {
        return {
          value: p.Id,
          label: this.getPaymentMethodLabel(p.type),
        };
      })
    );
  };

  getExtraInfo = (): JSX.Element => {
    const paymentMethod = this.getPaymentMethod(this.state.paymentMethodId, this.props.paymentMethods);
    if (paymentMethod) {
      switch (paymentMethod.type) {
        case PaymentMethodType.SWISH:
          return (
            <div>
              <MainInput
                required={true}
                shouldValidate={true}
                placeholder={this.props.translate(SitecoreDictionary.Cart.PhoneNumberPlaceholder) as string}
                label={this.props.translate(SitecoreDictionary.Cart.PhoneNumberLabel) as string}
                name="Swish"
                alignLabel="left"
                align="left"
                labelColor="black"
                isValid={this.isInputCorrectlyFormatted(this.props.phoneNumber ? this.props.phoneNumber : '')}
                value={this.props.phoneNumber}
                type="tel"
                border="grey"
                onValueChange={value => this.props.updatePhoneNumber(value as string)}
                autocomplete="off"
              />
            </div>
          );
        default:
          return <React.Fragment />;
      }
    } else {
      return <React.Fragment />;
    }
  };

  getPaymentMethodLabel = (type: PaymentMethodType): string => {
    switch (type) {
      case PaymentMethodType.BANK:
        return this.props.translate(SitecoreDictionary.Cart.PaymentMethodBank) as string;
      case PaymentMethodType.CARD:
        return this.props.translate(SitecoreDictionary.Cart.PaymentMethodCard) as string;
      case PaymentMethodType.SWISH:
        return this.props.translate(SitecoreDictionary.Cart.PaymentMethodSwish) as string;
      default:
        return 'Okänt';
    }
  };

  setCampaignCode = (code: string): void => {
    this.setState({
      ...this.state,
      code: code,
    });
  };

  // Not supported right now
  // private updatePaymentMethod(newValue: string) {
  //   this.setState({
  //     ...this.state,
  //     paymentMethod: newValue
  //   });
  // }

  updateTermsAccepted = (newValue: boolean) => {
    this.setState({
      ...this.state,
      termsAccepted: newValue,
    });
  };

  isInputCorrectlyFormatted(value: string): boolean {
    var regEx = /^[0-9 ()+-]+$/;
    return !!value && regEx.test(value) && value.length > 8;
  }

  getPaymentMethod(paymentMethodId: number | undefined, paymentMethods: fos.api.PaymentMethod[] | undefined) {
    if (!paymentMethodId || !paymentMethods) {
      return undefined;
    }
    const paymentMethod = paymentMethods.find(p => '' + p.Id === '' + paymentMethodId);
    if (!paymentMethod) {
      return undefined;
    }
    return paymentMethod;
  }
  isPaymentMethodValid(paymentMethodId: number | undefined, paymentMethods: fos.api.PaymentMethod[] | undefined) {
    const paymentMethod = this.getPaymentMethod(paymentMethodId, paymentMethods);
    if (paymentMethod) {
      switch (paymentMethod.type) {
        case PaymentMethodType.SWISH:
          return this.isInputCorrectlyFormatted(this.props.phoneNumber ? this.props.phoneNumber : '');
        case PaymentMethodType.CARD:
          return true;
        case PaymentMethodType.BANK:
          return true;
        default:
          return false;
      }
    }
    return false;
  }

  containsOnlyNumber = (value: string) => {
    return /^\d+$/.test(value);
  };

  handleCampaignChange = (code: string) => {
    if (!this.props.foereningId || !this.props.orderId) {
      return;
    }

    addCoupon(this.props.foereningId, this.props.orderId, code);
  };

  submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
}
