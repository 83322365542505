import { Reducer } from 'redux';
import { Action } from 'store';
import { changePasswordActionType as actionTypes } from './changePasswordActions';

export interface ChangePasswordState {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  isCurrentPasswordValid: boolean;
  isNewPasswordValid: boolean;
  isRepeatNewPasswordValid: boolean;
  isFormValid: boolean;
}

const initialState: ChangePasswordState = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
  isCurrentPasswordValid: false,
  isNewPasswordValid: false,
  isRepeatNewPasswordValid: false,
  isFormValid: false,
};

export const changePasswordReducer: Reducer<ChangePasswordState> = (
  state: ChangePasswordState = initialState,
  action: Action
): ChangePasswordState => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_PASSWORD_VALUE:
      return {
        ...state,
        currentPassword: action.newValue,
      };
    case actionTypes.UPDATE_FORM_VALIDITY:
      return {
        ...state,
        isFormValid: action.isValid,
      };
    case actionTypes.UPDATE_NEW_PASSWORD_VALIDITY:
      return {
        ...state,
        isNewPasswordValid: action.isValid,
      };
    case actionTypes.UPDATE_REPEAT_NEW_PASSWORD_VALIDITY:
      return {
        ...state,
        isRepeatNewPasswordValid: action.isValid,
      };
    case actionTypes.UPDATE_CURRENT_PASSWORD_VALIDITY:
      return {
        ...state,
        isCurrentPasswordValid: action.isValid,
      };
    case actionTypes.UPDATE_NEW_PASSWORD_VALUE:
      return {
        ...state,
        newPassword: action.newValue,
      };
    case actionTypes.UPDATE_REPEAT_NEW_PASSWORD_VALUE:
      return {
        ...state,
        repeatNewPassword: action.newValue,
      };
    case actionTypes.CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
