export enum PRODUCT_ERROR {
  UNKNOWN_ERROR,
  NOT_FOUND,
}

export enum Affaerssystem {
  BRP = 1,
  PastellData = 2,
}

export enum ProductTypes {
  Medlemskap,
  Traeningskort,
  Klippkort,
  Traeningskort_Dag,
  Traeningskort_Pensionar,
  Traeningskort_Student,
  Traeningskort_Tonaring,
  Nationellt_Traeningskort,
  Gratis_Nationellt_Traeningskort,
  Tvaafoereningskort,
}

export type ProductWhos = 'Pensionar' | 'Student' | 'Tonaring' | 'Vuxen';
export type ProductWhens = 'Dag' | 'DygnetRunt';
export type ProductHows = 'MANUAL_DEBIT' | 'RECURRING_CARD_PAYMENT' | 'DIRECT_DEBIT_SE' | 'DIRECT_DEBIT_NO';

export enum ShopFilterCategoryName {
  ProductWho = 'ProductWho',
  ProductWhere = 'ProductWhere',
  ProductWhen = 'ProductWhen',
  ProductHow = 'ProductHow',
}

export interface ShopFilter {
  ProductWho: string[];
  ProductWhere: string[];
  ProductWhen: string[];
  ProductHow: string[];
}

export interface FilterCategory {
  SitecoreId: string;
  Name: string;
  Description: string;
  Type: string;
  Children: FilterChoice[];
}

export interface FilterChoice {
  SitecoreId: string;
  Name: string;
  Description: string;
  Type: string;
  isSelected: boolean;
}

export type ShopFilterCategoryNames = 'ProductWho' | 'ProductWhere' | 'ProductWhen' | 'ProductHow';
