import * as React from 'react';
import icon from '../../static/assets/friskis-logo--white.svg';

export interface LogotypProps {
  className?: string;
}

export const Logotyp: React.SFC<LogotypProps> = (props: LogotypProps) => (
  <div className={'Logotyp ' + (props.className ? props.className : '')}>
    <img src={icon} alt={'Logotyp'} />
  </div>
);
