import { api } from 'api';
import { Dispatch, Action, RootState } from 'store';
import { PassFilterFavoritfilter, passFilterSetFavoritfilter, passFilterCreateFavoritfilter } from '.';
import { passFilterDeleteFavoritfilter } from './passFilterDispatches';

export const getFavouriteFilters = (anvaendareId: number) => (dispatch: Dispatch<Action>) => {
  const promise = api.get<PassFilterFavoritfilter[]>(`PassFilter/GetPassFilter?anvaendareId=${anvaendareId}`, {
    muteErrorNotificationOnStatus: [406],
  });
  promise.then(result => {
    if (!result.error) {
      let savedFilters: PassFilterFavoritfilter[] = result.result;
      dispatch(passFilterSetFavoritfilter(savedFilters));
    }
  });
  return promise;
};

export const saveFavouriteFilter = (filter: PassFilterFavoritfilter) => (
  dispatch: Dispatch<Action>,
  getState: () => RootState
) => {
  const promise = api.post<PassFilterFavoritfilter, PassFilterFavoritfilter>('PassFilter/AddPassFilter', filter, {
    muteErrorNotificationOnStatus: [406],
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(passFilterCreateFavoritfilter(result.result));
    }
  });
  return promise;
};

export const deleteFavouriteFilter = (filter: PassFilterFavoritfilter) => (dispatch: Dispatch<Action>) => {
  let favDelFilter = { PassFilter: filter.FilterName, UserId: filter.UserId };
  const promise = api.post<boolean>('PassFilter/DeletePassFilter', favDelFilter, {
    muteErrorNotificationOnStatus: [406],
  });
  promise.then(result => {
    if (!result.error && result.result) {
      dispatch(passFilterDeleteFavoritfilter(filter.FilterId));
    }
  });
  return promise;
};