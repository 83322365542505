export enum PaymentReadActionTypes {
  PaymentSucceded = 'PAYMENT_SUCCEDED',
  SignatureCases = 'AUTOGIRO_URL',
  PaymentMethods = 'PAYMENT_METHODS',
  Receipt = 'RECEIPT',
  ReceiptInfos = 'RECEIPT_INFOS',
  OrderId = 'ORDER_ID',
}

export interface GetPaymentSuccededAction {
  type: PaymentReadActionTypes.PaymentSucceded;
  paymentSucceded: boolean;
}

export interface SetSignatureCasesAction {
  type: PaymentReadActionTypes.SignatureCases;
  value: fos.api.SignatureCase[];
}

export interface SetPaymentMethodsAction {
  type: PaymentReadActionTypes.PaymentMethods;
  value: fos.api.PaymentMethod[];
}

export interface SetReceiptAction {
  type: PaymentReadActionTypes.Receipt;
  value: fos.api.Receipt;
}
export interface SetReceiptInfosAction {
  type: PaymentReadActionTypes.ReceiptInfos;
  value: fos.api.ReceiptInfo[];
}

export interface SetOrderIdAction {
  type: PaymentReadActionTypes.OrderId;
  value: number;
}

export type PaymentReadActions =
  | GetPaymentSuccededAction
  | SetSignatureCasesAction
  | SetPaymentMethodsAction
  | SetReceiptAction
  | SetReceiptInfosAction
  | SetOrderIdAction;

export const getPaymentSuccededAction = (): GetPaymentSuccededAction => ({
  type: PaymentReadActionTypes.PaymentSucceded,
  paymentSucceded: true,
});

export const setSignatureCasesAction = (signatureCases: fos.api.SignatureCase[]): SetSignatureCasesAction => ({
  type: PaymentReadActionTypes.SignatureCases,
  value: signatureCases,
});

export const setPaymentMethodsAction = (paymentMethods: fos.api.PaymentMethod[]): SetPaymentMethodsAction => ({
  type: PaymentReadActionTypes.PaymentMethods,
  value: paymentMethods,
});

export const setReceiptAction = (receipt: fos.api.Receipt): SetReceiptAction => ({
  type: PaymentReadActionTypes.Receipt,
  value: receipt,
});

export const setReceiptInfosAction = (receiptInfos: fos.api.ReceiptInfo[]): SetReceiptInfosAction => ({
  type: PaymentReadActionTypes.ReceiptInfos,
  value: receiptInfos,
});

export const setOrderIdAction = (orderId: number): SetOrderIdAction => ({
  type: PaymentReadActionTypes.OrderId,
  value: orderId,
});
