export enum SitecoreActionType {
  DisableBokaPass = 'DISABLE_BOKA_PASS',
  AllowBokaPass = 'ALLOW_BOKA_PASS',
}

interface AllowBokaPassAction {
  type: SitecoreActionType.AllowBokaPass;
}

interface DisableBokaPassAction {
  type: SitecoreActionType.DisableBokaPass;
}

export const disableBokaPass = (): DisableBokaPassAction => ({
  type: SitecoreActionType.DisableBokaPass,
});

export const allowBokaPass = (): AllowBokaPassAction => ({
  type: SitecoreActionType.AllowBokaPass,
});

export type SitecoreAction = AllowBokaPassAction | DisableBokaPassAction;
