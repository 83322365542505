import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { MainMenuActionProps, MainMenuDataProps, MainMenu } from './MainMenu';
import { setActiveMenu, closeMenu } from 'store/global/ui/main-menu';

import { SidebarStatus, SidebarContentType } from '../../store/global/ui/side-bar/sideBarReducer';

import { getTranslate } from 'store/global/dictionary';
import { toogleFoereningSwitcher } from 'store/global/ui/foereningSwitcher';
import { getApplicationViewModeIsDesktop } from 'store/global/ui/application-mode';
import { goToSchedule } from 'store/location';
import { registerDeviceId } from 'store/global/pushNotifications/registerDeviceCmd/registerDeviceCmdThunks';
import { MobileOSType, isApp } from 'models/app/MobileOSType';
import { fetchPassListInBackground } from 'store/pass';
import { logout } from 'store/auth';

const mapStateToProps = (state: RootState): MainMenuDataProps => ({
  activeMenu: state.global.ui.bottomMenu.menuShowing,
  translate: getTranslate(state.locale),
  availableFoereningar: state.global.session.inloggadeFoereningar,
  currentFoerening: state.global.session.inloggadeFoereningar.find(
    f => f.Id.toString() === (state.global.session.activeFoerening as number).toString()
  ) as fos.api.InloggadeFoerening,
  newNotificationsCount: state.global.ui.newNotification.newNotificationsCount,
  isDesktopMode: getApplicationViewModeIsDesktop(state),
  authenticated: state.auth.read.isAuthenticated || false,
});

const mapDispatchToProps = (dispatch: Dispatch): MainMenuActionProps => ({
  setActiveMenu: menu => dispatch(setActiveMenu(menu)),
  closeMenu: () => dispatch(closeMenu()),
  setSidebarToContainSchedule: () =>
    dispatch({
      type: 'UI_SIDEBAR_SET_CONTENT_TYPE',
      contentType: SidebarContentType.SCHEDULE,
    }),
  setSidebarToVisible: () =>
    dispatch({
      type: 'UI_SIDEBAR_LAUNCH',
      visibility: SidebarStatus.SHOWING,
    }),
  setIosDeviceId: deviceId => dispatch(registerDeviceId(deviceId, MobileOSType.iOS)),
  setAndroidDeviceId: deviceId => dispatch(registerDeviceId(deviceId, MobileOSType.Android)),
  showFoereningSwitcher: () => dispatch(toogleFoereningSwitcher(true)),
  goToSchedule: () => dispatch(goToSchedule()),
  updatePasslist: (foereningId: number) => dispatch(fetchPassListInBackground(foereningId)),
  logout: () => {
    const app = isApp();
    dispatch(logout(app, !app));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
