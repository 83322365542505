import { PublicSchedulePopupDataProps, PublicSchedulePopup } from './PublicSchedulePopup';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { getTranslate } from 'store/global/dictionary';

const mapStateToProps = (state: RootState, props: any): PublicSchedulePopupDataProps => {
  return {
    isAuthenticated: state.auth.read.isAuthenticated,
    translate: getTranslate(state.locale),
  };
};

export const PublicSchedulePopupContainer = connect(mapStateToProps)(PublicSchedulePopup);
// https://website.local:3000/public/schedule?foerening=stockholm
