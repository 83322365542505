import * as React from 'react';

export interface IconPlayProps {
  title?: string;
}

export class IconPlay extends React.Component<IconPlayProps, {}> {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 14 14.4" enableBackground="new 0 0 14 14.4">
        <title>{this.props.title}</title>
        <polygon points="97,183 111,190.8 97,197.4" fill="#fff" transform="translate(-97.000000, -183.000000)" />
      </svg>
    );
  }
}
