import * as React from 'react';
import { PaymentSettingsItem } from './PaymentSettingsItem';
import { EntityStatus } from '../../../store/global/fetch';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface CardsDataProps {
  data: fos.api.Betalkort[] | null;
  fetchState?: EntityStatus;
  translate: TranslateFunction;
}

export interface CardsActionProps {}

type CardsProps = CardsActionProps & CardsDataProps;

export const Cards = (props: CardsProps) => {
  const getCard = () => {
    if (props.fetchState === EntityStatus.FETCHING || props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND) {
      return <PaymentSettingsItem translate={props.translate} />;
    } else if (props.data) {
      return props.data.map(b => (
        <PaymentSettingsItem
          key={b.Id}
          cardNumber={b.CardNr}
          cardIssuerName={b.CardIssuer}
          type={b.BetalningsmedelType}
          translate={props.translate}
        />
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      {(props.fetchState === EntityStatus.FETCHING ||
        props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND ||
        (props.data && props.data.length > 0)) && (
        <div className="PaymentSettings__Title">
          {props.translate(SitecoreDictionary.Profile.PaymentSettings.Card) as string}
        </div>
      )}
      {getCard()}
    </div>
  );
};
