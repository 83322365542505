import { bokaPass } from 'store/pass';
import { ThunkAction } from 'store';
import { api } from 'api';
import { executingCommand, FetchCommandTypes, executedCommand, executingCommandFailed } from 'store/global/fetch';
import { setShouldAcceptBookingRulesAction, setPassToBook } from 'store/global/session';
import { BokningsStatusEnum } from 'models';

export const updateBookingRuleForActiveFoereningAction = (accept: boolean): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (!accept) {
    dispatch(setShouldAcceptBookingRulesAction(false));
    dispatch(setPassToBook());
    return;
  }

  if (!state.global.session.activeFoerening) {
    dispatch(executingCommandFailed('-1', FetchCommandTypes.ACCEPT_BOOKING_RULES));
    return;
  }

  const activeFoereningId: number = state.global.session.activeFoerening;
  dispatch(executingCommand(activeFoereningId.toString(), FetchCommandTypes.ACCEPT_BOOKING_RULES));

  return api.put(`Anvaendare/AcceptBookingRules?foereningId=${activeFoereningId}`, {}).then(res => {
    if (res.error) {
      dispatch(executingCommandFailed(activeFoereningId.toString(), FetchCommandTypes.ACCEPT_BOOKING_RULES));
    } else {
      // TODO: ouyo boka passet från kön
      dispatch(setShouldAcceptBookingRulesAction(false));
      dispatch(executedCommand(activeFoereningId.toString(), FetchCommandTypes.ACCEPT_BOOKING_RULES));
      const pass = state.global.session.passToBook;
      if (!!pass) {
        const bokaKoePlats =
          pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE ||
          pass.BokningStatus === BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN;
        dispatch(bokaPass(pass, bokaKoePlats));
      }
    }
  });
};
