import * as React from 'react';

interface MainButtonProps {
  id?: string;
  className?: string;
  color?: 'red' | 'white' | 'transparent';
  handleOnClick?: () => void;
  buttonStyle?: 'solid' | 'outline';
  type?: 'button' | 'submit';
  title?: string;
  disabled?: boolean;
  tabindex?: number;
}

export const MainButton: React.SFC<MainButtonProps> = props => {
  const getClassName = (): string => {
    let className: string = (props.className ? props.className : '') + ' Button';
    let type: string = ' Button';
    switch (props.color) {
      case 'white':
        type += '--white';
        break;
      case 'transparent':
        type += '--transparent';
        break;
      case 'red':
      default:
        type += '--primary';
        break;
    }

    let typeB: string = ' Button';
    switch (props.buttonStyle) {
      case 'outline':
        type += typeB + '--secondary';
        break;
      case 'solid':
      default:
        type += typeB + '--solid';
        break;
    }

    className += type;
    return className;
  };

  return (
    <button
      id={props.id}
      className={getClassName()}
      onClick={props.handleOnClick}
      type={props.type}
      disabled={props.disabled}
      tabIndex={props.tabindex}
    >
      {props.title}
    </button>
  );
};
