import * as React from 'react';
import { IconMenuMembership, IconMenuStatistics } from 'components/icons';
import { getQueryStringValue } from 'utils';
import { FilterCategoryType } from 'store/global/ui/shop-filter';
import { TranslateFunction } from 'react-localize-redux';

export interface CategorySliderDataProps {
  translate: TranslateFunction;
  selectedCategory: string;
}

export interface CategorySliderActionProps {
  setSelectedCategory: (categoryName: FilterCategoryType) => void;
}

interface Category {
  name: FilterCategoryType;
  icon: JSX.Element;
  selected: boolean;
}

type CategorySliderProps = CategorySliderDataProps & CategorySliderActionProps;

export const CategorySlider: React.FunctionComponent<CategorySliderProps> = ({
  translate,
  selectedCategory,
  setSelectedCategory,
}) => {
  const [categories, setCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    const category = getQueryStringValue('kategori');
    const newCategories: Category[] = [
      { name: 'Träningskort', icon: <IconMenuMembership />, selected: false },
      { name: 'Medlemskap', icon: <IconMenuStatistics />, selected: false },
    ];
    if (category) {
      for (let i = 0; i < newCategories.length; i++) {
        const name = newCategories[i].name;
        if (name.toLowerCase() === category.toLowerCase()) {
          newCategories[i].selected = true;
          setSelectedCategory(name);
        }
      }
    } else {
      newCategories[0].selected = true;
    }
    setCategories(newCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedItem = (index: number, name: FilterCategoryType) => {
    const newCategories = [...categories];
    newCategories.forEach((item, loopIndex) => {
      item.selected = index === loopIndex;
    });
    setCategories(newCategories);
    setSelectedCategory(name);
  };

  return (
    <div className={'CategorySlider ' + (categories.length > 3 ? 'scrollableSlider' : '')}>
      {categories.map((item, index) => (
        <div
          onClick={() => setSelectedItem(index, item.name)}
          className={
            ' CategorySlider__Item ' + (item.name === selectedCategory ? 'CategorySlider__Item--selected' : '')
          }
          key={item.name}
        >
          {item.icon}
          <div className="Item__Name">{item.name}</div>
        </div>
      ))}
    </div>
  );
};
