import * as React from 'react';

export interface IconPenProps {
  title?: string;
}

export class IconPen extends React.Component<IconPenProps, {}> {
  render() {
    return (
      /* tslint:disable */
      <svg
        className="IconPen"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 8 26"
        enableBackground="new 0 0 8 26"
      >
        <title>{this.props.title}</title>
        <style>
          {`
                .IconPen .st0 {
                  fill: none;
                  stroke: #c8c8c8;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
              `}
        </style>
        <g>
          <g>
            <path
              id="Path-2"
              className="st0"
              d="M1.9,19.7V5V2.5c0.3-1.2,0.8-1.8,1.6-1.8S5,1.3,5.3,2.5V5v14.6l-1.7,5.5L1.9,19.7z"
            />
            <polyline id="Path-3" className="st0" points="6.3,5 0.2,5 0.2,12.3" />
            <path id="Path-4" className="st0" d="M1.9,18.2h3.5" />
          </g>
        </g>
      </svg>
    );
  }
}
