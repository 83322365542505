interface Point {
  x: number;
  y: number;
}

export const getAngleBetween = (a: Point, b: Point, c: Point) => {
  // http://jsfiddle.net/m1erickson/XnL3B/
  const dx1 = a.x - b.x;
  const dy1 = a.y - b.y;
  const dx2 = c.x - b.x;
  const dy2 = c.y - b.y;
  const a1 = Math.atan2(dy1, dx1);
  const a2 = Math.atan2(dy2, dx2);
  const deg = -(((a2 - a1) * 180) / Math.PI + 360) % 360;
  const thershold = -25.0;
  if (deg < thershold) {
    return thershold;
  }
  return deg;
};
