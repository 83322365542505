import * as React from 'react';

interface BannerProps {
  backgroundUrl?: string;
  className?: string;
  overlay?: string;
}

export const Banner: React.SFC<BannerProps> = props => (
  <div
    className={'Banner ' + (props.className ? props.className : '')}
    style={{
      backgroundImage: `url(${props.backgroundUrl})`,
    }}
  >
    <div
      className="Banner__Overlay"
      style={
        props.overlay
          ? {
              background: props.overlay,
            }
          : undefined
      }
    />
    {<div className="Banner__Content">{props.children}</div>}
  </div>
);
