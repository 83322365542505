import {
  PassFilterActionType,
  PassFilterActionSetKategoriVald,
  PassFilterActionSetFilterValt,
  PassFilterActionSetAllaIFiltergruppVald,
  PassFilterActionSetFilterIGruppVald,
  PassFilterActionSetFiltergruppOeppnad,
  PassFilterActionSetFavoritfilterValt,
  PassFilterActionCreateFavoritfilter,
  PassFilterRensaFilter,
  PassFilterVisaFilter,
  PassFilterSetFilter,
  PassFilterUpdateUnfilteredDays,
  PassFilterActionSetFavoritfilter,
  PassFilterActionDeleteFavoritfilter,
  PassFilterActionSetFilterFraanCache,
  PassFilterActionSetFritextTerm,
} from './passFilterActions';
import {
  PassFilterKategorier,
  PassFilterFavoritfilter,
  PassFilterFiltrerbaraKategorier,
  PassFilterFilterCache,
} from '.';
import { DayViewObject } from 'models/Day';

export const passFilterSetKategoriVald = (
  kategori: keyof typeof PassFilterKategorier,
  vald: boolean
): PassFilterActionSetKategoriVald => ({
  type: PassFilterActionType.PassFilterSetKategoriVald,
  kategori,
  vald,
});

export const passFilterSetFilterValt = (
  kategori: keyof typeof PassFilterKategorier,
  namn: string,
  vald: boolean
): PassFilterActionSetFilterValt => ({ type: PassFilterActionType.PassFilterSetFilterValt, kategori, namn, vald });

export const passFilterSetAllaIFiltergruppVald = (
  kategori: keyof typeof PassFilterKategorier,
  filtergruppNamn: string,
  vald: boolean
): PassFilterActionSetAllaIFiltergruppVald => ({
  type: PassFilterActionType.PassFilterSetAllaIFiltergruppVald,
  kategori,
  filtergruppNamn,
  vald,
});

export const passFilterSetFilterIGruppVald = (
  kategori: keyof typeof PassFilterKategorier,
  filtergruppNamn: string,
  filter: string,
  vald: boolean
): PassFilterActionSetFilterIGruppVald => ({
  type: PassFilterActionType.PassFilterSetFilterIGruppVald,
  kategori,
  filtergruppNamn,
  filter,
  vald,
});

export const passFilterSetFiltergruppOeppnad = (
  kategori: keyof typeof PassFilterKategorier,
  filtergruppNamn: string,
  oeppnad: boolean
): PassFilterActionSetFiltergruppOeppnad => ({
  type: PassFilterActionType.PassFilterSetFiltergruppOeppnad,
  kategori,
  filtergruppNamn,
  oeppnad,
});

export const passFilterSetFavoritfilterValt = (favoritFilterId: number): PassFilterActionSetFavoritfilterValt => ({
  type: PassFilterActionType.PassFilterSetFavoritfilterValt,
  favoritFilterId,
});

export const passFilterCreateFavoritfilter = (
  filter: PassFilterFavoritfilter
): PassFilterActionCreateFavoritfilter => ({
  type: PassFilterActionType.PassFilterCreateFavoritfilter,
  filter,
});

export const passFilterDeleteFavoritfilter = (filterId: number): PassFilterActionDeleteFavoritfilter => ({
  type: PassFilterActionType.PassFilterDeleteFavoritfilter,
  filterId,
});

export const passFilterRensaFilter = (): PassFilterRensaFilter => ({
  type: PassFilterActionType.PassFilterRensaFilter,
});

export const passFilterVisaFilter = (visa: boolean): PassFilterVisaFilter => ({
  type: PassFilterActionType.PassFilterVisaFilter,
  visa,
});

export const passFilterSetFilter = (filter: PassFilterFiltrerbaraKategorier): PassFilterSetFilter => ({
  type: PassFilterActionType.PassFilterSetFilter,
  filter,
});

export const passFilterUpdateUnfilteredDays = (
  unfilteredDays: DayViewObject<fos.api.Pass>
): PassFilterUpdateUnfilteredDays => ({
  type: PassFilterActionType.PassFilterUpdateUnfilteredDays,
  unfilteredDays,
});

export const passFilterSetFavoritfilter = (
  favoritfilter: PassFilterFavoritfilter[]
): PassFilterActionSetFavoritfilter => ({
  type: PassFilterActionType.PassFilterSetFavoritfilter,
  favoritfilter,
});

export const passFilterSetFilterFraanCache = (cache: PassFilterFilterCache): PassFilterActionSetFilterFraanCache => ({
  type: PassFilterActionType.PassFilterSetFilterFraanCache,
  cache,
});

export const passFilterSetFritextTerm = (term: string): PassFilterActionSetFritextTerm => ({
  type: PassFilterActionType.PassFilterSetFritextTerm,
  term,
});
