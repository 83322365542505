export enum BokningsStatusEnum {
  EJ_BOKNINGSBART_DATE_DROPIN = 'EJ_BOKNINGSBART_DATE_DROPIN',
  FOERSENT_FOER_ATT_BOKA = 'FOERSENT_FOER_ATT_BOKA',
  FOERTIDIGT_FOER_ATT_BOKA = 'FOERTIDIGT_FOER_ATT_BOKA',
  BOKNINGSBART = 'BOKNINGSBART',
  BOKNINGSBART_DROPIN = 'BOKNINGSBART_DROPIN',
  BOKNINGSBART_KOE = 'BOKNINGSBART_KOE',
  BOKNINGSBART_KOE_DROPIN = 'BOKNINGSBART_KOE_DROPIN',
  FULLT = 'FULLT',
  FULLT_DROPIN = 'FULLT_DROPIN',
  DROPIN = 'DROPIN',
  INSTAELLD = 'INSTAELLD',
}
