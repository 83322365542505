import { setNewNotificationCount } from './newNotificationActions';
import { ThunkAction } from 'store';
import { api } from 'api';

export const fetchNewNotificationsCount = (): ThunkAction => dispatch => {
  const promise = api.get<number>('Notis/GetNewNotiserCount');
  promise.then(result => {
    if (!result.error) {
      dispatch(setNewNotificationCount(result.result));
    }
  });
  return promise;
};
