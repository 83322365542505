import * as React from 'react';
import { Product } from './Product/Product';
import { CouldAddProduct } from 'utils';
import { TranslateFunction } from 'react-localize-redux';


export interface ProductGroupProps {
  productsInBasket?: Array<fos.api.Product>;
  title: string;
  data: Array<fos.api.Product>;
  translate: TranslateFunction;
  foereningNamn: string;
  isAdmin: boolean;
  canAddProduct: (product: fos.api.Product) => CouldAddProduct;
  addProduct: (product: fos.api.Product) => void;
  removeProduct: (product: fos.api.Product) => void;
  goToProductDetails: (id: number, name: string, foereningNamn: string, customerType: number) => void;
}

export class ProductGroup extends React.Component<ProductGroupProps> {
  checkIfInBasket = (id: number, customerTypeId: number) => {
    if (this.props.productsInBasket) {
      for (let i = 0; i < this.props.productsInBasket.length; i++) {
        if (
          this.props.productsInBasket[i].Id === id &&
          this.props.productsInBasket[i].CustomerType.Id === customerTypeId
        ) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="List__Header">{this.props.title}</div>
        {this.props.data.map(item => (
          <Product
            isInBasket={this.checkIfInBasket(item.Id, item.CustomerType.Id)}
            goToProductDetails={this.props.goToProductDetails}
            product={item}
            key={item.Id + ' ' + item.CustomerType.Id}
            foereningNamn={this.props.foereningNamn}
            canAddProduct={this.props.canAddProduct}
            addProduct={this.props.addProduct}
            removeProduct={this.props.removeProduct}
            translate={this.props.translate}
            isAdmin={this.props.isAdmin}
          />
        ))}
      </React.Fragment>
    );
  }
}
