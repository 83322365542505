import { Reducer } from 'redux';
import { Action } from 'store';
import { TrainingPreferencesWriteActionType } from './trainingPreferencesWriteActions';

export interface TrainingPreferencesWriteState {
  workoutLevels: Array<string>;
  childAgeGroups: Array<string>;
  favoriteAnlaeggningar: Array<string>;
}

const initialState: TrainingPreferencesWriteState = {
  workoutLevels: [],
  childAgeGroups: [],
  favoriteAnlaeggningar: [],
};

export const trainingPreferencesWriteReducer: Reducer<TrainingPreferencesWriteState> = (
  state = initialState,
  action: Action
): TrainingPreferencesWriteState => {
  switch (action.type) {
    case TrainingPreferencesWriteActionType.RemoveWorkoutLevel:
      return { ...state, workoutLevels: state.workoutLevels.filter(i => i !== action.workoutLevel) };

    case TrainingPreferencesWriteActionType.AddWorkoutLevel:
      return { ...state, workoutLevels: [...state.workoutLevels, action.workoutLevel] };

    case TrainingPreferencesWriteActionType.RemoveChildAgeGroup:
      return { ...state, childAgeGroups: state.childAgeGroups.filter(i => i !== action.childAgeGroup) };

    case TrainingPreferencesWriteActionType.AddChildAgeGroup:
      return { ...state, childAgeGroups: [...state.childAgeGroups, action.childAgeGroup] };

    case TrainingPreferencesWriteActionType.RemoveSpecialintresse:
      return { ...state, childAgeGroups: [], workoutLevels: [] };

    case TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggningar:
      return { ...state, favoriteAnlaeggningar: [] };

    case TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggning:
      return { ...state, favoriteAnlaeggningar: state.favoriteAnlaeggningar.filter(i => i !== action.anlaeggning) };

    case TrainingPreferencesWriteActionType.AddFavoriteAnlaeggning:
      return { ...state, favoriteAnlaeggningar: [...state.favoriteAnlaeggningar, action.favoriteAnlaeggning] };

    default:
      return state;
  }
};
