export function getCurrencyCode(currency: string): string {
  if (!currency) {
    return '';
  }
  switch (currency.toUpperCase()) {
    case 'SEK':
      return ':-';
    default:
      return '';
  }
}
