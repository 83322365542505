import { Dispatch } from 'store';
import { ApplicationModeAction } from 'store/global/ui/application-mode/applicationModeActions';

export const DESKTOP_MODE_BREAKPOINT = 1000;

export interface WindowDimensions {
  innerWidth: number;
  innerHeight: number;
  outerWidth: number;
  outerHeight: number;
}

export const getDeviceDimensions = (): WindowDimensions => {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight,
  };
};

export const setApplicationMode = () => (dispatch: Dispatch<ApplicationModeAction>) => {
  if (getDeviceDimensions().innerWidth > DESKTOP_MODE_BREAKPOINT) {
    dispatch({
      type: 'UI_APPLICATION_MODE_SET_DESKTOP',
    });
  } else {
    dispatch({
      type: 'UI_APPLICATION_MODE_SET_MOBILE',
    });
  }
};
