import * as React from 'react';
// import moment from 'moment';
import 'moment/locale/sv';

import { ApiError } from 'store/global/errors';

import Bluescreen from './Bluescreen';
import Errors from './Errors';
import SwitchPage from './SwitchPage';
import HeaderContainer from 'components/header';
import ScheduleLauncher from 'components/common/ScheduleLauncher';

import FooterContentContainer from '../main-menu/MainMenuContainer';
import SideBarContainer from 'components/side-bar/SideBarContainer';

import CalendarSyncPopup from 'components/calendarSyncPopup';
import AcceptBookingRulesPopup from 'components/acceptBookingRulesPopup';
import { PopupGDPR } from 'components/shoppingBasket/forms/PopupGDPR';

import 'static/style/app.css';
import { SidebarContentType } from 'store/global/ui/side-bar/sideBarReducer';
import { LocationActionTypes as RouteActionType } from '../../store/location/locationActions';
import { LocationState, getIsAdminWebMode } from 'store/location';

import { FoereningSwitcher } from './FoereningSwitcher';
import { CSSTransition } from 'react-transition-group';
import { Overlay, Spinner } from 'components/common';
import { SitecoreDictionary } from 'models';
import { FoereningInternal } from 'store/foereningar/read';
import { getQueryStringValue, constructSafeName } from 'utils';
import SiteSwitcherContainer from 'components/SiteSwitcher/SiteSwitcherContainer';
import { HeaderIconEnum } from 'store/global/ui/header';
import { TranslateFunction } from 'react-localize-redux';
import { SiteSwitcherLogin } from 'components/SiteSwitcher/SiteSwitcherLogin';
import moment from 'moment';
import { isApp, isAndroid, isIOS, isDebugAndroid, isDebugIos } from 'models/app/MobileOSType';

export interface AppDataProps {
  errors: ReadonlyArray<ApiError>;
  hasAppCrashed: boolean;
  className?: string;
  isPublicWebMode: boolean;
  location: LocationState;
  isIframe: boolean;
  showFoereningSwitcher: boolean;
  isSwitchingFoerening: boolean;
  header?: string;
  authenticated?: boolean;
  isSidebarShowing: boolean;
  isSiteSwitcherShowing: boolean;
  sidebarContentType: SidebarContentType | undefined | null;
  showHeader: boolean;
  showFooter: boolean;
  isDesktopMode: boolean;
  isSideBarMaximised: boolean;
  pageHasTitle: boolean;
  aktivFoerening: number | undefined;
  userId?: number;
  translate: TranslateFunction;
  currentFoerening: fos.api.InloggadeFoerening;
  availableFoereningar: fos.api.InloggadeFoerening[];
  languageCode: string;
  shouldAcceptBookingRules?: boolean;
  shouldShowCalendarSync?: boolean;
  allFoerenignar: FoereningInternal[];

  isExecuting: boolean;
  isExecutingFailed: boolean;
  languageId: number;
  foereningId: number;
  shopFoerening?: number;
  username: string;
  password: string;

  numberOfProductsInBasket?: number;
  isInitialized: boolean;
  passDetailsVisible: boolean;
  scheduleVisible: boolean;

  datumFoerModalVisas?: Date;
  datumFoerModalKanInteStaengas?: Date;
  schemaUrl?: string;
}

export interface AppActionProps {
  initialize: (location: LocationState) => void;
  dismissError: (error: ApiError) => void;
  appCrashed: (error: Error) => void;
  setApplicationMode: () => void;
  toogleFoereningSwitcher: (isVisible: boolean) => void;
  updateActiveFoerening: (foerening: fos.api.InloggadeFoerening) => void;
  login: (credentials: fos.api.Credentials) => void;
  logout: (goToLogin: boolean, goToPublicWeb: boolean) => void;

  updateUsername: (email: string) => void;
  updatePassword: (password: string) => void;
  updateFoereningId: (foereningId: number) => void;
  onLogin: (credentials: fos.api.Credentials) => void;
  goToLogin: () => void;
  loginFromSchedule?: (credentials: fos.api.Credentials, passId: string | number, bokaKoePlats: boolean) => void;
  clearLoginCmd: () => void;
  setFoereningIdFromQueryParameter: () => void;
  setSiteSwitcherToHidden: () => void;
  setShopFoerening: (foereningId?: number) => void;
  setInitialized: (isInitialized: boolean) => void;
  getProfileInfo: (foereningId: number) => void;
  setHeaderIcons: (
    leftDesktopIcon?: HeaderIconEnum,
    rightDesktopIcon?: HeaderIconEnum,
    leftMobileIcon?: HeaderIconEnum,
    rightMobileIcon?: HeaderIconEnum
  ) => void;
  resetHeaderIcons: () => void;
  goToSchedule: () => void;
}

export interface LoginPassedProps {
  publicScheduleMode?: boolean;
  passId?: number | string;
  bokaKoePlats?: boolean;
  title?: string;
}

interface AppInternalState {
  canShowSchedule: boolean;
  isGdprVillkorOpened: boolean;
  isModalOpened: boolean;
}

const initialState: AppInternalState = {
  canShowSchedule: false,
  isGdprVillkorOpened: true,
  isModalOpened: true,
};

type AppProps = Readonly<AppDataProps & AppActionProps>;

export class App extends React.Component<AppProps, AppInternalState> {
  constructor(props: AppProps) {
    super(props);
    this.caughtError = this.caughtError.bind(this);
    this.state = initialState;
    this.toggleGdprVillkor = this.toggleGdprVillkor.bind(this);
    this.onResize = this.onResize.bind(this);
    this.props.initialize(this.props.location);
  }

  onResize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.props.setApplicationMode();
  }

  doPageSpecificInit() {
    switch (this.props.location.type) {
      case RouteActionType.Shop:
      case RouteActionType.ShoppingBasket:
      case RouteActionType.ProductDetails:
      case RouteActionType.PublicShop:
      case RouteActionType.PublicShoppingBasket:
      case RouteActionType.PublicProductDetails:
      case RouteActionType.PublicRegister:
      case RouteActionType.AdminShop:
      case RouteActionType.AdminShoppingBasket:
      case RouteActionType.AdminProductDetails:
      case RouteActionType.AdminRegister: {
        const foereningFromQuery = getQueryStringValue('foerening');
        const shopFoereningFromLocalStorage = localStorage.getItem('SHOP_FOERENING');
        const inloggadFoereningFromLocalStorage = localStorage.getItem('INLOGGADE_FOERENING');
        if (!!foereningFromQuery) {
          const foerening = this.props.allFoerenignar.find(
            f =>
              constructSafeName(f.Namn).toLocaleLowerCase() ===
              constructSafeName(decodeURIComponent(foereningFromQuery)).toLocaleLowerCase()
          );
          if (!!foerening) {
            this.props.setShopFoerening(parseInt(foerening.Id.toString(), 10));
            localStorage.setItem('SHOP_FOERENING', foerening.Id.toString());
          }
        } else if (!!inloggadFoereningFromLocalStorage) {
          const inloggadeFoereningar: fos.api.InloggadeFoerening[] = JSON.parse(inloggadFoereningFromLocalStorage);
          if (!!inloggadeFoereningar && inloggadeFoereningar.length > 0) {
            const id = inloggadeFoereningar[0].Id.toString();
            this.props.setShopFoerening(parseInt(id, 10));
            localStorage.setItem('SHOP_FOERENING', id);
          }
        } else if (!!shopFoereningFromLocalStorage) {
          this.props.setShopFoerening(parseInt(shopFoereningFromLocalStorage, 10));
          localStorage.setItem('SHOP_FOERENING', shopFoereningFromLocalStorage);
        }
        if (!!this.props.aktivFoerening || !!this.props.currentFoerening) {
          this.props.getProfileInfo(
            !!this.props.aktivFoerening
              ? this.props.aktivFoerening
              : !!this.props.currentFoerening
              ? this.props.currentFoerening.Id
              : 0
          );
        }
        break;
      }
      default:
        this.props.setShopFoerening(undefined);
        localStorage.removeItem('SHOP_FOERENING');
        break;
    }
    this.props.setInitialized(true);
  }

  componentDidMount() {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
    document.body.removeChild(scrollDiv);

    this.props.setInitialized(false);

    window.addEventListener('error', this.caughtError);
    window.addEventListener('unhandledrejection', this.caughtRejection);
    window.addEventListener('resize', this.onResize);
    this.onResize();

    setTimeout(
      () =>
        this.setState({
          ...this.state,
          canShowSchedule: true,
        }),
      500
    );
    this.updateIcon();

    if (this.props.authenticated) {
      const intent = localStorage.getItem('intent');
      if (intent === 'schedule') {
        this.props.goToSchedule();
        localStorage.removeItem('intent');
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.caughtError);
    window.removeEventListener('unhandledrejection', this.caughtRejection);
    window.removeEventListener('resize', this.onResize);
  }

  updateIcon() {
    switch (this.props.location.type) {
      case RouteActionType.Settings_Profile:
      case RouteActionType.Settings_PaymentSettings:
      case RouteActionType.Settings_TrainingPreferences:
      case RouteActionType.Settings_NotificationSettings:
      case RouteActionType.Settings_CalendarSyncSettings:
      case RouteActionType.Settings_UserAccount:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.BACK,
          HeaderIconEnum.NONE
        );
        break;
      case RouteActionType.PassDetails:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.BACK,
          HeaderIconEnum.NONE
        );
        break;
      case RouteActionType.PublicShoppingBasket:
      case RouteActionType.ShoppingBasket:
      case RouteActionType.PublicRegister:
      case RouteActionType.AdminShoppingBasket:
      case RouteActionType.AdminRegister:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.BACK,
          HeaderIconEnum.NONE
        );
        break;
      case RouteActionType.Schedule:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.FILTER,
          HeaderIconEnum.BACK
        );
        break;
      case RouteActionType.Shop:
      case RouteActionType.PublicShop:
      case RouteActionType.AdminShop:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.SHOPPINGBASKET,
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.SHOPPINGBASKET
        );
        break;
      case RouteActionType.ProductDetails:
      case RouteActionType.PublicProductDetails:
      case RouteActionType.AdminProductDetails:
        this.props.setHeaderIcons(
          HeaderIconEnum.INITIAL,
          HeaderIconEnum.SHOPPINGBASKET,
          HeaderIconEnum.BACK,
          HeaderIconEnum.SHOPPINGBASKET
        );
        break;
      case RouteActionType.PublicSchedule:
        this.props.setHeaderIcons(HeaderIconEnum.NONE, HeaderIconEnum.NONE, HeaderIconEnum.FILTER, HeaderIconEnum.NONE);
        break;
      default:
        this.props.resetHeaderIcons();
        break;
    }
  }

  componentDidUpdate(oldProps: AppProps) {
    if (this.props.isInitialized && (this.props.location.type !== oldProps.location.type || !oldProps.isInitialized)) {
      this.doPageSpecificInit();
    }
    if (oldProps.location.type !== this.props.location.type) {
      this.updateIcon();
      const mainContent = document.getElementById('mainContent');
      if (!!mainContent) {
        setTimeout(() => {
          if (!!mainContent.scrollTo) {
            mainContent.scrollTo(0, 0);
          } else {
            mainContent.scrollTop = 0;
          }
          if (!!window.scrollTo) {
            window.scrollTo(0, 0);
          } else {
            window.scroll(0, 0);
          }
        }, 0);
      }
    }

    if (oldProps.scheduleVisible !== this.props.scheduleVisible) {
      const body = document.querySelector('body');
      if (!body) {
        return;
      }
      if (this.props.scheduleVisible && !body.classList.contains('disableOverscrollY')) {
        body.classList.add('disableOverscrollY');
      } else if (!this.props.scheduleVisible && body.classList.contains('disableOverscrollY')) {
        body.classList.remove('disableOverscrollY');
      }
    }
  }

  componentDidCatch(error: Error & { __handled__?: boolean }, errorInfo: React.ErrorInfo) {
    if (!error.__handled__) {
      error.__handled__ = true;
      console.warn('componentDidCatch', error, errorInfo.componentStack);
      this.props.appCrashed(error);
    }
  }

  toggleGdprVillkor() {
    this.setState({
      ...this.state,
      isGdprVillkorOpened: !this.state.isGdprVillkorOpened,
    });
  }

  render() {
    const firstVisitLocalStorageItem = localStorage.getItem('firstvisit') || localStorage.getItem('firstVisit');
    const isFirstVisit = firstVisitLocalStorageItem == null || JSON.parse(firstVisitLocalStorageItem) === true;
    const {
      hasAppCrashed,
      errors,
      dismissError,
      isPublicWebMode,
      isSidebarShowing,
      sidebarContentType,
      showHeader,
      showFooter,
      isDesktopMode,
      isSideBarMaximised,
      aktivFoerening,
      userId,
      isIframe,
    } = this.props;

    const stillAuthenticating: boolean = this.props.authenticated === undefined;
    const isMobileMode = !isDesktopMode;
    // Used to hide the ScheduleLauncher
    // if errors exist so that it does not end up above the error messages on account of its high z-index value

    // const errorExists = errors && errors.length;
    const isLoginPage = this.props.location.type === RouteActionType.Login;
    const isShopPage =
      this.props.location.type === RouteActionType.Shop ||
      this.props.location.type === RouteActionType.PublicShop ||
      this.props.location.type === RouteActionType.AdminShop;
    const isProductPage =
      this.props.location.type === RouteActionType.ProductDetails ||
      this.props.location.type === RouteActionType.PublicProductDetails ||
      this.props.location.type === RouteActionType.AdminProductDetails;
    const isStartPage = this.props.location.type === RouteActionType.Home;
    const isShoppingBasketPage =
      this.props.location.type === RouteActionType.ShoppingBasket ||
      this.props.location.type === RouteActionType.PublicShoppingBasket ||
      this.props.location.type === RouteActionType.AdminShoppingBasket;
    const isPaymentPage = this.props.location.type === RouteActionType.Payment;
    const isReceiptPage = this.props.location.type === RouteActionType.Receipt;

    const isResetPasswordPage = this.props.location.type === RouteActionType.ResetPassword;
    // const isAuthenticatedOrIsInPublicWebMode = this.props.authenticated || isPublicWebMode;

    if (hasAppCrashed) {
      return <Bluescreen />;
    }

    const modalKnappar =
      this.props.datumFoerModalKanInteStaengas &&
      moment(this.props.datumFoerModalKanInteStaengas).isBetween(moment('2000', 'YYYY'), moment.now())
        ? [
            {
              text: 'Logga ut',
              buttonType: 'primary',
              onClick: () => {
                this.props.logout(true, false);
              },
            },
          ]
        : [
            {
              text: 'Ok',
              buttonType: 'primary',
              onClick: () => {
                this.setState({ ...this.state, isModalOpened: false });
              },
            },
          ];
    const visaModal =
      !isLoginPage &&
      this.state.isModalOpened &&
      this.props.datumFoerModalVisas &&
      moment(this.props.datumFoerModalVisas).isBetween(moment('2000', 'YYYY'), moment.now());
    return (
      <div
        id={isIframe ? 'wrapper__isPublicWebMode' : 'wrapper'}
        className={
          (isIframe ? 'mode-PublicWeb' : 'mode-mittFriskis') +
          ' ' +
          (isLoginPage || isResetPasswordPage ? 'page-login' : isSideBarMaximised ? ' fullViewMode' : '')
        }
      >
        {this.state.isGdprVillkorOpened &&
          this.props.authenticated &&
          !isShoppingBasketPage &&
          !isShopPage &&
          !isProductPage &&
          !isPaymentPage &&
          !isReceiptPage &&
          !visaModal &&
          isFirstVisit && (
            <PopupGDPR
              buttons={[
                {
                  text: SitecoreDictionary.AcceptBookingRulesPopup.Cancel,
                  buttonType: 'secondary',
                  onClick: () => this.props.logout(true, false),
                },
                {
                  text: SitecoreDictionary.AcceptBookingRulesPopup.Accept,
                  buttonType: 'primary',
                  onClick: () => {
                    localStorage.setItem('firstVisit', 'false');
                    localStorage.removeItem('firstvisit');
                    this.toggleGdprVillkor();
                  },
                },
              ]}
              translate={this.props.translate}
            />
          )}
        {visaModal && (
          <Overlay
            close={
              this.props.datumFoerModalKanInteStaengas &&
              moment(this.props.datumFoerModalKanInteStaengas).isBetween(moment('2000', 'YYYY'), moment.now())
                ? undefined
                : () => {
                    this.setState({ ...this.state, isModalOpened: false });
                  }
            }
          >
            <div className="MedlemsVillkor">
              <div className={'MedlemsVillkor__PopupSelect__TextContainer'}>
                <div className="MedlemsVillkor__PopupSelect__Text">
                  {isApp() && (
                    <>
                      <strong style={{ marginBottom: '8px' }}>
                        Hej! Nu byter vi app. Ladda ner den nya appen "Mitt Friskis" här:
                      </strong>
                      {(isAndroid() || isDebugAndroid()) && (
                        <a
                          href="https://play.google.com/store/apps/details?id=se.brpsystems.friskissvettis"
                          style={{ width: 'max-content' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Google play
                        </a>
                      )}
                      {(isIOS() || isDebugIos()) && (
                        <a
                          href="https://apps.apple.com/se/app/mitt-friskis/id1485214114"
                          style={{ width: 'max-content' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          App store
                        </a>
                      )}
                    </>
                  )}
                  {!isApp() && (
                    <>
                      <strong style={{ marginBottom: '8px' }}>
                        Hej! Nu byter vi bokningsplattform. Välkommen in till oss!
                      </strong>
                      <a
                        href={this.props.schemaUrl}
                        style={{ width: 'max-content' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Mitt Friskis
                      </a>
                    </>
                  )}
                </div>
                <div className="MedlemsVillkor__PopupSelect__Buttons">
                  {modalKnappar.map((button, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 && <div className="MedlemsVillkor__PopupSelect__Buttons--space" />}
                        <div className="MedlemsVillkor__PopupSelect__Buttons--button">
                          <button
                            className={'Button Button--' + button.buttonType + ' Button--fullWidth'}
                            onClick={button.onClick}
                          >
                            {button.text}
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </Overlay>
        )}
        {!isIframe && <SiteSwitcherContainer isDesktopMode={isDesktopMode} />}
        {(isPublicWebMode || this.props.aktivFoerening !== this.props.shopFoerening) && (
          <SiteSwitcherLogin
            isDesktopMode={isDesktopMode}
            isDesktopFullViewMode={isSideBarMaximised}
            foereningId={this.props.foereningId}
            translate={this.props.translate}
            isExecutingFailed={this.props.isExecutingFailed}
            isExecuting={this.props.isExecuting}
            languageId={this.props.languageId}
            username={this.props.username}
            password={this.props.password}
            updateUsername={this.props.updateUsername}
            updatePassword={this.props.updatePassword}
            updateFoereningId={this.props.updateFoereningId}
            onLogin={this.props.onLogin}
            goToLogin={this.props.goToLogin}
            clearLoginCmd={this.props.clearLoginCmd}
            isSiteSwitcherShowing={this.props.isSiteSwitcherShowing}
            setSiteSwitcherToHidden={this.props.setSiteSwitcherToHidden}
            numberOfProductsInBasket={this.props.numberOfProductsInBasket}
          />
        )}
        <div id="container" className={this.getClassesForContainer()}>
          <div
            className={
              isIframe
                ? 'containerInnerWrapper publicWebMode'
                : getIsAdminWebMode(this.props.location)
                ? 'containerInnerWrapper admin'
                : 'containerInnerWrapper'
            }
          >
            <Errors errors={errors} onClose={dismissError} translate={this.props.translate} />
            {(!isIframe || !isDesktopMode) && !stillAuthenticating && showHeader && (
              <header id="header" className={this.getClassesForHeader(isStartPage)}>
                <HeaderContainer
                  isDesktopMode={isDesktopMode}
                  isStartPage={isStartPage}
                  isSiteSwitcherShowing={this.props.isSiteSwitcherShowing}
                  isFirstVisit={isFirstVisit}
                />
              </header>
            )}
            <section id="mainContent" className={this.getClassesforMainContent()}>
              {(isPublicWebMode || isIframe || !stillAuthenticating) && (
                <SwitchPage
                  isDesktopMode={isDesktopMode}
                  isPublicWebMode={isPublicWebMode}
                  isDesktopFullViewMode={isPublicWebMode ? isDesktopMode : isSideBarMaximised}
                  // isDesktopFullViewMode={isSideBarMaximised}
                />
              )}
            </section>
          </div>

          {!isIframe &&
            showFooter &&
            isMobileMode &&
            !stillAuthenticating &&
            this.props.authenticated &&
            !isLoginPage &&
            !isShopPage &&
            (!isShoppingBasketPage || !isFirstVisit) && (
              <footer className="footer">
                <FooterContentContainer />
              </footer>
            )}
          {!isIframe &&
            isDesktopMode &&
            !stillAuthenticating &&
            this.props.authenticated &&
            aktivFoerening &&
            userId &&
            !isLoginPage && (
              <SideBarContainer
                isShowing={isSidebarShowing}
                contentType={sidebarContentType}
                isDesktopMode={isDesktopMode}
                isDesktopFullViewMode={isSideBarMaximised}
                isAuthenticated={this.props.authenticated}
                showCloser={true}
                aktivFoerening={aktivFoerening}
                userId={userId}
              />
            )}
          {
            <CSSTransition
              classNames="ScheduleLauncher--animation"
              timeout={300}
              in={
                !isIframe &&
                isDesktopMode &&
                !stillAuthenticating &&
                this.props.authenticated &&
                !isResetPasswordPage &&
                !isLoginPage &&
                // !isShop &&
                !isProductPage &&
                this.state.canShowSchedule
              }
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <ScheduleLauncher isDesktopMode={isDesktopMode} />
            </CSSTransition>
          }
          {this.props.isSwitchingFoerening && (
            <Overlay hideHeader={true} className="App__Blocker">
              <Spinner appearance="white" />
            </Overlay>
          )}
        </div>
        {this.props.showFoereningSwitcher && !this.props.isSwitchingFoerening && !!this.props.currentFoerening && (
          <FoereningSwitcher
            currentFoerening={this.props.currentFoerening}
            foereningar={this.props.availableFoereningar}
            close={() => this.props.toogleFoereningSwitcher(false)}
            setActiveFoerening={f => this.props.updateActiveFoerening(f)}
          />
        )}
        {this.props.shouldShowCalendarSync && !visaModal && <CalendarSyncPopup />}
        {this.props.shouldAcceptBookingRules && !visaModal && <AcceptBookingRulesPopup />}
      </div>
    );
  }

  private caughtError(error: ErrorEvent & { __handled__?: boolean }) {
    if (!error.__handled__) {
      error.__handled__ = true;
      console.warn('caughtError', error.message, error);
      this.props.appCrashed(error.error);
    }
  }

  private caughtRejection(error: PromiseRejectionEvent & { __handled__?: boolean }) {
    if (!error.__handled__) {
      error.__handled__ = true;
      console.warn('caughtError', error.reason.message, error);
      this.props.appCrashed(error.reason);
    }
  }

  private getClassesforMainContent(): string {
    let classes = 'mainContent full-flex';
    if (!this.props.isDesktopMode) {
      classes += this.props.showFooter ? ' has-footer' : '';
    }
    if (this.props.passDetailsVisible) {
      classes += ' passdetail-visible';
    } else if (this.props.scheduleVisible) {
      classes += ' schedule-visible';
    }
    return classes;
  }

  private getClassesForHeader(isStartPage: boolean): string {
    const pgTitle = localStorage.getItem('pageTitle');
    let pageTitle;
    if (pgTitle !== null) {
      pageTitle = JSON.parse(pgTitle);
    }
    let headerClasses = 'header';
    if ((this.props.pageHasTitle || pageTitle) && !isStartPage) {
      headerClasses = headerClasses + ' hasPageTitle';
    }
    return headerClasses;
  }

  private getClassesForContainer(): string {
    let containerClasses = 'MainContainer ';
    if (this.props.isDesktopMode) {
      containerClasses = containerClasses + 'is-Desktop-Mode ';
    } else {
      containerClasses += this.props.showFooter ? ' has-footer ' : ' ';
    }
    if (
      this.props.location.type === RouteActionType.Shop ||
      this.props.location.type === RouteActionType.AdminShop ||
      this.props.isPublicWebMode
    ) {
      containerClasses = containerClasses + 'is__Shop ';
    }
    if (this.props.isSidebarShowing) {
      containerClasses = containerClasses + 'sideBar-Is-Showing ';

      if (this.props.isSideBarMaximised) {
        containerClasses = containerClasses + 'sideBar-Is-Maximised ';
      } else {
        containerClasses = containerClasses + 'sideBar-Is-Minimised ';
      }
    }

    return containerClasses;
  }
}
