import { DayViewObject } from '../../models/Day';

export enum ActionType {
  GetBokningar = 'GET_BOKNINGAR',
  RemoveBokning = 'REMOVE_BOKNING',
  UpdateStatusMessage = 'UPDATE_STATUS_MESSAGE',
  AddBokning = 'ADD_BOKNING',
  PopulateBokningar = 'POPULATE_BOKNINGAR',
  DeleteBokning = 'DELETE_BOKNING',
  SetNextBokning = 'SET_NEXT_BOKNING',
  UpdateDayExpanded = 'UPDATE_DAY_EXPANDED',
}

export interface GetBokningarAction {
  type: ActionType.GetBokningar;
  payload: DayViewObject<fos.api.Bokning>;
}

export interface UpdateStatusMessageAction {
  type: ActionType.UpdateStatusMessage;
  status: string;
  id: string;
}

export interface RemoveBokningAction {
  type: ActionType.RemoveBokning;
  bokningId: string;
}

export interface AddBokningAction {
  type: ActionType.AddBokning;
  bokning: fos.api.Bokning;
}

export interface PopulateBokningarAction {
  type: ActionType.PopulateBokningar;
  bokningar: Array<fos.api.Bokning>;
}

export interface DeleteBokningAction {
  type: ActionType.DeleteBokning;
  bokningId: string;
}

export interface SetNextBokningAction {
  type: ActionType.SetNextBokning;
  bokning?: fos.api.Bokning;
}

export interface BookingActionUpdateDayExpanded {
  type: ActionType.UpdateDayExpanded;
  dayName: string;
  expanded: boolean;
}

export const deleteBokning = (bokningId: string): DeleteBokningAction => ({
  type: ActionType.DeleteBokning,
  bokningId,
});

export const populateBokningar = (bokningar: Array<fos.api.Bokning>): PopulateBokningarAction => ({
  type: ActionType.PopulateBokningar,
  bokningar,
});

export const addBokning = (bokning: fos.api.Bokning): AddBokningAction => ({
  type: ActionType.AddBokning,
  bokning,
});

export const removeBokning = (id: string): RemoveBokningAction => ({
  type: ActionType.RemoveBokning,
  bokningId: id,
});

export const updateStatusMessage = (message: string, id: string): UpdateStatusMessageAction => ({
  type: ActionType.UpdateStatusMessage,
  status: message,
  id: id,
});

export const updateBokningarUI = (Bokningar: DayViewObject<fos.api.Bokning>): GetBokningarAction => ({
  type: ActionType.GetBokningar,
  payload: Bokningar,
});

export const setNextBokning = (bokning?: fos.api.Bokning): SetNextBokningAction => ({
  type: ActionType.SetNextBokning,
  bokning,
});

export const updateBookingDayExpanded = (dayName: string, expanded: boolean): BookingActionUpdateDayExpanded => ({
  type: ActionType.UpdateDayExpanded,
  dayName,
  expanded,
});

export type BokningarAction =
  | GetBokningarAction
  | UpdateStatusMessageAction
  | RemoveBokningAction
  | AddBokningAction
  | PopulateBokningarAction
  | DeleteBokningAction
  | SetNextBokningAction
  | BookingActionUpdateDayExpanded;
