import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FriskisLogo from 'static/assets/friskis-logo--red.svg';
import { FancyNavLink } from 'components/app';
import { routesMap, LocationActionTypes } from 'store/location';
import { IconBigClose } from 'components/icons';
import { Provider } from 'react-redux';
import { store } from 'index';
import { lockBackgroundScroll, unlockBackgroundCallback } from 'websd/utils';

interface OverlayProps {
  className?: string;
  title?: string;
  hideHeader?: boolean;
  close?: () => void;
}

export const Overlay: React.FunctionComponent<OverlayProps> = ({ className, title, hideHeader, children, close }) => {
  const [unlockBackgroundScrollCallback, setUnlockBackgroundScrollCallback] = React.useState<
    unlockBackgroundCallback | undefined
  >(undefined);

  const render = React.useCallback(
    (p: HTMLDivElement) => {
      ReactDOM.render(
        <Provider store={store}>
          {children && (
            <div className="Overlay__Content">
              <div className="Overlay__Content__Wrapper" onClick={e => e.stopPropagation()}>
                {!hideHeader && (
                  <div className="Overlay__Content__Header">
                    <div className="Overlay__Content__Header__HomeIcon">
                      <FancyNavLink to={routesMap[LocationActionTypes.Home]}>
                        <img src={FriskisLogo} className="Overlay__Content__Header__HomeIcon__Logo" alt="Friskis" />
                      </FancyNavLink>
                    </div>
                    {title && <div className="Overlay__Content__Header__Title">{title}</div>}
                    {!!close && (
                      <div className="Overlay__Content__Header__Close" onClick={close}>
                        <IconBigClose />
                      </div>
                    )}
                  </div>
                )}
                <div className="Overlay__Content__Children">{children}</div>
              </div>
            </div>
          )}
        </Provider>,
        p
      );
    },
    [title, close, children, hideHeader]
  );

  React.useEffect(() => {
    const wrapper = document.getElementById('wrapper');
    const p = document.createElement('div');
    p.className = 'Overlay ' + (className ? className : '');
    if (wrapper) {
      wrapper.appendChild(p);
    }
    p.id = '_overlay';
    setUnlockBackgroundScrollCallback(lockBackgroundScroll());

    return () => {
      if (unlockBackgroundScrollCallback) {
        unlockBackgroundScrollCallback();
      }
      if (p) {
        const wrapper = document.getElementById('wrapper');
        if (wrapper) {
          wrapper.removeChild(p);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let p = document.getElementById('_overlay');
    if (p) {
      render(p as HTMLDivElement);
    }
  }, [render]);

  return null;
};
