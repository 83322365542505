import * as React from 'react';
import { IconChevron } from 'components/icons';
import { PreferenceItem } from './PreferenceItem';
import { TranslateFunction } from 'react-localize-redux';


interface ItemDropdownListProps {
  title: string;
  items: Array<string>;
  savedItems: Array<string>;
  removeItem: (preference: string) => void;
  addItem: (preference: string) => void;
  translate: TranslateFunction;
  selectedCallback: () => void;
}

interface ItemDropdownListState {
  isListExpanded: boolean;
}

export class ItemDropdownList extends React.Component<ItemDropdownListProps, ItemDropdownListState> {
  constructor(props: ItemDropdownListProps) {
    super(props);
    this.state = {
      isListExpanded: false,
    };
  }

  render() {
    return (
      <div className="ItemDropdownList">
        <div className="ItemDropdownList__Header" onClick={() => this.toggleList()}>
          {this.props.title}
          <IconChevron direction={this.state.isListExpanded ? 'top' : 'bottom'} color={'red'} />
        </div>

        {this.state.isListExpanded && (
          <div className="PreferenceList__Items">
            {this.props.items.map((i, index) => (
              <PreferenceItem
                removeItem={this.props.removeItem}
                addItem={this.props.addItem}
                title={i}
                key={index}
                disabled={this.isAlreadySaved(i)}
                translate={this.props.translate}
                selectedCallback={this.props.selectedCallback}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  private isAlreadySaved(name: string) {
    const el = this.props.savedItems.find(i => i === name);
    return el ? true : false;
  }

  private toggleList() {
    this.setState({ isListExpanded: !this.state.isListExpanded });
  }
}
