function registerOutsideClickHandler(listener: (ev?: MouseEvent) => void) {
  document.addEventListener('click', listener, false);
}

function deleteOutsideClickHandler(listener: (ev?: MouseEvent) => void) {
  document.removeEventListener('click', listener, false);
}

export const OutsideClickHandler = {
  register: registerOutsideClickHandler,
  delete: deleteOutsideClickHandler,
};

/**
 * This method will invoke a callback when when the scroll is stopped
 * @param callback The callback Function to run when the scroll is stopped
 * @param element an optional HTMLElement, when provided it'll listen on the scroll event on it otherwise on the document
 */
export const onScrollStop = (callback: Function, element?: HTMLElement) => {
  // https://gomakethings.com/detecting-when-a-visitor-has-stopped-scrolling-with-vanilla-javascript/
  let isScrolling: number;
  const container: HTMLElement | Window = element || window;
  // Listen for scroll events
  container.addEventListener(
    'scroll',
    event => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = window.setTimeout(() => callback(), 500);
    },
    false
  );
};
