import { BokningsStatusEnum } from 'models';

export const OK_STATUS_CLASS = 'StatusMessage--Green',
  ERROR_STATUS_CLASS = 'StatusMessage--Black';
export const NOT_BOOKABLE_STATUSES = [
  BokningsStatusEnum.EJ_BOKNINGSBART_DATE_DROPIN as string,
  BokningsStatusEnum.FOERSENT_FOER_ATT_BOKA as string,
  BokningsStatusEnum.FOERTIDIGT_FOER_ATT_BOKA as string,
  BokningsStatusEnum.FULLT as string,
  BokningsStatusEnum.FULLT_DROPIN as string,
  BokningsStatusEnum.DROPIN as string,
  BokningsStatusEnum.INSTAELLD as string,
];
