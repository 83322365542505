import { api } from 'api';
import { ThunkAction } from 'store';
import { toggleSyncCalendar, setSelectedCalendarId } from './calendarActions';
import {
  executingCommand,
  FetchCommandTypes,
  executingCommandFailed,
  executedCommand,
  fetching,
  FetchStatusAggregateTypes,
  fetched,
  fetchedFailed,
} from '../fetch';

interface CalendarProps {
  userId: number;
  syncCalendar: boolean;
}
interface SelectedCalendarProps {
  userId: number;
  id: string;
}
interface GetCalendarProps {
  syncCalendar: boolean;
  id: string;
}

export const setCalendarSync = (userId: number, syncCalendar: boolean): ThunkAction => (dispatch, getState) => {
  dispatch(executingCommand(userId.toString(), FetchCommandTypes.SET_CALENDAR_SYNC));
  dispatch(toggleSyncCalendar(syncCalendar));
  const response = api.post<boolean, CalendarProps>('Anvaendare/SyncCalendar', {
    userId: userId,
    syncCalendar: syncCalendar,
  });
  response.then(result => {
    if (result.error) {
      dispatch(executingCommandFailed(userId.toString(), FetchCommandTypes.SET_CALENDAR_SYNC));
      dispatch(toggleSyncCalendar(!syncCalendar));
    } else {
      dispatch(executedCommand(userId.toString(), FetchCommandTypes.SET_CALENDAR_SYNC));
    }
  });
};

export const setSelectedCalendar = (userId: number, id: string): ThunkAction => (dispatch, getState) => {
  dispatch(executingCommand(userId.toString(), FetchCommandTypes.SET_SELECTED_CALENDAR));
  dispatch(setSelectedCalendarId(id));
  const response = api.post<boolean, SelectedCalendarProps>('Anvaendare/SetSelectedCalendar', {
    userId: userId,
    id: id,
  });
  response.then(result => {
    if (result.error) {
      dispatch(executingCommandFailed(userId.toString(), FetchCommandTypes.SET_SELECTED_CALENDAR));
      dispatch(setSelectedCalendarId(undefined));
    } else {
      dispatch(executedCommand(userId.toString(), FetchCommandTypes.SET_SELECTED_CALENDAR));
    }
  });
};

export const getCalendarSync = (userId: number): ThunkAction => (dispatch, getState) => {
  dispatch(fetching(userId, FetchStatusAggregateTypes.GET_CALENDAR));
  const response = api.get<GetCalendarProps>('Anvaendare/GetCalendarSync?userId=' + userId);
  response.then(result => {
    if (!result.error) {
      dispatch(toggleSyncCalendar(result.result.syncCalendar));
      dispatch(setSelectedCalendarId(result.result.id));
      dispatch(fetched(userId, FetchStatusAggregateTypes.GET_CALENDAR, result.result));
    } else {
      dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.GET_CALENDAR));
    }
  });
};
