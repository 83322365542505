import * as React from 'react';

export interface IconMenuMembershipProps {
  color?: string;
  title?: string;
}

export class IconMenuMembership extends React.Component<IconMenuMembershipProps, {}> {
  fillColour = '#000';

  random = 1 + Math.random() * (1000 - 1);
  rand = 'rand-' + Math.round(this.random);
  randD = '.rand-' + Math.round(this.random);

  // TODO: Fix translation support for text
  render() {
    return (
      <svg viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" className={'IconMenuMembership ' + this.rand}>
        <title>{this.props.title}</title>

        <style type="text/css">
          {`
          .IconMenuMembership${this.randD} {
              width: 55px;
              height: 55px;
          }
          .IconMenuMembership${this.randD}  .cls-2{
            fill: none;
            stroke-width: 0.8;
            stroke: ${this.props.color ? this.props.color : '#343434'};
            troke-linecap: round;
            stroke-linejoin: round
          }
        `}
        </style>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path fill="none" d="M0 0h40v40H0z" />
            <path
              className="cls-2"
              d="M28.62 24.51L14.83 30.6a2.66 2.66 0 0 1-3.52-1.36l-3.88-8.78A2.67 2.67 0 0 1 8.8 17l13.75-6.08M9 19.85a1.2 1.2 0 0 1 .61-1.58"
            />
            <path
              fill={this.props.color ? this.props.color : '#343434'}
              d="M10.95 28.41l22.14-9.4-1.15-2.37-22.1 9.39 1.11 2.38z"
            />
            <path
              className="cls-2"
              d="M18.12 29.15l13.79-6.1a2.67 2.67 0 0 0 1.36-3.51l-3.88-8.78a2.66 2.66 0 0 0-3.52-1.36l-13.75 6.08"
            />
          </g>
        </g>
      </svg>
    );
  }
}
