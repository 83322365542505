import { connect } from 'react-redux';
import { RootState } from 'store';
import { getTranslate } from 'store/global/dictionary';
import { getPageTitle } from 'store/location';
import { Settings, SettingsDataProps, SettingsLocalProps, SettingsActionProps } from './Settings';
import { logout } from 'store/auth';
const defaultImage = require('static/assets/background-login.jpg');

const mapState = (state: RootState, props: SettingsLocalProps): SettingsDataProps => {
  const translate = getTranslate(state.locale);
  const foereningId = state.global.session.activeFoerening;
  const foerening = state.foereningar.read.Foereningar.find(f => '' + f.Id === '' + foereningId);
  return {
    translate: translate,
    inloggadeFoereningar: state.global.session.inloggadeFoereningar,
    activeFoereing: foereningId as number,
    settingsPageTitle:
      props.activeSettingsPage !== null && props.activeSettingsPage !== undefined
        ? getPageTitle(state.location, false, translate) || undefined // Get the dependent page title which is visible in desktop mode
        : undefined,
    image:
      state.global.ui.image.images.settings === '' || !state.global.ui.image.images.settings
        ? defaultImage
        : state.global.ui.image.images.settings,
    shopActive: !!foerening ? foerening.AerButikAktiv : false,
  };
};

const mapDispatchProps: SettingsActionProps = {
  logout,
};

export default connect(mapState, mapDispatchProps)(Settings);
