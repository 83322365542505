import { PushNotificationsActionType } from './pushNotificationSettingsActions';
import { Action } from 'store';
import { Reducer } from 'redux';

export interface PushNotificationSettingsRootState {
  UserNotificatonSettingsId?: number;
  PassCancelled?: boolean;
  Vaentelista?: boolean;
  News?: boolean;
  TraeningskortAboutToExpire?: boolean;
}

const initalState: PushNotificationSettingsRootState = {};

export const PushNotificationSettingsReducer: Reducer<PushNotificationSettingsRootState> = (
  state = initalState,
  action: Action
): PushNotificationSettingsRootState => {
  switch (action.type) {
    case PushNotificationsActionType.GetPushNotificationSettings:
      return {
        ...state,
        ...action.settings,
      };
    default:
      return state;
  }
};
