import * as React from 'react';
import { Overlay } from 'components/common';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface FacilityExceptionalOpenTimesDataProps {
  message: string;
  translate: TranslateFunction;
}

interface FacilityExceptionalOpenTimesActionProps {
  close: () => void;
}

type FacilityExceptionalOpenTimesProps = Readonly<
  FacilityExceptionalOpenTimesDataProps & FacilityExceptionalOpenTimesActionProps
>;

export const FacilityExceptionalOpenTimes: React.SFC<FacilityExceptionalOpenTimesProps> = props => {
  return (
    <Overlay
      title={props.translate(SitecoreDictionary.Facilities.ExceptionalOpenTimes) as string}
      className="FacilityExceptionalOpenTimes"
      close={props.close}
    >
      <div
        className="rich-text"
        dangerouslySetInnerHTML={{
          __html: props.message,
        }}
      />
    </Overlay>
  );
};
