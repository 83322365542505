import * as React from 'react';
import { Overlay } from 'components/common';
import { ItemDropdownList } from './ItemDropdownList';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface PreferenceListProps {
  childrenAgeGroups: Array<string>;
  workoutLevels: Array<string>;
  savedItems: Array<string>;
  close: () => void;
  removeWorkoutLevel: (preference: string) => void;
  addWorkoutLevel: (preference: string) => void;
  addChildAgeGroup: (preference: string) => void;
  removeChildAgeGroup: (preference: string) => void;
  saveSpecialIntressen: () => void;
  translate: TranslateFunction;
}

export class PreferenceList extends React.Component<PreferenceListProps> {
  constructor(props: PreferenceListProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Overlay close={this.props.close} className={'Overlay__PreferenceList'}>
        <div className="PreferenceList">
          <div className="PreferenceList__Title">
            <h5>
              {this.props.translate(SitecoreDictionary.TrainingPreferences.Add)}{' '}
              {this.props.translate(SitecoreDictionary.TrainingPreferences.SpecialInterests)}
            </h5>
          </div>

          <ItemDropdownList
            title={this.props.translate(SitecoreDictionary.TrainingPreferences.Children) as string}
            items={this.props.childrenAgeGroups}
            removeItem={this.props.removeChildAgeGroup}
            addItem={this.props.addChildAgeGroup}
            savedItems={this.props.savedItems}
            translate={this.props.translate}
            selectedCallback={this.props.saveSpecialIntressen}
          />

          <ItemDropdownList
            title={this.props.translate(SitecoreDictionary.TrainingPreferences.WorkoutLevel) as string}
            items={this.props.workoutLevels}
            removeItem={this.props.removeWorkoutLevel}
            addItem={this.props.addWorkoutLevel}
            savedItems={this.props.savedItems}
            translate={this.props.translate}
            selectedCallback={this.props.saveSpecialIntressen}
          />
        </div>
      </Overlay>
    );
  }
}
