import { Reducer } from 'redux';
import { AnlaeggningarActionType } from './anlaeggningarAction';
import { Action } from 'store';

export interface Anlaeggning {
  AnlaeggningId: string;
  AnlaeggningNamn: string;
}

export interface AnlaeggningState {
  anlaeggningar: Array<Anlaeggning>;
}

const initialState: AnlaeggningState = {
  anlaeggningar: [],
};

export const anlaeggningarReducer: Reducer<AnlaeggningState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case AnlaeggningarActionType.GetAnlaeggningar:
      return { ...state, anlaeggningar: action.payload };
    default:
      return { ...state };
  }
};
