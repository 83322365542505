import * as React from 'react';
import { connect } from 'react-redux';
import { appResumeAfterCrash } from 'store/global/errors';
import { getTranslate, TranslateFunction } from 'react-localize-redux';
import { RootState } from 'store';
import { SitecoreDictionary } from 'models';

type BluescreenDataProps = Readonly<{
  translate: TranslateFunction;
}>;

type BluescreenActionProps = Readonly<{
  onResumeAfterCrash: () => void;
}>;

type BluescreenProps = BluescreenActionProps & BluescreenDataProps;

export const Bluescreen: React.SFC<BluescreenProps> = ({ onResumeAfterCrash, translate }) => (
  <div className="BlueScreen">
    <div className="BlueScreen__Msg">
      <h2>{translate(SitecoreDictionary.ErrorPage.AnErrorOccured)}</h2>
      <br />
      {/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */}
      <a className="RefreshLink" href="javascript:location.reload();">
        {translate(SitecoreDictionary.ErrorPage.ClickHereToRefresh)}
      </a>
      {/* eslint-enable jsx-a11y/anchor-is-valid, no-script-url */}
    </div>
  </div>
);

const mapDispatchToProps: BluescreenActionProps = {
  onResumeAfterCrash: appResumeAfterCrash,
};

const mapStateToProps = (state: RootState): BluescreenDataProps => ({
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bluescreen);
