import { Reducer } from 'redux';
import { Action } from 'store';
import { StatisticsReadActionType } from './statisticsReadActions';

export interface StatisticsReadState {
  Statistics: fos.api.TrainingStatistics;
}

const initialState: StatisticsReadState = {
  Statistics: {
    WorkoutsThisWeek: 0,
    WorkoutsLastWeek: 0,
    WorkoutsThisMonth: 0,
    WorkoutsLastMonth: 0,
    WorkoutsThisYear: 0,
    WorkoutsLastYear: 0,
    WorkoutTypesPercentageLast30Days: [],
  },
};

export const StatisticsReadReducer: Reducer<StatisticsReadState> = (
  state = initialState,
  action: Action
): StatisticsReadState => {
  switch (action.type) {
    case StatisticsReadActionType.GetTraeningsstatistik:
      return {
        Statistics: action.statistics,
      };
    default:
      return state;
  }
};
