import { combineReducers } from 'redux';
import { RegisterDeviceCmdState, registerDeviceCmdReducer } from './registerDeviceCmd';

export interface PushNotificationsState {
  readonly registerDeviceCmd: RegisterDeviceCmdState;
}

export const pushNotificationsReducer = combineReducers<PushNotificationsState>({
  registerDeviceCmd: registerDeviceCmdReducer,
});
