import { connect } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import {
  ShopAssociationSelectorView,
  ShopAssociationSelectorViewStateProps,
  ShopAssociationSelectorViewActionProps,
} from './ShopAssociationSelectorView';
import { resetBuyBasketCommand } from 'store/basket';
import { setShopFoerening } from 'store/global/session';
import { getTranslate } from 'store/global/dictionary';

interface ShopAssociationSelectorViewContainerProps {
  isDesktopMode: boolean;
}

const mapStateToProps = (
  state: RootState,
  props: ShopAssociationSelectorViewContainerProps
): ShopAssociationSelectorViewStateProps => {
  return {
    foereningar: state.foereningar.read.Foereningar,
    isDesktopMode: props.isDesktopMode,
    shopFoerening: state.global.session.shopFoerening,
    translate: getTranslate(state.locale),
    isInitialied: state.initialize.read.initialized,
  };
};

const mapActionsToProps = (dispatch: Dispatch): ShopAssociationSelectorViewActionProps => {
  return {
    clearBasket: () => dispatch(resetBuyBasketCommand()),
    updateFoerening: (foereningsId: number) => {
      dispatch(setShopFoerening(foereningsId));
      localStorage.setItem('SHOP_FOERENING', foereningsId.toString());
    },
  };
};

export const ShopAssociationSelectorViewContainer = connect(
  mapStateToProps,
  mapActionsToProps
)(ShopAssociationSelectorView);
