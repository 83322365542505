import { combineReducers } from 'redux';
import { readReducer, PassReadState } from './read';

export interface PassRootState {
  read: PassReadState;
  // TODO write: WriteState;
}

export const passReducer = combineReducers<PassRootState>({
  read: readReducer,
  // TODO write: writeReducer
});
