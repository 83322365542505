import { Facilities, FacilitiesDataProps, FacilitiesActionProps } from './Facilities';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { getSitecoreAnlaeggningar } from 'store/anlaeggningar';
import { getTranslate } from 'store/global/dictionary';
import { getActiveFoerening } from 'utils';
import { getIsFetching, FetchStatusAggregateTypes } from 'store/global/fetch';

const mapStateToProps = (state: RootState, props: any): FacilitiesDataProps => {
  const foerening = getActiveFoerening(state.global.session.inloggadeFoereningar, state.global.session.activeFoerening);
  return {
    activeFoerning: foerening,
    anlaeggningar: state.anlaeggningar.read.sitecoreAnlaeggningar,
    translate: getTranslate(state.locale),
    isFetching: foerening
      ? getIsFetching(state.global.fetch, foerening.Id, FetchStatusAggregateTypes.ANLAEGGNINGAR)
      : false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): FacilitiesActionProps => ({
  getSitecoreAnlaeggningar: (foereningNamn: string, foereningId: number, countryId: number) =>
    dispatch(getSitecoreAnlaeggningar(foereningNamn, foereningId, countryId)),
});

export const FacilitiesContainer = connect(mapStateToProps, mapDispatchToProps)(Facilities);
