import * as React from 'react';

interface FormContainerProps {
  className?: string;
  title?: string;
  children?: JSX.Element | JSX.Element[];
}

export const FormContainer: React.SFC<FormContainerProps> = (props: FormContainerProps) => (
  <div className="ShoppingBasketForm__Container">{props.children}</div>
);
