export enum LanguageActionTypes {
  SET_LANGUAGE = 'UI/LANGUAGE/SET_LANGUAGE',
}

export type SetLanguageAction = {
  type: LanguageActionTypes.SET_LANGUAGE;
  language: fos.api.Language;
};

export const setLanguage = (language: fos.api.Language): SetLanguageAction => ({
  type: LanguageActionTypes.SET_LANGUAGE,
  language,
});

export type UILanguageActions = SetLanguageAction;
