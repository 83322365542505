import { connect } from 'react-redux';
import { RootState } from '../../store';
import { FoereningarList, FoereningarListProps } from './FoereningarList';
import { getTranslate } from 'store/global/dictionary';

interface FoereningarListContainerProps {
  readonly labelColor: string;
  readonly shouldValidate?: boolean;
  onChange: (foereningId: number) => void;
  theme?: string;
  disabled?: boolean;
  tabindex?: number;
  value: number;
}

const mapStateToProps = (state: RootState, props: FoereningarListContainerProps): FoereningarListProps => {
  return {
    labelColor: props.labelColor,
    foereningar: state.foereningar.read,
    onChange: props.onChange,
    translate: getTranslate(state.locale),
    shouldValidate: props.shouldValidate,
    disabled: props.disabled,
    tabindex: props.tabindex,
    value: props.value,
  };
};

export const FoereningarListContainer = connect(mapStateToProps)(FoereningarList);
