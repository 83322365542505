import { connect } from 'react-redux';
import { RootState } from 'store';
import { PassDetails, PassDetailsActionProps, PassDetailsExternalDataProps, PassDetailsDataProps } from './PassDetails';
import { getTranslate } from 'store/global/dictionary';
import { fetchPassDetails } from 'store/pass-details/passDetailsThunks';
import { getBookingsInBackground } from 'store/bokningar/bokningarThunks';
import {
  getIsFetching,
  FetchStatusAggregateTypes,
  getHasLoadFailed,
  getIsFetchingInBackgound,
} from 'store/global/fetch';

const mapState = (state: RootState, props: PassDetailsExternalDataProps): PassDetailsDataProps => {
  const passId =
    state.location.payload && state.location.payload.passId
      ? (state.location.payload.passId as string).trim()
      : state.passDetails.read.pass
      ? state.passDetails.read.pass.PassId
      : undefined;
  let anlaeggningsId;
  for (let i = 0; i < state.pass.read.days.length; i++) {
    const pass = state.pass.read.days[i].items.find(p => p.PassId === passId);
    if (!!pass) {
      anlaeggningsId = pass.AnlaeggningsId;
      break;
    }
  }
  return {
    passId,
    anlaeggningsId: anlaeggningsId,
    pass: state.passDetails.read.pass,
    translate: getTranslate(state.locale),
    fetchFailed:
      !!passId &&
      !(
        !!state.global.session.activeFoerening &&
        getIsFetching(state.global.fetch, state.global.session.activeFoerening, FetchStatusAggregateTypes.PASS_LIST)
      ) &&
      getHasLoadFailed(state.global.fetch, passId, FetchStatusAggregateTypes.PASS),
    isFetching:
      !!passId &&
      (getIsFetching(state.global.fetch, passId, FetchStatusAggregateTypes.PASS) ||
        getIsFetchingInBackgound(state.global.fetch, passId, FetchStatusAggregateTypes.PASS)),
    bokningar: state.bokningar.bokningar,

    ...props,
  };
};

const mapDispatch: PassDetailsActionProps = {
  fetchPassDetails,
  getBookingsInBackground,
};

export default connect(mapState, mapDispatch)(PassDetails);
