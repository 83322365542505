import { ThunkAction } from 'store/rootReducer';
import { api } from 'api';
import { getNews } from '.';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from 'store/global/fetch';
import { constructSafeName } from 'utils';

export const fetchNews = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const activeFoerening = state.global.session.activeFoerening;
  const inloggadeFoereningar = state.global.session.inloggadeFoereningar;
  const userId = state.global.session.userId;

  if (!userId) {
    return;
  }
  if (!activeFoerening || !inloggadeFoereningar || inloggadeFoereningar.length < 1) {
    dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.NEWS));
    return;
  }
  const foerening = state.global.session.inloggadeFoereningar.find(
    info => info.Id.toString() === activeFoerening.toString()
  );

  if (!foerening) {
    dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.NEWS));
    return;
  }

  const foereningName = foerening.Namn;
  dispatch(fetching(userId, FetchStatusAggregateTypes.NEWS));
  const promise = api.get<Array<fos.api.News>>(
    `Nyhet/Nyheter?userId=${userId}&foereningName=${constructSafeName(foereningName)}`
  );
  promise.then(result => {
    if (!result.error) {
      dispatch(fetched(userId, FetchStatusAggregateTypes.NEWS, result));
      dispatch(getNews(result.result));
    } else {
      dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.NEWS));
    }
  });
};
