import * as React from 'react';
import { MainButton } from '../common';
import { Banner } from '../common';
import background from '../../static/assets/background-login.jpg';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { IconBigClose } from 'components/icons';
import { SitecoreDictionary } from 'models';

import { routesMap, LocationActionTypes } from 'store/location';
import { TranslateFunction } from 'react-localize-redux';

export interface LoginPromptDataProps {
  translate: TranslateFunction;
}
export interface LoginPromptActionProps {
  setSiteSwitcherToVisible: () => void;
  toggleLoginPromptOpen: () => void;
}

export type LoginPromptProps = LoginPromptDataProps & LoginPromptActionProps;

export class LoginPrompt extends React.Component<LoginPromptProps, {}> {
  close = (e: React.MouseEvent<HTMLElement>, cat: string) => {
    e.stopPropagation();
    this.props.toggleLoginPromptOpen();
  };

  onLoginClick = () => {
    this.props.setSiteSwitcherToVisible();
  };

  render() {
    return (
      <div className="Banner-LoginPrompt__Wrap">
        <div className="Overlay__Content__Header__Close" onClick={e => this.close(e, '')}>
          <IconBigClose />
        </div>
        <Banner backgroundUrl={background} className="Banner-LoginPrompt">
          <h4>{this.props.translate(SitecoreDictionary.Login.PromptTitle)}</h4>
          <p>{this.props.translate(SitecoreDictionary.Login.PromptDescription)}</p>

          <div>
            <small>{this.props.translate(SitecoreDictionary.Login.PromptSignUpDescription)}</small>
            <FancyNavLink
              className="Banner-LoginPrompt--button Button Button--white Button--solid"
              to={routesMap[LocationActionTypes.PublicRegister]}
              clickHandler={e => this.close(e, 'Medlemskap')}
            >
              <React.Fragment>{this.props.translate(SitecoreDictionary.Login.PromptSignUpButton)}</React.Fragment>
            </FancyNavLink>
          </div>

          <small>{this.props.translate(SitecoreDictionary.Login.PromptLoginDescription)}</small>
          <MainButton
            className="Banner-LoginPrompt--button"
            type="submit"
            buttonStyle="solid"
            color="white"
            title="Logga in"
            handleOnClick={() => this.onLoginClick()}
          />
        </Banner>
      </div>
    );
  }
}

export default LoginPrompt;
