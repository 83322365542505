import moment from 'moment';
import { localStorage } from './localStorage';

const storedAuthName: string = process.env.REACT_APP_AUTH_COOKIE as string;

export function readToken(): fos.api.TokenModel | null {
  const storedAuth = localStorage.getItem<fos.api.TokenModel>(storedAuthName);
  if (!storedAuth) {
    return null;
  }
  return storedAuth.value;
}

export function getRefreshToken(): string {
  const authToken = readToken();
  if (authToken) {
    return authToken.RefreshToken;
  }

  return '';
}

export function hasAValidAuthToken(): boolean {
  const authToken = readToken();

  if (!authToken) {
    return false;
  }

  const giltighetstid = moment(authToken.Giltighetstid);
  if (!authToken.Token || moment().isAfter(giltighetstid)) {
    return false;
  }

  return true;
}

export function hasAValidRefreshToken(): boolean {
  const authToken = readToken();

  if (!authToken) {
    return false;
  }

  if (authToken.RefreshToken) {
    return true;
  }

  return false;
}

export function clearSavedAuth() {
  localStorage.removeItem(storedAuthName);

  sessionStorage.clear();
  const arr: string[] = []; // Array to hold the keys
  // Iterate over localStorage and insert the keys that meet the condition into arr
  for (let i = 0; i < window.localStorage.length; i++) {
    const order = window.localStorage.key(i);
    if (order) {
      if (order.substring(0, 5) === 'ORDER') {
        arr.push(order);
      }
    }
  }

  // Iterate over arr and remove the items by key
  for (let i = 0; i < arr.length; i++) {
    window.localStorage.removeItem(arr[i]);
  }
}
