import * as React from 'react';
import { MainInput } from 'components/common';
import { MainInputProps } from './MainInput';
import { isValidEmail } from 'utils';

interface EmailInputActionProps {
  onValidChanged?: (isValid: boolean) => void;
}

interface EmailInputState {
  isValid?: boolean;
  shouldValidate?: boolean;
}

type EmailInputProps = EmailInputActionProps & MainInputProps;

export class EmailInput extends React.Component<EmailInputProps, EmailInputState> {
  constructor(props: EmailInputProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const shouldValidate = this.props.shouldValidate;
    this.setState({
      shouldValidate: this.props.shouldValidate,
    });
    if (shouldValidate) {
      this.validate(this.props.value as string);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: EmailInputProps): void {
    if (newProps.shouldValidate !== this.state.shouldValidate) {
      this.setState({
        shouldValidate: this.props.shouldValidate,
      });
    }
    if (this.props.value !== newProps.value && newProps.shouldValidate) {
      this.validate(newProps.value as string);
    }
  }

  render(): React.ReactNode {
    return <MainInput {...this.props} onValueChange={newValue => this.onValueChange(newValue as string)} />;
  }

  private onValueChange(newValue: string): void {
    if (this.state.shouldValidate) {
      this.validate(newValue);
    }
    if (this.props.onValueChange) {
      this.props.onValueChange(newValue);
    }
  }

  private validate(value: string): void {
    const isValid: boolean = isValidEmail(value);
    if (this.props.onValidChanged && this.props.isValid !== isValid) {
      this.props.onValidChanged(isValid);
    }
  }
}
