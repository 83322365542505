import * as React from 'react';
import { Checkbox } from 'components/common';
import { MedlemsVillkor } from './medlemsVillkor';
import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface TermsCheckboxDataProps {
  isChecked?: boolean;
  isDesktopMode: boolean;
  translate: TranslateFunction;
}

interface TermsCheckboxActionProps {
  onValueChange: (newValue: boolean) => void;
}

interface TermsCheckboxState {
  isMedlemsVillkorOpened?: boolean;
}

type TermsCheckboxProps = TermsCheckboxActionProps & TermsCheckboxDataProps;

export class TermsCheckbox extends React.Component<TermsCheckboxProps, TermsCheckboxState> {
  constructor(props: TermsCheckboxProps) {
    super(props);
    this.state = {};
    this.toogleMedlemskapVillkor = this.toogleMedlemskapVillkor.bind(this);
  }

  render() {
    return (
      <div className="TermsCheckbox__Container">
        {this.state.isMedlemsVillkorOpened && (
          <MedlemsVillkor isDesktopMode={this.props.isDesktopMode} close={this.toogleMedlemskapVillkor} />
        )}
        <h4 className="TermsCheckbox__Title">{this.props.translate(SitecoreDictionary.Cart.TermsCheckboxTitle)}</h4>
        <Checkbox
          className="TermsCheckbox__Checkbox"
          checked={this.props.isChecked}
          onValueChange={newVal => this.props.onValueChange(newVal)}
        >
          <span>
            {this.props.translate(SitecoreDictionary.Cart.IAccept)}
            &nbsp;
          </span>
          <span>
            <div className="TermsCheckbox__Link" onClick={this.toogleMedlemskapVillkor}>
              {this.props.translate(SitecoreDictionary.Cart.TheTerms)}
            </div>
          </span>
        </Checkbox>
      </div>
    );
  }

  private toogleMedlemskapVillkor() {
    this.setState({
      isMedlemsVillkorOpened: !this.state.isMedlemsVillkorOpened,
    });
  }
}
