import * as React from 'react';
import { ProductGroup } from './ProductGroup/ProductGroup';
import { ProductTypes } from 'store/shop';

import { SitecoreDictionary } from 'models/SitecoreDictionary';
import { CouldAddProduct } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface ProductsProps {
  data?: boolean;
  shopFilter: Array<fos.api.FilterCategory>;
  filters: Array<fos.api.FilterCategory>;
  productType: ProductTypes;
  products: Array<fos.api.Product>;
  isShowing: boolean;
  productsInBasket?: Array<fos.api.Product>;
  translate: TranslateFunction;
  foereningNamn: string;
  isAdmin: boolean;
  canAddProduct: (product: fos.api.Product) => CouldAddProduct;
  addProduct: (product: fos.api.Product) => void;
  removeProduct: (product: fos.api.Product) => void;
  goToProductDetails: (id: number, name: string, foereningNamn: string, customerType: number) => void;
}

interface ProduktGroup {
  title: string;
  id: string;
  products: Array<fos.api.Product>;
}

interface ProduktWithHits {
  hits: number;
  product: fos.api.Product;
}

export class Products extends React.Component<ProductsProps> {
  render() {
    var productData: ProduktGroup[] = [];

    if (this.props.products.length > 0) {
      let productType = this.props.productType;
      productData = this.mapProductGroups(
        this.props.filters,
        this.props.products.filter(item => {
          return item.Types.indexOf(productType) > -1;
        })
      );
    }

    var allProducts = productData.find(o => o.id === '00');
    var strictlyMatchingProducts = productData.find(o => o.id === '01');
    var productsLoaded = this.props.products.length > 0;

    return (
      <div className={'Shop__Products ' + (!this.props.isShowing ? 'Shop__Products--hidden' : '')}>
        <div className="Products__List">
          {productsLoaded && !allProducts && !strictlyMatchingProducts && (
            <div className="List__Header">
              {this.props.translate(SitecoreDictionary.Shop.NoProductsFoundFromFilter)}
            </div>
          )}
          {productData.map(item => {
            return (
              <ProductGroup
                productsInBasket={this.props.productsInBasket}
                goToProductDetails={this.props.goToProductDetails}
                title={item.title}
                key={item.id}
                data={item.products}
                foereningNamn={this.props.foereningNamn}
                canAddProduct={this.props.canAddProduct}
                addProduct={this.props.addProduct}
                removeProduct={this.props.removeProduct}
                translate={this.props.translate}
                isAdmin={this.props.isAdmin}
              />
            );
          })}
        </div>
      </div>
    );
  }

  mapProductGroups(filters: Array<fos.api.FilterCategory>, products: Array<fos.api.Product>): ProduktGroup[] {
    var groupedProducts: ProduktGroup[] = [];
    var selectedFilters = this.getSelectedFilters(filters);
    var nrOfSelectedFilters = selectedFilters.length;
    if (nrOfSelectedFilters < 1) {
      groupedProducts.push({
        title: this.props.translate(SitecoreDictionary.Shop.AllProducts) as string,
        id: '00',
        products: products,
      });
    } else {
      var productsWithHits = this.getProductsWithFilterHits(selectedFilters, products);

      var exactHits = productsWithHits.filter(product => {
        return product.hits === nrOfSelectedFilters;
      });
      var others = productsWithHits.filter(product => {
        return product.hits !== nrOfSelectedFilters && product.hits >= 0;
      });

      if (exactHits.length) {
        groupedProducts.push({
          title: this.props.translate(SitecoreDictionary.Shop.ExactHits) as string,
          id: '01',
          products: exactHits.map(this.reduceProductHit),
        });
      }

      if (others.length) {
        groupedProducts.push({
          title:
            exactHits.length > 0
              ? (this.props.translate(SitecoreDictionary.Shop.OtherHits) as string)
              : (this.props.translate(SitecoreDictionary.Shop.NoExactHits) as string),
          id: '02',
          products: others
            .sort((a, b) => {
              return b.hits - a.hits;
            })
            .map(this.reduceProductHit),
        });
      }
    }
    return groupedProducts;
  }

  getSelectedFilters(filters: Array<fos.api.FilterCategory>): string[] {
    var arr: string[] = [];
    if (filters.length) {
      arr = filters
        .map(x => x.Children)
        .reduce((a, b) => a.concat(b))
        .filter(x => x.isSelected)
        .map(x => x.SitecoreId);
    }
    return arr;
  }

  getProductsWithFilterHits(selectedFilters: string[], products: Array<fos.api.Product>): ProduktWithHits[] {
    var data: ProduktWithHits[] = [];

    for (var i = 0; i < products.length; i++) {
      var product = products[i];
      var hits = this.getHitsForProductsAndFilter(selectedFilters, product);
      data.push({ product, hits });
    }

    return data;
  }

  getHitsForProductsAndFilter(selectedFilters: string[], product: fos.api.Product): number {
    var hits = 0;
    for (var i = 0; i < selectedFilters.length; i++) {
      if (product.FilterVal.indexOf(selectedFilters[i]) > -1) {
        hits += 1;
      }
    }
    return hits;
  }

  reduceProductHit(product: ProduktWithHits): fos.api.Product {
    return product.product;
  }
}
