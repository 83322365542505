import { RootState } from 'store';
import { connect } from 'react-redux';
import { Statistics, StatisticsActionProps, StatisticsDataProps } from './statistics';
import { getEntityStatus, FetchStatusAggregateTypes } from 'store/global/fetch';
import { fetchTraeningsstatistik } from 'store/statistics';

import { getTranslate } from 'store/global/dictionary';

const mapState = (state: RootState): StatisticsDataProps => ({
  statistics: state.statistics.read.Statistics,
  fetchState: getEntityStatus(state.global.fetch, -1, FetchStatusAggregateTypes.TRAENINGSSTATISTIK),
  translate: getTranslate(state.locale),
});

const mapDispatch: StatisticsActionProps = {
  fetchTraeningsstatistik,
};

export default connect(mapState, mapDispatch)(Statistics);
