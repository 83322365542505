import * as React from 'react';
import { IconHeart } from 'components/icons';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface PreferenceItemProps {
  title: string;
  disabled: boolean;
  active?: boolean;
  removeItem: (preference: string) => void;
  addItem: (preference: string) => void;
  translate?: TranslateFunction;
  selectedCallback?: () => void;
}

interface PreferenceItemState {
  selected: boolean;
}

export class PreferenceItem extends React.Component<PreferenceItemProps, PreferenceItemState> {
  cssClass = this.props.disabled ? 'Disabled' : '';

  constructor(props: PreferenceItemProps) {
    super(props);
    this.state = {
      selected: props.active ? props.active : false,
    };

    if (props.active) {
      this.props.addItem(this.props.title);
    }
  }

  render() {
    return (
      <div
        className={'PreferenceItem ' + this.cssClass}
        onClick={this.props.disabled ? () => null : () => this.toggleSelected(this.state.selected)}
      >
        <div className={'PreferenceItem__Icon ' + this.cssClass}>
          <IconHeart styling={this.state.selected || this.props.disabled ? 'filled' : 'empty'} />
        </div>
        <div className={'PreferenceItem__Text ' + this.cssClass}>
          {this.props.translate
            ? this.props.translate(SitecoreDictionary.TrainingPreferences.Preferences + this.props.title)
            : this.props.title}
        </div>
      </div>
    );
  }

  private toggleSelected(selected: boolean) {
    if (selected === true) {
      this.props.removeItem(this.props.title);
    } else {
      this.props.addItem(this.props.title);
    }
    this.setState({ selected: !this.state.selected });
    if (this.props.selectedCallback) {
      this.props.selectedCallback();
    }
  }
}
