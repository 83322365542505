import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import {
  ShoppingBasketProductsListExternalProps,
  ShoppingBasketProductsListActionProps,
  ShoppingBasketProductsList,
  ShoppingBasketProductsListDataProps,
} from './shoppingBasketProductsList';
import { removeCoupon, removeSubscription } from '../../shop/Order/Order';
import { showMembershipMessage } from 'store/basket';

const mapStateToProps = (
  state: RootState,
  props: ShoppingBasketProductsListExternalProps
): ShoppingBasketProductsListDataProps => {
  return {
    order: state.shoppingBasket.order,
    showMessage: state.shoppingBasket.showMembershipMessage,
    productList: props.productList,
    translate: props.translate,
    activeFoerening: props.activeFoerening,
    isAdmin: props.isAdmin,
  };
};

const mapActionsToProps = (dispatch: Dispatch): ShoppingBasketProductsListActionProps => ({
  deleteCoupon: (coupon: fos.api.Coupon, orderId: number, foereningId: number) =>
    removeCoupon(foereningId, orderId, coupon.name),
  deleteSubscription: (productId: number, orderId: number, foereningId: number) =>
    removeSubscription(foereningId, orderId, productId),
  showMembershipMessage: (showMessage: boolean) => dispatch(showMembershipMessage(showMessage)),
});

export const ShoppingBasketProductsListContainer = connect(
  mapStateToProps,
  mapActionsToProps
)(ShoppingBasketProductsList);
