import * as React from 'react';

export interface IconMenuBookedSessionsProps {
  title?: string;
  color?: string;
}

export class IconMenuBookedSessions extends React.Component<IconMenuBookedSessionsProps, {}> {
  classA = 'fill:white;';
  classB = 'fill:#333;';

  // TODO: Fix translation support for text
  render() {
    const strokeColor = this.props.color || '#343434';
    return (
      <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" className="IconMenuBookedSessions">
        <title>{this.props.title}</title>
        <style>
          {`
            .IconMenuBookedSessions {
              width: 55px;
              height: 55px;
            }
            .IconMenuBookedSessions .cls-2 {
              fill:none;
              stroke:${strokeColor};
              stroke-miterlimit:10
            }
          `}
        </style>

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path fill="none" d="M0 0h40v40H0z" />
            <rect className="cls-2" x="8.07" y="7.71" width="24.06" height="24.06" rx="6.23" ry="6.23" />
            <path className="cls-2" d="M31.49 14.81H8.36" />
            {/* tslint:disable-next-line:max-line-length */}
            <path
              d="M11.62 18.07l1.76 1.75m-.01-1.76l-1.75 1.76m9.91-1.78l1.76 1.75m0-1.76l-1.75 1.76m4.9-1.77l1.76 1.75m0-1.75l-1.75 1.76m-14.82 2.61l1.76 1.75m0-1.75l-1.75 1.76m5.04-1.77l1.76 1.75m-.01-1.76l-1.75 1.76m4.86-1.76l1.76 1.74m0-1.75l-1.75 1.76m-4.86 2.18l1.76 1.75m-.01-1.75l-1.75 1.76m9.78-1.79l1.76 1.75m-.01-1.75l-1.75 1.76"
              strokeLinecap="round"
              fill="none"
              stroke={strokeColor}
              strokeMiterlimit="10"
            />
          </g>
        </g>
      </svg>
    );
  }
}
