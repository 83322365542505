import { combineReducers } from 'redux';
import { readReducer, AnlaeggningarReadState, AnlaeggningarReadAction } from './read';

export type AnlaeggningarAction = AnlaeggningarReadAction;

export interface AnlaeggningarRootState {
  read: AnlaeggningarReadState;
}

export const anlaeggningarReducer = combineReducers<AnlaeggningarRootState>({
  read: readReducer,
});
