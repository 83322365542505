interface GetMedlemskapOchKortAction {
  type: MedlemskapOchKortReadActionType.GetMedlemskapOchKort;
  abonnemang?: fos.api.Abonnemang;
}

interface ClearMedlemskapOchKortAction {
  type: MedlemskapOchKortReadActionType.ClearMedlemskapOchKort;
}

export type MedlemskapOchKortReadActions = GetMedlemskapOchKortAction | ClearMedlemskapOchKortAction;

export enum MedlemskapOchKortReadActionType {
  GetMedlemskapOchKort = 'GET_MEDLEMSKAP_OCH_KORT',
  ClearMedlemskapOchKort = 'CLEAR_MEDLEMSKAP_OCH_KORT',
}

export const getMedlemskapOchKort = (abonnemang?: fos.api.Abonnemang): MedlemskapOchKortReadActions => ({
  type: MedlemskapOchKortReadActionType.GetMedlemskapOchKort,
  abonnemang,
});
