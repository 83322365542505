import { FetchCommandTypes, getIsExecuting } from 'store/global/fetch';
import { BokningStatusPopup, BokningStatusDataProps } from './BokningStatusPopup';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { getTranslate } from 'store/global/dictionary/dictionary';

const mapStateToProps = (state: RootState, props: any): BokningStatusDataProps => {
  return {
    statusMessage: getMessage(state, props.passId, props.passDay),
    isLoading: getIsExecuting(state.global.fetch, props.passId, FetchCommandTypes.BOKA_PASS),
    translate: getTranslate(state.locale),
  };
};

const getMessage = (state: RootState, id: number | string, passDay: string) => {
  const day = state.pass.read.days.find(d => d.key === passDay);
  if (!day) {
    return undefined;
  }
  const pass = day.items.find(p => p.PassId === id);
  return pass ? pass.statusMessage : undefined;
};

export const BokningStatusPopupContainer = connect(mapStateToProps)(BokningStatusPopup);
