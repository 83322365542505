import { ShopFilterCategoryState } from 'store/global/ui/shop-filter';
import { ScheduleFilterCategoryState } from 'store/global/ui/schedule-filter';

function hasCategoryExpanded(categoryState: ShopFilterCategoryState | ScheduleFilterCategoryState): boolean {
  const categoryKeys = Object.keys(categoryState);
  let filterHasAnExpandedCategory = !!categoryKeys.find(categoryName => {
    return categoryState[categoryName].atTop;
  });

  return filterHasAnExpandedCategory;
}

export const filterUtils = {
  hasCategoryExpanded,
};
