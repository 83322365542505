import * as React from 'react';
import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface ConfirmationTextBoxDataProps {
  text: string;
  translate: TranslateFunction;
}

interface ConfirmationTextBoxActionProps {
  handleNo: () => void;
  handleYes: () => void;
}

type ConfirmationTextBoxProps = ConfirmationTextBoxDataProps & ConfirmationTextBoxActionProps;

export const ConfirmationTextBox = (props: ConfirmationTextBoxProps) => {
  const handleNo = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    props.handleNo();
  };

  const handleYes = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    props.handleYes();
  };

  return (
    <div className="AvbokaConfirmation">
      <div className="AvbokaConfirmation__Info">{props.text}</div>

      <div className="AvbokaConfirmation__Option">
        <div className="Option__NoArea">
          <button onClick={handleNo}>{props.translate(SitecoreDictionary.Common.No)}</button>
        </div>

        <div className="Option__YesArea">
          <button onClick={handleYes}>{props.translate(SitecoreDictionary.Common.Yes)}</button>
        </div>
      </div>
    </div>
  );
};
