import * as React from 'react';
import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';

interface DetailsBlockDesktopProps {
  translate: TranslateFunction;
  pass: fos.api.PassDetails;
}

export const DetailsBlockDesktop: React.FunctionComponent<DetailsBlockDesktopProps> = ({ translate, pass }) => {
  const mStartTime = moment(pass.StartTime);
  const mEndTime = moment(pass.EndTime);
  const dayAndMonth = mStartTime.format('LL');
  const day = mStartTime.format('dddd');
  const startTime = mStartTime.format('HH:mm');
  const endTime = mEndTime.format('HH:mm');

  return (
    <div className="PassDetails__Details hide-for-medium-down">
      <div className="Trainers">
        <ul>
          {pass.Instruktoerer.map(name => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </div>
      <div className="Day">
        {startTime && (
          <div className="Day__WeekDay">
            <span className="capitalize">{day}</span>
          </div>
        )}
        {startTime && <div className="Day__Date">{dayAndMonth}</div>}
      </div>
      <div className="Time">
        <div className="Time__Hours">{`${startTime} - ${endTime}`}</div>
        <div className="Time__Duration">{pass.Length} min</div>
      </div>
      <div className="Location">
        <div className="Location__Association">{pass.AnlaeggningsNamn}</div>
        <div className="Location__Room">{pass.Lokal}</div>
      </div>
    </div>
  );
};
