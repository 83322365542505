import * as React from 'react';
import { Checkbox } from 'components/common';
import { SitecoreDictionary } from 'models';

import { SmsEmailVillkor } from './smsEmailVillkor';
import { TranslateFunction } from 'react-localize-redux';

interface TermsCheckboxDataProps {
  isChecked?: boolean;
  isDesktopMode: boolean;
  translate: TranslateFunction;
}

interface TermsCheckboxActionProps {
  onValueChange: (newValue: boolean) => void;
}

interface TermsCheckboxState {
  isSmsEmailVillkorOpened?: boolean;
}

type TermsCheckboxProps = TermsCheckboxActionProps & TermsCheckboxDataProps;

export class TermsCheckboxSmsEmail extends React.Component<TermsCheckboxProps, TermsCheckboxState> {
  constructor(props: TermsCheckboxProps) {
    super(props);
    this.state = {};
    this.toggleSmsEmailVillkor = this.toggleSmsEmailVillkor.bind(this);
  }

  render() {
    return (
      <div className="TermsCheckbox__Container">
        {this.state.isSmsEmailVillkorOpened && (
          <SmsEmailVillkor isDesktopMode={this.props.isDesktopMode} close={this.toggleSmsEmailVillkor} />
        )}
        <h4 className="TermsCheckbox__Title">{this.props.translate(SitecoreDictionary.GDPR.TermsCheckBoxTitleGDPR)}</h4>

        <Checkbox
          className="TermsCheckbox__Checkbox"
          checked={this.props.isChecked}
          onValueChange={newVal => this.props.onValueChange(newVal)}
        >
          <span>
            {this.props.translate(SitecoreDictionary.Cart.IAccept)}
            &nbsp;
          </span>
          <span>
            <div className="TermsCheckbox__Link" onClick={this.toggleSmsEmailVillkor}>
              {this.props.translate(SitecoreDictionary.Cart.TheTerms)}
            </div>
          </span>
        </Checkbox>
      </div>
    );
  }

  private toggleSmsEmailVillkor() {
    this.setState({
      isSmsEmailVillkorOpened: !this.state.isSmsEmailVillkorOpened,
    });
  }
}
