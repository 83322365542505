import * as React from 'react';

interface CheckboxDataProps {
  label?: string;
  className?: string;
  checked?: boolean;
  name?: string;
  required?: boolean;
  readonly?: boolean;
  children?: string | JSX.Element | JSX.Element[];
}

interface CheckboxActionProps {
  onValueChange?: (newValue: boolean) => void;
}

export type CheckboxProps = CheckboxActionProps & CheckboxDataProps;

export const Checkbox: React.SFC<CheckboxProps> = (props: CheckboxProps) => {
  const handleOnValueChange = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onValueChange) {
      props.onValueChange(!props.checked);
    }
  };

  const getIconClasses = (): string => {
    let classes: string = 'Checkbox__Icon';
    if (props.checked) {
      classes += ' Checkbox__Icon__Checked';
    }
    return classes;
  };

  const getContainerClasses = (): string => {
    let classes: string = 'Checkbox__Container';
    if (props.className) {
      classes += ' ' + props.className;
    }
    return classes;
  };

  return (
    <label className={getContainerClasses()}>
      <div className={getIconClasses()} onClick={event => handleOnValueChange(event)} />
      <div className="Checkbox__Details">{props.children}</div>
    </label>
  );
};
