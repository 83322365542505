import { combineReducers } from 'redux';

import { trainingPreferencesReadReducer, TrainingPreferencesReadState, TrainingPreferencesReadAction } from './read';

import {
  trainingPreferencesWriteReducer,
  TrainingPreferencesWriteState,
  TrainingPreferencesWriteAction,
} from './write';

export type TrainingPreferencesAction = TrainingPreferencesReadAction | TrainingPreferencesWriteAction;

export interface TrainingPreferencesRootState {
  read: TrainingPreferencesReadState;
  write: TrainingPreferencesWriteState;
}

export const TrainingPreferencesReducer = combineReducers<TrainingPreferencesRootState>({
  read: trainingPreferencesReadReducer,
  write: trainingPreferencesWriteReducer,
});
