import * as React from 'react';

import { SettingsLinks } from './SettingsLinks';
import { SettingBanner } from './SettingsBanner';
import { MainButton } from '../common';
import { SitecoreDictionary } from 'models';
import { isApp } from 'models/app/MobileOSType';
import { TranslateFunction } from 'react-localize-redux';

export enum SettingsPages {
  Profile,
  TrainingPreferences,
  PaymentSettings,
  AutogiroSettings,
  NotificationSettings,
  UserAccount,
  CalendarSync,
}

export interface SettingsLocalProps {
  activeSettingsPage?: SettingsPages;
  isDesktopMode: boolean;
}

export interface SettingsDataProps {
  translate: TranslateFunction;
  inloggadeFoereningar: fos.api.InloggadeFoerening[];
  activeFoereing: number;
  settingsPageTitle?: string;
  image: string;
  shopActive?: boolean;
}

export interface SettingsActionProps {
  logout: (goToLogin?: boolean, shouldRedirectToPublicWeb?: boolean) => void;
}

type SettingsProps = SettingsDataProps & SettingsLocalProps & SettingsActionProps;

export const Settings: React.SFC<SettingsProps> = props => (
  <div className={'Settings ' + (props.isDesktopMode ? 'Settings__Desktop ' : '')}>
    <div className="Settings__MainContainer">
      <SettingBanner
        activeSettingsPage={props.activeSettingsPage}
        translate={props.translate}
        isDesktopMode={props.isDesktopMode}
        settingsPageTitle={props.settingsPageTitle}
        image={props.image}
      />
      <SettingsLinks
        isDesktopMode={props.isDesktopMode}
        activeSettingsPage={props.activeSettingsPage}
        translate={props.translate}
        shopActive={props.shopActive}
      />
    </div>
    {
      //  props.logout(App())
    }
    <div className="Settings__Logout">
      <MainButton
        className="Button"
        buttonStyle="outline"
        title={props.translate(SitecoreDictionary.Profile.Logout) as string}
        handleOnClick={() => {
          isApp() ? props.logout(true, false) : props.logout(false, true);
        }}
      />
    </div>
  </div>
);
