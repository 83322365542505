import * as React from 'react';

import { ListItem } from './ListItem';

import { SitecoreDictionary, NotificationOptions } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface NotificationSettingsDataProps {
  PassCancelled?: boolean;
  Vaentelista?: boolean;
  News?: boolean;
  TraeningskortAboutToExpire?: boolean;
  translate: TranslateFunction;
}

export interface NotificationSettingsActionProps {
  fetchPushNotificationSettings: () => void;
  updatePushNotificationSettings: (setting: string) => void;
}

export type NotificationSettingsProps = NotificationSettingsActionProps & NotificationSettingsDataProps;

export class NotificationSettings extends React.Component<NotificationSettingsProps> {
  componentDidMount() {
    this.props.fetchPushNotificationSettings();
  }

  render() {
    return (
      <div className="NotificationSettings">
        <ListItem
          header={this.props.translate(SitecoreDictionary.PushNotificationSettings.WorkoutTitle) as string}
          subheader={this.props.translate(SitecoreDictionary.PushNotificationSettings.WorkoutText) as string}
          activated={!!this.props.PassCancelled}
          onClick={() => this.props.updatePushNotificationSettings(NotificationOptions.PassCancelled)}
          showSpinner={this.props.PassCancelled === undefined}
        />
        <ListItem
          header={this.props.translate(SitecoreDictionary.PushNotificationSettings.QueueTitle) as string}
          subheader={this.props.translate(SitecoreDictionary.PushNotificationSettings.QueueText) as string}
          activated={!!this.props.Vaentelista}
          onClick={() => this.props.updatePushNotificationSettings(NotificationOptions.Vaentelista)}
          showSpinner={this.props.Vaentelista === undefined}
        />
        <ListItem
          header={this.props.translate(SitecoreDictionary.PushNotificationSettings.NewsTitle) as string}
          subheader={this.props.translate(SitecoreDictionary.PushNotificationSettings.NewsText) as string}
          activated={!!this.props.News}
          onClick={() => this.props.updatePushNotificationSettings(NotificationOptions.News)}
          showSpinner={this.props.News === undefined}
        />
        {/* <ListItem
          header={this.props.translate(SitecoreDictionary.PushNotificationSettings.TrainingCardTitle) as string}
          subheader={this.props.translate(SitecoreDictionary.PushNotificationSettings.TrainingCardText) as string}
          activated={!!this.props.TraeningskortAboutToExpire}
          onClick={() => this.props.updatePushNotificationSettings(NotificationOptions.TraeningskortAboutToExpire)}
          showSpinner={this.props.TraeningskortAboutToExpire === undefined}
        /> */}
      </div>
    );
  }
}
