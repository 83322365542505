export enum ScheduleFilterCategoryName {
  Favoritfilter = 'Favoritfilter',
  Traeningsstaelle = 'Traeningsstaelle',
  Traeningspass = 'Traeningspass',
  LedareOrTraenare = 'LedareOrTraenare',
  Tidpunkt = 'Tidpunkt',
  Veckodag = 'Veckodag',
  Bokningsstatus = 'Bokningsstatus',
}

export interface ScheduleFilter {
  Favoritfilter: string[];
  Traeningsstaelle: string[];
  Traeningspass: string[];
  LedareOrTraenare: string[];
  Tidpunkt: string[];
  Veckodag: string[];
  Bokningsstatus: string[];
}

export type ScheduleFilterCategoryNames =
  | 'Favoritfilter'
  | 'Traeningsstaelle'
  | 'Traeningspass'
  | 'LedareOrTraenare'
  | 'Tidpunkt'
  | 'Veckodag'
  | 'Bokningsstatus';
