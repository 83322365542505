export enum AffaerssytemType {
  B = '1',
  P = '2',
}

export enum P_RESULTCODE {
  OK = 'OK',
  CANCEL = 'CANCEL',
  ERROR = 'ERROR',
}

export interface PaymentResult {
  type: AffaerssytemType;
  fid: string;
  error?: string;
  RESULTCODE?: P_RESULTCODE;
  orderid?: string;
  receiptid?: string;
}
