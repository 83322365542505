import { Reducer } from 'redux';
import { Action } from 'store';
import { NewNotificationActionTypes } from '.';

export interface NewNotificationState {
  newNotificationsCount?: number;
}

const initialState: NewNotificationState = {};

export const newNotificationReducer: Reducer<NewNotificationState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case NewNotificationActionTypes.SET_NEW_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        newNotificationsCount: action.value,
      };
    }
    default:
      return state;
  }
};
