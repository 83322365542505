import { Action } from 'store';
import { NewsActionType } from './newsActions';

export interface NewsRootState {
  news: Array<fos.api.News>;
}

const initialstate: NewsRootState = {
  news: [],
};

export const NewsReducer = (state = initialstate, action: Action): NewsRootState => {
  switch (action.type) {
    case NewsActionType.GetNews:
      return {
        ...state,
        news: action.news,
      };
    default:
      return state;
  }
};
