import * as React from 'react';
import CountUp from 'react-countup';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface ListOfStatisticsProps {
  data?: fos.api.TrainingStatisticsWorkoutType[];
  mainTitle: string;
  translate: TranslateFunction;
}

export class ListOfStatistics extends React.Component<ListOfStatisticsProps> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    if (!this.props.data || this.props.data.length === 0) {
      return <div />;
    } else {
      return (
        <div className="ListOfStatistics">
          <h3 className="ListOfStatistics__MainTitle h5">{this.props.mainTitle}</h3>

          <table className="ListOfStatistics__Table">
            {this.props.data.map(x => (
              <tr key={x.Id}>
                <td>
                  <CountUp start={0} end={x.Percentage} suffix="%" duration={2.75} useEasing={true} />
                </td>
                <td>
                  {x.WorkoutName
                    ? x.WorkoutName
                    : this.props.translate(SitecoreDictionary.Statistics.DefaultValueGym).toString()}
                </td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
  }
}
