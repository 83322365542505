import * as React from 'react';
import MainMenuContainer from 'components/main-menu/MainMenuContainer';
import { HeaderTitle } from './HeaderTitle';
import { LocationActionTypes } from 'store/location';

interface DesktopHeaderContentProps {
  isAuthenticated: boolean;
  isFirstVisit: boolean;
  location: LocationActionTypes;
  activeFoerening: number;
  showHeaderTitle: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
}

export const DesktopHeaderContent: React.SFC<DesktopHeaderContentProps> = props => (
  <div className={`Primary__Navigation${props.showHeaderTitle ? '--left' : ''}`}>
    {props.isAuthenticated &&
    ((props.location !== LocationActionTypes.ShoppingBasket &&
      props.location !== LocationActionTypes.PublicShoppingBasket) ||
      !props.isFirstVisit) ? (
      <MainMenuContainer />
    ) : props.showHeaderTitle ? (
      <div className={props.activeFoerening > 0 ? 'active-foe' : 'no-active-foe'}>
        <HeaderTitle title={props.headerTitle} subtitle={props.headerSubtitle} />
      </div>
    ) : (
      <div />
    )}
  </div>
);
