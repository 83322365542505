import { RootState } from './../rootReducer';
import { SitecoreDictionary } from 'models';
import { Query, redirect } from 'redux-first-router';
import { ThunkAction } from 'store';
import { switchView, LocationActionTypes, Location, LocationState } from '.';
import { store } from 'index';
import { TranslateFunction } from 'react-localize-redux';

export const siteHasHistory = (locationState: LocationState) =>
  !!locationState.prev && !!locationState.prev.type && locationState.kind !== 'redirect';

export const goToSchedule = (): ThunkAction => dispatch => dispatch(switchView(LocationActionTypes.Schedule));

export const goToHome = (): ThunkAction => dispatch => dispatch(switchView(LocationActionTypes.Home));

export const goToLogin = (): ThunkAction => dispatch => dispatch(switchView(LocationActionTypes.Login));

export const goBack =
  (withRedirect?: boolean): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const route = siteHasHistory(state.location)
      ? state.location.prev.type
      : isInShop(state.location.type)
      ? getIsPublicWebMode(state.location)
        ? LocationActionTypes.PublicShop
        : getIsAdminWebMode(state.location)
        ? LocationActionTypes.AdminShop
        : LocationActionTypes.Shop
      : LocationActionTypes.Home;

    if (withRedirect) {
      dispatch(redirectTo(route, state.location.prev.query, state.location.prev.payload));
    } else {
      dispatch(switchView(route, state.location.prev.query, state.location.prev.payload));
    }
  };

export const redirectTo =
  (location: LocationActionTypes, query?: Query, payload?: Object): ThunkAction =>
  dispatch => {
    const s = switchView(location, query, payload);
    return dispatch(redirect(s));
  };

const isInShop = (type: LocationActionTypes): boolean => {
  return (
    type === LocationActionTypes.Shop ||
    type === LocationActionTypes.PublicShop ||
    type === LocationActionTypes.AdminShop ||
    type === LocationActionTypes.ShoppingBasket ||
    type === LocationActionTypes.PublicShoppingBasket ||
    type === LocationActionTypes.AdminShoppingBasket ||
    type === LocationActionTypes.ProductDetails ||
    type === LocationActionTypes.PublicProductDetails ||
    type === LocationActionTypes.AdminProductDetails ||
    type === LocationActionTypes.AdminRegister
  );
};

interface ShowForLocation {
  location: LocationActionTypes;
  showOnDesktop?: boolean;
  hasTitle?: boolean;
  hasSubtitle?: boolean;
}

export const getLocation = (state: LocationState) => state as Location;

const pagesWithTitleHeader: ShowForLocation[] = [
  {
    location: LocationActionTypes.PublicShop,
    hasTitle: true,
    hasSubtitle: true,
  },
];

const publicWebPages: ShowForLocation[] = [
  {
    location: LocationActionTypes.PublicSchedule,
    showOnDesktop: false,
  },
  {
    location: LocationActionTypes.PublicShoppingBasket,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.PublicProductDetails,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.PublicShop,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.PublicRegister,
    showOnDesktop: true,
    hasTitle: true,
  },
  {
    location: LocationActionTypes.ResetPassword,
    showOnDesktop: true,
  },
  {
    location: LocationActionTypes.AdminLogin,
    showOnDesktop: true,
  },
];
const adminWebPages: ShowForLocation[] = [
  {
    location: LocationActionTypes.AdminShoppingBasket,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.AdminProductDetails,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.AdminShop,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.AdminRegister,
    showOnDesktop: true,
    hasTitle: true,
    hasSubtitle: true,
  },
  {
    location: LocationActionTypes.AdminLogin,
    showOnDesktop: true,
  },
];
const noShowsForHeader: ShowForLocation[] = [
  {
    location: LocationActionTypes.Payment,
    showOnDesktop: true,
  },
  {
    location: LocationActionTypes.ResetPassword,
    showOnDesktop: false,
  },
];

const noShowsForFooter: ShowForLocation[] = [
  {
    location: LocationActionTypes.PublicSchedule,
    showOnDesktop: false,
  },
  {
    location: LocationActionTypes.Schedule,
    showOnDesktop: true,
  },
  {
    location: LocationActionTypes.ShoppingBasket,
    showOnDesktop: false,
  },
  {
    location: LocationActionTypes.Payment,
    showOnDesktop: false,
  },
  {
    location: LocationActionTypes.ResetPassword,
    showOnDesktop: false,
  },
];

const unauthorizedPages: ShowForLocation[] = [
  {
    location: LocationActionTypes.PublicShop,
    showOnDesktop: true,
  },
];

const iframePages: ShowForLocation[] = [{ location: LocationActionTypes.PublicSchedule, showOnDesktop: false }];
export const getIsPublicWebMode = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return !shouldShow(locationState, publicWebPages, isDesktop);
};

export const getIsAdminWebMode = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return !shouldShow(locationState, adminWebPages, isDesktop);
};

export const getShowHeaderForLocation = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return shouldShow(locationState, noShowsForHeader, isDesktop);
};

export const getShowFooterForLocation = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return shouldShow(locationState, noShowsForFooter, isDesktop);
};

export const getIsUnauthorized = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return !shouldShow(locationState, unauthorizedPages, isDesktop);
};

export const getIsIframeMode = (locationState: LocationState, isDesktop?: boolean): boolean => {
  return !shouldShow(locationState, iframePages, isDesktop);
};

export const getPageTitle = (
  location: Location,
  isDesktopMode: boolean,
  translate: TranslateFunction
): string | null => {
  // const state: RootState = store.getState();
  let pageTitle = null;

  switch (location.type) {
    case LocationActionTypes.Home:
      return translate(SitecoreDictionary.StartPage.Greetings) as string;
    case LocationActionTypes.Settings:
      return translate(SitecoreDictionary.PageTitles.ProfileAndSettings) as string;
    case LocationActionTypes.Settings_Profile:
      return isDesktopMode
        ? (translate(SitecoreDictionary.PageTitles.ProfileAndSettings) as string)
        : (translate(SitecoreDictionary.PageTitles.Profile) as string);
    case LocationActionTypes.Settings_UserAccount:
      return isDesktopMode
        ? (translate(SitecoreDictionary.PageTitles.ProfileAndSettings) as string)
        : (translate(SitecoreDictionary.PageTitles.Account) as string);
    case LocationActionTypes.Settings_TrainingPreferences:
      return translate(SitecoreDictionary.PageTitles.TrainingsPreferences) as string;
    case LocationActionTypes.Settings_PaymentSettings:
      return isDesktopMode
        ? (translate(SitecoreDictionary.PageTitles.ProfileAndSettings) as string)
        : (translate(SitecoreDictionary.PageTitles.PaymentMethods) as string);
    case LocationActionTypes.Settings_NotificationSettings:
      return isDesktopMode
        ? (translate(SitecoreDictionary.PageTitles.ProfileAndSettings) as string)
        : (translate(SitecoreDictionary.PageTitles.PushNotifications) as string);
    case LocationActionTypes.Settings_CalendarSyncSettings:
      return isDesktopMode
        ? (translate(SitecoreDictionary.Profile.Menu.CalendarSyncTitle) as string)
        : (translate(SitecoreDictionary.Profile.Menu.CalendarSyncTitle) as string);
    case LocationActionTypes.Subscriptions:
      return translate(SitecoreDictionary.PageTitles.MembershipAndCards) as string;
    case LocationActionTypes.ShoppingBasket:
      return translate(SitecoreDictionary.PageTitles.ShoppingBasket) as string;
    case LocationActionTypes.PublicShoppingBasket:
      return translate(SitecoreDictionary.PageTitles.ShoppingBasket) as string;
    case LocationActionTypes.AdminShoppingBasket:
      return translate(SitecoreDictionary.PageTitles.ShoppingBasket) as string;
    case LocationActionTypes.AdminRegister:
      return translate(SitecoreDictionary.PageTitles.ShoppingBasket) as string;
    case LocationActionTypes.Facilities:
      return translate(SitecoreDictionary.PageTitles.Facilities) as string;
    case LocationActionTypes.News:
      return translate(SitecoreDictionary.PageTitles.News) as string;
    case LocationActionTypes.Payment:
      return translate(SitecoreDictionary.PageTitles.Payment) as string;
    case LocationActionTypes.PassDetails:
      return '';
    case LocationActionTypes.Login:
      return pageTitle;
    case LocationActionTypes.AdminLogin:
      return pageTitle;
    case LocationActionTypes.Reset:
      return pageTitle;
    case LocationActionTypes.ResetPassword:
      return pageTitle;
    case LocationActionTypes.Shop:
      return translate(SitecoreDictionary.PageTitles.Shop) as string;
    case LocationActionTypes.PublicShop:
      return translate(SitecoreDictionary.PageTitles.Shop) as string;
    case LocationActionTypes.AdminShop:
      return translate(SitecoreDictionary.PageTitles.Shop) as string;
    case LocationActionTypes.ProductDetails:
      return location.payload.productName;
    case LocationActionTypes.PublicProductDetails:
      return location.payload.productName;
    case LocationActionTypes.AdminProductDetails:
      return location.payload.productName;
    case LocationActionTypes.Bookings:
      return translate(SitecoreDictionary.PageTitles.Bookings) as string;
    case LocationActionTypes.Notifications:
      return translate(SitecoreDictionary.PageTitles.Notifications) as string;
    case LocationActionTypes.Statistics:
      return translate(SitecoreDictionary.PageTitles.Statistics) as string;
    case LocationActionTypes.Schedule:
      return translate(SitecoreDictionary.PageTitles.Schedule) as string;
    case LocationActionTypes.Receipt:
      return translate(SitecoreDictionary.PageTitles.Receipt) as string;
    default:
      return pageTitle;
  }
};

export const hasHeaderTitle = (location: Location): boolean => {
  const currentLocation = location.type;
  const page = pagesWithTitleHeader.find(p => p.location === currentLocation);
  return !!page && !!page.hasTitle;
};

export const hasHeaderSubtitle = (location: Location): boolean => {
  const currentLocation = location.type;
  const page = pagesWithTitleHeader.find(p => p.location === currentLocation);
  return !!page && !!page.hasSubtitle;
};

export const getHeaderSubtitleForCurrentLocation = () => {
  const state: RootState = store.getState();
  const location: Location = state.location;
  switch (location.type) {
    case LocationActionTypes.PublicShop:
      return getCurrentFoereningName();
    default:
      return;
  }
};

const shouldShow = (locationState: LocationState, noShows: ShowForLocation[], isDesktop?: boolean): boolean => {
  const currentLocation = locationState.type;
  const addressInNoShowsList = noShows.find(noShow => noShow.location === currentLocation);
  return (
    !addressInNoShowsList || ((addressInNoShowsList && addressInNoShowsList.showOnDesktop && isDesktop) as boolean)
  ); // Show only on desktop
};

const getCurrentFoereningName = () => {
  const state: RootState = store.getState();
  const activeFoerening = state.global.session.activeFoerening;
  if (!activeFoerening) {
    return;
  }
  const inloggadeFoereningar = state.global.session.inloggadeFoereningar;
  if (!inloggadeFoereningar || inloggadeFoereningar.length < 1) {
    return;
  }

  const foerening = inloggadeFoereningar.find(f => f.Id.toString() === activeFoerening.toString());
  if (!foerening) {
    return;
  }

  const fullFoerening = state.foereningar.read.Foereningar.find(f => f.Id.toString() === activeFoerening.toString());

  return !!fullFoerening ? fullFoerening.DisplayName : !!foerening.DisplayName ? foerening.DisplayName : foerening.Namn;
};
