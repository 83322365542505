import * as React from 'react';
import { lockBackgroundScroll, unlockBackgroundCallback } from 'websd/utils';
import { EntityStatus } from 'store/global/fetch';
import { PassListSkeleton } from 'components/common/pass-list-skeleton/PassListSkeleton';

import PassItemContainer from './pass-item/PassItemContainer';
import { SitecoreDictionary, Day } from 'models';
import ScheduleModeSwitcherContainer from '../schedule-mode-switcher/ScheduleModeSwitcherContainer';
import { PassHeader } from '.';
import { IconArrow } from 'components/icons';
import PassFilterContainer from '../../common/passFilter/PassFilterContainer';
import { TranslateFunction } from 'react-localize-redux';
import filtericon from '../../../static/assets/Filter.svg';
import { Spinner, MainInput } from 'components/common';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { PassFilterFiltrerbaraKategorier } from 'store/passFilter';

const PASS_COUNT_THRESHOLD = 50;

export interface ScheduleActionProps {
  getPassList: (foereningsId: number, foereningsName?: string) => void;
  getPassListInBackground: (
    foereningId: number
  ) => Promise<
    SuccessResult<{ pass: Array<Day<fos.api.Pass>>; filter: PassFilterFiltrerbaraKategorier }> | ErrorResult
  >;
  setScheduleToWeekMode: () => void;
  setFilterVisible: (visible: boolean) => void;
  getBookings: () => void;
  setFritextTerm: (filter: string) => void;
}

export interface ScheduleDataProps {
  isPublicWebMode: boolean;
  isDesktopMode: boolean;
  isFullDesktopView: boolean;
}

export interface ScheduleOwnProps {
  passInDay: { day: string; passIds: string[] }[];
  translate: TranslateFunction;

  filterExpanded: boolean;
  aktivFoerening?: number;
  foereningName?: string;

  fetchPasslistStatus?: EntityStatus;
  numbersOfFilters: number;
  visarOverlay: boolean;

  fritextTerm: string;
}

type ScheduleProps = ScheduleOwnProps & ScheduleDataProps & ScheduleActionProps;

export const Schedule: React.FunctionComponent<ScheduleProps> = ({
  isPublicWebMode,
  isDesktopMode,
  isFullDesktopView,
  passInDay,
  translate,

  filterExpanded,
  aktivFoerening,
  foereningName,

  fetchPasslistStatus,
  numbersOfFilters,
  getPassList,
  getPassListInBackground,
  setScheduleToWeekMode,
  setFilterVisible,
  getBookings,
  visarOverlay,
  fritextTerm,
  setFritextTerm,
}) => {
  const [unlockBackgroundScrollCallback, setUnlockBackgroundScrollCallback] = React.useState<
    unlockBackgroundCallback | undefined
  >(undefined);
  const [showPassForDay, setShowPassForDay] = React.useState<{ [day: string]: boolean }>({});
  const [totalNumberOfPass, setTotalNumberOfPass] = React.useState<number>(-1);
  const [scrollRightPercent, setScrollRightPercent] = React.useState<number>(0);
  const [shouldUpdateShownDays, setShouldUpdateShownDays] = React.useState<boolean>(true);
  const [timeAtLastInput, setTimeAtLastInput] = React.useState<number>(Date.now());

  const container = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!aktivFoerening || isPublicWebMode) {
      return () => {};
    }
    const timeout = window.setTimeout(() => {
      getPassListInBackground(aktivFoerening);
    }, 10000);
    const interval = window.setInterval(() => {
      const now = Date.now();
      if (now - timeAtLastInput < 3 * 60500) {
        // 60.5 * 3 sekunder för att ha lite spelrum
        getPassListInBackground(aktivFoerening);
      }
    }, 60000);
    return () => {
      window.clearInterval(interval);
      window.clearTimeout(timeout);
    };
  }, [timeAtLastInput, aktivFoerening, isPublicWebMode, getPassListInBackground]);

  React.useEffect(() => {
    const resetTimer = () => {
      setTimeAtLastInput(Date.now());
    };

    window.addEventListener('click', resetTimer);
    window.addEventListener('touchstart', resetTimer);
    window.addEventListener('scroll', resetTimer, true);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
      window.removeEventListener('scroll', resetTimer, true);
    };
  }, []);

  React.useEffect(() => {
    if (isPublicWebMode) {
      if (!isFullDesktopView) {
        setScheduleToWeekMode();
      }
      if (!!foereningName) {
        if (!hasOrIsFetchingPassList()) {
          getPassList(-1, foereningName);
        }
      }
    } else if (!!aktivFoerening) {
      if (!isDesktopMode) {
        lockBackground();
        getBookings();
      }
      if (!hasOrIsFetchingPassList()) {
        getPassListInBackground(aktivFoerening);
      }
    }

    return () => {
      if (!!unlockBackgroundScrollCallback) {
        unlockBackgroundScrollCallback();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let numberOfPass = 0;
    passInDay.forEach(v => (numberOfPass += v.passIds.length));
    setTotalNumberOfPass(numberOfPass);
    if (shouldUpdateShownDays) {
      const days = { ...showPassForDay };
      if (numberOfPass > PASS_COUNT_THRESHOLD) {
        passInDay.forEach(d => (days[d.day] = false));
        const firstDayWithPass = passInDay.find(p => p.passIds.length > 0);
        if (firstDayWithPass) {
          days[firstDayWithPass.day] = true;
        }
      } else {
        passInDay.forEach(d => (days[d.day] = true));
      }
      setShowPassForDay(days);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passInDay]);

  const renderPassList = (): JSX.Element => {
    let firstVisibleHeaderRendered = false;
    return (
      <React.Fragment>
        {!isPublicWebMode && isDesktopMode && (
          <React.Fragment>
            {!filterExpanded && (
              <React.Fragment>
                <div
                  style={{ position: 'absolute', width: '60px', margin: '14px 0px 0px 14px', cursor: 'pointer' }}
                  onClick={() => {
                    setFilterVisible(true);
                  }}
                >
                  <img src={filtericon} alt="Filter" />
                  {numbersOfFilters > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        width: '16px',
                        height: '16px',
                        backgroundColor: '#e31836',
                        lineHeight: '16px',
                        color: '#ffffff',
                        fontSize: '12px',
                        textAlign: 'center',
                        top: '0px',
                        right: '0px',
                        borderRadius: '50px',
                      }}
                    >
                      {numbersOfFilters}
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            <h4 className="Schedule__Title">{translate(SitecoreDictionary.Schedule.Schedule)}</h4>
          </React.Fragment>
        )}
        {!isPublicWebMode && isDesktopMode && <ScheduleModeSwitcherContainer />}
        <PassFilterContainer isFullDesktopView={isFullDesktopView} isPublicWebMode={isPublicWebMode} />
        <MainInput
          placeholder={translate(SitecoreDictionary.Schedule.Filter.QuickSearchPlaceholder) as string}
          align={'left'}
          smallRadius={true}
          border={'grey'}
          onValueChange={v => {
            const days = { ...showPassForDay };
            if (!passInDay.some(d => !days[d.day])) {
              // Stäng alla dagar temporärt för att inte rendera alla pass om alla dagarna råkar vara öppna
              passInDay.forEach(d => (days[d.day] = false));
              setShowPassForDay(days);
            }
            setFritextTerm(v as string);
          }}
          value={fritextTerm}
          className="Schedule__Fritext"
        />
        {!visarOverlay && (
          <PullToRefresh
            onRefresh={() => {
              if (!aktivFoerening) {
                return Promise.resolve();
              }
              return getPassListInBackground(aktivFoerening);
            }}
            pullingContent={
              <div style={{ width: '100%' }}>
                <span style={{ width: '100%', textAlign: 'center' }}>
                  {translate(SitecoreDictionary.Schedule.PullToRefresh)}
                </span>
              </div>
            }
            refreshingContent={<Spinner appearance="red" alignment="center" />}
            isPullable={!isDesktopMode}
          >
            <div
              className={'Schedule__PassListContainer'} // Todo: lägg till Schedule__PassListContainer--scrollingDisabled om vi lägger tillbaka daypicker och har den expanderad
              id={'Schedule__PassListContainer'}
              ref={r => (container.current = r)}
            >
              <div className="PassList">
                {totalNumberOfPass > 0 && (
                  <PassHeader
                    showPassForDay={showPassForDay}
                    container={container.current}
                    onClick={(element, day) => {
                      toggleDayExpanded(element, day);
                      setShouldUpdateShownDays(false);
                      (window as any).dataLayer.push({
                        event: 'dateRange',
                        forening: foereningName,
                      });
                    }}
                    passInDay={passInDay}
                  />
                )}
                {passInDay.map((day, index) => {
                  const shouldShowPassToday = !filterExpanded && !!showPassForDay[day.day];
                  const rend = (
                    <div
                      className={'DayItem PassListDayItem ' + (day.passIds.length === 0 ? 'DayItem--hidden' : '')}
                      key={'ScheduleIndex' + index}
                    >
                      <React.Fragment>
                        <div
                          style={!firstVisibleHeaderRendered ? { visibility: 'hidden' } : {}}
                          className={
                            'DayItem__Header DayItem__Header--passlist DayItem__Header--nonexpanded' +
                            (shouldShowPassToday ? ' DayItem__Header--expanded' : '')
                          }
                          onClick={e => {
                            toggleDayExpanded(e.currentTarget, day.day);
                            setShouldUpdateShownDays(false);
                            (window as any).dataLayer.push({
                              event: 'dateRange',
                              forening: foereningName,
                            });
                          }}
                        >
                          {day.day}
                        </div>
                        {shouldShowPassToday &&
                          day.passIds.map((passId, passIndex) => {
                            return (
                              <PassItemContainer
                                key={'NewPass_' + index + '_' + passIndex}
                                passId={passId}
                                translate={translate}
                                aktivFoerening={aktivFoerening!}
                                baseClass={isFullDesktopView ? 'PassItem' : 'PassItem'}
                                isDesktopMode={isDesktopMode}
                                passDay={day.day}
                              />
                            );
                          })}
                      </React.Fragment>
                    </div>
                  );
                  if (day.passIds.length > 0 && !firstVisibleHeaderRendered) {
                    firstVisibleHeaderRendered = true;
                  }
                  return rend;
                })}
                <div />
              </div>
            </div>
          </PullToRefresh>
        )}
      </React.Fragment>
    );
  };

  const renderPassSchedule = (): JSX.Element => {
    return (
      <React.Fragment>
        {<h4 className="Schedule__Title">{!isPublicWebMode && translate(SitecoreDictionary.Schedule.Schedule)}</h4>}
        {!isPublicWebMode && isDesktopMode && <ScheduleModeSwitcherContainer />}
        <PassFilterContainer isFullDesktopView={isFullDesktopView} isPublicWebMode={isPublicWebMode} />
        <div
          className={'Schedule__PassListContainer'}
          ref={r => (container.current = r)}
          onScroll={e => handleScroll(e)}
        >
          <div className="PassList PassList--scheduleView">
            {passInDay.map((day, index) => {
              return (
                <div className="DayItem PassListDayItem" key={'ScheduleIndex' + index}>
                  <React.Fragment>
                    <div className={'DayItem__Header'}>{day.day}</div>
                    {!visarOverlay &&
                      day.passIds.map((passId, passIndex) => {
                        return (
                          <PassItemContainer
                            key={'NewPass_' + index + '_' + passIndex}
                            passId={passId}
                            translate={translate}
                            aktivFoerening={aktivFoerening!}
                            baseClass={isFullDesktopView ? 'PassItem' : 'PassItem'}
                            isDesktopMode={isDesktopMode}
                            passDay={day.day}
                          />
                        );
                      })}
                  </React.Fragment>
                </div>
              );
            })}
          </div>
        </div>
        {!visarOverlay && passInDay.length > 7 && (
          <React.Fragment>
            {scrollRightPercent > 0 && (
              <div className={'scroll scroll--left'} onClick={() => scrollToLeft()}>
                <IconArrow color="white" direction="up" />
              </div>
            )}
            {scrollRightPercent < 1 && (
              <div className={'scroll scroll--right'} onClick={() => scrollToRight()}>
                <IconArrow color="white" direction="down" />
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const toggleDayExpanded = (element: HTMLDivElement | null, day: string): void => {
    const showPass = { ...showPassForDay };
    const keys = Object.keys(showPass);
    const skaFaellasUt = !showPass[day];
    if (!skaFaellasUt) {
      showPass[day] = false;
      // Fäll ihop.
      setShowPassForDay(showPass);
    } else {
      if (totalNumberOfPass > PASS_COUNT_THRESHOLD) {
        // Stäng alla andra dagar
        keys.forEach(k => (showPass[k] = false));
      }
      showPass[day] = true;
      setShowPassForDay(showPass);
    }
    setTimeout(() => {
      const container = document.getElementById('Schedule__PassListContainer');
      if (!!container) {
        let top = 0;
        if (!!element) {
          top = element.offsetTop;
        } else {
          const index = keys.indexOf(day);
          top = index * 44; // dagar är 44 pixlar hög...
        }
        if (container.scrollTop >= top || skaFaellasUt) {
          container.scrollTo({ top: top });
        }
      }
    }, 1);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!event || !event.currentTarget) {
      return;
    }
    const eventTarget = event.currentTarget as HTMLDivElement;
    const scrollRight = eventTarget.scrollLeft / (eventTarget.scrollWidth - eventTarget.clientWidth);
    if (scrollRight === scrollRightPercent) {
      return;
    }
    setScrollRightPercent(scrollRight);
  };

  const scrollToLeft = () => {
    if (!!container.current) {
      let left = container.current.scrollLeft - (container.current.scrollWidth / passInDay.length) * 7;
      if (left < 0) {
        left = 0;
      }
      if (!!container.current.scrollTo) {
        container.current.scrollTo({ left: left, behavior: 'smooth' });
      } else {
        container.current.scrollLeft = left;
      }
    }
  };

  const scrollToRight = () => {
    if (!!container.current) {
      let left = container.current.scrollLeft + (container.current.scrollWidth / passInDay.length) * 7;
      if (left > container.current.scrollWidth) {
        left = container.current.scrollWidth;
      }
      if (!!container.current.scrollTo) {
        container.current.scrollTo({ left: left, behavior: 'smooth' });
      } else {
        container.current.scrollLeft = left;
      }
    }
  };

  const getClasses = (): string => {
    if (isPublicWebMode && isFullDesktopView) {
      return 'Schedule Schedule--Is-Desktop-Full-View-Mode';
    } else if (isFullDesktopView) {
      return 'Schedule Schedule--Is-Desktop-Full-View-Mode';
    } else if (isPublicWebMode) {
      return 'Schedule Schedule--Is-Desktop-List-Mode';
    }
    return 'Schedule Schedule--Is-Mobile-Mode';
  };

  const hasOrIsFetchingPassList = () => {
    return (
      !!fetchPasslistStatus &&
      (fetchPasslistStatus === EntityStatus.FETCHING_IN_BACKGROUND ||
        fetchPasslistStatus === EntityStatus.FETCHING ||
        fetchPasslistStatus === EntityStatus.SUCCESS ||
        fetchPasslistStatus === EntityStatus.FAILED)
    );
  };

  const hasNotOrIsFetchingPassListInForeground = () => {
    return (
      !fetchPasslistStatus || fetchPasslistStatus === EntityStatus.FETCHING || !passInDay || passInDay.length === 0
    );
  };

  const lockBackground = () => {
    const unlockBackground = lockBackgroundScroll();
    setUnlockBackgroundScrollCallback(unlockBackground);
  };

  if (!passInDay) {
    return null;
  }
  return (
    <div id="schedule" className={getClasses()}>
      {hasNotOrIsFetchingPassListInForeground() && <PassListSkeleton numberOfDays={3} maxAmountOfPassItemsPerDay={3} />}
      {!hasNotOrIsFetchingPassListInForeground() && (
        <React.Fragment>
          {!isFullDesktopView && renderPassList()}
          {isFullDesktopView && renderPassSchedule()}
        </React.Fragment>
      )}
    </div>
  );
};
