import { Action } from 'store';
import { ShopActionType } from './';
import { ShopFilter, ShopFilterCategoryName, ShopFilterCategoryNames } from './shopModels';

export * from './shopDispatches';

export interface ShopRootState {
  unfilteredProducts: fos.api.Product[];
  filteredProducts: fos.api.Product[];
  /**
   * Meant to be filled upon user selection of filters
   */
  userSelectedFilterOptions: ShopFilter;
  fullSetOfFilterProperties: ShopFilter;

  filteredFilterCategories: fos.api.FilterCategory[];
}

const emptyFilters: ShopFilter = {
  ProductWho: [],
  ProductWhere: [],
  ProductWhen: [],
  ProductHow: [],
};

const initialState: ShopRootState = {
  unfilteredProducts: [],
  filteredProducts: [],
  userSelectedFilterOptions: JSON.parse(JSON.stringify(emptyFilters)),
  fullSetOfFilterProperties: JSON.parse(JSON.stringify(emptyFilters)),
  filteredFilterCategories: [],
};

/**
 *  Uses the filters to return an array of products matching the filter criteria.
 * @param {fos.api.Product[]} items
 * @param {ShopFilter} userFilters
 * @returns {fos.api.Product[]}
 */
function getFilteredItems(items: fos.api.Product[], userFilters: ShopFilter): fos.api.Product[] {
  // TODO: Complete with other properties
  /* eslint-disable no-lone-blocks */
  let filteredItems: fos.api.Product[] = [...items];
  {
    // For Who

    if (userFilters.ProductWho && userFilters.ProductWho.length) {
      filteredItems = filteredItems.filter((product: fos.api.Product) => {
        return userFilters.ProductWho.find(who => who === product.ProductWho);
      });
    }
  }
  {
    // For Where
    // if (userFilters.ProductWho && userFilters.ProductWho.length) {
    //   filteredItems = items.filter(, (product: fos.api.Product) => {
    //     return userFilters.find(.ProductWho, (who) => who === product.ProductWho);
    //   });
    // }
  }
  {
    // For When

    if (userFilters.ProductWhen && userFilters.ProductWhen.length) {
      filteredItems = filteredItems.filter((product: fos.api.Product) => {
        return userFilters.ProductWhen.find(who => who === product.ProductWhen);
      });
    }
  }
  {
    // For How

    if (userFilters.ProductHow && userFilters.ProductHow.length) {
      filteredItems = filteredItems.filter((product: fos.api.Product) => {
        return userFilters.ProductHow.find(who => who === product.ProductHow);
      });
    }
  }
  /* eslint-enable no-lone-blocks */
  return filteredItems;
}

/**
 * Creates the ScheduleFilter object with all filterable properties snatched from the pristine products data set.
 * The only argument is the  pristine data set from the server.
 *
 * @param {fos.api.Product[]} products
 * @returns {ShopFilter}
 */
export const snatchFilterPropertiesFromProductData = (products: fos.api.Product[]): ShopFilter => {
  const snatchedFilter: ShopFilter = {
    ...JSON.parse(JSON.stringify(emptyFilters)),
    ProductWho: getPropertyValuesForProperty(ShopFilterCategoryName.ProductWho, products),
    ProductWhere: [],
    ProductWhen: getPropertyValuesForProperty(ShopFilterCategoryName.ProductWhen, products),
    ProductHow: getPropertyValuesForProperty(ShopFilterCategoryName.ProductHow, products),
  };

  return snatchedFilter;
};

export function getPropertyValuesForProperty(property: ShopFilterCategoryNames, products: fos.api.Product[]): string[] {
  let values: string[] = [];
  products.forEach((product, index) => {
    switch (property) {
      case ShopFilterCategoryName.ProductWho: {
        if (values.indexOf(product.ProductWho) === -1 && !!product.ProductWho) values.push(product.ProductWho);
        break;
      }
      case ShopFilterCategoryName.ProductWhen: {
        if (values.indexOf(product.ProductWhen) === -1 && !!product.ProductWhen) values.push(product.ProductWhen);
        break;
      }
      case ShopFilterCategoryName.ProductHow: {
        if (values.indexOf(product.ProductHow) === -1 && !!product.ProductHow) values.push(product.ProductHow);
        break;
      }
      default:
        break;
    }
  });
  return values;
}

export const ProductReducer = (state: ShopRootState = initialState, action: Action): ShopRootState => {
  switch (action.type) {
    case ShopActionType.Fetch_FilterCategories: {
      // FÖRSTA HÄMTNIGNEN
      const stringifiedCachedFilterCategories = JSON.stringify(action.filter);
      const cachedFilteredFilterCategories = JSON.parse(stringifiedCachedFilterCategories);

      return { ...state, filteredFilterCategories: cachedFilteredFilterCategories };
    }
    case ShopActionType.Fetch_Products: {
      const cachedUnfilteredProducts = action.products;
      const cachedFilteredFilterCategories = [...state.filteredFilterCategories];
      cachedFilteredFilterCategories.forEach(f => {
        f.Children = f.Children.filter(
          c => !!cachedUnfilteredProducts.find(p => !!p.FilterVal.find(fv => fv === c.SitecoreId))
        );
      });
      const cachedFilteredProducts = getFilteredItems(cachedUnfilteredProducts, state.userSelectedFilterOptions);
      const cachedFullSetOfPropertiesSnatchedFromScheduleData = snatchFilterPropertiesFromProductData(
        cachedUnfilteredProducts
      );
      return {
        ...state,
        unfilteredProducts: cachedUnfilteredProducts,
        filteredProducts: cachedFilteredProducts,
        fullSetOfFilterProperties: cachedFullSetOfPropertiesSnatchedFromScheduleData,
        filteredFilterCategories: cachedFilteredFilterCategories,
      };
    }
    case ShopActionType.Shop_UpdateUserSelectedFilterOptionsProperty: {
      const cachedUserFilters = { ...state.userSelectedFilterOptions };
      cachedUserFilters[action.category] = action.propertyValue;
      const cachedFilteredProducts = getFilteredItems(state.unfilteredProducts, cachedUserFilters);
      return {
        ...state,
        userSelectedFilterOptions: { ...cachedUserFilters },
        filteredProducts: { ...cachedFilteredProducts },
      };
    }
    case ShopActionType.Shop_ClearUserSelectedFilterOptionsProperty: {
      const cachedUserFilters = { ...state.userSelectedFilterOptions };
      cachedUserFilters[action.category] = [];

      const cachedFilteredProducts = getFilteredItems(state.unfilteredProducts, cachedUserFilters);
      return {
        ...state,
        userSelectedFilterOptions: { ...cachedUserFilters },
        filteredProducts: { ...cachedFilteredProducts },
      };
    }
    case ShopActionType.Shop_ResetProductsFilter: {
      const cachedUserFilters = JSON.parse(JSON.stringify(emptyFilters));
      const cachedFilteredProducts = getFilteredItems(state.unfilteredProducts, cachedUserFilters);
      return {
        ...state,
        userSelectedFilterOptions: { ...cachedUserFilters },
        filteredProducts: { ...cachedFilteredProducts },
      };
    }
    default:
      return state;
  }
};
