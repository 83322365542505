import { Reducer } from 'redux';
import { Action } from 'store';

export enum SiteSwitcherStatus {
  'HIDDEN' = 'HIDDEN',
  'SHOWING' = 'SHOWING',
  'SHOW_FULLSCREEN' = 'SHOW_FULLSCREEN',
}

export interface SiteSwitcherState {
  visibility: SiteSwitcherStatus;
}

const initialState: SiteSwitcherState = {
  visibility: SiteSwitcherStatus.HIDDEN,
};

export const siteswitcherReducer: Reducer<SiteSwitcherState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'UI_SITESWITCHER_SHOW': {
      return { ...state, visibility: SiteSwitcherStatus.SHOWING };
    }
    case 'UI_SITESWITCHER_HIDE': {
      return { ...state, visibility: SiteSwitcherStatus.HIDDEN };
    }
    case 'UI_SITESWITCHER_SHOW_FULLSCREEN': {
      return { ...state, visibility: SiteSwitcherStatus.SHOW_FULLSCREEN };
    }
    default:
      return state;
  }
};
