import { connect } from 'react-redux';
import { RootState } from 'store';
import { Header } from './';
import { getShowHeaderForLocation } from 'store/location';
import { HeaderDataProps } from './Header';

const mapStateToProps = (state: RootState, props: any): HeaderDataProps => {
  const showHeader: boolean = getShowHeaderForLocation(state.location, props.isDesktopMode);
  return {
    showHeader: showHeader,
    isDesktopMode: props.isDesktopMode,
    isStartPage: props.isStartPage,
    isFirstVisit: props.isFirstVisit,
  };
};

export default connect(mapStateToProps)(Header);
