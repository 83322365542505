import { getEntityStatus } from 'store/global/fetch/fetchSelectors';
import { NewsDataProps, NewsActionProps, News } from './News';
import { RootState } from 'store';
import { fetchNews } from 'store/news';
import { connect } from 'react-redux';
import { FetchStatusAggregateTypes } from 'store/global/fetch';
import { getTranslate } from 'store/global/dictionary';

const mapState = (state: RootState): NewsDataProps => ({
  news: state.news.news,
  translate: getTranslate(state.locale),
  fetchState: getEntityStatus(
    state.global.fetch,
    state.global.session.userId as number,
    FetchStatusAggregateTypes.NEWS
  ),
});

const mapDispatch: NewsActionProps = {
  fetchNews,
};

export default connect(mapState, mapDispatch)(News);
