// tslint:disable:no-any
import { Action } from 'redux';

let errorIdCounter = 0;

let globalHeaders: {
  [key: string]: string;
} = {};

const nextErrorId = () => ++errorIdCounter;

const safeJsonParse = (text: string): any | undefined => {
  try {
    return JSON.parse(text);
  } catch (err) {
    return undefined;
  }
};

const isApiErrorResponse = (errorObject: any): errorObject is ApiErrorResponse =>
  errorObject && errorObject.Felkod && typeof errorObject.Felmeddelande !== 'undefined';

const getApiErrorMessage = (error: ApiErrorResponse) =>
  error.Felmeddelande ||
  (error.ValidationErrors ? error.ValidationErrors.map(v => v.UserMessage).join('. ') : 'någonting gick fel');

const rejectTextAsErrorMessage = (
  text: string,
  method: string,
  endpoint: string,
  status: number,
  statusText: string,
  errorCode: string | null = null
): ErrorMessage => ({
  id: nextErrorId(),
  message: text,
  errorCode: errorCode,
  http: {
    method: method.toUpperCase(),
    endpoint,
    status,
    statusText,
    response: text,
  },
});

const rejectJsonAsErrorMessage = (
  json: any,
  method: string,
  endpoint: string,
  status: number,
  statusText: string,
  errorCode: string | null = null
): ErrorMessage => {
  const http = {
    method,
    endpoint,
    status,
    statusText,
    response: json,
  };

  if (isApiErrorResponse(json)) {
    return { id: nextErrorId(), errorCode: errorCode, message: getApiErrorMessage(json), http };
  } else if (typeof json.message === 'string') {
    return { id: nextErrorId(), errorCode: errorCode, message: json.message, http };
  } else if (typeof json === 'string') {
    return { id: nextErrorId(), errorCode: errorCode, message: json, http };
  }

  return { id: nextErrorId(), errorCode: errorCode, message: 'Hoppsan, någonting gick fel', http };
};

const xUsername = process.env.REACT_APP_X_USERNAME;

const callApi = <SuccessResponseType>(
  rootEndpoint: string,
  endpoint: string,
  options: RequestInit,
  isAnonymous?: boolean
): Promise<SuccessResponseType> => {
  if (process.env.NODE_ENV !== 'production' && typeof xUsername !== 'undefined') {
    // Om ej production och x-username är satt skickar vi med den vid varje anrop
    options.headers = { ...options.headers, 'X-Username': xUsername };
  }

  return fetch(`${rootEndpoint}/${endpoint}`, { ...options, credentials: 'include' }).then(
    response =>
      response.text().then(
        text => {
          const json = safeJsonParse(text);

          if ((text && typeof json === 'undefined') || (!response.ok && !text)) {
            return Promise.reject(
              rejectTextAsErrorMessage(
                text,
                options.method || 'GET',
                endpoint,
                response.status,
                response.statusText,
                response.headers.get('X-FoS-Guid')
              )
            );
          }

          if (!response.ok) {
            return Promise.reject(
              rejectJsonAsErrorMessage(
                json,
                options.method || 'GET',
                endpoint,
                response.status,
                response.statusText,
                response.headers.get('X-FoS-Guid')
              )
            );
          }

          return json;
        },
        texterr =>
          Promise.reject(
            rejectTextAsErrorMessage(
              texterr,
              options.method || 'GET',
              endpoint,
              response.status,
              response.statusText,
              response.headers.get('X-FoS-Guid')
            )
          )
      ),
    _fetcherror =>
      Promise.reject(
        rejectTextAsErrorMessage('Du verkar vara offline', options.method || 'GET', endpoint, 999, 'Offline')
      )
  );
};

export const configureApi = (configureOptions: ConfigureOptions) => ({
  get: <SuccessResponseType>(endpoint: string, options: Options = {}) =>
    apiCall<SuccessResponseType>(
      configureOptions,
      endpoint,
      {
        headers: {
          Accept: 'application/json',
        },
      },
      options
    ),
  put: <SuccessResponseType, Payload = any>(endpoint: string, payload: Payload, options: Options = {}) =>
    apiCall<SuccessResponseType>(
      configureOptions,
      endpoint,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
      options
    ),
  post: <SuccessResponseType, Payload = any>(endpoint: string, payload: Payload, options: Options = {}) =>
    apiCall<SuccessResponseType>(
      configureOptions,
      endpoint,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
      options
    ),
});

const apiCall = <SuccessResponseType>(
  configureOptions: ConfigureOptions,
  endpoint: string,
  init: RequestInit,
  options: Options
) =>
  callApi<SuccessResponseType>(
    configureOptions.apiRootEndpoint,
    endpoint,
    {
      ...init,
      headers: {
        ...init.headers,
        ...options.headers,
        ...globalHeaders,
      },
    },
    options.anonymousCall
  )
    .then(
      response =>
        ({
          error: false,
          result: response,
        } as SuccessResult<SuccessResponseType>),
      (error: ErrorMessage) => {
        const muteErrorNotification =
          error.http &&
          options.muteErrorNotificationOnStatus &&
          options.muteErrorNotificationOnStatus.indexOf(error.http.status) >= 0;
        if (error.http) {
          switch (error.http.status) {
            case 401:
              if (!options.anonymousCall) {
                configureOptions.unauthorizedAction({
                  id: nextErrorId(),
                  message: 'Hoppsan, du blev utloggad!',
                  errorCode: null,
                  http: null,
                });
              } else {
                if (!muteErrorNotification) {
                  configureOptions.errorActionCreator(error);
                }
              }
              break;
            default:
              if (!muteErrorNotification) {
                configureOptions.errorActionCreator(error);
              }
          }
        } else {
          if (!muteErrorNotification) {
            configureOptions.errorActionCreator(error);
          }
        }

        return {
          error: true,
          result: error,
        } as ErrorResult;
      }
    )
    .catch(error => {
      // Firefox workaround, does not detect unhandled rejection
      setTimeout(() => {
        throw error;
      }, 0);
      return Promise.reject(error);
    });

export const registerGlobalHeader = (headerKey: string, headerValue: string, overwrite: boolean = false): void => {
  if (globalHeaders[headerKey] !== null && !overwrite) {
    throw new Error(`${headerKey} is already defined as a global header`);
  } else {
    globalHeaders[headerKey] = headerValue;
  }
};

export const removeGlobalHeader = (headerKey: string): void => {
  delete globalHeaders[headerKey];
};

interface ApiErrorResponse {
  Felkod: string;
  Felmeddelande: string;
  ValidationErrors:
    | {
        Entry: string;
        Property: string;
        UserMessage: string;
      }[]
    | null;
}

interface ConfigureOptions {
  apiRootEndpoint: string;
  unauthorizedAction: (errorMessage: ErrorMessage) => void;
  errorActionCreator: (errorMessage: ErrorMessage) => Action;
}

interface Options {
  headers?: any;
  muteErrorNotificationOnStatus?: number[];
  anonymousCall?: boolean;
}

export interface ErrorMessage {
  id: number;
  message: string;
  errorCode: string | null;
  http: {
    method: string;
    endpoint: string;
    status: number;
    statusText: string;
    response?: any;
  } | null;
}

export interface SuccessResult<SuccessResponseType> {
  readonly error: false;
  readonly result: SuccessResponseType;
}

export interface ErrorResult {
  readonly error: true;
  readonly result: ErrorMessage;
}
