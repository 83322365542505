import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import {
  KnownUserBuyingFormExternalDataProps,
  KnownUserBuyingFormDataProps,
  KnownUserBuyingFormActionProps,
  KnownUserBuyingForm,
} from './KnownUserBuyingForm';
import {
  buyBasket,
  updateAutoGiroClearingNumber,
  updateAutogiroAccountNumber,
  showCouponMessage,
  updatePaymentMethod,
  updatePhoneNumberAction,
  onCreatePaymentError,
  onBasketPurchaseError,
} from 'store/basket';
import { getPaymentMethods } from 'store/payment';
import { SiteSwitcherAction } from 'store/global/ui/siteswitcher';
import {
  getEntityStatus,
  FetchStatusAggregateTypes,
} from 'store/global/fetch';
import { SitecoreDictionary, ProductPaymentMethod } from 'models';
import { getPurchaseErrorMessage } from 'utils';

const setSiteSwitcherToVisibleFullscreen = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_SHOW_FULLSCREEN',
  });
};

const setSiteSwitcherToHidden = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_HIDE',
  });
};

const mapStateToProps = (
  state: RootState,
  props: KnownUserBuyingFormExternalDataProps
): KnownUserBuyingFormDataProps => {
  const products = state.shoppingBasket.products;
  return {
    disableSubmit: products.length === 0 || props.isAdmin,
    isDesktopMode: props.isDesktopMode,
    translate: props.translate,
    shouldShowAutogiroInfo: !!products.find(product => product.PaymentMethod === ProductPaymentMethod.AutoGiro),
    foereningId: props.foereningId,
    isAuthenticated: props.isAuthenticated,
    isAdmin: props.isAdmin,

    orderId: state.global.session.orderId!,
    isClearingNumberValid: !!state.shoppingBasket.autogiro.isClearingNumberValid,
    isAccountNumberValid: !!state.shoppingBasket.autogiro.isAccountNumberValid,
    clearingNumberValue: state.shoppingBasket.autogiro.clearingNumber,
    accountNumberValue: state.shoppingBasket.autogiro.accountNumber,
    phoneNumber: state.shoppingBasket.phoneNumber,
    shouldShowCouponMessage: state.shoppingBasket.showCouponMessage,
    getPaymentMethodsStatus: !!props.foereningId
      ? getEntityStatus(state.global.fetch, '' + props.foereningId, FetchStatusAggregateTypes.GET_PAYMENT_METHODS)
      : undefined,
    paymentMethods: state.payment.read.paymentMethods,
    errorsOnCreatePayment: state.shoppingBasket.errorsOnCreatePayment,
    errorOnPurchase: props.isAdmin
      ? (props.translate(SitecoreDictionary.Shop.Admin.CannotPurchaseInAdminShop) as string)
      : !!state.shoppingBasket.errorOnPurchase
      ? getPurchaseErrorMessage(
          props.translate,
          state.shoppingBasket.errorOnPurchase,
          state.global.session.activeFoerening
        )
      : undefined,
  };
};
const mapDispatchToProps = (dispatch: Dispatch): KnownUserBuyingFormActionProps => ({
  buy: () => dispatch(buyBasket()),
  onClearingNumberChange: value => dispatch(updateAutoGiroClearingNumber(value)),
  onAccountNumberChange: value => dispatch(updateAutogiroAccountNumber(value)),
  showCouponMessage: (showMessage: boolean) => dispatch(showCouponMessage(showMessage)),
  getPaymentMethods: (foereningId: number, orderId: number) => dispatch(getPaymentMethods(foereningId, orderId)),
  updatePaymentMethod: (paymentMethodId: number) => dispatch(updatePaymentMethod(paymentMethodId)),
  updatePhoneNumber: (phoneNumber: string) => dispatch(updatePhoneNumberAction(phoneNumber)),
  clearCreatePaymentErrors: () => dispatch(onCreatePaymentError([])),
  clearPurchaseErrors: () => dispatch(onBasketPurchaseError('')),
  setSiteSwitcherToVisible: () => dispatch(setSiteSwitcherToVisibleFullscreen()),
  setSiteSwitcherToHidden: () => dispatch(setSiteSwitcherToHidden()),
});

export const KnownUserBuyingFormContainer = connect(mapStateToProps, mapDispatchToProps)(KnownUserBuyingForm);
