import * as React from 'react';
import { MainButton, StatusBar, AutogiroInput } from 'components/common';
import { isAccountNumberValid, isClearingNumberValid } from 'utils';

import { getPaymentSettingsError } from 'models/SitecoreDictionary';
import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface AutogiroSettingsDataProps {
  translate: TranslateFunction;
  id: string;
  clearingNumber: string;
  accountNumber: string;
  isClearingNumberValid: boolean;
  isAccountNumberValid: boolean;
  updateAutogiroCmdIsExecuting: boolean;
  updateAutogiroCmdIsExecuted: boolean;
  updateAutogiroCmdExecutionFailed: boolean;
  errorMessage: string;
}

export interface AutogiroSettingsActionProps {
  updateClearingNumberValue: (newValue: string) => void;
  updateAccountNumberValue: (newValue: string) => void;
  updateClearingNumberValidity: (isValid: boolean) => void;
  updateAccountNumberValidity: (isValid: boolean) => void;
  updateAutogiro: () => void;
}

export interface AutogiroSettingsLocalProps {
  showConfirmation: boolean;
}

type AutogiroSettingsProps = AutogiroSettingsDataProps & AutogiroSettingsActionProps;

export class AutogiroSettings extends React.Component<AutogiroSettingsProps, AutogiroSettingsLocalProps> {
  constructor(props: AutogiroSettingsProps) {
    super(props);
    this.state = {
      showConfirmation: false,
    };

    this.props.updateClearingNumberValidity(isClearingNumberValid(this.props.clearingNumber));
    this.props.updateAccountNumberValidity(isAccountNumberValid(this.props.accountNumber));
    this.onClearingNumberChange = this.onClearingNumberChange.bind(this);
    this.onAccountNumberChange = this.onAccountNumberChange.bind(this);
  }

  render() {
    return (
      <div className="AutogiroSettings">
        <h4 className="AutogiroSettings__Title">
          {this.props.translate(SitecoreDictionary.Profile.PaymentSettings.UpdateBankAccount) as string}
        </h4>
        <form className="AutogiroSettings__Form" onSubmit={e => this.updateBankAccount(e)}>
          <AutogiroInput
            className="AutogiroSettings__Form__Input"
            isClearingNumberValid={this.props.isClearingNumberValid}
            isAccountNumberValid={this.props.isAccountNumberValid}
            required={true}
            shouldValidate={true}
            clearingNumberValue={this.props.clearingNumber}
            accountNumberValue={this.props.accountNumber}
            disabled={this.props.updateAutogiroCmdIsExecuting}
            onClearingNumberChange={this.onClearingNumberChange}
            onAccountNumberChange={this.onAccountNumberChange}
            translate={this.props.translate}
          />
          {this.state.showConfirmation &&
            (this.props.updateAutogiroCmdExecutionFailed || this.props.updateAutogiroCmdIsExecuted) && (
              <StatusBar
                visible={true}
                message={
                  this.props.updateAutogiroCmdExecutionFailed
                    ? (this.props.translate(getPaymentSettingsError(this.props.errorMessage)) as string)
                    : (this.props.translate(SitecoreDictionary.Common.SavingSucceed) as string)
                }
                cssClass={
                  'AutogiroSettings__Form__StatusMessage ' +
                  (this.props.updateAutogiroCmdExecutionFailed
                    ? 'AutogiroSettings__Form__StatusMessage--Fail'
                    : 'AutogiroSettings__Form__StatusMessage--Success')
                }
              />
            )}
          <MainButton
            className="AutogiroSettings__Form__SubmitButton"
            type="submit"
            buttonStyle="solid"
            color="red"
            title={this.props.translate(SitecoreDictionary.Common.Save) as string}
            disabled={!this.isFormValid() || this.props.updateAutogiroCmdIsExecuting}
          />
        </form>
      </div>
    );
  }

  private onClearingNumberChange(newValue: string) {
    this.props.updateClearingNumberValidity(isClearingNumberValid(newValue));
    this.props.updateClearingNumberValue(newValue as string);
  }

  private onAccountNumberChange(newValue: string) {
    this.props.updateAccountNumberValidity(isAccountNumberValid(newValue));
    this.props.updateAccountNumberValue(newValue as string);
  }

  private updateBankAccount(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({ showConfirmation: true });
    this.props.updateAutogiro();
  }

  private isFormValid() {
    return this.props.isClearingNumberValid && this.props.isAccountNumberValid;
  }
}
