import * as React from 'react';
import { NavLink } from 'redux-first-router-link';

export interface FancyNavLinkState {
  isActive: boolean;
}

export interface FancyNavLinkProps {
  to: string;
  exact?: boolean;
  className?: string;
  children?: string | JSX.Element | Array<JSX.Element>;
  isTargetBlank?: boolean;
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
}

export type FancyNavLinkAllProps = FancyNavLinkProps;

export class FancyNavLink extends React.Component<FancyNavLinkProps, FancyNavLinkState> {
  render() {
    return (
      <NavLink
        activeStyle={{ borderColor: 'rgba(0,0,0,0.6)' }}
        activeClassName="FancyNavLink--active bg-black-10"
        exact={this.props.exact}
        className={'FancyNavLink ' + (this.props.className ? this.props.className : '')}
        to={this.props.to}
        children={this.props.children}
        onClick={this.props.clickHandler}
        target={this.props.isTargetBlank ? '_blank' : ''} // "_blank" or default to if empty nothing
      />
    );
  }
}
