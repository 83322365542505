import * as React from 'react';

interface BowProps {
  className?: string;
  strokeColor?: string;
  style?: React.CSSProperties;
}

export const Bow: React.SFC<BowProps> = props => {
  const strokeColor = props.strokeColor || '#262626';
  return (
    <svg viewBox="0 0 100 17.5" style={props.style} className={'Bow ' + (props.className ? props.className : '')}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke={strokeColor}>
          <path d="M0,0 C33,23 69,23 100,0" />
        </g>
      </g>
    </svg>
  );
};
