import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface PassDetailsFailedFetchProps {
  translate: TranslateFunction;
}

export const PassDetailsFailedFetch: React.SFC<PassDetailsFailedFetchProps> = props => (
  <div className="PassDetails">
    <div className="HeroImage Banner">
      <h1>{props.translate(SitecoreDictionary.PassDetails.LoadingFailed) as string}</h1>
    </div>
  </div>
);
