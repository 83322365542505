import * as React from 'react';

import { IconCheckmark } from '../icons/IconCheckMark';

interface TickersProps {
  tickers: Array<string>;
}

export const Tickers: React.SFC<TickersProps> = ({ tickers }) => {
  if (tickers && tickers.length) {
    return (
      <div className="Tickers hide-for-medium-down">
        {tickers.map((ticker, index) => (
          <div key={ticker + '' + index} className="Tickers__Item">
            <IconCheckmark selected={true} />
            {ticker}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};
