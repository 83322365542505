import * as React from 'react';

import { ApiError } from 'store/global/errors';
import ErrorBubble from './ErrorBubble';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { TranslateFunction } from 'react-localize-redux';

interface ErrorsProps {
  className?: string;
  errors?: ReadonlyArray<ApiError>;
  onClose: (error: ApiError) => void;
  translate: TranslateFunction;
}

const Errors: React.SFC<ErrorsProps> = props => (
  <TransitionGroup className={props.className + ' Errors'}>
    {props.errors &&
      props.errors.map(error => (
        <CSSTransition
          in={true}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="Error--animation"
          timeout={300}
          key={error.id}
        >
          <ErrorBubble translate={props.translate} error={error} onClose={props.onClose} />
        </CSSTransition>
      ))}
  </TransitionGroup>
);

export default Errors;
