import * as React from 'react';
import NewsItem from './NewsItem';
import { EntityStatus } from 'store/global/fetch';
import { Spinner } from 'components/common/Spinner';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface NewsPassedProps {
  readonly startPageMode?: boolean;
}

export interface NewsDataProps {
  news: Array<fos.api.News>;
  fetchState: EntityStatus;
  translate: TranslateFunction;
}

export interface NewsActionProps {
  fetchNews: () => void;
}

type NewsProps = NewsDataProps & NewsActionProps & NewsPassedProps;

export class News extends React.Component<NewsProps, {}> {
  componentDidMount() {
    this.props.fetchNews();
  }

  render() {
    if (this.props.fetchState === EntityStatus.FETCHING) {
      return (
        <div className="Statistics__Spinner__Container">
          <Spinner className="Statistics__Spinner" />
        </div>
      );
    } else {
      return (
        <div className={'News ' + (this.props.startPageMode ? 'News--startPageMode' : '')}>
          {this.props.startPageMode && this.props.news !== null && this.props.news.length > 0 && (
            <div className="HeaderContent__Secondary HeaderContent__Secondary--StartPage">
              <h3 className="Secondary__TitleText Secondary__TitleText--StartPage">
                {this.props.translate(SitecoreDictionary.MainMenu.News)}
              </h3>
            </div>
          )}
          {this.props.news &&
            this.props.news.map((n, i) => (
              <NewsItem
                key={i}
                title={n.Title}
                text={n.Text}
                imageUrl={n.ImageUrl}
                date={n.Date}
                tags={n.Tags}
                url={n.Url}
              />
            ))}
        </div>
      );
    }
  }
}
