import * as React from 'react';
import { TextBox } from 'components/common/TextBox';
import { PopupGDPR } from 'components/shoppingBasket/forms/PopupGDPR';
import { UISettingsProfileUnderEdit } from 'store/global/ui/settings/profile';
import { ProfilePropertyNameValues } from 'store/global/ui/settings/profile/uiSettingsProfileModels';
import { TextBoxMessagingStatus } from 'components/common/TextBox';

import { SitecoreDictionary } from 'models';
import { isValidEmail } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface ProfileDataProps {
  editableUser?: fos.api.User;
  uiSettingsProfileState: UISettingsProfileUnderEdit;
  readonly unchangedUser: fos.api.User;
  inloggadFoereningId: number | undefined;
  translate: TranslateFunction;
  cmdErrorCode?: number;
  isDesktopMode: boolean;
  getIsExecutingForPropertyName: (propertName: string) => boolean;
  getIsExecutedForPropertyName: (propertName: string) => boolean;
  getIsExecutingFailedForPropertyName: (propertName: string) => boolean;
}

export interface ProfileActionProps {
  onUserPropertyChange: (editedUser: fos.api.User) => void;
  onEditField: (uiField: UISettingsProfileUnderEdit) => void;
  onRevertField: (uiField: UISettingsProfileUnderEdit, unchangedUser: fos.api.User) => void;
  onAcceptField: (uiField: UISettingsProfileUnderEdit, editedUser: fos.api.User) => void;
  onTextBoxClick: (uiField: ProfilePropertyNameValues) => void;
  getProfileInfo: (foereningId: number) => void;
  updateUserProfileCmd: (foereningId: number, user: fos.api.User, propertyName: string) => void;
  initializeUserProfileCmd: (foereningId: number, propertyName: string) => void;
}
interface ProfileState {
  isGdprVillkorOpened?: boolean;
}

const initialState: ProfileState = {
  isGdprVillkorOpened: false,
};

type ProfileProps = ProfileDataProps & ProfileActionProps;
export class Profile extends React.Component<ProfileProps, ProfileState> {
  constructor(props: ProfileProps) {
    super(props);
    this.state = initialState;
    this.toggleGdprVillkor = this.toggleGdprVillkor.bind(this);
  }

  componentDidMount() {
    if (!!this.props.inloggadFoereningId) {
      this.props.getProfileInfo(this.props.inloggadFoereningId);
    }
  }

  fieldChanged(evt: React.ChangeEvent<HTMLInputElement>) {
    const propertyName = evt.target.name as ProfilePropertyNameValues;
    const user = { ...this.props.editableUser };
    if (user[propertyName] === undefined) {
      console.warn(`${evt.target.name} does not exis on user!`);
    }
    user[propertyName] = evt.target.value;
    if (
      this.props.getIsExecutedForPropertyName(propertyName) ||
      this.props.getIsExecutingFailedForPropertyName(propertyName)
    ) {
      this.resetCmdForProperty(propertyName);
    }
    this.props.onUserPropertyChange(user as fos.api.User);
  }

  /*
   * We are only allowed to start editing a field if we are not currently editing another field.
   */
  activateEditMode(propertyName: string) {
    const uiField = { ...this.props.uiSettingsProfileState };
    for (const key in uiField) {
      if ((uiField as any)[key] === true) {
        return;
      } else {
        (uiField as any)[key] = false;
      }
    }
    (uiField as any)[propertyName] = true;
    this.props.onEditField(uiField);
  }

  onTextBoxClick(propertyName: ProfilePropertyNameValues) {
    this.props.onTextBoxClick(propertyName);
  }

  onRevertClicked(propertyName: string) {
    const uiField = { ...this.props.uiSettingsProfileState };
    if (propertyName === ProfilePropertyNameValues.City || propertyName === ProfilePropertyNameValues.ZipCode) {
      uiField[ProfilePropertyNameValues.City] = false;
      uiField[ProfilePropertyNameValues.ZipCode] = false;
    } else {
      (uiField as any)[propertyName] = false;
    }
    this.props.onRevertField(uiField, this.props.unchangedUser);
  }

  onAcceptClicked(propertyName: ProfilePropertyNameValues, value: string) {
    if (propertyName === ProfilePropertyNameValues.City || propertyName === ProfilePropertyNameValues.ZipCode) {
      if (
        !this.isInputCorrectlyFormatted(
          ProfilePropertyNameValues.City,
          (this.props.editableUser as fos.api.User).City
        ) ||
        !this.isInputCorrectlyFormatted(
          ProfilePropertyNameValues.ZipCode,
          (this.props.editableUser as fos.api.User).ZipCode
        )
      ) {
        return;
      }
    } else {
      if (!this.isInputCorrectlyFormatted(propertyName, value)) {
        return;
      }
    }
    const uiField = { ...this.props.uiSettingsProfileState };
    if (propertyName === ProfilePropertyNameValues.City || propertyName === ProfilePropertyNameValues.ZipCode) {
      uiField[ProfilePropertyNameValues.City] = false;
      uiField[ProfilePropertyNameValues.ZipCode] = false;
    } else {
      uiField[propertyName] = false;
    }
    this.props.onAcceptField(uiField, this.props.editableUser as fos.api.User);
    if (!!this.props.inloggadFoereningId) {
      this.props.updateUserProfileCmd(
        this.props.inloggadFoereningId,
        this.props.editableUser as fos.api.User,
        propertyName
      );
    }
  }

  isInputCorrectlyFormatted(propertyName: ProfilePropertyNameValues, value: string): boolean {
    switch (propertyName) {
      case ProfilePropertyNameValues.Email:
        return isValidEmail(value);
      case ProfilePropertyNameValues.ZipCode:
        return !(!value || isNaN(Number(value)) || value.length !== 5);
      case ProfilePropertyNameValues.PhoneNumber:
        var regEx = /^[0-9 ()+-]+$/;
        return !!value && regEx.test(value) && value.length > 8;
      // return !isNaN(Number(value));
      case ProfilePropertyNameValues.FirstName:
      case ProfilePropertyNameValues.LastName:
        return !!value && value.trim() !== '' && !/\d+/.test(value);
      case ProfilePropertyNameValues.Address:
      case ProfilePropertyNameValues.City:
        return !!value && value.trim() !== '';
      default:
        console.warn(`Property ${propertyName} has no validation rules!`);
        return true;
    }
  }
  renderAlerts(
    propertyName: ProfilePropertyNameValues,
    uiSettingsProfileState: UISettingsProfileUnderEdit,
    value: string
  ): TextBoxMessagingStatus | undefined {
    // TODO: Handle error statuses for textbox
    if (
      uiSettingsProfileState[propertyName] &&
      uiSettingsProfileState.PropertyFieldUnderClick !== propertyName &&
      !(
        (uiSettingsProfileState.PropertyFieldUnderClick === ProfilePropertyNameValues.ZipCode &&
          propertyName === ProfilePropertyNameValues.City) ||
        (uiSettingsProfileState.PropertyFieldUnderClick === ProfilePropertyNameValues.City &&
          propertyName === ProfilePropertyNameValues.ZipCode)
      )
    ) {
      // Får ej byta till ett annat fält innan att trycka på spara eller avbryt
      // Förutom Postnummer/Ort, då går det bra
      if (propertyName === ProfilePropertyNameValues.ZipCode && uiSettingsProfileState[propertyName] === true) {
        // Visa felet på Ort fältet istället
        return;
      }
      return {
        error: {
          show: true,
          message: this.props.translate(SitecoreDictionary.Profile.MustSaveBeforeContinue) as string,
        },
      };
    } else {
      if (
        propertyName === ProfilePropertyNameValues.City &&
        uiSettingsProfileState[ProfilePropertyNameValues.ZipCode] &&
        uiSettingsProfileState.PropertyFieldUnderClick !== ProfilePropertyNameValues.ZipCode &&
        uiSettingsProfileState.PropertyFieldUnderClick !== ProfilePropertyNameValues.City
      ) {
        return {
          error: {
            show: true,
            message: this.props.translate(SitecoreDictionary.Profile.MustSaveBeforeContinue) as string,
          },
        };
      }
      if (!value || value.trim() === '') {
        // Alla fält är obligatoriska
        /*return {
          error: {
            show: true,
            message: this.props.translate(SitecoreDictionary.Profile.RequiredFieldError) as string
          }
        };*/
      } else {
        if (!this.isInputCorrectlyFormatted(propertyName, value)) {
          switch (propertyName) {
            case ProfilePropertyNameValues.FirstName:
            case ProfilePropertyNameValues.LastName:
              return {
                error: {
                  show: true,
                  message: this.props.translate(SitecoreDictionary.Profile.FieldAcceptOnlyChars) as string,
                },
              };
            case ProfilePropertyNameValues.Email:
              return {
                error: {
                  show: true,
                  message: this.props.translate(SitecoreDictionary.Profile.InvalidEmail) as string,
                },
              };
            case ProfilePropertyNameValues.ZipCode:
              return {
                error: {
                  show: true,
                  message: this.props.translate(SitecoreDictionary.Profile.InvalidZipCode) as string,
                },
              };
            case ProfilePropertyNameValues.PhoneNumber:
              return {
                error: {
                  show: true,
                  message: this.props.translate(SitecoreDictionary.Profile.InvalidTelNumber) as string,
                },
              };
            default:
              break;
          }
        }
      }
      if (this.props.getIsExecutedForPropertyName(propertyName)) {
        this.resetCmdForProperty(propertyName, 3000);
        return {
          warning: {
            show: true,
            message: this.props.translate(SitecoreDictionary.Common.SavingSucceed) as string,
          },
        };
      }
      if (this.props.getIsExecutingFailedForPropertyName(propertyName)) {
        this.resetCmdForProperty(propertyName, 6000);
        const msg = {
          error: {
            show: true,
            message: this.props.translate(SitecoreDictionary.Common.SavingFailed) as string,
          },
        };
        switch (propertyName) {
          case ProfilePropertyNameValues.Email:
            if (this.props.cmdErrorCode === 409) {
              msg.error.message = this.props.translate(SitecoreDictionary.Profile.EmailAlreadyExists) as string;
            }
            break;
          default:
            break;
        }
        return msg;
      }
    }
    return undefined;
  }

  renderTextBox(title: string, value: string, propertyName: ProfilePropertyNameValues, enabled: boolean) {
    return (
      <TextBox
        className={propertyName}
        onValueChange={event => this.fieldChanged(event)}
        activateEditMode={property => this.activateEditMode(property)}
        onTextBoxClick={(property: ProfilePropertyNameValues) => this.onTextBoxClick(property)}
        onRevertClicked={property => this.onRevertClicked(property)}
        onAcceptClicked={(property, newValue) => this.onAcceptClicked(property as ProfilePropertyNameValues, newValue)}
        title={title}
        propertyName={propertyName}
        value={value || ''}
        enabled={enabled}
        alerts={this.renderAlerts(propertyName, this.props.uiSettingsProfileState, value)}
        isValid={this.isInputCorrectlyFormatted(propertyName, value)}
        isWorking={this.props.getIsExecutingForPropertyName(propertyName)}
        alwaysHideActions={propertyName === ProfilePropertyNameValues.ZipCode}
      />
    );
  }

  render() {
    const { uiSettingsProfileState, editableUser } = this.props;

    return (
      <div className="Profile">
        {editableUser &&
          this.renderTextBox(
            this.props.translate(SitecoreDictionary.Profile.Firstname) as string,
            editableUser.FirstName,
            ProfilePropertyNameValues.FirstName,
            uiSettingsProfileState.FirstName
          )}
        {editableUser &&
          this.renderTextBox(
            this.props.translate(SitecoreDictionary.Profile.Lastname) as string,
            editableUser.LastName,
            ProfilePropertyNameValues.LastName,
            uiSettingsProfileState.LastName
          )}
        {editableUser &&
          this.renderTextBox(
            this.props.translate(SitecoreDictionary.Profile.Email) as string,
            editableUser.Email,
            ProfilePropertyNameValues.Email,
            uiSettingsProfileState.Email
          )}
        {editableUser &&
          this.renderTextBox(
            this.props.translate(SitecoreDictionary.Profile.Phonenumber) as string,
            editableUser.PhoneNumber,
            ProfilePropertyNameValues.PhoneNumber,
            uiSettingsProfileState.PhoneNumber
          )}
        {editableUser &&
          this.renderTextBox(
            this.props.translate(SitecoreDictionary.Profile.Address) as string,
            editableUser.Address,
            ProfilePropertyNameValues.Address,
            uiSettingsProfileState.Address
          )}
        {editableUser && (
          <div className="Profile__PostCode__City__Container">
            {this.renderTextBox(
              this.props.translate(SitecoreDictionary.Profile.ZipCode) as string,
              editableUser.ZipCode,
              ProfilePropertyNameValues.ZipCode,
              uiSettingsProfileState.ZipCode || uiSettingsProfileState.City
            )}
            {this.renderTextBox(
              this.props.translate(SitecoreDictionary.Profile.City) as string,
              editableUser.City,
              ProfilePropertyNameValues.City,
              uiSettingsProfileState.City || uiSettingsProfileState.ZipCode
            )}
          </div>
        )}

        {
          <React.Fragment>
            <span className="Profile-gdpr">
              <div className="TermsCheckbox__Link" onClick={this.toggleGdprVillkor}>
                Friskis hantering av personuppgifter
              </div>
            </span>
            {this.state.isGdprVillkorOpened && (
              <PopupGDPR onClose={this.toggleGdprVillkor} translate={this.props.translate} />
            )}
          </React.Fragment>
        }
      </div>
    );
  }

  private toggleGdprVillkor() {
    this.setState({
      isGdprVillkorOpened: !this.state.isGdprVillkorOpened,
    });
  }

  private resetCmdForProperty(propertyName: ProfilePropertyNameValues, timeout?: number) {
    setTimeout(
      () => this.props.initializeUserProfileCmd(this.props.inloggadFoereningId as number, propertyName),
      timeout
    );
  }
}
