import { Reducer } from 'redux';
import { Action } from 'store';
import { UpdateUserProfileCmdActionTypes } from '.';

export interface UpdateUserProfileCmdState {
  user?: fos.api.User;
  errorCode?: number;
}

const initialState: UpdateUserProfileCmdState = {};

export const updateUserProfileCmdReducer: Reducer<UpdateUserProfileCmdState> = (
  state = initialState,
  action: Action
): UpdateUserProfileCmdState => {
  switch (action.type) {
    case UpdateUserProfileCmdActionTypes.UpdateUser:
      return {
        ...state,
        user: action.user,
      };
    case UpdateUserProfileCmdActionTypes.ClearUser:
      return {
        ...state,
        user: undefined,
      };
    case UpdateUserProfileCmdActionTypes.ErrorCode:
      return {
        ...state,
        errorCode: action.errorCode,
      };
    case UpdateUserProfileCmdActionTypes.ClearErrorCode:
      return {
        ...state,
        errorCode: undefined,
      };
    default:
      return state;
  }
};
