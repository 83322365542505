import * as React from 'react';

export interface SpinnerProps {
  appearance?: 'white' | 'red';
  alignment?: string;
  size?: string;
  type?: string;
  className?: string;
}

export const Spinner: React.SFC<SpinnerProps> = props => (
  <div
    className={
      'Spinner' +
      (props.appearance === 'white' ? ' Spinner--white' : '') +
      ' ' +
      (props.alignment === 'center' ? ' Spinner--center' : '') +
      ' ' +
      (props.alignment === 'center' ? ' Spinner--medium' : '') +
      ' ' +
      (props.className ? props.className : '') +
      ' ' +
      (props.type === 'insideButton' ? ' Spinner--insideButton' : '')
    }
  />
);
