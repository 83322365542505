import * as React from 'react';

export interface IconMenuStatisticsProps {
  title?: string;
}

export class IconMenuStatistics extends React.Component<IconMenuStatisticsProps, {}> {
  fillColour = '#000';

  // TODO: Fix translation support for text

  render() {
    return (
      /* tslint:disable */
      <svg width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg" className="IconMenuStatistics">
        <title>{this.props.title}</title>
        <style type="text/css">
          {`
        .IconMenuStatistics {
            width: 55px;
            height: 55px;
        }
        .IconMenuStatistics .cls-3{fill:#343434}
        `}
        </style>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path fill="none" d="M0 0h40v40H0z" />
            <path
              stroke="#343434"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              d="M9.21 26.06l5.27-6.47 7.49 9.18 8.46-16.64"
            />
            <circle className="cls-3" cx="14.48" cy="19.59" r="1.54" />
            <circle className="cls-3" cx="21.89" cy="28.31" r="1.54" />
            <circle className="cls-3" cx="30.73" cy="11.69" r="1.54" />
            <circle className="cls-3" cx="8.75" cy="26.27" r="1.54" />
          </g>
        </g>
      </svg>
    );
  }
}
