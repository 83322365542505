export enum updateAutogiroActionType {
  SET_CLEARING_NUMBER = 'USER/UPDATE_AUTOGIRO_CMD/SET_CLEARING_NUMBER',
  SET_ACCOUNT_NUMBER = 'USER/UPDATE_AUTOGIRO_CMD/SET_ACCOUNT_NUMBER',
  SET_AUTOGIRO_TO_UPDATE = 'USER/UPDATE_AUTOGIRO_CMD/SET_AUTOGIRO_TO_UPDATE',
  SET_CLEARING_NUMBER_VALIDITY = 'USER/UPDATE_AUTOGIRO_CMD/SET_CLEARING_NUMBER_VALIDITY',
  SET_ACCOUNT_NUMBER_VALIDITY = 'USER/UPDATE_AUTOGIRO_CMD/SET_ACCOUNT_NUMBER_VALIDITY',
  SET_ERROR_MESSAGE = 'USER/UPDATE_AUTOGIRO_CMD/SET_ERROR_MESSAGE',
}

interface SetAutogiroToUpdateAction {
  type: updateAutogiroActionType.SET_AUTOGIRO_TO_UPDATE;
  id: string;
  currentClearingNumber: string;
  currentAccountNumber: string;
}

interface SetClearingNumberAction {
  type: updateAutogiroActionType.SET_CLEARING_NUMBER;
  newValue: string;
}

interface SetAccountNumberAction {
  type: updateAutogiroActionType.SET_ACCOUNT_NUMBER;
  newValue: string;
}

interface SetClearingNumberValidityAction {
  type: updateAutogiroActionType.SET_CLEARING_NUMBER_VALIDITY;
  isValid: boolean;
}

interface SetAccountNumberValidityAction {
  type: updateAutogiroActionType.SET_ACCOUNT_NUMBER_VALIDITY;
  isValid: boolean;
}

interface SetErrorMessageAction {
  type: updateAutogiroActionType.SET_ERROR_MESSAGE;
  errorMessage: string;
}

const setAutogiroToUpdate = (
  id: string,
  currentClearingNumber: string,
  currentAccountNumber: string
): SetAutogiroToUpdateAction => ({
  type: updateAutogiroActionType.SET_AUTOGIRO_TO_UPDATE,
  id,
  currentClearingNumber,
  currentAccountNumber,
});

const setClearingNumber = (newValue: string = ''): SetClearingNumberAction => ({
  type: updateAutogiroActionType.SET_CLEARING_NUMBER,
  newValue,
});

const setAccountNumber = (newValue: string = ''): SetAccountNumberAction => ({
  type: updateAutogiroActionType.SET_ACCOUNT_NUMBER,
  newValue,
});

const setClearingNumberValidity = (isValid: boolean = false): SetClearingNumberValidityAction => ({
  type: updateAutogiroActionType.SET_CLEARING_NUMBER_VALIDITY,
  isValid,
});

const setAccountNumberValidity = (isValid: boolean = false): SetAccountNumberValidityAction => ({
  type: updateAutogiroActionType.SET_ACCOUNT_NUMBER_VALIDITY,
  isValid,
});

const setErrorMessage = (errorMessage: string): SetErrorMessageAction => ({
  type: updateAutogiroActionType.SET_ERROR_MESSAGE,
  errorMessage,
});

export const updateAutogiroActions = {
  setClearingNumber,
  setAccountNumber,
  setAutogiroToUpdate,
  setClearingNumberValidity,
  setAccountNumberValidity,
  setErrorMessage,
};

export type UpdateAutogiroAction =
  | SetClearingNumberAction
  | SetAccountNumberAction
  | SetAutogiroToUpdateAction
  | SetClearingNumberValidityAction
  | SetAccountNumberValidityAction
  | SetErrorMessageAction;
