import * as React from 'react';

import { PassItemSkeleton } from './PassItemSkeleton';

export interface PassItemSkeletonProps {
  numberOfDays: number;
  maxAmountOfPassItemsPerDay: number;
}

export class PassListSkeleton extends React.Component<PassItemSkeletonProps> {
  render() {
    return (
      <div className="PassList Skeleton">
        {Array.from({ length: this.props.numberOfDays }, (_, num) => (
          <div className="DayItem PassListDayItem" key={num}>
            <div className="StickyHolder" />
            <PassItemSkeleton maxAmountOfPassItemsPerDay={this.props.maxAmountOfPassItemsPerDay} />
          </div>
        ))}
      </div>
    );
  }
}
