import * as React from 'react';

import { StatusBar, ConfirmationTextBox, IconButton } from 'components/common';
import { SitecoreDictionary, SitecorePlaceholder } from 'models';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { NavLink } from 'redux-first-router-link';
import { passDetailsRouteAction } from 'store/location';
import { getDateTextForView } from 'websd/utils';
import { CommandStatus } from 'store/global/fetch';
import { TranslateFunction } from 'react-localize-redux';

export interface BookingItemInternalDataProps {
  bokning: fos.api.Bokning;
  translate: TranslateFunction;
}

export interface BookingItemDataProps {
  readonly instruktoerer: string[];
  readonly avbokaStatus: CommandStatus;
  readonly isCancelled: boolean;
}

export interface BookingItemActionProps {
  avbokaPass: (
    payload: fos.api.CancelPayload,
    dayViewObjectKey: string
  ) => Promise<ErrorResult | SuccessResult<fos.api.AvbokningResponse>>;
  setSelectedPass: (passId: string, passDay: string) => void;
}

export interface BokningItemState {
  showConfirmation?: boolean;
  showStatusMessage?: boolean;
}

const renderWithMaxLength = (input: string, maxLength: number) => {
  if (!input) {
    return '';
  } else {
    return input.length > maxLength
      ? input.substr(0, maxLength - 3) + '...'
      : input;
  }
};

type BookingItemProps = BookingItemDataProps &
  BookingItemActionProps &
  BookingItemInternalDataProps;

export class BookingItem extends React.Component<
  BookingItemProps,
  BokningItemState
> {
  constructor(props: BookingItemProps) {
    super(props);

    this.state = {
      showConfirmation: false,
      showStatusMessage: false
    };
  }

  avbokaPass = () => {
    const date = getDateTextForView(this.props.bokning.StartTime);
    this.props.avbokaPass(
      {
        bokningId: this.props.bokning.BokningId,
        foereningsId: this.props.bokning.FoereningId,
        queued: this.props.bokning.Koad,
        passId: this.props.bokning.PassId,
        anlaeggningsId: this.props.bokning.AnlaeggningsId,
      },
      date
    );

    this.setState({
      showStatusMessage: true,
      showConfirmation: false,
    });
  };

  showConfirmation = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.avbokaStatus !== CommandStatus.EXECUTING) {
      this.setState({
        showConfirmation: !this.state.showConfirmation,
      });
    }
  };

  hideConfirmation = () => {
    this.setState({
      showConfirmation: false
    });
  };

  getStatusMessage = () => {
    if (this.props.bokning.statusMessage) {
      return this.props.bokning.statusMessage;
    }
    return SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR;
  };

  getStatusMessageClass = () => {
    if (
      this.props.bokning.statusMessage ===
      SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return 'StatusMessage--Green';
    } else {
      return 'StatusMessage--Black';
    }
  };

  getIconButtonStyle = (): string => {
    if (this.props.isCancelled) {
      return '--Cancelled';
    }
    if (this.props.bokning.Koad) {
      return '--Queued';
    } else if (
      this.props.bokning.statusMessage ===
      SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return '--Available';
    }
    return '--Booked';
  };

  getIconButtonText = () => {
    let res: string = '';
    let position: string = this.props
      .translate(SitecoreDictionary.Schedule.QueuePosition)
      .toString();
    if (this.props.isCancelled) {
      return this.props
        .translate(SitecoreDictionary.Schedule.Messages.CANCELLED)
        .toString();
    }
    if (this.props.bokning.Koad) {
      res = position + ' ' + this.props.bokning.KoPlats;
    }
    return res;
  };

  getVisibleStatus = () => {
    if (this.state.showStatusMessage && !!this.props.bokning.statusMessage) {
      return true;
    }
    return false;
  };

  goToPass = () => {
    this.props.setSelectedPass(
      this.props.bokning.PassId,
      this.props.bokning.Day
    );
  }

  render() {
    return (
      <NavLink onClick={this.goToPass} to={passDetailsRouteAction(this.props.bokning)}>
        <div>
          <div className="PassItem" onClick={this.goToPass}>
            {this.state.showConfirmation && (
              <ConfirmationTextBox
                handleNo={this.hideConfirmation}
                translate={this.props.translate}
                handleYes={this.avbokaPass}
                text={this.props
                  .translate(SitecoreDictionary.Schedule.ConfirmCancel)
                  .toString()
                  .replace(SitecorePlaceholder.PASS, this.props.bokning.Name)
                  .replace(
                    SitecorePlaceholder.FOERENING,
                    this.props.bokning.AnlaeggningsNamn
                  )}
              />
            )}

            <div className="PassItem__Time">
              <div className="Time__StartTime">
                {this.props.bokning.PassStart}
              </div>
              <div className="Time__Duration">
                {this.props.bokning.PassLaengd} min
              </div>
            </div>

            <div className="PassItem__Info">
              <div className="Info__PassNameAndExtraInfo">
                <div className="PassNameAndExtraInfo__Container">
                  <div className="PassNameAndExtraInfo__PassName">
                    {this.props.bokning.Name}
                  </div>
                </div>
              </div>

              <div className="Info__InstructorName">{renderWithMaxLength(this.props.instruktoerer[0], 80)}</div>

              <div className="Info__FacilityNameAndLocale">
                <div className="FacilityNameAndLocale__Container">
                  <div className="FacilityNameAndLocale__FacilityName">
                    {this.props.bokning.AnlaeggningsNamn}
                  </div>
                </div>
              </div>
            </div>
            <IconButton
              handleOnClick={this.showConfirmation}
              text={this.getIconButtonText()}
              styleType={this.getIconButtonStyle()}
              isExecuting={this.props.avbokaStatus === CommandStatus.EXECUTING}
            />
          </div>
          <StatusBar
            visible={this.getVisibleStatus()}
            message={this.props.translate(this.getStatusMessage()).toString()}
            cssClass={this.getStatusMessageClass()}
          />
        </div>
      </NavLink>
    );
  }
}
