import * as React from 'react';
import { PaymentSettingsItem } from './PaymentSettingsItem';
import { EntityStatus } from '../../../store/global/fetch';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface AvtalegirosDataProps {
  data: fos.api.Avtalegiro[] | null;
  fetchState?: EntityStatus;
  translate: TranslateFunction;
}

export interface AvtalegirosActionProps {}

type AvtalegirosProps = AvtalegirosActionProps & AvtalegirosDataProps;

export const Avtalegiros = (props: AvtalegirosProps) => {
  const getAvtalegiro = () => {
    if (props.fetchState === EntityStatus.FETCHING || props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND) {
      return <PaymentSettingsItem translate={props.translate} />;
    } else if (props.data) {
      return props.data.map(b => (
        <PaymentSettingsItem
          key={b.Id}
          accountNumber={b.BankAccountNumber}
          type={b.BetalningsmedelType}
          translate={props.translate}
        />
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      {(props.fetchState === EntityStatus.FETCHING ||
        props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND ||
        (props.data && props.data.length > 0)) && (
        <div className="PaymentSettings__Title">
          {props.translate(SitecoreDictionary.Profile.PaymentSettings.Avtalegiro) as string}
        </div>
      )}
      {getAvtalegiro()}
    </div>
  );
};
