import * as React from 'react';
import { SitecoreDictionary } from 'models';
import { Spinner, MainButton } from 'components/common';

import { FoereningInternal } from 'store/foereningar/read';
import { TranslateFunction } from 'react-localize-redux';

export interface AcceptBookingRulesPopupDataProps {
  isLoading?: boolean;
  updateFailed?: boolean;
  foereningar: FoereningInternal[];
  focusedFoerening?: FoereningInternal;
  activeFoerning: number;
  isDesktopMode: boolean;
  translate: TranslateFunction;
}

export interface AcceptBookingRulesPopupActionProps {
  updateBookingRuleForActiveFoerening: (userAccepted: boolean) => void;
  fetchReglerIfNotInStore: (id: number) => void;
  setFocusOnFoerening: (id: number) => void;
}

export interface AcceptBookingRulesLocalProps {
  Id: number;
}

type AcceptBookingRulesPopupProps = AcceptBookingRulesPopupDataProps & AcceptBookingRulesPopupActionProps;

const Container = (props: any) => (
  <div className="AcceptBookingRulesPopup">
    <div className="Wrapper">{props.children}</div>
  </div>
);

export class AcceptBookingRulesPopup extends React.Component<
  AcceptBookingRulesPopupProps,
  AcceptBookingRulesLocalProps
> {
  constructor(props: AcceptBookingRulesPopupProps) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      Id: this.props.activeFoerning,
    };
  }

  toggle() {
    this.props.fetchReglerIfNotInStore(this.state.Id);
  }

  componentDidMount() {
    this.props.setFocusOnFoerening(this.props.activeFoerning);
    this.props.fetchReglerIfNotInStore(this.state.Id);
  }
  render() {
    if (this.props.isLoading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    return (
      <Container>
        <div className="Text">
          {this.props.updateFailed ? (
            this.props.translate(SitecoreDictionary.AcceptBookingRulesPopup.FailedToUpdate)
          ) : (
            <div>
              <h3>Bokningsregler</h3>
              {this.props.focusedFoerening && this.props.focusedFoerening.RuleText ? (
                <div
                  className="MedlemsVillkor__PopupSelect__Text rich-text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.focusedFoerening.RuleText,
                  }}
                />
              ) : (
                <div className="Skeleton__textFields" />
              )}
            </div>
          )}
        </div>
        <div className="Buttons">
          <MainButton
            color="white"
            className="CancelButton Button--secondary"
            handleOnClick={() => this.props.updateBookingRuleForActiveFoerening(false)}
            title={this.props.translate(SitecoreDictionary.AcceptBookingRulesPopup.Cancel) as string}
          />
          <MainButton
            className="AcceptButton"
            handleOnClick={() => this.props.updateBookingRuleForActiveFoerening(true)}
            title={
              (this.props.updateFailed
                ? this.props.translate(SitecoreDictionary.AcceptBookingRulesPopup.TryAgain)
                : this.props.translate(SitecoreDictionary.AcceptBookingRulesPopup.Accept)) as string
            }
          />
        </div>
      </Container>
    );
  }
}
