import {
  addSpecialintressen,
  addSavedSpecialintressen,
  removeSavedSpecialintresse,
  addSavedAnlaeggningar,
  removeSavedAnlaeggning,
} from './read/trainingPreferencesReadActions';
import { api } from 'api';
import { ThunkAction, RootState } from 'store/rootReducer';
import { getActiveFoerening } from 'utils';

export interface SpecialIntressen {
  childAgeGroups: Array<string>;
  workoutLevels: Array<string>;
}

export interface UpdateSpecialIntressenRequest extends SpecialIntressen {
  userId: number;
}

export const getSpecialIntressen = (): ThunkAction => dispatch => {
  const promise = api.get<SpecialIntressen>('Settings/GetSpecialIntressen');

  promise.then(result => {
    if (!result.error) {
      dispatch(addSpecialintressen(result.result.childAgeGroups, result.result.workoutLevels));
    }
  });
};

export const saveSpecialIntressen = (): ThunkAction => (dispatch, getState: () => RootState) => {
  const state = getState();
  const workoutLevels = state.trainingPreferences.write.workoutLevels;
  const childAgeGroups = state.trainingPreferences.write.childAgeGroups;
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }

  const promise = api.post<never, UpdateSpecialIntressenRequest>('Settings/SaveSpecialIntressen', {
    childAgeGroups: childAgeGroups,
    workoutLevels: workoutLevels,
    userId: userId,
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(getSavedSpecialIntressen());
    }
  });
};

export const getSavedSpecialIntressen = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }

  const promise = api.get<Array<fos.api.SavedSpecialintresse>>('Settings/GetSavedSpecialIntressen?userId=' + userId);
  promise.then(result => {
    if (!result.error) {
      dispatch(addSavedSpecialintressen(result.result));
    }
  });
};

export const deleteSavedSpecialintresse = (specialintresse: fos.api.SavedSpecialintresse): ThunkAction => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }

  const promise = api.post('settings/RemoveSpecialintresse', {
    UserId: userId,
    SpecialintresseId: specialintresse.SpecialintresseId,
  });
  promise.then(result => {
    dispatch(removeSavedSpecialintresse(specialintresse.SpecialintresseId));
  });
};

export const deleteSavedAnlaeggning = (specialintresse: fos.api.SavedSpecialintresse): ThunkAction => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }

  const promise = api.post('settings/RemoveFavoriteAnlaeggning', {
    UserId: userId,
    SpecialintresseId: specialintresse.SpecialintresseId,
  });
  promise.then(result => {
    dispatch(removeSavedAnlaeggning(specialintresse.SpecialintresseId));
  });
};

export const getSavedAnlaeggningar = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }

  const promise = api.get<Array<fos.api.SavedSpecialintresse>>(
    'Settings/GetSavedFavoriteAnlaeggningar?userId=' + userId
  );
  promise.then(result => {
    if (!result.error) {
      dispatch(addSavedAnlaeggningar(result.result));
    }
  });
};

export const saveFavoriteAnlaeggningar = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const userId = state.global.session.userId;
  const activeFoerning = getActiveFoerening(
    state.global.session.inloggadeFoereningar,
    state.global.session.activeFoerening
  );

  const anlaeggningar = state.trainingPreferences.write.favoriteAnlaeggningar;

  if (!userId || !activeFoerning) {
    return;
  }

  const promise = api.post('Settings/SaveFavoriteAnlaeggningar', { Anlaeggningar: anlaeggningar, UserId: userId });
  promise.then(result => {
    if (!result.error) {
      dispatch(getSavedAnlaeggningar());
    }
  });
};
