import * as React from 'react';

import { Banner, MainButton, Logotyp } from 'components/common';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as Location } from 'store/location/locationActions';
import background from 'static/assets/background-login.jpg';
import { IconKvitto, IconCheckmark } from '../icons';
import { PaymentResult } from 'store/payment';
import { LocationState } from 'store/location';

import { SitecoreDictionary } from 'models';
import { SignatureContainer } from 'components/shop';
import { TranslateFunction } from 'react-localize-redux';

export interface PaymentDataProps {
  isDesktop: boolean;
  paymentSucceded?: boolean;
  signatureCases?: fos.api.SignatureCase[];
  location: LocationState<PaymentResult>;
  translate: TranslateFunction;
  foereningUrl: string;
  isInitialized: boolean;
}

export interface PaymentActionProps {
  processResult: (result: PaymentResult) => void;
}

type PaymentProps = PaymentDataProps & PaymentActionProps;

export class Payment extends React.Component<PaymentProps> {

  componentDidMount() {
    if (this.props.isInitialized) {
      this.props.processResult(this.props.location.query);
    }
  }

  componentDidUpdate(prevProps: PaymentDataProps) {
    if (!prevProps.isInitialized && this.props.isInitialized) {
      this.props.processResult(this.props.location.query);
    }
  }

  render() {
    if (!this.props.paymentSucceded) {
      return null;
    }
    if (!!this.props.signatureCases && !!this.props.signatureCases.find(s => !s.signed)) {
      return this.renderSignatures();
    }
    return this.renderPaymentSuccess();
  }

  private renderPaymentSuccess() {
    return (
      <Banner backgroundUrl={background} className={'Payment ' + (this.props.isDesktop ? 'isDesktop' : '')}>
        <Logotyp />
        <div className="Payment__Kvitto">
          <IconKvitto className="Payment__Kvitto__Background" />
          <div className="Payment__Kvitto__Text">
            <h3 className="Payment__Kvitto__Text__Title">
              {this.props.translate(SitecoreDictionary.Receipt.ReceiptTitle)}
            </h3>
            <p className="Payment__Kvitto__Text__Content">
              {this.props.translate(SitecoreDictionary.Receipt.ReceiptText)}
            </p>
            <IconCheckmark className="Payment__Kvitto__Text__Checkmark" selected={true} strokeWidth={3} />
          </div>
        </div>
        {
          <div className="Payment__Navigation">
            <h3 className="Payment__Navigation__Title">
              {this.props.translate(SitecoreDictionary.Receipt.ReceiptAdditionalTitle)}
            </h3>
            <p className="Payment__Navigation__Text">
              {this.props.translate(SitecoreDictionary.Receipt.ReceiptAdditionalText)}
            </p>
            <FancyNavLink className="Payment__Navigation__Continue" to={RouteMap[Location.Home]}>
              <MainButton
                className="Payment__Navigation__Continue__Button"
                title={this.props.translate(SitecoreDictionary.Receipt.ContinueToApp) as string}
                buttonStyle="solid"
                color="white"
              />
            </FancyNavLink>
            <FancyNavLink to={this.props.foereningUrl} isTargetBlank={true}>
              <MainButton
                className="Payment__Navigation__ContinueToWebSite"
                title={this.props.translate(SitecoreDictionary.Receipt.ContinueToTheWebSite) as string}
                buttonStyle="outline"
                color="white"
              />
            </FancyNavLink>
          </div>
        }
        {!!this.props.location.query.receiptid && (
          <FancyNavLink to={'/receipt?receiptId=' + this.props.location.query.receiptid}>
            <MainButton
              className="Payment__Navigation__ContinueToWebSite"
              title={this.props.translate(SitecoreDictionary.Receipt.DisplayReceipt) as string}
              buttonStyle="outline"
              color="white"
            />
          </FancyNavLink>
        )}
        {!this.props.location.query.receiptid && !!this.props.location.query.orderid && (
          <FancyNavLink to={'/receipt?orderId=' + this.props.location.query.orderid}>
            <MainButton
              className="Payment__Navigation__ContinueToWebSite"
              title={this.props.translate(SitecoreDictionary.Receipt.DisplayReceipt) as string}
              buttonStyle="outline"
              color="white"
            />
          </FancyNavLink>
        )}
      </Banner>
    );
  }

  private renderSignatures() {
    return (
      <Banner backgroundUrl={background} className={'Payment ' + (this.props.isDesktop ? 'isDesktop' : '')}>
        <SignatureContainer />
      </Banner>
    );
  }
}
