import * as React from 'react';

interface Day {
  day: string;
  element: HTMLDivElement;
}

export interface PassHeaderDataProps {
  onClick: (element: HTMLDivElement | null, day: string) => void;
  showPassForDay: { [day: string]: boolean };
  container: HTMLDivElement | null;
  passInDay: { day: string; passIds: string[] }[];
}

export const PassHeader: React.FunctionComponent<PassHeaderDataProps> = ({
  onClick,
  showPassForDay,
  container,
  passInDay,
}) => {
  const [topDay, setTopDay] = React.useState<string>('');
  const [passListContainer, setPassListContainer] = React.useState<HTMLDivElement | null>(null);
  const [headerWidth, setHeaderWidth] = React.useState<number>(0);
  const [days, setDays] = React.useState<Day[]>([]);

  const onScroll = React.useCallback(() => {
    if (!container) {
      return;
    }
    for (let i = days.length - 1; i >= 0; i--) {
      if (days[i].element === undefined) {
        continue;
      }
      if (
        days[i].element.parentElement !== null &&
        days[i].element.clientHeight > 0 &&
        days[i].element.offsetTop <= container.scrollTop
      ) {
        if (topDay !== days[i].day) {
          setTopDay(days[i].day);
        }
        return;
      }
    }
  }, [container, days, topDay]);

  const onResize = React.useCallback(() => {
    if (passListContainer) {
      setHeaderWidth(passListContainer.getBoundingClientRect().width);
    }
  }, [passListContainer, setHeaderWidth]);

  React.useEffect(() => {
    if (!!container) {
      const passListContainer = container.children[0] as HTMLDivElement;
      if (!!passListContainer) {
        const dayItems = passListContainer.children;
        const dayArray: Day[] = [];
        // Börja på 1, då den här komponenten (PassHeader) ligger som första child.
        // Sluta på dayItems.length - 1, då Padding ligger som sista child.
        for (let index = 1; index < dayItems.length - 1; index++) {
          const item = dayItems.item(index);
          if (!!item && item.childElementCount > 0) {
            const dayText = item.children[0].textContent;
            if (!!dayText) {
              dayArray.push({ day: dayText, element: item as HTMLDivElement });
            }
          }
        }
        setDays(dayArray);
        setPassListContainer(passListContainer);
        setHeaderWidth(passListContainer.getBoundingClientRect().width);
        setTopDay(dayArray[0].day);

        (dayItems[dayItems.length - 1] as HTMLDivElement).style.height = container.clientHeight - 44 + 'px';
      }
    }
  }, [container]);

  React.useEffect(() => {
    if (!!container) {
      container.addEventListener('scroll', onScroll);
      window.addEventListener('resize', onResize);
      onScroll();
      onResize();
      return () => {
        container.removeEventListener('scroll', onScroll);
        container.removeEventListener('resize', onResize);
      };
    }
    return () => {};
  }, [container, onScroll, onResize]);

  React.useEffect(() => {
    if (onScroll) {
      onScroll();
    }
  }, [onScroll, passInDay]);

  return (
    <div
      className={
        'DayItem__Header DayItem__Header--passlist DayItem__Header--nonexpanded' +
        (showPassForDay[topDay] ? ' DayItem__Header--expanded' : '')
      }
      style={{
        position: 'fixed',
        width: headerWidth,
        zIndex: 1000,
      }}
      onClick={() => {
        const day = days.find(d => d.day === topDay);
        onClick(!!day ? day.element : null, topDay);
      }}
    >
      {topDay}
    </div>
  );
};
