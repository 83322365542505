import * as React from 'react';
import { MainButton } from 'components/common';

interface ShoppingBasketButtonProps {
  amount: number;
  className: string;
}

interface ShoppingBasketButtonState {
  shoppingBasketButtonHasSomeFocus: boolean;
}

export class ShoppingBasketButton extends React.Component<ShoppingBasketButtonProps, ShoppingBasketButtonState> {
  constructor(props: ShoppingBasketButtonProps) {
    super(props);

    this.state = {
      shoppingBasketButtonHasSomeFocus: false,
    };
  }

  componentDidUpdate(prevProps: ShoppingBasketButtonProps) {
    if (prevProps.amount !== this.props.amount) {
      this.giveShoppingBasketButtonSomeFocus();
    }
  }

  giveShoppingBasketButtonSomeFocus = () => {
    this.setState(
      {
        ...this.state,
        shoppingBasketButtonHasSomeFocus: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            shoppingBasketButtonHasSomeFocus: false,
          });
        }, 100);
      }
    );
  };

  render() {
    return (
      <div className={this.props.className}>
        <MainButton
          className={
            'CartButton ' + (this.state.shoppingBasketButtonHasSomeFocus ? 'CartButton--receiveSomeFocus' : '')
          }
          color={this.props.amount === 0 ? 'transparent' : 'red'}
          buttonStyle={this.props.amount === 0 ? 'outline' : 'solid'}
        />
        <div
          className={
            'ShoppingBasketButton__Amount ' + (this.props.amount === 0 ? 'ShoppingBasketButton__Amount--empty' : '')
          }
        >
          {this.props.amount}
        </div>
      </div>
    );
  }
}
