import { removeGlobalHeader } from './../../../websd/utils/configureApi';
import { Reducer } from 'redux';
import { Action } from 'store';
import { AuthReadActionType } from './authReadActions';
import { registerGlobalHeader } from 'websd/utils/configureApi';

export interface AuthReadState {
  Token?: string;
  Giltighetstid?: Date;
  isAuthenticated?: boolean;
}

const initialState: AuthReadState = {};

export const AuthReadReducer: Reducer<AuthReadState> = (state = initialState, action: Action): AuthReadState => {
  switch (action.type) {
    case AuthReadActionType.GetAuth:
      registerGlobalHeader('Authorization', `Bearer ${action.token}`, true);
      return {
        Token: action.token,
        Giltighetstid: new Date(action.giltighetstid),
        isAuthenticated: true,
      };

    case AuthReadActionType.ClearAuth:
      removeGlobalHeader('Authorization');
      return { isAuthenticated: false };
    default:
      return state;
  }
};
