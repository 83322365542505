import { Reducer } from 'redux';
import { Action } from 'store';
import { ApiError } from './errorsModels';
import { ActionType } from './errorsActions';

export interface ErrorsState {
  readonly errors: ReadonlyArray<ApiError>;
  readonly appCrashed: Error | null;
  readonly adminError: string;
}

const initialState: ErrorsState = {
  errors: [],
  appCrashed: null,
  adminError: '',
};

export const errorsReducer: Reducer<ErrorsState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.AddError:
      return {
        ...state,
        errors: state.errors.concat([action.error]),
      };

    case ActionType.RemoveError:
      return {
        ...state,
        errors: state.errors.filter(e => e !== action.error),
      };

    case ActionType.AppCrashed:
      return {
        ...state,
        appCrashed: action.error,
      };

    case ActionType.AppResumeAfterCrash:
      return {
        ...state,
        appCrashed: null,
      };

    case ActionType.SetAdminError:
      return {
        ...state,
        adminError: action.error,
      };

    default:
      return state;
  }
};

export const getAllErrors = (state: ErrorsState) => state.errors;

export const getHasAppCrashed = (state: ErrorsState) => state.appCrashed !== null;
