export enum BasketActionTypes {
  CREATE_BASKET = 'CREATE_BASKET',
  DELETE_BASKET = 'DELETE_BASKET',
  ADD_PRODUCT = 'ADD_PRODUCT',
  SHOW_MEMBERSHIP_MESSAGE = 'SHOW_MEMBERSHIP_MESSAGE',
  SHOW_COUPON_MESSAGE = 'SHOW_COUPON_MESSAGE',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  UPDATE_BASKET = 'UPDATE_BASKET',
  ERROR_ON_PURCHASE = 'ERROR_ON_PURCHASE',
  ERROR_ON_CREATE_PAYMENT = 'ERROR_ON_CREATE_PAYMENT',
  ERROR_ON_CREATE_USER = 'ERROR_ON_CREATE_USER',
  UPDATE_AUTOGIRO_ACCOUNT_NUMBER = 'UPDATE_AUTOGIRO_ACCOUNT_NUMBER',
  UPDATE_AUTOGIRO_CLEARING_NUMBER = 'UPDATE_AUTOGIRO_CLEARING_NUMBER',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER',
  UPDATE_ORDER = 'UPDATE_ORDER',
}

interface AddProductAction {
  type: BasketActionTypes.ADD_PRODUCT;
  product: fos.api.Product;
  foereningId: number;
}

interface CreatePaymentErrorAction {
  type: BasketActionTypes.ERROR_ON_CREATE_PAYMENT;
  errors?: string[];
}

interface CreateUserErrorAction {
  type: BasketActionTypes.ERROR_ON_CREATE_USER;
  errors?: string[];
}

interface RemoveProductAction {
  type: BasketActionTypes.DELETE_PRODUCT;
  product: fos.api.Product;
  foereningId: number;
}

interface CreateBasketAction {
  type: BasketActionTypes.CREATE_BASKET;
  valuta?: string;
  foereningId: number;
}

interface UpdateBasketAction {
  type: BasketActionTypes.UPDATE_BASKET;
  products: fos.api.Product[];
  foereningId: number;
}

interface DeleteBasketAction {
  type: BasketActionTypes.DELETE_BASKET;
  foereningId: number;
}

interface BasketPurchaseErrorAction {
  type: BasketActionTypes.ERROR_ON_PURCHASE;
  error: string;
}

interface UpdateAutoGiroAccountNumber {
  type: BasketActionTypes.UPDATE_AUTOGIRO_ACCOUNT_NUMBER;
  value: string;
}

interface UpdateAutoGiroClearingNumber {
  type: BasketActionTypes.UPDATE_AUTOGIRO_CLEARING_NUMBER;
  value: string;
}

interface ShowMembershipMessageAction {
  type: BasketActionTypes.SHOW_MEMBERSHIP_MESSAGE;
  showMembershipMessage: boolean;
}

interface ShowCouponMessageAction {
  type: BasketActionTypes.SHOW_COUPON_MESSAGE;
  showCouponMessage: boolean;
}

interface UpdatePaymentMethodsAction {
  type: BasketActionTypes.UPDATE_PAYMENT_METHOD;
  paymentMethodId: number;
}

interface UpdatePhoneNumberAction {
  type: BasketActionTypes.UPDATE_PHONE_NUMBER;
  phoneNumber: string;
}

interface UpdateOrderAction {
  type: BasketActionTypes.UPDATE_ORDER;
  order: fos.api.Order;
}

export const updateAutogiroAccountNumber = (value: string): UpdateAutoGiroAccountNumber => ({
  type: BasketActionTypes.UPDATE_AUTOGIRO_ACCOUNT_NUMBER,
  value,
});

export const updateAutoGiroClearingNumber = (value: string): UpdateAutoGiroClearingNumber => ({
  type: BasketActionTypes.UPDATE_AUTOGIRO_CLEARING_NUMBER,
  value,
});

export const addProduct = (product: fos.api.Product, foereningId: number): AddProductAction => ({
  type: BasketActionTypes.ADD_PRODUCT,
  product,
  foereningId,
});

export const showMembershipMessage = (showMessage: boolean): ShowMembershipMessageAction => ({
  type: BasketActionTypes.SHOW_MEMBERSHIP_MESSAGE,
  showMembershipMessage: showMessage,
});

export const showCouponMessage = (showMessage: boolean): ShowCouponMessageAction => ({
  type: BasketActionTypes.SHOW_COUPON_MESSAGE,
  showCouponMessage: showMessage,
});

export const onCreatePaymentError = (errors?: string[]): CreatePaymentErrorAction => ({
  type: BasketActionTypes.ERROR_ON_CREATE_PAYMENT,
  errors,
});

export const onCreateUserError = (errors?: string[]): CreateUserErrorAction => ({
  type: BasketActionTypes.ERROR_ON_CREATE_USER,
  errors,
});

export const removeProduct = (product: fos.api.Product, foereningId: number): RemoveProductAction => ({
  type: BasketActionTypes.DELETE_PRODUCT,
  product,
  foereningId,
});

export const createBasket = (foereningId: number, valuta?: string): CreateBasketAction => ({
  type: BasketActionTypes.CREATE_BASKET,
  valuta,
  foereningId,
});

export const updateBasket = (foereningId: number, products: fos.api.Product[]): UpdateBasketAction => ({
  type: BasketActionTypes.UPDATE_BASKET,
  products,
  foereningId,
});

export const deleteBasket = (foereningId: number): DeleteBasketAction => ({
  type: BasketActionTypes.DELETE_BASKET,
  foereningId,
});

export const onBasketPurchaseError = (error: string): BasketPurchaseErrorAction => ({
  type: BasketActionTypes.ERROR_ON_PURCHASE,
  error,
});

export const updatePaymentMethod = (paymentMethodId: number): UpdatePaymentMethodsAction => ({
  type: BasketActionTypes.UPDATE_PAYMENT_METHOD,
  paymentMethodId,
});

export const updatePhoneNumberAction = (phoneNumber: string): UpdatePhoneNumberAction => ({
  type: BasketActionTypes.UPDATE_PHONE_NUMBER,
  phoneNumber,
});

export const updateOrderAction = (order: fos.api.Order): UpdateOrderAction => ({
  type: BasketActionTypes.UPDATE_ORDER,
  order,
});

export type BasketActions =
  | AddProductAction
  | ShowMembershipMessageAction
  | ShowCouponMessageAction
  | RemoveProductAction
  | CreateBasketAction
  | DeleteBasketAction
  | UpdateBasketAction
  | BasketPurchaseErrorAction
  | CreatePaymentErrorAction
  | UpdateAutoGiroAccountNumber
  | UpdateAutoGiroClearingNumber
  | CreateUserErrorAction
  | UpdatePaymentMethodsAction
  | UpdatePhoneNumberAction
  | UpdateOrderAction;
