import { api } from 'api';
import { ThunkAction } from 'store';
import { setImages } from './imageActions';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from 'store/global/fetch';
export const getImages = (): ThunkAction => dispatch => {
  dispatch(fetching(-1, FetchStatusAggregateTypes.GET_BILDER));
  const promise = api.get<{ [key: string]: string }>('image/getImages', {
    anonymousCall: true,
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(setImages(result.result));
      dispatch(fetched(-1, FetchStatusAggregateTypes.GET_BILDER, result.result));
    } else {
      dispatch(fetchedFailed(-1, FetchStatusAggregateTypes.GET_BILDER));
    }
  });

  return promise;
};
