import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { PaymentSettings, PaymentSettingsDataProps, PaymentSettingsActionProps } from './PaymentSettings';
import { getTranslate } from 'store/global/dictionary';
import { getRegistreradeBetalningsmedel } from 'store/user';
import { updateAutogiroActions } from 'store/user/updateAutogiroCmd';

const mapStateToProps = (state: RootState): PaymentSettingsDataProps => {
  return {
    betalningsmedel: state.user.read.betalningsmedel,
    inloggadFoereningId: state.global.session.activeFoerening,
    translate: getTranslate(state.locale),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PaymentSettingsActionProps => ({
  getRegistreradeBetalningsmedel: (foereningId: number) => dispatch(getRegistreradeBetalningsmedel(foereningId)),
  setAutogiroToUpdate: (id: string, currentClearingNumber: string, currentAccountNumber: string) =>
    dispatch(updateAutogiroActions.setAutogiroToUpdate(id, currentClearingNumber, currentAccountNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);
