import * as React from 'react';

type Directions = 'up' | 'down' | 'left' | 'right';

export interface IconArrowProps {
  color: string;
  direction: Directions;
  className?: string;
}

export const IconArrow: React.SFC<IconArrowProps> = props => {
  const getRotation = (direction: Directions) => {
    switch (direction.toLowerCase()) {
      case 'right':
        return 'rotateZ(225deg)';
      case 'down':
        return 'rotateZ(-45deg)';
      case 'left':
        return 'rotateZ(45deg)';
      case 'up':
        return 'rotateZ(135deg)';
      default:
        return 'rotateZ(45deg)';
    }
  };

  return (
    <div
      className={'IconArrow' + (!!props.className ? ` ${props.className}` : '')}
      style={{
        borderColor: props.color,
        transform: getRotation(props.direction),
      }}
    />
  );
};
