import 'airbnb-js-shims';
import attachFastClick from 'fastclick';
import * as React from 'react';
import { render as renderDom } from 'react-dom';
import { Provider } from 'react-redux';
import { hotjar } from './utils/hotjar';
import { configureStore } from './configureStore';
import App from './components/app';

export const store = configureStore();

const rootEl = document.getElementById('root');
const renderApp = (Component = App) => {
  renderDom(
    <Provider store={store}>
      <Component />
    </Provider>,
    rootEl
  );
};

document.addEventListener('deviceready', () => {
  if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', () => attachFastClick(document.body), false);
  }
});
renderApp();

if (module.hot) {
  // If hot module replacement is enabled we rerender app on changes
  module.hot.accept('./components/app', () => {
    renderApp(require('./components/app').default);
  });

  // If hot module replacement is enabled we replace the root reducer
  module.hot.accept('./store', () => {
    store.replaceReducer(require('./store').rootReducer);
  });
}

hotjar();
