import { Reducer } from 'redux';
import { PaymentReadActionTypes } from './';
import { Action } from 'store';

export interface PaymentReadState {
  paymentSucceded?: boolean;
  signatureCases?: fos.api.SignatureCase[];
  paymentMethods?: fos.api.PaymentMethod[];
  receipt?: fos.api.Receipt;
  receiptInfos?: fos.api.ReceiptInfo[];
  orderId?: number;
}

const initialState: PaymentReadState = {};

export const paymentReadReducer: Reducer<PaymentReadState> = (
  state: PaymentReadState = initialState,
  action: Action
): PaymentReadState => {
  switch (action.type) {
    case PaymentReadActionTypes.PaymentSucceded:
      return {
        ...state,
        paymentSucceded: action.paymentSucceded,
      };
    case PaymentReadActionTypes.SignatureCases:
      return {
        ...state,
        signatureCases: action.value,
      };
    case PaymentReadActionTypes.PaymentMethods:
      return {
        ...state,
        paymentMethods: action.value,
      };
    case PaymentReadActionTypes.Receipt:
      return {
        ...state,
        receipt: action.value,
      };
    case PaymentReadActionTypes.ReceiptInfos:
      return {
        ...state,
        receiptInfos: action.value,
      };
    case PaymentReadActionTypes.OrderId:
      return {
        ...state,
        orderId: action.value,
      };
    default:
      return state;
  }
};
