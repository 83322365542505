import * as React from 'react';

export interface IconMenuNotificationsProps {
  color?: string;
  title?: string;
}

export class IconMenuNotifications extends React.Component<IconMenuNotificationsProps, {}> {
  fillColour = '#000';

  random = 1 + Math.random() * (1000 - 1);
  rand = 'rand-' + Math.round(this.random);
  randD = '.rand-' + Math.round(this.random);

  // TODO: Fix translation support for text
  render() {
    return (
      <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" className={'IconMenuNotifications ' + this.rand}>
        <title>{this.props.title}</title>
        <style>
          {`

            .IconMenuNotifications${this.randD} {
              width: 55px;
              height: 55px;
            }
            .IconMenuNotifications${this.randD} .cls-2 {
              fill: none;
              stroke: ${this.props.color ? this.props.color : '#2a3143'};
              stroke-linecap: round;
              stroke-linejoin: round;
            }

          `}
        </style>
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill="none" d="M0 0h44v44H0z" />
          {/* tslint:disable-next-line:max-line-length */}
          <path
            className="cls-2"
            d="M30.57 28c0 1.72-4.85 3.1-10.83 3.1S8.9 29.73 8.9 28l2.28-3.53a7.63 7.63 0 0 0 1.12-2.93C12.85 18.09 14.68 10 19.74 10s6.79 8 7.33 11.47a7.71 7.71 0 0 0 1.18 3.09zM16.82 13.26A4.45 4.45 0 0 1 17.91 12m3.8 20.9a2.31 2.31 0 0 1-2 1.12 2.27 2.27 0 0 1-1.89-1.14"
          />
          <rect className="cls-2" x="18.38" y="5.98" width="2.71" height="2.71" rx="1.36" ry="1.36" />
        </g>
      </svg>
    );
  }
}
