import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { BookingItemDataProps, BookingItemInternalDataProps, BookingItem, BookingItemActionProps } from '.';
import { FetchCommandTypes, getCommandStatus } from 'store/global/fetch';
import { avbokaPass } from 'store/bokningar';
import { PassUtils } from 'utils';
import { setSelectedPassById } from 'store/global/ui/schedule/uiScheduleThunks';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';

const mapState = (state: RootState, props: BookingItemInternalDataProps): BookingItemDataProps => {
  const day = state.pass.read.days.find(d => d.key === props.bokning.Day);
  const pass = !!day ? day.items.find(p => p.PassId === props.bokning.PassId) : undefined;
  return {
    avbokaStatus: getCommandStatus(state.global.fetch, props.bokning.PassId, FetchCommandTypes.AVBOKA_PASS),
    isCancelled: PassUtils.isBookedPassCancelled(props.bokning, state.pass.read.days),
    instruktoerer: !!pass ? pass.Instruktoerer : [''],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): BookingItemActionProps => ({
  avbokaPass: (payload, dayViewObjectKey) =>
    dispatch<Promise<ErrorResult | SuccessResult<fos.api.AvbokningResponse>>>(avbokaPass(payload, dayViewObjectKey)),
  setSelectedPass: (passId, passDay) => dispatch(setSelectedPassById(passId, passDay)),
});

export default connect(mapState, mapDispatchToProps)(BookingItem);
