import * as React from 'react';
import { Spinner } from './Spinner';

interface IconButtonProps {
  text: string;
  styleType: string;
  cssClass?: string;
  visible?: boolean;
  isExecuting?: boolean;
  handleOnClick: (event: React.MouseEvent<HTMLButtonElement>, statusBarIsActive?: boolean) => void;
}

export class IconButton extends React.Component<IconButtonProps> {
  render() {
    return (
      <div
        className={
          'IconButton ' + this.props.cssClass + (this.props.visible ? ' IconButton__StatusMessage--visible' : '')
        }
      >
        {this.props.isExecuting && (
          <button
            className={'IconButton__Button IconButton__Button--disabled IconButton__Button' + this.props.styleType}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();
            }}
          >
            <Spinner type="insideButton" />
          </button>
        )}
        {!this.props.isExecuting && (
          <button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              this.props.handleOnClick(event, this.props.visible);
            }}
            className={'IconButton__Button IconButton__Button' + this.props.styleType}
          />
        )}

        <div className={'IconButton__TotalAndStatus'}>
          <div
            className={`TotalAndStatus__Status` + (!this.props.text.length ? ' TotaltAndStatus__Status--hidden' : '')}
          >
            {this.props.text}
          </div>
        </div>
      </div>
    );
  }
}
