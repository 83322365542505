import * as React from 'react';
import { BetalningsmedelTypes } from 'store/user';

import { SitecoreDictionary } from 'models';
import { MainButton } from '../../common';
import AutogiroSettingsContainer from './autogiroSettings';
import { TranslateFunction } from 'react-localize-redux';

export interface PaymentSettingsItemDataProps {
  id?: string;
  cardNumber?: string;
  cardIssuerName?: string;
  accountNumber?: string;
  clearingNumber?: string;
  bankName?: string;
  type?: BetalningsmedelTypes;
  translate: TranslateFunction;
  enableEdit?: boolean;
}

export interface PaymentSettingsItemActionProps {
  setAutogiroToUpdate?: (id: string, currentClearingNumber: string, currentAccountNumber: string) => void;
}

interface PaymentSettingsItemState {
  showUpdateAutogiroForm: boolean;
}

type PaymentSettingsItemProps = PaymentSettingsItemDataProps & PaymentSettingsItemActionProps;

export class PaymentSettingsItem extends React.Component<PaymentSettingsItemProps, PaymentSettingsItemState> {
  constructor(props: PaymentSettingsItemProps) {
    super(props);
    this.state = {
      showUpdateAutogiroForm: false,
    };

    this.toggleUpdateAutogiro = this.toggleUpdateAutogiro.bind(this);
  }

  toggleUpdateAutogiro() {
    if (this.props.enableEdit && this.props.setAutogiroToUpdate) {
      if (this.props.id && this.props.clearingNumber && this.props.accountNumber) {
        this.setState({ showUpdateAutogiroForm: !this.state.showUpdateAutogiroForm });
        this.props.setAutogiroToUpdate(this.props.id, this.props.clearingNumber, this.props.accountNumber);
      }
    }
  }

  render() {
    if (this.props.type == null) {
      return null;
    } else if (this.props.type === BetalningsmedelTypes.Autogiro) {
      return (
        <div>
          <div className="PaymentSettings__Item">
            <div className="Item__Number">
              {this.props.translate(SitecoreDictionary.Profile.PaymentSettings.Account) as string}{' '}
              {this.props.clearingNumber} {this.props.accountNumber}
            </div>
            {this.props.enableEdit ? <div className="Item__Name">{this.props.bankName}</div> : ''}
          </div>
          {this.props.enableEdit ? (
            <MainButton
              key="extend"
              buttonStyle="outline"
              color="white"
              title={
                this.state.showUpdateAutogiroForm
                  ? (this.props.translate(SitecoreDictionary.Common.Back) as string)
                  : (this.props.translate(SitecoreDictionary.Profile.PaymentSettings.UpdateBankAccount) as string)
              }
              handleOnClick={() => this.toggleUpdateAutogiro()}
            />
          ) : (
            ''
          )}

          {this.state.showUpdateAutogiroForm && <AutogiroSettingsContainer />}
        </div>
      );
    } else if (this.props.type === BetalningsmedelTypes.Avtalegiro) {
      return (
        <div className="PaymentSettings__Item">
          <div className="Item__Number">
            {this.props.translate(SitecoreDictionary.Profile.PaymentSettings.Account) as string}{' '}
            {this.props.accountNumber}
          </div>
        </div>
      );
    } else if (this.props.type === BetalningsmedelTypes.Card) {
      return (
        <div className="PaymentSettings__Item">
          <div className="Item__Number">{this.props.cardNumber}</div>
          <div className="Item__Name">{this.props.cardIssuerName}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}
