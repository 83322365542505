import * as React from 'react';

import HeaderContent from './HeaderContent';

export interface HeaderDataProps {
  showHeader?: boolean;
  isDesktopMode: boolean;
  isStartPage: boolean;
  isFirstVisit: boolean;
}

export class Header extends React.Component<HeaderDataProps, {}> {
  render() {
    if (this.props.showHeader) {
      return (
        <HeaderContent
          isDesktopMode={this.props.isDesktopMode}
          isStartPage={this.props.isStartPage}
          isFirstVisit={this.props.isFirstVisit}
        />
      );
    } else {
      return null;
    }
  }
}
