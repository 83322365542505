import * as React from 'react';
import { FoereningarReadState } from '../../store/foereningar/read';
import { SelectInput } from 'components/common';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';
import { SelectInputOption } from 'components/common/SelectInput';

export interface FoereningarListProps {
  readonly foereningar: FoereningarReadState;
  readonly labelColor: string;
  readonly shouldValidate?: boolean;
  readonly theme?: string;
  readonly disabled?: boolean;
  readonly onChange: (foereningId: number) => void;
  readonly tabindex?: number;
  readonly translate: TranslateFunction;
  readonly value: number;
}

interface FoereningarListState {
  isValid?: boolean;
}

export class FoereningarList extends React.Component<FoereningarListProps, FoereningarListState> {
  constructor(props: FoereningarListProps) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  render() {
    return (
      <SelectInput
        align="center"
        alignLabel="left"
        label={this.props.translate(SitecoreDictionary.Login.Foerening) as string}
        labelColor={this.props.labelColor}
        name="experience"
        value={this.props.value}
        shouldValidate={this.props.shouldValidate !== undefined ? this.props.shouldValidate : true}
        isValid={this.props.value > -1}
        options={this.mapFoereningar()}
        onValueChange={value => this.onChange(value)}
        theme={this.props.theme ? this.props.theme : ''}
        disabled={this.props.disabled}
        tabindex={this.props.tabindex}
      />
    );
  }

  private mapFoereningar(): SelectInputOption[] {
    const options: SelectInputOption[] = [
      {
        value: -1,
        isDisabled: true,
        label: this.props.translate(SitecoreDictionary.Login.SelectFoerening) as string,
      },
    ];
    this.props.foereningar.Foereningar.forEach(f =>
      options.push({
        value: f.Id,
        label: f.DisplayName,
      })
    );
    return options;
  }

  private onChange(value: string): void {
    const foereningId: number = parseInt(value, 10);
    this.props.onChange(foereningId);
  }
}
