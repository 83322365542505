import * as React from 'react';
import { MainMenuListItem } from './MainMenuListItem';
import { IconMenuSwitch } from 'components/icons';

interface FoereningSwitcherItemDataProps {
  className?: string;
  currentFoerening: fos.api.InloggadeFoerening;
  availableFoereningar: fos.api.InloggadeFoerening[];
}

interface FoereningSwitcherItemActionProps {
  showFoereningSwitcher: () => void;
}

export type FoereningSwitcherItemProps = FoereningSwitcherItemDataProps & FoereningSwitcherItemActionProps;

export const FoereningSwitcherItem: React.SFC<FoereningSwitcherItemProps> = props => {
  return (
    <div>
      <MainMenuListItem
        onClick={() => (props.availableFoereningar.length > 1 ? props.showFoereningSwitcher() : null)}
        className="facility"
        reverse={props.availableFoereningar.length > 1}
        showIcon={props.availableFoereningar.length > 1}
        showSeperator={true}
        text={
          <strong>
            {!!props.currentFoerening.DisplayName ? props.currentFoerening.DisplayName : props.currentFoerening.Namn}
          </strong>
        }
        icon={<IconMenuSwitch />}
      />
    </div>
  );
};
