import * as React from 'react';
import { Mobile } from '../../../models/app/MobileOSType';
import { IconCheckmark } from 'components/icons';
import { EntityStatus } from 'store/global/fetch';

import { SitecoreDictionary } from 'models';
import { ListItem } from '../notification-settings/ListItem';
import { TranslateFunction } from 'react-localize-redux';

export interface CalendarSyncDataProps {
  syncCalendar: boolean;
  userId?: number;
  selectedCalendar?: string;
  fetchCalendarStatus: EntityStatus;
  translate: TranslateFunction;
}

export interface CalendarSyncActionProps {
  setCalendarSync: (syncToggle: boolean, userId: number) => void;
  setSelectedCalendar: (id: string, userId: number) => void;
}

export type CalendarSyncProps = CalendarSyncActionProps & CalendarSyncDataProps;

export const CalendarSync: React.FunctionComponent<CalendarSyncProps> = ({
  syncCalendar,
  userId,
  selectedCalendar,
  fetchCalendarStatus,
  translate,
  setCalendarSync,
  setSelectedCalendar,
}) => {
  const [lsCalendars, setLsCalendars] = React.useState<{ id: string; name: string }[] | undefined>(undefined);

  React.useEffect(() => {
    let LSCalendars = localStorage.getItem('calendars');
    if (LSCalendars) {
      setLsCalendars(JSON.parse(LSCalendars));
    }
  }, []);

  React.useEffect(() => {
    if (syncCalendar) {
      localStorage.removeItem('calendars');
      setLsCalendars([]);
      Mobile.fetchCalendars();
      const interval = window.setInterval(() => {
        let LSCalendars = localStorage.getItem('calendars');
        if (LSCalendars) {
          setLsCalendars(JSON.parse(LSCalendars));
          window.clearInterval(interval);
        }
      }, 100);
      return () => {
        window.clearInterval(interval);
      };
    } else {
      setLsCalendars([]);
    }
    return () => {};
  }, [syncCalendar]);

  const changeValue = () => {
    if (userId !== undefined) {
      setCalendarSync(!syncCalendar, userId);
    }
  };

  const changeSelected = (id: string) => {
    if (userId !== undefined) {
      setSelectedCalendar(id, userId);
    }
    Mobile.setCalendarId(id);
  };

  return (
    <div className="CalendarSync">
      <ListItem
        header={translate(SitecoreDictionary.Profile.CalendarSync.Title) as string}
        subheader={translate(SitecoreDictionary.Profile.CalendarSync.Text) as string}
        activated={syncCalendar}
        showSpinner={fetchCalendarStatus !== EntityStatus.SUCCESS}
        onClick={changeValue}
      />
      {/*Kalendrar */}
      {syncCalendar && fetchCalendarStatus === EntityStatus.SUCCESS && (
        <div className="CalendarSync__AvailableCalendars">
          <h3 className="ChooseCalendarHeader">{translate(SitecoreDictionary.Profile.CalendarSync.PickCalendar)}</h3>
          <div className="ChooseCalendarContainer">
            {lsCalendars &&
              lsCalendars.map(cal => (
                <div
                  className="CalendarEntry"
                  key={cal.id}
                  onClick={() => {
                    changeSelected(cal.id);
                  }}
                >
                  <div className="CalendarName">{cal.name}</div>
                  <div className="CalendarCheckbox">
                    <IconCheckmark selected={selectedCalendar === cal.id} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
