import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { AdminLogin, AdminLoginDataProps, AdminLoginActionProps } from './AdminLogin';
import { loginWithCreds, loginFromSchedule } from 'store/auth';
import { getIsExecutingFailed, FetchCommandTypes, getIsExecuting } from 'store/global/fetch';
import { SiteSwitcherAction, SiteSwitcherStatus } from 'store/global/ui/siteswitcher';
import { LocationActionTypes, switchView } from 'store/location';

import { getTranslate } from 'store/global/dictionary';
import {
  updateEmail,
  updateFoereningId,
  updatePassword,
  clearLoginCmd,
  setFoereningIdFromQueryParameter,
} from 'store/auth/loginCmd';
import { getQueryStringValue, constructSafeName } from 'utils';

const setSiteSwitcherToVisible = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_SHOW',
  });
};

const setSiteSwitcherToHidden = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_HIDE',
  });
};

const mapStateToProps = (state: RootState, props: any): AdminLoginDataProps => {
  const foereningNamnFromQuery = getQueryStringValue('foerening');
  const foereningFromQuery = state.foereningar.read.Foereningar.find(
    f =>
      constructSafeName(f.Namn.toLowerCase()) ===
      constructSafeName(decodeURIComponent(foereningNamnFromQuery.toLowerCase()))
  );
  const foereningIdFromQuery = !!foereningFromQuery ? foereningFromQuery.Id : undefined;
  const foereningId =
    state.auth.loginCmd.foereningId > 0
      ? state.auth.loginCmd.foereningId
      : !!foereningIdFromQuery
      ? foereningIdFromQuery
      : -1;
  return {
    isExecutingFailed: getIsExecutingFailed(state.global.fetch, state.auth.loginCmd.email, FetchCommandTypes.LOGIN),
    isExecuting: getIsExecuting(state.global.fetch, state.auth.loginCmd.email, FetchCommandTypes.LOGIN),
    translate: getTranslate(state.locale),
    username: state.auth.loginCmd.email,
    password: state.auth.loginCmd.password,
    foereningId,
    languageId: state.global.ui.language.id,
    siteSwitcherState: props.SiteSwitcherState,
    isSiteSwitcherShowing: state.global.ui.siteSwitcher.visibility === SiteSwitcherStatus.SHOWING,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AdminLoginActionProps => ({
  onLogin: credentials => dispatch(loginWithCreds(credentials)),
  goToLogin: () => dispatch(switchView(LocationActionTypes.AdminLogin)),
  loginFromSchedule: (credentials, passId, bokaKoePlats) =>
    dispatch(loginFromSchedule(credentials, passId, bokaKoePlats)),
  updateUsername: username => dispatch(updateEmail(username)),
  updatePassword: password => dispatch(updatePassword(password)),
  updateFoereningId: foereningId => dispatch(updateFoereningId(foereningId)),
  clearLoginCmd: () => dispatch(clearLoginCmd()),
  setFoereningIdFromQueryParameter: () => dispatch(setFoereningIdFromQueryParameter()),
  setSiteSwitcherToVisible: () => dispatch(setSiteSwitcherToVisible()),
  setSiteSwitcherToHidden: () => dispatch(setSiteSwitcherToHidden()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
