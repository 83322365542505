import * as React from 'react';
import { PassFilterKategorier } from 'store/passFilter';
import { MainButton } from 'components/common/MainButton';

export interface PassFilterOverlayMessageInternalDataProps {
  visaTraeningsstaelleMeddelande: boolean;
  visaIngaTraeffarMeddelande: boolean;
}

export interface PassFilterOverlayMessageExternalDataProps {
  isFullDesktopView: boolean;
}

export interface PassFilterOverlayMessageActionProps {
  visaFilter(): void;
  vaeljKategori(kategori: keyof typeof PassFilterKategorier): void;
}

export type PassFilterOverlayMessageDataProps = PassFilterOverlayMessageInternalDataProps &
  PassFilterOverlayMessageExternalDataProps;

export type PassFilterOverlayMessageProps = PassFilterOverlayMessageDataProps & PassFilterOverlayMessageActionProps;

export class PassFilterOverlayMessage extends React.Component<PassFilterOverlayMessageProps> {
  render() {
    if (!this.props.visaIngaTraeffarMeddelande && !this.props.visaTraeningsstaelleMeddelande) {
      return null;
    }
    return (
      <div
        className={'PassFilter__Overlay' + (this.props.isFullDesktopView ? ' PassFilter__Overlay--fulldesktopview' : '')}
      >
        <div className="PassFilter__Overlay__Container">
          {this.props.visaIngaTraeffarMeddelande && (
            <React.Fragment>
              <span>Inga träffar</span>
            </React.Fragment>
          )}
          {this.props.visaTraeningsstaelleMeddelande && (
            <React.Fragment>
              <span>Du måste välja minst ett träningsställe</span>
              <MainButton
                className="PassFilter__Overlay__Container__Button"
                handleOnClick={() => {
                  this.props.visaFilter();
                  this.props.vaeljKategori('Traeningsstaelle');
                }}
                title="Välj träningsställe"
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
