import { api } from 'api';
import { executingCommand, FetchCommandTypes, executingCommandFailed, executedCommand } from 'store/global/fetch';
import { MobileOSType } from 'models/app/MobileOSType';
import { RegisterDeviceRequest } from '.';
import { RootState, Dispatch } from 'store/rootReducer';

export const registerDeviceId = (deviceId: string, os: MobileOSType) => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const state = getState();
  if (!state.global.session.userId) {
    return;
  }
  const userId = state.global.session.userId;
  const cmdFailed = executingCommandFailed(userId.toString(), FetchCommandTypes.REGISTER_DEVICE_ID);

  dispatch(executingCommand(userId.toString(), FetchCommandTypes.REGISTER_DEVICE_ID));
  if (!deviceId) {
    dispatch(cmdFailed);
  } else {
    const payload: RegisterDeviceRequest = {
      deviceId,
      mobileOs: os,
    };
    api
      .post<void>(`Anvaendare/RegisterDevice`, payload, {
        muteErrorNotificationOnStatus: [400, 401],
      })
      .then(res => {
        if (!res.error) {
          dispatch(executedCommand(userId.toString(), FetchCommandTypes.REGISTER_DEVICE_ID));
        } else {
          dispatch(cmdFailed);
        }
      })
      .catch(err => dispatch(cmdFailed));
  }
};
