import { FilterCategoryLocalState, FilterCategoryType } from './uiShopFilterReducer';
import { ShopFilterCategoryName } from 'store/shop/shopModels';

export enum UIShopFilterActionType {
  SetFilterToExpanded = 'UI_SHOP_FILTER_EXPAND',
  SetFilterToMinimised = 'UI_SHOP_FILTER_MINIMISE',
  SetCategoryClicked = 'UI_SHOP_FILTER_CATEGORY_CLICKED',
  SetCategoryNotClicked = 'UI_SHOP_FILTER_CATEGORY_NOT_CLICKED',
  SetCategoryAtTop = 'UI_SHOP_FILTER_CATEGORY_AT_TOP',
  SetCategoryAtNotTop = 'UI_SHOP_FILTER_CATEGORY_AT_NOT_TOP',
  SetCategoryTopPosition = 'UI_SHOP_FILTER_CATEGORY_SET_TOP_POSITION',
  ToggleCategoryClicked = 'UI_SHOP_FILTER_CATEGORY_TOGGLE_CLICK',
  SetCategoryLocalState = 'UI_SHOP_FILTER_CATEGORY_SET_LOCAL_STATE',
  ToggleShowProducts = 'UI_SHOP_FILTER_TOGGLE_SHOW_PRODUCTS',

  SetCategory = 'UI_SHOP_FILTER_SET_SELECTED_CATEGORY',
}

export interface SetFilterSelectedCategory {
  type: UIShopFilterActionType.SetCategory;
  selectedCategory: FilterCategoryType;
}

type SetFilterToExpanded = {
  type: UIShopFilterActionType.SetFilterToExpanded;
  expanded: true;
};
type SetFilterToMinimised = {
  type: UIShopFilterActionType.SetFilterToMinimised;
  expanded: false;
};
type SetCategoryClicked = {
  type: UIShopFilterActionType.SetCategoryClicked;
  category: ShopFilterCategoryName;
};

type SetCategoryNotClicked = {
  type: UIShopFilterActionType.SetCategoryNotClicked;
  category: ShopFilterCategoryName;
};
type ToggleCategoryClicked = {
  type: UIShopFilterActionType.ToggleCategoryClicked;
  category: ShopFilterCategoryName;
  clicked: boolean;
};

type SetCategoryAtTop = {
  type: UIShopFilterActionType.SetCategoryAtTop;
  category: ShopFilterCategoryName;
};

type SetCategoryAtNotTop = {
  type: UIShopFilterActionType.SetCategoryAtNotTop;
  category: ShopFilterCategoryName;
};

type SetCategoryTopPosition = {
  type: UIShopFilterActionType.SetCategoryTopPosition;
  category: ShopFilterCategoryName;
  topPosition: number;
};

type SetCategoryLocalState = {
  type: UIShopFilterActionType.SetCategoryLocalState;
  category: ShopFilterCategoryName;
  localState: FilterCategoryLocalState;
};

export interface ToggleShowProducts {
  type: UIShopFilterActionType.ToggleShowProducts;
  showing: boolean;
}

export type UIShopFilterAction =
  | SetFilterToExpanded
  | SetFilterToMinimised
  | SetCategoryClicked
  | SetCategoryNotClicked
  | ToggleCategoryClicked
  | SetCategoryAtTop
  | SetCategoryAtNotTop
  | SetCategoryTopPosition
  | SetCategoryLocalState
  | SetFilterSelectedCategory
  | ToggleShowProducts;
