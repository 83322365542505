import * as React from 'react';

export interface IconMenuCalendarProps {
  color?: string;
  title?: string;
}

export class IconMenuCalendar extends React.Component<IconMenuCalendarProps, {}> {
  random = 1 + Math.random() * (1000 - 1);
  rand = 'rand-' + Math.round(this.random);
  randD = '.rand-' + Math.round(this.random);

  getDate() {
    let renderThis = new Date().getDate();
    return <div className="calenderText">{renderThis}</div>;
  }

  // TODO: Fix translation support for text
  render() {
    return (
      <div className="IconMenuCalenderContainer">
        <svg viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" className={'IconMenuCalender ' + this.rand}>
          <title>{this.props.title}</title>
          <style type="text/css">
            {`
              .IconMenuCalender${this.randD} {
                width: 55px;
                height: 55px;
                text-align: center;
              }
              .IconMenuCalender${this.randD} .st0{
                fill:none;
                text-align: center;
              }
              .IconMenuCalender${this.randD} .st1{
                fill: none;
                stroke: ${this.props.color ? this.props.color : '#343434'};
                stroke-miterlimit: 10;
              }
              .IconMenuCalender${this.randD} .st2{
                fill: ${this.props.color ? this.props.color : '#343434'};
              }
              .IconMenuCalender${this.randD} .st3{
                font-family:'Apercu-Bold';
                text-align: center;
              }
              .IconMenuCalender${this.randD} .st4{font-size:12px;}
            `}
          </style>

          <g id="Layer_2_1_">
            <g id="Layer_1-2">
              <rect y="0" className="st0" width="40" height="40" />
              <path
                className="st1"
                d="M14.3,7.7h11.6c3.4,0,6.2,2.8,6.2,6.2v11.6c0,3.4-2.8,6.2-6.2,6.2H14.3c-3.4,0-6.2-2.8-6.2-6.2V13.9C8.1,10.5,10.9,7.7,14.3,7.7z"
              />
              <line className="st1" x1="32.2" y1="14.8" x2="8.4" y2="14.8" />
            </g>
          </g>
          <g>
            <rect x="8.7" y="17.9" className="st0" width="22.8" height="10.1" />
          </g>
        </svg>
        <div className="calenderText">{this.getDate()}</div>
      </div>
    );
  }
}
