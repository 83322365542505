import * as React from 'react';

export const IconFriskisGo: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.15 112.15" width="38px" height="38px">
      <defs>
        <style>
          .cls-1
          {'{font-size:64.08px;fill:#1d1d1b;font-family:Apercu-Bold, Apercu;font-weight:700;letter-spacing:-0.04em;}'}
          .cls-2 {'{letter-spacing:-0.02em;}'}
          .cls-3 {'{fill:none;stroke:#1d1d1b;stroke-miterlimit:10;stroke-width:2px;}'}
        </style>
      </defs>
      <g id="Lager_2" data-name="Lager 2">
        <g id="Lager_1-2" data-name="Lager 1">
          <text className="cls-1" transform="translate(14.25 78.31)">
            G
            <tspan className="cls-2" x="44.65" y="0">
              o
            </tspan>
          </text>
          <rect className="cls-3" x="1" y="1" width="110.15" height="110.15" rx="12" />
        </g>
      </g>
    </svg>
  );
};
