import * as React from 'react';
import { SideBarState, SidebarStatus } from 'store/global/ui/side-bar/sideBarReducer';

// import calendar from '../../static/assets/icon-calender.svg';
// import close from '../../static/assets/converted-to-components/icon-big-close.svg';
import { IconCross, IconCalendar } from 'components/icons';

export interface ScheduleLauncherInternalDataProps {
  readonly expanded: boolean;
  readonly sideBarState: SideBarState;
  readonly isSideBarMaximised: boolean;
}

export interface ScheduleLauncherExternalDataProps {
  readonly isDesktopMode: boolean;
}

export interface ScheduleLauncherActionProps {
  setSidebarToContainSchedule: () => void;
  setSidebarToVisible: () => void;
  setSidebarToHidden: () => void;
  setSidebarToMaximised: (isSideBarMaximised: boolean) => void;
  setSidebarToMinimised: (isSideBarMaximised: boolean) => void;
  goToSchedule: () => void;
}

export type ScheduleLauncherDataProps = ScheduleLauncherInternalDataProps & ScheduleLauncherExternalDataProps;
export type ScheduleLauncherProps = ScheduleLauncherDataProps & ScheduleLauncherActionProps;

export const ScheduleLauncher: React.FunctionComponent<ScheduleLauncherProps> = ({
  expanded,
  sideBarState,
  isDesktopMode,
  isSideBarMaximised,
  setSidebarToContainSchedule,
  setSidebarToVisible,
  setSidebarToHidden,
  setSidebarToMaximised,
  setSidebarToMinimised,
  goToSchedule,
}) => {
  const getClasses = (): string => {
    const baseClass = 'ScheduleLauncher';
    let scheduleClasses = baseClass;
    if (sideBarState.visibility === SidebarStatus.SHOWING) {
      scheduleClasses = scheduleClasses + ` ${baseClass}--when-Sidebar-Is-Showing`;
      if (expanded) {
        scheduleClasses = scheduleClasses + ' schedulefilter-is-expanded';
      }
    }
    if (isSideBarMaximised) {
      scheduleClasses = scheduleClasses + ` ${baseClass}--when-Sidebar-Is-Maximised`; // expanded
    }
    return scheduleClasses;
  };
  const getIcon = () => {
    if (sideBarState.visibility === SidebarStatus.HIDDEN) {
      return <IconCalendar />;
    } else {
      return <IconCross />;
    }
  };
  const setIconClass = (): string => {
    if (sideBarState.visibility === SidebarStatus.HIDDEN) {
      return 'ScheduleLauncher__LogoImage--calendar';
    } else {
      return 'ScheduleLauncher__LogoImage--close';
    }
  };
  const onScheduleIconClick = () => {
    const sideBarIsShowing = sideBarState.visibility === SidebarStatus.SHOWING;
    if (!sideBarIsShowing) {
      if (isDesktopMode) {
        setSidebarToContainSchedule();
        setSidebarToVisible();
      } else {
        goToSchedule();
      }
    } else {
      if (isDesktopMode) {
        if (!isSideBarMaximised) {
          setSidebarToHidden();
        } else {
          setSidebarToMinimised(isSideBarMaximised);
        }
      }
    }
  };

  React.useEffect(() => {
    if (!isDesktopMode && sideBarState.visibility === SidebarStatus.SHOWING) {
      setSidebarToHidden();
      goToSchedule();
      setSidebarToMinimised(isSideBarMaximised);
    }
  }, [isDesktopMode, sideBarState, setSidebarToHidden, goToSchedule, setSidebarToMinimised, isSideBarMaximised]);

  return (
    <div className={getClasses()}>
      <div className={setIconClass()} onClick={() => onScheduleIconClick()}>
        {getIcon()}
      </div>
    </div>
  );
};
