export enum PasswordResetActionTypes {
  SET_PASSWORD_RESET = 'SET_PASSWORD_RESET',
  CLEAR_PASSWORD_RESET = 'CLEAR_PASSWORD_RESET',
  RESET_PASSWORD_RESULT = 'RESET_PASSWORD_RESULT',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  SET_IS_NEW_PASSWORD_VALID = 'SET_IS_NEW_PASSWORD_VALID',
  SET_REPEAT_NEW_PASSWORD = 'SET_REPEAT_NEW_PASSWORD',
  SET_IS_REPEAT_NEW_PASSWORD_VALID = 'SET_IS_REPEAT_NEW_PASSWORD_VALID',
}

export enum PasswordResetErrorCodes {
  UNKNOWN = 'UNKNOWN',
  INVALID_TOKEN = 'INVALID_TOKEN',
}

export interface SetPasswordResetAction {
  type: PasswordResetActionTypes.SET_PASSWORD_RESET;
  passwordResetRequestCompleted?: boolean;
  isSuccess?: boolean;
}

export interface ClearPasswordResetAction {
  type: PasswordResetActionTypes.CLEAR_PASSWORD_RESET;
}

export interface SetPasswordResetResultAction {
  type: PasswordResetActionTypes.RESET_PASSWORD_RESULT;
  passwordResetSucceeded?: boolean;
  errorCode?: PasswordResetErrorCodes;
}

export interface SetNewPasswordAction {
  type: PasswordResetActionTypes.SET_NEW_PASSWORD;
  value: string;
}

export interface SetRepeatNewPasswordAction {
  type: PasswordResetActionTypes.SET_REPEAT_NEW_PASSWORD;
  value: string;
}

export interface SetIsNewPasswordValidAction {
  type: PasswordResetActionTypes.SET_IS_NEW_PASSWORD_VALID;
  isValid: boolean;
}

export interface SetIsRepeatNewPasswordValidAction {
  type: PasswordResetActionTypes.SET_IS_REPEAT_NEW_PASSWORD_VALID;
  isValid: boolean;
}

export type PasswordResetActions =
  | SetPasswordResetAction
  | ClearPasswordResetAction
  | SetPasswordResetResultAction
  | SetNewPasswordAction
  | SetRepeatNewPasswordAction
  | SetIsNewPasswordValidAction
  | SetIsRepeatNewPasswordValidAction;

export interface PasswordResetState {
  passwordResetRequestCompleted?: boolean;
  requestPasswordResetLinkResult?: boolean;
  passwordResetSucceeded?: boolean;
  passwordResetErrorCode?: PasswordResetErrorCodes;
  newPassword: string;
  repeatNewPassword: string;
  isNewPasswordValid: boolean;
  isRepeatNewPassword: boolean;
}
