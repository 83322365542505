export enum FoereningSwitcherActionTypes {
  TOOGLE_SWITCHER = 'UI_FOERENING_SWITCHER/TOOGLE',
  IS_SWITCHING = 'UI_FOERENING_SWITCHER/IS_SWITHCING',
}

export interface FoereningSwitcherToogleAction {
  type: FoereningSwitcherActionTypes.TOOGLE_SWITCHER;
  value: boolean;
}

export interface FoereningSwitcherIsSwitchingAction {
  type: FoereningSwitcherActionTypes.IS_SWITCHING;
  value: boolean;
}

export const toogleFoereningSwitcher = (isVisible: boolean): FoereningSwitcherToogleAction => ({
  type: FoereningSwitcherActionTypes.TOOGLE_SWITCHER,
  value: isVisible,
});

export const isSwitchingFoereningSwitcher = (isSwitching: boolean): FoereningSwitcherIsSwitchingAction => ({
  type: FoereningSwitcherActionTypes.IS_SWITCHING,
  value: isSwitching,
});

export type FoereningSwitcherActions = FoereningSwitcherToogleAction | FoereningSwitcherIsSwitchingAction;
