import * as React from 'react';
import { IconArrow } from '../icons';

export interface SummaryProps {
  header: string;
  fullText: string;
}

export class Summary extends React.Component<SummaryProps> {
  bodyn: HTMLSpanElement | null = null;
  observer: ResizeObserver | null = new ResizeObserver(entries => {
    for (let entry of entries) {
      entry.target.classList[entry.target.scrollWidth > entry.contentRect.width ? 'add' : 'remove']('truncated');
    }
  });

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    return (
      <div className="Summary">
        <p className="Header">{this.props.header}</p>
        <div className="Body">
          <input type="checkbox" id="expander" />
          <span
            ref={r => {
              if (this.observer && this.bodyn) {
                this.observer.unobserve(this.bodyn);
              }
              this.bodyn = r;
              if (this.observer && this.bodyn) {
                this.observer.observe(this.bodyn);
              }
            }}
            dangerouslySetInnerHTML={{ __html: this.props.fullText }}
          />
          <label className="Summary__Expander" role="button" htmlFor="expander">
            <IconArrow color="#e31836" direction="left" />
          </label>
        </div>
      </div>
    );
  }
}
