import * as React from 'react';

import closeIcon from 'static/assets/converted-to-components/icon-big-close.svg';
import brand from '../../static/assets/friskis-logo--red.svg';

// Content types
import { SidebarContentType } from 'store/global/ui/side-bar/sideBarReducer';
import ScheduleContainer from 'components/schedule/schedule/ScheduleContainer';

export interface SideBarDataProps {
  showCloser?: boolean;
  isShowing: boolean;
  isConfirmationSideBar?: boolean;
  contentType: SidebarContentType;
  isDesktopFullViewMode: boolean; // Ordinarily it is only in the sidebar in the desktop mode.
  // Only when the schedule is to cover the full-width do wee want this value to be true
  isDesktopMode: boolean;
  isAuthenticated: boolean;
  aktivFoerening?: number;
  userId: number;
}

export interface SideBarActionProps {
  setFilterVisible: (visible: boolean) => void;
  closerHandler?: () => void;
  hideSideBar?: () => void;
}

export type SideBarProps = SideBarDataProps & SideBarActionProps;

export class SideBar extends React.Component<SideBarProps> {
  baseClass = 'SideBar';

  SideBarClasses = () => {
    let classes = this.baseClass;
    if (this.props.isShowing) {
      classes = classes + ' isShowing';
    }
    if (this.props.isDesktopMode) {
      if (this.props.isDesktopFullViewMode) {
        classes = classes + ` ${this.baseClass}--isDesktopMaximisedMode`;
      } else {
        classes = classes + ` ${this.baseClass}--isDesktopMinimisedMode`;
        if (this.isSafari()) {
          classes = classes + ' safari-treatment';
        }
      }
    }

    if (this.props.isConfirmationSideBar) {
      classes = classes + ' isConfirmation';
    }

    return classes;
  };

  isSafari() {
    // Small fulknep to check if its safari
    return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  }

  closeHandler = () => {
    if (this.props.hideSideBar) {
      this.props.hideSideBar();
    }
  };

  renderContentType() {
    switch (this.props.contentType) {
      case SidebarContentType.SCHEDULE: {
        if (!this.props.isAuthenticated || !this.props.aktivFoerening) {
          return null;
        } else {
          return (
            <ScheduleContainer
              isDesktopMode={this.props.isDesktopMode}
              isFullDesktopView={this.props.isDesktopFullViewMode}
              isPublicWebMode={false}
            />
          );
        }
      }
      default: {
        return null;
      }
    }
  }

  renderCloser() {
    if (this.props.showCloser && !this.props.isDesktopMode) {
      return (
        <div className="SideBar__Closer" onClick={this.closeHandler}>
          <img src={closeIcon} alt="Stäng" />
        </div>
      );
    } else {
      return null;
    }
  }

  // TODO: Pop-up:en borde sträcka sig över hela sidan!
  render() {
    return (
      <section className={this.SideBarClasses()}>
        <div className="SideBar__Background" />
        {!this.props.isDesktopMode && (
          <nav>
            <div className="HeaderContent">
              <div className="HeaderContent__Primary">
                <div className="brandArea">
                  <div className="brandArea__logoImage" onClick={this.closeHandler}>
                    <img src={brand} alt="Friskis" />
                  </div>
                </div>
              </div>
            </div>
            {this.renderCloser()}
          </nav>
        )}
        <div className="SideBar__Content">{this.renderContentType()}</div>
      </section>
    );
  }
}

export default SideBar;
