import * as React from 'react';

interface IconBookingStatusProps {
  extraClassName: string;
}

export class IconBookingStatus extends React.Component<IconBookingStatusProps, {}> {
  render() {
    return <div className={'IconButton__Button IconButton__Button' + this.props.extraClassName} />;
  }
}
