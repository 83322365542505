export enum NewNotificationActionTypes {
  SET_NEW_NOTIFICATIONS_COUNT = 'NEW_NOTIFICATION/SET_NEW_NOTIFICATIONS_COUNT',
}

interface SetNewNotificationCountAction {
  type: NewNotificationActionTypes.SET_NEW_NOTIFICATIONS_COUNT;
  value: number;
}

export const setNewNotificationCount = (count: number): SetNewNotificationCountAction => ({
  type: NewNotificationActionTypes.SET_NEW_NOTIFICATIONS_COUNT,
  value: count,
});

export type ShowNewNotificationIndicatorActions = SetNewNotificationCountAction;
