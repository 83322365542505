import { SidebarContentType, SidebarStatus, SidebarSize } from './sideBarReducer';

export interface SidebarLaunchAction {
  type: 'UI_SIDEBAR_LAUNCH';
  visibility: SidebarStatus;
}
export interface SidebarHideAction {
  type: 'UI_SIDEBAR_HIDE';
  visibility: SidebarStatus;
}

export interface SidebarContentTypeAction {
  type: 'UI_SIDEBAR_SET_CONTENT_TYPE';
  contentType: SidebarContentType;
}
export interface SidebarSizeMinimisdeAction {
  type: 'UI_SIDEBAR_SET_SIZE_MINIMISED';
  size: SidebarSize.MINIMISED;
}
export interface SidebarSizeMaximisedAction {
  type: 'UI_SIDEBAR_SET_SIZE_MAXIMISED';
  size: SidebarSize.MAXIMISED;
}

export const hideSideBar = (): SidebarHideAction => ({
  type: 'UI_SIDEBAR_HIDE',
  visibility: SidebarStatus.HIDDEN,
});

export type SideBarAction =
  | SidebarLaunchAction
  | SidebarHideAction
  | SidebarContentTypeAction
  | SidebarSizeMinimisdeAction
  | SidebarSizeMaximisedAction;
