import { connect } from 'react-redux';
import { RootState } from 'store';
import { setApplicationMode } from './Environment';
import { checkAuthStatus, logout, updateActiveFoerening } from 'store/auth';
import { getTranslate, getTranslationData, DictionaryStatus } from 'store/global/dictionary';
import { getApplicationViewModeIsDesktop } from 'store/global/ui/application-mode';
import { getAllErrors, getHasAppCrashed, removeError, sendErrorReport } from 'store/global/errors';
import {
  getIsPublicWebMode,
  getShowHeaderForLocation,
  getShowFooterForLocation,
  getIsIframeMode,
  getPageTitle,
  LocationActionTypes,
  switchView,
  LocationState,
  getIsAdminWebMode,
  goToSchedule,
} from 'store/location';
import { SidebarStatus, showSideBarInFullDesktopWidth } from 'store/global/ui/side-bar';
import { SiteSwitcherStatus } from '../../store/global/ui/siteswitcher';
import { AppDataProps, AppActionProps, App } from './App';
import { Dispatch } from '../../store';
import { getImages } from 'store/global/ui/image';
import { getFoereningar } from 'store/foereningar';
import { toogleFoereningSwitcher } from 'store/global/ui/foereningSwitcher';
import {
  getIsExecutingFailed,
  FetchCommandTypes,
  getIsExecuting,
  getIsFetched,
  FetchStatusAggregateTypes,
} from '../../store/global/fetch';
import { loginFromShop, loginWithCreds, loginFromSchedule } from '../../store/auth';
import {
  updateEmail,
  updateFoereningId,
  updatePassword,
  clearLoginCmd,
  setFoereningIdFromQueryParameter,
} from '../../store/auth/loginCmd';
import { SiteSwitcherAction } from '../../store/global/ui/siteswitcher';
import { setShopFoerening } from 'store/global/session';
import { initialized } from '../../store/initialize';
import { getProfileInfo } from 'store/user';
import { HeaderIconEnum, setHeaderIcons, resetHeaderIcons } from 'store/global/ui/header';
import { TranslateFunction } from 'react-localize-redux';

const setSiteSwitcherToHidden = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_HIDE',
  });
};

const mapStateToProps = (state: RootState): AppDataProps => {
  const isDesktopMode = getApplicationViewModeIsDesktop(state);
  const translate: TranslateFunction = getTranslate(state.locale);
  const foereningId = state.global.session.activeFoerening;
  let foerening: fos.api.InloggadeFoerening | undefined;
  if (foereningId) {
    foerening = state.global.session.inloggadeFoereningar.find(x => x.Id.toString() === foereningId.toString());
  }
  return {
    isDesktopMode,
    errors: getAllErrors(state.global.errors),
    hasAppCrashed: getHasAppCrashed(state.global.errors),
    authenticated: state.auth.read.isAuthenticated,
    isPublicWebMode: getIsPublicWebMode(state.location),
    isSidebarShowing: state.global.ui.sideBar.visibility === SidebarStatus.SHOWING,
    isSiteSwitcherShowing: state.global.ui.siteSwitcher.visibility === SiteSwitcherStatus.SHOWING,
    sidebarContentType: state.global.ui.sideBar.contentType,
    showHeader: getShowHeaderForLocation(state.location, isDesktopMode),
    showFooter: getShowFooterForLocation(state.location, isDesktopMode),
    isIframe: getIsIframeMode(state.location, getApplicationViewModeIsDesktop(state)),
    isSideBarMaximised: showSideBarInFullDesktopWidth(state.global.ui.sideBar, state.global.ui.schedule),
    pageHasTitle: !!getPageTitle(state.location, isDesktopMode, translate),
    location: state.location,
    aktivFoerening: foereningId,
    userId: state.global.session.userId,
    foereningId: state.auth.loginCmd.foereningId,
    shopFoerening: state.global.session.shopFoerening,
    translate,
    isExecuting: getIsExecuting(state.global.fetch, state.auth.loginCmd.email, FetchCommandTypes.LOGIN),
    showFoereningSwitcher: state.global.ui.foereningSwitcher.isVisible,
    isSwitchingFoerening: state.global.ui.foereningSwitcher.isSwitchingFoerening,
    currentFoerening: state.global.session.inloggadeFoereningar.find(
      f => f.Id.toString() === (foereningId as number).toString()
    ) as fos.api.InloggadeFoerening,
    availableFoereningar: state.global.session.inloggadeFoereningar,
    languageCode: state.user.read.languageCode,
    shouldAcceptBookingRules: state.global.session.shouldAcceptBookingRules,
    shouldShowCalendarSync: false,
    isExecutingFailed: state.auth.loginCmd.email
      ? getIsExecutingFailed(state.global.fetch, state.auth.loginCmd.email, FetchCommandTypes.LOGIN)
      : false,
    languageId: state.global.ui.language.id,
    username: state.auth.loginCmd.email,
    password: state.auth.loginCmd.password,
    numberOfProductsInBasket: state.shoppingBasket.products.length,
    allFoerenignar: state.foereningar.read.Foereningar,
    isInitialized:
      state.global.dictionary.status === DictionaryStatus.READY &&
      getIsFetched(state.global.fetch, -1, FetchStatusAggregateTypes.GET_FOERENINGAR) &&
      getIsFetched(state.global.fetch, -1, FetchStatusAggregateTypes.GET_BILDER),
    passDetailsVisible: state.location.type === LocationActionTypes.PassDetails,
    scheduleVisible: state.location.type === LocationActionTypes.Schedule,

    datumFoerModalVisas: foerening && foerening.ExtraFoereningInfo && foerening.ExtraFoereningInfo.DatumFoerModalVisas,
    datumFoerModalKanInteStaengas:
      foerening && foerening.ExtraFoereningInfo && foerening.ExtraFoereningInfo.DatumFoerModalKanInteStaengas,
    schemaUrl: foerening && foerening.ExtraFoereningInfo && foerening.ExtraFoereningInfo.SchemaUrl,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AppActionProps => ({
  initialize: (location: LocationState) => {
    dispatch(getTranslationData());
    dispatch(setApplicationMode());
    dispatch(getFoereningar(getIsAdminWebMode(location)));
    dispatch(checkAuthStatus());
    dispatch(getImages());
  },
  dismissError: error => dispatch(removeError(error)),
  appCrashed: error => dispatch(sendErrorReport(error)),
  setApplicationMode: () => dispatch(setApplicationMode()),
  toogleFoereningSwitcher: isVisible => dispatch(toogleFoereningSwitcher(isVisible)),
  updateActiveFoerening: foerening => dispatch(updateActiveFoerening(foerening)),
  login: credentials => dispatch(loginFromShop(credentials)),
  logout: (goToLogin, goToPublicWeb) => dispatch(logout(goToLogin, goToPublicWeb)),
  onLogin: credentials => dispatch(loginWithCreds(credentials)),
  goToLogin: () => dispatch(switchView(LocationActionTypes.Home)),
  loginFromSchedule: (credentials, passId, bokaKoePlats) =>
    dispatch(loginFromSchedule(credentials, passId, bokaKoePlats)),
  updateUsername: username => dispatch(updateEmail(username)),
  updatePassword: password => dispatch(updatePassword(password)),
  updateFoereningId: foereningId => dispatch(updateFoereningId(foereningId)),
  clearLoginCmd: () => dispatch(clearLoginCmd()),
  setFoereningIdFromQueryParameter: () => dispatch(setFoereningIdFromQueryParameter()),
  setSiteSwitcherToHidden: () => dispatch(setSiteSwitcherToHidden()),
  setShopFoerening: (foereningId?: number) => dispatch(setShopFoerening(foereningId)),
  setInitialized: (isInitialized: boolean) => dispatch(initialized(isInitialized)),
  getProfileInfo: (activeFoerening: number) => dispatch(getProfileInfo(activeFoerening)),
  setHeaderIcons: (
    leftDesktopIcon?: HeaderIconEnum,
    rightDesktopIcon?: HeaderIconEnum,
    leftMobileIcon?: HeaderIconEnum,
    rightMobileIcon?: HeaderIconEnum
  ) => dispatch(setHeaderIcons(leftDesktopIcon, rightDesktopIcon, leftMobileIcon, rightMobileIcon)),
  resetHeaderIcons: () => dispatch(resetHeaderIcons()),
  goToSchedule: () => dispatch(goToSchedule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
