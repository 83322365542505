import * as React from 'react';
import { FilterCategoryLocalState as ShopFilterCategoryLocalState } from 'store/global/ui/schedule-filter/uiScheduleFilterReducer';
import { ShopFilterType } from '../ShopFilter';
import { ShopFilterCategoryItem } from './category-item/ShopFilterCategoryItem';

import { UIScheduleFilterState } from 'store/global/ui/schedule-filter/uiScheduleFilterReducer';
import { UIShopFilterState } from 'store/global/ui/shop-filter/uiShopFilterReducer';
import { ScheduleFilterCategoryName } from 'store/schedule/scheduleModels';
import { IconChevron } from 'components/icons';

import { getWeekdaysObjectMap, getDayPeriodsObjectMap, getShopFilterCategoryValuesObjectMap } from 'models';
import { ShopFilterCategoryName, FilterChoice } from 'store/shop/shopModels';
import { TranslateFunction } from 'react-localize-redux';

export interface ShopFilterActionFunctions {
  onFilter: (value: string, category: string) => void;
  onSelect: (value: string, category: string) => void;
  onDeselect?: (value: string, category: string) => void;
  onClearCategoryFilters?: (category: string) => void;
}

export interface ShopFilterCategoryDataProps {
  readonly categoryName: string;
  readonly internalCategoryName: ScheduleFilterCategoryName | ShopFilterCategoryName;
  readonly data: FilterChoice[];
  localState: ShopFilterCategoryLocalState;
  readonly filterUIState: UIScheduleFilterState | UIShopFilterState;
  readonly filterCategoryActionFunctions: ShopFilterActionFunctions;
  readonly selectedValues: string[];
  readonly translate: TranslateFunction;
  readonly listItemGroupingMapping?: { [index: string]: string | string[] };
  readonly isFullDesktopView: boolean;
  readonly isDesktopMode: boolean;
  readonly filterType: ShopFilterType;
  readonly showSearchbar: boolean;
  readonly children: FilterChoice[];
  readonly id: string;
  readonly selectedCategory: string;
  readonly categoryDescription: string;
  onFavoriteFilterDelete(filterId: string): void;
  giveToggleButtonSomeFocus(): void;
  setClickedCategory(id: string): void;
}

export interface ShopFilterCategoryActionProps {
  selectAllCategoryListItems: (value: string, category: string) => void;
  unSelectCategoryHeader: (value: string, category: string) => void;
  expandCategory: (
    categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName,
    filterUIState: UIScheduleFilterState | UIShopFilterState,
    props: ShopFilterCategoryProps
  ) => void;
  setTopPosition: (
    categoryName: ScheduleFilterCategoryName | ShopFilterCategoryName,
    filterUIState: UIScheduleFilterState | UIShopFilterState,
    topPosition: number,
    props: ShopFilterCategoryDataProps
  ) => void;
}

export type ShopFilterCategoryProps = ShopFilterCategoryDataProps & ShopFilterCategoryActionProps;

function getSelectedValuesForDisplay(props: ShopFilterCategoryProps) {
  if (!props.selectedValues) {
    return '';
  }

  // TODO: Investigate generalising this function so that we do not hard-code our exceptions. It has been written this way to save time.
  const internalCategoryName = props.internalCategoryName;
  switch (internalCategoryName) {
    case ScheduleFilterCategoryName.Veckodag: {
      const weekdaysMapObject = getWeekdaysObjectMap();
      const selectedValuesWithLocalisedNames = props.selectedValues.map(dayName => {
        return weekdaysMapObject[dayName];
      });
      return selectedValuesWithLocalisedNames && selectedValuesWithLocalisedNames.length
        ? selectedValuesWithLocalisedNames.join(', ')
        : '';
    }
    case ScheduleFilterCategoryName.Tidpunkt: {
      const dayPeriodsMapObject = getDayPeriodsObjectMap();
      const selectedValuesWithLocalisedNames = props.selectedValues.map(dayName => dayPeriodsMapObject[dayName]);
      return selectedValuesWithLocalisedNames && selectedValuesWithLocalisedNames.length
        ? selectedValuesWithLocalisedNames.join(', ')
        : '';
    }
    case ShopFilterCategoryName.ProductWho: {
      return getTranslatedCategoryValuesForShopFilter(props.selectedValues);
    }
    case ShopFilterCategoryName.ProductWhen: {
      return getTranslatedCategoryValuesForShopFilter(props.selectedValues);
    }
    case ShopFilterCategoryName.ProductHow: {
      return getTranslatedCategoryValuesForShopFilter(props.selectedValues);
    }
    default: {
      return props.selectedValues.length ? props.selectedValues.join(', ') : '';
    }
  }
}

function getTranslatedCategoryValuesForShopFilter(selectedValues: string[]): string {
  const translationMapping = getShopFilterCategoryValuesObjectMap();
  const selectedValuesWithLocalisedNames = selectedValues.map(categoryValue => {
    return translationMapping[categoryValue];
  });
  return selectedValuesWithLocalisedNames && selectedValuesWithLocalisedNames.length
    ? selectedValuesWithLocalisedNames.join(', ')
    : '';
}

function crop(text: string, max: number) {
  if (max >= text.length) {
    return text;
  }

  return text.substr(0, max) + '...';
}

export class ShopFilterCategory extends React.Component<ShopFilterCategoryProps, ShopFilterCategoryLocalState> {
  private instance: HTMLElement | null = null;

  constructor(props: ShopFilterCategoryProps) {
    super(props);
    const currentlySelectedCategoryItem = props.children.find(c => c.isSelected);

    this.state = {
      showEdit: false,
      atTop: false,
      clicked: false,
      topPosition: 0,
      selectedCategoryItem: !!currentlySelectedCategoryItem
        ? { id: currentlySelectedCategoryItem.SitecoreId, name: currentlySelectedCategoryItem.Name }
        : { id: '', name: '' },
    };
  }

  componentDidMount() {
    if (this.instance) {
      let topPosition = (this.instance as HTMLElement).offsetTop;
      this.props.setTopPosition(this.props.internalCategoryName, this.props.filterUIState, topPosition, this.props);
    }
  }

  setSelectedCategoryItem = (event: React.MouseEvent<HTMLElement>, item: FilterChoice) => {
    event.stopPropagation();
    event.preventDefault();

    for (var i = 0; i < this.props.children.length; i++) {
      this.props.children[i].isSelected = false;
    }

    if (item.SitecoreId === this.state.selectedCategoryItem.id) {
      this.setState({
        selectedCategoryItem: { id: '', name: '' },
      });
    } else {
      item.isSelected = true;

      this.setState({
        selectedCategoryItem: { id: item.SitecoreId, name: item.Name },
      });
    }
    this.handleClick(event, this.props.id);
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    this.props.setClickedCategory(id);
    event.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const selectedValues = getSelectedValuesForDisplay(this.props);
    const hasSelectedValues = this.state.selectedCategoryItem.name;

    const expanded = !!this.props.selectedCategory && this.props.selectedCategory === this.props.id;

    let fullText = selectedValues;

    let fullTextExceedsMaxCharacters = fullText.length > 10;
    let max = fullTextExceedsMaxCharacters ? 30 : fullText.length;
    let croppedText = crop(fullText, max);

    let mappedCategories;

    if (this.props.children) {
      mappedCategories = this.props.children.map(category => (
        <ShopFilterCategoryItem
          setSelectedCategoryItem={this.setSelectedCategoryItem}
          key={category.SitecoreId}
          item={category}
          giveToggleButtonSomeFocus={this.props.giveToggleButtonSomeFocus}
        />
      ));
    }

    return (
      <div
        ref={node => (this.instance = node)}
        className={
          'FilterCategoryContainer' +
          (expanded ? ' FilterCategoryContainer--expanded' : '') +
          (hasSelectedValues ? ' FilterCategoryContainer--CategorySelected' : '')
        }
      >
        <div className="FilterCategory">
          <div
            onClick={(event: React.MouseEvent<HTMLElement>) => this.handleClick(event, this.props.id)}
            className={'Category__Title ' + (hasSelectedValues ? 'Category__Title--hasValues' : '')}
          >
            <span className="Title__Name">{this.props.categoryName}</span>
            {!expanded && hasSelectedValues && (
              <span className="Title__SelectedValues">{this.state.selectedCategoryItem.name}</span>
            )}
            <IconChevron />
          </div>
          <div className="Category__Settings">
            {this.props.categoryDescription}

            <div className="Settings__Values">{croppedText}</div>
          </div>
          <div className="Category__Description">{mappedCategories}</div>
        </div>
      </div>
    );
  }
}
