import { Reducer } from 'redux';
import { LanguageActionTypes } from '.';
import { Action } from 'store';

export interface LanguageUiState {
  readonly id: number;
  readonly name: string;
  readonly code: string;
}

const initialState: LanguageUiState = {
  id: 1,
  name: 'Svenska',
  code: 'sv-SE',
};

export const languageUiReducer: Reducer<LanguageUiState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case LanguageActionTypes.SET_LANGUAGE:
      return {
        ...state,
        id: action.language.LanguageId,
        name: action.language.LanguageName,
        code: action.language.LanguageCode,
      };
    default:
      return state;
  }
};
