import * as React from 'react';
import PassBookerContainer from 'components/common/booking/PassBookerContainer';

import { SitecoreDictionary } from 'models';
import {
  OK_STATUS_CLASS,
  ERROR_STATUS_CLASS,
  // NOT_BOOKABLE_STATUSES
} from './model';
import { BokningsStatusEnum } from 'models';
import { StatusBar } from 'components/common';
import { TranslateFunction } from 'react-localize-redux';

interface SlotsProps {
  bokningar: Array<fos.api.Bokning>;
  translate: TranslateFunction;
  pass: fos.api.PassDetails;
}

export const Slots: React.FunctionComponent<SlotsProps> = ({ bokningar, translate, pass }) => {
  const [statusMessageVisible, setStatusMessageVisible] = React.useState(false);
  const [userInformed, setUserInformed] = React.useState(true);
  const [statusMessageText, setStatusMessageText] = React.useState('');
  const [timeout, setTimeout] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (!userInformed && !!pass.statusMessage) {
      if (!!timeout) {
        window.clearTimeout(timeout);
      }
      setUserInformed(true);
      setStatusMessageText(pass.statusMessage);
      setStatusMessageVisible(true);

      const t = window.setTimeout(() => {
        setStatusMessageVisible(false);
        setStatusMessageText('');
      }, 4500);
      setTimeout(t);
    }
  }, [userInformed, pass, timeout]);

  const getStatusMessageClass = () => {
    if (
      statusMessageText === SitecoreDictionary.Schedule.Messages.OK_BOOK ||
      statusMessageText === SitecoreDictionary.Schedule.Messages.QUEUED ||
      statusMessageText === SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return OK_STATUS_CLASS;
    } else {
      return ERROR_STATUS_CLASS;
    }
  };

  const getPassStatus = (): 'Booked' | 'NotBooked' | 'Queued' => {
    const bookedPass = bokningar.find(b => b.PassId === pass.PassId);
    return !bookedPass ? 'NotBooked' : bookedPass.Koad ? 'Queued' : 'Booked';
  };

  const getIconButtonStyle = () => {
    switch (getPassStatus()) {
      case 'Booked':
        return '--Booked';
      case 'Queued':
        return '--BookedWithQueue';
      default:
        switch (pass.BokningStatus) {
          case BokningsStatusEnum.EJ_BOKNINGSBART_DATE_DROPIN:
            return '--NotAvailableYet';
          case BokningsStatusEnum.FOERTIDIGT_FOER_ATT_BOKA:
            return '--EarlyToBook';
          case BokningsStatusEnum.FOERSENT_FOER_ATT_BOKA:
            return '--LateToBook';
          case BokningsStatusEnum.BOKNINGSBART:
            return '--Available';
          case BokningsStatusEnum.BOKNINGSBART_DROPIN:
            return '--Available';
          case BokningsStatusEnum.BOKNINGSBART_KOE:
            return '--AvailableWithQueue';
          case BokningsStatusEnum.BOKNINGSBART_KOE_DROPIN:
            return '--AvailableWithQueue';
          case BokningsStatusEnum.FULLT || BokningsStatusEnum.FULLT_DROPIN:
            return '--MaxCapacity';
          case BokningsStatusEnum.DROPIN:
            return '--Dropin';
          case BokningsStatusEnum.INSTAELLD:
            return '--Cancelled';
          default:
            return '--Available';
        }
    }
  };
  return (
    <div className="Slots">
      {pass.BokningStatus !== 'DROPIN' && (
        <div className="Slots__Container">
          <span className="Slots__FreeSlots">{pass.AntalLedigaPlatser}</span>
          <div className="Slots__TotalSlots">
            <div className="Text">{translate(SitecoreDictionary.PassItem.EmptyPlaces)}</div>
            <div className="Total">
              {translate(SitecoreDictionary.PassItem.Total)} {pass.AntalPlatserTotal} platser
            </div>
          </div>
          <div className="Slots__Icon">
            <PassBookerContainer
              pass={pass}
              shouldShowInfoOnHover={!statusMessageVisible}
              translate={translate}
              onBokatEllerAvbokat={() => {
                setUserInformed(false);
              }}
            />
          </div>
        </div>
      )}
      <div className="Slots__Status">
        <StatusBar
          visible={statusMessageVisible}
          message={translate(statusMessageText).toString()}
          cssClass={getStatusMessageClass()}
          buttonStyle={getIconButtonStyle()}
        />
      </div>
    </div>
  );
};
