import { UIScheduleViewType } from './uiScheduleReducer';
import { UIScheduleFilterAction } from '../schedule-filter/uiScheduleFilterActions';

export enum UIScheduleActionType {
  SetScrollToDay = 'UI/SCHEDULE/SETSCROLLTODAY',
  SetDayClickedFromDayPicker = 'UI/SCHEDULE/SET_DAY_CLICKED_FROM_DAYPICKER',
  SetClickedFromDayPicker = 'UI/SCHEDULE/SET_CLICKED_FROM_DAYPICKER',
  SetViewTypeToList = 'UI_SCHEDULE_VIEWTYPE_LIST',
  SetViewTypeToWeek = 'UI_SCHEDULE_VIEWTYPE_WEEK',
  SetSelectedPass = 'UI_SCHEDULE_SET_SELECTED_PASS',
  SetShouldDisableSticky = 'UI_SCHEDULE_SET_SHOULD_DISABLE_STICKY',
  SetScrollPosition = 'UI_SCHEDULE_SET_SCROLL_POSITION',
}

export type SetScrollToDayAction = {
  type: UIScheduleActionType.SetScrollToDay;
  scrollToDay: string;
};

export type UISetClickedFromDayPickerAction = {
  type: UIScheduleActionType.SetClickedFromDayPicker;
  clickFromDayPicker: boolean;
};

export type UISetDayClickedFromDayPickerAction = {
  type: UIScheduleActionType.SetDayClickedFromDayPicker;
  clickFromDayPickerDay: string;
};

export interface UIScheduleSetViewTypeToListAction {
  type: UIScheduleActionType.SetViewTypeToList;
  viewShowing: UIScheduleViewType.LISTVIEW;
}

export interface UIScheduleSetViewTypeToWeekAction {
  type: UIScheduleActionType.SetViewTypeToWeek;
  viewShowing: UIScheduleViewType.WEEKVIEW;
}

export type UIScheduleSetSelectedPass = {
  type: UIScheduleActionType.SetSelectedPass;
  selectedPass: fos.api.Pass;
};

export type UIScheduleSetShouldDisableSticky = {
  type: UIScheduleActionType.SetShouldDisableSticky;
  value: boolean;
};

export type UIScheduleSetScrollPosition = {
  type: UIScheduleActionType.SetScrollPosition;
  scrollTop: number;
  scrollBottom: number;
  scrollRightPercent: number;
};

export type UIScheduleAction =
  | SetScrollToDayAction
  | UIScheduleSetViewTypeToListAction
  | UIScheduleSetViewTypeToWeekAction
  | UIScheduleFilterAction
  | UIScheduleSetSelectedPass
  | UIScheduleSetShouldDisableSticky
  | UISetDayClickedFromDayPickerAction
  | UISetClickedFromDayPickerAction
  | UIScheduleSetScrollPosition;
