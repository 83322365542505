import * as React from 'react';
import { StatusBar, NewPassword, MainInput, MainButton } from 'components/common';

import { SitecoreDictionary } from 'models';
import { LocationState } from 'store/location';
import { TranslateFunction } from 'react-localize-redux';
export interface UserAccountDataProps {
  translate: TranslateFunction;
  newPasswordValue: string;
  repeatNewPasswordValue: string;
  currentPasswordValue: string;
  isNewPasswordValid: boolean;
  isRepeatNewPasswordValid: boolean;
  isCurrentPasswordValid: boolean;
  isFormValid: boolean;
  changePasswordCmdIsExecuted: boolean;
  changePasswordCmdExecutionFailed: boolean;
  changePasswordCmdIsExecuting: boolean;
  activeFoereningId: number;
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
  savedAnlaeggningar: Array<fos.api.SavedSpecialintresse>;
  location: LocationState;
}

export interface UserAccountActionProps {
  updateCurrentPasswordValue: (newValue: string) => void;
  updateNewPasswordValue: (newValue: string) => void;
  updateRepeatNewPasswordValue: (newValue: string) => void;
  updateRepeatNewPasswordValidity: (isValid: boolean) => void;
  updateNewPasswordValidity: (isValid: boolean) => void;
  updateCurrentPasswordValidity: (isValid: boolean) => void;
  updateFormValidity: (isValid: boolean) => void;
  changePassword: () => void;
  clearChangeFetchPasswordCmd: (activeFoereningId: number) => void;
  clearChangePasswordCmd: () => void;
}

type UserAccountProps = UserAccountDataProps & UserAccountActionProps;

export class UserAccount extends React.Component<UserAccountProps> {
  constructor(props: UserAccountProps) {
    super(props);
    this.onCurrentPasswordChange = this.onCurrentPasswordChange.bind(this);
    this.onNewPasswordValidityChange = this.onNewPasswordValidityChange.bind(this);
    this.onRepeatNewPasswordValidityChange = this.onRepeatNewPasswordValidityChange.bind(this);
  }

  componentDidMount() {
    this.props.clearChangeFetchPasswordCmd(this.props.activeFoereningId);
  }

  componentWillUnmount() {
    this.props.clearChangePasswordCmd();
  }

  render() {
    return (
      <div className="UserAccount__Container">
        <div className="UserAccount__ChangePassword">
          <h4 className="UserAccount__ChangePassword__Title">
            {this.props.translate(SitecoreDictionary.UserAccount.ChangePasswordTitle)}
          </h4>
          <form className="UserAccount__ChangePassword__Form" onSubmit={e => this.changePassword(e)}>
            <MainInput
              name="password"
              shouldValidate={true}
              isValid={this.props.isCurrentPasswordValid}
              type="password"
              border="grey"
              label={this.props.translate(SitecoreDictionary.UserAccount.EnterCurrentPassword) as string}
              placeholder={
                this.props.translate(SitecoreDictionary.UserAccount.EnterCurrentPasswordPlaceHolder) as string
              }
              alignLabel="left"
              labelColor="black"
              align="center"
              value={this.props.currentPasswordValue}
              onValueChange={value => this.onCurrentPasswordChange(value as string)}
              disabled={this.props.changePasswordCmdIsExecuting}
            />
            <NewPassword
              alignText="center"
              translate={this.props.translate}
              passwordValue={this.props.newPasswordValue}
              repeatPasswordValue={this.props.repeatNewPasswordValue}
              isPasswordValid={this.props.isNewPasswordValid}
              isRepeatPasswordValid={this.props.isRepeatNewPasswordValid}
              updatePassword={this.props.updateNewPasswordValue}
              updateRepeatPassword={this.props.updateRepeatNewPasswordValue}
              updateRepeatPasswordValidity={this.onRepeatNewPasswordValidityChange}
              updatePasswordValidity={this.onNewPasswordValidityChange}
              disabled={this.props.changePasswordCmdIsExecuting}
              location={this.props.location}
            />
            {(this.props.changePasswordCmdExecutionFailed || this.props.changePasswordCmdIsExecuted) && (
              <StatusBar
                visible={true}
                message={
                  this.props.translate(
                    this.props.changePasswordCmdExecutionFailed
                      ? SitecoreDictionary.UserAccount.PasswordChangeFailed
                      : SitecoreDictionary.UserAccount.PasswordChangedSuccessfully
                  ) as string
                }
                cssClass={
                  'UserAccount__ChangePassword__Form__StatusMessage ' +
                  (this.props.changePasswordCmdExecutionFailed
                    ? 'UserAccount__ChangePassword__Form__StatusMessage--Fail'
                    : 'UserAccount__ChangePassword__Form__StatusMessage--Success')
                }
              />
            )}
            <MainButton
              className="UserAccount__ChangePassword__Form__SubmitButton"
              type="submit"
              buttonStyle="solid"
              color="red"
              title={this.props.translate(SitecoreDictionary.UserAccount.ChangePassword) as string}
              disabled={!this.props.isFormValid || this.props.changePasswordCmdIsExecuting}
            />
          </form>
        </div>
      </div>
    );
  }

  private changePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.changePassword();
  }

  private onCurrentPasswordChange(newValue: string) {
    this.props.updateCurrentPasswordValue(newValue);
    const isCurrentPasswordValid = !!newValue && newValue !== '';
    this.props.updateCurrentPasswordValidity(isCurrentPasswordValid);
    this.updateFormValidity(isCurrentPasswordValid, this.props.isNewPasswordValid, this.props.isRepeatNewPasswordValid);
  }

  private onNewPasswordValidityChange(isValid: boolean) {
    this.props.updateNewPasswordValidity(isValid);
    this.updateFormValidity(this.props.isCurrentPasswordValid, isValid, this.props.isRepeatNewPasswordValid);
  }

  private onRepeatNewPasswordValidityChange(isValid: boolean) {
    this.props.updateRepeatNewPasswordValidity(isValid);
    this.updateFormValidity(this.props.isCurrentPasswordValid, this.props.isNewPasswordValid, isValid);
  }

  private updateFormValidity(
    isCurrentPasswordValid: boolean,
    isNewPasswordValid: boolean,
    isRepeatNewPasswordValid: boolean
  ) {
    if (this.props.changePasswordCmdIsExecuted || this.props.changePasswordCmdExecutionFailed) {
      this.props.clearChangeFetchPasswordCmd(this.props.activeFoereningId);
    }
    this.props.updateFormValidity(isCurrentPasswordValid && isNewPasswordValid && isRepeatNewPasswordValid);
  }
}
