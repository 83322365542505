import * as React from 'react';
import {
  IconMyAccount,
  IconMenuNotifications,
  // IconMenuCalendar,
  IconMenuCalendarSync,
  IconMenuMembership,
  IconProfile,
  IconArrow, // ,
  // IconMenuSettings
} from 'components/icons';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as LocationAction } from 'store/location/locationActions';
import { SettingsPages } from '.';

import { SitecoreDictionary } from 'models';
import { isApp } from 'models/app/MobileOSType';
import { TranslateFunction } from 'react-localize-redux';

export interface SettingsLinksProps {
  className?: string;
  isDesktopMode?: boolean;
  activeSettingsPage?: SettingsPages;
  translate: TranslateFunction;
  shopActive?: boolean;
}

export const SettingsLinks: React.SFC<SettingsLinksProps> = props => {
  const renderLink = (
    link: LocationAction,
    icon: JSX.Element,
    heading: string,
    description: string,
    isActive: boolean = false
    ) => (
    <FancyNavLink to={RouteMap[link]} className={'Summary__Item ' + (isActive ? 'Summary__Item__Active' : '')}>
      <div className="Item__Icon">{icon}</div>
      <div className="Item__Text">
        <div className="Text__Heading">{heading}</div>
        <div className="Text__Description">{description}</div>
      </div>

      <div className={'Item__Arrow ' + (isActive ? 'Item__Arrow__Active ' : '')}>
        <IconArrow color={isActive && props.isDesktopMode ? '#e9e9e9' : '#e31836'} direction="right" />
      </div>
    </FancyNavLink>
  );

  return (
    <div className={'Settings__Summary ' + (props.className ? props.className : '')}>
      {renderLink(
        LocationAction.Settings_Profile,
        <IconMyAccount color="#94989B" active={false} />,
        props.translate(SitecoreDictionary.Profile.Menu.ProfileTitle) as string,
        props.translate(SitecoreDictionary.Profile.Menu.ProfileText) as string,
        props.activeSettingsPage === SettingsPages.Profile
      )}

      {/*
      renderLink(
        LocationAction.Settings_TrainingPreferences,
        <IconMenuSettings color="#94989B" />,
        props.translate(SitecoreDictionary.Profile.Menu.TrainingPreferencesTitle) as string,
        props.translate(SitecoreDictionary.Profile.Menu.TrainingPreferencesText) as string,
        props.activeSettingsPage === SettingsPages.TrainingPreferences
        )
      */}

      {renderLink(
        LocationAction.Settings_NotificationSettings,
        <IconMenuNotifications color="#94989B" />,
        props.translate(SitecoreDictionary.Profile.Menu.NotificationSettingsTitle) as string,
        props.translate(SitecoreDictionary.Profile.Menu.NotificationSettingsText) as string,
        props.activeSettingsPage === SettingsPages.NotificationSettings
      )}

      {// disable isApp to debugg PEDRAM
      isApp() &&
        // true &&
        renderLink(
          LocationAction.Settings_CalendarSyncSettings,
          <IconMenuCalendarSync color="#94989B" active={false} />,
          props.translate(SitecoreDictionary.Profile.Menu.CalendarSyncTitle) as string,
          props.translate(SitecoreDictionary.Profile.Menu.CalendarSyncText) as string,
          props.activeSettingsPage === SettingsPages.CalendarSync
        )}

      {props.shopActive &&
        renderLink(
          LocationAction.Settings_PaymentSettings,          
          <IconMenuMembership color="#94989B"/>,
          props.translate(SitecoreDictionary.Profile.Menu.PaymentsTitle) as string,
          props.translate(SitecoreDictionary.Profile.Menu.PaymentsText) as string,
          props.activeSettingsPage === SettingsPages.PaymentSettings
        )}

      {renderLink(
        LocationAction.Settings_UserAccount,
        <IconProfile active={false} />,
        props.translate(SitecoreDictionary.Profile.Menu.UserAccountTitle) as string,
        props.translate(SitecoreDictionary.Profile.Menu.UserAccountText) as string,
        props.activeSettingsPage === SettingsPages.UserAccount
      )}
    </div>
  );
};
