import * as React from 'react';

// import { DayPicker } from 'components/common';
import { Day } from 'models';

import { BookingItemContainer } from 'components/common';
import { DayViewObject } from 'models/Day';
import { getDateTextForView } from 'websd/utils';
import { TranslateFunction } from 'react-localize-redux';

interface DayDataProps {
  readonly days: DayViewObject<fos.api.Bokning>;
  readonly day: Day<fos.api.Bokning>;
  readonly translate: TranslateFunction;
}

interface DayActionProps {
  readonly updateBookingDayExpanded: (dayName: string, expanded: boolean) => void;
}

type DayProps = DayDataProps & DayActionProps;

export const BookingDayItem: React.SFC<DayProps> = (props: DayProps) => (
  <div className="DayItem BookingDayItem">
    <div className="DayItem__Header">{getDateTextForView(props.day.date)}</div>
    {props.day.items.map(pass => (
      <BookingItemContainer key={pass.PassId} bokning={pass} translate={props.translate} />
    ))}
  </div>
);
