import { RootState } from 'store';
import { connect } from 'react-redux';
import { ReceiptDataProps, ReceiptActionProps, Receipt } from './Receipt';
import { getEntityStatus, FetchStatusAggregateTypes, EntityStatus } from 'store/global/fetch';
import { getReceipt, getReceiptForOrder } from 'store/payment';
import { getTranslate } from 'react-localize-redux';

const mapDataToProps = (state: RootState, props: any): ReceiptDataProps => {
  const receiptId = !!state.location.query ? state.location.query.receiptId : undefined;
  const orderId = !!state.location.query ? state.location.query.orderId : undefined;
  const foereningId = state.global.session.activeFoerening;
  return {
    receiptId,
    orderId,
    activeFoerening: foereningId,
    receipt: state.payment.read.receipt,
    fetchReceiptStatus: !!receiptId
      ? getEntityStatus(state.global.fetch, '' + receiptId, FetchStatusAggregateTypes.GET_RECEIPT)
      : !!orderId
      ? getEntityStatus(state.global.fetch, 'order' + orderId, FetchStatusAggregateTypes.GET_RECEIPT)
      : EntityStatus.INITIAL,
    fetchProductsStatus: !!foereningId
      ? getEntityStatus(state.global.fetch, foereningId, FetchStatusAggregateTypes.GET_PRODUCTS)
      : EntityStatus.INITIAL,
    translate: getTranslate(state.locale),
  };
};

const mapActionsToProps: ReceiptActionProps = {
  getReceipt,
  getReceiptForOrder,
};

export const ReceiptContainer = connect(mapDataToProps, mapActionsToProps)(Receipt);
