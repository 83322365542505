import { FilterCategoryLocalState } from './uiScheduleFilterReducer';
import { ScheduleFilterCategoryName } from 'store/schedule/scheduleModels';

export enum UIScheduleFilterActionType {
  SetFilterToExpanded = 'UI_SCHEDULE_FILTER_EXPAND',
  SetFilterToMinimised = 'UI_SCHEDULE_FILTER_MINIMISE',
  SetCategoryClicked = 'UI_SCHEDULE_FILTER_CATEGORY_CLICKED',
  SetCategoryNotClicked = 'UI_SCHEDULE_FILTER_CATEGORY_NOT_CLICKED',
  SetCategoryAtTop = 'UI_SCHEDULE_FILTER_CATEGORY_AT_TOP',
  SetCategoryAtNotTop = 'UI_SCHEDULE_FILTER_CATEGORY_AT_NOT_TOP',
  SetCategoryTopPosition = 'UI_SCHEDULE_FILTER_CATEGORY_SET_TOP_POSITION',
  ToggleCategoryClicked = 'UI_SCHEDULE_FILTER_CATEGORY_TOGGLE_CLICK',
  SetCategoryLocalState = 'UI_SCHEDULE_FILTER_CATEGORY_SET_LOCAL_STATE',
}

// interface SetCategoryClickedInterface {
//   type: UIScheduleFilterActionType;
//   category: ScheduleFilterCategoryName;
// }
type SetFilterToExpanded = {
  type: UIScheduleFilterActionType.SetFilterToExpanded;
  expanded: true;
};
type SetFilterToMinimised = {
  type: UIScheduleFilterActionType.SetFilterToMinimised;
  expanded: false;
};
type SetCategoryClicked = {
  type: UIScheduleFilterActionType.SetCategoryClicked;
  category: ScheduleFilterCategoryName;
};

type SetCategoryNotClicked = {
  type: UIScheduleFilterActionType.SetCategoryNotClicked;
  category: ScheduleFilterCategoryName;
};
type ToggleCategoryClicked = {
  type: UIScheduleFilterActionType.ToggleCategoryClicked;
  category: ScheduleFilterCategoryName;
  clicked: boolean;
};

type SetCategoryAtTop = {
  type: UIScheduleFilterActionType.SetCategoryAtTop;
  category: ScheduleFilterCategoryName;
};

type SetCategoryAtNotTop = {
  type: UIScheduleFilterActionType.SetCategoryAtNotTop;
  category: ScheduleFilterCategoryName;
};

type SetCategoryTopPosition = {
  type: UIScheduleFilterActionType.SetCategoryTopPosition;
  category: ScheduleFilterCategoryName;
  topPosition: number;
};
type SetCategoryLocalState = {
  type: UIScheduleFilterActionType.SetCategoryLocalState;
  category: ScheduleFilterCategoryName;
  localState: FilterCategoryLocalState;
};

export type UIScheduleFilterAction =
  | SetFilterToExpanded
  | SetFilterToMinimised
  | SetCategoryClicked
  | SetCategoryNotClicked
  | ToggleCategoryClicked
  | SetCategoryAtTop
  | SetCategoryAtNotTop
  | SetCategoryTopPosition
  | SetCategoryLocalState;
