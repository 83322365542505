interface RemoveWorkoutLevelWriteAction {
  type: TrainingPreferencesWriteActionType.RemoveWorkoutLevel;
  workoutLevel: string;
}

interface AddWorkoutLevelWriteAction {
  type: TrainingPreferencesWriteActionType.AddWorkoutLevel;
  workoutLevel: string;
}

interface AddChildAgeGroupWriteAction {
  type: TrainingPreferencesWriteActionType.AddChildAgeGroup;
  childAgeGroup: string;
}

interface RemoveChildGroupWriteAction {
  type: TrainingPreferencesWriteActionType.RemoveChildAgeGroup;
  childAgeGroup: string;
}

interface RemoveSpecialintresseAction {
  type: TrainingPreferencesWriteActionType.RemoveSpecialintresse;
}

interface AddFavoriteAnlaeggningWriteAction {
  type: TrainingPreferencesWriteActionType.AddFavoriteAnlaeggning;
  favoriteAnlaeggning: string;
}

interface RemoveFavoriteAnlaeggningarWriteAction {
  type: TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggningar;
}

interface RemoveFavoriteAnlaeggningWriteAction {
  type: TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggning;
  anlaeggning: string;
}

export type TrainingPreferencesWriteAction =
  | RemoveWorkoutLevelWriteAction
  | AddWorkoutLevelWriteAction
  | RemoveChildGroupWriteAction
  | AddChildAgeGroupWriteAction
  | RemoveSpecialintresseAction
  | RemoveFavoriteAnlaeggningarWriteAction
  | AddFavoriteAnlaeggningWriteAction
  | RemoveFavoriteAnlaeggningWriteAction;

export enum TrainingPreferencesWriteActionType {
  RemoveWorkoutLevel = 'REMOVE_WORKOUTLEVEL',
  AddWorkoutLevel = 'ADD_WORKOUTLEVEL',
  RemoveChildAgeGroup = 'REMOVE_CHILD_AGE_GROUP',
  AddChildAgeGroup = 'ADD_CHILD_AGE_GROUP',
  RemoveSpecialintresse = 'REMOVE_PREFERENCES',
  AddFavoriteAnlaeggning = 'ADD_FAVORITE_ANLAEGGNING',
  RemoveFavoriteAnlaeggningar = 'REMOVE_FAVORITE_ANLAEGGNINGAR',
  RemoveFavoriteAnlaeggning = 'REMOVE_FAVORITE_ANLAEGGNING',
}

export const addWorkoutLevel = (workoutLevel: string): AddWorkoutLevelWriteAction => ({
  type: TrainingPreferencesWriteActionType.AddWorkoutLevel,
  workoutLevel,
});

export const removeWorkoutLevel = (workoutLevel: string): RemoveWorkoutLevelWriteAction => ({
  type: TrainingPreferencesWriteActionType.RemoveWorkoutLevel,
  workoutLevel,
});

export const removeChildAgeGroup = (childAgeGroup: string): RemoveChildGroupWriteAction => ({
  type: TrainingPreferencesWriteActionType.RemoveChildAgeGroup,
  childAgeGroup,
});

export const addChildAgeGroup = (childAgeGroup: string): AddChildAgeGroupWriteAction => ({
  type: TrainingPreferencesWriteActionType.AddChildAgeGroup,
  childAgeGroup,
});

export const removeSpecialintressen = (): RemoveSpecialintresseAction => ({
  type: TrainingPreferencesWriteActionType.RemoveSpecialintresse,
});

export const addFavoriteAnlaeggning = (favoriteAnlaeggning: string): AddFavoriteAnlaeggningWriteAction => ({
  type: TrainingPreferencesWriteActionType.AddFavoriteAnlaeggning,
  favoriteAnlaeggning,
});

export const removeFavoriteAnlaeggningar = (): RemoveFavoriteAnlaeggningarWriteAction => ({
  type: TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggningar,
});

export const removeFavoriteAnlaeggning = (anlaeggning: string): RemoveFavoriteAnlaeggningWriteAction => ({
  type: TrainingPreferencesWriteActionType.RemoveFavoriteAnlaeggning,
  anlaeggning,
});
