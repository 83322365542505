import * as React from 'react';
import { getCurrencyCode } from 'utils';

export interface ShoppingBasketTotalPriceProps {
  title: string;
  amount: number;
  currency: string;
}

export const ShoppingBasketTotalPrice: React.SFC<ShoppingBasketTotalPriceProps> = (
  props: ShoppingBasketTotalPriceProps
) => {
  return (
    <div className="ShoppingBasketProduct__Total">
      <div className="ShoppingBasketProduct__Total__Title">
        <p>{props.title}</p>
      </div>
      <div className="ShoppingBasketProduct__Total__Right">
        <span className="Total__Price">
          {props.amount}
          {getCurrencyCode(props.currency)}
        </span>
      </div>
    </div>
  );
};
