import * as React from 'react';
import {
  PassFilterFiltrerbarKategori,
  PassFilterKategorier,
  PassFilterFavoritfilter,
  PassFilterFiltrerbaraKategorier,
  PassFilterFlatKategorier,
  PassFilterFilterCache,
  PassFilterLegacyFilterCache,
} from 'store/passFilter/passFilterModels';
import { isFiltervalGrupp } from 'store/passFilter';
import { MainInput, ConfirmationTextBox } from 'components/common';
import { IconChevron, IconRoundButtonCheckmark, IconHeart, IconRemove, IconArrow } from '../../icons';
import PassFilterOverlayMessageContainer from './overlay/PassFilterOverlayMessageContainer';
import { SitecoreDictionary } from 'models';
import { MainButton } from '../MainButton';
import { HeaderIconEnum } from 'store/global/ui/header';
import { Spinner } from '../Spinner';
import { ErrorResult, SuccessResult } from 'websd/utils/configureApi';
import { PopOver } from '../PopOver';
import { TranslateFunction } from 'react-localize-redux';
import { getQueryStringValue } from 'utils';
import { ScheduleFilter } from 'store/schedule/scheduleModels';

export interface PassFilterInternalProps {
  filter: PassFilterFiltrerbaraKategorier;
  oeppenKategori?: keyof typeof PassFilterKategorier;
  favoritFilter: PassFilterFavoritfilter[];
  valtFavoritFilterId?: number;
  filterSynligt: boolean;
  translate: TranslateFunction;
  userId?: number;
  flatFilter: PassFilterFlatKategorier;
  harTraeffar: boolean;
  foereningName?: string;
  foereningId?: number;
  isPassDetailsShowing: boolean;
}

export interface PassFilterExternalProps {
  isFullDesktopView: boolean;
  isPublicWebMode: boolean;
}

export interface PassFilterActionProps {
  vaeljKategori: (kategori: keyof typeof PassFilterKategorier, vald: boolean) => void;
  vaeljFilter: (kategori: keyof typeof PassFilterKategorier, namn: string, vald: boolean) => void;
  vaeljAllaIFiltergrupp: (kategori: keyof typeof PassFilterKategorier, filtergruppNamn: string, vald: boolean) => void;
  vaeljFilterIGrupp: (
    kategori: keyof typeof PassFilterKategorier,
    filtergruppNamn: string,
    filter: string,
    vald: boolean
  ) => void;
  oeppnaFiltergrupp: (kategori: keyof typeof PassFilterKategorier, filtergruppNamn: string, oeppnad: boolean) => void;
  rensaFilter: () => void;
  setFilterSynligt: (synligt: boolean) => void;

  vaeljFavoritfilter: (favoritfilterId: number) => void;
  skapaFavoritfilter: (
    favoritfilter: PassFilterFavoritfilter
  ) => Promise<ErrorResult | SuccessResult<PassFilterFavoritfilter>>;
  deleteFavoritFilter: (favoritfilter: PassFilterFavoritfilter) => Promise<ErrorResult | SuccessResult<boolean>>;
  getFavoritFilters: (userId: number) => Promise<ErrorResult | SuccessResult<PassFilterFavoritfilter[]>>;
  setFilterFraanCache: (flatFilter: PassFilterFilterCache) => void;
  setHeaderIcons: (
    leftDesktopIcon?: HeaderIconEnum,
    rightDesktopIcon?: HeaderIconEnum,
    leftMobileIcon?: HeaderIconEnum,
    rightMobileIcon?: HeaderIconEnum
  ) => void;
}

export type PassFilterDataProps = PassFilterInternalProps & PassFilterExternalProps;
type PassFilterProps = PassFilterDataProps & PassFilterActionProps;

export const PassFilter: React.FunctionComponent<PassFilterProps> = ({
  filter,
  oeppenKategori,
  favoritFilter,
  valtFavoritFilterId,
  filterSynligt,
  translate,
  userId,
  flatFilter,
  harTraeffar,
  isFullDesktopView,
  isPublicWebMode,
  vaeljKategori,
  vaeljFilter,
  vaeljAllaIFiltergrupp,
  vaeljFilterIGrupp,
  oeppnaFiltergrupp,
  rensaFilter,
  setFilterSynligt,
  foereningName,
  foereningId,
  isPassDetailsShowing,

  vaeljFavoritfilter,
  skapaFavoritfilter,
  deleteFavoritFilter,
  getFavoritFilters,
  setFilterFraanCache,
  setHeaderIcons,
}) => {
  const popOverConstraintContainer = React.useRef<HTMLDivElement | null>(null);
  const [sparaFilterOeppnat, setSparaFilterOeppnat] = React.useState<boolean>(false);
  const [taBortFilterOeppnat, setTaBortFilterOeppnat] = React.useState<boolean>(false);
  const [spararNyttFilter, setSpararNyttFilter] = React.useState<boolean>(false);
  const [nyttFavoritfilterNamn, setNyttFavoritfilterNamn] = React.useState<string | undefined>();
  const [soekstraengar, setSoekstraengar] = React.useState<{ [key in keyof typeof PassFilterKategorier]: string }>({
    LedareOrTraenare: '',
    Tidpunkt: '',
    Traeningspass: '',
    Traeningsstaelle: '',
    Veckodag: '',
  });
  const [favoritFilterBorttagning, setFavoritFilterBorttagning] = React.useState<{
    [key: number]: { fraagaSynlig: boolean; tasBort: boolean };
  }>({});

  React.useEffect(() => {
    if (!isPublicWebMode) {
      uppdateraFilterFraanCache();
    } else {
      uppdateraPassFilterFraanQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (userId && !isPublicWebMode) {
      getFavoritFilters(userId).then(r => {
        if (!r.error) {
          uppdateraFilterFraanCache(r.result);

          (window as any).dataLayer.push({
            numberOfFavorites: r.result.length,
            forening: foereningName,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (userId && !isPublicWebMode) {
      const cached: PassFilterFilterCache = {
        LedareOrTraenare: flatFilter.LedareOrTraenare,
        Tidpunkt: flatFilter.Tidpunkt,
        Traeningspass: flatFilter.Traeningspass,
        Traeningsstaelle: flatFilter.Traeningsstaelle,
        Veckodag: flatFilter.Veckodag,
        favoritFilter: valtFavoritFilterId,
      };
      localStorage.setItem('cachedFilter', JSON.stringify(cached));
    }
  }, [flatFilter, isPublicWebMode, userId, valtFavoritFilterId]);

  React.useEffect(() => {
    if (isPassDetailsShowing) {
      return;
    }
    if (filterSynligt) {
      setHeaderIcons(undefined, undefined, HeaderIconEnum.NONE, HeaderIconEnum.NONE);
    } else {
      setHeaderIcons(
        undefined,
        undefined,
        HeaderIconEnum.FILTER,
        isPublicWebMode ? HeaderIconEnum.NONE : HeaderIconEnum.BACK
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPassDetailsShowing, filterSynligt]);

  const getAntalValdaFilter = () => {
    return (
      flatFilter.LedareOrTraenare.length +
      flatFilter.Tidpunkt.length +
      flatFilter.Traeningspass.length +
      flatFilter.Traeningsstaelle.length +
      flatFilter.Veckodag.length
    );
  };

  const flattenKategori = (filter: PassFilterFiltrerbarKategori) => {
    const result: string[] = [];
    if (filter.vaerden.length === 0) {
      return result;
    }
    filter.vaerden.forEach(v => {
      if (isFiltervalGrupp(v)) {
        v.filterval.forEach(f => {
          if (f.vald) {
            result.push(f.vaerde);
          }
        });
      } else if (v.vald) {
        result.push(v.vaerde);
      }
    });
    return result;
  };

  const konstrueraValdaFilternamn = (filter: PassFilterFiltrerbarKategori) => {
    let result: string = '';
    if (filter.vaerden.length === 0) {
      return result;
    }
    filter.vaerden.forEach(v => {
      if (isFiltervalGrupp(v)) {
        v.filterval.forEach(f => {
          if (f.vald) {
            if (result !== '') {
              result += ', ';
            }
            result += filter.anvaendTranslate ? translate(f.namn) : f.namn;
          }
        });
      } else if (v.vald) {
        if (result !== '') {
          result += ', ';
        }
        result += filter.anvaendTranslate ? translate(v.namn) : v.namn;
      }
    });
    return result;
  };

  const renderFavoritFilter = () => {
    return (
      <React.Fragment>
        {favoritFilter.length > 0 && (
          <React.Fragment>
            {!isFullDesktopView && (
              <strong style={{ paddingLeft: '22px', display: oeppenKategori ? 'none' : 'block' }}>
                {translate(SitecoreDictionary.Schedule.Filter.SavedFilters)}
              </strong>
            )}
            <div
              className={`PassFilter__Favoritfilter ${
                oeppenKategori && !isFullDesktopView ? 'PassFilter__Favoritfilter--hidden' : ''
              }`}
            >
              {favoritFilter.map(f => {
                return (
                  <div
                    key={'favoritfilter ' + f.FilterId}
                    className={`PassFilter__Favoritfilter__Favorit ${
                      f.FilterId === valtFavoritFilterId ? 'PassFilter__Favoritfilter__Favorit--selected' : ''
                    }`}
                    onClick={() => {
                      if (f.FilterId === valtFavoritFilterId) {
                        rensaFilter();
                      } else {
                        vaeljFavoritfilter(f.FilterId);
                      }
                    }}
                  >
                    <span>{f.FilterName}</span>
                  </div>
                );
              })}

              <div>
                <div
                  className="PassFilter__Favoritfilter__TaBortFilter"
                  onClick={() => {
                    setTaBortFilterOeppnat(!taBortFilterOeppnat);
                  }}
                >
                  <span>{translate(SitecoreDictionary.Schedule.Filter.DeleteFilter)}</span>
                </div>
                {isFullDesktopView && taBortFilterOeppnat && popOverConstraintContainer.current && (
                  <PopOver
                    constraintElement={popOverConstraintContainer.current}
                    verticalPadding={22}
                    horizontalPadding={22}
                  >
                    {renderTaBortContainer()}
                  </PopOver>
                )}
              </div>
            </div>
            {!isFullDesktopView && taBortFilterOeppnat && (
              <div className="PassFilter__Favoritfilter__TaBortFilterContainer">
                <div className="PassFilter__Favoritfilter__TaBortFilterContainer__Wrapper">
                  <div className="PassFilter__Favoritfilter__TaBortFilterContainer__Wrapper__Title">
                    <div onClick={() => setTaBortFilterOeppnat(false)}>
                      <IconArrow color="#e31836" direction="up" />
                    </div>
                    <span>{translate(SitecoreDictionary.Schedule.Filter.SaveFilter)}</span>
                    {/* Fulhack för att se till att texten ovan hamnar i mitten. */}
                    <div style={{ visibility: 'hidden', cursor: 'default' }}>
                      <IconArrow color="#e31836" direction="up" />
                    </div>
                  </div>
                  {renderTaBortContainer()}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderTaBortContainer = () => {
    return (
      <div className="PassFilter__Favoritfilter__TaBortFilterContainer__Wrapper__Container">
        {favoritFilter.map(f => {
          const borttagning = favoritFilterBorttagning[f.FilterId];
          return (
            <div
              className="PassFilter__Favoritfilter__TaBortFilterContainer__Wrapper__Container__Filter"
              key={'taBort' + f.FilterId}
            >
              <span>{f.FilterName}</span>
              {borttagning && borttagning.tasBort && <Spinner className="Spinner--medium" />}
              {(!borttagning || !borttagning.tasBort) && (
                <div
                  className="PassFilter__Favoritfilter__TaBortFilterContainer__Wrapper__Container__Filter__TaBort"
                  onClick={() => {
                    const res = { ...favoritFilterBorttagning };
                    if (!res[f.FilterId]) {
                      res[f.FilterId] = { fraagaSynlig: true, tasBort: false };
                    } else {
                      res[f.FilterId].fraagaSynlig = true;
                    }
                    setFavoritFilterBorttagning(res);
                  }}
                >
                  <IconRemove />
                </div>
              )}
              {borttagning && borttagning.fraagaSynlig && (
                <ConfirmationTextBox
                  handleNo={() => {
                    const res = { ...favoritFilterBorttagning };
                    res[f.FilterId].fraagaSynlig = false;
                    setFavoritFilterBorttagning(res);
                  }}
                  handleYes={() => {
                    deleteFavoritFilter(f).then(result => {
                      const res = { ...favoritFilterBorttagning };
                      res[f.FilterId].tasBort = false;
                      setFavoritFilterBorttagning(res);
                      setTaBortFilterOeppnat(favoritFilter.length === 0 ? false : taBortFilterOeppnat);
                    });
                    {
                      const res = { ...favoritFilterBorttagning };
                      res[f.FilterId].fraagaSynlig = false;
                      res[f.FilterId].tasBort = true;
                      setFavoritFilterBorttagning(res);
                    }
                  }}
                  text={(translate(SitecoreDictionary.Schedule.Filter.DeleteFilterConfirm) as string).replace(
                    '$0',
                    f.FilterName
                  )}
                  translate={translate}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSparaRensa = () => {
    return (
      <React.Fragment>
        <div className="PassFilter__SparaRensa">
          {/* Spara / rensa */}
          {!isPublicWebMode && !!foereningId && (
            <div>
              <div
                className={`PassFilter__SparaRensa__Spara ${
                  getAntalValdaFilter() === 0 ? 'PassFilter__SparaRensa__Spara--disabled' : ''
                }`}
                onClick={() => setSparaFilterOeppnat(true)}
              >
                <IconHeart />
                <span>{translate(SitecoreDictionary.Schedule.Filter.SaveFilter)}</span>
              </div>
              {isFullDesktopView && sparaFilterOeppnat && popOverConstraintContainer.current && (
                <PopOver
                  constraintElement={popOverConstraintContainer.current}
                  verticalPadding={22}
                  horizontalPadding={22}
                >
                  {renderSparaContainer()}
                </PopOver>
              )}
              {!isFullDesktopView && sparaFilterOeppnat && (
                <div className="PassFilter__SparaContainer">{renderSparaContainer()}</div>
              )}
            </div>
          )}
          <div className="PassFilter__SparaRensa__Rensa" onClick={rensaFilter}>
            <IconRemove />
            <span>{translate(SitecoreDictionary.Schedule.Filter.ClearFilter)}</span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderSparaContainer = () => {
    return (
      <div className="PassFilter__SparaContainer__Container">
        <span>{translate(SitecoreDictionary.Schedule.Filter.NameFilterTitle)}</span>
        <MainInput
          placeholder={translate(SitecoreDictionary.Schedule.Filter.NameFilter) as string}
          align={'left'}
          smallRadius={true}
          border={'grey'}
          onValueChange={v => setNyttFavoritfilterNamn(v as string)}
          value={nyttFavoritfilterNamn || ''}
          autoSelect={true}
        />
        {spararNyttFilter && <Spinner alignment="center" />}
        {!spararNyttFilter && (
          <div className="PassFilter__SparaContainer__Container__Buttons">
            <MainButton
              title={translate(SitecoreDictionary.Common.Cancel) as string}
              color="white"
              buttonStyle="outline"
              handleOnClick={() => setSparaFilterOeppnat(false)}
              disabled={false}
            />
            <MainButton
              title={translate(SitecoreDictionary.Common.Save) as string}
              color="red"
              handleOnClick={() => {
                if (userId) {
                  setSpararNyttFilter(true);
                  skapaFavoritfilter({
                    FilterName: nyttFavoritfilterNamn as string,
                    FilterId: 0,
                    UserId: userId,
                    FoereningId: foereningId!,
                    Veckodag: flattenKategori(filter.Veckodag),
                    Traeningsstaelle: flattenKategori(filter.Traeningsstaelle),
                    Traeningspass: flattenKategori(filter.Traeningspass),
                    Tidpunkt: flattenKategori(filter.Tidpunkt),
                    LedareOrTraenare: flattenKategori(filter.LedareOrTraenare),
                  }).then(() => {
                    setSpararNyttFilter(false);
                    setSparaFilterOeppnat(false);
                    setNyttFavoritfilterNamn(undefined);
                  });
                } else {
                  setSparaFilterOeppnat(false);
                  setNyttFavoritfilterNamn(undefined);
                }
              }}
              disabled={favoritFilter.some(f => f.FilterName === nyttFavoritfilterNamn)}
            />
          </div>
        )}
      </div>
    );
  };

  const renderKategorival = (
    filtrerbarKategori: PassFilterFiltrerbarKategori,
    kategoriNamn: keyof typeof PassFilterKategorier
  ) => {
    return (
      <div className="PassFilter__Kategorier__Kategori__Content__Vaerde">
        {filtrerbarKategori.vaerden
          .filter(
            v =>
              !filtrerbarKategori.soekbar ||
              !soekstraengar[kategoriNamn] ||
              (filtrerbarKategori.anvaendTranslate ? (translate(v.namn) as string) : v.namn)
                .toLocaleLowerCase()
                .indexOf(soekstraengar[kategoriNamn].toLocaleLowerCase()) !== -1 ||
              (isFiltervalGrupp(v) &&
                v.filterval.some(
                  vf =>
                    (filtrerbarKategori.anvaendTranslate ? (translate(vf.namn) as string) : vf.namn)
                      .toLocaleLowerCase()
                      .indexOf(soekstraengar[kategoriNamn].toLocaleLowerCase()) !== -1
                ))
          )
          .map(v => {
            return (
              <React.Fragment key={`vaerde${kategoriNamn}${v.namn}`}>
                {/* Grupperade val */}
                {isFiltervalGrupp(v) && (
                  <div
                    className={`PassFilter__Kategorier__Kategori__Content__Vaerde__Grupp ${
                      v.oeppen ? 'PassFilter__Kategorier__Kategori__Content__Vaerde__Grupp--selected' : ''
                    }`}
                  >
                    <div
                      onClick={e => {
                        oeppnaFiltergrupp(kategoriNamn, v.namn, !v.oeppen);
                        e.stopPropagation();
                      }}
                      className="PassFilter__Kategorier__Kategori__Content__Vaerde__Val"
                    >
                      <span>
                        {(filtrerbarKategori.anvaendTranslate ? translate(v.namn) : v.namn) +
                          (v.filterval.filter(f2 => f2.vald).length > 0
                            ? ' (' + v.filterval.filter(f2 => f2.vald).length + ')'
                            : '')}
                      </span>
                      <IconChevron direction={v.oeppen ? 'top' : 'bottom'} color="red" />
                    </div>
                    {v.oeppen && (
                      <div className="PassFilter__Kategorier__Kategori__Content__Vaerde__Grupp__Filter">
                        <div
                          onClick={e => {
                            vaeljAllaIFiltergrupp(
                              kategoriNamn,
                              v.namn,
                              v.filterval.some(fv => !fv.vald)
                            );
                          }}
                          className="PassFilter__Kategorier__Kategori__Content__Vaerde__Val"
                        >
                          <span>
                            {(translate(SitecoreDictionary.Schedule.Filter.SelectAllInCategory) as string).replace(
                              '$0',
                              (filtrerbarKategori.anvaendTranslate ? (translate(v.namn) as string) : v.namn) + ' '
                            )}
                          </span>
                          <IconRoundButtonCheckmark selected={!v.filterval.some(f2 => !f2.vald)} />
                        </div>
                        {v.filterval.map(v2 => {
                          return (
                            <div
                              className="PassFilter__Kategorier__Kategori__Content__Vaerde__Val"
                              key={`vaerde${kategoriNamn}${v.namn}${v2.namn}`}
                              onClick={e => {
                                vaeljFilterIGrupp(kategoriNamn, v.namn, v2.namn, !v2.vald);
                              }}
                            >
                              <span>
                                {(filtrerbarKategori.anvaendTranslate ? (translate(v2.namn) as string) : v2.namn) + ' '}
                              </span>
                              <IconRoundButtonCheckmark selected={v2.vald} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {/* Ej grupperade val */}
                {!isFiltervalGrupp(v) && (
                  <div
                    className="PassFilter__Kategorier__Kategori__Content__Vaerde__Val"
                    onClick={e => {
                      vaeljFilter(kategoriNamn, v.namn, !v.vald);
                    }}
                  >
                    <span>{(filtrerbarKategori.anvaendTranslate ? (translate(v.namn) as string) : v.namn) + ' '}</span>
                    <IconRoundButtonCheckmark selected={v.vald} />
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    );
  };

  const renderAllaKategorier = () => {
    return (
      <React.Fragment>
        <div className="PassFilter__Kategorier">
          {(Object.keys(filter) as (keyof typeof PassFilterKategorier)[]).map(kategori => {
            const f = filter[kategori];
            const harValdaFilter = f.vaerden.some(v =>
              isFiltervalGrupp(v) ? v.filterval.some(f2 => f2.vald) : v.vald
            );
            return (
              <div
                className={`PassFilter__Kategorier__Kategori${
                  oeppenKategori === kategori ? ' PassFilter__Kategorier__Kategori--selected' : ''
                }${harValdaFilter ? ' PassFilter__Kategorier__Kategori--harValdaFilter' : ''}`}
                key={`kategori${kategori}`}
              >
                <div
                  onClick={() => {
                    vaeljKategori(kategori, oeppenKategori !== kategori);
                  }}
                  className="PassFilter__Kategorier__Kategori__Namn"
                >
                  <div className="PassFilter__Kategorier__Kategori__Namn__Container">
                    <div className="PassFilter__Kategorier__Kategori__Namn__Container__Namn">
                      {translate(SitecoreDictionary.Schedule.Filter.Categories[kategori])}
                      {isFullDesktopView && harValdaFilter && (
                        <React.Fragment>{' (' + flattenKategori(f).length + ')'}</React.Fragment>
                      )}
                    </div>
                    {!isFullDesktopView && harValdaFilter && (
                      <div className="PassFilter__Kategorier__Kategori__Namn__Container__ValdaVaerden">
                        {konstrueraValdaFilternamn(f)}
                      </div>
                    )}
                  </div>
                  <IconChevron direction={oeppenKategori === kategori ? 'top' : 'bottom'} color="red" />
                </div>
                {oeppenKategori === kategori && (
                  <React.Fragment>
                    {isFullDesktopView && popOverConstraintContainer.current && (
                      <PopOver
                        constraintElement={popOverConstraintContainer.current}
                        skipArrow={true}
                        verticalPadding={22}
                      >
                        {renderKategoriContent(f, kategori)}
                      </PopOver>
                    )}
                    {!isFullDesktopView && renderKategoriContent(f, kategori)}
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const renderKategoriContent = (f: PassFilterFiltrerbarKategori, kategori: keyof typeof PassFilterKategorier) => {
    return (
      <div className="PassFilter__Kategorier__Kategori__Content">
        {f.soekbar && (
          <MainInput
            type="text"
            border="grey"
            alignLabel="left"
            align="left"
            placeholder={translate(SitecoreDictionary.Schedule.Filter.SearchWithFreeText) as string}
            onValueChange={value => {
              const s = { ...soekstraengar };
              s[kategori] = value as string;
              setSoekstraengar(s);
            }}
            value={soekstraengar[kategori]}
            className="PassFilter__Kategorier__Kategori__Content--input"
          />
        )}

        {renderKategorival(f, kategori)}
      </div>
    );
  };

  const uppdateraPassFilterFraanQuery = (allaFavoritfilter?: PassFilterFavoritfilter[]) => {
    const defaultAnlaeggning = decodeURIComponent(getQueryStringValue('Anlaeggning'));
    const defaultTraeningspass = decodeURIComponent(getQueryStringValue('Traeningspass'));
    let defaultFilter: ScheduleFilter = {
      Veckodag: [],
      Traeningsstaelle: [],
      Traeningspass: [],
      Tidpunkt: [],
      LedareOrTraenare: [],
      Favoritfilter: [],
      Bokningsstatus: [],
    };
    if (defaultTraeningspass !== '' || defaultAnlaeggning !== '') {
      if (defaultAnlaeggning !== '') {
        defaultFilter.Traeningsstaelle = [defaultAnlaeggning];
      }
      if (defaultTraeningspass !== '') {
        defaultFilter.Traeningspass = [defaultTraeningspass];
      }
    }
    setFilterFraanCache(defaultFilter);
  };

  const uppdateraFilterFraanCache = (allaFavoritfilter?: PassFilterFavoritfilter[]) => {
    let cachedFilter = localStorage.getItem('filterStuff');
    if (cachedFilter) {
      if (!allaFavoritfilter) {
        return;
      }
      localStorage.removeItem('filterStuff');
      const parsedLegacyFilter = JSON.parse(cachedFilter) as PassFilterLegacyFilterCache;
      const favFilter =
        parsedLegacyFilter.Favoritfilter.length > 0
          ? allaFavoritfilter.find(f => f.FilterName === parsedLegacyFilter.Favoritfilter[0])
          : undefined;
      const parsedNewFilter: PassFilterFilterCache = {
        Veckodag: parsedLegacyFilter.Veckodag.map(v => v.toLocaleLowerCase()),
        Traeningsstaelle: parsedLegacyFilter.Traeningsstaelle.map(v => v.toLocaleLowerCase()),
        Traeningspass: parsedLegacyFilter.Traeningspass.map(v => v.toLocaleLowerCase()),
        Tidpunkt: parsedLegacyFilter.Tidpunkt.map(v => v.toLocaleLowerCase()),
        LedareOrTraenare: parsedLegacyFilter.LedareOrTraenare.map(v => v.toLocaleLowerCase()),
        favoritFilter: !!favFilter ? favFilter.FilterId : undefined,
      };

      setFilterFraanCache(parsedNewFilter);
    } else {
      cachedFilter = localStorage.getItem('cachedFilter');
      if (cachedFilter) {
        const parsedFilter = JSON.parse(cachedFilter) as PassFilterFilterCache;
        setFilterFraanCache(parsedFilter);
      }
    }
  };

  return (
    <>
      {(filterSynligt || isFullDesktopView) && (
        <div
          ref={r => {
            popOverConstraintContainer.current = r;
          }}
          className={'PassFilter' + (isFullDesktopView ? ' PassFilter--fulldesktopview' : '')}
        >
          {!isPublicWebMode && renderFavoritFilter()}
          {renderAllaKategorier()}
          {renderSparaRensa()}
          {!isFullDesktopView && (
            <div className="PassFilter__Button">
              <MainButton
                handleOnClick={() => {
                  if (oeppenKategori) {
                    vaeljKategori(oeppenKategori, false);
                  }
                  if (sparaFilterOeppnat) {
                    setSparaFilterOeppnat(false);
                  }
                  if (taBortFilterOeppnat) {
                    setTaBortFilterOeppnat(false);
                  }
                  setFilterSynligt(false);
                }}
                title={
                  (harTraeffar
                    ? (translate(SitecoreDictionary.Schedule.Filter.HideFilter) as string)
                    : (translate(SitecoreDictionary.Schedule.Filter.FilterHasNoHits) as string)) +
                  (getAntalValdaFilter() > 0 ? ' (' + getAntalValdaFilter() + ')' : '')
                }
                className={!harTraeffar ? 'Button--warning' : ''}
              />
            </div>
          )}
        </div>
      )}
      {(!filterSynligt || isFullDesktopView) && (
        <PassFilterOverlayMessageContainer isFullDesktopView={isFullDesktopView} />
      )}
    </>
  );
};
