import { createBasket } from './../basket/basketActions';
import { setActiveFoerening, setInloggadeFoereningar } from 'store/global/session';
import { addError } from './../global/errors/errorsActions';
import { api } from 'api';
import { PRODUCT_ERROR, fetchProducts, fetchFilters } from './';
import { Dispatch } from 'redux';
import { Action, RootState, ThunkAction } from 'store';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from '../global/fetch';

export const getSubscriptionsDetails =
  (kort: fos.api.Medlemskap, foereningsId: number) =>
  (dispatch: Dispatch<Action>, getState: () => RootState): Promise<fos.api.Product> => {
    const resultPromise = new Promise<fos.api.Product>(async (resolve, reject: (reason: PRODUCT_ERROR) => void) => {
      const res = await api.get<fos.api.Product>(
        `Produkter/GetMedlemskapKortDetaljer/${kort.ProductId}?foereningsId=${foereningsId}`,
        {
          muteErrorNotificationOnStatus: [404],
        }
      );

      if (res.error) {
        if (res.result.http && res.result.http.status === 404) {
          reject(PRODUCT_ERROR.NOT_FOUND);
          dispatch(
            addError({
              ...res.result,
              message: 'Det går inte att köpa denna produkt',
            })
          );
        } else {
          reject(PRODUCT_ERROR.UNKNOWN_ERROR);
        }
      } else {
        resolve(res.result);
      }
    });
    return resultPromise;
  };

export const getTrainingCardDetails =
  (kort: fos.api.Traeningskort, foereningsId: number): ThunkAction =>
  (dispatch, getState): Promise<fos.api.Product> => {
    dispatch(fetching(kort.Id, FetchStatusAggregateTypes.PRODUCT_INFO));
    const resultPromise = new Promise<fos.api.Product>(async (resolve, reject: (reason: PRODUCT_ERROR) => void) => {
      const res = await api.get<fos.api.Product>(
        `Produkter/GetTraeningsKortDetaljer/${kort.ProductId}?foereningsId=${foereningsId}`,
        {
          muteErrorNotificationOnStatus: [404],
        }
      );

      if (res.error) {
        if (res.result.http && res.result.http.status === 404) {
          reject(PRODUCT_ERROR.NOT_FOUND);
        } else {
          reject(PRODUCT_ERROR.UNKNOWN_ERROR);
        }
        dispatch(fetchedFailed(kort.Id, FetchStatusAggregateTypes.PRODUCT_INFO));
      } else {
        resolve(res.result);
        dispatch(fetched(kort.Id, FetchStatusAggregateTypes.PRODUCT_INFO, res));
      }
    });
    return resultPromise;
  };

export const getProducts =
  (foereningId: number): ThunkAction =>
  (dispatch: Dispatch, getState: () => RootState): Promise<Array<fos.api.Product>> => {
    dispatch(fetching(foereningId, FetchStatusAggregateTypes.GET_PRODUCTS));
    const resultPromise = new Promise<Array<fos.api.Product>>(async (resolve, reject) => {
      const url = 'produkter/getproduktlist?foereningId=';
      const res = await api.get<Array<fos.api.Product>>(url + foereningId, {
        muteErrorNotificationOnStatus: [400],
      });

      if (!res.error) {
        dispatch(fetchProducts(res.result));
        resolve(res.result);

        dispatch(fetched(foereningId, FetchStatusAggregateTypes.GET_PRODUCTS, res.result));
      } else {
        if (res.result) {
          reject(new Error(res.result.message));
        } else {
          reject(new Error());
        }
      }
    });
    return resultPromise;
  };

// NY KOD
export const getFilters =
  (foereningId: number): ThunkAction =>
  (dispatch, getState) => {
    dispatch(fetching(foereningId, FetchStatusAggregateTypes.GET_FILTER));

    const url = 'WebShop/GetAllFilters';
    const promise = api.get<Array<fos.api.FilterCategory>>(url, {
      muteErrorNotificationOnStatus: [400],
    });

    promise.then(result => {
      if (!result.error) {
        dispatch(fetched(foereningId, FetchStatusAggregateTypes.GET_FILTER, result.result));
        dispatch(fetchFilters(result.result));
      }
    });
  };
export const getProductsAndInitSession =
  (foereningName: string): ThunkAction =>
  dispatch => {
    const promise = api.get<{ Produkter: fos.api.Product[] } & fos.api.InloggadeFoerening>(
      'produkter/GetProdukterAndFoerening?name=' + foereningName
    );

    dispatch(fetching('-1', FetchStatusAggregateTypes.GET_PRODUCTS));
    promise.then(result => {
      if (!result.error) {
        dispatch(fetchProducts(result.result.Produkter));
        dispatch(setActiveFoerening(result.result.Id));
        dispatch(createBasket(result.result.Id));
        dispatch(
          setInloggadeFoereningar([
            {
              Id: result.result.Id,
              Namn: result.result.Namn,
              CountryId: result.result.CountryId,
              HasAutoGiro: result.result.HasAutoGiro,
              HarNationelltTraeningsKort: result.result.HarNationelltTraeningsKort,
              AerButikAktiv: result.result.AerButikAktiv,
              DisplayName: result.result.DisplayName,
              OnlinetraeningStatus: result.result.OnlinetraeningStatus,
              // Set from GetExtraInfo
              ExtraFoereningInfo: { ExternalUrl: '', OnlineTrainingUrl: '' },
            },
          ])
        );
        dispatch(fetched('-1', FetchStatusAggregateTypes.GET_PRODUCTS, result.result));
      } else {
        dispatch(fetchedFailed('-1', FetchStatusAggregateTypes.GET_PRODUCTS));
      }
    });
  };
