import { PassActionType } from './passActions';
import { PassDetailsReadActionType } from '../../pass-details/read/passDetailsReadActions';
import { Day } from 'models';
import { Action } from 'store';

export interface PassReadState {
  days: Array<Day<fos.api.Pass>>;
}

const initialState: PassReadState = {
  days: [],
};

export const readReducer = (state: PassReadState = initialState, action: Action): PassReadState => {
  switch (action.type) {
    case PassActionType.Day_UpdateDay: {
      return { ...state, days: action.days };
    }
    case PassActionType.UpdateBokaPassStatus: {
      const newDays = [...state.days];
      for (let i = 0; i < newDays.length; i++) {
        const pass = newDays[i].items.find(p => p.PassId === action.passId);
        if (!!pass) {
          pass.statusMessage = action.message;
          break;
        }
      }
      return { ...state, days: newDays };
    }
    case PassDetailsReadActionType.PassDetails_UpdatePassDetails: {
      const newDays = [...state.days];
      for (let i = 0; i < newDays.length; i++) {
        const pass = newDays[i].items.find(p => p.PassId === action.passDetails.PassId);
        if (!!pass) {
          pass.AntalBokningsbaraPlatser = action.passDetails.AntalBokningsbaraPlatser;
          pass.AntalBokningsbaraPlatserTotalt = action.passDetails.AntalBokningsbaraPlatserTotalt;
          pass.AntalDropinPlatser = action.passDetails.AntalDropinPlatser;
          pass.AntalIVaenteLista = action.passDetails.AntalIVaenteLista;
          pass.AntalLedigaPlatser = action.passDetails.AntalLedigaPlatser;
          pass.AntalPlatserTotal = action.passDetails.AntalPlatserTotal;
          pass.LedigaKoePlatser = action.passDetails.LedigaKoePlatser;
          break;
        }
      }
      return { ...state, days: newDays };
    }

    default:
      return state;
  }
};
