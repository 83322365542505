import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface NotFoundProps {
  translate: TranslateFunction;
}

const NotFound = (props: NotFoundProps) => (
  <article className="notFound">
    <header className="tc pt4">
      <h1 className="normal notFound__Header-h1">404</h1>
      <h2 className="normal notFound__Header-h2">{props.translate(SitecoreDictionary.PageNotFound) as string}</h2>
    </header>
  </article>
);

export default NotFound;
