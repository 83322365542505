import { Reducer } from 'redux';
import { Action } from 'store';
import { PasswordResetActionTypes, PasswordResetState } from '.';

const initialState: PasswordResetState = {
  newPassword: '',
  repeatNewPassword: '',
  isNewPasswordValid: false,
  isRepeatNewPassword: false,
};

export const passwordResetReducer: Reducer<PasswordResetState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case PasswordResetActionTypes.SET_PASSWORD_RESET:
      return {
        ...state,
        passwordResetRequestCompleted: action.passwordResetRequestCompleted,
        requestPasswordResetLinkResult: action.isSuccess,
      };
    case PasswordResetActionTypes.RESET_PASSWORD_RESULT:
      return {
        ...initialState,
        passwordResetSucceeded: action.passwordResetSucceeded,
        passwordResetErrorCode: action.errorCode,
      };
    case PasswordResetActionTypes.SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.value,
      };
    case PasswordResetActionTypes.SET_REPEAT_NEW_PASSWORD:
      return {
        ...state,
        repeatNewPassword: action.value,
      };
    case PasswordResetActionTypes.SET_IS_NEW_PASSWORD_VALID:
      return {
        ...state,
        isNewPasswordValid: action.isValid,
      };
    case PasswordResetActionTypes.SET_IS_REPEAT_NEW_PASSWORD_VALID:
      return {
        ...state,
        isRepeatNewPassword: action.isValid,
      };
    case PasswordResetActionTypes.CLEAR_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
};
