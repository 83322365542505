import { ProfilePropertyNameValues, UISettingsProfileUnderEdit } from './uiSettingsProfileModels';

export enum uiSettingsProfileActionType {
  GetUISettingsProfileUnderEdit = 'GET_UI_SETTINGS_PROFILE_UNDER_EDIT',
  UpdateUISettingsProfileUnderEdit = 'UPDATE_UI_SETTINGS_PROFILE_UNDER_EDIT',
  UpdateUISettingsProfileUnderClick = 'UPDATE_UI_SETTINGS_PROFILE_UNDER_CLICK',
}

export interface GetUISettingsProfileAction {
  type: uiSettingsProfileActionType.GetUISettingsProfileUnderEdit;
}

export interface UpdateUISettingsProfileAction {
  type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit;
  uiSettingsProfileUnderEdit: UISettingsProfileUnderEdit;
}

export interface UpdateUISettingsProfileClickAction {
  type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderClick;
  // uiSettingsProfileUnderEdit: UISettingsProfileUnderEdit;
  propertyFieldUnderClick: ProfilePropertyNameValues;
}

export const getUISettingsProfileUnderEdit = (): uiSettingsProfileAction => ({
  type: uiSettingsProfileActionType.GetUISettingsProfileUnderEdit,
});

export const updateUISettingsProfileUnderEdit = (
  uiSettingsProfileUnderEdit: UISettingsProfileUnderEdit
): uiSettingsProfileAction => ({
  type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit,
  uiSettingsProfileUnderEdit: uiSettingsProfileUnderEdit,
});

export const updateUISettingsProfileUnderFocus = (
  uiSettingsProfileUnderEdit: UISettingsProfileUnderEdit,
  propertyFieldUnderClick: ProfilePropertyNameValues
): uiSettingsProfileAction => ({
  type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderClick,
  // uiSettingsProfileUnderEdit: uiSettingsProfileUnderEdit,
  propertyFieldUnderClick: propertyFieldUnderClick,
});

export type uiSettingsProfileAction =
  | GetUISettingsProfileAction
  | UpdateUISettingsProfileAction
  | UpdateUISettingsProfileClickAction;
