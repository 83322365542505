import { getApplicationViewModeIsDesktop } from 'store/global/ui/application-mode/applicationModeReducer';
import { RootState, Dispatch } from 'store';
import { connect } from 'react-redux';
import { SchedulePage, SchedulePageDataProps, SchedulePageActionProps } from './schedulePage';
import { SidebarStatus, SideBarAction, SidebarSize } from 'store/global/ui/side-bar';
import { UIScheduleAction, UIScheduleActionType, UIScheduleViewType } from 'store/global/ui/schedule';
import { UIScheduleFilterActionType } from 'store/global/ui/schedule-filter';

const setScheduleToWeekView = () => (dispatch: Dispatch<UIScheduleAction | SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_LAUNCH',
    visibility: SidebarStatus.SHOWING,
  });
  dispatch({
    type: UIScheduleFilterActionType.SetFilterToMinimised,
    expanded: false,
  });
  dispatch({
    type: UIScheduleActionType.SetViewTypeToWeek,
    viewShowing: UIScheduleViewType.WEEKVIEW,
  });
  dispatch({
    type: 'UI_SIDEBAR_SET_SIZE_MAXIMISED',
    size: SidebarSize.MAXIMISED,
  });
};

const setScheduleToListView = () => (dispatch: Dispatch<UIScheduleAction | SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_SET_SIZE_MINIMISED',
    size: SidebarSize.MINIMISED,
  });
  dispatch({
    type: UIScheduleActionType.SetViewTypeToList,
    viewShowing: UIScheduleViewType.LISTVIEW,
  });
};

const mapDataToProps = (state: RootState): SchedulePageDataProps => ({
  isDesktopMode: getApplicationViewModeIsDesktop(state),
});

const mapActionToProps: SchedulePageActionProps = {
  setScheduleToWeekView,
  setScheduleToListView,
};

export default connect(mapDataToProps, mapActionToProps)(SchedulePage);
