import * as React from 'react';
import { IconCross, IconCrossColor } from 'components/icons';

export interface StatusBarProps {
  readonly message?: string | React.ReactNode;
  readonly cssClass: string;
  readonly visible: boolean;
  readonly buttonStyle?: string;
  readonly closeBtn?: boolean;
  handleClick?: () => void;
}

export class StatusBar extends React.Component<StatusBarProps> {
  render() {
    return (
      <div
        onClick={this.props.handleClick}
        className={
          'StatusMessage ' +
          this.props.cssClass +
          (this.props.visible ? ' StatusMessage--visible' : '') +
          (this.props.buttonStyle ? ' StatusMessage' + this.props.buttonStyle : '')
        }
      >
        {!!this.props.message && this.props.message}

        {!!this.props.closeBtn && !!this.props.handleClick && this.props.handleClick !== undefined && (
          <div className="icon" onClick={this.props.handleClick}>
            <IconCross color={IconCrossColor.gray} />
          </div>
        )}
      </div>
    );
  }
}
