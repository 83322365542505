import { ThunkAction } from 'store/rootReducer';
import {
  fetched,
  FetchStatusAggregateTypes,
  fetchedFailed,
  fetching,
  fetchingInBackground,
  FetchingAction,
  FetchingInBackgroundAction,
} from 'store/global/fetch';
import { api } from 'api';
import { constructSafeName } from 'utils';

import { updatePassDetailsData } from './read';

const fetchPassDetailsInternal = (
  passId: string,
  anlaeggningsId: string,
  fetchingMethod: (
    id: number | string,
    aggregateType: FetchStatusAggregateTypes
  ) => FetchingAction | FetchingInBackgroundAction
): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const foereningId = state.global.session.activeFoerening;
  const aktivFoerening = foereningId
    ? state.global.session.inloggadeFoereningar.find(f => f.Id.toString() === foereningId.toString())
    : null;
  if (foereningId && aktivFoerening) {
    dispatch(fetchingMethod(passId, FetchStatusAggregateTypes.PASS));
    let url = `pass/GetPass?foereningsId=${aktivFoerening.Id}&passId=${passId}&foerening=${constructSafeName(
      aktivFoerening.Namn
    )}&anlaeggningsId=${anlaeggningsId}`;
    let promise = api.get<fos.api.PassDetails>(url, {
      muteErrorNotificationOnStatus: [406],
    });
    promise.then(result => {
      if (!result.error) {
        let pass: fos.api.PassDetails = result.result;
        dispatch(updatePassDetailsData(pass));
        dispatch(fetched(passId, FetchStatusAggregateTypes.PASS, pass));
      } else {
        dispatch(fetchedFailed(passId, FetchStatusAggregateTypes.PASS));
      }
    });
    return promise;
  }
  dispatch(fetchedFailed(passId, FetchStatusAggregateTypes.PASS));

  return null;
};

export const fetchPassDetails = (passId: string, anlaeggningsId: string): ThunkAction => (dispatch, getState) => {
  return dispatch(fetchPassDetailsInternal(passId, anlaeggningsId, fetching));
};

export const fetchPassDetailsInBackground = (passId: string, anlaeggningsId: string): ThunkAction => (
  dispatch,
  getState
) => {
  return dispatch(fetchPassDetailsInternal(passId, anlaeggningsId, fetchingInBackground));
};
