import { getTranslate } from 'store/global/dictionary';
import NotFound, { NotFoundProps } from './NotFound';
import { RootState } from 'store';
import { connect } from 'react-redux';

const mapStateToProps = (state: RootState): NotFoundProps => ({
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(NotFound);
