import * as React from 'react';

export interface IconDropProps {
  title?: string;
}

export class IconDrop extends React.Component<IconDropProps, {}> {
  render() {
      /* tslint:disable */
    return (
      <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g fill="none" fillRule="evenodd">
          <circle cx="11" cy="11" r="11" fill="#B2B2B2" />
          <path
            d="M10.9285714,5 C8.73060531,7.71701075 7.48303565,9.74088661 7.18586245,11.0716276 C6.49228605,14.1774613 7.73985571,17.5714286 10.9285714,17.5714286 C14.0431589,17.5714286 15.3058031,14.2488235 14.7165041,11.2857143 C14.4486503,9.93889379 13.1860061,7.84365569 10.9285714,5 Z"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    );
  }
}
