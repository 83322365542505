import { Reducer } from 'redux';
import { Action } from 'store';
import { FoereningSwitcherActionTypes } from '.';

export interface FoereningSwitcherState {
  isVisible: boolean;
  isSwitchingFoerening: boolean;
}

const initialState: FoereningSwitcherState = {
  isVisible: false,
  isSwitchingFoerening: false,
};

export const foereningSwitcherReducer: Reducer<FoereningSwitcherState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case FoereningSwitcherActionTypes.TOOGLE_SWITCHER: {
      return {
        ...state,
        isVisible: action.value,
      };
    }
    case FoereningSwitcherActionTypes.IS_SWITCHING: {
      return {
        ...state,
        isSwitchingFoerening: action.value,
      };
    }
    default:
      return state;
  }
};
