import { connect } from 'react-redux';
import { RootState } from 'store';
import {
  SmsEmailVillkor,
  SmsEmailVillkorActionProps,
  SmsEmailVillkorDataProps,
  SmsEmailVillkorPassedProps,
} from './SmsEmailVillkor';
import { getSmsEmailVillkor } from 'store/foereningar';
import { getTranslate } from 'store/global/dictionary';
import { FoereningInternal } from 'store/foereningar/read';
import { getHasLoadFailed, FetchStatusAggregateTypes, getIsFetching, getIsFetched } from 'store/global/fetch';

const mapStateToProps = (state: RootState, props: SmsEmailVillkorPassedProps): SmsEmailVillkorDataProps => {
  const foereningId = state.global.session.shopFoerening
    ? state.global.session.shopFoerening
    : state.global.session.activeFoerening;
  const activeFoerening = getActiveFoerening(state.foereningar.read.Foereningar, foereningId);
  const smsEmailVillkor = getVillkor(state.foereningar.read.Foereningar, foereningId);
  return {
    activeFoerening,
    smsEmailVillkor,
    translate: getTranslate(state.locale),
    smsEmailVillkorNotFound:
      !!activeFoerening &&
      getHasLoadFailed(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR) &&
      !smsEmailVillkor,
    isFetching:
      !!activeFoerening &&
      getIsFetching(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR),
    isFetched:
      !!activeFoerening &&
      (getIsFetched(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR) ||
        getHasLoadFailed(state.global.fetch, activeFoerening.Id, FetchStatusAggregateTypes.SMS_EMAIL_VILLKOR)),
  };
};

const getActiveFoerening = (foereningar: FoereningInternal[], foereningId?: number): FoereningInternal | undefined => {
  if (!foereningId) {
    return;
  }
  return foereningar.find(f => f.Id.toString() === foereningId.toString());
};

const getVillkor = (foereningar: FoereningInternal[], foereningId?: number): string | undefined => {
  const foerening = getActiveFoerening(foereningar, foereningId);

  return foerening ? foerening.SmsEmailVillkor : undefined;
};

const mapDispatchToProps: SmsEmailVillkorActionProps = {
  getSmsEmailVillkor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsEmailVillkor);
