import * as React from 'react';
import { IconMyAccount, IconMyWorkout, IconMenuArrow } from '../icons/index';
import { MainMenuListSwitcher } from './main-menu-list/MainMenuListSwitcher';
import { MainMenuActiveItem } from 'store/global/ui/main-menu';
import { OutsideClickHandler } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface MainMenuActionProps {
  setActiveMenu: (menu: MainMenuActiveItem) => void;
  closeMenu: () => void;
  setSidebarToContainSchedule: () => void;
  setSidebarToVisible: () => void;
  showFoereningSwitcher: () => void;
  setIosDeviceId: (deviceId: string) => void;
  setAndroidDeviceId: (deviceId: string) => void;
  goToSchedule: () => void;
  updatePasslist: (foereningId: number) => void;
  logout: () => void;
}

export interface MainMenuDataProps {
  activeMenu: MainMenuActiveItem;
  translate: TranslateFunction;
  currentFoerening: fos.api.InloggadeFoerening;
  availableFoereningar: fos.api.InloggadeFoerening[];
  newNotificationsCount?: number;
  isDesktopMode: boolean;
  authenticated: boolean;
}

export interface MainMenuState {
  accountMenu?: boolean;
  workoutMenu?: boolean;
}

export type FooterContentProps = MainMenuActionProps & MainMenuDataProps;

export class MainMenu extends React.Component<FooterContentProps, MainMenuState> {
  node: HTMLDivElement | null = null;
  constructor(props: FooterContentProps) {
    super(props);
    this.state = {
      accountMenu: false,
      workoutMenu: false,
    };
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.setAccountMenu = this.setAccountMenu.bind(this);
    this.setWorkoutMenu = this.setWorkoutMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('iosPushNotification', this.iosPushNotification);
    window.addEventListener('androidPushNotification', this.androidPushNotification);
    window.addEventListener('androidCalendars', this.androidCalendars);
    window.addEventListener('iosCalendars', this.iosCalendars);
    window.addEventListener('mobileFocus', this.onFocus);
  }

  iosPushNotification = (data: any) => {
    this.props.setIosDeviceId(data.detail);
  };

  androidPushNotification = (data: any) => {
    this.props.setAndroidDeviceId(data.detail);
  };

  androidCalendars = (data: any) => {
    // tslint:disable-next-line: max-line-length
  };

  iosCalendars = (data: any) => {
    // tslint:disable-next-line: no-console
    // console.log(data.detail);
  };

  onFocus = () => {
    if (this.props.currentFoerening) {
      this.props.updatePasslist(parseInt(this.props.currentFoerening.Id + '', 10));
    }

    if (this.props.authenticated) {
      const intent = localStorage.getItem('intent');
      if (intent === 'schedule') {
        this.props.goToSchedule();
        localStorage.removeItem('intent');
      }
    }
  };

  getIsActive(menu: MainMenuActiveItem): boolean {
    return menu === this.props.activeMenu;
  }

  getActiveStyles(menu: MainMenuActiveItem): boolean {
    return menu === this.props.activeMenu;
  }

  renderMainMenuContainer(menuShowing: MainMenuActiveItem) {
    const menuShouldBeShown = menuShowing && menuShowing !== MainMenuActiveItem.NONE;
    return (
      <div className="MainMenuContainer">
        <MainMenuListSwitcher
          activeMenu={menuShowing}
          show={menuShouldBeShown}
          openSidebarSchedule={this.props.setSidebarToContainSchedule}
          showSidebar={this.props.setSidebarToVisible}
          translate={this.props.translate}
          currentFoerening={this.props.currentFoerening}
          availableFoereningar={this.props.availableFoereningar}
          showFoereningSwitcher={this.props.showFoereningSwitcher}
          newNotificationsCount={this.props.newNotificationsCount}
          isDesktopMode={this.props.isDesktopMode}
          goToSchedule={this.props.goToSchedule}
          setAccountMenu={this.setAccountMenu}
          setWorkoutMenu={this.setWorkoutMenu}
          logout={this.props.logout}
        />
      </div>
    );
  }

  handleOutsideClick() {
    this.props.closeMenu();
    OutsideClickHandler.delete(this.handleOutsideClick);
  }

  setAccountMenu(val: boolean) {
    this.setState({
      accountMenu: val,
      workoutMenu: false,
    });
  }

  setWorkoutMenu(val: boolean) {
    this.setState({
      workoutMenu: val,
      accountMenu: false,
    });
  }

  openMenu(menu: MainMenuActiveItem) {
    OutsideClickHandler.delete(this.handleOutsideClick);
    if (menu !== this.props.activeMenu) {
      this.props.setActiveMenu(menu);
      OutsideClickHandler.register(this.handleOutsideClick);
    } else {
      this.props.closeMenu();
    }
  }

  render() {
    return (
      <div
        className="MainMenu"
        ref={node => {
          this.node = node;
        }}
      >
        {this.renderMainMenuContainer(this.props.activeMenu)}
        <div className={'myAccount full-flex' + (this.state.accountMenu ? ' activeMenu' : '')}>
          <div
            className="myAccount__icon icon"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openMenu(MainMenuActiveItem.MY_ACCOUNT);
            }}
          >
            <IconMyAccount active={this.getActiveStyles(MainMenuActiveItem.MY_ACCOUNT || this.state.accountMenu)} />

            <div
              className={
                'text_for_icon' +
                (this.getActiveStyles(MainMenuActiveItem.MY_ACCOUNT)
                  ? ' text_for_icon--active'
                  : ' text_for_icon--inactive')
              }
            >
              {' '}
              Jag{' '}
            </div>
            {this.getIsActive(MainMenuActiveItem.MY_ACCOUNT) && (
              <div className="arrow">
                <IconMenuArrow />
              </div>
            )}
            {this.myAccountHasNewEvents() && <div className="newEvents" />}
          </div>
        </div>
        <div className={'myWorkout full-flex' + (this.state.workoutMenu ? ' activeMenu' : '')}>
          <div
            className="myWorkout__icon icon"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openMenu(MainMenuActiveItem.MY_WORKOUT);
            }}
          >
            <IconMyWorkout active={this.getActiveStyles(MainMenuActiveItem.MY_WORKOUT || this.state.workoutMenu)} />

            <div
              className={
                'text_for_icon' +
                (this.getActiveStyles(MainMenuActiveItem.MY_WORKOUT)
                  ? ' text_for_icon--active'
                  : ' text_for_icon--inactive')
              }
            >
              {' '}
              Friskis{' '}
            </div>

            {this.getIsActive(MainMenuActiveItem.MY_WORKOUT) && (
              <div className="arrow">
                <IconMenuArrow />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private myAccountHasNewEvents() {
    return !!this.props.newNotificationsCount && this.props.newNotificationsCount > 0;
  }
}
