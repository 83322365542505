import { api } from 'api';
import { ThunkAction, Dispatch, Action } from 'store';
import { updateUser } from 'store/user/updateUserProfileCmd';
import { getUser, getBetalningsmedel } from 'store/user/read';
import { fetching, FetchStatusAggregateTypes, fetched, fetchedFailed } from 'store/global/fetch';
import 'moment/locale/sv';
import 'moment/locale/nb';
import moment from 'moment';
import {
  updateFirstName,
  updateLastName,
  updateZipCode,
  updateStreet,
  updateCity,
  UpdateLookupStatus,
} from './createUserCmd';
import { deleteCookie, addCookie } from 'websd/utils/cookie';
import { readToken, localStorage } from 'utils';
import { setFoereningExtraInfo } from 'store/global/session';
import { setInloggadeFoereningarInStorage } from 'store/auth';
import { BetalningsmedelTypes } from '.';
import { updateAutogiroAccountNumber, updateAutoGiroClearingNumber } from 'store/basket';

export const getProfileInfo = (FoereningId: number) => (dispatch: Dispatch<Action>) => {
  const promise = api.get<fos.api.User>('ProfileInfo/Get?foereningId=' + FoereningId, {
    muteErrorNotificationOnStatus: [406],
  });
  dispatch(fetching(FoereningId, FetchStatusAggregateTypes.ANVAENDARE));
  promise.then(result => {
    if (!result.error) {
      let user: fos.api.User = result.result;
      dispatch(updateUser(user));
      dispatch(getUser(user));
      dispatch(fetched(FoereningId, FetchStatusAggregateTypes.ANVAENDARE, user));

      const authCookie: fos.api.TokenModel | null = readToken();
      if (!!authCookie) {
        const value =
          user.Email +
          ',' +
          authCookie.FoereningarLista.map(f => f.Namn + '(' + f.OnlinetraeningStatus + ')').join(',');
        deleteCookie('LID', '/', process.env.REACT_APP_FOS_DOMAIN);
        addCookie('LID', value, '/', process.env.REACT_APP_FOS_DOMAIN, moment(authCookie.Giltighetstid).toDate());
      }
    } else {
      dispatch(fetchedFailed(FoereningId, FetchStatusAggregateTypes.ANVAENDARE));
    }
  });
  return promise;
};

export const getRegistreradeBetalningsmedel =
  (FoereningId: number): ThunkAction =>
  (dispatch: Dispatch<Action>) => {
    const promise = api.get<fos.api.Betalningsmedel>(`Betalningsmedel//Get/${FoereningId}`, {
      // muteErrorNotificationOnStatus: [406]
    });
    dispatch(fetching(FoereningId, FetchStatusAggregateTypes.REGISTRERADE_BETALNINGS_MEDEL));
    promise.then(result => {
      if (!result.error) {
        let betalningsmedel: fos.api.Betalningsmedel = result.result;
        dispatch(getBetalningsmedel(betalningsmedel));
        dispatch(fetched(FoereningId, FetchStatusAggregateTypes.REGISTRERADE_BETALNINGS_MEDEL, result));
        if (betalningsmedel && betalningsmedel.AutogiroLista) {
          const autoGiroInfo = betalningsmedel.AutogiroLista.find(
            item => item.BetalningsmedelType === BetalningsmedelTypes.Autogiro
          );
          if (!!autoGiroInfo) {
            dispatch(updateAutogiroAccountNumber(autoGiroInfo.BankAccountNumber));
            dispatch(updateAutoGiroClearingNumber(autoGiroInfo.ClearingNumber));
          }
        }
      } else {
        dispatch(fetchedFailed(FoereningId, FetchStatusAggregateTypes.REGISTRERADE_BETALNINGS_MEDEL));
      }
    });
    return promise;
  };

export const getExtraFoereningInformation =
  (foereningName: string): ThunkAction =>
  dispatch => {
    const promise = api.get<fos.api.ExtraFoereningInfo>(`Foerening/GetExtraInfo?foerening=${foereningName}`, {
      anonymousCall: true,
    });
    promise.then(result => {
      if (!result.error) {
        dispatch(setFoereningExtraInfo(result.result, foereningName));
        const inloggadeFoereningar = localStorage.getItem<fos.api.InloggadeFoerening[]>('INLOGGADE_FOERENING');
        if (!!inloggadeFoereningar && !!inloggadeFoereningar.value) {
          const f = inloggadeFoereningar.value.find(fo => fo.Namn === foereningName);
          if (!!f) {
            f.ExtraFoereningInfo = result.result;
            setInloggadeFoereningarInStorage(inloggadeFoereningar.value);
          }
        }
      }
    });
    return promise;
  };

export const getPersonLookupAvailable = (foereningId: number) => (dispatch: Dispatch<Action>) => {
  const promise = api.get<boolean>('Anvaendare/GetPersonLookupAvailable?foereningId=' + foereningId);
  dispatch(fetching(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP_AVAILABLE));
  promise.then(result => {
    if (!result.error) {
      dispatch(UpdateLookupStatus(result.result ? 1 : 0));
      dispatch(fetched(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP_AVAILABLE, result.result));
    } else {
      dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP_AVAILABLE));
    }
  });
  return promise;
};

export const getPersonLookup = (foereningId: number, ssn: string) => (dispatch: Dispatch<Action>) => {
  dispatch(UpdateLookupStatus(1));
  const promise = api.get<fos.api.PersonLookup>(`Anvaendare/GetPersonLookup?foereningId=${foereningId}&ssn=${ssn}`);
  dispatch(fetching(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP));
  promise.then(result => {
    if (!result.error) {
      dispatch(UpdateLookupStatus(result.result.Status));
      if (!!result.result.Data) {
        dispatch(updateFirstName(result.result.Data.Foernamn));
        dispatch(updateLastName(result.result.Data.Efternamn));
        dispatch(updateZipCode(result.result.Data.Postnummer));
        dispatch(updateStreet(result.result.Data.Gata));
        dispatch(updateCity(result.result.Data.Stad));
      }
      dispatch(fetched(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP, result.result));
    } else {
      dispatch(fetchedFailed(foereningId, FetchStatusAggregateTypes.GET_PERSON_LOOKUP));
    }
  });
  return promise;
};
