// Uses react-youtube: https://github.com/troybetz/react-youtube

import * as React from 'react';
import YouTube from 'react-youtube';
import { IconPlay } from 'components/icons';
import { Header } from './Header';
import { Tickers } from './Tickers';

type youtubeEventButton = Event & { target: { playVideo: () => void } };

interface YouTubeVideoProps {
  videoId: string;
  placeholderUrl: string;
  imageUrl?: string;
  buttonText?: string;
  header?: string;
  tickers: Array<string>;
}

interface YouTubeVideoState {
  videoStarted: boolean;
  videoPaused: boolean;
}

export class YouTubeVideo extends React.Component<YouTubeVideoProps, YouTubeVideoState> {
  PlayButton: HTMLElement | null;

  constructor(props: YouTubeVideoProps) {
    super(props);

    this.PlayButton = null;

    this.state = {
      videoStarted: false,
      videoPaused: false,
    };
  }

  playVideo() {
    this.setState({
      videoStarted: true,
      videoPaused: false,
    });
  }

  stopVideo() {
    this.setState({
      videoStarted: false,
      videoPaused: false,
    });
  }

  pauseVideo() {
    this.setState({
      videoPaused: true,
    });
  }

  render() {
    const {
      videoId,
      // placeholderUrl,
      buttonText,
    } = this.props;
    // TODO style={{ backgroundImage: `url('${placeholderUrl}'` }}
    return (
      <div
        className={
          'YouTubeVideo ' + (this.state.videoStarted ? 'playing' : '') + (this.state.videoPaused ? 'paused' : '')
        }
      >
        <div className="YouTubeVideo__Placeholder" style={{ backgroundImage: `url('${this.props.imageUrl}'` }}>
          <Header header={this.props.header} />
          <Tickers tickers={this.props.tickers} />
          <div
            id="#PlayButton"
            className="PlayButton"
            ref={input => {
              this.PlayButton = input;
            }}
            onClick={() => this.playVideo()}
          >
            <div className="PlayButton__Icon">
              <IconPlay />
            </div>
            {buttonText && <div className="PlayButton__Text">{buttonText}</div>}
          </div>
        </div>
        <YouTube
          videoId={videoId}
          opts={{
            // https://developers.google.com/youtube/player_parameters
            playerVars: {
              controls: 1,
              disablekb: 0,
              autoplay: 0,
              fs: 1,
              iv_load_policy: 3,
              modestbranding: 1,
              playsinline: 1,
              rel: 0,
              showinfo: 0,
            },
          }}
          onReady={event => this.bindPlayButtonClick(event as youtubeEventButton)}
          onEnd={() => this.stopVideo()}
          onPause={() => this.pauseVideo()}
          onPlay={() => this.playVideo()}
        />
      </div>
    );
  }

  bindPlayButtonClick(event: youtubeEventButton) {
    if (this.PlayButton != null && event && event.target) {
      this.PlayButton.onclick = () => event && event.target && event.target.playVideo();
    }
  }
}
