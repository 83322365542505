import { Reducer } from 'redux';
import { Action } from 'store';
import { SessionActionTypes, SessionState } from '.';

const initialState: SessionState = {
  inloggadeFoereningar: [],
  // shouldShowCalendarSync: false
};

export const sessionReducer: Reducer<SessionState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case SessionActionTypes.ClearActiveFoerening:
      return {
        ...state,
        activeFoerening: undefined,
      };

    case SessionActionTypes.SetActiveFoerening:
      return {
        ...state,
        activeFoerening: action.foereningId,
      };

    case SessionActionTypes.SetShopFoerening:
      return {
        ...state,
        shopFoerening: action.foereningId,
      };

    case SessionActionTypes.SetInloggadeFoereningar:
      return {
        ...state,
        inloggadeFoereningar: action.inloggadeFoereningar,
      };
    case SessionActionTypes.SetFoereningExtraInfo:
      // @Speed
      const arr = JSON.parse(JSON.stringify(state.inloggadeFoereningar)) as fos.api.InloggadeFoerening[];
      const foe = arr.findIndex(f => f.Namn === action.foereningName);
      if (foe >= 0) {
        arr[foe].ExtraFoereningInfo = action.extraInfo;
      }
      return {
        ...state,
        inloggadeFoereningar: arr,
      };
    case SessionActionTypes.SetUserId:
      return {
        ...state,
        userId: action.userId,
      };
    case SessionActionTypes.ShouldAcceptBookingRules:
      return {
        ...state,
        shouldAcceptBookingRules: action.value,
      };
    case SessionActionTypes.SetPassIdToBook:
      return {
        ...state,
        passToBook: action.value,
      };
    case SessionActionTypes.SetOrderId:
      return {
        ...state,
        orderId: action.orderId,
      };
    default:
      return state;
  }
};
