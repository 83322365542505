import * as React from 'react';
import CountUp from 'react-countup';

interface SideBySideStatisticsProps {
  dataValue1: number;
  dataValue2: number;
  mainTitle: string;
  title1: string;
  title2: string;
}

export class SideBySideStatistics extends React.Component<SideBySideStatisticsProps> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className="SideBySideStatistics">
        <h3 className="SideBySideStatistics__MainTitle h5">{this.props.mainTitle}</h3>

        <div className="SideBySideStatistics__DataContainer">
          <div className="DataContainer__First">
            <div className="First__Data">
              <CountUp start={0} end={this.props.dataValue1} duration={2.75} useEasing={true} />
            </div>
            <div className="First__Title">{this.props.title1}</div>
          </div>

          <div className="DataContainer__Divider" />

          <div className="DataContainer__Second">
            <div className="Second__Data">
              <CountUp start={0} end={this.props.dataValue2} duration={2.75} useEasing={true} />
            </div>
            <div className="Second__Title">{this.props.title2}</div>
          </div>
        </div>
      </div>
    );
  }
}
