import { HeaderIconEnum } from './headerReducer';

export enum HeaderActionTypes {
  SET_ICONS = 'SET_ICONS',
  RESET_ICONS = 'RESET_ICONS',
}

interface SetIconsAction {
  type: HeaderActionTypes.SET_ICONS;
  leftDesktopIcon?: HeaderIconEnum;
  rightDesktopIcon?: HeaderIconEnum;
  leftMobileIcon?: HeaderIconEnum;
  rightMobileIcon?: HeaderIconEnum;
}
interface ResetIconsAction {
  type: HeaderActionTypes.RESET_ICONS;
}

export const setHeaderIcons = (
  leftDesktopIcon?: HeaderIconEnum,
  rightDesktopIcon?: HeaderIconEnum,
  leftMobileIcon?: HeaderIconEnum,
  rightMobileIcon?: HeaderIconEnum
): SetIconsAction => ({
  type: HeaderActionTypes.SET_ICONS,
  leftDesktopIcon,
  rightDesktopIcon,
  leftMobileIcon,
  rightMobileIcon,
});

export const resetHeaderIcons = (): ResetIconsAction => ({
  type: HeaderActionTypes.RESET_ICONS,
});

export type HeaderAction = SetIconsAction | ResetIconsAction;
