import * as React from 'react';

export interface IconBigCloseProps {
  readonly className?: string;
}

export const IconBigClose: React.SFC<IconBigCloseProps> = props => {
  const styles = `	`;
  return (
    <svg x="0px" y="0px" viewBox="0 0 55 55" className={props.className ? props.className : 'IconBigClose'}>
      <style type="text/css">{styles}</style>
      <g id="Top-Navigation-Close" transform="translate(-23.000000, -15.000000)" className="st0">
        <circle id="Oval-3" className="st1" cx="50.5" cy="42.5" r="26.5" />
      </g>
      <g id="Top-Navigation-Close_1_" transform="translate(-39.000000, -31.000000)" className="st0">
        <g id="icon-cross" transform="translate(40.000000, 32.000000)">
          <path id="Shape" className="st2" d="M15.8,16.3l21.4,21.5" />
          <path id="Shape_1_" className="st2" d="M37.2,16.3L15.8,37.7" />
        </g>
      </g>
    </svg>
  );
};
