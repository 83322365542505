import { PassDetailsReadActionType } from './passDetailsReadActions';
import { UIScheduleActionType } from '../../global/ui/schedule/uiScheduleActions';
import { Action } from 'store';
import { PassActionType } from 'store/pass';

export interface PassDetailsReadState {
  pass?: fos.api.PassDetails;
}

const initialState: PassDetailsReadState = {
  pass: undefined,
};

export const readReducer = (state: PassDetailsReadState = initialState, action: Action): PassDetailsReadState => {
  switch (action.type) {
    case PassDetailsReadActionType.PassDetails_UpdatePassDetails: {
      return { ...state, pass: action.passDetails };
    }
    case UIScheduleActionType.SetSelectedPass: {
      if (!action.selectedPass || !state.pass || state.pass.PassId !== action.selectedPass.PassId) {
        return { ...state, pass: { ...state.pass, ...action.selectedPass } as fos.api.PassDetails | undefined };
      } else {
        return state;
      }
    }
    case PassActionType.UpdateBokaPassStatus: {
      if (state.pass) {
        if (action.passId === state.pass.PassId) {
          return {
            ...state,
            pass: {
              ...state.pass,
              statusMessage: action.message,
            },
          };
        }
      }
      return state;
    }
    default:
      return state;
  }
};
