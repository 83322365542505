import { Reducer } from 'redux';
import { Action } from 'store';
import { UIScheduleState } from '../schedule/uiScheduleReducer';

export enum SidebarStatus {
  'HIDDEN' = 'HIDDEN',
  'SHOWING' = 'SHOWING',
}

export enum SidebarContentType {
  'SCHEDULE' = 'SCHEDULE',
}

export enum SidebarSize {
  'MINIMISED' = 'MINIMISED',
  'MAXIMISED' = 'MAXIMISED',
}

export interface SideBarState {
  visibility: SidebarStatus;
  contentType: SidebarContentType | undefined | null;
  size: SidebarSize;
}

const initialState: SideBarState = {
  visibility: SidebarStatus.HIDDEN,
  contentType: SidebarContentType.SCHEDULE,
  size: SidebarSize.MINIMISED,
};

export const getIsSidebarShowing = (sideBarState: SideBarState): boolean => {
  if (sideBarState) {
    return sideBarState.visibility === SidebarStatus.SHOWING;
  }
  return false;
};
export const getIsSidebarMaximised = (sideBarState: SideBarState): boolean => {
  if (sideBarState) {
    return sideBarState.size === SidebarSize.MAXIMISED;
  }
  return false;
};
export const showSideBarInFullDesktopWidth = (
  sideBarState: SideBarState,
  scheduleUIState: UIScheduleState
): boolean => {
  if (sideBarState) {
    // return getIsSidebarMaximised(sideBarState) || getScheduleHasWeekView(scheduleUIState);
    return getIsSidebarMaximised(sideBarState);
  }
  return false;
};

export const sidebarReducer: Reducer<SideBarState> = (state = initialState, action: Action) => {
  // console.info('sidebarReducer state:', state);
  // console.info('sidebarReducer action:', action);
  switch (action.type) {
    case 'UI_SIDEBAR_LAUNCH': {
      return { ...state, visibility: SidebarStatus.SHOWING };
    }
    case 'UI_SIDEBAR_HIDE': {
      return { ...state, visibility: SidebarStatus.HIDDEN };
    }
    case 'UI_SIDEBAR_SET_CONTENT_TYPE': {
      return { ...state, contentType: action.contentType };
    }
    case 'UI_SIDEBAR_SET_SIZE_MINIMISED': {
      return { ...state, size: SidebarSize.MINIMISED };
    }
    case 'UI_SIDEBAR_SET_SIZE_MAXIMISED': {
      return { ...state, size: SidebarSize.MAXIMISED };
    }
    default:
      return state;
  }
};
