import * as React from 'react';

import { DetailsBlockDesktop } from './DetailsBlockDesktop';
import { PassDetailsHeroBanner } from './PassDetailsHeroBanner';
import { PassDetailsContent } from './PassDetailsContent';
import { PassDetailsSkeleton } from './pass-details-skeletons';
import { PassDetailsFailedFetch } from './PassDetailsFailedFetch';
import { TranslateFunction } from 'react-localize-redux';

export interface PassDetailsInternalDataProps {
  pass?: fos.api.PassDetails;
  translate: TranslateFunction;
  passId?: string;
  anlaeggningsId?: string;
  fetchFailed: boolean;
  isFetching: boolean;
  bokningar: fos.api.Bokning[];
}

export interface PassDetailsExternalDataProps {
  isDesktopMode: boolean;
}

export interface PassDetailsActionProps {
  fetchPassDetails: (passId: string, anlaeggningsId: string) => void;
  getBookingsInBackground: () => void;
}

export type PassDetailsDataProps = PassDetailsInternalDataProps & PassDetailsExternalDataProps;
type PassDetailsProps = PassDetailsDataProps & PassDetailsActionProps;

export const PassDetails: React.FunctionComponent<PassDetailsProps> = ({
  pass,
  translate,
  passId,
  anlaeggningsId,
  fetchFailed,
  isFetching,
  bokningar,
  isDesktopMode,
  fetchPassDetails,
  getBookingsInBackground,
}) => {
  React.useEffect(() => {
    if (!!passId && !!anlaeggningsId && (!pass || '' + pass.PassId !== '' + passId)) {
      fetchPassDetails(passId, anlaeggningsId);
      getBookingsInBackground();
    }
  }, [passId, anlaeggningsId, fetchPassDetails, getBookingsInBackground, pass]);

  if (fetchFailed) {
    return <PassDetailsFailedFetch translate={translate} />;
  }
  if (isFetching || !pass) {
    return <PassDetailsSkeleton isDesktop={isDesktopMode} />;
  }
  return (
    <div className={'PassDetails ' + (isDesktopMode ? 'PassDetails--isDesktopMode' : '')}>
      <PassDetailsHeroBanner translate={translate} isDesktopMode={isDesktopMode} pass={pass} />
      {isDesktopMode && <DetailsBlockDesktop translate={translate} pass={pass} />}
      <PassDetailsContent translate={translate} isDesktopMode={isDesktopMode} pass={pass} bokningar={bokningar} />
    </div>
  );
};
