import { initialCommand } from './../../../store/global/fetch/fetchActions';
import { connect } from 'react-redux';
import { RootState, Dispatch, Action } from 'store';
import { getTranslate } from 'store/global/dictionary';
import { UpdateUserProfileCmdActionTypes } from 'store/user/updateUserProfileCmd';
import { ProfilePropertyNameValues } from 'store/global/ui/settings/profile/uiSettingsProfileModels';
import { Profile, ProfileDataProps, ProfileActionProps } from './Profile';
import { UISettingsProfileUnderEdit, uiSettingsProfileActionType } from 'store/global/ui/settings/profile';
import {
  UpdateUISettingsProfileClickAction,
  UpdateUISettingsProfileAction,
} from 'store/global/ui/settings/profile/uiSettingsProfileActions';
import { getProfileInfo } from 'store/user/thunks';
import { updateUserProfileCmd } from 'store/user/updateUserProfileCmd';
import { getIsExecuting, FetchCommandTypes, getIsExecuted, getIsExecutingFailed } from 'store/global/fetch';

const getCmdId = (activeFoerening: number, propertyName: string) => `${activeFoerening}/${propertyName}`;

const onAcceptField = (uiField: UISettingsProfileUnderEdit, editedUser: fos.api.User) => (
  dispatch: Dispatch<Action>
) => {
  dispatch({
    type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit,
    uiSettingsProfileUnderEdit: uiField,
  });
};

const onEditField = (uiField: UISettingsProfileUnderEdit) => (dispatch: Dispatch<UpdateUISettingsProfileAction>) => {
  dispatch({
    type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit,
    uiSettingsProfileUnderEdit: uiField,
  });
};

const onTextBoxClick = (uiField: ProfilePropertyNameValues) => (
  dispatch: Dispatch<UpdateUISettingsProfileClickAction>
) => {
  dispatch({
    type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderClick,
    propertyFieldUnderClick: uiField,
  });
};

const onRevertField = (uiField: UISettingsProfileUnderEdit, unchangedUser: fos.api.User) => (
  dispatch: Dispatch<Action>
) => {
  dispatch({
    type: uiSettingsProfileActionType.UpdateUISettingsProfileUnderEdit,
    uiSettingsProfileUnderEdit: uiField,
  });
  dispatch({
    type: UpdateUserProfileCmdActionTypes.UpdateUser,
    user: unchangedUser,
  });
};

const onUserPropertyChange = (editedUser: fos.api.User) => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: UpdateUserProfileCmdActionTypes.UpdateUser,
    user: editedUser,
  });
};

const mapStateToProps = (state: RootState, props: any): ProfileDataProps => {
  return {
    editableUser: state.user.write.user,
    unchangedUser: state.user.read.user,
    uiSettingsProfileState: state.global.ui.uiSettingsProfileState.uiProfileSettings,
    inloggadFoereningId: state.global.session.activeFoerening,
    translate: getTranslate(state.locale),
    cmdErrorCode: state.user.write.errorCode,
    isDesktopMode: props.isDesktopMode,
    getIsExecutingForPropertyName: propertyName =>
      getIsExecuting(
        state.global.fetch,
        getCmdId(state.global.session.activeFoerening as number, propertyName),
        FetchCommandTypes.SPARA_ANVAENDARE
      ),
    getIsExecutedForPropertyName: propertyName =>
      getIsExecuted(
        state.global.fetch,
        getCmdId(state.global.session.activeFoerening as number, propertyName),
        FetchCommandTypes.SPARA_ANVAENDARE
      ),
    getIsExecutingFailedForPropertyName: propertyName =>
      getIsExecutingFailed(
        state.global.fetch,
        getCmdId(state.global.session.activeFoerening as number, propertyName),
        FetchCommandTypes.SPARA_ANVAENDARE
      ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): ProfileActionProps => ({
  onUserPropertyChange: editedUser => dispatch(onUserPropertyChange(editedUser)),
  onEditField: uiField => dispatch(onEditField(uiField)),
  onRevertField: (uiField, unchangedUser) => dispatch(onRevertField(uiField, unchangedUser)),
  onAcceptField: (uiField, editedUser) => dispatch(onAcceptField(uiField, editedUser)),
  onTextBoxClick: uiField => dispatch(onTextBoxClick(uiField)),
  getProfileInfo: foereningId => dispatch(getProfileInfo(foereningId)),
  updateUserProfileCmd: (foereningId, user, propertyName) =>
    dispatch(updateUserProfileCmd(foereningId, user, propertyName)),
  initializeUserProfileCmd: (foereningId, propertyName) =>
    dispatch(initialCommand(getCmdId(foereningId, propertyName), FetchCommandTypes.SPARA_ANVAENDARE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
