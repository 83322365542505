import { Reducer } from 'redux';
import { Action } from 'store';
import { ActionType } from './debugActions';

export type DebugState = Readonly<boolean>;

export const debugReducer: Reducer<DebugState> = (state = false, action: Action) => {
  if (action.type === ActionType.errorFetch) {
    return true;
  }

  return false;
};

export const getIsWaitingForError = (state: DebugState) => state;
