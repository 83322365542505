export enum ActionType {
  GetNotifications = 'GET_NOTIFICATIONS',
}

export interface GetNotificationsAction {
  type: ActionType.GetNotifications;
  notifications: Array<fos.api.Notification>;
}

export const getNotifications = (notifications: Array<fos.api.Notification>): GetNotificationsAction => ({
  type: ActionType.GetNotifications,
  notifications,
});

export type NotificationsActions = GetNotificationsAction;
