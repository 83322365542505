interface GetPushNotificationSettingsAction {
  type: PushNotificationsActionType.GetPushNotificationSettings;
  settings: fos.api.PushNotificationSettings;
}

export enum PushNotificationsActionType {
  GetPushNotificationSettings = 'GET_PUSH_NOTIFICATION_SETTINGS',
}

export const getPushNotificationSettings = (
  settings: fos.api.PushNotificationSettings
): GetPushNotificationSettingsAction => ({
  type: PushNotificationsActionType.GetPushNotificationSettings,
  settings,
});

export type PushNotificationSettingsActions = GetPushNotificationSettingsAction;
