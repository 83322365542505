import * as React from 'react';

export interface IconMenuCalendarSyncProps {
  active: boolean;
  color?: string;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconMenuCalendarSync extends React.Component<IconMenuCalendarSyncProps, {}> {
  activeStyle = `stroke: ${
    this.props.color ? this.props.color : '#8B8F92'
  };fill:none;stroke-linecap:round;stroke-linejoin:round;`;
  defaultStyle = 'stroke: #e31836;fill:none;stroke-linecap:round;stroke-linejoin:round;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  render() {
    // TODO: Fix translation support for text
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" className="IconMenuCalendarSync">
        <title>{this.props.title}</title>
        <style type="text/css">
          .IconMenuCalendarSync .inactive{'{' + this.defaultStyle + '}'}
          .IconMenuCalendarSync .active{'{' + this.activeStyle + '}'}
          .IconMenuCalendarSync .inactive{'{' + this.activeStyle + '}'}
        </style>
        {/* tslint:disable */}
        <g>
          <g>
            <path
              className={this.getClassForIcon()}
              d="M21.986,27.637H6.46c-3.292,0-5.96-2.669-5.96-5.96V6.46c0-3.292,2.669-5.96,5.96-5.96h15.526
              c3.292,0,5.96,2.669,5.96,5.96v15.216C27.947,24.968,25.278,27.637,21.986,27.637z"
            />
            <line className={this.getClassForIcon()} x1="0.5" y1="8.418" x2="27.947" y2="8.418" />
          </g>
          <path
            className={this.getClassForIcon()}
            d="M16.497,13.165c1.866,0.861,3.161,2.748,3.161,4.937c0,2.192-1.297,4.08-3.166,4.94"
          />
          <path
            className={this.getClassForIcon()}
            d="M11.965,23.046c-1.874-0.857-3.175-2.749-3.175-4.944c0-2.197,1.304-4.089,3.179-4.946"
          />
          <polyline className={this.getClassForIcon()} points="16.891,12.32 15.618,12.884 16.277,14.183 		" />
          <polyline className={this.getClassForIcon()} points="11.248,23.761 12.563,23.302 12.01,21.954 		" />
        </g>
      </svg>
    );
  }
}
