import { store } from './../index';
import { getTranslate } from 'store/global/dictionary/dictionary';
import { SitecoreDictionary } from 'models';
export enum SPECIAL_INTRESSE_TAGS {
  'Children 0-5',
  'Children 13-18',
  'Children 6-12',
  'Beginner',
  'Easy',
  'Hard',
  'Medium',
}

export const TranslateIfSpecialIntresse = (str: string): string => {
  const translate = getTranslate(store.getState().locale);

  if (str in SPECIAL_INTRESSE_TAGS) {
    return translate(SitecoreDictionary.TrainingPreferences.Preferences + str) as string;
  }
  return str;
};
