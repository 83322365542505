import * as React from 'react';
import { IconPlus } from '../../icons/index';
import { AnlaeggningarList } from './AnlaeggningarList';
import { PreferenceList } from './PreferenceList';
import { AnvaendarePreferenceList } from './AnvaendarePreferenceList';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface TrainingPreferencesDataProps {
  user: fos.api.User;
  activeFoerning?: fos.api.InloggadeFoerening;
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
  workoutLevels: Array<string>;
  childrenAgeGroups: Array<string>;
  savedSpecialintressen: Array<fos.api.SavedSpecialintresse>;
  savedAnlaeggningar: Array<fos.api.SavedSpecialintresse>;
  translate: TranslateFunction;
}

export interface TrainingPreferencesActionProps {
  removeChildAgeGroup: (name: string) => void;
  addChildAgeGroup: (name: string) => void;
  removeWorkoutLevel: (preference: string) => void;
  addWorkoutLevel: (preference: string) => void;
  getSitecoreAnlaeggningar: (foereningName: string, foereningId: number, countryId: number) => void;
  getSpecialIntressen: () => void;
  saveSpecialIntressen: () => void;
  removeSpecialintressen: () => void;
  getSavedSpecialIntressen: () => void;
  removeSavedSpecialintresse: (Specialintresse: fos.api.SavedSpecialintresse) => void;
  removeFavoriteAnlaeggningar: () => void;
  removeFavoriteAnlaeggning: (anlaeggning: string) => void;
  addFavoriteAnlaeggning: (anlaeggning: string) => void;
  saveFavoriteAnlaeggningar: () => void;
  getSavedAnlaeggningar: () => void;
  removeSavedFavoriteAnlaeggning: (Specialintresse: fos.api.SavedSpecialintresse) => void;
}

interface TrainingPreferencesState {
  isAnlaeggningarOverlayOpen: boolean;
  isSpecialIntresenOverlayOpen: boolean;
}

type TrainingPreferencesProps = TrainingPreferencesDataProps & TrainingPreferencesActionProps;

export class TrainingPreferences extends React.Component<TrainingPreferencesProps, TrainingPreferencesState> {
  constructor(props: TrainingPreferencesProps) {
    super(props);

    this.state = {
      isAnlaeggningarOverlayOpen: false,
      isSpecialIntresenOverlayOpen: false,
    };

    this.toggleIsAnlaeggnignarOverlayOpen = this.toggleIsAnlaeggnignarOverlayOpen.bind(this);
    this.toggleIsSpecialIntressenOverlayOpen = this.toggleIsSpecialIntressenOverlayOpen.bind(this);
    this.closeSpecialIntressenOverlayOpen = this.closeSpecialIntressenOverlayOpen.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getSpecialIntressen();
    this.props.getSavedSpecialIntressen();
    this.props.getSavedAnlaeggningar();
    if (this.props.activeFoerning) {
      this.props.getSitecoreAnlaeggningar(
        this.props.activeFoerning.Namn,
        this.props.activeFoerning.Id,
        this.props.activeFoerning.CountryId
      );
    }
  }

  render() {
    return (
      <div className="TrainingPreferences">
        {this.state.isAnlaeggningarOverlayOpen && (
          <AnlaeggningarList
            translate={this.props.translate}
            close={this.toggleIsAnlaeggnignarOverlayOpen}
            preferences={this.props.anlaeggningar.map(i => i.Name)}
            savedFavoriteAnlaeggningar={this.props.savedAnlaeggningar.map(i => i.SpecialintresseName)}
            removeFavoriteAnlaeggning={this.props.removeFavoriteAnlaeggning}
            addFavoriteAnlaeggning={this.props.addFavoriteAnlaeggning}
            saveFavoriteAnlaeggningar={this.props.saveFavoriteAnlaeggningar}
          />
        )}
        {this.state.isSpecialIntresenOverlayOpen && (
          <PreferenceList
            translate={this.props.translate}
            addWorkoutLevel={this.props.addWorkoutLevel}
            removeWorkoutLevel={this.props.removeWorkoutLevel}
            addChildAgeGroup={this.props.addChildAgeGroup}
            removeChildAgeGroup={this.props.removeChildAgeGroup}
            close={this.closeSpecialIntressenOverlayOpen}
            childrenAgeGroups={this.props.childrenAgeGroups}
            workoutLevels={this.props.workoutLevels}
            saveSpecialIntressen={this.props.saveSpecialIntressen}
            savedItems={this.props.savedSpecialintressen.map(i => i.SpecialintresseName)}
          />
        )}

        <div className="TrainingPreferences__Traeningsstaellen">
          <div className="Traeningsstaellen__Information">
            <div className="Title">{this.props.translate(SitecoreDictionary.TrainingPreferences.Facilities)}</div>
            <div className="Summary">{this.props.translate(SitecoreDictionary.TrainingPreferences.FacilitiesText)}</div>
          </div>
          <div className="Traeningsstaellen__List">
            <AnvaendarePreferenceList
              items={this.props.savedAnlaeggningar}
              translate={this.props.translate}
              removeItem={this.props.removeSavedFavoriteAnlaeggning}
              shouldTranslate={false}
            />

            <div className="List__Add row" onClick={() => this.toggleIsAnlaeggnignarOverlayOpen()}>
              <div className="text">{this.props.translate(SitecoreDictionary.TrainingPreferences.Add)}</div>
              <div className="icon">
                <IconPlus />
              </div>
            </div>
          </div>
        </div>
        <div className="TrainingPreferences__Specialintressen">
          <div className="Specialintressen__Information">
            <div className="Title">{this.props.translate(SitecoreDictionary.TrainingPreferences.SpecialInterests)}</div>
            <div className="Summary">
              {this.props.translate(SitecoreDictionary.TrainingPreferences.SpecialInterestsText)}
            </div>
          </div>
          <div className="Specialintressen__List">
            <AnvaendarePreferenceList
              items={this.props.savedSpecialintressen}
              translate={this.props.translate}
              removeItem={this.props.removeSavedSpecialintresse}
              shouldTranslate={true}
            />

            <div className="List__Add row" onClick={() => this.toggleIsSpecialIntressenOverlayOpen()}>
              <div className="text">{this.props.translate(SitecoreDictionary.TrainingPreferences.Add)}</div>
              <div className="icon">
                <IconPlus />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private toggleIsAnlaeggnignarOverlayOpen() {
    this.props.removeFavoriteAnlaeggningar();
    this.setState({ isAnlaeggningarOverlayOpen: !this.state.isAnlaeggningarOverlayOpen });
  }

  private closeSpecialIntressenOverlayOpen() {
    this.props.removeSpecialintressen();
    this.setState({ isSpecialIntresenOverlayOpen: !this.state.isSpecialIntresenOverlayOpen });
  }

  private toggleIsSpecialIntressenOverlayOpen() {
    this.setState({ isSpecialIntresenOverlayOpen: !this.state.isSpecialIntresenOverlayOpen });
  }
}
