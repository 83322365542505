import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { formatDate } from 'websd/utils';
import { TranslateFunction } from 'react-localize-redux';

interface SubscriptionDataProps {
  data?: fos.api.Medlemskap;
  translate: TranslateFunction;
  hideRenewButton?: boolean;
  shopActive?: boolean;
}

interface SubscriptionActionProps {
  buy?: (item: fos.api.Medlemskap) => void;
}

type SubscriptionProps = SubscriptionDataProps & SubscriptionActionProps;

export const Subscription: React.SFC<Readonly<SubscriptionProps>> = props => {
  const isAktiv = () => props.data && props.data.IsAktiv;

  const shouldWarn = () => !isAktiv(); // TODO ouyo Check the warn conditions

  const getSubscriptionDetailsText = (): string => {
    return isAktiv() && props.data
      ? `${props.translate(SitecoreDictionary.SubscriptionsAndCards.Valid) as string} ${formatDate(
          props.data.Startdatum as Date
        )} - ${formatDate(props.data.Slutdatum as Date)}`
      : (props.translate(SitecoreDictionary.SubscriptionsAndCards.MembershipHasExpired) as string);
  };

  if (props.data) {
    return (
      <div className="SubscriptionsAndTrainingCard__Row" key={props.data.Id}>
        <div className="SubscriptionsAndTrainingCard__Row__Info">
          <p className={'SubscriptionsAndTrainingCard__Row__Title'}>{props.data.Namn}</p>
          <pre className={'SubscriptionsAndTrainingCard__Row__Details' + (shouldWarn() ? ' Warning' : '')}>
            {getSubscriptionDetailsText()}
          </pre>
        </div>
      </div>
    );
  } else {
    return (
      <div className="SubscriptionsAndTrainingCard__Row Skeleton">
        <div className="SubscriptionsAndTrainingCard__Row__Info">
          <p className="SubscriptionsAndTrainingCard__Row__Title" />
          <p className="SubscriptionsAndTrainingCard__Row__Details" />
        </div>
        <div className="SubscriptionsAndTrainingCard__Row__Actions" />
      </div>
    );
  }
};
