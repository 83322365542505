import * as React from 'react';
import { PassDetailsHeroBannerSkeleton } from './PassDetailsHeroBannerSkeleton';
// import { DetailsBlockDesktopSkeleton } from './DetailsBlockDesktopSkeleton';
// import { PassDetailsContentSkeleton } from './PassDetailsContentSkeleton';

interface PassDetailsSkeletonProps {
  isDesktop: boolean;
}

export const PassDetailsSkeleton: React.SFC<PassDetailsSkeletonProps> = props => (
  <div className="PassDetails Skeleton">
    <PassDetailsHeroBannerSkeleton isDesktop={props.isDesktop} />
    {/* {props.isDesktop && <DetailsBlockDesktopSkeleton />}
    <PassDetailsContentSkeleton isDesktop={props.isDesktop} /> */}
  </div>
);
