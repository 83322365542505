import { ThunkAction } from 'store';
import { setSelectedPass, setScrollToDay, setShouldDisableSticky } from '.';

export const setSelectedPassById = (passId: string, passDay: string): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const day = state.pass.read.days.find(d => d.key === passDay);
  if (!day) {
    return;
  }
  const pass = day.items.find(p => p.PassId.toString() === passId.toString());
  if (pass) {
    dispatch(setSelectedPass(pass));
  }
};

export const scrollToDay = (day: string): ThunkAction => dispatch => {
  dispatch(setShouldDisableSticky(true));
  dispatch(setScrollToDay(day));
  setTimeout(() => dispatch(setShouldDisableSticky(false)), 200);
};
