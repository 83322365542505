import * as ErrorStackParser from 'error-stack-parser';
import { api } from 'api';
import { Dispatch } from 'store';
import { ErrorsAction, appCrashed } from './errorsActions';
import { ErrorReport } from './errorsModels';

export const sendErrorReport = (error: Error) => (dispatch: Dispatch<ErrorsAction>) => {
  dispatch(appCrashed(error));

  const errorReport: ErrorReport = {
    message: error.message,
    stackframes: ErrorStackParser.parse(error),
  };

  api.post('LogClient/Error', errorReport, { anonymousCall: true }).then(response => {
    if (response.error) {
      // tslint:disable-next-line:no-console
      console.warn('Could not send error report', response.result);
    }
  });
};
