import { getApplicationViewModeIsDesktop } from 'store/global/ui/application-mode';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { Payment, PaymentDataProps, PaymentActionProps } from './Payment';
import { processPaymentResult } from 'store/payment';
import { getTranslate } from 'store/global/dictionary';

const mapDataToProps = (state: RootState, props: any): PaymentDataProps => {
  const foereningId = state.global.session.activeFoerening;
  const foerening = !!foereningId
    ? state.global.session.inloggadeFoereningar.find(x => x.Id.toString() === foereningId.toString())
    : undefined;
  return {
    isDesktop: getApplicationViewModeIsDesktop(state),
    paymentSucceded: state.payment.read.paymentSucceded,
    signatureCases: state.payment.read.signatureCases,
    location: state.location,
    translate: getTranslate(state.locale),
    foereningUrl:
      !!foerening && !!foerening.ExtraFoereningInfo
        ? foerening.ExtraFoereningInfo.ExternalUrl
        : 'https://www.friskissvettis.se/',
    isInitialized: state.initialize.read.initialized,
  };
};

const mapActionsToProps: PaymentActionProps = {
  processResult: processPaymentResult,
};

export const PaymentContainer = connect(mapDataToProps, mapActionsToProps)(Payment);
