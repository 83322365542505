import * as React from 'react';
import { Spinner } from '../../common';
import Switch from 'components/Switch/Switch';

export interface ListItemDataProps {
  readonly header: string;
  readonly subheader: string;
  readonly activated: boolean;
  readonly showSpinner: boolean;
}

export interface ListItemActionProps {
  onClick: () => void;
}

type ListItemProps = ListItemDataProps & ListItemActionProps;

export const ListItem: React.SFC<ListItemProps> = props => (
  <div className="ListItem">
    <div className="ListItem__Description">
      <p className="Description__Header">{props.header}</p>
      <p className="Description__Subheader">{props.subheader}</p>
    </div>

    <div className="ListItem__Toggler">
      {props.showSpinner ? (
        <Spinner appearance="red" />
      ) : (
        <Switch
          isOn={props.activated}
          handleToggle={props.onClick}
          onColor="#E31836"
          switchId={props.header + props.subheader}
        />
      )}
    </div>
  </div>
);
