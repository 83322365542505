import { executedCommand, executingCommandFailed } from './../../global/fetch/fetchActions';
import { ThunkAction } from 'store';
import { api } from 'api';
import { changePasswordActions } from '.';
import { executingCommand, FetchCommandTypes } from 'store/global/fetch';

export const changePassword = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (!state.global.session.activeFoerening) {
    return;
  }

  const activeFoereningId: number = state.global.session.activeFoerening;
  const requestBody: fos.api.ChangePasswordRequest = {
    FoereningId: activeFoereningId,
    OldPassword: state.user.changePasswordCmd.currentPassword,
    NewPassword: state.user.changePasswordCmd.newPassword,
    ConfirmPassword: state.user.changePasswordCmd.repeatNewPassword,
  };
  dispatch(executingCommand(activeFoereningId.toString(), FetchCommandTypes.AENDRA_LOESENORD));
  return api
    .put<{}, fos.api.ChangePasswordRequest>('Anvaendare/ChangePassword', requestBody, {
      muteErrorNotificationOnStatus: [406],
    })
    .then(res => {
      if (res.error) {
        dispatch(executingCommandFailed(activeFoereningId.toString(), FetchCommandTypes.AENDRA_LOESENORD));
      } else {
        dispatch(executedCommand(activeFoereningId.toString(), FetchCommandTypes.AENDRA_LOESENORD));
        dispatch(changePasswordActions.clearCmd());
      }
    });
};
