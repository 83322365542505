export enum ActionType {
  errorFetch = 'debug/errorFetch',
  errorFetched = 'debug/errorFetched',
  errorFetchedFailed = 'debug/errorFetchedFailed',
}

interface DebugErrorFetchAction {
  type: ActionType.errorFetch;
}

interface DebugErrorFetchedAction {
  type: ActionType.errorFetched;
}

interface DebugErrorFetchedFailedAction {
  type: ActionType.errorFetchedFailed;
}

export type DebugAction = DebugErrorFetchAction | DebugErrorFetchedAction | DebugErrorFetchedFailedAction;

export const debugErrorFetch = (): DebugErrorFetchAction => ({
  type: ActionType.errorFetch,
});

export const debugErrorFetched = (): DebugErrorFetchedAction => ({
  type: ActionType.errorFetched,
});

export const debugErrorFetchedFailed = (): DebugErrorFetchedFailedAction => ({
  type: ActionType.errorFetchedFailed,
});
