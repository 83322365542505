import { connect } from 'react-redux';
import { RootState } from 'store';
import {
  TrainingCardDataProps,
  ExternalTrainingCardDataProps,
  TrainingCard,
  TrainingCardActionProps,
} from './TrainingCard';
import { getTranslate } from 'store/global/dictionary';
import { getHasLoadFailed, FetchStatusAggregateTypes } from 'store/global/fetch';
import { buyTrainingCardOnTheFly } from 'store/basket';
import { avslutaAbonnemang } from 'store/medlemskapOchKort';

const mapStateToProps = (state: RootState, props: ExternalTrainingCardDataProps): TrainingCardDataProps => {
  const foereningId = state.global.session.shopFoerening || state.global.session.activeFoerening;
  const foerening = state.foereningar.read.Foereningar.find(f => '' + f.Id === '' + foereningId);
  return {
    translate: getTranslate(state.locale),
    errorOnBuy:
      !!props &&
      !!props.data &&
      getHasLoadFailed(state.global.fetch, props.data.Id, FetchStatusAggregateTypes.PRODUCT_INFO),
    data: props.data,
    foereningId: foereningId,
    shopActive: !!foerening ? foerening.AerButikAktiv : undefined,
  };
};

const mapDispatchToProps: TrainingCardActionProps = {
  buy: buyTrainingCardOnTheFly,
  terminate: avslutaAbonnemang,
};

export const TrainingCardContainer = connect(mapStateToProps, mapDispatchToProps)(TrainingCard);
