import * as React from 'react';
import { getCurrencyCode } from 'utils';
import { IconCross, IconCrossColor } from 'components/icons';

export interface ShoppingBasketCouponDataProps {
  coupon: fos.api.Coupon;
}

export interface ShoppingBasketCouponActionProps {
  delete: () => void;
}

type ShoppingBasketCouponProps = ShoppingBasketCouponDataProps & ShoppingBasketCouponActionProps;

export const ShoppingBasketCoupon: React.SFC<ShoppingBasketCouponProps> = (props: ShoppingBasketCouponProps) => {
  const getFormattedCouponAmount = (): string => {
    const amount = props.coupon.discount.amount / 100;
    return '-' + amount.toString() + getCurrencyCode(props.coupon.discount.currency);
  };

  return (
    <div className="ShoppingBasketProduct__Item">
      <div className="ShoppingBasketProduct__Item__Name">
        <p>{props.coupon.name}</p>
      </div>
      <div className="ShoppingBasketProduct__Item__Right">
        <span className="Item__Price">{getFormattedCouponAmount()}</span>
        <button className="Item__Delete" onClick={() => props.delete()}>
          <IconCross color={IconCrossColor.red} />
        </button>
      </div>
    </div>
  );
};
