import * as React from 'react';
import { Spinner, MainButton } from 'components/common';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface SignatureDataProps {
  signatureCases?: fos.api.SignatureCase[];
  foereningId?: number;
  orderId?: number;
  isFetchingSignatureCases: boolean;
  hasFetchedSignatureCases: boolean;
  translate: TranslateFunction;
}

export interface SignatureActionProps {
  fetchSignatures: (foereningId: number, orderId: number) => void;
}

type SignatureProps = Readonly<SignatureDataProps & SignatureActionProps>;

export class Signature extends React.Component<SignatureProps> {
  onFocus = (ev: FocusEvent) => {
    if (this.props.foereningId && this.props.orderId && !this.props.isFetchingSignatureCases) {
      this.props.fetchSignatures(this.props.foereningId, this.props.orderId);
    }
  };

  UNSAFE_componentWillReceiveProps(newProps: SignatureDataProps) {
    if (
      newProps.foereningId &&
      newProps.orderId &&
      !newProps.isFetchingSignatureCases &&
      !newProps.hasFetchedSignatureCases
    ) {
      this.props.fetchSignatures(newProps.foereningId, newProps.orderId);
    }
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFocus);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  getButtonTitleForSignatureCase(signatureCase: fos.api.SignatureCase) {
    if (!signatureCase.signed) {
      return this.props.translate(SitecoreDictionary.Signing.SignatureCaseNotSigned) as string;
    }
    return this.props.translate(SitecoreDictionary.Signing.SignatureCaseSigned) as string;
  }

  render() {
    const isLoading = !this.props.signatureCases;
    if (!this.props.signatureCases || this.props.signatureCases.length === 0) {
      return <div />;
    }
    const hasMedlemskap = this.props.signatureCases && this.props.signatureCases.some(s => !s.isAutogiro && !s.signed);
    const hasAutogiro = this.props.signatureCases && this.props.signatureCases.some(s => s.isAutogiro && !s.signed);
    return (
      <div className="Signature">
        {(isLoading || this.props.isFetchingSignatureCases) && <Spinner appearance="white" />}
        {!isLoading && !this.props.isFetchingSignatureCases && !!this.props.signatureCases && (
          <React.Fragment>
            <h3 className="Signature__Header">{this.props.translate(SitecoreDictionary.Signing.SigningTitle)}</h3>
            <h5 className="Signature__Header">
              {hasMedlemskap
                ? this.props.translate(SitecoreDictionary.Signing.SigningTitleMembership)
                : hasAutogiro
                ? this.props.translate(SitecoreDictionary.Signing.SigningTitleDirectDebit)
                : this.props.translate(SitecoreDictionary.Signing.SigningTitleAllSigned)}
            </h5>
            {this.props.signatureCases
              .filter(s => (hasMedlemskap ? !s.isAutogiro : hasAutogiro ? s.isAutogiro : false))
              .map(signatureCase => {
                return (
                  <div key={signatureCase.documentUrl} className="Signature__Button">
                    <MainButton
                      title={this.getButtonTitleForSignatureCase(signatureCase)}
                      color="red"
                      buttonStyle="solid"
                      handleOnClick={() => window.open(signatureCase.documentUrl, '_blank')}
                      className="Signature__Button"
                    />
                  </div>
                );
              })}
          </React.Fragment>
        )}
      </div>
    );
  }
}
