import * as React from 'react';

export interface IconMenuArrowProps {
  title?: string;
}

export class IconMenuArrow extends React.Component<IconMenuArrowProps, {}> {
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g
          id="Navigatin"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(-74.000000, -528.000000)"
        >
          <polygon id="Triangle-Copy" fill="#FFFFFF" points="84 548 74 528 94 528" />
        </g>
      </svg>
    );
  }
}
