export enum UpdateUserProfileCmdActionTypes {
  UpdateUser = 'UPDATE_USER',
  ClearUser = 'CLEAR_USER',
  ErrorCode = 'ERROR_CODE',
  ClearErrorCode = 'CLEAR_ERROR_CODE',
}

interface UpdateUserProfileCmdAction {
  type: UpdateUserProfileCmdActionTypes.UpdateUser;
  user: fos.api.User;
}

interface SetUserProfileCmdErrorCodeAction {
  type: UpdateUserProfileCmdActionTypes.ErrorCode;
  errorCode: number;
}

interface ClearUserProfileCmdErrorCodeAction {
  type: UpdateUserProfileCmdActionTypes.ClearErrorCode;
}

interface ClearUpdateUserProfileCmdAction {
  type: UpdateUserProfileCmdActionTypes.ClearUser;
}

export const updateUser = (user: fos.api.User): UpdateUserProfileCmdAction => ({
  type: UpdateUserProfileCmdActionTypes.UpdateUser,
  user: user,
});

export const setUpdateUserCmdErrorCode = (errorCode: number): SetUserProfileCmdErrorCodeAction => ({
  type: UpdateUserProfileCmdActionTypes.ErrorCode,
  errorCode,
});

export const clearUpdateUserCmdErrorCode = (): ClearUserProfileCmdErrorCodeAction => ({
  type: UpdateUserProfileCmdActionTypes.ClearErrorCode,
});

export const clearCachedUser = (): ClearUpdateUserProfileCmdAction => ({
  type: UpdateUserProfileCmdActionTypes.ClearUser,
});

export type UpdateUserProfileCmdActions =
  | UpdateUserProfileCmdAction
  | ClearUpdateUserProfileCmdAction
  | SetUserProfileCmdErrorCodeAction
  | ClearUserProfileCmdErrorCodeAction;
