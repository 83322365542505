import * as React from 'react';
import checkMark from 'static/assets/icon-checkmark--black.svg';
import { MainButton } from 'components/common';
import { ProductMobileHeader } from '.';
import { GrayLabel } from 'components/common/gray-label';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface ProductDataProps {
  features: Array<string>;
  htmlFeatures?: string;
  isDesktopMode: boolean;
  product: fos.api.Product;
  canBuyProduct: boolean;
  translate: TranslateFunction;
}

export interface ProductActionProps {
  addProduct: (product: fos.api.Product) => void;
}

interface ProductLocalProps {
  isExpanded: boolean;
}

type ProductProps = ProductDataProps & ProductActionProps;
export class Product extends React.Component<ProductProps, ProductLocalProps> {
  constructor(props: ProductProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };

    this.toggleProduct = this.toggleProduct.bind(this);
  }

  toggleProduct() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  render() {
    return (
      <div className="ProductWrapper">
        {!this.props.isDesktopMode && (
          <ProductMobileHeader
            isExpanded={this.state.isExpanded}
            title={this.props.product.Namn}
            price={this.props.product.Pris}
            handleToggleProduct={this.toggleProduct}
          />
        )}

        {(this.state.isExpanded || this.props.isDesktopMode) && (
          <div className="Product">
            {this.props.isDesktopMode && (
              <div className="Product__Title">
                <h4>{this.props.product.Namn}</h4>
              </div>
            )}

            <div className="Product__Feature">
              <ul>
                {this.props.features.map((item, key) => {
                  return (
                    <li key={key}>
                      <img src={checkMark} alt="check" />
                      {item}
                    </li>
                  );
                })}
              </ul>

              {this.props.htmlFeatures && <div dangerouslySetInnerHTML={{ __html: this.props.htmlFeatures }} />}
            </div>

            {this.props.isDesktopMode && (
              <div className="Product__Price">
                <GrayLabel labelText={this.props.product.PaymentMethod} /> {this.props.product.Pris} :–
              </div>
            )}

            <div className="Product__Button">
              <MainButton
                title={
                  this.props.canBuyProduct
                    ? (this.props.translate(SitecoreDictionary.Shop.AddToBasket) as string)
                    : (this.props.translate(SitecoreDictionary.Shop.CannotBuyProduct) as string)
                }
                color={this.props.isDesktopMode ? 'white' : 'red'}
                buttonStyle={this.props.isDesktopMode ? 'outline' : 'solid'}
                handleOnClick={() => this.props.addProduct(this.props.product)}
                className={this.props.canBuyProduct ? 'Product__Button--active' : 'Product__Button--disabled'}
                disabled={!this.props.canBuyProduct}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
