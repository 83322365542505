import { initialize, addTranslation } from 'react-localize-redux';
import { ThunkAction } from 'store/rootReducer';
import { api } from 'api';
import { RootState } from 'store';
import { localStorage } from 'utils/localStorage';
import { updateDictionaryStatus, DictionaryStatus } from '.';

const LOCALSTORAGE_DICTIONARY_KEY = 'dict';

export interface DictionaryData {
  Languages: Array<string>;
  Translations: {};
}

const setupDictionary = (dictionary: DictionaryData, defaultLanguage: string): ThunkAction => dispatch => {
  localStorage.saveItem(LOCALSTORAGE_DICTIONARY_KEY, dictionary, undefined, true);
  dispatch(initialize(dictionary.Languages, { defaultLanguage }));
  dispatch(addTranslation(dictionary.Translations));
  dispatch(updateDictionaryStatus(DictionaryStatus.READY));
};

export const getTranslationData = (): ThunkAction => (dispatch, getState) => {
  dispatch(updateDictionaryStatus(DictionaryStatus.LOADING));
  const localDict = localStorage.getItem<DictionaryData>(LOCALSTORAGE_DICTIONARY_KEY);
  if (localDict && localDict.value) {
    dispatch(setupDictionary(localDict.value, getDefaultLanguage(getState())));
  }
  api.get('Dictionary/GetDictionary', { anonymousCall: true }).then(res => {
    if (!res.error) {
      let defaultLanguage = getDefaultLanguage(getState());
      let dictionary = res.result as DictionaryData;
      dispatch(setupDictionary(dictionary, defaultLanguage));
    }
  });
};

function getDefaultLanguage(state: RootState) {
  if (state.global.ui.language && state.global.ui.language.code) {
    return state.global.ui.language.code;
  } else {
    // TODO: ouyo hitta på ett bättre sätt för default språk
    return 'sv';
  }
}
