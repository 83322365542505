import * as React from 'react';
import { getCurrencyCode } from 'utils';
import { IconCross, IconCrossColor } from 'components/icons';

export interface ShoppingBasketProductDataProps {
  item: fos.api.SubscriptionItem;
  product: fos.api.Product;
}

export interface ShoppingBasketProductActionProps {
  delete: () => void;
}

type ShoppingBasketProductProps = ShoppingBasketProductDataProps & ShoppingBasketProductActionProps;

export const ShoppingBasketProduct: React.SFC<ShoppingBasketProductProps> = (props: ShoppingBasketProductProps) => {
  const getFormatedPrice = (): string => {
    if (!props.item.price) {
      return '';
    }
    let amount = props.item.price.amount;
    if (!!props.item.coupon) {
      amount += props.item.coupon.discount.amount;
    }
    amount /= 100;
    return amount.toString() + getCurrencyCode(props.item.price.currency);
  };

  return (
    <div className="ShoppingBasketProduct__Item">
      <div className="ShoppingBasketProduct__Item__Name">
        <p>{props.product.Namn}</p>
      </div>
      <div className="ShoppingBasketProduct__Item__Right">
        <span className="Item__Price">
          {getFormatedPrice()}
          {props.product.PaymentMethod === 'AutoGiro' && <span className="Price__PerMonth">/mån</span>}
        </span>
        <button className="Item__Delete" onClick={() => props.delete()}>
          <IconCross color={IconCrossColor.red} />
        </button>
      </div>
    </div>
  );
};
