import * as React from 'react';
import { IconChevron } from '../icons';
import { Spinner } from '.';

export interface SelectInputOption {
  value: string | number;
  label: string | number;
  isDisabled?: boolean;
}

interface SelectInputDataProps {
  className?: string;
  label?: string;
  labelColor?: string;
  name?: string;
  align?: 'left' | 'center' | 'right';
  required?: boolean;
  alignLabel?: 'left' | 'center' | 'right';
  value?: string | number;
  shouldValidate?: boolean;
  isValid?: boolean;
  options: SelectInputOption[];
  theme?: string;
  disabled?: boolean;
  tabindex?: number;
}

interface SelectInputActionProps {
  onValueChange?: (newValue: string) => void;
}

type SelectInputProps = SelectInputDataProps & SelectInputActionProps;

export class SelectInput extends React.Component<SelectInputProps> {
  render() {
    return this.props.options && this.props.options.length > 1 ? (
      <div className={this.getContainerClasses()}>
        {this.getLabel()}
        <div className={this.getSelectContainerClasses()}>
          <div className={this.getValueContainerClasses()}>
            <p>{this.getValueName()}</p>
          </div>
          <div>
            <select
              className={this.getSelectClasses()}
              name={this.props.name}
              value={this.props.value}
              onChange={event => this.onChange(event)}
              disabled={this.props.disabled}
              tabIndex={this.props.tabindex}
            >
              {this.mapOptions()}
            </select>

            <IconChevron />
          </div>
        </div>
      </div>
    ) : (
      <div className={this.getContainerClasses()}>
        {this.getLabel()}
        <Spinner appearance="white" size="medium" alignment="center" />
      </div>
    );
  }
  private getValueName() {
    const value = this.props.options.find(
      opt =>
        opt.value === this.props.value ||
        (!!this.props.value && this.props.value.toString && opt.value.toString() === this.props.value.toString())
    );
    return value ? value.label : '';
  }
  private onChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    const newValue: string = event.target.value;
    if (this.props.onValueChange) {
      this.props.onValueChange(newValue);
    }
  }

  private mapOptions(): JSX.Element[] {
    return this.props.options.map(option => (
      <option key={option.value} value={option.value} disabled={option.isDisabled}>
        {option.label}
      </option>
    ));
  }

  private getValueContainerClasses() {
    const mainClass = 'Input__DropDownMenu__Select__ValueContainer';
    let classNames = mainClass;
    classNames += ` ${this.props.labelColor === 'black' ? `${mainClass}--black` : ''}`;

    switch (this.props.align) {
      case 'left':
        classNames += ` ${mainClass}--left`;
        break;
      case 'right':
        classNames += ` ${mainClass}--right`;
        break;
      case 'center':
      default:
        classNames += ` ${mainClass}--center`;
        break;
    }

    return classNames;
  }

  private getSelectContainerClasses(): string {
    let className: string = 'Input__DropDownMenu__Select';
    if (this.props.shouldValidate) {
      className +=
        ' ' + (this.props.isValid ? 'Input__DropDownMenu__Select__Valid' : 'Input__DropDownMenu__Select__UnValid');
    }
    return className;
  }

  private getSelectClasses(): string {
    let className: string = 'Input__Select';
    switch (this.props.align) {
      case 'left':
        className += ' Input__Select__Left';
        break;
      case 'right':
        className += ' Input__Select__Right';
        break;
      case 'center':
      default:
        className += ' Input__Select__Center';
        break;
    }
    return className;
  }

  private getContainerClasses(): string {
    let className: string = 'MainInput Input__DropDownMenu ';
    className += this.props.className ? this.props.className + ' ' : '';
    className += this.props.theme ? this.props.theme + ' ' : '';
    return className;
  }

  private getLabel(): React.ReactNode {
    if (this.props.label) {
      return (
        <label htmlFor={this.props.name} className={this.getLabelClasses()}>
          {this.props.label}
        </label>
      );
    } else {
      return null;
    }
  }

  private getLabelClasses(): string {
    let className: string = 'InputLabel';
    switch (this.props.alignLabel) {
      case 'center':
        className += ' InputLabel__Center';
        break;
      case 'left':
        className += ' InputLabel__Left';
        break;
      case 'right':
        className += ' InputLabel__Right';
        break;
      default:
        className += ' InputLabel__Center';
        break;
    }

    switch (this.props.labelColor) {
      case 'black':
        className += ' InputLabel--black';
        break;
      default:
        break;
    }

    return className;
  }
}
