import { Day } from 'models/Day';
import { DayViewObject } from 'models/Day';
import moment from 'moment';

export const getListOfUniqueDays = <T extends fos.internal.Workout>(items: Array<T>): Array<Day<T>> => {
  // @Speed blegh
  const result: Day<T>[] = [];
  (JSON.parse(JSON.stringify(items)) as Array<T>).forEach(
    (item: T) => {
      const date = new Date(item.StartTime as string);
      const i: Day<T> = {
        isActive: false,
        items: [],
        expanded: false,
        isExpandable: false,
        date,
        rawDate: item.StartTime as string,
        key: getDateTextForView(item.StartTime as string),
      };
      if (result.some(r => r.key === i.key)) {
        // do nothing
      } else {
        result.push(i);
      }
    }
  );
  return result;
};

export function getDateTextForView(startTime: string | Date, format?: string) {
  const mDate = moment(startTime, format);
  const day = mDate.format('dddd');
  const dayMonth = mDate.format('D MMMM');
  const upperDay = day.replace(/^\w/, c => c.toUpperCase());

  return `${upperDay}, ${dayMonth}`;
}

export function formatDate(date: string | Date) {
  return moment(date).format('L');
}

export function getWeekDays() {
  return moment.weekdays(true).map(day => day.replace(/^\w/, c => c.toUpperCase()));
}

export function SortIntoDays<T extends fos.internal.Workout>(items: Array<T>): Array<Day<T>> {
  const uniqueDaysArray: Day<T>[] = getListOfUniqueDays(items);

  items.forEach((item, index) => {
    const matchingDay = uniqueDaysArray.find(day => day.key === getDateTextForView(item.StartTime as string));
    if (matchingDay) {
      matchingDay.items.push(item);
    }
  });
  return uniqueDaysArray;
}

export function SortSortedIntoDaysViewObject<T extends fos.internal.Workout>(items: Array<Day<T>>): DayViewObject<T> {
  let dayViewObject = {};
  items.forEach((day: Day<fos.internal.Workout>, index) => {
    (dayViewObject as any)[day.key] = day;
  });
  return dayViewObject;
}
