import { TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from './../../../store';
import {
  MainMenuListWorkout,
  MainMenuListWorkoutActionProps,
  MainMenuListWorkoutDataProps,
} from './MainMenuListWorkout';

export interface MainMenuListWorkoutContainerProps {
  translate: TranslateFunction;
  currentFoerening: fos.api.InloggadeFoerening;
  availableFoereningar: fos.api.InloggadeFoerening[];
  isDesktopMode: boolean;
  setWorkoutMenu: (val: boolean) => void;
  openSidebarSchedule: () => void;
  showSidebar: () => void;
  showFoereningSwitcher: () => void;
  goToSchedule: () => void;
  onClick?: (val: boolean) => void;
}

const mapStateToProps = (state: RootState, props: MainMenuListWorkoutContainerProps): MainMenuListWorkoutDataProps => {
  const foereningId = state.global.session.activeFoerening;
  let foerening: fos.api.InloggadeFoerening | undefined;
  if (foereningId) {
    foerening = state.global.session.inloggadeFoereningar.find(x => x.Id.toString() === foereningId.toString());
  }
  let externUrl = 'https://www.friskissvettis.se/';
  let onlineTrainingUrl: string | undefined = undefined;
  if (!!foerening && !!foerening.ExtraFoereningInfo) {
    externUrl = foerening.ExtraFoereningInfo.ExternalUrl;
    onlineTrainingUrl = foerening.ExtraFoereningInfo.OnlineTrainingUrl;
  }
  return {
    translate: props.translate,
    currentFoerening: props.currentFoerening,
    availableFoereningar: props.availableFoereningar,
    isDesktopMode: props.isDesktopMode,
    setWorkoutMenu: props.setWorkoutMenu,
    getFoereningUrl: externUrl,
    onlineTrainingUrl: onlineTrainingUrl,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: MainMenuListWorkoutContainerProps): MainMenuListWorkoutActionProps => ({
  openSidebarSchedule: props.openSidebarSchedule,
  showSidebar: props.showSidebar,
  showFoereningSwitcher: props.showFoereningSwitcher,
  goToSchedule: props.goToSchedule,
  onClick: props.onClick,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuListWorkout);
