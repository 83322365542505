import * as React from 'react';
import { IconCross } from 'components/icons';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface AnvaendarePreferenceListProps {
  items: Array<fos.api.SavedSpecialintresse>;
  removeItem: (specialintresse: fos.api.SavedSpecialintresse) => void;
  shouldTranslate: boolean;
  translate: TranslateFunction;
}

export class AnvaendarePreferenceList extends React.Component<AnvaendarePreferenceListProps> {
  render() {
    return (
      <div className="Specialintressen__List">
        {this.props.items.map((i, index) => (
          <div className="List__Item row" key={index}>
            <div className="text">
              {this.props.shouldTranslate
                ? (this.props.translate(
                    SitecoreDictionary.TrainingPreferences.Preferences + i.SpecialintresseName
                  ) as string)
                : i.SpecialintresseName}
            </div>
            <div className="icon" onClick={() => this.props.removeItem(i)}>
              <IconCross />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
