import * as React from 'react';
import { Banner, MainButton, SmallHeader } from 'components/common';
import background from 'static/assets/tanka_traening.png';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as LocationAction } from 'store/location/locationActions';
import { TrainingCardContainer } from './TrainingCard/TrainingCardContainer';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';
import { Subscription } from './Subscription';
import { SubscriptionReceipt } from './SubscriptionReceipt';

export interface SubscriptionsDataProps {
  abonnemang?: fos.api.Abonnemang;
  receipts?: fos.api.ReceiptInfo[];
  isFetching: boolean;
  activeFoerening?: number;
  shopActive?: boolean;
  readonly translate: TranslateFunction;
}

export interface SubscriptionsActionProps {
  getAbonnemang: (foreningId: number) => void;
  getReceiptInfos: (foereningId: number) => void;
  resetMedlemskapOchKort: () => void;
  buySubscription: (item: fos.api.Medlemskap) => void;
}

type SubscriptionsProps = SubscriptionsDataProps & SubscriptionsActionProps;

export const SubscriptionsAndCards: React.FunctionComponent<SubscriptionsProps> = ({
  abonnemang,
  receipts,
  isFetching,
  activeFoerening,
  shopActive,
  translate,
  getAbonnemang,
  getReceiptInfos,
  resetMedlemskapOchKort,
  buySubscription,
}) => {
  React.useEffect(() => {
    if (activeFoerening) {
      getAbonnemang(activeFoerening);
      getReceiptInfos(activeFoerening);
    }
    return () => {
      resetMedlemskapOchKort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subscriptions = () => {
    return (
      <div className="Subscriptions">
        {isFetching || (abonnemang && abonnemang.MedlemskapLista && abonnemang.MedlemskapLista.length > 0) ? (
          <SmallHeader headerText={translate(SitecoreDictionary.SubscriptionsAndCards.Membership).toString()} />
        ) : (
          <div className="Subscriptions__EmptyMessage">Du har inget medlemskap.</div>
        )}
        {isFetching && <Subscription translate={translate} />}
        {!isFetching &&
          abonnemang &&
          abonnemang.MedlemskapLista &&
          abonnemang.MedlemskapLista.map(sub => (
            <Subscription
              key={sub.ProductId}
              data={sub}
              buy={buySubscription}
              hideRenewButton={false}
              translate={translate}
              shopActive={shopActive}
            />
          ))}
      </div>
    );
  };

  const trainingCards = () => {
    return (
      <div className="TrainingCards">
        {(isFetching || (abonnemang && abonnemang.TraeningskortLista && abonnemang.TraeningskortLista.length > 0)) && (
          <SmallHeader headerText={translate(SitecoreDictionary.SubscriptionsAndCards.TrainingCards).toString()} />
        )}
        {isFetching && <TrainingCardContainer />}
        {!isFetching &&
          abonnemang &&
          abonnemang.TraeningskortLista &&
          abonnemang.TraeningskortLista.map(card => <TrainingCardContainer key={card.ProductId} data={card} />)}
      </div>
    );
  };

  const renderReceipts = () => {
    return (
      <div className="SubscriptionReceipts">
        {(isFetching || (receipts && receipts.length > 0)) && (
          <SmallHeader
            headerText={translate(SitecoreDictionary.SubscriptionsAndCards.SubscriptionReceipts).toString()}
          />
        )}
        {isFetching && <SubscriptionReceipt />}
        {!isFetching && receipts && receipts.map(receipt => <SubscriptionReceipt key={receipt.Id} receipt={receipt} />)}
      </div>
    );
  };

  const getBanner = () => {
    return (
      <Banner className="Subscriptions__And__Cards__Banner" backgroundUrl={background}>
        <h3 className="Subscriptions__And__Cards__Banner__Title">
          {translate(SitecoreDictionary.SubscriptionsAndCards.BannerText).toString()}
        </h3>
        <FancyNavLink to={RouteMap[LocationAction.Shop]}>
          <MainButton
            className="Subscriptions__And__Cards__Banner__Button"
            title={translate(SitecoreDictionary.SubscriptionsAndCards.ShopButton).toString()}
            buttonStyle="outline"
          />
        </FancyNavLink>
      </Banner>
    );
  };

  return (
    <div className="Subscriptions__And__Cards__Container">
      <div className="Subscriptions__And__Cards__List">
        {subscriptions()}
        {trainingCards()}
        {renderReceipts()}
      </div>
      {!!shopActive && <div className="Subscriptions__And__Cards__Banner__Container">{getBanner()}</div>}
    </div>
  );
};
