import {
  InitializeAction,
  AddTranslationAction,
  AddTranslationForLanguageAction,
  SetActiveLanguageAction,
  SetLanguagesAction,
} from 'react-localize-redux';
import { DictionaryStatus } from '.';

export enum ActionType {
  SetTranslations = 'SET_TRANSLATIONS',
}

export type LocaleAction =
  | InitializeAction
  | AddTranslationAction
  | AddTranslationForLanguageAction
  | SetActiveLanguageAction
  | SetLanguagesAction;

export interface TranslateAction {
  type: ActionType.SetTranslations;
  translations: {};
}

export enum DictionaryActionTypes {
  UPDATE_STATUS = 'DICTIONARY/UPDATE_STATUS',
}

export interface UpdateDictionaryStatusAction {
  type: DictionaryActionTypes.UPDATE_STATUS;
  status: DictionaryStatus;
}

export const updateDictionaryStatus = (status: DictionaryStatus): UpdateDictionaryStatusAction => ({
  type: DictionaryActionTypes.UPDATE_STATUS,
  status,
});

export type DictionaryActions = UpdateDictionaryStatusAction;
