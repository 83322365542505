import * as React from 'react';

export interface IconFilterListViewProps {
  active: boolean;
  title?: string;
}

enum StatusStyles {
  active = 'active',
  inactive = 'inactive',
}

export class IconFilterListView extends React.Component<IconFilterListViewProps, {}> {
  inactiveStyle = 'fill: #B6B6B6;';
  activeStyle = 'fill: #e31836;';

  getClassForIcon() {
    const iconClass: StatusStyles = this.props.active ? StatusStyles.active : StatusStyles.inactive;
    return iconClass;
  }

  /* tslint:disable */
  render() {
    // TODO: Fix translation support for text
    return (
      <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title} </title>
        <style type="text/css">
          .inactive{'{' + this.inactiveStyle + '}'}
          .active{'{' + this.activeStyle + '}'}
        </style>
        <desc></desc>
        <g fill="none">
          <g className={this.getClassForIcon()}>
            <path d="M0.4 0C0.2 0 0 0.2 0 0.5L0 2.4C0 2.7 0.2 2.9 0.5 2.9L2.4 2.9C2.7 2.9 2.9 2.7 2.9 2.4L2.9 0.5C2.9 0.2 2.7 0 2.4 0L0.5 0C0.5 0 0.4 0 0.4 0 0.4 0 0.4 0 0.4 0ZM1 1L1.9 1 1.9 1.9 1 1.9 1 1ZM4.2 1C3.9 1 3.7 1.2 3.8 1.5 3.8 1.8 4 2 4.3 1.9L21.5 1.9C21.7 1.9 21.8 1.8 21.9 1.7 22 1.5 22 1.3 21.9 1.2 21.8 1 21.7 1 21.5 1L4.3 1C4.3 1 4.3 1 4.3 1 4.2 1 4.2 1 4.2 1 4.2 1 4.2 1 4.2 1ZM0.4 4.8C0.2 4.8 0 5 0 5.3L0 7.2C0 7.5 0.2 7.7 0.5 7.7L2.4 7.7C2.7 7.7 2.9 7.5 2.9 7.2L2.9 5.3C2.9 5 2.7 4.8 2.4 4.8L0.5 4.8C0.5 4.8 0.4 4.8 0.4 4.8 0.4 4.8 0.4 4.8 0.4 4.8ZM1 5.8L1.9 5.8 1.9 6.7 1 6.7 1 5.8ZM4.2 5.8C3.9 5.8 3.7 6 3.8 6.3 3.8 6.6 4 6.8 4.3 6.7L21.5 6.7C21.7 6.7 21.8 6.6 21.9 6.5 22 6.3 22 6.1 21.9 6 21.8 5.8 21.7 5.8 21.5 5.8L4.3 5.8C4.3 5.8 4.3 5.8 4.3 5.8 4.2 5.8 4.2 5.8 4.2 5.8 4.2 5.8 4.2 5.8 4.2 5.8ZM0.4 9.6C0.2 9.6 0 9.8 0 10.1L0 12C0 12.3 0.2 12.5 0.5 12.5L2.4 12.5C2.7 12.5 2.9 12.3 2.9 12L2.9 10.1C2.9 9.8 2.7 9.6 2.4 9.6L0.5 9.6C0.5 9.6 0.4 9.6 0.4 9.6 0.4 9.6 0.4 9.6 0.4 9.6ZM1 10.5L1.9 10.5 1.9 11.5 1 11.5 1 10.5ZM4.2 10.5C3.9 10.6 3.7 10.8 3.8 11.1 3.8 11.4 4 11.5 4.3 11.5L21.5 11.5C21.7 11.5 21.8 11.4 21.9 11.3 22 11.1 22 10.9 21.9 10.8 21.8 10.6 21.7 10.5 21.5 10.5L4.3 10.5C4.3 10.5 4.3 10.5 4.3 10.5 4.2 10.5 4.2 10.5 4.2 10.5 4.2 10.5 4.2 10.5 4.2 10.5ZM0.4 14.4C0.2 14.4 0 14.6 0 14.9L0 16.8C0 17 0.2 17.3 0.5 17.3L2.4 17.3C2.7 17.3 2.9 17 2.9 16.8L2.9 14.9C2.9 14.6 2.7 14.4 2.4 14.4L0.5 14.4C0.5 14.4 0.4 14.4 0.4 14.4 0.4 14.4 0.4 14.4 0.4 14.4ZM1 15.3L1.9 15.3 1.9 16.3 1 16.3 1 15.3ZM4.2 15.3C3.9 15.4 3.7 15.6 3.8 15.9 3.8 16.2 4 16.3 4.3 16.3L21.5 16.3C21.7 16.3 21.8 16.2 21.9 16.1 22 15.9 22 15.7 21.9 15.6 21.8 15.4 21.7 15.3 21.5 15.3L4.3 15.3C4.3 15.3 4.3 15.3 4.3 15.3 4.2 15.3 4.2 15.3 4.2 15.3 4.2 15.3 4.2 15.3 4.2 15.3Z" />
          </g>
        </g>
      </svg>
    );
  }
}
