import { BookingRulesState, bookingRulesReducer } from './bookingRules/bookingRulesReducer';
import { FoereningSwitcherState, foereningSwitcherReducer } from './foereningSwitcher/foereningSwitcherReducer';
import { SitecoreState, sitecoreReducer } from './sitecore/sitecoreReducer';
import { combineReducers } from 'redux';
import { scheduleUiReducer, UIScheduleState } from './schedule';
import { scheduleFilterUiReducer, UIScheduleFilterState } from './schedule-filter';
import { languageUiReducer, LanguageUiState } from './language';
import { mainMenuReducer, MainMenuState } from './main-menu';
import { headerReducer, HeaderState } from './header';
import { sidebarReducer, SideBarState } from './side-bar';
import { siteswitcherReducer, SiteSwitcherState } from './siteswitcher';
import { applicationModeReducer, ApplicationMode } from './application-mode';
import { uiSettingsProfileReducer, UISettingsProfileState } from './settings/profile';
import { UIBookingState, bookingUiReducer } from './bookings';
import { AnlaeggningState, anlaeggningarReducer } from './anlaeggningar';
import { shopFilterUIReducer, UIShopFilterState } from './shop-filter';
import { ImageState, imageReducer } from './image';
import { NewNotificationState, newNotificationReducer } from './newNotification';

export interface UIState {
  readonly language: LanguageUiState;
  bottomMenu: MainMenuState;
  schedule: UIScheduleState;
  bookings: UIBookingState;
  scheduleFilter: UIScheduleFilterState;
  header: HeaderState;
  sideBar: SideBarState;
  siteSwitcher: SiteSwitcherState;
  applicationMode: ApplicationMode;
  uiSettingsProfileState: UISettingsProfileState;
  anlaeggningar: AnlaeggningState;
  sitecore: SitecoreState;
  shopFilter: UIShopFilterState;
  image: ImageState;
  foereningSwitcher: FoereningSwitcherState;
  newNotification: NewNotificationState;
  bookingRules: BookingRulesState;
}

export const uiReducer = combineReducers<UIState>({
  language: languageUiReducer,
  bottomMenu: mainMenuReducer,
  schedule: scheduleUiReducer,
  scheduleFilter: scheduleFilterUiReducer,
  header: headerReducer,
  sideBar: sidebarReducer,
  siteSwitcher: siteswitcherReducer,
  applicationMode: applicationModeReducer,
  uiSettingsProfileState: uiSettingsProfileReducer,
  bookings: bookingUiReducer,
  anlaeggningar: anlaeggningarReducer,
  sitecore: sitecoreReducer,
  shopFilter: shopFilterUIReducer,
  image: imageReducer,
  foereningSwitcher: foereningSwitcherReducer,
  newNotification: newNotificationReducer,
  bookingRules: bookingRulesReducer,
});
