import * as React from 'react';

export enum IconCrossColor {
  red = 'red',
  black = 'black',
  gray = 'gray',
}

const ColorValues = {
  red: '#e31836',
  black: '#1D1D1B',
  gray: '#d2d2d0',
};

export interface IconCrossProps {
  color?: IconCrossColor;
  title?: string;
}

export class IconCross extends React.Component<IconCrossProps, {}> {
  // TODO: Fix translation support for text

  getColor(color?: IconCrossColor) {
    if (color) {
      switch (color) {
        case IconCrossColor.red:
          return ColorValues.red;
        case IconCrossColor.black:
          return ColorValues.black;
        case IconCrossColor.gray:
          return ColorValues.gray;
        default:
          return ColorValues.black;
      }
    }
    return ColorValues.black;
  }

  render() {
    const iconColor = this.getColor(this.props.color);
      /* tslint:disable */

    return (
      <svg
        className="IconCross"
        version="1.1"
        id="Lager_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 22 22"
      >
        <title>{this.props.title}</title>
        <style>
          {`
            .IconCross {
              transform: scale(0.8);
            }
            .IconCross .st0 {
              fill: none;
              stroke: ${iconColor};
              stroke-miterlimit: 10;
            }
          `}
        </style>
        <g>
          <line className="st0" x1="3.2" y1="3.2" x2="18.8" y2="18.8" />
        </g>
        <g>
          <line className="st0" x1="18.8" y1="3.2" x2="3.2" y2="18.8" />
        </g>
      </svg>
    );
  }
}
