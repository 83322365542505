import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { BookingRules, BookingRulesActionProps, BookingRulesDataProps } from './BookingRules';
import { fetchReglerIfNotInStore } from 'store/foereningar';
import { getTranslate } from 'store/global/dictionary';
import { setFocusOnFoerening } from 'store/global/ui/bookingRules';

const mapStateToProps = (state: RootState, props: any): BookingRulesDataProps => {
  return {
    // Todo fix problem with no match without toString() == toString()
    foereningar: state.foereningar.read.Foereningar.filter(i =>
      state.global.session.inloggadeFoereningar.find(j => j.Id.toString() === i.Id.toString())
    ),
    activeFoerning: state.global.session.activeFoerening as number,
    isDesktopMode: props.isDesktopMode,
    translate: getTranslate(state.locale),
    focusedFoerening: state.foereningar.read.Foereningar.find(
      i => i.Id.toString() === state.global.ui.bookingRules.focusedFoereningId.toString()
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): BookingRulesActionProps => ({
  fetchReglerIfNotInStore: (foerening: number) => dispatch(fetchReglerIfNotInStore(foerening)),
  setFocusOnFoerening: (id: number) => dispatch(setFocusOnFoerening(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingRules);
