import * as React from 'react';
import { IconBigClose, IconArrow } from 'components/icons';

interface BackButtonActionProps {
  goBack: () => void;
  icon: 'chevron' | 'close';
  className?: string;
}

export type BackButtonProps = BackButtonActionProps;

export const BackButton: React.SFC<BackButtonProps> = (props: BackButtonProps) => {
  return (
    <div className={'Header_BackButton ' + (props.className ? props.className : '')} onClick={props.goBack}>
      {props.icon === 'close' && <IconBigClose />}
      {props.icon === 'chevron' && <IconArrow color="#e31836" direction="up" />}
    </div>
  );
};
