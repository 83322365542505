import { Action } from 'store';
import { ActionType } from './bokningarAction';
import { DayViewObject } from '../../models/Day';
import { SitecoreDictionary } from 'models';

export interface BokningarRootState {
  bokningar: Array<fos.api.Bokning>;
  days: DayViewObject<fos.api.Bokning>;
  nextBokning?: fos.api.Bokning;
  hasFetchedBokningarOnce: boolean;
}

const initialState: BokningarRootState = {
  days: {},
  bokningar: [],
  hasFetchedBokningarOnce: false,
};

// const getBokningByBokningId = (days: DayViewObject<fos.api.Bokning>, key: string, id: string): fos.api.Bokning | undefined =>  {
//   let day = days[key];
//   let bokning = day.items.find(item => item.BokningId === id);
//   return bokning;
// };

/*
  Update a object of fos.api.Bokning with statusMessage and sets readyToDelete if statusMessage is OK_CANCEL
*/
const updateBokning = (days: DayViewObject<fos.api.Bokning>, id: string, message: string) => {
  let keys = Object.keys(days);
  let key = keys.find(k => days[k].items && !!days[k].items.find(b => b.BokningId.toString() === id));
  if (!key) {
    return days;
  }
  let day = days[key];
  for (let i = 0; i < day.items.length; i++) {
    if (id === day.items[i].BokningId) {
      day.items[i].statusMessage = message;

      if (message === SitecoreDictionary.Schedule.Messages.OK_CANCEL) {
        day.items[i].readyToDelete = true;
      }
      break;
    }
  }
  days[key] = day;
  return days;
};

export const BokningarReducer = (state = initialState, action: Action): BokningarRootState => {
  switch (action.type) {
    case ActionType.SetNextBokning:
      return {
        ...state,
        nextBokning: action.bokning,
      };
    case ActionType.DeleteBokning:
      return { ...state, bokningar: state.bokningar.filter(b => b.BokningId !== action.bokningId) };

    case ActionType.PopulateBokningar:
      return { ...state, hasFetchedBokningarOnce: true, bokningar: action.bokningar };

    case ActionType.AddBokning:
      return { ...state, hasFetchedBokningarOnce: true, bokningar: [...state.bokningar, action.bokning] };

    case ActionType.UpdateStatusMessage:
      const days: DayViewObject<fos.api.Bokning> = updateBokning(state.days, action.id, action.status);
      return { ...state, days: days };

    case ActionType.RemoveBokning:
      let newDays = { ...state.days };

      for (let key of Object.keys(newDays)) {
        for (let i = 0; i < newDays[key].items.length; i++) {
          if (action.bokningId === newDays[key].items[i].BokningId) {
            if (newDays[key].items[i].readyToDelete) {
              newDays[key].items.splice(i, 1);
            }
            if (newDays[key].items.length < 1) {
              delete newDays[key];
            }

            if (Object.keys(newDays).length < 1) {
              newDays = {};
            }
            return { ...state, days: newDays };
          }
        }
      }
      return { ...state };
    case ActionType.GetBokningar:
      return { ...state, days: action.payload };
    case ActionType.UpdateDayExpanded:
      let daysList = state.days;
      const relevantDay = daysList[action.dayName];
      daysList = {
        ...daysList,
        [action.dayName]: {
          ...relevantDay,
          expanded: action.expanded,
        },
      };
      return {
        ...state,
        days: daysList,
      };
    default:
      return state;
  }
};
