import * as React from 'react';
import { Overlay } from '../common';
import { FoereningInternal } from 'store/foereningar/read';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface BookingRulesDataProps {
  foereningar: FoereningInternal[];
  focusedFoerening?: FoereningInternal;
  activeFoerning: number;
  isDesktopMode: boolean;
  translate: TranslateFunction;
}

export interface BookingRulesActionProps {
  fetchReglerIfNotInStore: (id: number) => void;
  setFocusOnFoerening: (id: number) => void;
}

export interface BookingRulesLocalProps {
  Id: number;
  visible: boolean;
}

export type BookingRulesProps = Readonly<BookingRulesActionProps & BookingRulesDataProps>;
export class BookingRules extends React.Component<BookingRulesProps, BookingRulesLocalProps> {
  constructor(props: BookingRulesProps) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      Id: this.props.activeFoerning,
      visible: false,
    };
  }

  toggle() {
    this.props.fetchReglerIfNotInStore(this.state.Id);
    this.setState({
      visible: !this.state.visible,
    });
  }

  componentDidMount() {
    this.props.setFocusOnFoerening(this.props.activeFoerning);
    this.props.fetchReglerIfNotInStore(this.state.Id);
  }

  render() {
    const translate = this.props.translate;
    return (
      <div className="BookingRules">
        <p className="showBookingRules" onClick={() => this.toggle()}>
          {translate(SitecoreDictionary.Schedule.ShowRules)}
        </p>
        {this.state.visible && (
          <Overlay
            close={this.toggle}
            title={this.props.translate(SitecoreDictionary.Bookings.BookingRulesTitle) as string}
          >
            <div className="BookingRules__Container">
              <h3>Bokningsregler</h3>
              <div className="PopupSelect__Text">
                {this.props.focusedFoerening && this.props.focusedFoerening.RuleText ? (
                  <div
                    className="MedlemsVillkor__PopupSelect__Text rich-text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.focusedFoerening.RuleText,
                    }}
                  />
                ) : (
                  <div className="Skeleton__textFields" />
                )}
              </div>

              {!this.props.isDesktopMode && (
                <button className="Button Button--secondary Button--fullWidth margin-b" onClick={() => this.toggle()}>
                  {translate(SitecoreDictionary.Common.Back)}
                </button>
              )}
            </div>
          </Overlay>
        )}
      </div>
    );
  }
}
