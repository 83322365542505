import * as React from 'react';

interface PaymentPurchaseFailMessageProps {
  msg: string;
}

export const PaymentPurchaseFailMessage: React.SFC<PaymentPurchaseFailMessageProps> = (
  props: PaymentPurchaseFailMessageProps
) => {
  const scrollIntoView = (ref: HTMLDivElement | null) => {
    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="Payment__PurchaseFailMessage" ref={ref => scrollIntoView(ref)}>
      <p className="Payment__PurchaseFailMessage__Text">{props.msg}</p>
    </div>
  );
};
