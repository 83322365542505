import { PassFilterKategorier, PassFilterFavoritfilter, PassFilterFiltrerbaraKategorier } from '.';
import { DayViewObject } from 'models/Day';
import { PassFilterFilterCache } from './passFilterModels';

export enum PassFilterActionType {
  PassFilterSetKategoriVald = 'PASS_FILTER_SET_KATEGORI_VALD',
  PassFilterSetFilterValt = 'PASS_FILTER_SET_FILTER_VALT',
  PassFilterSetAllaIFiltergruppVald = 'PASS_FILTER_SET_ALLA_I_FILTERGRUPP_VALD',
  PassFilterSetFilterIGruppVald = 'PASS_FILTER_SET_FILTER_I_GRUPP_VALD',
  PassFilterSetFiltergruppOeppnad = 'PASS_FILTER_SET_FILTERGRUPP_OEPPNAD',
  PassFilterSetFavoritfilterValt = 'PASS_FILTER_SET_FAVORITFILTER_VALT',
  PassFilterCreateFavoritfilter = 'PASS_FILTER_CREATE_FAVORITFILTER',
  PassFilterDeleteFavoritfilter = 'PASS_FILTER_DELETE_FAVORITFILTER',
  PassFilterRensaFilter = 'PASS_FILTER_RENSA_FILTER',
  PassFilterVisaFilter = 'PASS_FILTER_VISA_FILTER',
  PassFilterSetFilter = 'PASS_FILTER_SET_FILTER',
  PassFilterUpdateUnfilteredDays = 'PASS_FILTER_UPDATE_UNFILTERED_DAYS',
  PassFilterSetFavoritfilter = 'PASS_FILTER_SET_FAVORITFILTER',
  PassFilterSetFilterFraanCache = 'PASS_FILTER_SET_FILTER_FRAAN_CACHE',
  PassFilterSetFritextTerm = 'PASS_FILTER_SET_FRITEXT_TERM',
}

export interface PassFilterActionSetKategoriVald {
  type: PassFilterActionType.PassFilterSetKategoriVald;
  kategori: keyof typeof PassFilterKategorier;
  vald: boolean;
}
export interface PassFilterActionSetFilterValt {
  type: PassFilterActionType.PassFilterSetFilterValt;
  kategori: keyof typeof PassFilterKategorier;
  namn: string;
  vald: boolean;
}
export interface PassFilterActionSetAllaIFiltergruppVald {
  type: PassFilterActionType.PassFilterSetAllaIFiltergruppVald;
  kategori: keyof typeof PassFilterKategorier;
  filtergruppNamn: string;
  vald: boolean;
}
export interface PassFilterActionSetFilterIGruppVald {
  type: PassFilterActionType.PassFilterSetFilterIGruppVald;
  kategori: keyof typeof PassFilterKategorier;
  filtergruppNamn: string;
  filter: string;
  vald: boolean;
}
export interface PassFilterActionSetFiltergruppOeppnad {
  type: PassFilterActionType.PassFilterSetFiltergruppOeppnad;
  kategori: keyof typeof PassFilterKategorier;
  filtergruppNamn: string;
  oeppnad: boolean;
}
export interface PassFilterActionSetFavoritfilterValt {
  type: PassFilterActionType.PassFilterSetFavoritfilterValt;
  favoritFilterId: number;
}
export interface PassFilterActionCreateFavoritfilter {
  type: PassFilterActionType.PassFilterCreateFavoritfilter;
  filter: PassFilterFavoritfilter;
}
export interface PassFilterActionDeleteFavoritfilter {
  type: PassFilterActionType.PassFilterDeleteFavoritfilter;
  filterId: number;
}
export interface PassFilterRensaFilter {
  type: PassFilterActionType.PassFilterRensaFilter;
}
export interface PassFilterVisaFilter {
  type: PassFilterActionType.PassFilterVisaFilter;
  visa: boolean;
}
export interface PassFilterSetFilter {
  type: PassFilterActionType.PassFilterSetFilter;
  filter: PassFilterFiltrerbaraKategorier;
}
export interface PassFilterUpdateUnfilteredDays {
  type: PassFilterActionType.PassFilterUpdateUnfilteredDays;
  unfilteredDays: DayViewObject<fos.api.Pass>;
}
export interface PassFilterActionSetFavoritfilter {
  type: PassFilterActionType.PassFilterSetFavoritfilter;
  favoritfilter: PassFilterFavoritfilter[];
}
export interface PassFilterActionSetFilterFraanCache {
  type: PassFilterActionType.PassFilterSetFilterFraanCache;
  cache: PassFilterFilterCache;
}
export interface PassFilterActionSetFritextTerm {
  type: PassFilterActionType.PassFilterSetFritextTerm;
  term: string;
}
export type PassFilterAction =
  | PassFilterActionSetKategoriVald
  | PassFilterActionSetFilterValt
  | PassFilterActionSetAllaIFiltergruppVald
  | PassFilterActionSetFilterIGruppVald
  | PassFilterActionSetFiltergruppOeppnad
  | PassFilterActionSetFavoritfilterValt
  | PassFilterActionCreateFavoritfilter
  | PassFilterActionDeleteFavoritfilter
  | PassFilterRensaFilter
  | PassFilterVisaFilter
  | PassFilterSetFilter
  | PassFilterUpdateUnfilteredDays
  | PassFilterActionSetFavoritfilter
  | PassFilterActionSetFritextTerm
  | PassFilterActionSetFilterFraanCache;
