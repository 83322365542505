import * as React from 'react';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export enum Tab {
  Local,
  TwoBusinessUnits,
  National,
}

export interface TabsProps {
  setSelectedTab: (id: Tab) => void;
  selectedTab: Tab;
  tabs: Tab[];
  translate: TranslateFunction;
  foereningNamn: string;
}

export class Tabs extends React.Component<TabsProps> {
  render() {
    const tabTitles: Map<Tab, string> = new Map<Tab, string>([
      [Tab.Local, this.props.foereningNamn],
      [Tab.TwoBusinessUnits, this.props.translate(SitecoreDictionary.Shop.TwoBusinessUnitCard) as string],
      [Tab.National, this.props.translate(SitecoreDictionary.Shop.NationalCard) as string],
    ]);
    return (
      this.props.tabs.length > 0 && (
        <div className="Tabs">
          <h5>{this.props.translate(SitecoreDictionary.Shop.TrainingCardTabsTitle)}</h5>
          <div className="TabsList">
            {this.props.tabs.map(item => (
              <div
                key={item}
                className={'TabsList__Item ' + (this.props.selectedTab === item ? 'TabsList__Item--selected' : '')}
                onClick={() => this.props.setSelectedTab(item)}
              >
                {tabTitles.get(item)}
              </div>
            ))}
          </div>
        </div>
      )
    );
  }
}
