import { PassFilterActionType, PassFilterAction } from './passFilterActions';
import {
  PassFilterFiltrerbarKategori,
  PassFilterFilterval,
  PassFilterFiltervalGrupp,
  PassFilterKategorier,
  PassFilterFavoritfilter,
  PassFilterFiltrerbaraKategorier,
  PassFilterFlatKategorier,
} from '.';
import { DayViewObject } from 'models/Day';

export interface PassFilterRootState {
  filter: PassFilterFiltrerbaraKategorier;
  oeppenKategori?: keyof typeof PassFilterKategorier;
  favoritFilter: PassFilterFavoritfilter[];
  valtFavoritFilterId?: number;
  filterSynligt: boolean;
  filteredPassIds: { day: string; passIds: string[] }[];
  unfilteredDays: DayViewObject<fos.api.Pass>;
  flatFilter: PassFilterFlatKategorier;
  fritextTerm: string;
}

const initialState: PassFilterRootState = {
  filter: {
    Traeningsstaelle: {
      vaerden: [],
      soekbar: true,
      anvaendTranslate: false,
    },
    Traeningspass: {
      vaerden: [],
      soekbar: true,
      anvaendTranslate: false,
    },
    Tidpunkt: {
      vaerden: [],
      soekbar: false,
      anvaendTranslate: true,
    },
    LedareOrTraenare: {
      vaerden: [],
      soekbar: false,
      anvaendTranslate: false,
    },
    Veckodag: {
      vaerden: [],
      soekbar: false,
      anvaendTranslate: true,
    },
  },
  favoritFilter: [],
  filterSynligt: false,
  filteredPassIds: [],
  unfilteredDays: {},
  flatFilter: {
    Traeningsstaelle: [],
    LedareOrTraenare: [],
    Traeningspass: [],
    Veckodag: [],
    Tidpunkt: [],
  },
  fritextTerm: '',
};

export const isFiltervalGrupp = (
  filterval: PassFilterFilterval | PassFilterFiltervalGrupp
): filterval is PassFilterFiltervalGrupp => {
  return filterval['filterval'] !== undefined;
};

const getFilteredPass = (
  pass: fos.api.Pass[],
  dagIVeckan: string,
  filter: PassFilterFlatKategorier,
  fritextFilter: string[]
): fos.api.Pass[] => {
  if (
    filter.Traeningsstaelle.length === 0 &&
    filter.Traeningspass.length === 0 &&
    filter.Tidpunkt.length === 0 &&
    filter.Veckodag.length === 0 &&
    filter.LedareOrTraenare.length === 0 &&
    fritextFilter.length === 0
  ) {
    return pass;
  }
  const fi = pass.filter(p => {
    const anlaeggningsNamn = p.AnlaeggningsNamn.toLocaleLowerCase();
    const traeningspass = p.PassNamn.toLocaleLowerCase();
    const tidpunkt = p.PeriodOfTheDay.toLocaleLowerCase();
    const veckodag = dagIVeckan.toLocaleLowerCase();
    if (
      (filter.Traeningsstaelle.length === 0 || filter.Traeningsstaelle.some(t => t === anlaeggningsNamn)) &&
      (filter.Traeningspass.length === 0 || filter.Traeningspass.some(t => t === traeningspass)) &&
      (filter.Tidpunkt.length === 0 || filter.Tidpunkt.some(t => t === tidpunkt)) &&
      (filter.Veckodag.length === 0 || filter.Veckodag.some(v => v === veckodag)) &&
      (filter.LedareOrTraenare.length === 0 ||
        filter.LedareOrTraenare.some(t => p.Instruktoerer.some(i => i.toLocaleLowerCase() === t)))
    ) {
      if (fritextFilter.length === 0) {
        return true;
      }
      const r = fritextFilter.map(f => {
        return (
          anlaeggningsNamn.indexOf(f) !== -1 ||
          traeningspass.indexOf(f) !== -1 ||
          tidpunkt.indexOf(f) !== -1 ||
          veckodag.indexOf(f) !== -1 ||
          p.Instruktoerer.some(i => i.toLocaleLowerCase().indexOf(f) !== -1)
        );
      });
      return !r.some(r => !r);
    }
    return false;
  });
  return fi;
};

const getFilteredPassIdByDay = (
  unfilteredDays: DayViewObject<fos.api.Pass>,
  flatFilter: PassFilterFlatKategorier,
  fritextTerm: string
): { day: string; passIds: string[] }[] => {
  if (!unfilteredDays) {
    return [];
  }
  const fritextFilter = fritextTerm
    .replace(/[,.]/g, ' ')
    .split(' ')
    .filter(l => !!l);
  const dayKeys = Object.keys(unfilteredDays);
  const val = dayKeys.map(d => {
    const filtered = getFilteredPass(
      unfilteredDays[d].items,
      unfilteredDays[d].key.substring(0, unfilteredDays[d].key.indexOf(',')),
      flatFilter,
      fritextFilter
    );
    return { day: d, passIds: filtered.map(p => p.PassId) };
  });
  return val;
};

const appliceraFilterPaaKategori = (filterKategori: PassFilterFiltrerbarKategori, filter: string[]) => {
  if (filterKategori) {
    filterKategori.vaerden.forEach(tp => {
      if (isFiltervalGrupp(tp)) {
        tp.filterval.forEach(fv => {
          fv.vald = filter.indexOf(fv.vaerde) !== -1;
        });
      } else {
        tp.vald = filter.indexOf(tp.vaerde) !== -1;
      }
    });
  }
};

const uppdateraInkommandeFilterMedNuvarandeVal = (
  inkommandeFilter: PassFilterFiltrerbarKategori,
  nuvarandeFilter: PassFilterFiltrerbarKategori
) => {
  inkommandeFilter.vaerden.forEach((v, i) => {
    const nuvarande = nuvarandeFilter.vaerden.find(nv => nv.namn === v.namn);
    if (nuvarande) {
      if (isFiltervalGrupp(v)) {
        if (isFiltervalGrupp(nuvarande)) {
          v.oeppen = nuvarande.oeppen;
          v.filterval.forEach(fv => {
            const nuvarandeFilterval = nuvarande.filterval.find(nfv => nfv.namn === fv.namn);
            fv.vald = !!nuvarandeFilterval && nuvarandeFilterval.vald;
          });
        }
      } else {
        if (!isFiltervalGrupp(nuvarande)) {
          v.vald = nuvarande.vald;
        }
      }
    }
  });
};

export const passFilterReducer = (
  state: PassFilterRootState = initialState,
  action: PassFilterAction
): PassFilterRootState => {
  switch (action.type) {
    case PassFilterActionType.PassFilterSetFritextTerm: {
      const fritext = action.term.toLocaleLowerCase();
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, state.flatFilter, fritext);
      return {
        ...state,
        filteredPassIds: filteredPassIds,
        fritextTerm: fritext,
      };
    }

    case PassFilterActionType.PassFilterUpdateUnfilteredDays: {
      const unfilteredDays = action.unfilteredDays;
      const filteredPassIds = getFilteredPassIdByDay(unfilteredDays, state.flatFilter, state.fritextTerm);
      return {
        ...state,
        unfilteredDays: unfilteredDays,
        filteredPassIds: filteredPassIds,
      };
    }

    case PassFilterActionType.PassFilterSetFilter: {
      uppdateraInkommandeFilterMedNuvarandeVal(action.filter.Traeningsstaelle, state.filter.Traeningsstaelle);
      uppdateraInkommandeFilterMedNuvarandeVal(action.filter.Traeningspass, state.filter.Traeningspass);
      uppdateraInkommandeFilterMedNuvarandeVal(action.filter.Tidpunkt, state.filter.Tidpunkt);
      uppdateraInkommandeFilterMedNuvarandeVal(action.filter.LedareOrTraenare, state.filter.LedareOrTraenare);
      uppdateraInkommandeFilterMedNuvarandeVal(action.filter.Veckodag, state.filter.Veckodag);
      // todo: kanske bygga om flat filter här?
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, state.flatFilter, state.fritextTerm);
      return { ...state, filter: { ...action.filter }, filteredPassIds: filteredPassIds };
    }

    case PassFilterActionType.PassFilterVisaFilter: {
      return { ...state, filterSynligt: action.visa };
    }

    case PassFilterActionType.PassFilterSetKategoriVald: {
      return { ...state, oeppenKategori: action.vald ? action.kategori : undefined };
    }

    case PassFilterActionType.PassFilterSetFilterValt: {
      const filt = { ...state.filter };
      const filterKategori = filt[action.kategori];
      if (!filterKategori) {
        return state;
      }
      const vaerde = filterKategori.vaerden.find(v => v.namn === action.namn);
      if (!vaerde || isFiltervalGrupp(vaerde)) {
        return state;
      }
      vaerde.vald = action.vald;
      const flat = { ...state.flatFilter };
      const flatKategori = flat[action.kategori];
      if (vaerde.vald) {
        flatKategori.push(vaerde.vaerde);
      } else {
        flatKategori.splice(flatKategori.indexOf(vaerde.vaerde), 1);
      }
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filter: filt,
        filteredPassIds: filteredPassIds,
        valtFavoritFilterId: undefined,
        flatFilter: flat,
      };
    }

    case PassFilterActionType.PassFilterSetAllaIFiltergruppVald: {
      const filt = { ...state.filter };
      const filterKategori = filt[action.kategori];
      if (!filterKategori) {
        return state;
      }
      const vaerde = filterKategori.vaerden.find(v => v.namn === action.filtergruppNamn);
      if (!vaerde || !isFiltervalGrupp(vaerde)) {
        return state;
      }
      const flat = { ...state.flatFilter };
      const flatKategori = flat[action.kategori];
      vaerde.filterval.forEach(f => {
        f.vald = action.vald;
        if (f.vald) {
          flatKategori.push(f.vaerde);
        } else {
          flatKategori.splice(flatKategori.indexOf(f.vaerde), 1);
        }
      });

      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filter: filt,
        filteredPassIds: filteredPassIds,
        valtFavoritFilterId: undefined,
        flatFilter: flat,
      };
    }

    case PassFilterActionType.PassFilterSetFilterIGruppVald: {
      const filt = { ...state.filter };
      const filterKategori = filt[action.kategori];
      if (!filterKategori) {
        return state;
      }
      const vaerde = filterKategori.vaerden.find(v => v.namn === action.filtergruppNamn);
      if (!vaerde || !isFiltervalGrupp(vaerde)) {
        return state;
      }
      const filterVal = vaerde.filterval.find(f => f.namn === action.filter);
      if (!filterVal) {
        return state;
      }
      filterVal.vald = action.vald;
      const flat = { ...state.flatFilter };
      const flatKategori = flat[action.kategori];
      if (filterVal.vald) {
        flatKategori.push(filterVal.vaerde);
      } else {
        flatKategori.splice(flatKategori.indexOf(filterVal.vaerde), 1);
      }
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filter: filt,
        filteredPassIds: filteredPassIds,
        valtFavoritFilterId: undefined,
        flatFilter: flat,
      };
    }

    case PassFilterActionType.PassFilterSetFiltergruppOeppnad: {
      const filt = { ...state.filter };
      const filterKategori = filt[action.kategori];
      if (!filterKategori) {
        return state;
      }
      const vaerde = filterKategori.vaerden.find(v => v.namn === action.filtergruppNamn);
      if (!vaerde || !isFiltervalGrupp(vaerde)) {
        return state;
      }
      vaerde.oeppen = action.oeppnad;
      return { ...state, filter: filt };
    }
    case PassFilterActionType.PassFilterCreateFavoritfilter: {
      const newFavorit = [...state.favoritFilter];
      newFavorit.push(action.filter);
      return { ...state, valtFavoritFilterId: action.filter.FilterId, favoritFilter: newFavorit };
    }
    case PassFilterActionType.PassFilterDeleteFavoritfilter: {
      const newFavorit = [...state.favoritFilter];
      newFavorit.splice(
        state.favoritFilter.findIndex(f => f.FilterId === action.filterId),
        1
      );
      return {
        ...state,
        valtFavoritFilterId: state.valtFavoritFilterId === action.filterId ? undefined : state.valtFavoritFilterId,
        favoritFilter: newFavorit,
      };
    }
    case PassFilterActionType.PassFilterRensaFilter: {
      const newFilter = { ...state.filter };
      (Object.keys(newFilter) as (keyof typeof PassFilterKategorier)[]).forEach(f => {
        newFilter[f].vaerden.forEach(v => {
          if (isFiltervalGrupp(v)) {
            v.filterval.forEach(fv => {
              fv.vald = false;
            });
          } else {
            v.vald = false;
          }
        });
      });
      const flat: PassFilterFlatKategorier = {
        LedareOrTraenare: [],
        Tidpunkt: [],
        Traeningspass: [],
        Traeningsstaelle: [],
        Veckodag: [],
      };
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filter: newFilter,
        filteredPassIds: filteredPassIds,
        valtFavoritFilterId: undefined,
        flatFilter: flat,
      };
    }
    case PassFilterActionType.PassFilterSetFavoritfilterValt: {
      const favoritFilter = state.favoritFilter.find(f => f.FilterId === action.favoritFilterId);
      if (!favoritFilter) {
        return state;
      }
      const newFilter = { ...state.filter };
      const flat: PassFilterFlatKategorier = {
        Traeningspass: [...favoritFilter.Traeningspass],
        Traeningsstaelle: [...favoritFilter.Traeningsstaelle],
        Veckodag: [...favoritFilter.Veckodag],
        Tidpunkt: [...favoritFilter.Tidpunkt],
        LedareOrTraenare: [...favoritFilter.LedareOrTraenare],
      };
      appliceraFilterPaaKategori(newFilter.Traeningspass, favoritFilter.Traeningspass);
      appliceraFilterPaaKategori(newFilter.Traeningsstaelle, favoritFilter.Traeningsstaelle);
      appliceraFilterPaaKategori(newFilter.Veckodag, favoritFilter.Veckodag);
      appliceraFilterPaaKategori(newFilter.Tidpunkt, favoritFilter.Tidpunkt);
      appliceraFilterPaaKategori(newFilter.LedareOrTraenare, favoritFilter.LedareOrTraenare);
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filter: newFilter,
        filteredPassIds: filteredPassIds,
        valtFavoritFilterId: action.favoritFilterId,
        flatFilter: flat,
      };
    }
    case PassFilterActionType.PassFilterSetFavoritfilter: {
      return { ...state, favoritFilter: action.favoritfilter };
    }
    case PassFilterActionType.PassFilterSetFilterFraanCache: {
      const flat = { ...action.cache };
      const newFilter = { ...state.filter };
      appliceraFilterPaaKategori(newFilter.Traeningspass, flat.Traeningspass);
      appliceraFilterPaaKategori(newFilter.Traeningsstaelle, flat.Traeningsstaelle);
      appliceraFilterPaaKategori(newFilter.Veckodag, flat.Veckodag);
      appliceraFilterPaaKategori(newFilter.Tidpunkt, flat.Tidpunkt);
      appliceraFilterPaaKategori(newFilter.LedareOrTraenare, flat.LedareOrTraenare);
      const filteredPassIds = getFilteredPassIdByDay(state.unfilteredDays, flat, state.fritextTerm);
      return {
        ...state,
        filteredPassIds: filteredPassIds,
        filter: newFilter,
        flatFilter: flat,
        valtFavoritFilterId: flat.favoritFilter,
      };
    }
    default:
      return state;
  }
};
