import * as React from 'react';

type FontWeight = 'normal' | 'bold';
type FontSize = 'mini' | 'small' | 'medium' | 'large';

interface ShortListProps {
  header: string;
  list?: Array<string>;
  large: boolean;
  fontWeight?: FontWeight;
  fontSize?: FontSize;
}

function getFontWeight(props: ShortListProps): string {
  const fontWeightBold = 'ShortList--font-weight-bold';
  if (props.fontWeight === 'normal') {
    return 'ShortList--font-weight-normal';
  } else if (!props.fontWeight || props.fontWeight === 'bold') {
    return fontWeightBold;
  }
  return fontWeightBold;
}

function getFontSize(props: ShortListProps): string {
  const fontSizeSmall = 'ShortList--font-size-small';
  if (props.fontSize === 'mini') {
    return 'ShortList--font-size-mini';
  } else if (!props.fontWeight || props.fontSize === 'small') {
    return fontSizeSmall;
  }
  return fontSizeSmall;
}

export class ShortList extends React.Component<ShortListProps> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    if (!this.props.list || !this.props.list.length) {
      return null;
    }
    return (
      <div className={'ShortList ' + getFontWeight(this.props) + ' ' + getFontSize(this.props)}>
        <h2 className="ShortList__Header">{this.props.header}</h2>
        <ul className="ShortList__List">
          {this.props.list.map(item => (
            <li key={item} className={'Item' + (this.props.large ? ' Item--large' : '')}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
