export interface UISettingsProfileUnderEdit {
  FirstName: boolean;
  LastName: boolean;
  Email: boolean;
  PhoneNumber: boolean;
  Address: boolean;
  ZipCode: boolean;
  City: boolean;
  PropertyFieldUnderClick?: ProfilePropertyNameValues;
}
export enum ProfilePropertyNameValues {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  Address = 'Address',
  ZipCode = 'ZipCode',
  City = 'City',
}
