import * as React from 'react';

import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as Location } from 'store/location/locationActions';
import { MainButton, Logotyp, NewPassword, Banner } from 'components/common';
import { StatusBar } from 'components/common';

import { SitecoreDictionary } from 'models';
import { Spinner } from 'components/common/Spinner';
import background from 'static/assets/background-login.jpg';
import { LocationState } from 'store/location';
import { constructSafeName } from 'utils';
import { TranslateFunction } from 'react-localize-redux';

export interface ResetPasswordDataProps {
  token?: string;
  foerening?: string;
  newPassword: string;
  repeatNewPassword: string;
  isNewPasswordValid: boolean;
  isRepeatNewPasswordValid: boolean;
  resetSuccess?: boolean;
  resetErrorCode?: string;
  isExecuting?: boolean;
  translate: TranslateFunction;
  anlaeggningar: Array<fos.api.SitecoreAnlaeggningar>;
  location: LocationState;
}

export interface ResetPasswordActionProps {
  resetPassword: (model: fos.api.ResetPasswordRequest) => void;
  setNewPassword: (value: string) => void;
  setRepeatNewPassword: (value: string) => void;
  setIsNewPasswordValid: (isValid: boolean) => void;
  setIsRepeatNewPasswordValid: (isValid: boolean) => void;
  clearResetPassword: () => void;
  logout: (gotoLogin: boolean) => void;
  gotoLogin: () => void;
}

export type ResetPasswordComponentProps = ResetPasswordActionProps & ResetPasswordDataProps;

export class ResetPassword extends React.Component<ResetPasswordComponentProps> {
  errorStatusClass = 'StatusMessage--BlackRoundedCorners';

  componentDidMount() {
    if (!this.props.token) {
      this.props.gotoLogin();
    }
    this.props.logout(false);
  }

  componentWillUnmount() {
    this.props.clearResetPassword();
  }

  render() {
    return (
      <Banner backgroundUrl={background} className="ResetPassword full-flex">
        <Logotyp className="ResetPassword__Brand" />
        <div className="ResetPassword__Form">
          {this.props.isExecuting && <Spinner appearance="white" className="ResetPassword__Form__Spinner" />}
          {!this.props.isExecuting && (
            <form onSubmit={event => this.submit(event)}>
              <div className="Form__Input">
                <div className="Input__Submit">
                  {!this.props.resetSuccess && (
                    <NewPassword
                      alignText="center"
                      passwordValue={this.props.newPassword}
                      repeatPasswordValue={this.props.repeatNewPassword}
                      isPasswordValid={this.props.isNewPasswordValid}
                      isRepeatPasswordValid={this.props.isRepeatNewPasswordValid}
                      translate={this.props.translate}
                      className="NewPassword__Input"
                      disabled={this.props.isExecuting || this.props.resetSuccess}
                      updatePassword={this.props.setNewPassword}
                      updatePasswordValidity={this.props.setIsNewPasswordValid}
                      updateRepeatPassword={this.props.setRepeatNewPassword}
                      updateRepeatPasswordValidity={this.props.setIsRepeatNewPasswordValid}
                      location={this.props.location}
                    />
                  )}
                  <StatusBar
                    visible={!!this.props.resetErrorCode || !!this.props.resetSuccess}
                    message={
                      this.props.translate(
                        this.props.resetErrorCode
                          ? (SitecoreDictionary.ResetPassword.ChangePasswordError as any)[this.props.resetErrorCode]
                          : SitecoreDictionary.ResetPassword.ResetSucceeded
                      ) as string
                    }
                    cssClass={
                      'ResetPasswordStatus ' +
                      (!!this.props.resetErrorCode
                        ? 'StatusMessage--BlackRoundedCorners'
                        : 'StatusMessage--WhiteRoundedCorners StatusMessage--skiparrow')
                    }
                  />
                  {!this.props.resetSuccess && (
                    <MainButton
                      type="submit"
                      buttonStyle="solid"
                      color="white"
                      title={this.props.translate(SitecoreDictionary.ResetPassword.ChangePassword) as string}
                      className="ResetPassword__SubmitButton"
                      disabled={this.shouldDisableSubmit()}
                    />
                  )}
                  <FancyNavLink className="ResetPassword__LoginLink" to={RouteMap[Location.Login]}>
                    {this.props.translate(SitecoreDictionary.ResetPassword.Login) as string}
                  </FancyNavLink>
                </div>
              </div>
            </form>
          )}
        </div>
      </Banner>
    );
  }

  private shouldDisableSubmit(): boolean {
    return (
      !this.props.token ||
      !this.props.isNewPasswordValid ||
      !this.props.isRepeatNewPasswordValid ||
      this.props.resetSuccess ||
      !!this.props.isExecuting
    );
  }

  private submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (this.props.token && this.props.newPassword) {
      this.props.resetPassword({
        Token: this.props.token,
        NewPassword: this.props.newPassword,
        Foerening: !!this.props.foerening ? constructSafeName(this.props.foerening.toLowerCase()) : '',
      });
    }
  }
}
