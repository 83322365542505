import * as React from 'react';

export interface IconMenuNewsProps {
  title?: string;
}

export class IconMenuNews extends React.Component<IconMenuNewsProps, {}> {
  fillColour = '#000';

  // TODO: Fix translation support for text
  render() {
    return (
      <svg width="40" height="37" viewBox="0 0 40 37" className="IconMenuNews" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <style>
          {`
            .IconMenuNews {
              width: 55px;
              height: 55px;
            }

            .IconMenuNews .cls-3 {
              fill: none;
              stroke: #343434;
              stroke-linecap: round;
              stroke-miterlimit: 10
            }
          `}
        </style>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect width="40" height="40" fill="none" />

            {/* tslint:disable-next-line:max-line-length */}
            <path
              d="M30.28,28.66a5.52,5.52,0,0,0,2.62,4.72H14.29a4.71,4.71,0,0,1-4.71-4.72V11.34a4.71,4.71,0,0,1,4.71-4.72H25.56a4.72,4.72,0,0,1,4.72,4.72V28.66Z"
              strokeLinejoin="round"
              stroke="#343434"
              strokeLinecap="round"
              fill="none"
            />
            <line className="cls-3" x1="13.8" y1="17.94" x2="26.6" y2="17.94" />
            <line className="cls-3" x1="13.8" y1="21" x2="26.6" y2="21" />
            <line className="cls-3" x1="13.8" y1="27.41" x2="18.11" y2="27.41" />
            <line className="cls-3" x1="19.85" y1="27.41" x2="24.16" y2="27.41" />
            <line className="cls-3" x1="13.8" y1="24.03" x2="23.06" y2="24.03" />
            <line className="cls-3" x1="13.8" y1="14.53" x2="19.68" y2="14.53" />
            <line className="cls-3" x1="14.89" y1="9.13" x2="16.21" y2="9.13" />
            <path className="cls-3" d="M11.94,12.16V10.59A1.47,1.47,0,0,1,13.4,9.13" />
          </g>
        </g>
      </svg>
    );
  }
}
