import * as React from 'react';
import {
  IconMenuSettings,
  IconMenuMembership,
  IconMenuStatistics,
  IconMenuBookedSessions,
  IconMenuNotifications,
  IconProfile,
} from 'components/icons/index';
import { FancyNavLink } from 'components/app/FancyNavLink';
import { routesMap as RouteMap } from 'store/location/routesMap';
import { LocationActionTypes as LocationAction } from 'store/location/locationActions';

import { SitecoreDictionary } from 'models';
import { MainMenuListItem } from './MainMenuListItem';
import { TranslateFunction } from 'react-localize-redux';

export interface MainMenuListAccountActionProps {
  onClick?: (val: boolean) => void;
}

export interface MainMenuListAccountState {
  accountMenu?: boolean;
}

export interface MainMenuListAccountDataProps {
  translate: TranslateFunction;
  newNotificationsCount?: number;
  setAccountMenu: (val: boolean) => void;
  logout: () => void;
}

export type MainMenuListAccountProps = MainMenuListAccountState &
  MainMenuListAccountActionProps &
  MainMenuListAccountDataProps;

export class MainMenuListAccount extends React.Component<MainMenuListAccountDataProps, MainMenuListAccountState> {
  render() {
    return (
      <div className="MainMenuListAccount mainMenuContent">
        <FancyNavLink to={RouteMap[LocationAction.Settings]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuSettings />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Settings) as string}
            onClick={() => this.props.setAccountMenu(true)}
          />
        </FancyNavLink>
        <FancyNavLink to={RouteMap[LocationAction.Subscriptions]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuMembership />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Subscriptions) as string}
            onClick={() => this.props.setAccountMenu(true)}
          />
        </FancyNavLink>
        <FancyNavLink to={RouteMap[LocationAction.Statistics]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuStatistics />}
            text={this.props.translate(SitecoreDictionary.MainMenu.TrainingStatistics) as string}
            onClick={() => this.props.setAccountMenu(true)}
          />
        </FancyNavLink>
        <FancyNavLink to={RouteMap[LocationAction.Notifications]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuNotifications />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Notifications) as string}
            newEventsCount={this.props.newNotificationsCount}
            onClick={() => this.props.setAccountMenu(true)}
          />
        </FancyNavLink>
        <FancyNavLink to={RouteMap[LocationAction.Bookings]}>
          <MainMenuListItem
            showIcon={true}
            icon={<IconMenuBookedSessions />}
            text={this.props.translate(SitecoreDictionary.MainMenu.Bookings) as string}
            onClick={() => this.props.setAccountMenu(true)}
          />
        </FancyNavLink>
        <MainMenuListItem
          showIcon={true}
          icon={<IconProfile active={false} color={'#343434'} />}
          text={this.props.translate(SitecoreDictionary.Profile.Logout) as string}
          onClick={() => this.props.logout()}
        />
      </div>
    );
  }
}
