import { ThunkAction, RootState, Action, Dispatch } from './../rootReducer';
import { setNextBokning, updateStatusMessage } from './bokningarAction';
import { api } from 'api';
import { SortIntoDays, SortSortedIntoDaysViewObject } from 'websd/utils';
import { Day, SitecoreDictionary, getStatusMessage } from 'models';
import { populateBokningar, updateBokningarUI, deleteBokning } from '.';
import {
  executingCommand,
  FetchCommandTypes,
  executingCommandFailed,
  executedCommand,
  fetching,
  FetchStatusAggregateTypes,
  fetched,
  fetchedFailed,
  fetchingInBackground,
} from 'store/global/fetch';
import { dispatchPassForScheduleReducer, updateDayData, updateBokaPassStatus } from '../pass';
import moment from 'moment';
import { getDateTextForView } from 'websd/utils';
import { updatePassDetailsData } from 'store/pass-details/read';

export const getBookingsInBackground = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (state.global.session.userId) {
    dispatch(fetchingInBackground(state.global.session.userId, FetchStatusAggregateTypes.BOKNING));
  }
  return dispatch(getBookings());
};

export const getBookingsInForeground = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (state.global.session.userId) {
    dispatch(fetching(state.global.session.userId, FetchStatusAggregateTypes.BOKNING));
  }
  return dispatch(getBookings());
};

const getBookings = (): ThunkAction => (dispatch: Dispatch<Action>, getState: () => RootState) => {
  const state = getState();
  const promise = api.get<Array<fos.api.Bokning>>('Bokning/MinaBokningar/', {
    muteErrorNotificationOnStatus: [406],
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(populateBokningar(result.result));
      const days: Array<Day<fos.api.Bokning>> = SortIntoDays(result.result);
      const bokningarDaysInViewObject = SortSortedIntoDaysViewObject(days);
      dispatch(updateBokningarUI(bokningarDaysInViewObject));
      if (state.global.session.userId) {
        dispatch(fetched(state.global.session.userId, FetchStatusAggregateTypes.BOKNING, result));
      }
    } else {
      dispatch(updateBokningarUI({}));
      if (state.global.session.userId) {
        dispatch(fetchedFailed(state.global.session.userId, FetchStatusAggregateTypes.BOKNING));
      }
    }
    dispatch(getNextBokning());
  });
  return promise;
};

export const deleteBokningFromStore =
  (id: string): ThunkAction =>
  (dispatch, getState) => {
    dispatch(deleteBokning(id));
    const days: Array<Day<fos.api.Bokning>> = SortIntoDays(getState().bokningar.bokningar);
    const bokningarDaysInViewObject = SortSortedIntoDaysViewObject(days);
    dispatch(updateBokningarUI(bokningarDaysInViewObject));
    dispatch(getNextBokning());
  };

export const avbokaPass =
  (payload: fos.api.CancelPayload, dayViewObjectKey: string): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    const booking = state.bokningar.bokningar.find(b => b.BokningId === payload.bokningId) as fos.api.Bokning;
    return dispatch(scheduleAvbokaPass(payload, booking.PassId, dayViewObjectKey));
  };

export const scheduleAvbokaPass =
  (payload: fos.api.CancelPayload, passId: string, key: string) =>
  (dispatch: Dispatch<Action>, getState: () => RootState) => {
    dispatch(executingCommand(passId, FetchCommandTypes.AVBOKA_PASS));
    const promise = api.post<fos.api.AvbokningResponse, fos.api.CancelPayload>('Bokning/AvbokaPass/', payload, {
      muteErrorNotificationOnStatus: [400],
    });
    promise.then(result => {
      if (!result.error) {
        result.result.pass.statusMessage = getStatusMessage(result.result.status);
        const allDays = getState().pass.read.days;
        const dayKey = getDateTextForView(result.result.pass.StartTime);
        for (let i = 0; i < allDays.length; i++) {
          if (allDays[i].key === dayKey) {
            for (let j = 0; allDays[i].items.length; j++) {
              if (allDays[i].items[j].PassId === result.result.pass.PassId) {
                allDays[i].items[j] = result.result.pass;
                const detailedPass = getState().passDetails.read.pass;
                if (!!detailedPass && detailedPass.PassId === result.result.pass.PassId) {
                  detailedPass.AntalLedigaPlatser = result.result.pass.AntalLedigaPlatser;
                  detailedPass.AntalBokningsbaraPlatser = result.result.pass.AntalBokningsbaraPlatser;
                  detailedPass.AntalDropinPlatser = result.result.pass.AntalDropinPlatser;
                  detailedPass.AntalIVaenteLista = result.result.pass.AntalIVaenteLista;
                  detailedPass.AntalPlatserTotal = result.result.pass.AntalPlatserTotal;
                  detailedPass.AntalBokningsbaraPlatserTotalt = result.result.pass.AntalBokningsbaraPlatserTotalt;
                  detailedPass.LedigaKoePlatser = result.result.pass.LedigaKoePlatser;
                  detailedPass.BokningStatus = result.result.pass.BokningStatus;
                  dispatch(updatePassDetailsData(detailedPass));
                }
                break;
              }
            }
            break;
          }
        }

        dispatch(updateDayData(allDays));
        dispatchPassForScheduleReducer(allDays, dispatch);
        dispatch(deleteBokning(payload.bokningId));
        const days: Array<Day<fos.api.Bokning>> = SortIntoDays(getState().bokningar.bokningar);
        const bokningarDaysInViewObject = SortSortedIntoDaysViewObject(days);
        dispatch(updateBokaPassStatus(passId, getStatusMessage(result.result.status), key));
        dispatch(updateBokningarUI(bokningarDaysInViewObject));
        dispatch(getNextBokning());
        dispatch(executedCommand(passId, FetchCommandTypes.AVBOKA_PASS));
      } else {
        const statusMsg =
          result.result.http && result.result.http.response
            ? getStatusMessage(result.result.http.response.status)
            : SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR;
        dispatch(updateBokaPassStatus(passId, statusMsg, key));
        dispatch(updateStatusMessage(statusMsg, payload.bokningId));
        dispatchPassForScheduleReducer(getState().pass.read.days, dispatch);
        dispatch(executingCommandFailed(passId, FetchCommandTypes.AVBOKA_PASS));
      }
    });
    return promise;
  };

export const getNextBokning = (): ThunkAction => (dispatch, getState) => {
  let nextBokning: fos.api.Bokning | undefined = undefined;
  let nextBokningStartDate: moment.Moment;
  getState().bokningar.bokningar.forEach(bokning => {
    if (bokning.readyToDelete) {
      return;
    }
    const bokningStartDate = moment(bokning.StartTime);
    const now = moment();
    if (!nextBokning) {
      if (bokningStartDate.isBefore(now)) {
        return;
      }
      nextBokning = bokning;
      nextBokningStartDate = bokningStartDate;
    } else {
      if (bokningStartDate.isBefore(nextBokningStartDate)) {
        nextBokning = bokning;
        nextBokningStartDate = bokningStartDate;
      }
    }
  });
  dispatch(setNextBokning(nextBokning));
};
