import { Schedule, ScheduleOwnProps, ScheduleActionProps, ScheduleDataProps } from '.';
import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { fetchPassListInForeground, fetchPassListInBackground } from 'store/pass';
import { getEntityStatus, FetchStatusAggregateTypes } from 'store/global/fetch';
import { LocationActionTypes } from 'store/location';
import { getQueryStringValue, constructSafeName } from 'utils';
import { getTranslate } from 'store/global/dictionary';
import { UIScheduleActionType, UIScheduleViewType } from 'store/global/ui/schedule';
import { SidebarSize } from 'store/global/ui/side-bar';
import {
  passFilterVisaFilter,
  isFiltervalGrupp,
  PassFilterFiltrerbaraKategorier,
  passFilterSetFritextTerm,
} from 'store/passFilter';
import { getBookingsInBackground } from 'store/bokningar';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';
import { Day } from 'models';

const mapState = (state: RootState, props: ScheduleDataProps): ScheduleOwnProps & ScheduleDataProps => {
  const passListFetchKey =
    state.location.type === LocationActionTypes.PublicSchedule
      ? constructSafeName(decodeURIComponent(getQueryStringValue('foerening')))
      : (state.global.session.activeFoerening as number);

  let foereningName = decodeURIComponent(getQueryStringValue('foerening'));
  if (!foereningName) {
    const inloggade = state.global.session.inloggadeFoereningar.find(
      i => i.Id === state.global.session.activeFoerening
    );
    if (inloggade) {
      foereningName = inloggade.Namn;
    }
  } else {
    foereningName = constructSafeName(foereningName);
  }
  const harPass = state.passFilter.filteredPassIds.map(ids => ids.passIds.length).some(n => n !== 0);
  const traeningsstaelleFilter = state.passFilter.filter.Traeningsstaelle;
  const skaVisaTraeningsstaelleMeddelande =
    !!traeningsstaelleFilter &&
    traeningsstaelleFilter.vaerden.length > 3 &&
    !traeningsstaelleFilter.vaerden.some(v => {
      if (isFiltervalGrupp(v)) {
        return v.filterval.some(fv => fv.vald);
      } else {
        return v.vald;
      }
    });
  return {
    passInDay: state.passFilter.filteredPassIds,
    translate: getTranslate(state.locale),
    filterExpanded: state.passFilter.filterSynligt,
    aktivFoerening: state.global.session.activeFoerening,
    foereningName: foereningName,

    fetchPasslistStatus: getEntityStatus(state.global.fetch, passListFetchKey, FetchStatusAggregateTypes.PASS_LIST),
    numbersOfFilters:
      state.passFilter.flatFilter.LedareOrTraenare.length +
      state.passFilter.flatFilter.Tidpunkt.length +
      state.passFilter.flatFilter.Traeningspass.length +
      state.passFilter.flatFilter.Traeningsstaelle.length +
      state.passFilter.flatFilter.Veckodag.length,
    visarOverlay: !harPass || skaVisaTraeningsstaelleMeddelande,
    fritextTerm: state.passFilter.fritextTerm,
    ...props,
  };
};
const mapDispatch = (dispatch: Dispatch, props: any): ScheduleActionProps => ({
  getPassList: (foereningsId: number, foereningsName?: string) => {
    dispatch(fetchPassListInForeground(foereningsId, foereningsName));
  },
  getPassListInBackground: (foereningId: number) => {
    return dispatch<
      Promise<SuccessResult<{ pass: Array<Day<fos.api.Pass>>; filter: PassFilterFiltrerbaraKategorier }> | ErrorResult>
    >(fetchPassListInBackground(foereningId));
  },
  setScheduleToWeekMode: () => {
    dispatch(passFilterVisaFilter(false));
    dispatch({
      type: UIScheduleActionType.SetViewTypeToWeek,
      viewShowing: UIScheduleViewType.WEEKVIEW,
    });
    dispatch({
      type: 'UI_SIDEBAR_SET_SIZE_MAXIMISED',
      size: SidebarSize.MAXIMISED,
    });
  },
  setFilterVisible: (visa: boolean) => {
    dispatch(passFilterVisaFilter(visa));
  },
  getBookings: () => {
    dispatch(getBookingsInBackground());
  },
  setFritextTerm: (filter: string) => {
    dispatch(passFilterSetFritextTerm(filter));
  },
});

export default connect(mapState, mapDispatch)(Schedule);
