import * as React from 'react';

interface InfoProps {
  icon: React.ReactNode;
  header: string | React.ReactNode;
  textColor?: string;
  infoText?: string;
}

export class Info extends React.Component<InfoProps> {
  renderInfoSection() {
    const { header, infoText } = this.props;

    return (
      <div className="Info__Text">
        <span className="Header">{header}</span>
        {!!infoText && <span className="InfoText">{infoText}</span>}
      </div>
    );
  }

  render() {
    const { icon, textColor } = this.props;

    return (
      <div className="Info" style={{ color: textColor }}>
        <div className="Info__Icon">{icon}</div>
        {this.renderInfoSection()}
      </div>
    );
  }
}
