import * as React from 'react';
import { Banner } from 'components/common';

import background from 'static/assets/start_sida.jpg';
import { NextBokning } from '../nextBokning';
import { SitecoreDictionary } from 'models';
import { PassListSkeleton } from '../../common/pass-list-skeleton/PassListSkeleton';
import { TranslateFunction } from 'react-localize-redux';

export interface StartPageDesktopBannerProps {
  nextBokning?: fos.api.Bokning;
  translate: TranslateFunction;
  BookingsFetched?: boolean;
  userName?: string;
}
export interface StartPageDesktopActionProps {
  openSidebarSchedule: () => void;
  showSidebar: () => void;
  goToScheduleMobile: () => void;
}

export type StartPageDesktopProps = StartPageDesktopBannerProps & StartPageDesktopActionProps;

export class StartPageDesktopBanner extends React.Component<StartPageDesktopProps> {
  render() {
    return (
      <Banner backgroundUrl={background} className="StartPage__DesktopBanner">
        {
          <div
            className={
              'StartPage__DesktopBanner__GreetingsContainer ' +
              (this.props.translate(SitecoreDictionary.StartPage.Greetings) && this.props.userName
                ? 'StartPage__DesktopBanner__GreetingsContainer--visible'
                : '')
            }
          >
            <h2 className="StartPage__DesktopBanner__GreetingsContainer__Content">
              {this.props.translate(SitecoreDictionary.StartPage.Greetings)}
              {this.props.userName && (
                <span>
                  <br />
                  {this.props.userName}
                </span>
              )}
              !
            </h2>
          </div>
        }
        <div className="StartPage__DesktopBanner__BokningContainer">
          {this.props.BookingsFetched && (
            <NextBokning
              className="StartPage__DesktopBanner__BokningContainer__NextBokning"
              pass={this.props.nextBokning}
              isDesktopMode={true}
              translate={this.props.translate}
              openSidebarSchedule={this.props.openSidebarSchedule}
              showSidebar={this.props.showSidebar}
              goToScheduleMobile={this.props.goToScheduleMobile}
            />
          )}
          {!this.props.BookingsFetched && <PassListSkeleton numberOfDays={1} maxAmountOfPassItemsPerDay={1} />}
        </div>
      </Banner>
    );
  }
}
