import { LocationActionTypes } from './locationActions';

export const routesMap = {
  [LocationActionTypes.Home]: '/',
  [LocationActionTypes.PublicSchedule]: '/public/schedule',
  [LocationActionTypes.Schedule]: '/schedule',
  [LocationActionTypes.Login]: '/login',
  [LocationActionTypes.AdminLogin]: '/admin',
  [LocationActionTypes.Reset]: '/reset',
  [LocationActionTypes.Settings]: '/settings',
  [LocationActionTypes.PassDetails]: '/pass/:passId',
  [LocationActionTypes.Settings_Profile]: '/settings/profile',
  [LocationActionTypes.Settings_UserAccount]: '/settings/account',
  [LocationActionTypes.Settings_TrainingPreferences]: '/settings/preferences',
  [LocationActionTypes.Settings_PaymentSettings]: '/settings/paymentSettings',
  [LocationActionTypes.Settings_NotificationSettings]: '/settings/notification-settings',
  [LocationActionTypes.Settings_CalendarSyncSettings]: '/settings/calendarsync',
  [LocationActionTypes.Subscriptions]: '/subscriptions',
  [LocationActionTypes.Bookings]: '/bookings',
  [LocationActionTypes.Notifications]: '/notifications',
  [LocationActionTypes.Statistics]: '/statistics',
  [LocationActionTypes.Rules]: '/rules',
  [LocationActionTypes.ShoppingBasket]: '/ShoppingBasket',
  [LocationActionTypes.PublicShoppingBasket]: '/public/ShoppingBasket',
  [LocationActionTypes.Facilities]: '/anlaggningar',
  [LocationActionTypes.News]: '/nyheter',
  [LocationActionTypes.Payment]: '/payment',
  [LocationActionTypes.Shop]: '/shop',
  [LocationActionTypes.ProductDetails]: '/shop/product/:productId',
  [LocationActionTypes.PublicProductDetails]: '/public/shop/product/:productId',
  [LocationActionTypes.PublicShop]: '/public/shop',
  [LocationActionTypes.PublicRegister]: '/public/register',
  [LocationActionTypes.AdminShoppingBasket]: '/admin/ShoppingBasket',
  [LocationActionTypes.AdminProductDetails]: '/admin/shop/product/:productId',
  [LocationActionTypes.AdminShop]: '/admin/shop',
  [LocationActionTypes.AdminRegister]: '/admin/register',
  [LocationActionTypes.ResetPassword]: '/resetPassword',
  [LocationActionTypes.Statistics]: '/statistics',
  [LocationActionTypes.Receipt]: '/receipt',
};
