import { combineReducers } from 'redux';
import { paymentReadReducer, PaymentReadActions, PaymentReadState } from './read';

export type PaymentActions = PaymentReadActions;

export interface PaymentRootState {
  read: PaymentReadState;
}

export const PaymentReducer = combineReducers<PaymentRootState>({
  read: paymentReadReducer,
});
