import { connect } from 'react-redux';
import { RootState } from 'store';
import { ResetPassword, ResetPasswordDataProps, ResetPasswordActionProps } from './ResetPassword';
import { getTranslate } from 'store/global/dictionary';
import { getIsExecuted, FetchCommandTypes, getIsExecuting } from 'store/global/fetch';
import {
  resetPassword,
  setNewPassword,
  setRepeatNewPassword,
  setIsNewPasswordValid,
  setIsRepeatNewPasswordValid,
  doClearPasswordResetState,
} from 'store/global/passwordReset';
import { logout } from 'store/auth';
import { switchView } from 'store/location';

const mapStateToProps = (state: RootState, props: any): ResetPasswordDataProps => ({
  location: state.location,
  anlaeggningar: state.anlaeggningar.read.sitecoreAnlaeggningar,
  translate: getTranslate(state.locale),
  token: state.location && state.location.query ? state.location.query.token : undefined,
  foerening: state.location && state.location.query ? state.location.query.foerening : undefined,
  newPassword: state.global.passowrdReset.newPassword,
  repeatNewPassword: state.global.passowrdReset.repeatNewPassword,
  isNewPasswordValid: state.global.passowrdReset.isNewPasswordValid,
  isRepeatNewPasswordValid: state.global.passowrdReset.isRepeatNewPassword,
  resetSuccess:
    state.location && state.location.query && state.location.query.token
      ? getIsExecuted(state.global.fetch, state.location.query.token as string, FetchCommandTypes.RESET_LOESENORD)
      : undefined,
  isExecuting:
    state.location && state.location.query && state.location.query.token
      ? getIsExecuting(state.global.fetch, state.location.query.token as string, FetchCommandTypes.RESET_LOESENORD)
      : undefined,
  resetErrorCode: state.global.passowrdReset.passwordResetErrorCode,
});

const mapDispatchToProps: ResetPasswordActionProps = {
  resetPassword,
  setNewPassword,
  setRepeatNewPassword,
  setIsNewPasswordValid,
  setIsRepeatNewPasswordValid,
  logout,
  gotoLogin: switchView,
  clearResetPassword: doClearPasswordResetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
