import { Reducer } from 'redux';
import { Action } from 'store';
import { UIScheduleFilterActionType } from './uiScheduleFilterActions';
import { ScheduleFilterCategoryName } from 'store/schedule/scheduleModels';
import { UIScheduleActionType } from '../schedule/uiScheduleActions';

export interface UIScheduleFilterState {
  readonly expanded: boolean;
  categoryState: ScheduleFilterCategoryState;
}

export interface SelectedCategoryItem {
  id: string;
  name: string;
}

export interface FilterCategoryLocalState {
  showEdit: boolean;
  clicked: boolean;
  topPosition: number;
  atTop: boolean;
  selectedCategoryItem: SelectedCategoryItem;
}

export interface ScheduleFilterCategoryState {
  Favoritfilter: FilterCategoryLocalState;
  Traeningsstaelle: FilterCategoryLocalState;
  Traeningspass: FilterCategoryLocalState;
  LedareOrTraenare: FilterCategoryLocalState;
  Tidpunkt: FilterCategoryLocalState;
  Veckodag: FilterCategoryLocalState;
  Bokningsstatus: FilterCategoryLocalState;
}

const defaultFilterCategoryLocalState: FilterCategoryLocalState = {
  showEdit: false,
  clicked: false,
  atTop: false,
  topPosition: 0,
  selectedCategoryItem: { id: '', name: '' },
};
export const initialScheduleFilterState: UIScheduleFilterState = {
  expanded: false,
  categoryState: {
    Favoritfilter: defaultFilterCategoryLocalState,
    Traeningsstaelle: defaultFilterCategoryLocalState,
    Traeningspass: defaultFilterCategoryLocalState,
    LedareOrTraenare: defaultFilterCategoryLocalState,
    Tidpunkt: defaultFilterCategoryLocalState,
    Veckodag: defaultFilterCategoryLocalState,
    Bokningsstatus: defaultFilterCategoryLocalState,
  },
};

export const getScheduleFilterIsExpanded = (scheduleFilterUIState: UIScheduleFilterState): boolean => {
  return scheduleFilterUIState.expanded;
};

export const getCategoryLocalState = (
  scheduleFilterUIState: UIScheduleFilterState,
  internalCategoryName: ScheduleFilterCategoryName
): FilterCategoryLocalState => {
  return scheduleFilterUIState.categoryState[internalCategoryName];
};

export const scheduleFilterUiReducer: Reducer<UIScheduleFilterState> = (
  state = initialScheduleFilterState,
  action: Action
) => {
  switch (action.type) {
    case UIScheduleFilterActionType.SetFilterToExpanded:
      return { ...state, expanded: true };
    case UIScheduleFilterActionType.SetFilterToMinimised:
      return { ...state, expanded: false };
    case UIScheduleFilterActionType.SetCategoryClicked: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: true };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.SetCategoryNotClicked: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: false };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.ToggleCategoryClicked: {
      state.categoryState.Bokningsstatus.clicked = false;
      state.categoryState.Favoritfilter.clicked = false;
      state.categoryState.LedareOrTraenare.clicked = false;
      state.categoryState.Tidpunkt.clicked = false;
      state.categoryState.Traeningspass.clicked = false;
      state.categoryState.Traeningsstaelle.clicked = false;
      state.categoryState.Veckodag.clicked = false;
      let localState = state.categoryState[action.category];
      localState = { ...localState, clicked: !action.clicked };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.SetCategoryAtTop: {
      state.categoryState.Bokningsstatus.atTop = false;
      state.categoryState.Favoritfilter.atTop = false;
      state.categoryState.LedareOrTraenare.atTop = false;
      state.categoryState.Tidpunkt.atTop = false;
      state.categoryState.Traeningspass.atTop = false;
      state.categoryState.Traeningsstaelle.atTop = false;
      state.categoryState.Veckodag.atTop = false;
      let localState = state.categoryState[action.category];
      localState = { ...localState, atTop: true };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.SetCategoryAtNotTop: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, atTop: false };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.SetCategoryTopPosition: {
      let localState = state.categoryState[action.category];
      localState = { ...localState, topPosition: action.topPosition };
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: localState,
        },
      };
    }
    case UIScheduleFilterActionType.SetCategoryLocalState: {
      return {
        ...state,
        categoryState: {
          ...state.categoryState,
          [action.category]: action.localState,
        },
      };
    }
    case UIScheduleActionType.SetViewTypeToList: {
      const categoryStateProperties = Object.keys(state.categoryState);
      const newCategoryState = { ...state.categoryState };
      let numberOfCategoriesClicked = 0;
      let numberOfCategoriesAtTop = 0;
      let weHaveAtLeastOneCategoryExpanded = false;
      categoryStateProperties.forEach((categoryName, index) => {
        if (newCategoryState[categoryName].clicked) {
          numberOfCategoriesClicked = numberOfCategoriesClicked + 1;
          weHaveAtLeastOneCategoryExpanded = true;
        }
        if (newCategoryState[categoryName].atTop) {
          numberOfCategoriesAtTop = numberOfCategoriesAtTop + 1;
          weHaveAtLeastOneCategoryExpanded = true;
        }
      });
      const weHaveMoreThanOneCategoryExpanded = numberOfCategoriesClicked > 1 || numberOfCategoriesAtTop > 1;
      if (weHaveMoreThanOneCategoryExpanded) {
        // Unset category state clicked and category state top values wince we cannot know which values were the currently visible and we can only have one visible in list mode

        categoryStateProperties.forEach((categoryName, index) => {
          newCategoryState[categoryName] = defaultFilterCategoryLocalState;
        });
      }
      return {
        ...state,
        expanded: state.expanded ? state.expanded : weHaveAtLeastOneCategoryExpanded,
        categoryState: newCategoryState,
      };
    }

    default:
      return state;
  }
};
