import * as React from 'react';
import { MainInput, EmailInput, MainButton, StatusBar, Checkbox } from 'components/common';

import { SitecoreDictionary, PersonLookupStatus } from 'models';
import { Anlaeggning } from 'store/global/ui/anlaeggningar';
import { FancyNavLink } from 'components/app';
import { TranslateFunction } from 'react-localize-redux';

interface MemberProfileFormDataProps {
  validateEmail: (isValid: boolean) => void;
  translate: TranslateFunction;
  ssn: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  zipCode: string;
  city: string;
  street: string;
  hasNoSwedishSsn: boolean;
  emailIsValid?: boolean;
  anlaeggningsId: string;
  anlaeggningar: Array<Anlaeggning>;
  lookupStatus?: PersonLookupStatus;
  foereningId?: number;
  hasDebitProductInBasket: boolean;
}

export interface MemberProfileFormActionProps {
  updateSSN: (value: string) => void;
  updateFirstName: (value: string) => void;
  updateLastName: (value: string) => void;
  updateEmail: (value: string) => void;
  updateTelephone: (value: string) => void;
  updateStreet: (value: string) => void;
  updateZipCode: (value: string) => void;
  updateCity: (value: string) => void;
  updateHasNoSwedishSsn: (value: boolean) => void;
  getPersonLookup: (foereningId: number, ssn: string) => void;
  isSsnValid: (value: string, swedishSsn: boolean) => boolean;
  isPhonenumberValid: (value: string) => boolean;
  openLoginPrompt: () => void;
}

export type MemberProfileProps = MemberProfileFormActionProps & MemberProfileFormDataProps;

export interface MemberProfileFormState {
  showErrorMessage: boolean;
}

export class MemberProfileForm extends React.Component<MemberProfileProps, MemberProfileFormState> {
  constructor(props: MemberProfileProps) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
  }

  componentDidUpdate(prevProps: MemberProfileProps) {
    if (prevProps.lookupStatus !== this.props.lookupStatus) {
      this.setState({
        ...this.state,
        showErrorMessage:
          this.props.lookupStatus === PersonLookupStatus.AnvaendareAerRegistrerad ||
          this.props.lookupStatus === PersonLookupStatus.AnvaendareEjHittad,
      });
    }
  }

  render() {
    return (
      <div className="MemberProfileForm">
        <h4 className="ShoppingBasketForm__Title">
          {this.props.translate(SitecoreDictionary.Cart.YourProfile) as string}
        </h4>
        <StatusBar
          visible={this.state.showErrorMessage}
          message={this.getPersonLookupErrorMessage()}
          cssClass={''}
          handleClick={() => {
            this.setState({ ...this.state, showErrorMessage: false });
          }}
          closeBtn={true}
        />
        <div
          className={!!this.props.lookupStatus && !this.props.hasNoSwedishSsn ? 'MemberProfileForm__SSN' : undefined}
        >
          <MainInput
            required={true}
            shouldValidate={true}
            placeholder={
              this.props.hasNoSwedishSsn
                ? (this.props.translate(SitecoreDictionary.Cart.SSNPlaceholderNoSwedishSSN) as string)
                : (this.props.translate(SitecoreDictionary.Cart.SSNPlaceholder) as string)
            }
            label={
              this.props.hasNoSwedishSsn
                ? (this.props.translate(SitecoreDictionary.Cart.BirthDate) as string)
                : (this.props.translate(SitecoreDictionary.Cart.SSN) as string)
            }
            name={'ssn'}
            value={this.props.ssn}
            alignLabel="left"
            align="left"
            labelColor="black"
            type="text"
            border="grey"
            onValueChange={value => this.handleSSNChange(value as string)}
            autocomplete="off"
            isValid={this.props.isSsnValid(this.props.ssn, !this.props.hasNoSwedishSsn)}
          />
          {!!this.props.lookupStatus && !this.props.hasNoSwedishSsn && (
            <MainButton
              title={this.props.translate(SitecoreDictionary.Cart.SSNLookup.PerfomLookupButtonText) as string}
              handleOnClick={() => {
                if (!!this.props.foereningId && this.props.isSsnValid(this.props.ssn, !this.props.hasNoSwedishSsn)) {
                  this.props.getPersonLookup(this.props.foereningId, this.props.ssn);
                }
              }}
            />
          )}
        </div>
        {
          <Checkbox
            checked={this.props.hasNoSwedishSsn}
            onValueChange={this.props.updateHasNoSwedishSsn}
            className="MemberProfileForm__SSNCheckbox"
          >
            {this.props.translate(SitecoreDictionary.Cart.HasNoSwedishSSN) as string}
          </Checkbox>
        }
        {this.props.hasDebitProductInBasket && (
          <p className="ShoppingBasketForm__InfoText">{this.props.translate(SitecoreDictionary.Cart.DebitInfo)}</p>
        )}
        {(this.props.lookupStatus === PersonLookupStatus.EjTillgaenglig ||
          this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad ||
          this.props.hasNoSwedishSsn) && (
          <React.Fragment>
            <MainInput
              required={true}
              shouldValidate={true}
              placeholder={this.props.translate(SitecoreDictionary.Cart.FirstNamePlaceholder) as string}
              label={this.props.translate(SitecoreDictionary.Cart.FirstName) as string}
              name={'firstname'}
              value={this.props.firstName}
              alignLabel="left"
              align="left"
              labelColor="black"
              type="text"
              border="grey"
              onValueChange={value => this.handleFirstNameChange(value as string)}
              isValid={this.props.firstName.length > 0}
              autocomplete="given-name"
            />

            <MainInput
              required={true}
              shouldValidate={true}
              placeholder={this.props.translate(SitecoreDictionary.Cart.LastNamePlaceholder) as string}
              label={this.props.translate(SitecoreDictionary.Cart.LastName) as string}
              value={this.props.lastName}
              name={'lastname'}
              alignLabel="left"
              align="left"
              labelColor="black"
              type="text"
              border="grey"
              onValueChange={value => this.handleLastNameChange(value as string)}
              isValid={this.props.lastName.length > 0}
              autocomplete="last-name"
              disabled={
                this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad && !this.props.hasNoSwedishSsn
              }
            />

            <EmailInput
              required={true}
              shouldValidate={true}
              placeholder={this.props.translate(SitecoreDictionary.Cart.EmailPlaceholder) as string}
              autocomplete="email"
              label={this.props.translate(SitecoreDictionary.Cart.Email) as string}
              value={this.props.email}
              name={'epost'}
              alignLabel="left"
              align="left"
              labelColor="black"
              type="text"
              border="grey"
              onValueChange={value => this.handleEmailChange(value as string)}
              onValidChanged={isValid => this.handleEmailIsValidChange(isValid)}
              isValid={this.props.emailIsValid}
            />

            <MainInput
              required={true}
              shouldValidate={true}
              placeholder={this.props.translate(SitecoreDictionary.Cart.TelephonePlaceholder) as string}
              label={this.props.translate(SitecoreDictionary.Cart.TelephoneNumber) as string}
              value={this.props.telephone}
              name={'telephonenumber'}
              alignLabel="left"
              align="left"
              labelColor="black"
              type="text"
              border="grey"
              onValueChange={value => this.handleTelephoneChange(value as string)}
              isValid={this.props.isPhonenumberValid(this.props.telephone)}
              autocomplete="tel"
            />

            <MainInput
              required={true}
              shouldValidate={true}
              placeholder={this.props.translate(SitecoreDictionary.Cart.StreetPlaceholder) as string}
              label={this.props.translate(SitecoreDictionary.Cart.Street) as string}
              value={this.props.street}
              name={'adress'}
              alignLabel="left"
              align="left"
              labelColor="black"
              type="text"
              border="grey"
              onValueChange={value => this.handleStreetChange(value as string)}
              isValid={this.props.street.length > 0}
              autocomplete="street-address"
              disabled={
                this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad && !this.props.hasNoSwedishSsn
              }
            />

            <div className="MemberProfileForm__AddressInfo">
              <MainInput
                required={true}
                shouldValidate={true}
                className="MemberProfileForm__AddressInfo__ZipCode"
                label={this.props.translate(SitecoreDictionary.Cart.ZipCode) as string}
                name={'adress'}
                alignLabel="left"
                align="left"
                labelColor="black"
                type="text"
                border="grey"
                value={this.props.zipCode}
                onValueChange={value => this.handleZipCodeChange(value as string)}
                isValid={this.props.zipCode.length === 5}
                autocomplete="postal-code"
                disabled={
                  this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad && !this.props.hasNoSwedishSsn
                }
              />

              <MainInput
                required={true}
                shouldValidate={true}
                className="MemberProfileForm__AddressInfo__City"
                label={this.props.translate(SitecoreDictionary.Cart.City) as string}
                name={'adress'}
                alignLabel="left"
                align="left"
                labelColor="black"
                type="text"
                border="grey"
                value={this.props.city}
                onValueChange={value => this.handleCityChange(value as string)}
                isValid={this.props.city.length > 0}
                autocomplete=""
                disabled={
                  this.props.lookupStatus === PersonLookupStatus.AnvaendareHittad && !this.props.hasNoSwedishSsn
                }
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  private getPersonLookupErrorMessage(): React.ReactNode {
    if (this.props.lookupStatus === undefined) {
      return '';
    }
    switch (this.props.lookupStatus) {
      case PersonLookupStatus.AnvaendareAerRegistrerad:
        return (
          <React.Fragment>
            <p>{this.props.translate(SitecoreDictionary.Cart.SSNLookup.PersonAlreadyExists)}</p>
            <FancyNavLink to="#" clickHandler={this.props.openLoginPrompt}>
              <React.Fragment>{this.props.translate(SitecoreDictionary.Login.Login)}</React.Fragment>
            </FancyNavLink>
          </React.Fragment>
        );
      case PersonLookupStatus.AnvaendareEjHittad:
        return this.props.translate(SitecoreDictionary.Cart.SSNLookup.PersonNotFound);
      default:
        return '';
    }
  }

  private handleSSNChange(ssn: string): void {
    this.props.updateSSN(ssn);
  }

  private handleFirstNameChange(firstName: string): void {
    this.props.updateFirstName(firstName);
  }

  private handleLastNameChange(lastName: string): void {
    this.props.updateLastName(lastName);
  }

  private handleEmailChange(email: string): void {
    this.props.updateEmail(email);
  }

  private handleCampaignChange(email: string): void {
    // this.props.updateEmail(email);
  }

  private handleEmailIsValidChange(emailIsValid: boolean) {
    this.props.validateEmail(emailIsValid);
  }

  private handleTelephoneChange(telephone: string): void {
    this.props.updateTelephone(telephone);
  }

  private handleStreetChange(street: string): void {
    this.props.updateStreet(street);
  }

  private handleZipCodeChange(zipCode: string): void {
    this.props.updateZipCode(zipCode);
  }

  private handleCityChange(city: string): void {
    this.props.updateCity(city);
  }

  private containsOnlyNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }
}
