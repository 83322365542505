export enum NewsActionType {
  GetNews = 'GET_NEWS',
}

export interface GetNewsAction {
  type: NewsActionType.GetNews;
  news: Array<fos.api.News>;
}

export const getNews = (news: Array<fos.api.News>): GetNewsAction => ({
  type: NewsActionType.GetNews,
  news,
});

export type NewsAction = GetNewsAction;
