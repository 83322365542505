import { Query } from 'redux-first-router';

export enum LocationActionTypes {
  Home = 'LOCATION/HOME',
  PublicSchedule = 'LOCATION/PUBLIC/SCHEDULE',
  Login = 'LOCATION/LOGIN',
  AdminLogin = 'LOCATION/ADMIN',
  Reset = 'LOCATION/RESET',
  Settings = 'LOCATION/SETTINGS',
  Settings_Profile = 'LOCATION/SETTINGS/PROFILE',
  Settings_UserAccount = 'LOCATION/SETTINGS/USER_ACCOUNT',
  Settings_TrainingPreferences = 'LOCATION/SETTINGS/TRAININGPREFERENCES',
  Settings_PaymentSettings = 'LOCATION/SETTINGS/PAYMENT_SETTINGS',
  Settings_NotificationSettings = 'LOCATION/SETTINGS/NOTIFICATION_SETTINGS',
  Settings_CalendarSyncSettings = 'LOCATION/SETTINGS/CALENDARSYNC',
  Subscriptions = 'LOCATION/SUBSCRIPTIONS',
  Bookings = 'LOCATION/BOOKINGS',
  Rules = 'LOCATION/RULES',
  PassDetails = 'LOCATION/PASS_DETAILS',
  ShoppingBasket = 'LOCATIONS/SHOPPING_BASKET',
  PublicShoppingBasket = 'LOCATIONS/PUBLIC/SHOPPING_BASKET',
  AdminShoppingBasket = 'LOCATIONS/ADMIN/SHOPPING_BASKET',
  ProductDetails = 'LOCATION/PRODUCT_DETAILS',
  PublicProductDetails = 'LOCATION/PUBLIC/PRODUCT_DETAILS',
  AdminProductDetails = 'LOCATION/ADMIN/PRODUCT_DETAILS',
  Shop = 'LOCATIONS/SHOP',
  PublicShop = 'LOCATIONS/PUBLIC/SHOP',
  PublicRegister = 'LOCATIONS/PUBLIC/REGISTER',
  AdminShop = 'LOCATIONS/ADMIN/SHOP',
  AdminRegister = 'LOCATION/ADMIN/REGISTER',
  Facilities = 'LOCATIONS/FACILITIES',
  News = 'LOCATIONS/NEWS',
  Payment = 'LOCATIONS/PAYMENT',
  Notifications = 'LOCATIONS/NOTIFICATIONS',
  ResetPassword = 'LOCATIONS/ResetPassword',
  Statistics = 'LOCATIONS/STATISTICS',
  Schedule = 'LOCATION/SCHEDULE',
  Receipt = 'LOCATION/RECEIPT',
}

type HomeRouteAction = {
  type: LocationActionTypes.Home;
};

type PublicScheduleRouteAction = {
  type: LocationActionTypes.PublicSchedule;
};

type LoginRouteAction = {
  type: LocationActionTypes.Login;
};

type AdminLoginRouteAction = {
  type: LocationActionTypes.AdminLogin;
};

type ResetRouteAction = {
  type: LocationActionTypes.Reset;
};

type SettingsRouteAction = {
  type: LocationActionTypes.Settings;
};

type PassDetailsRouteAction = {
  type: LocationActionTypes.PassDetails;
  payload: {
    passId: string;
  };
};

type ProductDetailsRouteAction = {
  type: LocationActionTypes.ProductDetails;
  payload: {
    productId: number;
    productName: string;
  };
  query: {
    foerening: string;
    customerType: number;
  };
};

type AdminProductDetailsRouteAction = {
  type: LocationActionTypes.AdminProductDetails;
  payload: {
    productId: number;
    productName: string;
  };
  query: {
    foerening: string;
    customerType: number;
  };
};

type PublicProductDetailsRouteAction = {
  type: LocationActionTypes.PublicProductDetails;
  payload: {
    productId: number;
    productName: string;
  };
  query: {
    foerening: string;
    customerType: number;
  };
};

type ProfileRouteAction = {
  type: LocationActionTypes.Settings_Profile;
};

type RulesAction = {
  type: LocationActionTypes.Rules;
};

type BookingsAction = {
  type: LocationActionTypes.Bookings;
};

type NotificationsAction = {
  type: LocationActionTypes.Notifications;
};

type StatisticsAction = {
  type: LocationActionTypes.Statistics;
};

type PaymentAction = {
  type: LocationActionTypes.Payment;
};

type UserAccountAction = {
  type: LocationActionTypes.Settings_UserAccount;
};

type NotificationSettingsAction = {
  type: LocationActionTypes.Settings_NotificationSettings;
};

type CalendarSyncAction = {
  type: LocationActionTypes.Settings_CalendarSyncSettings;
};

export type ChangeLocationAction = {
  type: LocationActionTypes;
  query?: Query;
  payload?: Object;
};

export enum LocationSwitchViewActionType {
  SwitchView = 'SWITCH_VIEW',
}

type ResetPasswordRouteAction = {
  type: LocationActionTypes.ResetPassword;
};

type ScheduleRouteAction = {
  type: LocationActionTypes.Schedule;
};

type ReceiptRouteAction = {
  type: LocationActionTypes.Receipt;
};

export const passDetailsRouteAction = (pass: fos.api.Pass | fos.api.Bokning): PassDetailsRouteAction => {
  return {
    type: LocationActionTypes.PassDetails,
    payload: {
      passId: pass.PassId,
    },
  };
};

export const publicProductDetailsRouteAction = (
  id: number,
  name: string,
  foereningNamn: string,
  customerType: number
): PublicProductDetailsRouteAction => {
  return {
    type: LocationActionTypes.PublicProductDetails,
    payload: {
      productId: id,
      productName: name,
    },
    query: {
      foerening: foereningNamn,
      customerType: customerType,
    },
  };
};

export const productDetailsRouteAction = (
  id: number,
  name: string,
  foereningNamn: string,
  customerType: number
): ProductDetailsRouteAction => {
  return {
    type: LocationActionTypes.ProductDetails,
    payload: {
      productId: id,
      productName: name,
    },
    query: {
      foerening: foereningNamn,
      customerType: customerType,
    },
  };
};

export const adminProductDetailsRouteAction = (
  id: number,
  name: string,
  foereningNamn: string,
  customerType: number
): AdminProductDetailsRouteAction => {
  return {
    type: LocationActionTypes.AdminProductDetails,
    payload: {
      productId: id,
      productName: name,
    },
    query: {
      foerening: foereningNamn,
      customerType: customerType,
    },
  };
};

export const switchView = (
  destination?: LocationActionTypes,
  query?: Query,
  payload?: Object
): ChangeLocationAction => ({
  type: destination || LocationActionTypes.Home,
  query,
  payload,
});

export type LocationAction =
  | HomeRouteAction
  | PublicScheduleRouteAction
  | LoginRouteAction
  | AdminLoginRouteAction
  | ResetRouteAction
  | SettingsRouteAction
  | ProfileRouteAction
  | BookingsAction
  | PassDetailsRouteAction
  | ProductDetailsRouteAction
  | PublicProductDetailsRouteAction
  | RulesAction
  | PaymentAction
  | NotificationsAction
  | NotificationSettingsAction
  | CalendarSyncAction
  | UserAccountAction
  | StatisticsAction
  | ResetPasswordRouteAction
  | ScheduleRouteAction
  | ReceiptRouteAction
  | AdminProductDetailsRouteAction;
