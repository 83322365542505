export enum LoginCmdActionTypes {
  UPDATE_EMAIL = 'LOGIN_CMD/UPDATE_EMAIL',
  UPDATE_PASSWORD = 'LOGIN_CMD/UPDATE_PASSWORD',
  UPDATE_FOERENINGID = 'LOGIN_CMD/UPDATE_FOERENINGID',
  CLEAR_CMD = 'LOGIN_CMD/CLEAR_CMD',
}

export interface UpdateEmailAction {
  type: LoginCmdActionTypes.UPDATE_EMAIL;
  email: string;
}

export interface UpdatePasswordAction {
  type: LoginCmdActionTypes.UPDATE_PASSWORD;
  password: string;
}

export interface UpdateFoereningIdAction {
  type: LoginCmdActionTypes.UPDATE_FOERENINGID;
  foereningId: number;
}
export interface ClearCmdAction {
  type: LoginCmdActionTypes.CLEAR_CMD;
}

export const updateEmail = (email: string): UpdateEmailAction => ({
  type: LoginCmdActionTypes.UPDATE_EMAIL,
  email,
});

export const updateFoereningId = (foereningId: number): UpdateFoereningIdAction => ({
  type: LoginCmdActionTypes.UPDATE_FOERENINGID,
  foereningId,
});

export const updatePassword = (password: string): UpdatePasswordAction => ({
  type: LoginCmdActionTypes.UPDATE_PASSWORD,
  password,
});

export const clearLoginCmd = (): ClearCmdAction => ({
  type: LoginCmdActionTypes.CLEAR_CMD,
});

export type LoginCmdActions = UpdateEmailAction | UpdatePasswordAction | UpdateFoereningIdAction | ClearCmdAction;
