import * as React from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { MainMenuActiveItem } from 'store/global/ui/main-menu';
import { MainMenuListAccount } from './MainMenuListAccount';
import MainMenuListWorkoutContainer from './MainMenuListWorkoutContainer';

export interface MainMenuListSwitcherActionProps {
  openSidebarSchedule: () => void;
  showSidebar: () => void;
  showFoereningSwitcher: () => void;
  goToSchedule: () => void;
  onClick?: (val: boolean) => void;
  logout: () => void;
}

export interface MainMenuListSwitcherDataProps {
  activeMenu: MainMenuActiveItem;
  show: boolean;
  translate: TranslateFunction;
  availableFoereningar: fos.api.InloggadeFoerening[];
  currentFoerening: fos.api.InloggadeFoerening;
  newNotificationsCount?: number;
  isDesktopMode: boolean;
  accountMenu?: boolean;
  workoutMenu?: boolean;
  setAccountMenu: (val: boolean) => void;
  setWorkoutMenu: (val: boolean) => void;
}

export type MainMenuListSwitcherProps = MainMenuListSwitcherActionProps & MainMenuListSwitcherDataProps;

export class MainMenuListSwitcher extends React.Component<MainMenuListSwitcherProps, {}> {
  renderActiveMenuList(menuToShow: MainMenuActiveItem) {
    if (menuToShow === MainMenuActiveItem.MY_ACCOUNT) {
      return (
        <MainMenuListAccount
          setAccountMenu={this.props.setAccountMenu}
          translate={this.props.translate}
          newNotificationsCount={this.props.newNotificationsCount}
          logout={this.props.logout}
        />
      );
    } else if (menuToShow === MainMenuActiveItem.MY_WORKOUT) {
      return (
        <MainMenuListWorkoutContainer
          setWorkoutMenu={this.props.setWorkoutMenu}
          openSidebarSchedule={this.props.openSidebarSchedule}
          showSidebar={this.props.showSidebar}
          translate={this.props.translate}
          availableFoereningar={this.props.availableFoereningar}
          showFoereningSwitcher={this.props.showFoereningSwitcher}
          currentFoerening={this.props.currentFoerening}
          goToSchedule={this.props.goToSchedule}
          isDesktopMode={this.props.isDesktopMode}
        />
      );
    } else {
      return undefined;
    }
  }

  getClasses(): string {
    const baseClass: string = 'MainMenuListSwitcher';
    if (this.props.show) {
      return baseClass + ' ' + this.getShowingClass();
    } else {
      return baseClass + ' ' + this.getShowingClass();
    }
  }

  getShowingClass(): string {
    let direction = this.props.activeMenu === MainMenuActiveItem.MY_WORKOUT ? '__Right' : '__Left';
    // TODO - replace when online training link is available
    let activeOnlineTraining = true ? 'Extended' : '';
    if (this.props.show) {
      return `showing${direction} ${activeOnlineTraining}`;
    } else {
      return 'hiding';
    }
  }

  render() {
    if (this.props.show) {
      return <div className={this.getClasses()}>{this.renderActiveMenuList(this.props.activeMenu)}</div>;
    } else {
      return null;
    }
  }
}
