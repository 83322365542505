import { Day } from 'models';

function isBookedPassCancelled(bokning: fos.api.Bokning, passList: Day<fos.api.Pass>[]): boolean {
  if (!bokning || !passList || passList.length < 1) {
    return false;
  }
  const day = passList.find(p => p.key === bokning.Day);
  if (!!day) {
    const pass = day.items.find(p => p.PassId === bokning.PassId);
    if (!!pass) {
      return pass.AerInstaelld;
    }
  }
  return false;
}

export const PassUtils = {
  isBookedPassCancelled,
};
