import { NotificationOptions } from 'models';
import { fetched, fetching, FetchStatusAggregateTypes, fetchedFailed } from 'store/global/fetch';
import { getPushNotificationSettings } from './pushNotificationSettingsActions';
import { api } from 'api';
import { ThunkAction } from 'store';

export const fetchPushNotificationSettings = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const userId = state.global.session.userId;
  if (!userId) {
    return;
  }
  dispatch(fetching(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS));
  const promise = api.get<fos.api.PushNotificationSettings>(`Notis/Getinstaellningar`);
  promise.then(result => {
    if (!result.error) {
      dispatch(getPushNotificationSettings(result.result));
      dispatch(fetched(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS, result.result));
    } else {
      dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS));
    }
  });
  return promise;
};

export const updatePushNotificationSettings = (setting: string): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const settings = state.pushNotificationSettings;
  const userId = state.global.session.userId;

  if (!userId || !settings.UserNotificatonSettingsId) {
    return;
  }

  const payload: fos.api.PushNotificationSettings = {
    UserNotificatonSettingsId: settings.UserNotificatonSettingsId,
  };

  switch (setting) {
    case NotificationOptions.PassCancelled:
      payload.PassCancelled = !settings.PassCancelled;
      break;
    case NotificationOptions.Vaentelista:
      payload.Vaentelista = !settings.Vaentelista;
      break;
    case NotificationOptions.News:
      payload.News = !settings.News;
      break;
    case NotificationOptions.TraeningskortAboutToExpire:
      payload.TraeningskortAboutToExpire = !settings.TraeningskortAboutToExpire;
      break;
    default:
      break;
  }
  // Uppdatera lokalt direkt, och rätta i efterhand om ändringen misslyckades
  dispatch(getPushNotificationSettings(payload));
  dispatch(fetching(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS));
  const promise = api.post<fos.api.PushNotificationSettings, fos.api.PushNotificationSettings>(
    'Notis/UpdateInstaellningar',
    payload
  );
  promise.then(result => {
    if (!result.error) {
      if (didUpdateFail(setting, settings, result.result)) {
        dispatch(getPushNotificationSettings(result.result));
      }
      dispatch(fetched(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS, result.result));
    } else {
      dispatch(fetchedFailed(userId, FetchStatusAggregateTypes.NOTIFICATIONS_SETTINGS));
    }
  });
};

const didUpdateFail = (
  setting: string,
  originalValue: fos.api.PushNotificationSettings,
  result: fos.api.PushNotificationSettings
): boolean => {
  switch (setting) {
    case NotificationOptions.PassCancelled:
      return originalValue.PassCancelled === result.PassCancelled;
    case NotificationOptions.Vaentelista:
      return originalValue.Vaentelista === result.Vaentelista;
    case NotificationOptions.News:
      return originalValue.News === result.News;
    case NotificationOptions.TraeningskortAboutToExpire:
      return originalValue.TraeningskortAboutToExpire === result.TraeningskortAboutToExpire;
    default:
      return false;
  }
};
