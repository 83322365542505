import { connect } from 'react-redux';
import { RootState } from '../../store';
import { SiteSwitcherProps, SiteSwitcher } from './SiteSwitcher';
import { getIsPublicWebMode } from '../../store/location';

const mapStateToProps = (state: RootState, props: any): SiteSwitcherProps => {
  const foereningId = !!state.global.session.shopFoerening
    ? state.global.session.shopFoerening
    : state.global.session.activeFoerening;
  const foerening = state.foereningar.read.Foereningar.find(f => '' + f.Id === '' + foereningId);
  let url = 'https://www.friskissvettis.se/';
  if (!!foerening && !!foerening.ExtraFoereningInfo) {
    url = foerening.ExtraFoereningInfo.ExternalUrl;
  }
  return {
    isPublicWebMode: getIsPublicWebMode(state.location),
    isDesktopMode: props.isDesktopMode,
    foereningUrl: url,
    foereningNamn: !!foerening ? foerening.DisplayName : '',
  };
};

export default connect(mapStateToProps)(SiteSwitcher);
