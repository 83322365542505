import * as React from 'react';
import { IconCalendar, IconMenuBookedSessions } from 'components/icons';

import { SitecoreDictionary } from 'models';
import { Bow } from 'components/common';
import { TranslateFunction } from 'react-localize-redux';

interface MobileLinksProps {
  goToSchema: () => void;
  goToBookings: () => void;
  translate: TranslateFunction;
}

export const MobileLinks: React.SFC<MobileLinksProps> = props => (
  <div className={'StartPage__MobileLinks'}>
    <div className="StartPage__MobileLinks__Separator" />
    <div className="StartPage__MobileLinks__Bookings" onClick={() => props.goToBookings()}>
      <div className="StartPage__MobileLinks__Bookings__Icon">
        <IconMenuBookedSessions color="red" />
        <Bow className="StartPage__MobileLinks__Bookings__Icon__Bow" />
      </div>
      <span className="StartPage__MobileLinks__Bookings__Text">
        {props.translate(SitecoreDictionary.StartPage.ShowBokningar)}
      </span>
    </div>
    <div className="StartPage__MobileLinks__Calendar" onClick={() => props.goToSchema()}>
      <div className="StartPage__MobileLinks__Calendar__Icon">
        <IconCalendar />
        <Bow className="StartPage__MobileLinks__Calendar__Icon__Bow" />
      </div>
      <span className="StartPage__MobileLinks__Calendar__Text">
        {props.translate(SitecoreDictionary.StartPage.OpenSchema)}
      </span>
    </div>
  </div>
);
