import * as React from 'react';
import { PaymentSettingsItem } from './PaymentSettingsItem';
import { EntityStatus } from '../../../store/global/fetch';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

export interface AutogirosDataProps {
  data: fos.api.Autogiro[] | null;
  fetchState?: EntityStatus;
  translate: TranslateFunction;
  enableEdit: boolean;
}

export interface AutogirosActionProps {
  setAutogiroToUpdate: (id: string, currentClearingNumber: string, currentAccountNumber: string) => void;
}

type AutogirosProps = AutogirosActionProps & AutogirosDataProps;

export const Autogiros = (props: AutogirosProps) => {
  const getAutogiro = () => {
    if (props.fetchState === EntityStatus.FETCHING || props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND) {
      return <PaymentSettingsItem translate={props.translate} enableEdit={props.enableEdit} />;
    } else if (props.data) {
      return props.data.map(b => (
        <PaymentSettingsItem
          key={b.Id}
          id={b.Id}
          accountNumber={b.BankAccountNumber}
          clearingNumber={b.ClearingNumber}
          bankName={b.BankName}
          type={b.BetalningsmedelType}
          translate={props.translate}
          enableEdit={props.enableEdit}
          setAutogiroToUpdate={props.setAutogiroToUpdate}
        />
      ));
    } else {
      return null;
    }
  };

  return (
    <div>
      {(props.fetchState === EntityStatus.FETCHING ||
        props.fetchState === EntityStatus.FETCHING_IN_BACKGROUND ||
        (props.data && props.data.length > 0)) && (
        <div className="PaymentSettings__Title">
          {props.translate(SitecoreDictionary.Profile.PaymentSettings.Autogiro) as string}
        </div>
      )}
      {getAutogiro()}
    </div>
  );
};
