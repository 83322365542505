import { SitecoreActionType } from './sitecoreActions';
import { Reducer } from 'redux';
import { Action } from 'store';

export interface SitecoreState {
  allowBokaPass: boolean;
}

const initialState: SitecoreState = {
  allowBokaPass: true,
};

export const sitecoreReducer: Reducer<SitecoreState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case SitecoreActionType.AllowBokaPass:
      return { ...state, allowBokaPass: true };
    case SitecoreActionType.DisableBokaPass:
      return { ...state, allowBokaPass: false };
    default:
      return state;
  }
};
