import * as React from 'react';

export enum IconRemoveStyling {
  red = 'red',
  white = 'white',
}
export type IconRemoveStylingTypes = 'red' | 'white';

export interface IconRemoveProps {
  styling?: IconRemoveStylingTypes;
  title?: string;
  desc?: string;
}

export class IconRemove extends React.Component<IconRemoveProps, {}> {
  // TODO: Fix translation support for text

  getClass(styling?: IconRemoveStylingTypes) {
    if (!styling) {
      return IconRemoveStyling.red;
    }

    switch (styling) {
      case IconRemoveStyling.red:
        return IconRemoveStyling.red;
      case IconRemoveStyling.white:
        return IconRemoveStyling.white;
      default:
        return IconRemoveStyling.red;
    }
  }

  render() {
    const styling = this.getClass(this.props.styling);

      /* tslint:disable */
    
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" className="IconRemove">
        <title>{this.props.title}</title>
        <style type="text/css">
          {' '}
          {`.IconRemove{
                width: 33px;
                height: 33px;
            }
            .IconRemove .red{
                fill:#E31836;
             }
            .IconRemove .white{
                fill:#FFF;
             }
            `}
        </style>
        <desc>{this.props.desc}</desc>
        <path
          className={styling}
          d="M29.5 11c-1.4 0-2.6 1.2-2.6 2.6v0.9h-7.2c-1.1 0-1.8 0.8-2.1 1.8h-0.1c-1.9 0-3.5 1.6-3.5 3.7v0.7c0 0.5 0.4 0.9 0.9 0.9h1l4.4 30.6c0.2 1.7 1.6 2.9 3.3 2.9h19c1.6 0 2.9-1.2 3.3-2.8 0 0 0 0 0-0.1l4.4-30.6h1c0.5 0 0.9-0.4 0.9-0.9V20c0-2-1.6-3.7-3.5-3.7h-0.1c-0.4-1-1.1-1.8-2.1-1.8H39v-0.9c0-1.4-1.2-2.6-2.6-2.6H29.5zM29.5 12.8h6.9c0.5 0 0.9 0.4 0.9 0.9v0.9h-8.6v-0.9C28.7 13.2 29.1 12.8 29.5 12.8zM19.8 16.3h26.4c0.3 0 0.8 0.4 0.8 0.9 0 0.5 0.4 0.9 0.9 0.9h0.6c0.9 0 1.7 0.7 1.8 1.8H15.8c0.1-1 0.8-1.8 1.8-1.8h0.6c0.5 0 0.9-0.4 0.9-0.9C19 16.7 19.4 16.3 19.8 16.3zM17.6 21.6h30.8l-4.3 30.2c-0.2 0.9-0.8 1.4-1.6 1.4h-19c-0.8 0-1.5-0.5-1.6-1.3L17.6 21.6zM22 25.3c-0.2 0-0.4 0.2-0.6 0.4 -0.1 0.2-0.2 0.4-0.2 0.7l2.9 21.9c0 0.3 0.2 0.6 0.5 0.8 0.3 0.1 0.7 0.1 0.9-0.1 0.3-0.2 0.4-0.6 0.3-0.9L23 26.1c0-0.2-0.1-0.5-0.3-0.6C22.5 25.3 22.2 25.3 22 25.3zM29.2 25.3c-0.4 0.1-0.8 0.5-0.7 0.9l0.9 21.9c0 0.5 0.4 0.9 0.9 0.8 0.5 0 0.8-0.4 0.8-0.9l-0.9-21.9c0-0.2-0.1-0.5-0.3-0.6C29.8 25.4 29.6 25.3 29.2 25.3 29.3 25.3 29.3 25.3 29.2 25.3zM36.5 25.3c-0.4 0.1-0.7 0.4-0.7 0.9l-0.9 21.9c0 0.5 0.3 0.9 0.8 0.9 0.5 0 0.9-0.4 0.9-0.8l0.9-21.9c0-0.3-0.1-0.5-0.2-0.7C37.1 25.4 36.8 25.3 36.5 25.3 36.5 25.3 36.5 25.3 36.5 25.3zM43.7 25.3c-0.4 0.1-0.7 0.4-0.7 0.8L40.2 48c-0.1 0.3 0 0.7 0.3 0.9 0.3 0.2 0.6 0.3 0.9 0.1 0.3-0.1 0.5-0.4 0.5-0.8l2.9-21.9c0-0.3 0-0.5-0.2-0.7C44.3 25.4 44.1 25.3 43.7 25.3 43.8 25.3 43.8 25.3 43.7 25.3z"
        />
      </svg>
    );
  }
}
