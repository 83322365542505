import { initialCommand } from 'store/global/fetch/fetchActions';
import { RootState, ThunkAction } from 'store';
import { addProduct, onCreateUserError, updateBasket, onCreatePaymentError } from './basketActions';
import { createBasket } from '.';
import { logout, authenticateAndSetupUser } from 'store/auth';
import { LocationActionTypes, switchView, redirectTo } from 'store/location';
import { getSubscriptionsDetails, ProductTypes, getProducts } from 'store/shop';
import { api } from 'api';
import { CreateUserState } from 'store/user/createUserCmd';
import { executingCommandFailed, FetchCommandTypes, executingCommand, executedCommand } from 'store/global/fetch';
import { ProductPaymentMethod } from 'models';
import { setSignatureCasesAction } from 'store/payment/read';
import { setOrderId } from 'store/global/session';

export const buySubscriptionOnTheFly =
  (item: fos.api.Medlemskap): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    let foereningId: number;
    if (!state.global.session.shopFoerening) {
      dispatch(logout());
      return;
    } else {
      foereningId = state.global.session.shopFoerening;
    }

    try {
      const res = await getSubscriptionsDetails(item, state.global.session.shopFoerening)(dispatch, getState);
      if (state.shoppingBasket.foereningId !== foereningId) {
        dispatch(createBasket(foereningId));
      }
      if (item.ProductId && !basketHasProduct(item.ProductId, state)) {
        dispatch(addProduct(res, foereningId));
      }
      dispatch(switchView(LocationActionTypes.ShoppingBasket));
    } catch {
      return;
    }
  };

export const buyTrainingCardOnTheFly =
  (item: fos.api.Traeningskort): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    if (!state.global.session.activeFoerening) {
      dispatch(logout());
      return;
    }
    const foereningId = state.global.session.activeFoerening;
    try {
      let products = state.product.unfilteredProducts;
      if (products.length === 0) {
        products = await dispatch<Promise<Array<fos.api.Product>>>(getProducts(foereningId));
      }
      const res = products.find(p => '' + p.Id === item.ProductId);
      if (state.shoppingBasket.foereningId !== foereningId) {
        dispatch(createBasket(foereningId));
      } else {
        dispatch(updateBasket(foereningId, []));
        localStorage.removeItem('ORDER::' + foereningId);
        dispatch(setOrderId(undefined));
      }
      if (!res) {
        dispatch(switchView(LocationActionTypes.Shop));
        return;
      }
      dispatch(addProduct(res, foereningId));
      dispatch(switchView(LocationActionTypes.ShoppingBasket));
    } catch {
      return;
    }
  };

export const buyBasket = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const inloggadeFoerening = state.global.session.shopFoerening || state.shoppingBasket.foereningId;
  if (!inloggadeFoerening || !state.shoppingBasket.products) {
    return;
  }
  dispatch(executingCommand(inloggadeFoerening.toString(), FetchCommandTypes.BUY_BASKET));

  const autogiroInfo: fos.api.BankAccountInfo | undefined = state.shoppingBasket.products.find(
    prod => prod.PaymentMethod === ProductPaymentMethod.AutoGiro
  )
    ? {
        BankAccountNumber: state.shoppingBasket.autogiro.accountNumber,
        ClearingNumber: state.shoppingBasket.autogiro.clearingNumber,
      }
    : undefined;
  const orderId = state.global.session.orderId;
  if (!orderId) {
    return;
  }
  const newWindow = window.open();
  api
    .post<fos.api.NewPaymentUrl, fos.api.NewPaymentUrlRequest>('Order/GetPaymentUrl', {
      foereningId: inloggadeFoerening,
      bankAccountInfo: autogiroInfo,
      userId: state.user.read.user.Id,
      orderId,
      paymentMethod: state.shoppingBasket.paymentMethodId,
      phoneNumber: state.shoppingBasket.phoneNumber,
    })
    .then(res => {
      if (!res.error && res.result) {
        dispatch(executedCommand(inloggadeFoerening.toString(), FetchCommandTypes.BUY_BASKET));
        if (!!res.result.SignatureCases && !!res.result.SignatureCases.find(s => !s.signed)) {
          // Måste signera köpet
          dispatch(setSignatureCasesAction(res.result.SignatureCases));
        }
        if (newWindow && res.result.Url) {
          newWindow.location.href = res.result.Url;
        }
      } else {
        if (res.error && res.result && res.result.http && res.result.http.response && res.result.http.response.Error) {
          const errors: string[] = [res.result.http.response.Error];
          dispatch(onCreatePaymentError(errors));
        }
        dispatch(executingCommandFailed(inloggadeFoerening.toString(), FetchCommandTypes.BUY_BASKET));
        if (newWindow) {
          newWindow.close();
        }
      }
    });
};

export const createUser =
  (user: CreateUserState): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();
    if (!state.global.session.shopFoerening) {
      return;
    }

    const requestBody: fos.api.CreateUser = {
      ...user,
      foereningId: parseInt(state.global.session.shopFoerening.toString(), 10),
      spraakId: state.global.ui.language.id,
    };
    dispatch(executingCommand('-1', FetchCommandTypes.SKAPA_ANVAENDARE));
    const promise = api.post<fos.api.CreateUserResponse, fos.api.CreateUser>(
      'Anvaendare/SkapaNyAnvaendare',
      requestBody,
      {
        anonymousCall: true,
        muteErrorNotificationOnStatus: [406, 409],
      }
    );
    promise.then(res => {
      if (!res.error) {
        dispatch(executedCommand('-1', FetchCommandTypes.SKAPA_ANVAENDARE));
        dispatch(authenticateAndSetupUser(res.result.authToken, state.global.ui.language.id));
        if (state.location.type === LocationActionTypes.PublicRegister) {
          dispatch(redirectTo(LocationActionTypes.ShoppingBasket));
        } else if (state.location.type === LocationActionTypes.AdminRegister) {
          dispatch(redirectTo(LocationActionTypes.AdminShoppingBasket));
        }
      } else {
        dispatch(executingCommandFailed('-1', FetchCommandTypes.SKAPA_ANVAENDARE));
        if (!!res.result.http && !!res.result.http.response) {
          const errors = res.result.http.response.errors;
          dispatch(onCreateUserError(errors));
        }
      }
    });
    return promise;
  };

export const addMembership = (): ThunkAction => (dispatch, getState) => {
  const state = getState();

  if (!!state.shoppingBasket && !!state.shoppingBasket.products) {
    const medlemskapInBasket = state.shoppingBasket.products.find(
      p => p.Types.find(t => t === ProductTypes.Medlemskap) && p.Namn.includes(new Date().getFullYear().toString())
    );
    if (!!medlemskapInBasket) {
      return;
    }
  }

  const foereningId = state.global.session.shopFoerening;
  if (!foereningId) {
    return;
  }
  const products = state.product.unfilteredProducts;
  let medlemskap = products.find(
    p => !!p.Types.find(t => t === ProductTypes.Medlemskap) && p.Namn.includes(new Date().getFullYear().toString())
  );

  if (!!medlemskap) {
    dispatch(addProduct(medlemskap, foereningId));
  } else {
    medlemskap = products.find(p => !!p.Types.find(t => t === ProductTypes.Medlemskap));
    if (!!medlemskap) {
      dispatch(addProduct(medlemskap, foereningId));
    }
  }
};

export const resetBuyBasketCommand = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (state.global.session.shopFoerening) {
    dispatch(initialCommand(state.global.session.shopFoerening.toString(), FetchCommandTypes.BUY_BASKET));
  } else {
    dispatch(initialCommand('-1', FetchCommandTypes.BUY_BASKET));
  }
};

function basketHasProduct(productId: string, state: RootState): boolean {
  let result: boolean = false;
  const productsList = state.shoppingBasket.products;
  if (productsList && productsList.length > 0) {
    productsList.forEach(p => (p.Id.toString() === productId ? (result = true) : null));
  }
  return result;
}
