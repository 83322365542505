import * as React from 'react';
import moment from 'moment';
import { ShortList } from '../text';
import { TranslateFunction } from 'react-localize-redux';

interface DetailsBlockMobileProps {
  translate: TranslateFunction;
  pass: fos.api.PassDetails;
}

export const DetailsBlockMobile: React.FunctionComponent<DetailsBlockMobileProps> = ({ translate, pass }) => {
  const getTimeAndPlace = () => {
    const startTime = moment(pass.StartTime);
    const endTime = moment(pass.EndTime);
    const start = startTime.format('HH:mm');
    const end = endTime.format('HH:mm');
    const date = startTime.format('D MMMM');
    const day = startTime.format('dddd');
    const upperDay = day.replace(/^\w/, c => c.toUpperCase());

    return [
      `${upperDay} ${date} ${start} - ${end} (${pass.Length} min)`,
      `${pass.Lokal.length > 0 ? pass.Lokal.join(', ') + ', ' : ''}${pass.AnlaeggningsNamn}`,
    ];
  };

  return (
    <div className="Details">
      <ShortList header={translate('Instructors') as string} list={pass.Instruktoerer} large={true} />
      <ShortList
        header={translate('TimeAndPlace') as string}
        list={getTimeAndPlace()}
        large={false}
        fontWeight="normal"
        fontSize="mini"
      />
    </div>
  );
};
