import { Reducer } from 'redux';
import { Action, RootState } from 'store';

export enum ApplicationViewEnum {
  'MOBILE' = 'MOBILE',
  'DESKTOP' = 'DESKTOP',
}

export interface ApplicationMode {
  applicationView: ApplicationViewEnum;
}

const initialState: ApplicationMode = {
  applicationView: ApplicationViewEnum.MOBILE,
};
export const getApplicationViewModeIsDesktop = (state: RootState): boolean => {
  return state.global.ui.applicationMode.applicationView === ApplicationViewEnum.DESKTOP;
};

export const applicationModeReducer: Reducer<ApplicationMode> = (state = initialState, action: Action) => {
  // console.info('applicationModeReducer state:', state);
  // console.info('applicationModeReducer action:', action);
  switch (action.type) {
    case 'UI_APPLICATION_MODE_SET_MOBILE': {
      return { ...state, applicationView: ApplicationViewEnum.MOBILE };
    }
    case 'UI_APPLICATION_MODE_SET_DESKTOP': {
      return { ...state, applicationView: ApplicationViewEnum.DESKTOP };
    }

    default:
      return state;
  }
};
