import { connect } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import { Signature, SignatureDataProps, SignatureActionProps } from './Signature';
import { getSignatureCases } from 'store/payment';
import { getIsFetching, FetchStatusAggregateTypes, getIsFetched } from 'store/global/fetch';
import { getTranslate } from 'store/global/dictionary';

const mapStateToProps = (state: RootState, props: any): SignatureDataProps => {
  const foerening = !!state.global.session.activeFoerening
    ? state.global.session.activeFoerening
    : !!state.shoppingBasket.foereningId
    ? state.shoppingBasket.foereningId
    : 0;
  const orderId = state.payment.read.orderId;
  return {
    signatureCases: state.payment.read.signatureCases,
    foereningId: foerening,
    orderId: orderId,
    isFetchingSignatureCases: getIsFetching(state.global.fetch, '' + orderId, FetchStatusAggregateTypes.SIGNATURECASE),
    hasFetchedSignatureCases: getIsFetched(state.global.fetch, '' + orderId, FetchStatusAggregateTypes.SIGNATURECASE),
    translate: getTranslate(state.locale),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): SignatureActionProps => ({
  fetchSignatures: (foereningId: number, orderId: number) =>
    dispatch(getSignatureCases(foereningId, orderId.toString())),
});

export const SignatureContainer = connect(mapStateToProps, mapDispatchToProps)(Signature);
