import * as React from 'react';
import { canAddProduct } from 'utils';

import { SitecoreDictionary } from 'models';
import { UIShopFilterState, FilterCategoryType } from 'store/global/ui/shop-filter/uiShopFilterReducer';
import { ShopFilter, ProductTypes } from 'store/shop/shopModels';

import { ShopFilterActionFunctions } from '../common/shopFilter/filter-category';
import ShopFilterContainer from 'components/common/shopFilter/ShopFilterContainer';
import { ShopFilterType } from '../common/shopFilter/ShopFilter';
import { CategorySlider } from './CategorySlider/CategorySlider';
import { Tabs, Tab } from './Tabs/Tabs';

import { Products } from './Products/Products';
import { addProductToOrder } from './Order/Order';
import { lockBackgroundScroll, unlockBackgroundCallback } from 'websd/utils';
import { BackButton } from 'components/header/HeaderContent/BackButton';
import { TranslateFunction } from 'react-localize-redux';

export interface ShopFilterFunctions {
  ProductWho: ShopFilterActionFunctions;
  ProductWhere: ShopFilterActionFunctions;
  ProductWhen: ShopFilterActionFunctions;
  ProductHow: ShopFilterActionFunctions;
}

export interface ShopDataProps {
  isPublicWebMode: boolean;
  products: Array<fos.api.Product>;
  productsInBasket: Array<fos.api.Product>;
  filters: Array<fos.api.FilterCategory>;
  isDesktopMode: boolean;
  shopFoerening: number;
  isAuthenticated?: boolean;
  translate: TranslateFunction;
  isFetchingFilters: boolean;
  isFiltersFetched: boolean;
  filterUIState: UIShopFilterState;
  cachedUserFilters: ShopFilter;
  cachedFullSetOfFilterProperties: ShopFilter;
  filterFunctions: ShopFilterFunctions;
  selectedCategory: FilterCategoryType;
  foereningNamn: string;
  foereningDisplayName: string;
  isVisiting: boolean;
  isShowingProducts: boolean;
  orderId: number;
  isAdmin: boolean;
  adminError?: string;
  isPendingOrderChange: boolean;
}

export interface ShopActionProps {
  getFilters: (foereningId: number) => void;
  createBasket: (foereningId: number) => void;
  addProduct: (product: fos.api.Product, foereningId: number) => void;
  removeProduct: (product: fos.api.Product, foereningId: number, orderId: number) => void;
  goToLogin: () => void;
  onClearFilters: () => void;
  setSelectedCategory: (categoryName: FilterCategoryType) => void;
  goToProductDetails: (id: number, name: string, foereningNamn: string, customerType: number) => void;
  toggleShowProducts: (showing: boolean) => void;
}

export interface ShopState {
  selectedTab: Tab;
  tabs: Tab[];

  toggleButtonHasSomeFocus: boolean;
  unlockBackgroundCallback?: unlockBackgroundCallback;
}

export interface FilterData {
  ProductName: string;
  TargetGroup: string;
  When: string;
  ValidityPerdiod: string;
}

type ShopProps = Readonly<ShopDataProps & ShopActionProps>;

export const Shop: React.FunctionComponent<ShopProps> = ({
  isPublicWebMode,
  products,
  productsInBasket,
  filters,
  isDesktopMode,
  shopFoerening,
  isAuthenticated,
  translate,
  isFetchingFilters,
  isFiltersFetched,
  filterUIState,
  cachedUserFilters,
  cachedFullSetOfFilterProperties,
  filterFunctions,
  selectedCategory,
  foereningNamn,
  foereningDisplayName,
  isVisiting,
  isShowingProducts,
  orderId,
  isAdmin,
  adminError,
  isPendingOrderChange,
  getFilters,
  createBasket,
  addProduct,
  removeProduct,
  goToLogin,
  onClearFilters,
  setSelectedCategory,
  goToProductDetails,
  toggleShowProducts,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<Tab>(Tab.Local);
  const [tabs, setTabs] = React.useState<Tab[]>([]);
  const [toggleButtonHasSomeFocus, setToggleButtonHasSomeFocus] = React.useState<boolean>(false);
  const [unlockBackgroundCallback, setUnlockBackgroundCallback] = React.useState<
    unlockBackgroundCallback | undefined
  >();

  React.useEffect(() => {
    if (isShowingProducts) {
      setUnlockBackgroundCallback(lockBackgroundScroll());
    }
    return () => {
      if (unlockBackgroundCallback) {
        unlockBackgroundCallback();
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const tabs: Tab[] = [Tab.Local];
    if (!!products.find(p => !!p.Types.find(t => t === ProductTypes.Tvaafoereningskort))) {
      tabs.push(Tab.TwoBusinessUnits);
    }

    // Just nu kollar vi inte om det finns nationella träningskort, då det inte finns några sådana kort än.
    // if (!!newProps.products.find(p => !!p.Types.find(t => t === ProductTypes.Nationellt_Traeningskort))) {
    tabs.push(Tab.National);
    // }
    setTabs(tabs);
  }, [products]);

  React.useEffect(() => {
    if (!isFetchingFilters && !isFiltersFetched) {
      getFilters(shopFoerening);
      createBasket(shopFoerening);
    }
  }, [isFetchingFilters, isFiltersFetched, getFilters, createBasket, shopFoerening]);

  React.useEffect(() => {
    if (!isAuthenticated && !isVisiting) {
      goToLogin();
    }
  }, [isAuthenticated, isVisiting, goToLogin]);

  const toggleProducts = () => {
    if (!!unlockBackgroundCallback) {
      unlockBackgroundCallback();
    }
    if (!isShowingProducts) {
      setUnlockBackgroundCallback(lockBackgroundScroll());
    }

    toggleShowProducts(!isShowingProducts);
  };

  const giveToggleButtonSomeFocus = () => {
    setToggleButtonHasSomeFocus(true);
    setTimeout(() => {
      setToggleButtonHasSomeFocus(false);
    }, 100);
  };

  const categoryId =
    selectedCategory === 'Träningskort'
      ? selectedTab === Tab.TwoBusinessUnits
        ? ProductTypes.Tvaafoereningskort
        : selectedTab === Tab.National
        ? ProductTypes.Nationellt_Traeningskort
        : ProductTypes.Traeningskort
      : ProductTypes.Medlemskap;
  return (
    <React.Fragment>
      {!!adminError && <div className="AdminError">{adminError}</div>}
      <div className={'Shop ' + (isPublicWebMode ? 'Shop--public' : '') + (isShowingProducts ? ' showP' : ' hideP')}>
        <div className="Shop__newFilterContainer">
          <CategorySlider
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            translate={translate}
          />

          {selectedCategory === 'Träningskort' && (
            <React.Fragment>
              <Tabs
                translate={translate}
                tabs={tabs}
                setSelectedTab={tab => setSelectedTab(tab)}
                selectedTab={selectedTab}
                foereningNamn={foereningDisplayName}
              />
              <ShopFilterContainer
                onHideProductClick={toggleProducts}
                totalNumberOfItems={products.length}
                maxNumberOfItems={200}
                translate={translate}
                isDesktopMode={isDesktopMode}
                scheduleIsFetching={isFetchingFilters}
                isPublicWebMode={isPublicWebMode}
                filterHidden={false}
                filterUIState={filterUIState}
                filterableProperties={cachedFullSetOfFilterProperties}
                filterFunctions={filterFunctions}
                onClearFilters={onClearFilters}
                hideFilterSaving={true}
                productsIsShowing={isShowingProducts}
                shopFilter={filters} // Filter från Sitecore
                userFilters={cachedUserFilters} // Användarens cachade filter
                filterType={ShopFilterType.Shop} // Shop eller Schema
                selectedTab={selectedTab}
                giveToggleButtonSomeFocus={giveToggleButtonSomeFocus}
              />
            </React.Fragment>
          )}

          {selectedCategory === 'Medlemskap' && (
            <React.Fragment>
              {isShowingProducts && (
                <React.Fragment>
                  <div className="newFilter__Selection">
                    <div className="Inner__Container">
                      <BackButton icon="chevron" goBack={() => toggleProducts()} />
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="Cards">
                <div className="Cards__inner">
                  <h5>{translate(SitecoreDictionary.Shop.MembershipTitle)}</h5>
                  <p>{translate(SitecoreDictionary.Shop.MembershipDescription)}</p>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        {selectedTab !== Tab.National && (
          <Products
            translate={translate}
            isShowing={isShowingProducts}
            shopFilter={selectedCategory === 'Träningskort' ? filters : []}
            filters={selectedCategory === 'Träningskort' ? filters : []}
            products={products}
            canAddProduct={(product: fos.api.Product) =>
              canAddProduct(product, translate, productsInBasket, isPendingOrderChange)
            }
            addProduct={(product: fos.api.Product) => {
              addProduct(product, shopFoerening);
              addProductToOrder(shopFoerening, orderId, product);
            }}
            removeProduct={(product: fos.api.Product) => {
              removeProduct(product, shopFoerening, orderId);
            }}
            foereningNamn={foereningNamn}
            productsInBasket={productsInBasket}
            goToProductDetails={goToProductDetails}
            productType={categoryId}
            isAdmin={isAdmin}
          />
        )}

        {selectedTab !== Tab.National && (
          <div className="ShopButtonWrapper">
            <div
              className={
                'Shop__ToggleProducts ' + (toggleButtonHasSomeFocus ? 'Shop__ToggleProducts--receiveSomeFocus' : '')
              }
              onClick={() => toggleProducts()}
            >
              {!isShowingProducts
                ? translate(SitecoreDictionary.Shop.DisplayProducts)
                : translate(SitecoreDictionary.Shop.HideProducts)}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
