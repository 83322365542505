import { Reducer } from 'redux';
import { Action } from 'store';
import { localStorage, isAccountNumberValid, isClearingNumberValid } from 'utils';
import { BasketActionTypes } from './';

const BASKET_STORAGE_PREFIX = 'BASKET::';

export interface BasketRootState {
  products: fos.api.Product[];
  valuta?: string;
  foereningId?: number;
  anlaeggningId?: number;
  errorOnPurchase?: string;
  errorsOnCreatePayment?: string[];
  errorsOnCreateUser?: string[];
  showMembershipMessage?: boolean;
  showCouponMessage?: boolean;
  autogiro: {
    clearingNumber: string;
    accountNumber: string;
    isAccountNumberValid?: boolean;
    isClearingNumberValid?: boolean;
  };
  paymentMethodId?: number;
  phoneNumber?: string;
  order?: fos.api.Order;
}

const initialState: BasketRootState = {
  products: [],
  autogiro: {
    clearingNumber: '',
    accountNumber: '',
  },
  showMembershipMessage: false,
  showCouponMessage: false,
};

export const BasketReducer: Reducer<BasketRootState> = (state = initialState, action: Action): BasketRootState => {
  let updatedState: BasketRootState = state;
  let shouldUpdateStorage: boolean = true;

  switch (action.type) {
    case BasketActionTypes.CREATE_BASKET:
      {
        const cachedBasket: BasketRootState | null = localStorage.getItemFromSession<BasketRootState>(
          BASKET_STORAGE_PREFIX + action.foereningId
        );
        updatedState = !cachedBasket
          ? {
              ...initialState,
              valuta: action.valuta,
              foereningId: action.foereningId,
            }
          : {
              ...(cachedBasket as BasketRootState),
              valuta: action.valuta || cachedBasket.valuta,
              autogiro: {
                ...initialState.autogiro,
              },
              foereningId: action.foereningId,
            };
      }
      break;
    case BasketActionTypes.UPDATE_BASKET:
        // @Speed bleh
        updatedState.products = JSON.parse(JSON.stringify(action.products));

        localStorage.saveItemInSession(BASKET_STORAGE_PREFIX + action.foereningId, {
          ...updatedState,
          autogiro: {
            ...initialState.autogiro,
          },
        });
      break;
    case BasketActionTypes.DELETE_BASKET:
      shouldUpdateStorage = false;
      localStorage.removeItemFromSession(BASKET_STORAGE_PREFIX + action.foereningId);
      updatedState = initialState;
      break;
    case BasketActionTypes.ADD_PRODUCT:
        if (updatedState.products) {
          if (!existsInArray(updatedState.products, action.product) && action.foereningId !== undefined) {
            // Avoiding mutation
            updatedState.products = [...updatedState.products, action.product];
          }
        } else {
          updatedState.products = [action.product];
        }
        if (updatedState.foereningId !== action.foereningId && !!action.foereningId) {
          updatedState.foereningId = action.foereningId;
        }
        localStorage.saveItemInSession(BASKET_STORAGE_PREFIX + action.foereningId, {
          ...updatedState,
          autogiro: {
            ...initialState.autogiro,
          },
        });
      break;
    case BasketActionTypes.SHOW_MEMBERSHIP_MESSAGE:
      updatedState = {
        ...state,
        showMembershipMessage: action.showMembershipMessage,
      };
      break;
    case BasketActionTypes.SHOW_COUPON_MESSAGE:
      updatedState = {
        ...state,
        showCouponMessage: action.showCouponMessage,
      };
      break;
    case BasketActionTypes.DELETE_PRODUCT:
      if (action.foereningId !== state.foereningId) {
        break;
      }
      if (!state.products) {
        break;
      }
      const newProducts: fos.api.Product[] = [];
      state.products.forEach(p => {
        if ('' + p.Id !== '' + action.product.Id) {
          newProducts.push(p);
        }
      });
      updatedState.products = newProducts;
      localStorage.saveItemInSession('BASKET::' + action.foereningId, {
        ...updatedState,
      });
      break;
    case BasketActionTypes.ERROR_ON_PURCHASE:
      shouldUpdateStorage = false;
      updatedState = {
        ...state,
        errorOnPurchase: action.error,
      };
      break;
    case BasketActionTypes.ERROR_ON_CREATE_PAYMENT: {
      updatedState = { ...state, errorsOnCreatePayment: action.errors };
      break;
    }
    case BasketActionTypes.ERROR_ON_CREATE_USER: {
      updatedState = { ...state, errorsOnCreateUser: action.errors };
      break;
    }
    case BasketActionTypes.UPDATE_AUTOGIRO_ACCOUNT_NUMBER: {
      updatedState = {
        ...state,
        autogiro: {
          ...state.autogiro,
          accountNumber: action.value,
          isAccountNumberValid: isAccountNumberValid(action.value),
        },
      };
      break;
    }
    case BasketActionTypes.UPDATE_AUTOGIRO_CLEARING_NUMBER: {
      updatedState = {
        ...state,
        autogiro: {
          ...state.autogiro,
          clearingNumber: action.value,
          isClearingNumberValid: isClearingNumberValid(action.value),
        },
      };
      break;
    }
    case BasketActionTypes.UPDATE_PAYMENT_METHOD: {
      updatedState = {
        ...state,
        paymentMethodId: action.paymentMethodId,
      };
      break;
    }
    case BasketActionTypes.UPDATE_PHONE_NUMBER: {
      updatedState = {
        ...state,
        phoneNumber: action.phoneNumber,
      };
      break;
    }
    case BasketActionTypes.UPDATE_ORDER: {
      updatedState = {
        ...state,
        order: action.order,
      };
      break;
    }
    default:
      shouldUpdateStorage = false;
      break;
  }
  if (shouldUpdateStorage && updatedState.foereningId) {
    localStorage.saveItemInSession(BASKET_STORAGE_PREFIX + updatedState.foereningId, {
      ...updatedState,
      autogiro: {
        ...initialState.autogiro,
      },
    });
  }
  return updatedState;
};

function existsInArray(arr: Array<fos.api.Product>, product: fos.api.Product) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].Id === product.Id) {
      return true;
    }
  }
  return false;
}
