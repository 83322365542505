import { SetCalendarSyncAction } from '.';
import { CalendarActionTypes, SetSelectedCalendarAction, SetShouldShowCalendarSyncPopupAction } from './calendarModels';

export const toggleSyncCalendar = (syncCalendar: boolean): SetCalendarSyncAction => ({
  type: CalendarActionTypes.SetCalendarSync,
  syncCalendar,
});

export const setSelectedCalendarId = (id?: string): SetSelectedCalendarAction => ({
  type: CalendarActionTypes.SetSelectedCalendar,
  id,
});

export const setShouldShowCalendarSyncPopup = (show: boolean): SetShouldShowCalendarSyncPopupAction => ({
  type: CalendarActionTypes.SetShouldShowCalendarSyncPopup,
  show,
});

export type CalendarActions = SetCalendarSyncAction | SetSelectedCalendarAction | SetShouldShowCalendarSyncPopupAction;
