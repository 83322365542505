import {
  FetchStatusAggregateTypes,
  FetchCommandTypes,
  ContextType,
  ContextTypes,
  EntityStatus,
  CommandStatus,
} from './fetchModels';

export type FetchAction =
  | InitialFetchgAction
  | FetchingAction
  | FetchingInBackgroundAction
  | FetchedFailedAction
  | FetchedAction
  | SavingAction
  | SavedFailedAction
  | SavedAction
  | DeleteAction
  | InitialCommandAction
  | ExecutingAction
  | ExecutingFailedAction
  | ExecutingSuccessfulAction;

export type FetchAggregateTypeBase = FetchBase & {
  id: number | string;
  aggregateType: FetchStatusAggregateTypes;
  commandType: undefined;
};

export type FetchCommandTypeBase = FetchBase & {
  id: number | string;
  aggregateType: undefined;
  commandType: FetchCommandTypes;
};

export type FetchBase = {
  contextType: ContextType;
};

export type InitialFetchgAction = FetchAggregateTypeBase & {
  type: EntityStatus.INITIAL;
};

export type FetchingAction = FetchAggregateTypeBase & {
  type: EntityStatus.FETCHING;
};

export type FetchingInBackgroundAction = FetchAggregateTypeBase & {
  type: EntityStatus.FETCHING_IN_BACKGROUND;
};

export type FetchedFailedAction = FetchAggregateTypeBase & {
  type: EntityStatus.FAILED;
};

export type FetchedAction = FetchAggregateTypeBase & {
  type: EntityStatus.SUCCESS;
  // tslint:disable-next-line:no-any
  entity: any;
};

export type SavingAction = FetchAggregateTypeBase & {
  type: EntityStatus.SAVING;
};

export type SavedFailedAction = FetchAggregateTypeBase & {
  type: EntityStatus.SAVING_FAILED;
  reason: string;
};

export type SavedAction = FetchAggregateTypeBase & {
  type: EntityStatus.SUCCESS;
  // tslint:disable-next-line:no-any
  entity: any;
};

export type DeleteAction = FetchAggregateTypeBase & {
  type: EntityStatus.DELETED;
};
export type InitialCommandAction = FetchCommandTypeBase & {
  type: CommandStatus.INITIAL;
};
export type ExecutingAction = FetchCommandTypeBase & {
  type: CommandStatus.EXECUTING;
};

export type ExecutingFailedAction = FetchCommandTypeBase & {
  type: CommandStatus.EXECUTING_FAILED;
};

export type ExecutingSuccessfulAction = FetchCommandTypeBase & {
  type: CommandStatus.EXECUTED;
};

export const initialFetch = (
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): InitialFetchgAction => ({
  type: EntityStatus.INITIAL,
  id,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const fetching = (
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): FetchingAction => ({
  type: EntityStatus.FETCHING,
  id,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const fetchingInBackground = (
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): FetchingInBackgroundAction => ({
  type: EntityStatus.FETCHING_IN_BACKGROUND,
  id,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const fetched =
  // tslint:disable:no-any
  (
    id: number | string,
    aggregateType: FetchStatusAggregateTypes,
    entity: any,
    contextType: ContextType = ContextTypes.MITT_FRISKIS
  ): FetchedAction => ({
    type: EntityStatus.SUCCESS,
    id,
    aggregateType,
    commandType: undefined,
    contextType,
    entity,
  });
// tslint:enable:no-any

export const fetchedFailed = (
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): FetchedFailedAction => ({
  type: EntityStatus.FAILED,
  id,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const saving = (
  id: number | string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): SavingAction => ({
  type: EntityStatus.SAVING,
  id,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const saveFailed = (
  id: number | string,
  reason: string,
  aggregateType: FetchStatusAggregateTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): SavedFailedAction => ({
  type: EntityStatus.SAVING_FAILED,
  id,
  reason,
  aggregateType,
  commandType: undefined,
  contextType,
});

export const saved =
  // tslint:disable:no-any
  (
    id: number | string,
    entity: any,
    aggregateType: FetchStatusAggregateTypes,
    contextType: ContextType = ContextTypes.MITT_FRISKIS
  ): SavedAction => ({
    type: EntityStatus.SUCCESS,
    id,
    entity,
    aggregateType,
    commandType: undefined,
    contextType,
  });
// tslint:enable:no-any

export const initialCommand = (
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): InitialCommandAction => ({
  type: CommandStatus.INITIAL,
  id,
  aggregateType: undefined,
  commandType,
  contextType,
});
export const executingCommand = (
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): ExecutingAction => ({
  type: CommandStatus.EXECUTING,
  id,
  aggregateType: undefined,
  commandType,
  contextType,
});

export const executedCommand = (
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): ExecutingSuccessfulAction => ({
  type: CommandStatus.EXECUTED,
  id,
  aggregateType: undefined,
  commandType,
  contextType,
});

export const executingCommandFailed = (
  id: string,
  commandType: FetchCommandTypes,
  contextType: ContextType = ContextTypes.MITT_FRISKIS
): ExecutingFailedAction => ({
  type: CommandStatus.EXECUTING_FAILED,
  id,
  aggregateType: undefined,
  commandType,
  contextType,
});
