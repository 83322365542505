import { Reducer } from 'redux';
import { Action } from 'store';
import { LoginCmdActionTypes } from '.';

export interface LoginCmdState {
  email: string;
  password: string;
  foereningId: number;
}

export const initialState: LoginCmdState = {
  email: '',
  password: '',
  foereningId: -1,
};

export const loginCmdReducer: Reducer<LoginCmdState> = (state = initialState, action: Action) => {
  switch (action.type) {
    case LoginCmdActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case LoginCmdActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case LoginCmdActionTypes.UPDATE_FOERENINGID:
      return {
        ...state,
        foereningId: action.foereningId,
      };
    case LoginCmdActionTypes.CLEAR_CMD:
      return initialState;
    default:
      return state;
  }
};
