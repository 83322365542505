import * as React from 'react';
import { SitecoreDictionary } from 'models';
import { MainButton } from '../common';

import { Spinner } from '../common/Spinner';
import { TranslateFunction } from 'react-localize-redux';

export interface BokningStatusDataProps {
  statusMessage?: string;
  isLoading: boolean;
  translate: TranslateFunction;
}

interface BokningStatusPopupState {
  statusMessage?: string;
}

export class BokningStatusPopup extends React.Component<BokningStatusDataProps, BokningStatusPopupState> {
  constructor(props: BokningStatusDataProps) {
    super(props);
    this.state = {
      statusMessage: props.statusMessage,
    };
  }

  componentDidUpdate() {
    if (!!this.props.statusMessage && this.state.statusMessage !== this.props.statusMessage) {
      this.setState({ ...this.state, statusMessage: this.props.statusMessage });
    }
  }

  render() {
    return (
      <div className="BokningStatusPopup">
        <div className={this.getStatusClass()}>
          {this.props.isLoading && <Spinner appearance="white" />}
          {!this.props.isLoading && <span>{this.getStatusMessage()}</span>}
        </div>
        <div className={'BokningStatusPopup__Content ' + this.getStatusClassSmall()}>
          <div>{this.props.translate(SitecoreDictionary.Schedule.PublicWeb.MittFriskisHeader)}</div>

          <div className="BokningStatusPopup__GoToMittFriskis">
            <MainButton
              title={this.props.translate(SitecoreDictionary.Schedule.PublicWeb.MittFriskisLink) as string}
              handleOnClick={() => this.sendEventToSitecore()}
            />
          </div>
        </div>
      </div>
    );
  }

  private sendEventToSitecore() {
    window.parent.postMessage('GotoMittFriskis', process.env.REACT_APP_FOS_URL as string);
  }

  private getStatusMessage() {
    return this.state.statusMessage ? this.props.translate(this.state.statusMessage) : false;
  }

  private getStatusClass() {
    if (
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.OK_BOOK ||
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.QUEUED ||
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return 'BokningStatusPopup__Status BokningStatusPopup__Status--Ok';
    } else {
      return 'BokningStatusPopup__Status BokningStatusPopup__Status--Error';
    }
  }
  private getStatusClassSmall() {
    if (
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.OK_BOOK ||
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.QUEUED ||
      this.state.statusMessage === SitecoreDictionary.Schedule.Messages.OK_CANCEL
    ) {
      return 'BokningStatusPopup__Content--booking-ok';
    } else {
      return 'BokningStatusPopup__Content--error';
    }
  }
}
