import * as React from 'react';

export interface IconCheckMarkProps {
  readonly className?: string;
  readonly strokeWidth?: number;
  readonly selected?: boolean;
  title?: string;
}

export class IconCheckmark extends React.Component<IconCheckMarkProps, {}> {
  strokeWidth: number = this.props.strokeWidth || 1;

  render() {
      /* tslint:disable */
    return (
      <svg
        className={
          'IconCheckmark ' +
          (this.props.className ? this.props.className : '') +
          (!this.props.selected ? 'IconCheckmark--gray' : 'IconCheckmark--white')
        }
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 23 18"
        enableBackground="new 0 0 23.3 16"
      >
        {this.props.title && <title>{this.props.title}</title>}
        <polyline className="st0" points="1,6.7 7.4,14 21.3,0" />
      </svg>
    );
  }
}
