import { RootState, Dispatch } from 'store';
import { NewUserFormActionProps, NewUserFormDataProps, NewUserForm, NewUserFormInternalProps } from './NewUserForm';
import { connect } from 'react-redux';
import { createUser } from 'store/basket';
import {
  updateSSN,
  updateAnlaeggningsId,
  updateFirstName,
  updateLastName,
  updateEmail,
  updateTelephone,
  updateStreet,
  updateZipCode,
  updateCity,
  updatePassword,
  updateRepeatPassword,
  updateHasNoSwedishSsn,
} from 'store/user/createUserCmd';
import {
  FetchCommandTypes,
  getIsExecutingFailed,
  getEntityStatus,
  FetchStatusAggregateTypes,
  getIsExecuting,
} from 'store/global/fetch';
import { getPersonLookup, getPersonLookupAvailable } from 'store/user';
import { ProductPaymentMethod } from 'models';
import { SiteSwitcherAction, SiteSwitcherStatus } from 'store/global/ui/siteswitcher';
import { getTranslate } from 'store/global/dictionary';
import { fetchAnlaeggningar } from 'store/global/ui/anlaeggningar';

const setSiteSwitcherToVisible = () => (dispatch: Dispatch<SiteSwitcherAction>) => {
  dispatch({
    type: 'UI_SITESWITCHER_SHOW',
    visibility: SiteSwitcherStatus.SHOWING,
  });
};

const mapStateToProps = (state: RootState, props: NewUserFormInternalProps): NewUserFormDataProps => ({
  foereningId: state.global.session.shopFoerening,
  location: state.location,
  personnr: state.user.createUserCmd.personnr,
  foernamn: state.user.createUserCmd.foernamn,
  efternamn: state.user.createUserCmd.efternamn,
  epost: state.user.createUserCmd.epost,
  mobiltelefonnr: state.user.createUserCmd.mobiltelefonnr,
  gata: state.user.createUserCmd.gata,
  postkod: state.user.createUserCmd.postkod,
  stad: state.user.createUserCmd.stad,
  loesenord: state.user.createUserCmd.loesenord,
  loesenordRepeat: state.user.createUserCmd.loesenordRepeat,
  hasNoSwedishSsn: state.user.createUserCmd.hasNoSwedishSsn,
  isExecutingFailed: getIsExecutingFailed(state.global.fetch, '-1', FetchCommandTypes.SKAPA_ANVAENDARE),
  errorMessages:
    state.shoppingBasket.errorsOnCreatePayment ||
    state.shoppingBasket.errorsOnCreateUser ||
    (!!state.shoppingBasket.errorOnPurchase ? [state.shoppingBasket.errorOnPurchase] : undefined),
  basket: state.shoppingBasket.products,
  anlaeggningsId: state.user.createUserCmd.anlaeggningsId,
  anlaeggningar: state.global.ui.anlaeggningar.anlaeggningar,
  lookupStatus: state.user.createUserCmd.lookupStatus,
  hasDebitProductInBasket: !!state.shoppingBasket.products.find(p => p.PaymentMethod === ProductPaymentMethod.AutoGiro),
  translate: getTranslate(state.locale),
  fetchStateAnlaeggningar: getEntityStatus(state.global.fetch, -1, FetchStatusAggregateTypes.ANLAEGGNINGAR),
  isCreatingUser: getIsExecuting(state.global.fetch, '-1', FetchCommandTypes.SKAPA_ANVAENDARE),
});

const mapActionsToProps: NewUserFormActionProps = {
  updateSSN,
  updateAnlaeggningsId,
  updateFirstName,
  updateLastName,
  updateEmail,
  updateTelephone,
  updateStreet,
  updateZipCode,
  updateCity,
  updatePassword,
  updateRepeatPassword,
  updateHasNoSwedishSsn,
  createUser,
  getPersonLookupAvailable,
  getPersonLookup,
  setSiteSwitcherToVisible: setSiteSwitcherToVisible,
  fetchAnlaeggningar,
};

export const NewUserFormContainer = connect(mapStateToProps, mapActionsToProps)(NewUserForm);
