import * as React from 'react';

export interface IconRoundButtonCheckMarkProps {
  readonly selected?: boolean;
}

export class IconRoundButtonCheckmark extends React.Component<IconRoundButtonCheckMarkProps, {}> {
  render() {
    return (
      <div className={'IconRoundButtonCheckmark' + (this.props.selected ? ' IconRoundButtonCheckmark--selected' : '')}>
        {this.props.selected && (
          <svg
            className={'IconRoundButtonCheckmark__IconCheckmark'}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <polyline className="st0" points="6,13 10,18 18,8" />
          </svg>
        )}
      </div>
    );
  }
}
