import { Action } from 'store';
import { ActionType } from './notificationsActions';

export interface NotificationsRootState {
  notifications: Array<fos.api.Notification>;
}

const initialState: NotificationsRootState = {
  notifications: [],
};

export const NotificationsReducer = (state = initialState, action: Action): NotificationsRootState => {
  switch (action.type) {
    case ActionType.GetNotifications:
      return {
        ...state,
        notifications: action.notifications,
      };
    default:
      return state;
  }
};
