import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { goToSchedule, goBack } from 'store/location';
import {
  SideBarAction,
  SidebarStatus,
  SidebarContentType,
  SidebarSize,
  showSideBarInFullDesktopWidth,
} from 'store/global/ui/side-bar';
import {
  ScheduleLauncherDataProps,
  ScheduleLauncherActionProps,
  ScheduleLauncher,
  ScheduleLauncherExternalDataProps,
} from './ScheduleLauncher';
import { UIScheduleAction, UIScheduleActionType } from 'store/global/ui/schedule';
import { UIScheduleViewType } from 'store/global/ui/schedule/uiScheduleReducer';

const setSidebarToContainSchedule = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_SET_CONTENT_TYPE',
    contentType: SidebarContentType.SCHEDULE,
  });
};
const setSidebarToVisible = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_LAUNCH',
    visibility: SidebarStatus.SHOWING,
  });
};
const setSidebarToHidden = () => (dispatch: Dispatch<SideBarAction>) => {
  dispatch({
    type: 'UI_SIDEBAR_HIDE',
    visibility: SidebarStatus.HIDDEN,
  });
};
const setSidebarToMaximised = (isSideBarMaximised: boolean) => (dispatch: Dispatch<SideBarAction>) => {
  if (!isSideBarMaximised) {
    dispatch({
      type: 'UI_SIDEBAR_SET_SIZE_MAXIMISED',
      size: SidebarSize.MAXIMISED,
    });
  }
};
export const setSidebarToMinimised =
  (isSideBarMaximised: boolean) => (dispatch: Dispatch<SideBarAction | UIScheduleAction>) => {
    if (isSideBarMaximised) {
      // Vi vill att detta endast ska ske för desktop, men detta körs endast för desktop, så....
      dispatch(goBack());

      dispatch({
        type: 'UI_SIDEBAR_SET_SIZE_MINIMISED',
        size: SidebarSize.MINIMISED,
      });
      // Change the selected icon to show ListView as highlighted
      dispatch({
        type: UIScheduleActionType.SetViewTypeToList,
        viewShowing: UIScheduleViewType.LISTVIEW,
      });
    }
  };

const mapStateToProps = (state: RootState, props: ScheduleLauncherExternalDataProps): ScheduleLauncherDataProps => {
  const expanded = state.global.ui.scheduleFilter.expanded;
  return {
    expanded: expanded,
    sideBarState: state.global.ui.sideBar,
    isSideBarMaximised: showSideBarInFullDesktopWidth(state.global.ui.sideBar, state.global.ui.schedule),

    ...props,
  };
};

const mapDispatchToProps: ScheduleLauncherActionProps = {
  setSidebarToContainSchedule: setSidebarToContainSchedule,
  setSidebarToVisible: setSidebarToVisible,
  setSidebarToHidden: setSidebarToHidden,
  setSidebarToMaximised: setSidebarToMaximised,
  setSidebarToMinimised: setSidebarToMinimised,
  goToSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleLauncher);
