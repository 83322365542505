import * as React from 'react';
import { NewPassword } from 'components/common';

import { SitecoreDictionary } from 'models';
import { LocationState } from 'store/location';
import { TranslateFunction } from 'react-localize-redux';

interface AccountFormDataProps {
  translate: TranslateFunction;
  isDesktopMode: boolean;
  password: string;
  repeatPassword: string;
  isPasswordValid: boolean;
  isRepeatPasswordValid: boolean;
  location: LocationState;
}

interface AccountFormActionProps {
  updatePassword: (value: string) => void;
  updateRepeatPassword: (value: string) => void;
  updatePasswordValidity: (isValid: boolean) => void;
  updateRepeatPasswordValidity: (isValid: boolean) => void;
}

export type AccountFormProps = AccountFormDataProps & AccountFormActionProps;

export class AccountForm extends React.Component<AccountFormProps> {

  render() {
    return (
      <div className="AccountForm">
        <h5>{this.props.translate(SitecoreDictionary.Cart.AccountTitle)}</h5>
        <NewPassword
          translate={this.props.translate}
          passwordValue={this.props.password}
          repeatPasswordValue={this.props.repeatPassword}
          updatePassword={this.props.updatePassword}
          updateRepeatPassword={this.props.updateRepeatPassword}
          isPasswordValid={this.props.isPasswordValid}
          isRepeatPasswordValid={this.props.isRepeatPasswordValid}
          updatePasswordValidity={this.props.updatePasswordValidity}
          updateRepeatPasswordValidity={this.props.updateRepeatPasswordValidity}
          location={this.props.location}
        />
        <br />
      </div>
    );
  }
}
