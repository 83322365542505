import * as React from 'react';

export interface IconOnlineWorkoutProps {
  title?: string;
}

export class IconOnlineWorkout extends React.Component<IconOnlineWorkoutProps, {}> {
  defaultStyle = 'fill:none;stroke:#8B8F92;stroke-linecap:round;stroke-linejoin:round;';
  activeStyle = 'fill:none;stroke:#e31836;stroke-linecap:round;stroke-linejoin:round;';

  render() {
    return (
      <svg
        width="78px"
        height="78px"
        viewBox="0 0 78 78"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="IconOnlineWorkout"
      >
        <style type="text/css">
          .IconOnlineWorkout .inactive{'{' + this.defaultStyle + '}'}
          .IconOnlineWorkout .active{'{' + this.activeStyle + '}'}
          .activeMenu .inactive{'{' + this.activeStyle + '}'}
        </style>
        <title>{this.props.title}</title>
        {/* tslint:disable */}
        <g
          xmlns="http://www.w3.org/2000/svg"
          id="Symbols"
          stroke="none"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g id="Object-stream-icon" transform="translate(-1.000000, 0.000000)" stroke="#231F20">
            <g transform="translate(2.000000, 1.000000)">
              <path
                d="M38.0066108,76 C58.9918235,76 76,58.9926926 76,38.0052892 C76,17.0125966 58.9918235,0 38.0066108,0 C17.0187537,0 0,17.0125966 0,38.0052892 C0,58.9926926 17.0187537,76 38.0066108,76 Z"
                id="Stroke-3397"
              />
              <path
                d="M50.9951027,35.8746963 L33.2214139,21.575021 C31.5052438,20.1952513 29,21.454224 29,23.698363 L29.0026042,52.3030824 C29.0026042,54.544537 31.507848,55.8061941 33.2214139,54.4237401 L50.9951027,40.1213804 C52.3362675,39.0422609 52.3336633,36.9538158 50.9951027,35.8746963 Z"
                id="Stroke-3398"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
