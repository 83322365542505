import * as React from 'react';

// import { SitecoreDictionary } from 'models';

export interface PopOverProps {
  constraintElement: HTMLElement;
  skipArrow?: boolean;
  horizontalPadding?: number;
  verticalPadding?: number;
  children?: string | JSX.Element | Array<JSX.Element>;
  popoverClassName?: string;
  popoverContainerClassName?: string;
}

interface PopOverState {}

export class PopOver extends React.Component<PopOverProps, PopOverState> {
  constructor(props: PopOverProps) {
    super(props);
    this.state = {};
  }

  getContainerStyle = (containerRef: HTMLDivElement): { marginLeft: string; marginRight: string; height: string } => {
    const style: { marginLeft: string; marginRight: string; height: string } = {
      marginLeft: '',
      marginRight: '',
      height: '',
    };
    containerRef.style.marginRight = '0px';
    containerRef.style.marginLeft = '0px';
    const containerRect = containerRef.getBoundingClientRect();
    const constraintRect = this.props.constraintElement.getBoundingClientRect();
    if (containerRect.left <= constraintRect.left) {
      const m = (constraintRect.left - containerRect.left) * 2;
      if (this.props.horizontalPadding) {
        style.marginLeft = m + this.props.horizontalPadding * 2 + 'px';
      } else {
        style.marginLeft = m + 'px';
      }
    } else {
      style.marginLeft = '0px';
    }
    if (containerRect.right >= constraintRect.right) {
      const m = (containerRect.right - constraintRect.right) * 2;
      if (this.props.horizontalPadding) {
        style.marginRight = m + this.props.horizontalPadding * 2 + 'px';
      } else {
        style.marginRight = m + 'px';
      }
    } else {
      style.marginRight = '0px';
    }
    if (containerRect.bottom + (this.props.verticalPadding || 0) >= window.innerHeight) {
      style.height =
        containerRect.height - (containerRect.bottom - window.innerHeight) - (this.props.verticalPadding || 0) + 'px';
    } else {
      style.height = 'unset';
    }
    return style;
  };

  render() {
    // const translate = this.props.translate;
    return (
      <div
        className={`PopOver${this.props.popoverClassName ? ' ' + this.props.popoverClassName : ''}${
          !this.props.skipArrow ? ' PopOver--hasArrow' : ''
        }`}
      >
        <div
          ref={r => {
            if (r) {
              const style = this.getContainerStyle(r);
              r.style.marginRight = style.marginRight || '0px';
              r.style.marginLeft = style.marginLeft;
              r.style.height = style.height;
            }
          }}
          className={`PopOver__Container${
            this.props.popoverContainerClassName ? ' ' + this.props.popoverContainerClassName : ''
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
