import { ThunkAction } from './../rootReducer';
import { api } from 'api';
import { getNotifications } from '.';
import {
  executingCommand,
  FetchCommandTypes,
  executingCommandFailed,
  executedCommand,
  fetching,
  FetchStatusAggregateTypes,
  fetched,
  fetchedFailed,
} from 'store/global/fetch';
import { fetchNewNotificationsCount } from 'store/global/ui/newNotification';

export const fetchNotifications = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (state.global.session.userId) {
    dispatch(fetching(state.global.session.userId, FetchStatusAggregateTypes.NOTIFICATIONS));
  }

  const promise = api.get<Array<fos.api.Notification>>('Notis/GetNotiser', {
    muteErrorNotificationOnStatus: [400],
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(getNotifications(result.result));
      if (state.global.session.userId) {
        dispatch(markNotificationsAsRead(state.global.session.userId));
        dispatch(fetched(state.global.session.userId, FetchStatusAggregateTypes.NOTIFICATIONS, result));
      }
    } else {
      if (state.global.session.userId) {
        dispatch(fetchedFailed(state.global.session.userId, FetchStatusAggregateTypes.NOTIFICATIONS));
      }
    }
    dispatch(fetchNewNotificationsCount());
  });
  return promise;
};

export const markNotificationsAsRead = (userid: number): ThunkAction => (dispatch, getState) => {
  dispatch(executingCommand(userid.toString(), FetchCommandTypes.MARK_NOTIFICATIONS_AS_READ));

  const promise = api.post<never, number>('Notis/MarkeraNotiserSomLaest', userid, {
    // muteErrorNotificationOnStatus: [400]
  });
  promise.then(result => {
    if (!result.error) {
      dispatch(executedCommand(userid.toString(), FetchCommandTypes.MARK_NOTIFICATIONS_AS_READ));
    } else {
      dispatch(executingCommandFailed(userid.toString(), FetchCommandTypes.MARK_NOTIFICATIONS_AS_READ));
    }
    dispatch(fetchNewNotificationsCount());
  });
  return promise;
};
