import * as React from 'react';
import { SettingsPageHeader } from './settingsPageHeader';
import { Banner } from 'components/common';
import { SettingsPages } from '.';
import PaymentSettings from './paymentSettings';

import { SitecoreDictionary } from 'models';
import { CSSTransition } from 'react-transition-group';
import Profile from './profile';
import UserAccount from './userAccount';
import NotificationSettings from './notification-settings';
import TrainingPreferences from './trainingpreferences';
import CalendarSyncContainer from './calendarSync/';
import { TranslateFunction } from 'react-localize-redux';

export interface SettingBannerProps {
  className?: string;
  activeSettingsPage?: SettingsPages;
  translate: TranslateFunction;
  isDesktopMode: boolean;
  settingsPageTitle?: string;
  image: string;
}

export const SettingBanner: React.SFC<SettingBannerProps> = props => {
  const getSettingsPage = (key: SettingsPages) => {
    switch (key) {
      case SettingsPages.Profile:
        return <Profile />;
      case SettingsPages.UserAccount:
        return <UserAccount />;
      case SettingsPages.PaymentSettings:
        return <PaymentSettings />;
      case SettingsPages.NotificationSettings:
        return <NotificationSettings />;
      case SettingsPages.CalendarSync:
        return <CalendarSyncContainer />;
      case SettingsPages.TrainingPreferences:
        return <TrainingPreferences />;
      default:
        return null;
    }
  };

  const getSettingsDescription = (key: SettingsPages) => {
    switch (key) {
      case SettingsPages.Profile:
        return props.translate(SitecoreDictionary.Profile.SectionDescription.Profile) as string;
      case SettingsPages.UserAccount:
        return props.translate(SitecoreDictionary.Profile.SectionDescription.Account) as string;
      case SettingsPages.PaymentSettings:
        return props.translate(SitecoreDictionary.Profile.SectionDescription.PaymentMethods) as string;
      case SettingsPages.NotificationSettings:
        return props.translate(SitecoreDictionary.Profile.SectionDescription.NotificationsSettings) as string;
      case SettingsPages.CalendarSync:
        return props.translate(SitecoreDictionary.Profile.Menu.CalendarSyncTitle) as string;
      case SettingsPages.TrainingPreferences:
        return props.translate(SitecoreDictionary.Profile.SectionDescription.TrainingPreferences) as string;
      default:
        return undefined;
    }
  };

  const renderPages = () => {
    return Object.keys(SettingsPages).map(key => (
      <CSSTransition
        key={key}
        in={props.activeSettingsPage === (SettingsPages as any)[key]}
        timeout={0}
        classNames={'Settings__Banner__Page-animation'}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <div className="Settings__Banner__Page">
          {props.settingsPageTitle && (
            <SettingsPageHeader
              className="Settings__Banner__Page__Header"
              title={props.settingsPageTitle}
              description={getSettingsDescription((SettingsPages as any)[key])}
            />
          )}
          <div className="Settings__Banner__Page__Content">{getSettingsPage((SettingsPages as any)[key])}</div>
        </div>
      </CSSTransition>
    ));
  };

  const hasActivePage = () => {
    return props.activeSettingsPage !== null && props.activeSettingsPage !== undefined;
  };

  const getBannerClasses = () => {
    let classes = ' Settings__Banner';
    const positionBottomClass = 'Settings__Banner--alignContentBottom';
    const positionTopClass = 'Settings__Banner--alignContentTop';
    const positionMiddleClass = 'Settings__Banner--alignContentMiddle';
    classes += hasActivePage() ? ' Settings__Banner--hasActivePage' : '';
    switch (props.activeSettingsPage) {
      case SettingsPages.Profile:
        classes += ` ${positionTopClass} `;
        break;
      case SettingsPages.PaymentSettings:
        classes += ` ${positionMiddleClass} `;
        break;
      case SettingsPages.UserAccount:
        classes += ` ${positionBottomClass} `;
        break;
      case SettingsPages.TrainingPreferences:
        classes += ` ${positionTopClass} `;
        break;
      case SettingsPages.NotificationSettings:
        classes += ` ${positionMiddleClass} `;
        break;
      default:
        classes += '';
    }
    return classes;
  };

  return (
    <Banner className={getBannerClasses()} backgroundUrl={props.image}>
      {!hasActivePage() && (
        <div className="Settings__Banner__Content">{props.translate(SitecoreDictionary.Settings.Banner)}</div>
      )}
      {props.isDesktopMode && renderPages()}
    </Banner>
  );
};
