import { connect } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { getTranslate } from 'store/global/dictionary';
import { setPassToBook } from '../../store/global/session/sessionActions';
import { CalendarSyncPopupDataProps, CalendarSyncPopupActionProps, CalendarSyncPopup } from './CalendarSyncPopup';
import { setShouldShowCalendarSyncPopup } from 'store/global/calendar';

const mapStateToProps = (state: RootState, props: any): CalendarSyncPopupDataProps => ({
  translate: getTranslate(state.locale),
  passToBook: state.global.session.passToBook,
  syncCalendar: state.global.calendar.syncIsActive,
});

const mapActionsToProps = (dispatch: Dispatch): CalendarSyncPopupActionProps => ({
  popupShown: () => {
    dispatch(setPassToBook());
    dispatch(setShouldShowCalendarSyncPopup(false));
  },
});

export default connect(mapStateToProps, mapActionsToProps)(CalendarSyncPopup);
