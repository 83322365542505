import { getCookie, addCookie, clearCookies } from './../websd/utils/cookie';
import moment from 'moment';
//eslint-disable-next-line valid-typeof
const isLocalStorageAvailable = typeof Storage !== null;
const expirationDateExt: string = '{{expDate}}';

interface LocalStorageItem<T> {
  value: T | null;
  isExpired: boolean | null;
  ExpirationDate?: Date;
}

function addAsCookie(
  key: string,
  // tslint:disable-next-line:no-any
  item: any,
  expirationDate?: Date,
  overwrite: boolean = true
) {
  const hasACookieWithSameName = getCookie(key) && getCookie(key) !== '';
  if (hasACookieWithSameName && !overwrite) {
    return;
  }
  addCookie(key, item, '/', undefined, expirationDate);
}

function saveItem(
  key: string,
  // tslint:disable-next-line:no-any
  item: any,
  expirationDate?: Date,
  overwrite: boolean = true,
  useCookiesIfNotPossible: boolean = true
) {
  if (!isLocalStorageAvailable && useCookiesIfNotPossible) {
    addAsCookie(key, item, expirationDate, overwrite);
  } else {
    if (isLocalStorageAvailable) {
      const currentItem = getItem<string>(key);
      if (overwrite || !currentItem || !currentItem.value || currentItem.value === '' || currentItem.isExpired) {
        window.localStorage.setItem(key, JSON.stringify(item));
        if (expirationDate) {
          expirationDate.setDate(expirationDate.getDate() + 7);
          window.localStorage.setItem(key + expirationDateExt, expirationDate.toUTCString());
        } else {
          removeItem(key + expirationDateExt);
        }
      }
    }
  }
}

function saveItemInSession(
  key: string,
  // tslint:disable-next-line:no-any
  item: any,
  overwrite: boolean = true,
  useCookiesIfNotPossible: boolean = true
) {
  if (!isLocalStorageAvailable && useCookiesIfNotPossible) {
    addAsCookie(key, item, undefined, overwrite);
  } else {
    if (isLocalStorageAvailable) {
      const currentItem = getItem<string>(key);
      if (overwrite || !currentItem) {
        window.sessionStorage.setItem(key, JSON.stringify(item));
      }
    }
  }
}

function getItem<T>(key: string, useCookiesIfNotPossible: boolean = true): LocalStorageItem<T> | null {
  let value: T;
  if (!isLocalStorageAvailable && !useCookiesIfNotPossible) {
    return null;
  }

  const item: string | null = isLocalStorageAvailable ? window.localStorage.getItem(key) : getCookie(key);
  if (!item) {
    return null;
  }
  value = JSON.parse(item) as T;
  if (!isLocalStorageAvailable) {
    return {
      value,
      isExpired: false,
    };
  }

  const expirationDate: string | null = window.localStorage.getItem(key + expirationDateExt);
  let isExpired: boolean | null = null;
  let mExpirationDate: moment.Moment | undefined = undefined;
  if (expirationDate && expirationDate !== '') {
    mExpirationDate = moment(expirationDate);
    isExpired = mExpirationDate.isBefore(moment());
  }
  return {
    value,
    isExpired,
    ExpirationDate: !isExpired && mExpirationDate && mExpirationDate.isValid() ? mExpirationDate.toDate() : undefined,
  };
}

function getItemFromSession<T>(key: string, useCookiesIfNotPossible: boolean = true): T | null {
  let value: T;
  if (!isLocalStorageAvailable && !useCookiesIfNotPossible) {
    return null;
  }

  const item: string | null = isLocalStorageAvailable ? window.sessionStorage.getItem(key) : getCookie(key);
  if (!item) {
    return null;
  }
  value = JSON.parse(item) as T;
  return value;
}

function removeItem(key: string) {
  if (isLocalStorageAvailable) {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(key + expirationDateExt);
  }
}

function removeItemFromSession(key: string) {
  if (isLocalStorageAvailable) {
    window.sessionStorage.removeItem(key);
  }
}

function clear() {
  if (isLocalStorageAvailable) {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }
  clearCookies();
}

export const localStorage = {
  saveItem,
  saveItemInSession,
  getItem,
  getItemFromSession,
  removeItem,
  removeItemFromSession,
  clear,
};
