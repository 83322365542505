import { combineReducers } from 'redux';
import { readReducer, PassDetailsReadState, PassDetailsReadAction } from './read';

export type PassDetailsAction = PassDetailsReadAction;

export interface PassDetailsRootState {
  read: PassDetailsReadState;
}

export const passDetailsReducer = combineReducers<PassDetailsRootState>({
  read: readReducer,
});
