import * as React from 'react';

export interface IconMessageProps {
  title?: string;
}

export class IconMessage extends React.Component<IconMessageProps, {}> {
  render() {
      /* tslint:disable */
    return (
      <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g fill="none" fillRule="evenodd">
          <circle fill="#E31836" cx="11" cy="11" r="11" />
          <path
            d="M14,12.0086957 C14,12.7434783 13.5970149,13.351087 12.7910448,13.8315217 C13.2835821,14.2978261 13.5223881,14.8347826 13.5223881,15.4423913 C13.5223881,16.2336957 13.2686567,16.8554348 12.7761194,17.3076087 C12.2835821,17.773913 11.7014925,18 11,18 C9.55223881,18 8.64179104,17.3076087 8.29850746,15.9369565 L9.13432836,15.6684783 C9.44776119,16.6717391 10.0746269,17.1804348 11,17.1804348 C11.9104478,17.1804348 12.5671642,16.5586957 12.5671642,15.4706522 C12.5671642,15.0184783 12.3880597,14.6652174 11.9552239,14.3684783 C11.7313433,14.2130435 11.5223881,14.0717391 11.3283582,13.9728261 L10.4477612,13.548913 C10.0597015,13.3652174 9.74626866,13.1956522 9.50746269,13.0684783 C8.44776119,12.5032609 8,11.9663043 8,10.9913043 C8,10.2565217 8.40298507,9.64891304 9.20895522,9.16847826 C8.71641791,8.70217391 8.47761194,8.16521739 8.47761194,7.5576087 C8.47761194,6.76630435 8.71641791,6.14456522 9.20895522,5.6923913 C9.70149254,5.22608696 10.2985075,5 11,5 C12.4477612,5 13.358209,5.6923913 13.7014925,7.06304348 L12.8656716,7.33152174 C12.5522388,6.32826087 11.9253731,5.81956522 11,5.81956522 C10.0895522,5.81956522 9.43283582,6.44130435 9.43283582,7.52934783 C9.43283582,7.98152174 9.6119403,8.32065217 10.0447761,8.64565217 C10.2686567,8.80108696 10.4776119,8.92826087 10.6716418,9.02717391 C10.8656716,9.12608696 11.1641791,9.28152174 11.5522388,9.46521739 C11.9402985,9.64891304 12.2537313,9.80434783 12.4925373,9.93152174 C13.5522388,10.4967391 14,11.0336957 14,12.0086957 Z M10.9552239,12.8 L12.0447761,13.2945652 C12.7462687,12.9695652 13.1044776,12.5456522 13.1044776,11.9945652 C13.1044776,11.5423913 12.8358209,11.1891304 12.3432836,10.8923913 C12.0597015,10.7086957 11.8955224,10.5956522 11.5223881,10.426087 L11.0447761,10.2 L9.95522388,9.70543478 C9.25373134,10.0304348 8.89552239,10.4543478 8.89552239,11.0054348 C8.89552239,11.4576087 9.1641791,11.7826087 9.65671642,12.1217391 C9.94029851,12.2913043 10.1044776,12.4043478 10.4776119,12.5880435 L10.9552239,12.8 Z"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    );
  }
}
