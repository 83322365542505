import * as React from 'react';
import { LocationActionTypes } from 'store/location';
import calendarIcon from 'static/assets/icon-startpage-calender.svg';
import notiserIcon from 'static/assets/icon-startpage-notifications.svg';
import traeningsstatistikIcon from 'static/assets/icon-startpage-statistics.svg';
import traeningsstaellenIcon from 'static/assets/icon-startpage-facilities.svg';
import onlineTreaningsIcon from 'static/assets/icon-online-training.svg';
import nyheterIcon from 'static/assets/icon-startpage-news.svg';
import medlemskapIcon from 'static/assets/icon-startpage-membership.svg';

import { SitecoreDictionary } from 'models';
import { TranslateFunction } from 'react-localize-redux';

interface DesktopLinksActionProps {
  switchView: (location: LocationActionTypes) => void;
  translate: TranslateFunction;
  inloggadFoerening?: fos.api.InloggadeFoerening;
}

export type DesktopLinksProps = DesktopLinksActionProps;
type linkType = {
  location: LocationActionTypes;
  icon: string;
  title: string;
};

export const DesktopLinks: React.SFC<DesktopLinksProps> = props => {
  let onlineTraining = false;
  if (
    !!props.inloggadFoerening &&
    !!props.inloggadFoerening.ExtraFoereningInfo &&
    !!props.inloggadFoerening.ExtraFoereningInfo.OnlineTrainingUrl
  ) {
    onlineTraining = true;
  }
  const links: linkType[] = [
    {
      location: LocationActionTypes.Bookings,
      title: props.translate(SitecoreDictionary.StartPage.Menu.Bookings) as string,
      icon: calendarIcon,
    },
    {
      location: LocationActionTypes.Notifications,
      title: props.translate(SitecoreDictionary.StartPage.Menu.Notifications) as string,
      icon: notiserIcon,
    },
    {
      location: LocationActionTypes.Statistics,
      title: props.translate(SitecoreDictionary.StartPage.Menu.TrainingStatistics) as string,
      icon: traeningsstatistikIcon,
    },
    {
      location: LocationActionTypes.Facilities,
      title: props.translate(SitecoreDictionary.StartPage.Menu.Facilities) as string,
      icon: traeningsstaellenIcon,
    },
    {
      location: LocationActionTypes.News,
      title: props.translate(SitecoreDictionary.StartPage.Menu.News) as string,
      icon: nyheterIcon,
    },
    {
      location: LocationActionTypes.Subscriptions,
      title: props.translate(SitecoreDictionary.StartPage.Menu.Subsctiptions) as string,
      icon: medlemskapIcon,
    },
  ];

  return (
    <div className="StartPage__DesktopLinks">
      <div className="StartPage__DesktopLinks__Menu">
        {/* ONLINETRÄNING */}
        {onlineTraining && (
          <a
            className="StartPage__DesktopLinks__Menu__Item onlineTraining"
            href={props.inloggadFoerening ? props.inloggadFoerening.ExtraFoereningInfo.OnlineTrainingUrl : ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="StartPage__DesktopLinks__Menu__Item__Icon__Container">
              {<img className="StartPage__DesktopLinks__Menu__Item__Icon" alt="Onlineträning" src={onlineTreaningsIcon} />}
            </div>
            <div className="StartPage__DesktopLinks__Menu__Item__Title">
              {props.translate(SitecoreDictionary.MainMenu.OnlineTraining)}
            </div>
          </a>
        )}
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {!(onlineTraining && link.location === LocationActionTypes.News) && (
              <div
                key={link.location + '' + index}
                className="StartPage__DesktopLinks__Menu__Item"
                onClick={() => props.switchView(link.location)}
              >
                <div className="StartPage__DesktopLinks__Menu__Item__Icon__Container">
                  <img className="StartPage__DesktopLinks__Menu__Item__Icon" alt="Meny" src={link.icon} />
                </div>
                <div className="StartPage__DesktopLinks__Menu__Item__Title">{link.title}</div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
