import { ThunkAction } from 'store/rootReducer';
import { updateFoereningId } from '.';
import { constructSafeName } from 'utils';

export const setFoereningIdFromQueryParameter = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  const foerening = state.foereningar.read.Foereningar.find(
    f => constructSafeName(f.Namn.toLowerCase()) === constructSafeName(state.location.query.foerening.toLowerCase())
  );
  if (foerening) {
    dispatch(updateFoereningId(foerening.Id));
  }
};
