export enum InitializeActionTypes {
  SET_INITIALIZED = 'SET_INITIALIZED',
}

export type SetInitializedAction = {
  type: InitializeActionTypes.SET_INITIALIZED;
  initialized: boolean;
};

export type InitializeAction = SetInitializedAction;
