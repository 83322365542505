import { UIScheduleFilterAction } from '../schedule-filter/uiScheduleFilterActions';

export enum UIBookingActionType {
  SetScrollToDay = 'UI/BOOKING/SETSCROLLTODAY',
  SetSelectedDayPickerDay = 'UI/BOOKING/SET_SELECTED_DAYPICKER_DAY',
}

export type SetScrollToDayAction = {
  type: UIBookingActionType.SetScrollToDay;
  scrollToDay: string;
};

export type UIBookingSetSelectedDayAction = {
  type: UIBookingActionType.SetSelectedDayPickerDay;
  centerDay: string;
};

export type UIBookingAction =
  | SetScrollToDayAction
  // | ToggleDayListAction
  | UIScheduleFilterAction
  | UIBookingSetSelectedDayAction;
