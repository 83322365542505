import { setShouldAcceptBookingRulesAction, setPassToBook } from './../global/session/sessionActions';
import { ThunkAction } from './../rootReducer';
import { api } from 'api';
import { Dispatch, RootState, Action } from 'store';
import { SortSortedIntoDaysViewObject, getDateTextForView } from 'websd/utils';
import { Day, getStatusMessage, SitecoreDictionary } from 'models';
import { updateDayData, updateBokaPassStatus } from './read';

import {
  FetchCommandTypes,
  executingCommand,
  executedCommand,
  executingCommandFailed,
  fetching,
  FetchStatusAggregateTypes,
  fetched,
  fetchedFailed,
  fetchingInBackground,
} from '../global/fetch';
import { getNextBokning, getBookingsInBackground } from '../bokningar';
import { Mobile, isApp } from 'models/app/MobileOSType';
import { getQueryStringValue } from 'utils';
import { LocationActionTypes } from 'store/location';
import { allowBokaPass } from 'store/global/ui/sitecore/sitecoreActions';
import { setShouldShowCalendarSyncPopup } from 'store/global/calendar';
import { updatePassDetailsData } from 'store/pass-details/read';
import { getTranslate } from 'store/global/dictionary';
import { getWeekdayName, getMonthName } from 'models/SitecoreDictionary';
import { PassFilterFiltrerbaraKategorier, passFilterSetFilter, passFilterUpdateUnfilteredDays } from '../passFilter';
import moment from 'moment';
import { SuccessResult, ErrorResult } from 'websd/utils/configureApi';

const arrangeShiftsInDateByTime = (passDaysList: Array<Day<fos.api.Pass>>): Array<Day<fos.api.Pass>> => {
  passDaysList.forEach((shiftsForDay, index) => {
    shiftsForDay.items = shiftsForDay.items.sort((pass1: fos.api.Pass, pass2: fos.api.Pass) => {
      const m1 = moment(pass1.StartTime);
      const m2 = moment(pass2.StartTime);
      return m1 < m2 ? -1 : m1 > m2 ? 1 : 0;
    });
  });
  return passDaysList;
};

export const dispatchPassForScheduleReducer = (days: Array<Day<fos.api.Pass>>, dispatch: Dispatch<Action>) => {
  const passDays: Array<Day<fos.api.Pass>> = arrangeShiftsInDateByTime(days);
  const passDaysInViewObject = SortSortedIntoDaysViewObject(passDays);
  dispatch(passFilterUpdateUnfilteredDays(passDaysInViewObject));
};

export const fetchPassListFromActiveFoereningInBackground =
  (): ThunkAction => (dispatch: Dispatch, getState: () => RootState) => {
    if (getState().location.type !== LocationActionTypes.PublicSchedule) {
      let foerening = getState().global.session.activeFoerening;
      if (!!foerening) {
        return dispatch(fetchPassListInBackground(foerening));
      }
    }
    let foereningName = getQueryStringValue('foerening');
    if (!!foereningName) {
      return dispatch(fetchPassListInBackground(-1, foereningName));
    }
    return null;
  };

export const fetchPassListInBackground =
  (foereningsId: number, foereningsName?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(
      fetchingInBackground(!!foereningsName ? foereningsName : foereningsId, FetchStatusAggregateTypes.PASS_LIST)
    );
    return dispatch<
      Promise<SuccessResult<{ pass: Array<Day<fos.api.Pass>>; filter: PassFilterFiltrerbaraKategorier }> | ErrorResult>
    >(fetchPassList(foereningsId, foereningsName));
  };

export const fetchPassListInForeground =
  (foereningsId: number, foereningsName?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(fetching(!!foereningsName ? foereningsName : foereningsId, FetchStatusAggregateTypes.PASS_LIST));
    return dispatch<
      Promise<SuccessResult<{ pass: Array<Day<fos.api.Pass>>; filter: PassFilterFiltrerbaraKategorier }> | ErrorResult>
    >(fetchPassList(foereningsId, foereningsName));
  };

const fetchPassList =
  (foereningsId: number, foereningsName?: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const getPassListUrl = !!foereningsName
      ? `pass/GetPassListPublic?foereningsName=${foereningsName}`
      : `pass/GetPassList?foereningsId=${foereningsId}`;
    const promise = api.get<{ pass: Array<Day<fos.api.Pass>>; filter: PassFilterFiltrerbaraKategorier }>(
      getPassListUrl,
      {
        muteErrorNotificationOnStatus: [406],
        anonymousCall: true,
      }
    );
    promise.then(result => {
      if (!result.error && result.result.pass) {
        const days = result.result.pass;
        const translate = getTranslate(getState().locale);
        days.forEach(d => {
          d.date = new Date(d.rawDate);
          d.key =
            translate((SitecoreDictionary.WeekDays as any)[getWeekdayName(d.date.getDay())]) +
            ', ' +
            d.date.getDate() +
            ' ' +
            translate((SitecoreDictionary.Months as any)[getMonthName(d.date.getMonth())]);
        });
        dispatch(updateDayData(days));
        dispatchPassForScheduleReducer(days, dispatch);
        dispatch(passFilterSetFilter(result.result.filter));
        dispatch(fetched(!!foereningsName ? foereningsName : foereningsId, FetchStatusAggregateTypes.PASS_LIST, days));
      } else {
        dispatch(fetchedFailed(!!foereningsName ? foereningsName : foereningsId, FetchStatusAggregateTypes.PASS_LIST));
      }
    });
    return promise;
  };

export const bokaPassFromSitecore =
  (bokaPassRequest: BokningRequest, bokaKoePlats: boolean): ThunkAction =>
  dispatch => {
    const url = `Pass/${bokaKoePlats ? 'BokaKoePlats' : 'BokaPass'}/`;
    const promise = api.post<BokningResponse, BokningRequest>(url, bokaPassRequest, {
      muteErrorNotificationOnStatus: [403, 412],
    });
    promise.then(res => {
      if (!res.error) {
        dispatch(updateBokaPassStatus(bokaPassRequest.passId, getStatusMessage(res.result.status), ''));
      } else if (res.result && res.result.http) {
        dispatch(updateBokaPassStatus(bokaPassRequest.passId, getStatusMessage(res.result.http.response.status), ''));
        if (res.result.http.status === 412) {
          dispatch(setShouldAcceptBookingRulesAction(true));
        }
      } else {
        dispatch(
          updateBokaPassStatus(
            bokaPassRequest.passId,
            getStatusMessage(SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR),
            ''
          )
        );
      }
    });
  };

export const bokaPass =
  (pass: fos.api.Pass, bokaKoePlats: boolean) => (dispatch: Dispatch<Action>, getState: () => RootState) => {
    dispatch(executingCommand(pass.PassId, FetchCommandTypes.BOKA_PASS));
    const foereningsId = getState().global.session.activeFoerening as number;
    const syncCalendar = getState().global.calendar.syncIsActive;
    const shouldShowPopup = getState().global.calendar.shouldShowCalendarSyncPopup;
    const request: BokningRequest = {
      passId: pass.PassId,
      foereningsId: foereningsId,
    };
    const url = `Pass/${bokaKoePlats ? 'BokaKoePlats' : 'BokaPass'}/`;
    const promise = api.post<BokningResponse, BokningRequest>(url, request, {
      muteErrorNotificationOnStatus: [403, 412],
    });

    const filterUsed = getState().passFilter.flatFilter;
    const numberOfFiltersUsed =
      filterUsed.Veckodag.length +
      filterUsed.Traeningsstaelle.length +
      filterUsed.Traeningspass.length +
      filterUsed.Tidpunkt.length +
      filterUsed.LedareOrTraenare.length;

    promise.then(res => {
      dispatch(allowBokaPass());
      if (!res.error) {
        (window as any).dataLayer.push({
          event: 'bookedTraining',
          forening: pass.FoereningNamn,
          filterUsage: numberOfFiltersUsed > 0,
          NumberOfUsedfilters: numberOfFiltersUsed,
        });

        const status: string = getStatusMessage(res.result.status);
        res.result.pass.statusMessage = status;
        const allDays = getState().pass.read.days;
        const key = getDateTextForView(res.result.pass.StartTime);
        for (let i = 0; i < allDays.length; i++) {
          if (allDays[i].key === key) {
            for (let j = 0; allDays[i].items.length; j++) {
              if (allDays[i].items[j].PassId === res.result.pass.PassId) {
                allDays[i].items[j] = res.result.pass;
                const detailedPass = getState().passDetails.read.pass;
                if (!!detailedPass && detailedPass.PassId === res.result.pass.PassId) {
                  detailedPass.AntalLedigaPlatser = res.result.pass.AntalLedigaPlatser;
                  detailedPass.AntalBokningsbaraPlatser = res.result.pass.AntalBokningsbaraPlatser;
                  detailedPass.AntalDropinPlatser = res.result.pass.AntalDropinPlatser;
                  detailedPass.AntalIVaenteLista = res.result.pass.AntalIVaenteLista;
                  detailedPass.AntalPlatserTotal = res.result.pass.AntalPlatserTotal;
                  detailedPass.AntalBokningsbaraPlatserTotalt = res.result.pass.AntalBokningsbaraPlatserTotalt;
                  detailedPass.LedigaKoePlatser = res.result.pass.LedigaKoePlatser;
                  detailedPass.statusMessage = res.result.pass.statusMessage;
                  detailedPass.BokningStatus = res.result.pass.BokningStatus;
                  dispatch(updatePassDetailsData(detailedPass));
                }
                break;
              }
            }
            break;
          }
        }

        dispatch(updateDayData(allDays));
        dispatch(updateBokaPassStatus(pass.PassId, status, key));
        dispatchPassForScheduleReducer(allDays, dispatch);
        dispatch(getBookingsInBackground());
        dispatch(getNextBokning());
        dispatch(executedCommand(pass.PassId, FetchCommandTypes.BOKA_PASS));
        if (syncCalendar && pass.BokningStatus === 'BOKNINGSBART') {
          var data = JSON.stringify(pass);
          Mobile.addToCalendar(data);
        } else if (!syncCalendar && shouldShowPopup && isApp() && pass.BokningStatus === 'BOKNINGSBART') {
          dispatch(setPassToBook(pass));
          dispatch(setShouldShowCalendarSyncPopup(true));
        }
      } else {
        const date = getDateTextForView(pass.StartTime);
        if (res.result.http && res.result.http.response) {
          dispatch(updateBokaPassStatus(pass.PassId, getStatusMessage(res.result.http.response.status), date));
          dispatchPassForScheduleReducer(getState().pass.read.days, dispatch);
          dispatch(executingCommandFailed(pass.PassId, FetchCommandTypes.BOKA_PASS));
          if (res.result.http.status === 412) {
            dispatch(setPassToBook(pass));
            dispatch(setShouldAcceptBookingRulesAction(true));
          }
        } else {
          dispatch(updateBokaPassStatus(pass.PassId, SitecoreDictionary.Schedule.Messages.UNKNOWN_ERROR, date));
          dispatchPassForScheduleReducer(getState().pass.read.days, dispatch);
          dispatch(executingCommandFailed(pass.PassId, FetchCommandTypes.BOKA_PASS));
        }
      }
    });
    return promise;
  };

export interface BokningResponse {
  status: string;
  koPlats: number;
  bokningId: string;
  pass: fos.api.Pass;
}

export interface BokningRequest {
  passId: string | number;
  foereningsId: number;
}
