import * as React from 'react';

export interface IconInformationProps {
  title?: string;
}

export class IconInformation extends React.Component<IconInformationProps, {}> {
  render() {
      /* tslint:disable */
    return (
      <svg width="22px" height="22px" xmlns="http://www.w3.org/2000/svg">
        <title>{this.props.title}</title>
        <g fill="none" fillRule="evenodd">
          <circle
            cx="11"
            cy="11"
            r="11"
            transform="translate(11.000000, 11.000000) scale(1, -1) translate(-11.000000, -11.000000)"
            fill="#262626"
          />
          <path
            d="M10,5.44 C10,5.03199796 10.1349987,4.69000138 10.405,4.414 C10.6750014,4.13799862 11.0199979,4 11.44,4 C11.8600021,4 12.2049986,4.13799862 12.475,4.414 C12.7450014,4.69000138 12.88,5.03199796 12.88,5.44 C12.88,5.84800204 12.7450014,6.18999862 12.475,6.466 C12.2049986,6.74200138 11.8600021,6.88 11.44,6.88 C11.0199979,6.88 10.6750014,6.74200138 10.405,6.466 C10.1349987,6.18999862 10,5.84800204 10,5.44 Z M12.556,8.05 L12.556,17.122 L10.324,17.122 L10.324,8.05 L12.556,8.05 Z"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    );
  }
}
