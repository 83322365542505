import { Dispatch, Action, RootState, ThunkAction } from 'store';
import { api } from 'api';
import { FetchCommandTypes, executingCommandFailed, executedCommand, executingCommand } from 'store/global/fetch';
import { updateUser, clearUpdateUserCmdErrorCode, setUpdateUserCmdErrorCode } from '.';
import { getUser } from 'store/user/read';

export const updateUserProfileCmd = (FoereningId: number, User: fos.api.User, propertyName: string): ThunkAction => (
  dispatch: Dispatch<Action>,
  getState: () => RootState
) => {
  const cmdId = `${FoereningId.toString()}/${propertyName}`;
  dispatch(clearUpdateUserCmdErrorCode());
  dispatch(executingCommand(cmdId, FetchCommandTypes.SPARA_ANVAENDARE));
  const promise = api.post<fos.api.User>('ProfileInfo/Update?foereningId=' + FoereningId, User, {
    muteErrorNotificationOnStatus: [406, 409],
  });
  promise.then(result => {
    if (!result.error) {
      let user: fos.api.User = User;
      dispatch(getUser(user));
      dispatch(executedCommand(cmdId, FetchCommandTypes.SPARA_ANVAENDARE));
    } else {
      // Kunde inte spara ProfileInfo.
      const state = getState();
      const originaleProfileData = state.user.read.user;
      if (result.result && result.result.http && !!result.result.http.status) {
        dispatch(setUpdateUserCmdErrorCode(result.result.http.status));
      }
      dispatch(executingCommandFailed(cmdId, FetchCommandTypes.SPARA_ANVAENDARE));
      dispatch(updateUser(originaleProfileData));
    }
  });
  return promise;
};

export const initializeUpdateUserProfileCmd = (FoereningId: number, propertyName: string): ThunkAction => (
  dispatch: Dispatch<Action>,
  getState: () => RootState
) => {
  const cmdId = `${FoereningId.toString()}/${propertyName}`;
  dispatch(executingCommand(cmdId, FetchCommandTypes.SPARA_ANVAENDARE));
};
