/**
 * Kollar om en e-post adress är giltig eller inte
 * @param value e-post adressen
 * @returns true om e-post adressen är giltig annars false
 */
export const isValidEmail = (value: string): boolean => {
  // http://emailregex.com/
  const emailRegExp: RegExp = new RegExp(
    [
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)',
      '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])',
      '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ].join('')
  );

  return emailRegExp.test(value);
};

export const isAccountNumberValid = (value: string | number): boolean => !!value && !isNaN(Number(value));

export const isClearingNumberValid = (value: string | number): boolean => !!value && !isNaN(Number(value));
