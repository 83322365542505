import { executedCommand, executingCommandFailed } from './../../global/fetch/fetchActions';
import { ThunkAction } from 'store';
import { api } from 'api';
import { executingCommand, FetchCommandTypes } from 'store/global/fetch';
import { getAutogiro } from '../read/userReadActions';
import { updateAutogiroActions } from './updateAutogiroActions';

export const updateAutogiro = (): ThunkAction => (dispatch, getState) => {
  const state = getState();
  if (!state.global.session.activeFoerening) {
    return;
  }

  const activeFoereningId: number = state.global.session.activeFoerening;
  const requestBody: fos.api.UpdateAutogiroRequest = {
    FoereningId: activeFoereningId,
    Id: state.user.updateAutogiroCmd.id,
    ClearingNumber: state.user.updateAutogiroCmd.clearingNumber,
    BankAccountNumber: state.user.updateAutogiroCmd.accountNumber,
  };
  dispatch(executingCommand(state.user.updateAutogiroCmd.id, FetchCommandTypes.UPDATE_AUTOGIRO));
  return api
    .put<fos.api.UpdateAutogiroResponse, fos.api.UpdateAutogiroRequest>('Betalningsmedel/UpdateAutogiro', requestBody, {
      muteErrorNotificationOnStatus: [400],
    })
    .then(res => {
      if (res.error) {
        dispatch(executingCommandFailed(state.user.updateAutogiroCmd.id, FetchCommandTypes.UPDATE_AUTOGIRO));
        if (res.result.http) {
          dispatch(updateAutogiroActions.setErrorMessage(res.result.http.response.status));
        }
      } else {
        dispatch(executedCommand(state.user.updateAutogiroCmd.id, FetchCommandTypes.UPDATE_AUTOGIRO));
        dispatch(getAutogiro(res.result.autogiro));
      }
    });
};
