import * as React from 'react';
import { getTranslate } from 'store/global/dictionary';
import { SitecoreDictionary } from 'models';
import { FancyNavLink } from 'components/app';
import { MainButton } from 'components/common';
import { routesMap, LocationActionTypes, getIsAdminWebMode } from 'store/location';
import { RootState } from 'store';
import { connect } from 'react-redux';
import { TranslateFunction } from 'react-localize-redux';

type ShopNotAvailableForFoereningProps = Readonly<{
  translate: TranslateFunction;
  isAdmin: boolean;
}>;

const ShopNotAvailableForFoerening: React.SFC<ShopNotAvailableForFoereningProps> = ({ translate, isAdmin }) => (
  <div className="ShopNotAvailableForFoerening">
    <h3>
      {translate(
        isAdmin
          ? SitecoreDictionary.Shop.Admin.ShopNotAvailableForAdminTitle
          : SitecoreDictionary.Shop.ShopNotAvailableTitle
      )}
    </h3>
    <p>
      {translate(
        isAdmin
          ? SitecoreDictionary.Shop.Admin.ShopNotAvailableForAdminText
          : SitecoreDictionary.Shop.ShopNotAvailableText
      )}
    </p>
    <FancyNavLink
      className="ShopNotAvailableForFoerening__Continue"
      to={routesMap[isAdmin ? LocationActionTypes.Shop : LocationActionTypes.Home]}
    >
      <MainButton
        className="ShopNotAvailableForFoerening__Continue__Button"
        title={
          translate(
            isAdmin
              ? SitecoreDictionary.Shop.Admin.ShopNotAvailableForAdminReturnButtonText
              : SitecoreDictionary.Shop.ShopNotAvailableReturnButtonText
          ) as string
        }
        buttonStyle="solid"
        color="white"
      />
    </FancyNavLink>
  </div>
);

const mapStateToProps = (state: RootState): ShopNotAvailableForFoereningProps => ({
  translate: getTranslate(state.locale),
  isAdmin: getIsAdminWebMode(state.location),
});

export default connect(mapStateToProps, {})(ShopNotAvailableForFoerening);
